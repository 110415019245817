import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import {
  arrowDown,
  arrowUp,
  asc,
  desc,
  detail,
  eye,
} from "../../../../../assets/icons";
import Loading from "../../../../../components/loading/Loading";
import Pagination from "../../../../../components/pagination/Pagination";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import { formatRupiah } from "../../../../../utils/format_rupiah/FormatRupiah";
import { useQueries } from "../../../../../utils/query_searchParams/QuerySearchParams";
import GetStockPerSize from "../../../helpers/use_query_hook/GetStockPerSize";

interface IProps {
  data: any;
}

const CampaignProductTable: React.FC<IProps> = ({ data }) => {
  const { data: dataStock, isLoading } = GetStockPerSize(data?.product_id);
  const { CampaignDispatch } = useContext(CampaignContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = useQueries(useLocation);
  const sorting: any = query.get("sort") ? query.get("sort") : "created_at";
  const isAsc: any = query.get("isAsc") ? query.get("isAsc") : "desc";
 
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : dataStock?.data?.length < 0 ? (
        <div className="flex justify-center w-full mt-20">
          <h1>Empty Data</h1>
        </div>
      ) : dataStock?.data?.length > 0 ? (
        <div className="mt-14 pb-10">
          <table className="w-full border relative text-center text-sm">
            <thead>
              <tr className="h-18 sticky top-0 bg-white shadow-sm">
                <th className="border-r h-18">
                  <div className="flex justify-center items-center">
                    <h1>Size</h1>
                    <img
                      src={sorting === "size" && isAsc === "asc" ? asc : desc}
                      onClick={() => {
                        let new_search = search.replace(sorting, "size");
                        let fix_search;
                        isAsc === "asc"
                          ? (fix_search = new_search.replace(isAsc, "desc"))
                          : (fix_search = new_search.replace(isAsc, "asc"));
                        history.push({ pathname, search: fix_search });
                      }}
                      alt="asc-desc"
                      className="w-4 ml-3 cursor-pointer"
                    />
                  </div>
                </th>
                <th className="border-r h-18">
                  <div className="flex justify-center items-center">
                    <h1>Seller</h1>
                    {/* <img
                    src={sort.seller === "asc" ? asc : desc}
                    onClick={() =>
                      setSort({
                        ...sort,
                        seller: sort.seller === "asc" ? "desc" : "asc",
                      })
                    }
                    alt="asc-desc"
                    className="w-4 ml-3 cursor-pointer"
                  /> */}
                  </div>
                </th>
                <th className="border-r h-18">
                  <div className="flex justify-center items-center">
                    <h1>Status</h1>
                    {/* <img
                    src={sort.status === "asc" ? asc : desc}
                    onClick={() =>
                      setSort({
                        ...sort,
                        status: sort.status === "asc" ? "desc" : "asc",
                      })
                    }
                    alt="asc-desc"
                    className="w-4 ml-3 cursor-pointer"
                  /> */}
                  </div>
                </th>
                <th className="border-r h-18">
                  <div className="flex justify-center items-center">
                    <h1>Price</h1>
                    {/* <img
                    src={sort.status === "asc" ? asc : desc}
                    onClick={() =>
                      setSort({
                        ...sort,
                        status: sort.status === "asc" ? "desc" : "asc",
                      })
                    }
                    alt="asc-desc"
                    className="w-4 ml-3 cursor-pointer"
                  /> */}
                  </div>
                </th>
                <th className="border-r h-18">
                  <div className="flex justify-center items-center">
                    <h1>Stock</h1>
                    {/* <img
                      src={
                        sorting === "created_at" && isAsc === "asc" ? asc : desc
                      }
                      onClick={() => {
                        let new_search = search.replace(sorting, "created_at");
                        let a;
                        isAsc === "asc"
                          ? (a = new_search.replace(isAsc, "desc"))
                          : (a = new_search.replace(isAsc, "asc"));
                        history.push({ pathname, search: a });
                      }}
                      alt="asc-desc"
                      className="w-4 ml-3 cursor-pointer"
                    /> */}
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataStock?.data?.map((el: any) => (
                <tr className="border h-24 font-rom hover:bg-gray-50">
                  <td className="border-r">{el.product_size}</td>
                  <td className="border-r">{el.seller_username}</td>
                  <td
                    className={
                      "border-r font-med " +
                      (el.pre_order ? "text-yellow-400" : "text-success")
                    }
                  >
                    {el.pre_order ? "Pre-Order" : "Brand New"}
                  </td>
                  <td className="border-r">
                    <div className="flex flex-col justify-center">
                      <h1>{formatRupiah(el.price)}</h1>
                    </div>
                  </td>

                  <td className="border-r">
                    <div>
                      <h1>{el?.stock}</h1>
                    </div>
                  </td>

                  <td className="">
                    <div className="w-full flex justify-evenly">
                      {el.has_multiple_sellers && (
                        <img
                          onClick={() =>
                            CampaignDispatch({
                              type: ActionType.ShowProductPerSize,
                              payload: {
                                status: true,
                                size: el.product_size_id,
                              },
                            })
                          }
                          src={detail}
                          alt="see-all"
                          className="w-5 cursor-pointer"
                        />
                      )}
                      <img
                        onClick={() =>
                          history.push(`/marketplace/on-selling/${el?.code}`)
                        }
                        src={eye}
                        alt="eyes"
                        className="w-6 cursor-pointer"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            length={dataStock?.data?.length}
            total={dataStock?.meta?.total}
            isTable={true}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CampaignProductTable;
