import React, { useContext } from "react";
import { cancel } from "../../../assets/icons";
import SmallButton from "../../../components/button/SmallButton";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Input from "../../../components/input/Input";
import { useHistory, useLocation } from "react-router-dom";
import CreditEffectContext from "../../../store/credit_effect/CreditEffectContext";
import { ActionType } from "../../../store/credit_effect/action";
import {
  loadOptCampaign,
  loadOptCreditEffect,
} from "../../../utils/loadOption/LoadOption";
import SetQueryString from "../../../utils/query_searchParams/SetQueryString";
import { queryparamsCreditEffect } from "../helpers/list";

const scopeOptions: any[] = [
  { value: "marketplace", label: "Marketplace" },
  { value: "retail", label: "Retail" },
];

const CreditEffectFilter = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { CreditEffectDispatch } = useContext(CreditEffectContext);

  const handleShowFilter = () => {
    CreditEffectDispatch({ type: ActionType.ShowFilter });
  };

  const handleChangeFilter = (event: any, default_name?: string) => {
    let name;
    let value;
    if (!event?.label) {
      name = event.target.name;
      value = event.target.value;
    } else {
      value = event.value;
      name = default_name;
    }
    SetQueryString(
      queryparamsCreditEffect,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  const applyFilter = () => {
    setTimeout(
      () => CreditEffectDispatch({ type: ActionType.ShowFilter }),
      500
    );
  };

  return (
    <div className="fixed z-50 cursor-pointer h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white md:rounded-lg cursor-default rounded-t-none rounded-b-lg h-2/3 shadow-xl md:w-2/5 w-full">
        <div className="px-10 flex justify-between items-center w-full h-1/6">
          <h1
            className="md:text-2xl text-lg text-textPrimary"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full" />
        <div className="h-4/6 overflow-y-auto">
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Credit Effect Name
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptCreditEffect}
                onChange={(e) => handleChangeFilter(e, "credit_effect_name")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>

          <hr className="w-full" />
          <div className="grid grid-cols-2 gap-8 w-full px-10 md:my-0 my-3 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Effect For
              </h1>
              <Select
                isSearchable={true}
                className="my-3.5 font-rom text-sm"
                options={scopeOptions}
                onChange={(e) => handleChangeFilter(e, "effect_for")}
              />
            </div>
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Get Credit
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptCampaign}
                onChange={(e) => handleChangeFilter(e, "get_credit")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="grid grid-cols-2 gap-8 w-full px-10 md:my-0 my-3 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Effect (from)
              </h1>
              <Input
                type={"date"}
                name={"effect_from"}
                handleChange={handleChangeFilter}
              />
            </div>
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Effect (to)
              </h1>
              <Input
                type={"date"}
                name={"effect_to"}
                handleChange={handleChangeFilter}
              />
            </div>
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-center items-center h-1/6">
          <SmallButton
            type={"button"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditEffectFilter;
