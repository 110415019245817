import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { breadcrumbnext, reviewProduct } from "../../../assets/icons";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Toggle from "../../../components/button/Toggle";
import Input from "../../../components/input/Input";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import {
  optRoutesEditProduct,
  optSelectPicture,
  optTypeOptions,
} from "./helpers/list";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import {
  loadOptionsBrand,
  loadOptionsCategory,
  loadOptionsSeason,
} from "../../../utils/loadOption/LoadOption";
import Select from "react-select";
import GetProductDetail from "./helpers/use_query_hooks/GetProductDetail";
import GetProductScrapedCode from "./helpers/use_query_hooks/GetProductScrapedCode";
import EditProductImage from "./components/edit_product/EditProductImage";
import {
  optSelectCurrency,
  optSelectGender,
  optSelectReleaseDate,
  optSelectYearList,
} from "../product_need_review/helpers/list";
import { ActionType } from "../../../store/product/product_depatu/actions";
import ProductDepatuContext from "../../../store/product/product_depatu/ProductDepatuContext";
import ConfirmProductEdit from "./components/edit_product/ConfirmProductEdit";
import AddInformationEdit from "./components/edit_product/AddInformationEdit";
import * as Yup from "yup";

const EditProduct = () => {
  const history = useHistory();
  const params: { id: string; code: string } = useParams();
  const { data: dataDetail, isLoading: loadDetail } = GetProductDetail();
  const { data, isLoading } = GetProductScrapedCode();

  const [date, setDate] = useState({ value: "date", label: "Date" });
  const [source, setSource] = useState<string>("");
  const [index, setIndex] = useState(0);
  const { productDepatuDispatch, productDepatuState } =
    useContext(ProductDepatuContext);
  const {
    dataImageEdit,
    showAddInfoEdit,
    dimensionEdit,
    displayImageEdit,
    showConfirmEdit,
    pictureInit,
  } = productDepatuState;

  useEffect(() => {
    setSource(data?.foreign_products[index]?.source);
  }, [index, data]);

  useEffect(() => {
    if (dataDetail?.release_date !== null) {
      setDate({ value: "date", label: "Date" });
    } else {
      setDate({ value: "season", label: "Season" });
    }
  }, [dataDetail]);

  const handleChangeSource = (opt: string) => {
    if (opt === "+") {
      setIndex(index + 1);
    } else {
      setIndex(index - 1);
    }
  };

  const { values, setValues, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        code: "",
        is_active: true,
        is_active_product_secondary: false,
        is_active_brand_secondary: false,
        is_active_category_secondary: false,
        name: "",
        retail_price: 0,
        retail_price_currency: {
          label: "IDR",
          value: "IDR",
        },
        gender: {
          label: "",
          value: "",
        },
        color: "",
        description: "",
        story: "",
        release_date: "",
        brand: {
          label: "Select...",
          value: "",
        },
        category: {
          label: "",
          value: "",
        },
        type: {
          label: "",
          value: optTypeOptions[0].value,
        },
        season_id: {
          label: "",
          value: "",
        },
        season_year: {
          label: "",
          value: "",
        },
        secondary_brand: [],
        secondary_category: [],
        secondary_code: [],
      },
      validationSchema: Yup.object({
        name: Yup.string().required("Name is required"),
        code: Yup.string().required("Product Code is required"),
        brand: Yup.object().shape({
          value: Yup.string().required("Brand is required"),
        }),
        category: Yup.object().shape({
          value: Yup.string().required("Category is required"),
        }),
        type: Yup.object().shape({
          value: Yup.string().required("Type is required"),
        }),
        gender: Yup.object().shape({
          value: Yup.string().required("Gender is required"),
        }),
        color: Yup.string().required("Color is required"),
        story: Yup.string().required("Description is required"),
        retail_price: Yup.number().required("Retail Price is required"),
      }),
      onSubmit: async () => {
        await productDepatuDispatch({
          type: ActionType.ShowConfirmProductEdit,
        });
      },
    });

  useEffect(() => {
    productDepatuDispatch({
      type: ActionType.SetDefaultDimension,
      payload: dataDetail?.custom_attributes,
    });
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      setValues({
        ...values,
        name: dataDetail?.name,
        code: dataDetail?.code,
        is_active: dataDetail?.is_active,
        brand: {
          label: dataDetail?.product_brand.name,
          value: dataDetail?.product_brand.id,
        },
        category: {
          label: dataDetail?.product_category?.name,
          value: dataDetail?.product_category?.id,
        },
        type: {
          label: optTypeOptions[dataDetail?.package_type_id - 1]?.label,
          value: dataDetail?.package_type_id,
        },

        retail_price: dataDetail?.retail_price,
        retail_price_currency: {
          label: dataDetail?.retail_price_currency,
          value: dataDetail?.retail_price_currency,
        },
        gender: {
          label:
            dataDetail?.gender === 0
              ? "Men"
              : dataDetail?.gender === 1
              ? "Women"
              : dataDetail?.gender === 2
              ? "Youth"
              : dataDetail?.gender === 3
              ? "Infant"
              : dataDetail?.gender === 4
              ? "Indefinable"
              : dataDetail?.gender === 5
              ? "Unisex"
              : "",
          value: dataDetail?.gender,
        },
        color: dataDetail?.color,
        story: dataDetail?.story,
        is_active_brand_secondary:
          dataDetail?.secondary_product_brands?.length > 0 ? true : false,
        secondary_brand: dataDetail?.secondary_product_brands.map(
          (el: any) => ({
            label: el.name,
            value: el.id,
          })
        ),
        is_active_category_secondary:
          dataDetail?.secondary_product_categories?.length > 0 ? true : false,
        secondary_category: dataDetail?.secondary_product_categories.map(
          (el: any) => ({
            label: el.name,
            value: el.id,
          })
        ),
        is_active_product_secondary:
          dataDetail?.secondary_codes?.length > 0 ? true : false,
        secondary_code: dataDetail?.secondary_codes.map((el: any) => ({
          label: el,
          value: el,
        })),

        release_date:
          dataDetail?.release_date !== null
            ? moment(dataDetail?.release_date).format("YYYY-MM-DD")
            : "",
        season_id:
          dataDetail?.season_id !== null
            ? { label: dataDetail?.season?.name, value: dataDetail?.season_id }
            : { label: "", value: "" },
        season_year:
          dataDetail?.season_year !== null
            ? { label: dataDetail?.season_year, value: dataDetail?.season_year }
            : { label: "", value: "" },
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    productDepatuDispatch({
      type: ActionType.HandlePictureInit,
      payload:
        dataDetail?.detail_image_url.length > 0
          ? optSelectPicture[0]
          : optSelectPicture[1],
    });
  }, [dataDetail]);

  const handleCopy = (name: string) => {
    setValues({ ...values, [name]: data.foreign_products[index][name] });
  };

  const handleCodeSeceondary = (newValue: any, actionMeta: any) => {
    setValues({ ...values, secondary_code: newValue });
  };

  const handleChangeDimension = (e: any) => {
    productDepatuDispatch({
      type: ActionType.AddInfoValueEdit,
      payload: e.target.value,
      name: e.target.name,
    });
  };

  const handleSetAllInformation = () => {
    setValues({
      ...values,
      name: data.foreign_products[index].name,
      code: data.foreign_products[index].code.split(" ").join("-"),
      color: data.foreign_products[index].color,
      release_date: data.foreign_products[index].release_date,
      story: data.foreign_products[index].story,
      retail_price_currency: {
        value: data.foreign_products[index].retail_price_currency,
        label: data.foreign_products[index].retail_price_currency,
      },
      retail_price: data.foreign_products[index].retail_price,
    });
  };

  return (
    <div>
      <div className="absolute top-0 z-30">
        <NavDetail
          title1="Edit"
          title2="Product"
          handleBack={() => history.goBack()}
        />
      </div>
      {showAddInfoEdit && <AddInformationEdit />}
      {showConfirmEdit && (
        <ConfirmProductEdit
          displayImage={displayImageEdit}
          data={values}
          dataImage={dataImageEdit}
          dimension={dimensionEdit}
          date={date}
          pictureInit={pictureInit}
        />
      )}
      <DynamicBreadcrumb data={optRoutesEditProduct(params.id, params.code)} />
      {isLoading || loadDetail ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <EditProductImage data={data} dataDetail={dataDetail} />
          <main
            className={
              "grid grid-cols-1 gap-7 my-7 " +
              (data ? "lg:grid-cols-2 " : "lg:grid-cols-1")
            }
          >
            {data && (
              <section className="border rounded-md">
                <div className="flex justify-between px-8 py-6 border-b">
                  <div className="flex">
                    <button
                      onClick={() => handleChangeSource("-")}
                      disabled={
                        data.foreign_products[0].source === source
                          ? true
                          : false
                      }
                      className={
                        "focus:outline-none " +
                        (data.foreign_products[0].source === source
                          ? "cursor-default"
                          : "cursor-pointer")
                      }
                    >
                      <img
                        src={breadcrumbnext}
                        alt="next"
                        className="w-2 transform rotate-180 mr-4"
                      />
                    </button>
                    <h1 className="text-lg uppercase">
                      {source?.split("_")?.join(" ")}
                    </h1>
                    <button
                      onClick={() => handleChangeSource("+")}
                      disabled={
                        data.foreign_products[data.foreign_products.length - 1]
                          .source === source
                          ? true
                          : false
                      }
                      className={
                        "focus:outline-none " +
                        (data.foreign_products[data.foreign_products.length - 1]
                          .source === source
                          ? "cursor-default"
                          : "cursor-pointer")
                      }
                    >
                      <img
                        src={breadcrumbnext}
                        alt="next"
                        className="w-2 ml-4"
                      />
                    </button>
                  </div>
                  <h1
                    onClick={handleSetAllInformation}
                    className="hover:text-primary cursor-pointer text-lg"
                  >
                    Use this Information
                  </h1>
                </div>

                <div className="p-8">
                  <div>
                    <h1 className="text-textPrimary">Product Name</h1>
                    <div className="flex items-center w-full">
                      <div className="w-11/12">
                        <Input
                          name="name"
                          type="text"
                          value={data.foreign_products[index].name}
                          disable
                        />
                      </div>
                      <div
                        onClick={() => handleCopy("name")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Product Code</h1>
                    <div className="flex items-center w-full">
                      <div className="w-11/12">
                        <Input
                          name="code"
                          type="text"
                          value={data.foreign_products[index].code}
                          disable
                        />
                      </div>
                      <div
                        onClick={() =>
                          setValues({
                            ...values,
                            code: data.foreign_products[index].code
                              .split(" ")
                              .join("-"),
                          })
                        }
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary font-rom flex">
                      <h1 className="font-med mr-1">Product Code</h1>{" "}
                      (Secondary)
                    </h1>
                    <div className="flex items-center w-full">
                      <div className="w-11/12">
                        <Input
                          name="secondary_codes"
                          type="text"
                          value={data.foreign_products[index].secondary_codes}
                          disable
                        />
                      </div>
                      <div
                        onClick={() => handleCopy("secondary_codes")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Brand</h1>
                    <div className="flex items-center w-full">
                      <div className="w-full">
                        <Input
                          name="brand"
                          type="text"
                          value={data.foreign_products[index].brand}
                          disable
                        />
                      </div>
                      {/* <div
                        onClick={() => handleCopy("brand")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div> */}
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary font-rom flex">
                      <h1 className="font-med mr-1">Brand</h1> (Secondary)
                    </h1>
                    <div className="flex items-center w-full">
                      <div className="w-full">
                        <Input
                          name="secondary_brands"
                          type="text"
                          value={data.foreign_products[index].secondary_brands}
                          disable
                        />
                      </div>
                      {/* <div
                        onClick={() => handleCopy("secondary_brands")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div> */}
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Category</h1>
                    <div className="flex items-center w-full">
                      <div className="w-full">
                        <Input
                          name="category"
                          type="text"
                          value={data.foreign_products[index].category}
                          disable
                        />
                      </div>
                      {/* <div
                        onClick={() => handleCopy("category")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div> */}
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary font-rom flex">
                      <h1 className="font-med mr-1">Category</h1> (Secondary)
                    </h1>
                    <div className="flex items-center w-full">
                      <div className="w-full">
                        <Input
                          name="secondary_categories"
                          type="text"
                          value={
                            data.foreign_products[index].secondary_categories
                          }
                          disable
                        />
                      </div>
                      {/* <div
                        onClick={() => handleCopy("secondary_categories")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div> */}
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Release Date</h1>
                    <div className="flex items-center w-full">
                      <div className="w-11/12">
                        <Input
                          name="release_date"
                          type="date"
                          value={moment(
                            data.foreign_products[index].release_date
                          ).format("YYYY-MM-DD")}
                          disable
                        />
                      </div>
                      <div
                        onClick={() => handleCopy("release_date")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Retail Price</h1>
                    <div className="flex items-center w-full">
                      <div className="w-11/12">
                        <Input
                          name="retail_price"
                          type="text"
                          value={`${data.foreign_products[index].retail_price_currency} ${data.foreign_products[index].retail_price}`}
                          disable
                        />
                      </div>
                      <div
                        onClick={() => {
                          setValues({
                            ...values,
                            retail_price_currency: {
                              value:
                                data.foreign_products[index]
                                  .retail_price_currency,
                              label:
                                data.foreign_products[index]
                                  .retail_price_currency,
                            },
                            retail_price:
                              data.foreign_products[index].retail_price,
                          });
                        }}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Color</h1>
                    <div className="flex items-center w-full">
                      <div className="w-11/12">
                        <Input
                          name="description"
                          type="text"
                          value={data.foreign_products[index].color}
                          disable
                        />
                      </div>
                      <div
                        onClick={() => handleCopy("color")}
                        className="md:h-10 h-8 w-1/12 border border-l-0 rounded-md rounded-l-none flex justify-center items-center cursor-pointer hover:bg-disableColor"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <h1 className="text-textPrimary">Gender</h1>
                    <div className="flex items-center w-full">
                      <Input
                        name="gender"
                        type="text"
                        value={data.foreign_products[index].gender}
                        disable
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <h1 className="text-textPrimary">Description</h1>
                    <div className="flex items-center w-full border-2 border-stroke rounded-md my-3 h-auto">
                      <div className="w-11/12 flex items-center h-full">
                        <textarea
                          disabled
                          value={data.foreign_products[index].story}
                          placeholder="Place holder"
                          name="story"
                          className="w-full font-rom md:text-sm text-xs px-3 py-2 border-r-2 focus:ring-1 focus:outline-none"
                        ></textarea>
                      </div>

                      <div
                        onClick={() => handleCopy("story")}
                        className="w-1/12 h-full flex justify-center items-center cursor-pointer"
                      >
                        <h1 className="text-textPrimary text-xs">Copy</h1>
                      </div>
                    </div>
                  </div>
                  {/* {ReactHtmlParser(data.foreign_products[index].story)} */}
                </div>
              </section>
            )}
            <section className="border rounded-md">
              <div className="flex justify-between px-8 py-6 border-b">
                <h1 className="text-lg">DEPATU</h1>

                <div className="flex items-center">
                  <h1 className="text-textPrimary mr-2">Posted</h1>
                  <Toggle
                    handleClick={() =>
                      setValues({ ...values, is_active: !values.is_active })
                    }
                    input={values.is_active}
                  />
                </div>
              </div>
              <div className="p-8">
                <div>
                  <h1 className="text-textPrimary">Product Name</h1>
                  <Input
                    name="name"
                    type="text"
                    handleChange={handleChange}
                    value={values.name}
                  />
                </div>

                <div className="my-2">
                  <h1 className="text-textPrimary">Product Code</h1>
                  <Input handleChange={handleChange} name="code" type="text" value={values.code} />
                </div>

                <div className="my-2">
                  <div className="flex text-textPrimary items-center">
                    <div className="flex">
                      <h1 className="font-med mr-1">Product Code</h1>
                      <h1 className="font-rom"> (Secondary)</h1>
                    </div>
                    <div className="ml-3">
                      <Toggle
                        handleClick={() =>
                          setValues({
                            ...values,
                            is_active_product_secondary:
                              !values.is_active_product_secondary,
                          })
                        }
                        input={values.is_active_product_secondary}
                      />
                    </div>
                  </div>
                  <CreatableSelect
                    isDisabled={!values.is_active_product_secondary}
                    name="product_code_seceondary"
                    isMulti
                    value={values.secondary_code}
                    onChange={handleCodeSeceondary}
                    className="font-rom mt-3 pb-3.5 text-sm"
                  />
                </div>

                <div className="my-2">
                  <h1 className="text-textPrimary">Brand</h1>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isSearchable={true}
                    loadOptions={loadOptionsBrand}
                    value={values.brand}
                    onChange={(e: any) => setValues({ ...values, brand: e })}
                    className="font-rom mt-3 pb-3.5 text-sm"
                  />
                </div>
                <div className="my-2">
                  <div className="flex text-textPrimary items-center">
                    <div className="flex">
                      <h1 className="font-med mr-1">Brand</h1>
                      <h1 className="font-rom"> (Secondary)</h1>
                    </div>
                    <div className="ml-3">
                      <Toggle
                        handleClick={() =>
                          setValues({
                            ...values,
                            is_active_brand_secondary:
                              !values.is_active_brand_secondary,
                          })
                        }
                        input={values.is_active_brand_secondary}
                      />
                    </div>
                  </div>
                  <AsyncSelect
                    isDisabled={!values.is_active_brand_secondary}
                    cacheOptions
                    isMulti
                    defaultOptions
                    value={values.secondary_brand}
                    isSearchable={true}
                    loadOptions={loadOptionsBrand}
                    onChange={(e: any) =>
                      setValues({ ...values, secondary_brand: e })
                    }
                    className="font-rom mt-3 pb-3.5 text-sm"
                  />
                </div>
                <div className="my-2">
                  <h1 className="text-textPrimary">Category</h1>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={values.category}
                    isSearchable={true}
                    loadOptions={loadOptionsCategory}
                    onChange={(e: any) => setValues({ ...values, category: e })}
                    className="font-rom mt-3 pb-3.5 text-sm"
                  />
                </div>
                <div className="my-2">
                  <div className="flex text-textPrimary items-center">
                    <div className="flex">
                      <h1 className="font-med mr-1">Category</h1>
                      <h1 className="font-rom"> (Secondary)</h1>
                    </div>
                    <div className="ml-3">
                      <Toggle
                        handleClick={() =>
                          setValues({
                            ...values,
                            is_active_category_secondary:
                              !values.is_active_category_secondary,
                          })
                        }
                        input={values.is_active_category_secondary}
                      />
                    </div>
                  </div>
                  <AsyncSelect
                    isDisabled={!values.is_active_category_secondary}
                    cacheOptions
                    isMulti
                    defaultOptions
                    value={values.secondary_category}
                    isSearchable={true}
                    loadOptions={loadOptionsCategory}
                    onChange={(e: any) =>
                      setValues({ ...values, secondary_category: e })
                    }
                    className="font-rom mt-3 pb-3.5 text-sm"
                  />
                </div>
                <div className="my-2">
                  <h1 className="text-textPrimary">Type</h1>
                  <Select
                    isSearchable={true}
                    options={optTypeOptions}
                    value={values.type}
                    onChange={(e: any) => setValues({ ...values, type: e })}
                    className="font-rom mt-3 mb-3.5 text-sm"
                  />
                  {touched.type && errors.type && (
                    <h1 className="text-primary font-rom -mt-1 text-xs">
                      {errors.type.value}
                    </h1>
                  )}
                </div>
                <div className="my-2">
                  <h1 className="text-textPrimary">Release Date</h1>
                  <div className="flex">
                    <Select
                      options={optSelectReleaseDate}
                      value={date}
                      onChange={(e: any) => setDate(e)}
                      className="font-rom mt-3 mb-3.5 text-sm w-1/4 mr-3"
                    />
                    {date.value === "date" ? (
                      <div className="w-3/4">
                        <Input
                          name="release_date"
                          type="date"
                          handleChange={handleChange}
                          value={moment(values.release_date).format(
                            "YYYY-MM-DD"
                          )}
                        />
                      </div>
                    ) : (
                      <div className="w-3/4 flex">
                        <div className="w-3/4 mr-3">
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            isSearchable={true}
                            value={values.season_id}
                            loadOptions={loadOptionsSeason}
                            onChange={(e: any) =>
                              setValues({ ...values, season_id: e })
                            }
                            className="font-rom mt-3 pb-3.5 text-sm"
                          />
                        </div>
                        <div className="w-1/4">
                          <Select
                            options={optSelectYearList()}
                            value={values.season_year}
                            onChange={(e: any) =>
                              setValues({ ...values, season_year: e })
                            }
                            className="font-rom mt-3 mb-3.5 text-sm "
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="my-2">
                  <h1 className="text-textPrimary">Retail Price</h1>
                  <div className="flex">
                    <Select
                      options={optSelectCurrency}
                      value={values.retail_price_currency}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          retail_price_currency: e,
                        })
                      }
                      className="font-rom mt-3 mb-3.5 text-sm w-1/4 mr-3"
                    />
                    <div className="w-3/4">
                      <Input
                        name="retail_price"
                        type="number"
                        handleChange={handleChange}
                        value={values.retail_price}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  <h1 className="text-textPrimary">Color</h1>
                  <Input
                    name="color"
                    type="text"
                    handleChange={handleChange}
                    value={values.color}
                  />
                </div>

                <div className="my-2">
                  <h1 className="text-textPrimary">Gender</h1>
                  <Select
                    value={values.gender}
                    options={optSelectGender}
                    onChange={(e: any) => setValues({ ...values, gender: e })}
                    className="font-rom mt-3 mb-3.5 text-sm "
                  />
                </div>

                <div className="my-2">
                  <h1 className="text-textPrimary">Description</h1>
                  <textarea
                    onChange={handleChange}
                    value={values.story}
                    placeholder="Place holder"
                    name="story"
                    className="w-full font-rom md:text-sm text-xs px-3 py-2 my-3 border-2 border-stroke rounded-md focus:ring-1 focus:outline-none"
                  ></textarea>
                  {touched.story && errors.story && (
                    <h1 className="text-primary font-rom -mt-2.5 text-xs">
                      {errors.story}
                    </h1>
                  )}
                </div>

                {dimensionEdit?.map((el: any) => (
                  <div className="my-2" key={el.label}>
                    <div className="flex justify-between">
                      <h1 className="text-textPrimary">{el.label}</h1>
                      <h1
                        onClick={() => {
                          productDepatuDispatch({
                            type: ActionType.DeleteInfoEdit,
                            payload: el,
                          });
                        }}
                        className="text-textPrimary hover:text-primary cursor-pointer"
                      >
                        Delete
                      </h1>
                    </div>
                    <Input
                      name={el.label}
                      value={el.value}
                      type="text"
                      handleChange={handleChangeDimension}
                    />
                  </div>
                ))}
                <hr className="mt-4 mb-7" />
                <div className="flex justify-center">
                  <h1
                    onClick={() =>
                      productDepatuDispatch({
                        type: ActionType.ShowAddInfoEdit,
                      })
                    }
                    className="cursor-pointer hover:text-primary text-lg"
                  >
                    Add Information
                  </h1>
                </div>
              </div>
            </section>
          </main>
          <button
            type="submit"
            className="bg-primary hover:bg-opacity-70 focus:outline-none rounded-full shadow-md w-16 h-16 fixed bottom-0 right-0 mx-12 mb-10 flex justify-center items-center pb-1 cursor-pointer"
          >
            <img src={reviewProduct} alt="icon" className="w-1/3" />
          </button>
        </form>
      )}
    </div>
  );
};

export default EditProduct;
