import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetStockPerSize = (productId: number) => {
  const query = useQueries(useLocation);
  const params: { templateId: string } = useParams();
  const { CampaignDispatch } = useContext(CampaignContext);
  const { data, isLoading } = useQuery(
    [
      "GetStockPerSize",
      query.get("product_id"),
      query.get("status"),
      query.get("release_date_from"),
      query.get("release_date_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      productId,
    ],
    () =>
      CampaignRequest.getOnSellingStockPerSize(
        productId,
        params.templateId,
        CampaignDispatch
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetStockPerSize;
