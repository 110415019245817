import { IBuiltinSort, IPagination } from "../../core/helpers/helper";
import {
  EBnibTransactionSortField,
  PBnibTransactionFilter,
} from "../../core/bnib_transaction/entities";
import BnibTransactionRepository from "../../core/bnib_transaction/repository/repository";
import BnibTransactionUsecase from "../../core/bnib_transaction/usecase/usecase";
import moment from "moment";
import ErrorHandler from "../../components/toast/ErrorHandler";

export class BnibTransactionService {
  static getBnibTransactions = async (
    pagination: IPagination,
    filter: PBnibTransactionFilter,
    sorts: IBuiltinSort<EBnibTransactionSortField>[]
  ) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.getBnibTransactions(
        pagination,
        filter,
        sorts
      );
      return bnibTransaction;
    } catch (error) {
      console.log(error);
    }
  };

  static getBnibTransactionCounter = async (filter: PBnibTransactionFilter) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const counter = await bnibTransactionUsecase.getBnibTransactionCounter(
        filter
      );
      return counter;
    } catch (error) {
      console.log(error);
    }
  };

  static getBnibTransaction = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.getBnibTransaction(
        code
      );
      return bnibTransaction;
    } catch (error) {
      console.log(error);
    }
  };

  static getBnibTransactionTrack = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransactionTrack =
        await bnibTransactionUsecase.getBnibTransactionTrack(code);
      return bnibTransactionTrack;
    } catch (error) {
      console.log(error);
    }
  };

  static getBnibTransactionProcess = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransactionProcess =
        await bnibTransactionUsecase.getBnibTransactionProcess(code);
      return bnibTransactionProcess;
    } catch (error) {
      console.log(error);
    }
  };

  static arriveBnibTransaction = async (
    code: string,
    payload: {
      location_dest_name: string;
    }
  ) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const arrivebnibTransaction =
        await bnibTransactionUsecase.arriveBnibTransaction(code, payload);
      return arrivebnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static disputeBnibTransaction = async (
    code: string,
    payload: { reject_reason: string; location_dest_name: string }
  ) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const disputebnibTransaction =
        await bnibTransactionUsecase.disputeBnibTransaction(code, payload);
      return disputebnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static acceptBnibTransaction = async (
    code: string,
    payload: {
      location_dest_name: string;
    }
  ) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const acceptbnibTransaction =
        await bnibTransactionUsecase.acceptBnibTransaction(code, payload);
      return acceptbnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static rejectBnibTransaction = async (
    code: string,
    payload: { reject_reason: string; location_dest_name: string }
  ) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const rejectbnibTransaction =
        await bnibTransactionUsecase.rejectBnibTransaction(code, payload);
      return rejectbnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static defectBnibTransaction = async (
    code: string,
    payload: { is_defect: boolean; defect_images?: Blob[] | any }
  ) => {
    try {
      const formdata = new FormData();
      formdata.append("is_defect", payload.is_defect.toString());
      if (payload?.defect_images && payload?.defect_images?.file?.length > 1) {
        payload?.defect_images?.file?.map((image: any) =>
          formdata.append("defect_images[]", image)
        );
      } else if (payload?.defect_images) {
        formdata.append("defect_images[]", payload?.defect_images);
      }
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const defectbnibTransaction =
        await bnibTransactionUsecase.defectBnibTransaction(code, formdata);
      return defectbnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static readyForLegitCheck = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.readyForLegitCheck(
        code
      );
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static confirmFinalResult = async (
    code: string,
    payload: { confirm_result: string }
  ) => {
    try {
      const newPayload = { result: payload.confirm_result };
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.confirmFinalResult(
        code,
        newPayload
      );
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static readyShippingToBuyer = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.readyShippingToBuyer(
        code
      );
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static sendProductToBuyer = async (
    code: string,
    payload: { pickup_time: string }
  ) => {
    try {
      const newPayload = {
        pickup_time: moment(payload.pickup_time).toISOString(),
      };
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.sendProductToBuyer(
        code,
        newPayload
      );
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static readyShippingToSeller = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction =
        await bnibTransactionUsecase.readyShippingToSeller(code);
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static sendProductToSeller = async (
    code: string,
    payload: { pickup_time: string }
  ) => {
    try {
      const newPayload = {
        pickup_time: moment(payload.pickup_time).toISOString(),
      };
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.sendProductToSeller(
        code,
        newPayload
      );
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static receipt = async (
    code: string,
    payload: { images?: Blob[] | any; external_url?: string }
  ) => {
    try {
      const formdata = new FormData();
      if (payload?.images) {
        payload?.images?.map((image: any) =>
          formdata.append("images[]", image)
        );
      }
      if (payload?.external_url) {
        formdata.append("external_url", payload.external_url);
      }

      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const bnibTransaction = await bnibTransactionUsecase.receipt(
        code,
        formdata
      );
      return bnibTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static transferStock = async (
    code: string,
    payload: {
      location_dest_name: string;
    }
  ) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const transfer = await bnibTransactionUsecase.transferStock(
        code,
        payload
      );
      return transfer;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static printShippingLabel = async (code: string) => {
    try {
      const bnibTransactionRepo = new BnibTransactionRepository();
      const bnibTransactionUsecase = new BnibTransactionUsecase(
        bnibTransactionRepo
      );
      const shippingLabel = await bnibTransactionUsecase.printShippingLabel(
        code
      );
      return shippingLabel;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };
}
