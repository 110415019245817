import { createContext } from "react";
import { IProductReviewContext, IProductReviewState } from "./state";

export const initialProductReviewState: IProductReviewState = {
  showFilter: false,
  showCrawl: false,
  showConfirmProduct: false,
  chip: [],
  dataImage: [],
  displayImage: null,
  showAddInfo: false,
  dimension: [],
  showDelete: {
    status: false,
    id: 0,
  },
  pictureInit: { value: "2d", label: "2D" },
};

const ProductReviewContext = createContext<IProductReviewContext>({
  productReviewState: initialProductReviewState,
  productReviewDispatch: () => undefined,
});

export const ProductReviewContextProvider = ProductReviewContext.Provider;
export const ProductReviewContextConsumer = ProductReviewContext.Consumer;

export default ProductReviewContext;
