import React from "react";
import { ScopeType } from "../../../helpers/list";

interface IProps {
  data: any;
  dataUsedTicket: any;
  title: string;
  onClick?: () => void;
}

const CampaignSideCard: React.FC<IProps> = ({
  data,
  dataUsedTicket,
  title,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="w-1/3 h-screen border-r-2 border-white flex flex-col justify-center items-center"
    >
      <img
        src={
          data.scope !== ScopeType.BASIC
            ? data?.cool_image
            : data?.product_image
        }
        alt="-"
        className="w-44"
      />
      <h1 className="text-white text-center font-med text-2xl mt-8 uppercase">
        {title}
      </h1>
      <h1 className="text-white text-center font-rom text-xl mt-2">
        {data.product_name}
      </h1>
      <section className="mt-8 w-full flex flex-col items-center">
        <div className="flex flex-col items-start rounded-md bg-primary-100 w-2/5 my-3 p-5">
          <h1 className="text-white font-rom">Total Credits:</h1>
          <div className="flex font-rom items-end mt-1">
            <h1 className="text-yellow-300 text-3xl">
              {`${dataUsedTicket.credit_total}`}
            </h1>
            <h1 className="text-white ml-1">Credits</h1>
          </div>
        </div>
        <div className="flex flex-col items-start rounded-md bg-primary-100 w-2/5 my-3 p-5">
          <h1 className="text-white font-rom">Participants:</h1>
          <div className="flex font-rom items-end mt-1">
            <h1 className="text-yellow-300 text-3xl">
              {`${dataUsedTicket.participant_total}`}
            </h1>
            <h1 className="text-white ml-1">Depatuers</h1>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CampaignSideCard;
