import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CreditEffectRequest } from "../../../../apis/credit_effect/CreditEffectRequest";

const GetCreditEffectById = () => {
  const params: { id: string } = useParams();
  const { data, isLoading } = useQuery(
    ["getCreditEffectById"],
    () => CreditEffectRequest.getCreditEffectById(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetCreditEffectById;
