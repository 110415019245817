export enum ActionType {
  ShowFilter,
  HandleChip,
  showDelete,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface ShowDelete {
  type: ActionType.showDelete;
  payload: {
    status: boolean;
    id: number;
  };
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export type CreditEffectAction = ShowFilter | HandleChip | ShowDelete;
