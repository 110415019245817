export enum ActionType {
  ShowFilter,
  HandleChip,
  AddImageEdit,
  AddDisplayImgEdit,
  ShowAddInfoEdit,
  AddInfoEdit,
  AddInfoValueEdit,
  DeleteInfoEdit,
  ShowConfirmProductEdit,
  AddImageAdd,
  AddDisplayImgAdd,
  ShowAddInfoAdd,
  AddInfoAdd,
  AddInfoValueAdd,
  DeleteInfoAdd,
  ShowConfirmProductAdd,
  SetDefaultDimension,
  HandlePictureInit,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface ShowConfirmProductEdit {
  type: ActionType.ShowConfirmProductEdit;
}

export interface ShowAddInfoEdit {
  type: ActionType.ShowAddInfoEdit;
}

export interface DeleteInfoEdit {
  type: ActionType.DeleteInfoEdit;
  payload: string;
}

export interface AddInfoEdit {
  type: ActionType.AddInfoEdit;
  payload: string;
}

export interface AddInfoValueEdit {
  type: ActionType.AddInfoValueEdit;
  payload: string;
  name: string;
}

export interface AddImageEdit {
  type: ActionType.AddImageEdit;
  payload: any[];
}

export interface AddDisplayImgEdit {
  type: ActionType.AddDisplayImgEdit;
  payload: string | null;
}

export interface ShowConfirmProductAdd {
  type: ActionType.ShowConfirmProductAdd;
}

export interface ShowAddInfoAdd {
  type: ActionType.ShowAddInfoAdd;
}

export interface DeleteInfoAdd {
  type: ActionType.DeleteInfoAdd;
  payload: string;
}

export interface AddInfoAdd {
  type: ActionType.AddInfoAdd;
  payload: string;
}

export interface AddInfoValueAdd {
  type: ActionType.AddInfoValueAdd;
  payload: string;
  name: string;
}

export interface AddImageAdd {
  type: ActionType.AddImageAdd;
  payload: any[];
}

export interface AddDisplayImgAdd {
  type: ActionType.AddDisplayImgAdd;
  payload: string | null;
}

export interface SetDefaultDimension {
  type: ActionType.SetDefaultDimension;
  payload: any[];
}

export interface HandlePictureInit {
  type: ActionType.HandlePictureInit;
  payload: any;
}

export type ProductDepatuAction =
  | ShowFilter
  | HandleChip
  | AddImageEdit
  | AddDisplayImgEdit
  | ShowAddInfoEdit
  | AddInfoEdit
  | DeleteInfoEdit
  | AddInfoValueEdit
  | ShowConfirmProductEdit
  | AddImageAdd
  | AddDisplayImgAdd
  | ShowAddInfoAdd
  | AddInfoAdd
  | DeleteInfoAdd
  | AddInfoValueAdd
  | ShowConfirmProductAdd
  | SetDefaultDimension
  | HandlePictureInit;
