import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { BnibBuyOrderService } from "../../apis/bnib_buy_order";
import {
  EBnibBuyOrderSortField,
  EBnibBuyOrderStatus,
} from "../../core/bnib_buy_order/entities";
import { useQueries } from "../../helpers/QuerySearchParams";

const useFetchBnibBuyOrders = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "BnibBuyOrders",
      query.get("sort"),
      query.get("status"),
      query.get("code"),
      query.get("limit"),
      query.get("offset"),
      query.get("date"),
    ],
    () =>
      BnibBuyOrderService.getBnibBuyOrders(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {
          status:
            query.get("status") ||
            `${EBnibBuyOrderStatus.Expired},${EBnibBuyOrderStatus.WaitingPayment},${EBnibBuyOrderStatus.PaymentCancelled}`,
          code: query.get("code") || undefined,
          date: query.get("date") || `${date.startDate},${date.endDate}`,
        },
        [
          {
            field: query.get("sort") || EBnibBuyOrderSortField.newest,
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useFetchBnibBuyOrder = (code: string) => {
  return useQuery(
    [`BnibBuyOrder-${code}`],
    () => BnibBuyOrderService.getBnibBuyOrder(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchBnibBuyOrderById = (id: number) => {
  return useQuery(
    [`BnibBuyOrderbyId-${id}`],
    () => BnibBuyOrderService.getBnibBuyOrderById(id),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchBnibBuyOrderCounter = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(["BnibBuyOrdersCounter", query.get("date")], () =>
    BnibBuyOrderService.getBnibBuyOrderCounter({
      status: `${EBnibBuyOrderStatus.Expired},${EBnibBuyOrderStatus.WaitingPayment},${EBnibBuyOrderStatus.PaymentCancelled}`,
      date: query.get("date") || `${date.startDate},${date.endDate}`,
    })
  );
};

export {
  useFetchBnibBuyOrders,
  useFetchBnibBuyOrder,
  useFetchBnibBuyOrderById,
  useFetchBnibBuyOrderCounter,
};
