import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SendCreditRequest } from "../../apis/send_credit/SendCreditRequest";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import SmallButton from "../../components/button/SmallButton";
import Input from "../../components/input/Input";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import ErrorHandler from "../../components/toast/ErrorHandler";
import LocalStorage from "../../config/LocalStorage";
import { ActionType } from "../../store/send_credit/action";
import SendCreditContext from "../../store/send_credit/SendCreditContext";
import { useQueries } from "../../utils/query_searchParams/QuerySearchParams";
import RevertSendCredit from "./components/RevertSendCredit";
import SendCreditModal from "./components/SendCreditModal";
import { optRoutesSendCreditHistory } from "./helpers/list";
import GetSendCredit from "./helpers/UseHooks/GetSendCredit";

const SendHistory = () => {
  const query = useQueries(useLocation);
  const params: { id: string } = useParams();
  const { isLoading, data } = GetSendCredit();
  const { SendCreditDispatch, SendCreditState } = useContext(SendCreditContext);
  const { showDelete, showSendModal } = SendCreditState;
  const { pathname, search, hash } = useLocation();
  const history = useHistory();
  const [sentFrom, setsentFrom] = useState("");
  const [sentTo, setsentTo] = useState("");
  const [notes, setNotes] = useState<any>({});
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "giver";

  useEffect(() => {
    const from = query.get("sent_date_from");
    const to = query.get("sent_date_to");
    if (from) setsentFrom(from);
    if (to) setsentTo(to);
  }, []);

  useEffect(() => {
    const from = query.get("sent_date_from");
    if (from !== null || sentFrom.length > 0) {
      let new_search =
        from !== null
          ? search.replace(
              `sent_date_from=${from}`,
              `sent_date_from=${sentFrom}`
            )
          : `${search}&&sent_date_from=${sentFrom}`;
      history.push({
        pathname,
        search: new_search,
        hash,
      });
    }
  }, [sentFrom]);

  useEffect(() => {
    const to = query.get("sent_date_to");
    if (to !== null || sentTo.length > 0) {
      let new_search =
        to !== null
          ? search.replace(`sent_date_to=${to}`, `sent_date_to=${sentTo}`)
          : `${search}&&sent_date_to=${sentTo}`;
      history.push({
        pathname,
        search: new_search,
        hash,
      });
    }
  }, [sentTo]);

  useEffect(() => {
    let tempNote: any = {};
    if (data) {
      data?.data?.map(
        (el: { id: number; notes: string }) =>
          (tempNote[el.id] = { id: el.id, notes: el.notes })
      );
      setNotes(tempNote);
    }
  }, [data]);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (note: { id: number; notes: string }) =>
      SendCreditRequest.addNoteHistory(note, role),
    {
      onMutate: () => {
        console.log("mutate add note");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("GetSendCredit");
          ErrorHandler("#00C62E", "Add Note Completed");
        }
      },
    }
  );

  const handleChangeNote = (e: any, id: number) => {
    const new_note = { ...notes };
    new_note[id].notes = e.target.value;
    setNotes(new_note);
  };

  const SaveNote = (note: { id: number; notes: string }) => {
    mutation.mutate(note);
  };

  const goRevert = (id: number) => {
    SendCreditDispatch({
      type: ActionType.ShowDelete,
      payload: { status: true, id },
    });
  };

  return (
    <div className="font-med">
      <section id="top">
        <DynamicBreadcrumb data={optRoutesSendCreditHistory(params.id, role)} />
        {showDelete.status && <RevertSendCredit />}
        {showSendModal && <SendCreditModal />}
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="md:mt-12 mt-10">
              <div className="md:mt-12 mt-10">
                <div className="flex justify-between">
                  <h1 className="text-2xl">Giver</h1>
                  <h1
                    onClick={() =>
                      SendCreditDispatch({ type: ActionType.ShowSendCredit })
                    }
                    className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
                  >
                    Send Credit
                  </h1>
                </div>
                <hr className="my-3" />
                <div className="md:w-1/3 w-full flex mt-5">
                  <div className="w-1/2">
                    <h1 className="md:text-base text-sm">Sent Date (From)</h1>
                    <Input
                      type={"date"}
                      name={"sent_date_from"}
                      placeholder={"Place holder"}
                      value={sentFrom}
                      handleChange={(e: any) => setsentFrom(e.target.value)}
                    />
                  </div>
                  <div className="w-1/2 md:ml-5 ml-3">
                    <h1 className="md:text-base text-sm">Sent Date (to)</h1>
                    <Input
                      type={"date"}
                      name={"sent_date_to"}
                      placeholder={"Place holder"}
                      value={sentTo}
                      handleChange={(e: any) => setsentTo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <section>
              {!data?.data?.length ? (
                <div className="flex justify-center my-20 md:text-lg text-base">
                  <h1>Empty Data</h1>
                </div>
              ) : (
                <>
                  <div className="my-7">
                    {data?.data?.map((el: any, i: number) => (
                      <section>
                        <div className="w-full my-5 items-center px-5 flex justify-between">
                          <div className="w-full">
                            <div className="flex justify-between w-full">
                              <h1 className="md:text-base text-sm">
                                Username : {el.username || "-"}
                              </h1>
                              {!el.is_revert && (
                                <h1
                                  onClick={() => goRevert(el.id)}
                                  className="md:text-base text-sm hover:text-primary cursor-pointer"
                                >
                                  Revert
                                </h1>
                              )}
                            </div>
                            <div className="flex justify-between w-full">
                              <div className="flex">
                                {el.histories.map((credit: any, i: number) => (
                                  <h1
                                    key={i}
                                    className={
                                      "text-sm font-rom mt-1 " +
                                      (i === 0 ? "ml-0" : "ml-1")
                                    }
                                  >
                                    {credit.qty} {credit.campaign_name}{" "}
                                    {i !== el?.histories?.length - 1 && "|"}
                                  </h1>
                                ))}
                              </div>
                              <h1 className="md:text-base text-sm font-nor">
                                {moment(el.created_at).format("ll | hh:mm a")}
                              </h1>
                            </div>

                            <div className="flex items-center w-full">
                              <h1 className="mr-5">Notes:</h1>
                              <div className="w-full flex items-center">
                                <Input
                                  type={"text"}
                                  name={"notes"}
                                  value={
                                    notes[el.id]?.id === el.id
                                      ? notes[el.id]?.notes
                                      : ""
                                  }
                                  handleChange={(e: any) =>
                                    handleChangeNote(e, el.id)
                                  }
                                />
                                <SmallButton
                                  loadColor={"white"}
                                  type={"button"}
                                  isSubmitting={
                                    mutation.variables?.id === el.id &&
                                    mutation.isLoading
                                  }
                                  handleClick={() => SaveNote(notes[el.id])}
                                  color={"bg-primary"}
                                  hoverColor={"opacity-70"}
                                  text={"Save"}
                                  textColor={"text-white"}
                                  height={10}
                                  rounded="rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </section>
                    ))}
                  </div>
                  <Pagination
                    length={data?.data?.length}
                    total={data?.meta?.total}
                    isTable={false}
                  />
                </>
              )}
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default SendHistory;
