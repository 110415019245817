import { ActionType, InvoiceAction } from "./action";
import { IInvoiceState } from "./state";

export const InvoiceReducer = (state: IInvoiceState, action: InvoiceAction) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.ShowTopup:
      let showTopup = action.payload;
      document.body.classList[showTopup.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showTopup };
  }
};
