import { useEffect } from "react";

export default function HandleClickOutside(
  ref: any,
  dispatch: any,
  state: any,
  type: string | any,
  payload?: any
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({
          type: type,
          payload: payload,
        });
        // document.body.classList[state.status ? "add" : "remove"](
        //   "-translate-y-full"
        // );
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, payload, ref, state, type]);
}
