import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { TagsRequest } from "../../../apis/tags/TagsRequest";
import { down } from "../../../assets/icons";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../components/navbar/NavDetail";
import ProductCard from "./EditTagsComponent/ProductCard";
import TagInformation from "./EditTagsComponent/TagInformation";
import Loading from "../../../components/loading/Loading";
import AsyncSelect from "react-select/async";
import { loadOptionsProduct } from "../../../utils/loadOption/LoadOption";
import TagFullCard from "./EditTagsComponent/TagFullCard";
import { useEffect, useState } from "react";
import { optRoutesEdit } from "../helpers/list";
import InfiniteScroll from "react-infinite-scroller";
import axiosCelestine from "../../../config/AxiosCelestine";
import ContactSkeletonLoading from "../../../components/loading/SkeletonLoading";

const EditTags = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const [showFullTag, setShowFullTag] = useState(false);
  const [tempProduct, setTempProduct] = useState("");

  const { data: tagInBanner, isLoading: loadTaginBanner } = useQuery(
    "getTagsinBanner",
    () => TagsRequest.getTagsinBanner("getTagsinBanner", +params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  const { data, isLoading } = useQuery(
    "getTagsBySlug",
    () => TagsRequest.getTagsBySlug("getTagsBySlug", +params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  const getProducts = async () => {
    try {
      const results: any = await axiosCelestine().get(
        `admin/tag/${params.id}/product`,
        {
          params: {
            limit: limit,
            offset: offset,
          },
        }
      );
      const result = await results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data: allDataProduct, isLoading: loadProduct } = useQuery(
    "getProductPerTag",
    () => TagsRequest.getProductPerTag("getProductPerTag", params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  const dataProduct = allDataProduct?.data;
  const [product, setProduct] = useState<any>(dataProduct);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);
  const [more, setMore] = useState<boolean>(true);
  const total = allDataProduct?.meta?.total;

  useEffect(() => {
    setProduct(dataProduct);
  }, [allDataProduct]);

  const fetchMoredata = async () => {
    const new_data = [...product, ...(await getProducts())];
    setProduct(new_data);
  };

  useEffect(() => {
    if (total - product?.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [product?.length]);

  useEffect(() => {
    if (product?.length > limit) {
      setOffset(offset + 10);
    }
  }, [product?.length]);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: any) =>
      TagsRequest.addProductToTags(payload, params.id, setShowFullTag),
    {
      onMutate: () => {
        console.log("mutate");
      },
      onSettled: async (data: any, error: any) => {
        await setOffset(10);
        await queryClient.invalidateQueries("getProductPerTag");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const handleChangeProduct = (payload: any) => {
    setTempProduct(payload);
    mutation.mutate(payload);
  };

  return (
    <div>
      <NavDetail
        title1={"Edit"}
        title2={"Tag"}
        handleBack={() => history.goBack()}
      />
      <div className="md:pt-28 sm:pt-20 pt-16">
        <DynamicBreadcrumb data={optRoutesEdit(params.id)} />

        {isLoading || loadTaginBanner ? (
          <div className="flex justify-center mt-10 w-full">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="w-full h-full mt-14 lg:mb-14 mb-12 md:flex justify-between">
              <TagInformation data={data} dataBanner={tagInBanner} />
            </div>
            <div className="w-full flex justify-center">
              <a
                href="#products"
                className="cursor-pointer hover:text-textPrimary"
              >
                Products
              </a>
              <img src={down} alt="down" className="w-3 mx-1 mt-0.5" />
            </div>
            <div className="mt-20" id="products">
              <h1>Add Product (Product Code (SKU) / Name)</h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsProduct}
                onChange={(payload: any) => handleChangeProduct(payload)}
                className="my-3.5 font-rom text-sm"
              />
            </div>
            {loadProduct ? (
              <div className="flex justify-center my-20">
                <Loading />
              </div>
            ) : (
              <InfiniteScroll
                initialLoad={false}
                loadMore={fetchMoredata}
                hasMore={more}
                loader={<ContactSkeletonLoading />}
              >
                <div className="mt-14 grid md:grid-cols-2 grid-cols-1 md:gap-10 gap-5 my-10">
                  {product?.map((el: any) => (
                    <ProductCard data={el} key={el.id} setOffset={setOffset} />
                  ))}
                </div>
              </InfiniteScroll>
            )}

            {showFullTag && (
              <TagFullCard
                setShowFullTag={setShowFullTag}
                product={tempProduct}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditTags;
