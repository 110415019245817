import { ActionType, CreditEffectAction } from "./action";
import { ICreditEffectState } from "./state";

export const CreditEffectReducer = (
  state: ICreditEffectState,
  action: CreditEffectAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.showDelete:
      let showDelete = action.payload;
      document.body.classList[showDelete.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDelete };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
