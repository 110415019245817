import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import ApplyChip from "../../utils/applyChip/ApplyChip";
import qs from "qs";
import {
  queryparams,
  queryparamsGroupProductSize,
} from "../../pages/on_selling/helpers/list";

export class OnSellingRequest {
  static getOnSelling = async (
    key: string,
    Dispatch: any,
    size?: any,
    seller?: any,
    sellOptions?: any,
    product?: any
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const is_filter = query.get("filter");
      const listSeller = query.get("exclude_seller_id");
      const results = await axiosCelestine().get(
        "admin/bnib-product/on-selling-group-by-product",
        {
          params: {
            scope: "basic",
            ...(query.get("product_id") !== null && {
              product_id: query.get("product_id"),
            }),
            ...(query.get("product") !== null && {
              product_name: query.get("product"),
            }),
            ...(query.get("category") !== null && {
              product_category_id: query.get("category"),
            }),
            ...(query.get("brand") !== null && {
              product_brand_id: query.get("brand"),
            }),
            ...(query.get("pre_order") !== null && {
              pre_order: query.get("pre_order"),
            }),
            ...(query.get("seller_id") !== null && {
              seller_id: query.get("seller_id"),
            }),
            ...(query.get("exclude_seller_id") !== null && {
              exclude_seller_id: listSeller?.split(","),
            }),

            ...(is_filter === "false" && {
              cpsi: size
                .map((el: any) => el.id)
                .filter((e: any) => e !== undefined),
            }),
            ...(is_filter === "false" && {
              cpi: product.filter((e: any) => e !== undefined),
            }),
            ...(is_filter === "false" && {
              csi: seller
                .map((el: any) => el.id)
                .filter((e: any) => e !== undefined),
            }),
            ...(is_filter === "false" && {
              cpo: sellOptions.filter((e: any) => e !== undefined),
            }),

            limit: query.get("limit") !== null && query.get("limit"),
            offset: query.get("offset") !== null && query.get("offset"),
            sort: `${query.get("sort") !== null && query.get("sort")} ${
              query.get("isAsc") !== null && query.get("isAsc")
            }`,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      ApplyChip(queryparams, Dispatch);
      return results;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getOnSellingDetail = async (code: string) => {
    try {
      const results = await axiosCelestine().get(`admin/bnib-product/${code}`);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getOnSellingGroupByProductSize = async (
    product_id: string,
    dispatch: any
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const listSeller = query.get("exclude_seller_id");
      const results = await axiosCelestine().get(
        `admin/bnib-product/${product_id}/on-selling-group-by-product-size`,
        {
          params: {
            scope: "basic",
            ...(query.get("pre_order") !== null && {
              pre_order: query.get("pre_order"),
            }),
            ...(query.get("seller_id") !== null && {
              seller_id: query.get("seller_id"),
            }),
            ...(query.get("exclude_seller_id") !== null && {
              exclude_seller_id: listSeller?.split(","),
            }),

            ...(query.get("selling_time_from") !== null &&
            query.get("selling_time_to") !== null
              ? {
                  created_at: `${query.get("selling_time_from")},${query.get(
                    "selling_time_to"
                  )}`,
                }
              : ""),
            ...(query.get("selling_time_from") !== null &&
            query.get("selling_time_to") === null
              ? { created_at: `${query.get("selling_time_from")},2100-01-01` }
              : ""),
            ...(query.get("selling_time_from") === null &&
            query.get("selling_time_to") !== null
              ? { created_at: `2000-01-01,${query.get("selling_time_to")}` }
              : ""),

            limit: query.get("limit") !== null && query.get("limit"),
            offset: query.get("offset") !== null && query.get("offset"),
            sort: `${query.get("sort") !== null && query.get("sort")} ${
              query.get("isAsc") !== null && query.get("isAsc")
            }`,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      ApplyChip(queryparamsGroupProductSize, dispatch);
      const result = results.data;
      return result;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getAllProductSize = async (
    product_id: string,
    product_size_id: number | string,
    sort: { value: string; isAsc: string },
    limit: number,
    offset: number
  ) => {
    try {
      const results = await axiosCelestine().get(`admin/bnib-product`, {
        params: {
          scope: "basic",
          product_id,
          product_size_id,
          limit,
          offset,
          sort: `${sort.value} ${sort.isAsc}`,
        },
      });
      const result = results.data;
      return result;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getProduct = async (id: string) => {
    try {
      const results = await axiosCelestine().get(`admin/product/${id}`);
      const result = results.data;
      return result;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getSellerUsername = async (product_id?: string) => {
    try {
      const results = await axiosCelestine().get(
        `admin/bnib-product/on-selling-seller`,
        {
          params: {
            limit: 100,
            ...(product_id !== undefined && {
              product_id,
            }),
          },
        }
      );
      const result = results.data.data.map((el: any) => ({
        label: el.username,
        value: el.id,
      }));
      return result;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };
}
