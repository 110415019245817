import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OnBidding from "../../../pages/on_bidding/on_bidding/OnBidding";
import OnBiddingDetail from "../../../pages/on_bidding/on_bidding_detail/OnBiddingDetail";
import OnBiddingSeeAll from "../../../pages/on_bidding/on_bidding_see_all/OnBiddingSeeAll";
import {
  initialOnBiddingState,
  OnBiddingContextProvider,
} from "../../../store/on_bidding/OnBiddingContext";
import { OnBiddingReducer } from "../../../store/on_bidding/OnBiddingReducer";

const OnBiddingRoute = () => {
  const [onBiddingState, onBiddingDispatch] = useReducer(
    OnBiddingReducer,
    initialOnBiddingState
  );
  return (
    <OnBiddingContextProvider value={{ onBiddingState, onBiddingDispatch }}>
      <div className="font-med">
        <Switch>
          <Route path="/marketplace/on-bidding" exact component={OnBidding} />
          <Route
            path="/marketplace/on-bidding/:code"
            exact
            component={OnBiddingDetail}
          />
          <Route
            path="/marketplace/on-bidding/:id/see-all"
            exact
            component={OnBiddingSeeAll}
          />
          <Redirect from="/marketplace" to="/marketplace/on-bidding" exact />
        </Switch>
      </div>
    </OnBiddingContextProvider>
  );
};

export default OnBiddingRoute;
