import { CrossStorageClient } from "cross-storage";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import LocalStorage from "../config/LocalStorage";
import { AcceptedRole } from "../layout/marketplace/interfaces";

const LoginHelper = () => {
  const history = useHistory();
  const storage = new CrossStorageClient(
    process.env.REACT_APP_CROSS_STORAGE_URL || "",
    {}
  );

  const pushNavigate = (token: string, role: string) => {
    const decoded: { id: number } = jwtDecode(token);
    switch (role) {
      case AcceptedRole.Admin:
        setCrossStorage(token, role);
        history.push("/marketplace/product-category-group");
        break;
      case AcceptedRole.Cashier:
        setLocalStorage(token, role);
        history.push("/marketplace/topup");
        break;
      case AcceptedRole.finance_analyst:
        setLocalStorage(token, role);
        history.push("/marketplace/topup");
        break;
      case AcceptedRole.finance_support:
        setLocalStorage(token, role);
        history.push("/marketplace/topup");
        break;
      case AcceptedRole.Spinner:
        setLocalStorage(token, role);
        history.push("/marketplace/campaign");
        break;
      case AcceptedRole.User:
        setLocalStorage(token, role);
        history.push("/marketplace/campaign");
        break;
      case AcceptedRole.Crawler:
        setLocalStorage(token, role);
        history.push("/marketplace/product-depatu");
        break;
      case AcceptedRole.Giver:
        setLocalStorage(token, role);
        history.push(`/marketplace/send-credit/history/${decoded.id}`);
        break;
      case AcceptedRole.Operation:
        setLocalStorage(token, role);
        history.push("/marketplace/product-category-group");
        break;
      case AcceptedRole.Product:
        setLocalStorage(token, role);
        history.push("/marketplace/product-category-group");
        break;
      case AcceptedRole.KeyAccount:
        setLocalStorage(token, role);
        history.push("/marketplace/tags");
        break;
      case AcceptedRole.Marketing:
        setLocalStorage(token, role);
        history.push("/marketplace/product-need-review");
        break;
    }
    return;
  };

  const setCrossStorage = (token: string, role: string) => {
    storage
      .onConnect()
      .then(function () {
        return storage.set("AUTH_TOKEN", token);
      })
      .then(() => {
        storage.set("ROLE", role);
      });
    LocalStorage.AUTH_TOKEN = token;
    LocalStorage.ROLE = role;
  };

  const setLocalStorage = (token: string, role: string) => {
    localStorage.setItem("AUTH_TOKEN", token);
    localStorage.setItem("ROLE", role);
  };

  return {
    pushNavigate,
  };
};

export default LoginHelper;
