import React, { useEffect, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import EditProductCategoryGroup from "./EditProductCategoryGroup";
import { useMutation, useQueryClient } from "react-query";
import { ProductCategoryGroupRequest } from "../../../apis/product_category_group/ProductCategoryGroupRequest";
import ListElementProductCategoryGroup from "../product_category_group_component/ListElementProductCategoryGroup";

const ProductCategoryGroupCards = ({ refetch, dataList }) => {
  const [showEdit, setShowEdit] = useState();
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState(dataList);

  useEffect(() => {
    setDataState(dataList);
  }, [dataList]);

  const handleDrop = (sourceIndex, destinationIndex) => {
    const filterData = dataState[sourceIndex];
    const payload = {
      filterData,
      destinationIndex,
    };
    if (sourceIndex !== destinationIndex) {
      setLoading(true);
      mutation.mutate(payload);
      const prevDataIndex = dataState[sourceIndex];
      let prevData = [...dataState];
      if (sourceIndex < destinationIndex) {
        prevData.splice(destinationIndex + 1, 0, prevDataIndex);
        delete prevData[+sourceIndex];
        setDataState(prevData);
      } else {
        prevData.splice(destinationIndex, 0, prevDataIndex);
        delete prevData[+sourceIndex + 1];
        setDataState(prevData);
      }
    }
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload) =>
      ProductCategoryGroupRequest.editPriorityProductCategoryGroup(
        payload,
        setDataState,
        dataList,
        setLoading
      ),
    {
      onMutate: () => {
        console.log("mutate edit product category");
        setLoading(true);
      },
      onSettled: async (data, error) => {
        await queryClient.invalidateQueries("getProductCtgry");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  return (
    <div className="w-full">
      {loading ? (
        <div className="w-full py-5 h-screen">
          <div className="flex justify-center fixed items-center h-full w-full inset-0 z-50"></div>
          <ListManager
            items={dataState}
            direction="horizontal"
            maxItems={2}
            render={(item) =>
              showEdit === item.id ? (
                <EditProductCategoryGroup
                  showEdit={showEdit}
                  setShowEdit={setShowEdit}
                  refetch={refetch}
                />
              ) : (
                <ListElementProductCategoryGroup
                  item={item}
                  handleShowEdit={setShowEdit}
                />
              )
            }
            onDragEnd={handleDrop}
          />
        </div>
      ) : (
        <ListManager
          items={dataState}
          direction="horizontal"
          maxItems={2}
          render={(item) =>
            showEdit === item.id ? (
              <EditProductCategoryGroup
                showEdit={showEdit}
                setShowEdit={setShowEdit}
                refetch={refetch}
              />
            ) : (
              <ListElementProductCategoryGroup
                item={item}
                handleShowEdit={setShowEdit}
              />
            )
          }
          onDragEnd={handleDrop}
        />
      )}
    </div>
  );
};

export default ProductCategoryGroupCards;
