import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ActionType } from "../../../../store/coupon/action";
import SizingContext from "../../../../store/sizing/SizingContext";

interface IProps {
  data?: any;
}

const SizingCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  // const { sizingDispatch } = useContext(SizingContext);
  return (
    <div className="w-full h-auto border-2 border-stroke rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4 py-5">
        <div className="border-r-2 border-stroke flex flex-col items-start px-8 justify-evenly">
          <h1 className="text-lg font-rom">Product Category</h1>
          <h1 className="text-xl">{data.product_category_name}</h1>
        </div>

        <div className="flex flex-col items-start pl-7 justify-center border-r-2 border-stroke">
          <h1 className="text-lg">Brands</h1>
          <div className="flex items-center">
            <h1 className="text-xl font-rom mt-0.5">
              {data.product_brand_name}
            </h1>
          </div>
        </div>

        <div className="flex flex-col items-start justify-evenly px-10 border-r-2 border-stroke">
          <h1 className="text-lg">Available Gender</h1>
          <h1 className="text-lg font-rom mt-0.5">
            {Object.values(data?.available_genders).join(", ")}
          </h1>
        </div>

        <div className="flex items-center justify-evenly mx-10">
          <h1
            onClick={() =>
              history.push(
                `/marketplace/sizing/edit-sizing/${data.product_category_id}/${
                  data.product_brand_parent_id !== 0
                    ? data.product_brand_parent_id
                    : data.product_brand_id
                }`
              )
            }
            className="text-lg hover:text-primary cursor-pointer"
          >
            Edit
          </h1>

          <h1
            onClick={() =>
              history.push(
                `/marketplace/sizing/see-brand/${data.product_category_id}/${data.product_brand_id}`
              )
            }
            className="text-lg hover:text-primary cursor-pointer"
          >
            See Brands
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SizingCard;
