import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import React from "react";

registerPlugin(
  FilePondPluginImageEdit,
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop
);

export interface FilePondsProps {
  setFiles: any;
  imageType: any[];
  cropRatio: string;
  maxFileSize: string;
  values?: any;
  name?: string;
  allowMultiple?: boolean;
  maxFile?: number;
  file?: any;
}

const NewFilePonds: React.FC<FilePondsProps> = ({
  setFiles,
  imageType,
  cropRatio,
  maxFileSize,
  maxFile = 1,
  allowMultiple = false,
  values,
  name,
}) => {
  return (
    <div className="xl:w-full md:w-10/12 mt-5 mb-7">
      <FilePond
        className="w-full"
        imageValidateSizeLabelImageSizeTooBig={"Image is too big"}
        allowMultiple={allowMultiple}
        maxFiles={maxFile}
        maxFileSize={maxFileSize}
        labelMaxFileSizeExceeded={"File is too large"}
        allowReplace
        checkValidity
        allowImageEdit={true}
        styleImageEditButtonEditItemPosition={"bottom center"}
        allowImagePreview={true}
        allowImageExifOrientation
        imageCropAspectRatio={cropRatio}
        labelFileTypeNotAllowed={"File of invalid type"}
        acceptedFileTypes={imageType}
        onupdatefiles={
          values && name
            ? (fileItems) => {
                if (fileItems.length === 0) {
                  setFiles({ ...values, [name]: "" });
                } else {
                  fileItems.map((fileItem) =>
                    setFiles({ ...values, [name]: fileItem.file })
                  );
                }
              }
            : (fileItems) => {
                if (fileItems.length <= 0) {
                  setFiles(null);
                } else if (fileItems.length > 1) {
                  setFiles({
                    file: fileItems.map((fileItem) => fileItem.file),
                  });
                } else {
                  fileItems.map((fileItem) => setFiles(fileItem.file));
                }
              }
        }
      />
    </div>
  );
};

export default NewFilePonds;
