import React, { useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import Input from "../../../../components/input/Input";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../components/loading/Loading";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesListproductcampaign } from "../../helpers/list";
import GetProductId from "../../helpers/use_query_hook/GetProductById";
import { useFormik } from "formik";
import * as Yup from "yup";
import CircularLoad from "../../../../components/loading/CircularLoad";
import { formatRupiah } from "../../../../utils/format_rupiah/FormatRupiah";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { ActionType } from "../../../../store/campaign/action";
import GetUser from "../../helpers/use_query_hook/GetUser";
import { useMutation } from "react-query";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import ModalSecurityCode from "./sell_product/ModalSecurityCode";

const ListProduct = () => {
  const history = useHistory();
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const location: any = useLocation();
  const campaign_product_template_id =
    location?.state?.campaign_product_template_id;
  const params: { id: string; productId: string } = useParams();
  const { data, isLoading } = GetProductId(campaign_product_template_id);
  const { data: dataUser, isLoading: loaduser } = GetUser();

  const { values, setValues, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        is_simulated: true,
        price: 0,
        campaign_product_template_id: campaign_product_template_id,
        product_size_id: 0,
        coupon_code: "",
      },
      validationSchema: Yup.object({
        product_size_id: Yup.number()
          .min(1, "Please select product size")
          .required("Product size is Required"),
      }),
      onSubmit: async () => {
        const payload: any = {
          ...values,
          coupon_code: "",
          refund_shipping_address_id:
            dataUser?.active_refund_shipping_address_id,
        };
        payload.is_simulated = false;
        mutation.mutate(payload);
      },
    });

  const {
    data: dataSell,
    mutate: mutateSell,
    isLoading: loadSell,
  } = useMutation(() => CampaignRequest.sellProduct(values), {
    onMutate: () => {
      console.log("mutate sell product");
    },
    onSettled: async (data: any, error: any) => {
      if (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (values.product_size_id !== 0) {
      mutateSell();
    }
  }, [values.product_size_id]);

  const mutation = useMutation(
    (payload: any) => CampaignRequest.sellProduct(payload),
    {
      onMutate: () => {
        console.log("mutate sell product");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          CampaignDispatch({
            type: ActionType.ShowModalSecurityCode,
            payload: {
              status: true,
              code: data.code,
            },
          });
        }
      },
    }
  );

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="List"
          title2="This Product"
          handleBack={() => history.goBack()}
        />
      </div>
      {CampaignState.showModalSecurityCode.status && <ModalSecurityCode />}
      <div>
        <DynamicBreadcrumb
          data={optRoutesListproductcampaign(params.id, params.productId)}
        />
        {isLoading || loaduser ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="my-20">
            <aside className="flex my-10">
              <img
                src={data.display_image_url}
                alt="-"
                className="border-2 rounded-md p-4 w-32"
              />
              <div className="ml-10">
                <h1 className="text-primary text-xl">{data.code}</h1>
                <h1 className="text-2xl">{data.name}</h1>
              </div>
            </aside>
            <hr />
            <section className="my-7">
              <h1 className="text-2xl">Select Type</h1>
            </section>
            <hr />
            <section className="my-10 flex">
              <div
                className={
                  "rounded-full w-36 py-4 flex justify-center border bg-primary text-white"
                }
              >
                <h1>Brand New</h1>
              </div>
              <div
                className={
                  "ml-5 rounded-full w-36 py-4 flex justify-center border text-breadscrumbColor"
                }
              >
                <h1>Pre Order</h1>
              </div>
            </section>

            <section className="my-10">
              <h1 className="text-2xl">Select Size</h1>
              <div className="my-7">
                <hr />
              </div>
              <div className="grid grid-cols-6  md:grid-cols-8 lg:grid-cols-12 gap-5">
                {data.product_sizes.map((el: any) => (
                  <div
                    onClick={() =>
                      setValues({ ...values, product_size_id: el.id })
                    }
                    className={
                      "border rounded-md py-6 cursor-pointer flex flex-col items-center justify-center " +
                      (el.id === values.product_size_id
                        ? "border-primary"
                        : "")
                    }
                  >
                    <h1 className="text-primary text-xl">{el.size}</h1>
                    <h1 className="text-xs">{formatRupiah(0)}</h1>
                  </div>
                ))}
              </div>
              {errors.product_size_id && touched.product_size_id && (
                <p className="text-primary text-xs font-rom mt-3 mb-1">
                  {errors.product_size_id}
                </p>
              )}
            </section>

            <section>
              <h1 className="text-2xl">Payment</h1>
              <hr className="mt-7" />
              {loadSell ? (
                <div className="flex justify-center mt-10">
                  <CircularLoad color={"#ed4846"} height={32} width={32} />
                </div>
              ) : (
                <div className="mt-7">
                  <Input
                    name="payment"
                    type={"text"}
                    value={
                      dataSell?.billing_fees[0]?.amount
                        ? formatRupiah(dataSell?.billing_fees[0]?.amount)
                        : "-"
                    }
                    disable
                  />
                </div>
              )}
              <hr className="mt-7" />
              <div className="text-breadscrumbColor text-sm font-rom my-5">
                <h1 className="mt-1">
                  -Payout and Protection Fee will be paid back to you after
                  products is sold and transaction completed within 1x36 hours.
                </h1>
                <h1 className="mt-1">
                  -Make sure to send the product with correct SKU, size and
                  condition. If product sent to DEPATU didn't meet above product
                  condition it will be cancelled without a refund of protection
                  fee.
                </h1>
                <h1 className="mt-1">
                  -Transaction will be cancelled without a refund of protection,
                  if product is authenticated fake or fraud
                </h1>
              </div>
              <hr className="" />
              <div className="flex justify-center mt-7">
                {mutation.isLoading ? (
                  <div className="flex justify-center mt-10">
                    <CircularLoad color={"#ed4846"} height={32} width={32} />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="hover:text-primary cursor-pointer text-2xl focus:outline-none"
                  >
                    Continue
                  </button>
                )}
              </div>
            </section>
          </form>
        )}
      </div>
    </>
  );
};

export default ListProduct;
