import ErrorHandler from "../../components/toast/ErrorHandler";
import { AxiosInstance } from "../../config/AxiosInstance";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import { validateRole } from "../../utils/checkRole/CheckRole";

export class SendCreditRequest {
  static getCredit = async (id: string, role: string) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Pandora().get(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Giver
        }/send-credit`,
        {
          params: {
            giver_id: id,
            ...(query.get("sent_date_from") !== null &&
            query.get("sent_date_to") !== null
              ? {
                  created_at: `${query.get("sent_date_from")},${query.get(
                    "sent_date_to"
                  )}`,
                }
              : ""),
            ...(query.get("sent_date_from") !== null &&
            query.get("sent_date_to") === null
              ? { created_at: `${query.get("sent_date_from")},2100-01-01` }
              : ""),
            ...(query.get("sent_date_from") === null &&
            query.get("sent_date_to") !== null
              ? { created_at: `2000-01-01,${query.get("sent_date_to")}` }
              : ""),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static listGiver = async () => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Pandora().get(
        `admin/giver-permission`,
        {
          params: {
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static sendCredit = async (payload: any, role: string) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Giver
        }/send-credit`,
        payload
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Send Failed");
      }
    }
  };

  static addNoteHistory = async (
    note: { id: number; notes: string },
    role: string
  ) => {
    try {
      const result = await AxiosInstance.Pandora().patch(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Giver
        }/send-credit/${note.id}`,
        {
          notes: note.notes || null,
        }
      );
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Add Notes Failed"
        );
      }
    }
  };

  static revertSendCredit = async (id: number, role: string) => {
    try {
      const result = await AxiosInstance.Pandora().post(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Giver
        }/send-credit/revert/${id}`
      );
      ErrorHandler("#00C62E", "Revert Completed");
      const status = result.status;
      return status;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Revert Failed");
      }
    }
  };
}
