import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { drag } from "../../../../../assets/icons";
import TrackingContext from "../../../../../store/tracking/TrackingContext";
import * as Yup from "yup";
import { TrackingRequest } from "../../../../../apis/tracking/TrackingRequest";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import ErrorHandler from "../../../../../components/toast/ErrorHandler";

interface IProps {
  item: any;
}

const ListElementEdit: React.FC<IProps> = ({ item }) => {
  const { trackingDispatch } = useContext(TrackingContext);

  const goBack = () => {
    trackingDispatch({
      type: "show_edit_step",
      payload: 0,
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      description: item.description,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("description is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TrackingRequest.editDescStep(values, item.id, setSubmitting, goBack),
    {
      onMutate: () => {
        console.log("mutate edit step");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTrackingStep");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );
  return (
    <form onSubmit={handleSubmit} className="flex h-auto my-8">
      <div className="w-5% flex justify-center items-center">
        <h1 className="text-3xl">{item.priority}.</h1>
      </div>
      <div className="border rounded-md w-3/4 h-auto py-4 flex justify-between items-center px-6">
        <textarea
          onChange={handleChange}
          name="description"
          value={values.description}
          className={
            "w-11/12 focus:outline-none pt-1 h-auto border-b " +
            (errors.description && "border-primary")
          }
        />
        {errors.description &&
          touched.description &&
          ErrorHandler("#ed4846", errors.description)}
        <img src={drag} alt="drag" className="w-5" />
      </div>
      <div className="flex justify-evenly items-center w-1/5">
        <h1 onClick={goBack} className="cursor-pointer hover:text-primary">
          Cancel
        </h1>
        {isSubmitting ? (
          <div className="mr-5">
            <CircularLoad color="#A7A7A7" height={20} width={20} />
          </div>
        ) : (
          <button type="submit" className="focus:outline-none mr-5">
            <h1 className="cursor-pointer hover:text-primary">Save</h1>
          </button>
        )}
      </div>
    </form>
  );
};

export default ListElementEdit;
