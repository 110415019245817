import { createContext } from "react";
import { IinitialTrackingState, ITrackingContext } from "./interfaces";

const pathname = document.location.pathname;
const location = document.location.search;
const queryParams = new URLSearchParams(location);
const offset = queryParams.get("offset");
const limit = queryParams.get("limit");
const isAsc = queryParams.get("isAsc");
const sort = queryParams.get("sort");

export const initialTrackingState: IinitialTrackingState = {
  showAdd: false,
  showAddStep: false,
  showEditName: false,
  editStep: 0,
  showDeleteLocation: {
    id: 0,
    status: false,
  },
  showDeleteStep: {
    id: 0,
    status: false,
  },
  isAsc: pathname !== "/marketplace/tracking" ? "desc" : isAsc || "desc",
  sortValue:
    pathname !== "/marketplace/tracking" ? "created_at" : sort || "created_at",
  limit: pathname !== "/marketplace/tracking" ? 10 : Number(limit) || 10,
  offset: pathname !== "/marketplace/tracking" ? 0 : Number(offset) || 0,
};

const TrackingContext = createContext<ITrackingContext>({
  trackingState: initialTrackingState,
  trackingDispatch: () => {},
});

export const TrackingContextProvider = TrackingContext.Provider;
export const TrackingContextConsumer = TrackingContext.Consumer;

export default TrackingContext;
