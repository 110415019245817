import Swal from "sweetalert2";
import "./index.css";

function ErrorHandler(color = "#ed4846", title) {
  const Toast = Swal.mixin({
    background: color,
    toast: true,
    position: "top-end",
    width: "100%",
    showConfirmButton: false,
    timer: 1500,
    customClass: "swall-class",
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    html: "<pre>" + title + "</pre>",
    animation: false,
  });
}

export default ErrorHandler;
