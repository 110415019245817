import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import Toggle from "../../components/button/Toggle";
import CircularLoad from "../../components/loading/CircularLoad";
import NavDetail from "../../components/navbar/NavDetail";
import { optRoutesAddCreditEffect } from "./helpers/list";
import { useTranslation } from "react-i18next";
import Input from "../../components/input/Input";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  loadOptCampaignEndAt,
  loadOptionsBrand,
  loadOptionsProduct,
  loadOptionsProductStore,
  loadOptionsStore,
  loadOptionsTag,
  loadOptPaymentMethod,
  loadOptTicket,
} from "../../utils/loadOption/LoadOption";
import isEmpty from "lodash.isempty";
import { cancel } from "../../assets/icons/index";
import FormControlCreditEffect, { scope } from "./form/FormControlCreditEffect";
import PriceInput from "../../components/input/PriceInput";

const AddCreditEffect = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleReset,
    handleSubmit,
    check,
    setCheck,
    mutation,
    handleChangeChecked,
    handleAddCreditReward,
    handleChangeCampaign,
    handleChangeQuantity,
    handleChangeScope,
    loadTicketVariant,
    variantOpt,
  } = FormControlCreditEffect("Add");

  useEffect(() => {
    if (!isEmpty(values.retail_ticket_bundles)) {
      loadTicketVariant("", values.retail_ticket_bundles.value);
    }
  }, [values.retail_ticket_bundles.value]);

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1={t("creditEffect.formCreditEffect.add")}
          title2={t("creditEffect.formCreditEffect.navbar")}
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb data={optRoutesAddCreditEffect(t)} />
        <form onSubmit={handleSubmit} className="flex justify-between my-14">
          <aside className="md:w-1/3 h-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
              <h1 className="font-medium md:text-lg text-base">
                {t("creditEffect.formCreditEffect.results")}
              </h1>
            </div>
            <hr />

            <div>
              {values?.results?.map((el: any, idx: number) => (
                <section>
                  <div className="flex justify-end">
                    {idx !== 0 && (
                      <div
                        onClick={() =>
                          setValues({
                            ...values,
                            results: values.results.filter(
                              (e: any, idx_target: number) => idx !== idx_target
                            ),
                          })
                        }
                        className="bg-textPrimary bg-opacity-75 hover:bg-opacity-70 cursor-pointer rounded-bl-md p-1.5"
                      >
                        <img
                          src={cancel}
                          className=" cursor-pointer w-3.5"
                          alt="delete_button"
                        />
                      </div>
                    )}
                  </div>
                  <div className={"md:mx-8 mx-5 " + (idx === 0 && "mt-5")}>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.selectCampaign")}
                    </h1>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptCampaignEndAt}
                      onChange={(e: any) => handleChangeCampaign(e, idx)}
                      className="my-3.5 font-rom text-sm"
                    />
                    {/* {errors.results && touched.results && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.results}
                      </p>
                    )} */}
                  </div>
                  <div className="md:mx-8 mx-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.quantity")}
                    </h1>
                    <Input
                      type={"text"}
                      name={"quantity"}
                      handleChange={(e: any) => handleChangeQuantity(e, idx)}
                    />
                    {/* {errors.campaign && touched.campaign && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.campaign}
                      </p>
                    )} */}
                  </div>
                  <div className="mt-5">
                    <hr />
                  </div>
                </section>
              ))}
            </div>
            <div className="flex justify-center w-full my-5">
              <h1
                onClick={handleAddCreditReward}
                className="cursor-pointer hover:text-primary"
              >
                {t("creditEffect.formCreditEffect.addCredit")}
              </h1>
            </div>
          </aside>
          <aside className="md:w-custom h-full md:mt-0">
            <div className="rounded-md rounded-b-none border-2 border-stroke">
              <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
                <h1 className="font-medium md:text-lg text-base">
                  {t("creditEffect.formCreditEffect.creditInfo")}
                </h1>

                <div className="flex">
                  <h1 className="md:text-base text-sm mr-3 text-textPrimary">
                    {t("creditEffect.formCreditEffect.posted")}
                  </h1>
                  <div className="flex justify-between items-center">
                    <Toggle
                      handleClick={() => {
                        setValues({ ...values, is_active: !values.is_active });
                      }}
                      input={values.is_active}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="w-full px-8 py-4">
                <div className="w-full my-2.5">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    {t("creditEffect.formCreditEffect.effectName")}
                  </h1>
                  <Input
                    type={"text"}
                    name={"name"}
                    handleChange={handleChange}
                  />
                  {errors.name && touched.name && (
                    <p className="text-primary text-xs font-rom -mt-2 mb-1">
                      {errors.name}
                    </p>
                  )}
                </div>

                <div className="w-full my-2.5 flex">
                  <div className="w-1/2 mr-8">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.effectFrom")}
                    </h1>

                    <Input
                      type={"datetime-local"}
                      name={"valid_time_from"}
                      handleChange={handleChange}
                    />
                    {errors.valid_time_from && touched.valid_time_from && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.valid_time_from}
                      </p>
                    )}
                  </div>
                  <div className="w-1/2">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.effectTo")}
                    </h1>
                    <Input
                      handleChange={handleChange}
                      type={"datetime-local"}
                      name={"valid_time_until"}
                    />
                    {errors.valid_time_until && touched.valid_time_until && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.valid_time_until}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full my-2.5">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    {t("creditEffect.formCreditEffect.effectFor")}
                  </h1>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="scope"
                      row
                      value={values.scope}
                      onChange={handleChangeScope}
                    >
                      <FormControlLabel
                        value={scope.MARKETPLACE}
                        control={<Radio />}
                        label="Marketplace"
                      />
                      <FormControlLabel
                        value={scope.RETAIL}
                        control={<Radio />}
                        label="Official Store"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="w-full my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    {t("creditEffect.formCreditEffect.selectEffect")}
                  </h1>
                  <section>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={check.min_purchase_active}
                          onChange={(e: any) =>
                            handleChangeChecked(e, check, setCheck)
                          }
                          name="min_purchase_active"
                          style={{ color: "#ed4846" }}
                        />
                      }
                      label={t("creditEffect.formCreditEffect.min_purchase")}
                    />
                    {values.scope === scope.RETAIL && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.min_quantity_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="min_quantity_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t("creditEffect.formCreditEffect.min_quantity")}
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={check.payment_method_active}
                          onChange={(e: any) =>
                            handleChangeChecked(e, check, setCheck)
                          }
                          name="payment_method_active"
                          style={{ color: "#ed4846" }}
                        />
                      }
                      label={t("creditEffect.formCreditEffect.payment_method")}
                    />
                    {values.scope === scope.MARKETPLACE && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.brand_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="brand_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t(
                          "creditEffect.formCreditEffect.market_product_brands"
                        )}
                      />
                    )}
                    {values.scope === scope.MARKETPLACE && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.product_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="product_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t(
                          "creditEffect.formCreditEffect.market_products"
                        )}
                      />
                    )}
                    {values.scope === scope.MARKETPLACE && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.tag_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="tag_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t("creditEffect.formCreditEffect.market_tags")}
                      />
                    )}
                    {values.scope === scope.RETAIL && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.store_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="store_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t("creditEffect.formCreditEffect.retail_shops")}
                      />
                    )}
                    {values.scope === scope.RETAIL && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.retail_product_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="retail_product_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t(
                          "creditEffect.formCreditEffect.retail_products"
                        )}
                      />
                    )}
                    {values.scope === scope.RETAIL && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={check.ticket_active}
                            onChange={(e: any) =>
                              handleChangeChecked(e, check, setCheck)
                            }
                            name="ticket_active"
                            style={{ color: "#ed4846" }}
                          />
                        }
                        label={t(
                          "creditEffect.formCreditEffect.retail_ticket_bundles"
                        )}
                      />
                    )}
                  </section>
                </div>

                <div className="w-full flex">
                  {check.min_purchase_active && (
                    <div
                      className={
                        "mb-2.5 " +
                        (check.min_quantity_active ? "w-1/2 mr-8" : "w-full")
                      }
                    >
                      <h1 className="md:text-base text-sm text-textPrimary">
                        {t("creditEffect.formCreditEffect.min_purchase")}
                      </h1>
                      <PriceInput
                        displayType={"input"}
                        handleChange={handleChange}
                        value={values.min_subtotal}
                        name={"min_subtotal"}
                      />
                      {errors.min_subtotal && touched.min_subtotal && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.min_subtotal}
                        </p>
                      )}
                    </div>
                  )}
                  {check.min_quantity_active && values.scope === scope.RETAIL && (
                    <div
                      className={
                        "mb-2.5 " +
                        (check.min_purchase_active ? "w-1/2" : "w-full")
                      }
                    >
                      <h1 className="md:text-base text-sm text-textPrimary">
                        {t("creditEffect.formCreditEffect.min_quantity")}
                      </h1>
                      <Input
                        type={"text"}
                        name={"min_quantity"}
                        handleChange={handleChange}
                      />
                      {errors.min_quantity && touched.min_quantity && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.min_quantity}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                {check.payment_method_active && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.payment_method")}
                    </h1>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptPaymentMethod}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          payment_methods: e,
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {errors.payment_methods && touched.payment_methods && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.payment_methods}
                      </p>
                    )}
                  </div>
                )}

                {check.brand_active && values.scope === scope.MARKETPLACE && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.market_product_brands")}
                    </h1>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={values.market_product_brands}
                      loadOptions={loadOptionsBrand}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          market_product_brands: e,
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {!isEmpty(values.market_product_brands) && (
                      <div className="grid grid-cols-2 gap-5">
                        {values.market_product_brands.map((el: any) => (
                          <div className="border border-stroke rounded-md flex items-center">
                            <img
                              src={el.img}
                              alt=""
                              className="w-1/6 rounded-l-md"
                            />
                            <h1 className="ml-5 w-4/6">{el.label}</h1>
                            <div className=" w-1/6 self-start flex justify-end">
                              <div
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    retail_shops:
                                      values.market_product_brands.filter(
                                        (e: any) => el.value !== e.value
                                      ),
                                  })
                                }
                                className="bg-textPrimary hover:bg-opacity-70 cursor-pointer rounded-bl-md rounded-tr-md p-1.5"
                              >
                                <img
                                  src={cancel}
                                  className=" cursor-pointer w-3.5"
                                  alt="delete_button"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {errors.market_product_brands &&
                      touched.market_product_brands && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.market_product_brands}
                        </p>
                      )}
                  </div>
                )}

                {check.product_active && values.scope === scope.MARKETPLACE && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.market_products")}
                    </h1>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptionsProduct}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          market_products: e,
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {!isEmpty(values.market_products) && (
                      <div className="grid grid-cols-2 gap-5">
                        {values.market_products.map((el: any) => (
                          <div className="border border-stroke rounded-md flex items-center">
                            <img
                              src={el.img}
                              alt=""
                              className="w-1/6 rounded-l-md"
                            />
                            <h1 className="ml-5 w-4/6">{el.label}</h1>
                            <div className=" w-1/6 self-start flex justify-end">
                              <div
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    retail_shops: values.market_products.filter(
                                      (e: any) => el.value !== e.value
                                    ),
                                  })
                                }
                                className="bg-textPrimary hover:bg-opacity-70 cursor-pointer rounded-bl-md rounded-tr-md p-1.5"
                              >
                                <img
                                  src={cancel}
                                  className=" cursor-pointer w-3.5"
                                  alt="delete_button"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {errors.market_products && touched.market_products && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.market_products}
                      </p>
                    )}
                  </div>
                )}

                {check.tag_active && values.scope === scope.MARKETPLACE && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.market_tags")}
                    </h1>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptionsTag}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          market_tags: e,
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {errors.market_tags && touched.market_tags && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.market_tags}
                      </p>
                    )}
                  </div>
                )}

                {check.store_active && values.scope === scope.RETAIL && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.retail_shops")}
                    </h1>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={values.retail_shops}
                      loadOptions={loadOptionsStore}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          retail_shops: e,
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {!isEmpty(values.retail_shops) && (
                      <div className="grid grid-cols-2 gap-5">
                        {values.retail_shops.map((el: any) => (
                          <div className="border border-stroke rounded-md flex items-center">
                            <img
                              src={el.img}
                              alt=""
                              className="w-1/6 rounded-l-md"
                            />
                            <h1 className="ml-5 w-4/6">{el.label}</h1>
                            <div className=" w-1/6 self-start flex justify-end">
                              <div
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    retail_shops: values.retail_shops.filter(
                                      (e: any) => el.value !== e.value
                                    ),
                                  })
                                }
                                className="bg-textPrimary hover:bg-opacity-70 cursor-pointer rounded-bl-md rounded-tr-md p-1.5"
                              >
                                <img
                                  src={cancel}
                                  className=" cursor-pointer w-3.5"
                                  alt="delete_button"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {errors.retail_shops && touched.retail_shops && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.retail_shops}
                      </p>
                    )}
                  </div>
                )}

                {check.retail_product_active && values.scope === scope.RETAIL && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.retail_products")}
                    </h1>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={values.retail_products}
                      loadOptions={loadOptionsProductStore}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          retail_products: e,
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />

                    {!isEmpty(values.retail_products) && (
                      <div className="grid grid-cols-2 gap-5">
                        {values.retail_products.map((el: any) => (
                          <div className="border border-stroke rounded-md flex items-center">
                            <img
                              src={el.img}
                              alt=""
                              className="w-1/6 rounded-l-md"
                            />
                            <h1 className="ml-5 w-4/6">{el.label}</h1>
                            <div className=" w-1/6 self-start flex justify-end">
                              <div
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    retail_products:
                                      values.retail_products.filter(
                                        (e: any) => el.value !== e.value
                                      ),
                                  })
                                }
                                className="bg-textPrimary hover:bg-opacity-70 cursor-pointer rounded-bl-md rounded-tr-md p-1.5"
                              >
                                <img
                                  src={cancel}
                                  className=" cursor-pointer w-3.5"
                                  alt="delete_button"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {errors.retail_products && touched.retail_products && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.retail_products}
                      </p>
                    )}
                  </div>
                )}

                {check.ticket_active && values.scope === scope.RETAIL && (
                  <div className="w-full mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      {t("creditEffect.formCreditEffect.retail_ticket_bundles")}
                    </h1>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={values.retail_ticket_bundles}
                      loadOptions={loadOptTicket}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          retail_ticket_bundles: e,
                          retail_tickets: [],
                        })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {errors.retail_ticket_bundles &&
                      touched.retail_ticket_bundles && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.retail_ticket_bundles}
                        </p>
                      )}
                  </div>
                )}

                {check.ticket_active &&
                  values.scope === scope.RETAIL &&
                  values.retail_ticket_bundles.value > 0 && (
                    <div className="w-full mb-5">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        {t("creditEffect.formCreditEffect.retail_tickets")}
                      </h1>
                      <Select
                        isMulti
                        value={values.retail_tickets}
                        options={variantOpt}
                        onChange={(e: any) =>
                          setValues({
                            ...values,
                            retail_tickets: e,
                          })
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  )}
              </div>
            </div>

            <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
              <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
                <button
                  type="reset"
                  onClick={() => {
                    handleReset({});
                  }}
                  className="focus:outline-none"
                >
                  <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                    {t("creditEffect.formCreditEffect.clear")}
                  </h1>
                </button>
              </div>
              <div className="w-1/2 flex items-center justify-center">
                <button type="submit" className="focus:outline-none">
                  {mutation.isLoading ? (
                    <CircularLoad color={"black"} height={16} width={16} />
                  ) : (
                    <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                      {t("creditEffect.formCreditEffect.add")}
                    </h1>
                  )}
                </button>
              </div>
            </div>
          </aside>
        </form>
      </div>
    </>
  );
};

export default AddCreditEffect;
