export enum AcceptedRole {
  Admin = "admin",
  Crawler = "crawler",
  Spinner = "spinner",
  User = "user",
  Cashier = "cashier",
  Giver = "giver",
  Operation = "admin__operation",
  Product = "admin__product",
  KeyAccount = "admin__key_account",
  Marketing = "admin__marketing",
  finance_analyst = "admin__finance_analyst",
  finance_support = "admin__finance_support",
}
