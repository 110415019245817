import React, { useEffect, useState } from "react";
import { down, up } from "../../../../../assets/icons";
import { TransactionRequest } from "../../../../../apis/transactions/Transaction";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import moment from "moment";

const TrackingInformation = () => {
  const params: { code: string } = useParams();
  const [dropTracking, setDropTracking] = useState(true);
  const [tagIndex, setTagIndex] = useState(0);

  const { data, isSuccess } = useQuery(
    ["getTrack"],
    () => TransactionRequest.getTrack(params.code),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      for (const key in data) {
        if (data[key].tag === "office-shipping") {
          setTagIndex(+key);
        }
      }
    }
  }, [data]);

  return (
    <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
      <div
        className={
          "rounded-md border-2 border-stroke " +
          (dropTracking ? " rounded-b-none" : "")
        }
      >
        <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-lg text-base">
            Tracking Information
          </h1>

          <div className="flex">
            <img
              src={dropTracking ? up : down}
              alt="img"
              className="w-4 cursor-pointer"
              onClick={() => setDropTracking(!dropTracking)}
            />
          </div>
        </div>
        {dropTracking &&
          isSuccess &&
          data &&
          data[tagIndex].tag === "office-shipping" && <hr className="border" />}

        {dropTracking &&
          isSuccess &&
          data &&
          data[tagIndex].tag === "office-shipping" && (
            <div className="flex w-full px-10 my-5">
              <div className="w-full font-rom flex flex-col items-center text-sm my-5">
                {data?.[tagIndex]?.history?.length > 0 && data[tagIndex]?.history.map((el: any, index: number) => (
                  <div key={index} className="flex flex-col items-start w-full">
                    <div className="flex items-center justify-start w-full">
                      <div className="flex flex-col items-center text-textPrimary">
                        <h1>{moment(el.date_time).format("L")}</h1>
                        <h1>{`(${moment(el.date_time).format("HH:mm")})`}</h1>
                      </div>
                      <div className="">
                        <div
                          className={
                            "w-7 h-7 rounded-full mx-3 " +
                            (data[0]?.history?.length - 1 === index
                              ? "bg-primary"
                              : "bg-gray-300")
                          }
                        ></div>
                      </div>
                      <div>
                        <h1>{el.status}</h1>
                      </div>
                    </div>
                    {data[tagIndex]?.history?.length - 1 !== index && (
                      <div className="border-l-2 ml-24 border-gray-300 h-7"></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default TrackingInformation;
