import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../components/chip/Chip";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import NewSorting from "../../components/sorting/NewSorting";
import LocalStorage from "../../config/LocalStorage";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import { ActionType } from "../../store/coupon/action";
import CouponContext from "../../store/coupon/CouponContext";
import { validateRole } from "../../utils/checkRole/CheckRole";
import CouponCard from "./components/coupon/CouponCard";
import CouponFilter from "./components/coupon/CouponFilter";
import DeleteCouponModal from "./components/coupon/DeleteCouponModal";
import SendCouponModal from "./components/coupon/SendCouponModal";
import {
  optRoutesCoupon,
  optSortingCoupon,
  queryparamsCoupon,
} from "./helpers/list";
import GetCoupon from "./helpers/use_query_hook/GetCoupon";

const Coupon = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const history = useHistory();
  const { couponDispatch, couponState } = useContext(CouponContext);
  const { showFilter, showSendCoupon, showDelete } = couponState;
  const { data, isFetching, isLoading } = GetCoupon();

  return (
    <div>
      {showFilter && <CouponFilter />}
      {showSendCoupon && <SendCouponModal />}
      {showDelete.status && <DeleteCouponModal />}
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesCoupon} />
        <div className="md:mt-12 mt-10">
          {validateRole(role, [
            AcceptedRole.Admin,
            AcceptedRole.Operation,
            AcceptedRole.KeyAccount,
            AcceptedRole.Marketing,
          ]) && (
            <div className="flex justify-end">
              <h1
                onClick={() =>
                  couponDispatch({
                    type: ActionType.ShowSendCoupon,
                  })
                }
                className="hover:text-primary md:text-base cursor-pointer inline-flex "
              >
                Send Coupon
              </h1>
              <h1
                onClick={() => history.push(`/marketplace/coupon/add-coupon`)}
                className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
              >
                Add Coupon
              </h1>
            </div>
          )}
          <div className="flex justify-between mt-2">
            <div className="flex">
              <h1
                onClick={() =>
                  couponDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingCoupon} />
          </div>

          <Chip
            dispatchContext={couponDispatch}
            stateContext={couponState}
            queryParams={queryparamsCoupon}
            ActionType={ActionType}
          />
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            {data?.data.map((el: any) => (
              <CouponCard data={el} key={el.id} />
            ))}
          </div>
        )}

        <Pagination
          length={data?.data?.length}
          total={data?.meta?.total}
          isTable={false}
        />
      </section>
    </div>
  );
};

export default Coupon;
