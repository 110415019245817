import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { CouponRequest } from "../../../../apis/coupon/CouponRequest";
import CouponContext from "../../../../store/coupon/CouponContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetCoupon = () => {
  const query = useQueries(useLocation);
  const {couponDispatch} = useContext(CouponContext)
  const { data, isLoading, isFetching } = useQuery(
    [
      "getCoupon",
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("display_name"),
      query.get("for_reward"),
      query.get("scopes"),
    ],
    () => CouponRequest.getCoupon(couponDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetCoupon;
