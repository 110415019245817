import moment from "moment";
import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import LocalStorage from "../../config/LocalStorage";
import { queryparamsProductReview } from "../../pages/product/product_need_review/helpers/list";
import ApplyChipTS from "../../utils/applyChip/ApplyChipTS";
import { ActionType } from "../../store/product/product_need_review/actions";
export class ProductScrapingRequest {
  static getProductScraped = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const role = LocalStorage.ROLE;
      const results = await axiosCelestine().get(
        `${role === "admin" ? "admin" : "crawler"}/scraped-product`,
        {
          params: {
            ...(query.get("scrap_batch_id") !== null && {
              scrap_batch_id: query.get("scrap_batch_id"),
            }),
            ...(query.get("code") !== null && {
              code: query.get("code"),
            }),
            ...(query.get("need_review") !== null && {
              need_review: query.get("need_review"),
            }),
            ...(query.get("source") !== null && {
              "foreign_products.source": query.get("source"),
            }),
            ...(query.get("status") !== null && {
              matched_product_id__isnull: query.get("status"),
            }),
            ...(query.get("crawl_time_from") !== null &&
            query.get("crawl_time_to") !== null
              ? {
                  created_at: `${query.get("crawl_time_from")},${query.get(
                    "crawl_time_to"
                  )}`,
                }
              : ""),
            ...(query.get("crawl_time_from") !== null &&
            query.get("crawl_time_to") === null
              ? { created_at: `${query.get("crawl_time_from")},2100-01-01` }
              : ""),
            ...(query.get("crawl_time_from") === null &&
            query.get("crawl_time_to") !== null
              ? { created_at: `2000-01-01,${query.get("crawl_time_to")}` }
              : ""),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChipTS(queryparamsProductReview, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getProductScrapedDetail = async (id: string) => {
    try {
      const role = LocalStorage.ROLE;
      const results = await axiosCelestine().get(
        `${role === "admin" ? "admin" : "crawler"}/scraped-product/${id}`
      );
      const result = results.data.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static uploadImage = async (payload: any) => {
    try {
      const role = LocalStorage.ROLE;
      const results = await axiosCelestine().post(
        `${
          role === "admin" ? "admin" : "crawler"
        }/product/6123b9d82413a49eccc750c2/upload-image`,
        payload
      );
      const result = results.data;
      ErrorHandler("#00C62E", "Upload Success");
      const removeFile: any = document.getElementsByClassName(
        "filepond--file-action-button filepond--action-remove-item"
      );
      removeFile[0]?.click();
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static uploadDetailImage = async (payload: any) => {
    try {
      const role = LocalStorage.ROLE;
      const results = await axiosCelestine().post(
        `/${
          role === "admin" ? "admin" : "crawler"
        }/product/6123b9d82413a49eccc750c2/upload-image`,
        payload
      );
      const result = results.data;
      ErrorHandler("#00C62E", "Upload Success");
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addProduct = async (
    payload: any,
    displayImage: string | null,
    dataImage: any[],
    dimension: any,
    date: any,
    history: any,
    handleShowConfirm?: any,
    pictureInit?: any
  ) => {
    try {
      const role = LocalStorage.ROLE;
      const data: any = {
        name: payload.name,
        code: payload.code,
        is_active: payload.is_active,
        story: payload.story,
        gender: Number(payload.gender.value),
        color: payload.color,
        retail_price_currency: payload.retail_price_currency.value,
        retail_price: payload.retail_price,
        product_brand_id: payload.brand.value,
        product_category_id: payload.category.value,
        package_type_id: payload.type.value,

        display_image_url: displayImage,
        original_display_image_url: displayImage,
      };
      if (pictureInit.value === "2d") {
        data["detail_image_urls"] = dataImage;
      } else {
        data["carousel_image_urls"] = dataImage;
      }
      if (dimension.length > 0) {
        data["custom_attributes"] = dimension;
      }
      if (payload.is_active_brand_secondary) {
        const secondary_product_brand_ids = payload.secondary_brand.map(
          (el: any) => el.value
        );
        data["secondary_product_brand_ids"] = secondary_product_brand_ids;
      }
      if (payload.is_active_category_secondary) {
        const secondary_product_category_ids = payload.secondary_category.map(
          (el: any) => el.value
        );
        data["secondary_product_category_ids"] = secondary_product_category_ids;
      }
      if (payload.is_active_product_secondary) {
        const secondary_code_ids = payload.secondary_code.map(
          (el: any) => el.value
        );
        data["secondary_code_ids"] = secondary_code_ids;
      }
      if (date.value === "date") {
        data["release_date"] = moment(payload.release_date).format("");
      } else if (date.value === "season") {
        data["season_id"] = payload.season_id.value;
        data["season_year"] = Number(payload.season_year.value);
      }
      const results = await axiosCelestine().post(
        `${role === "admin" ? "admin" : "crawler"}/product`,
        data
      );
      const result = results.data;
      ErrorHandler("#00C62E", "Add Completed");
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getStatusScraping = async () => {
    try {
      const role = LocalStorage.ROLE;
      const results = await axiosCelestine().get(
        `${role === "admin" ? "admin" : "crawler"}/scraped-product/scrap-status`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static crawlProduct = async (payload: any, setSubmitting: any) => {
    try {
      const role = LocalStorage.ROLE;
      setSubmitting(true);
      const results = await axiosCelestine().post(
        `${role === "admin" ? "admin" : "crawler"}/scraped-product/new-scrap`,
        payload
      );
      const result = results.data;
      setSubmitting(false);
      return result;
    } catch (error: any) {
      setSubmitting(false);
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static deleteProduct = async (id: number, goBack: () => void) => {
    try {
      const role = LocalStorage.ROLE;
      await axiosCelestine().delete(
        `${role === "admin" ? "admin" : "crawler"}/scraped-product/${id}`
      );
      ErrorHandler("#00C62E", "Delete Completed");
      goBack();
    } catch (error: any) {
      ErrorHandler("#ed4846", "Delete Failed");
    }
  };
}
