export enum ActionType {
  ShowSidebar,
  ShowTransactionList,
  ShowProductList,
  ShowInvoiceList,
}

export interface ShowSidebar {
  type: ActionType.ShowSidebar;
  payload: boolean;
}

export interface ShowTransactionList {
  type: ActionType.ShowTransactionList;
}
export interface ShowProductList {
  type: ActionType.ShowProductList;
}

export interface ShowInvoiceList {
  type: ActionType.ShowInvoiceList;
}

export type NavbarAction =
  | ShowSidebar
  | ShowInvoiceList
  | ShowProductList
  | ShowTransactionList;
