export const optRoutesWaitingInvoice = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/invoice/waiting-invoice",
    name: "Waiting Invoice",
  },
];

export const optRoutesInvoiceHistory = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/invoice/invoice-history",
    name: "Invoice History",
  },
];

export const optRoutesWaitingInvoiceDetail = (id: string, userId: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/invoice/waiting-invoice",
      name: "Waiting Invoice",
    },
    {
      id: 3,
      path: `/marketplace/invoice/waiting-invoice/${id}/${userId}`,
      name: "Details",
    },
  ];
};

export const optRoutesInvoiceHistoryDetail = (id: string, userId: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/invoice/invoice-history",
      name: "Invoice History",
    },
    {
      id: 3,
      path: `/marketplace/invoice/invoice-history/${id}/${userId}`,
      name: "Details",
    },
  ];
};

export const queryParamsWaitingInvoice: any[] = [
  { real_name: "username", show_name: "username" },
  { real_name: "code", show_name: "code" },
];
