import { Redirect, Route } from "react-router-dom";
import LocalStorage from "../../config/LocalStorage";

const LoginGuard = ({ component: Component, ...rest }) => {
  let value = LocalStorage.AUTH_TOKEN || localStorage.getItem("AUTH_TOKEN");
  return (
    <Route
      {...rest}
      render={(props) =>
        value ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

export default LoginGuard;
