import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SingleValue } from "react-select";
import Abutton from "../../components/atoms/Abutton";
import AdateRangePicker, {
  checkLabelColor,
} from "../../components/atoms/AdateRangePicker";
import AfilterDateInput from "../../components/atoms/AfilterDateInput";
import CircularLoad from "../../components/atoms/loading/CircularLoad";
import Pagination from "../../components/atoms/pagination/Pagination";
import Loading from "../../components/loading/Loading";
import MbnibTransactionCard from "../../components/molecules/MbnibTransactionCard";
import MfilterGeneral from "../../components/molecules/MfilterGeneral";
import MfilterStatus from "../../components/molecules/MfilterStatus";
import OtrackProduct from "../../components/organisms/bnib_transaction/OtrackProduct";
import {
  IBnibTransaction,
  listFilter,
} from "../../core/bnib_transaction/entities";
import { listSort } from "../../core/retail_transaction/entities";
import { formatDate } from "../../helpers/common";
import {
  applyDateFilter,
  handleChangeSort,
  handleSearchCode,
  resetDateFilter,
} from "../../helpers/helper";
import { useQueries } from "../../helpers/QuerySearchParams";
import { UseOutsideClick } from "../../helpers/useOutsideClick";
import {
  useFetchBnibTransactionCounter,
  useFetchBnibTransactions,
} from "../../hooks/bnib_transaction";
import { ActionType } from "../../store/transaction/action";
import TransactionContext from "../../store/transaction/TransactionContext";
import { defineds } from "../../utils/date";
import generateCSV from "../../utils/generateCSV";
import { getBnibTransaction } from "./helpers/getBnibTransaction";

const Transaction = () => {
  const { TransactionState, TransactionDispatch } =
    useContext(TransactionContext);
  const { showFilter, openTrackDialog } = TransactionState;
  const query = useQueries(useLocation);
  const { search } = useLocation();
  const navigate = useHistory();
  const filterRef = useRef(null);

  const [load, setLoad] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(
        query.get("date")?.split(",")[0] || formatDate(defineds.startOfMonth)
      ),
      endDate: new Date(
        query.get("date")?.split(",")[1] || formatDate(defineds.endOfMonth)
      ),
      key: "selection",
    },
  ]);
  const [sort, setSort] = useState<
    SingleValue<{ label: string; value: string }>
  >(listSort[0]);
  const [searchCode, setSearchCode] = useState("");

  const { data, isLoading, isError } = useFetchBnibTransactions({
    startDate: formatDate(defineds.startOfMonth),
    endDate: formatDate(defineds.endOfMonth),
  });
  const { data: dataCounter, isLoading: loadCounter } =
    useFetchBnibTransactionCounter({
      startDate: formatDate(defineds.startOfMonth),
      endDate: formatDate(defineds.endOfMonth),
    });

  const handleShowFilter = () => {
    if (showFilter) {
      setTimeout(() => {
        TransactionDispatch({
          type: ActionType.ShowFilter,
        });
      }, 300);
    } else {
      TransactionDispatch({
        type: ActionType.ShowFilter,
      });
    }
  };

  UseOutsideClick(filterRef, handleShowFilter);

  useEffect(() => {
    if (showFilter) {
      checkLabelColor(date);
    }
  }, [date, showFilter]);

  const exportToCsv = async () => {
    let offset = 0;
    let limit = data && data?.[1]?.total < 300 ? data?.[1].total : 300;
    const datacsv: IBnibTransaction[] = [];
    while (data && offset < data?.[1].total) {
      const data = [
        ...(await getBnibTransaction(
          limit,
          offset,
          setLoad,
          date,
          query.get("status") || listFilter[0].status.toString()
        )),
      ];
      await datacsv.push(...data);
      if (!load) {
        offset += limit;
      }
    }
    generateCSV(
      datacsv,
      `transaction-order-(${formatDate(
        date[0].startDate,
        "DD/MM/YYYY"
      )}-${formatDate(date[0].endDate, "DD/MM/YYYY")})`
    );
  };

  if (isError) {
    return <h1>Something went wrong! Please refresh the page</h1>;
  } else if (load) {
    return (
      <div className="z-50 h-full w-full overflow-hidden flex justify-center items-center fixed">
        <CircularLoad height={50} width={50} />
      </div>
    );
  }

  return (
    <main className="w-full sm:pt-28 pt-16">
      {openTrackDialog.status && <OtrackProduct />}
      <section>
        <aside>
          <article>
            <h1 className="font-Bold sm:text-base text-sm">Transaction</h1>
          </article>

          <article className="flex justify-between sm:gap-0 gap-3 items-center mt-2">
            <AfilterDateInput
              showFilter={showFilter}
              handleShowFilter={handleShowFilter}
              date={date}
              handleReset={() => resetDateFilter({ navigate, search, setDate })}
              applyFilter={() => applyDateFilter({ date, navigate, search })}
            />
            <div className="">
              <Abutton
                onClick={exportToCsv}
                title="Export CSV"
                theme="secondary"
              />
            </div>
          </article>
          {showFilter && (
             <section className="relative sm:top-11 top-7">
              <div ref={filterRef} className="absolute z-50 my-3 sm:-mt-12 -mt-8">
                <AdateRangePicker
                  date={date}
                  handleChangeDate={(item) => setDate([item.selection])}
                />
              </div>
            </section>
          )}
        </aside>
      </section>

      <section>
        <section className="bg-white sm:rounded-lg sm:shadow-md w-auto top-12 pt-5 sticky mt-5">
          <aside className="flex overflow-x-auto">
            {listFilter.map((el, idx) => (
              <MfilterStatus
                key={idx}
                item={el}
                counter={dataCounter || []}
                listFilter={listFilter}
              />
            ))}
          </aside>
          <MfilterGeneral
            sort={sort}
            handleChangeSort={(
              sort: SingleValue<{ label: string; value: string }>
            ) => handleChangeSort({ navigate, search, setSort, sort })}
            listSort={listSort}
            search={searchCode}
            setSearch={setSearchCode}
            handleSearch={() =>
              handleSearchCode({ search, searchCode, navigate })
            }
          />
        </section>

        {isLoading || loadCounter ? (
          <div className="w-full flex justify-center mt-20">
            <Loading />
          </div>
        ) : data?.[0] && data?.[0].length > 0 ? (
          <section className="sm:mt-5 mt-3 pb-10">
            <div className="flex flex-col gap-y-5">
              {data?.[0].map((item: IBnibTransaction, idx) => (
                <MbnibTransactionCard key={idx} data={item} />
              ))}
            </div>
            <div className="my-5">
              <Pagination
                length={data?.[0].length || 0}
                total={data?.[1].total || 0}
                isTable={false}
              />
            </div>
          </section>
        ) : (
          <div className="flex w-full justify-center sm:my-20 my-10 text-textPrimary sm:text-base text-xs">
            Data Not Found.
          </div>
        )}
      </section>
    </main>
  );
};

export default Transaction;
