import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import { bgDepatu } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import db from "../../../../../config/InitFirebase";
import isEmpty from "lodash.isempty";
import LocalStorage from "../../../../../config/LocalStorage";
import CampaignSideCard from "./CampaignSideCard";
import GetUsedTicket from "../../../helpers/use_query_hook/GetUsedTicket";

const WinnerPage = () => {
  const history = useHistory();
  const params: {
    id: string;
    templateId: string;
    winner: string;
    totalWinner: string;
  } = useParams();
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const { data_spinner } = CampaignState;
  const [load, setLoad] = useState(false);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const { data: dataUsedTicket, isLoading: loadUsedTicket } = GetUsedTicket();
  const getSnapshot = () => {
    setLoad(true);
    onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === +params.templateId &&
              doc.campaign_id === +params.id
          )[0];
        CampaignDispatch({
          type: ActionType.AddDataSpinner,
          payload: data,
        });
        setLoad(false);
        return;
      }
    );
  };

  useEffect(() => {
    getSnapshot();
  }, []);

  const mutation = useMutation(
    () => CampaignRequest.reshuffle(Number(params.templateId), role),
    {
      onMutate: () => {
        console.log("mutate add shuffle");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data && data_spinner.batch_count > 0) {
          history.push(
            `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${
              params.templateId
            }/shuffle/${params.totalWinner}/${Number(params.winner) + 1}`
          );
        }
      },
    }
  );

  const goToSummary = () => {
    history.push(
      `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${params.templateId}/summary/${params.totalWinner}/${params.winner}`
    );
  };

  return (
    <div className="absolute overflow-hidden top-0 left-0 z-30 bg-black w-screen h-screen flex">
      {isLoading || load || loadUsedTicket ? (
        <div className="flex justify-center w-full mt-20">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <>
          <CampaignSideCard
            data={data}
            dataUsedTicket={dataUsedTicket}
            title="GIVEAWAY"
          />
          <div className="w-2/3 flex justify-center items-center text-white py-10">
            <section className="absolute flex justify-start items-end bottom-0 w-2/3 h-full top-20">
              <img src={bgDepatu} alt="-" />
            </section>
            {!isEmpty(data_spinner) && (
              <div className="flex flex-col items-center justify-center z-40 w-10/12">
                <h1 className="text-white font-med text-4xl mt-8">WINNER</h1>
                <h1 className="text-white font-rom text-3xl mt-2">
                  {data.product_name}
                </h1>
                <div className="my-10 border border-yellow-400 w-1/3 h-28 flex justify-center items-center">
                  <h1 text-white text-2xl>
                    {data_spinner?.winners[params.winner]?.winner_username}
                  </h1>
                </div>
                <div className="flex w-full justify-evenly">
                  <SmallButton
                    text={
                      Number(params.winner) === Number(params.totalWinner) - 1
                        ? "Summary"
                        : "Next"
                    }
                    isSubmitting={mutation.isLoading}
                    height={14}
                    width={"44"}
                    type={"button"}
                    handleClick={
                      Number(params.winner) === Number(params.totalWinner) - 1
                        ? goToSummary()
                        : () => mutation.mutate()
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WinnerPage;
