import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";

const GetUsedTicket = () => {
  const params: { templateId: string } = useParams();
  const { data, isLoading } = useQuery(
    ["GetUsedTicket", params.templateId],
    () => CampaignRequest.getUsedTicket(params.templateId),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetUsedTicket;
