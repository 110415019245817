import { ListManager } from "react-beautiful-dnd-grid";
import React, { useContext, useEffect, useState } from "react";
import ListElement from "./form_edit_step_component/ListElement";
import { TrackingRequest } from "../../../../apis/tracking/TrackingRequest";
import { useMutation, useQueryClient } from "react-query";
import TrackingContext from "../../../../store/tracking/TrackingContext";
import ListElementEdit from "./form_edit_step_component/ListElementEdit";

interface IProps {
  data: any;
}

const FormEditStep: React.FC<IProps> = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const { trackingState } = useContext(TrackingContext);
  const { editStep } = trackingState;
  const [dataOfStep, setDataOfStep] = useState<any>(data);

  useEffect(() => {
    setDataOfStep(data);
  }, [data]);

  const handleDrop = (
    sourceIndex: string | number,
    destinationIndex: number
  ) => {
    const filterData = dataOfStep[sourceIndex];
    const payload = {
      filterData,
      destinationIndex,
    };
    if (sourceIndex !== destinationIndex) {
      setLoading(true);
      mutation.mutate(payload);
      const prevDataIndex = dataOfStep[sourceIndex];
      let prevData = [...dataOfStep];
      if (sourceIndex < destinationIndex) {
        prevData.splice(destinationIndex + 1, 0, prevDataIndex);
        delete prevData[+sourceIndex];
        setDataOfStep(prevData);
      } else {
        prevData.splice(destinationIndex, 0, prevDataIndex);
        delete prevData[+sourceIndex + 1];
        setDataOfStep(prevData);
      }
    }
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: any) =>
      TrackingRequest.editPriorityStep(
        payload,
        setDataOfStep,
        data,
        setLoading
      ),
    {
      onMutate: () => {
        console.log("mutate edit tracking step");
        setLoading(true);
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTrackingStep");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  return (
    <div className="w-full py-5">
      {loading ? (
        <div className="w-full py-5 h-screen">
          <div className="flex justify-center fixed items-center h-full w-full inset-0 z-50"></div>
          <ListManager
            items={dataOfStep}
            direction="vertical"
            maxItems={1}
            render={(item) =>
              editStep === item.id ? (
                <ListElementEdit item={item} />
              ) : (
                <ListElement item={item} />
              )
            }
            onDragEnd={handleDrop}
          />
        </div>
      ) : (
        <ListManager
          items={dataOfStep}
          direction="vertical"
          maxItems={1}
          render={(item) =>
            editStep === item.id ? (
              <ListElementEdit item={item} />
            ) : (
              <ListElement item={item} />
            )
          }
          onDragEnd={handleDrop}
        />
      )}
    </div>
  );
};

export default FormEditStep;
