export const optRoutesCoupon = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/coupon",
    name: "Coupon",
  },
];

export const optRoutesAddCoupon = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/coupon",
    name: "Coupon",
  },
  {
    id: 3,
    path: "/marketplace/coupon/add-coupon",
    name: "Add Coupon",
  },
];

export const optRouteEditCoupon = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/coupon",
      name: "Coupon",
    },
    {
      id: 3,
      path: `/marketplace/coupon/edit-coupon/${id}`,
      name: "Edit Coupon",
    },
  ];
};

export const optSortingCoupon = [
  {
    value: "created_at",
    name: "Date",
  },
];


export const queryparamsCoupon: any[] = [
  { real_name: "display_name", show_name: "coupon_name" },
  { real_name: "for_reward", show_name: "coupon_type" },
  { real_name: "scopes", show_name: "coupon_for" },
  // { real_name: "add_time_from", show_name: "add_time_from" },
  // { real_name: "add_time_to", show_name: "add_time_to" },
  // { real_name: "release_date_from", show_name: "release_date_from" },
  // { real_name: "release_date_to", show_name: "release_date_to" },
];
