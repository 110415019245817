import { useFormik } from "formik";
import { useEffect, useState } from "react";
import axiosCelestine from "../../../../../config/AxiosCelestine";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

//components
import FilePonds from "../../../../../components/addImage/Filepond";
import ErrorHandler from "../../../../../components/toast/ErrorHandler";
import Input from "../../../../../components/input/Input";

export interface TypeToBrandProps {}

const TypeToBrand: React.FC<TypeToBrandProps> = () => {
  const history = useHistory();
  const params: {
    id: string;
    detail: string;
  } = useParams();
  const [posted, setPosted] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [product, setProduct] = useState<any>(null);
  const [productDetail, setproductDetail] = useState<any>(null);
  const [productCategory, setProductCategory] = useState<any>({
    label: "Select here",
    values: "",
  });
  const [typeimg, setTypeImg] = useState<any>({ values: "upload" });

  const imageType = [
    { values: "upload", label: "Upload Image" },
    { values: "code_name", label: "Product Code / Name" },
  ];

  const { values, setValues, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      product_category_id: "",
      special_category_id: "",
      type: "special_category",
      published: true,
    },
    validationSchema: Yup.object({
      product_category_id: Yup.string().required(
        "Product category is required!"
      ),
    }),
    onSubmit: (values) => {
      values.special_category_id = params.detail;

      const new_product_brand = new FormData();
      new_product_brand.append(
        "special_category_id",
        values.special_category_id
      );
      new_product_brand.append("published", values.published.toString());
      new_product_brand.append("type", values.type);
      new_product_brand.append(
        "product_category_id",
        values.product_category_id
      );
      if (typeimg.values === "upload") {
        new_product_brand.append("image", files);
      } else {
        new_product_brand.append("product_id", productDetail.id);
      }

      axiosCelestine()
        .post("admin/special-category-list", new_product_brand)
        .then(({ data }) => {
          ErrorHandler("#00C62E", "Add Completed");
          setTimeout(() => {
            history.push(
              `/marketplace/product-category-group/categories/${params.id}/detail-categories/${params.detail}`
            );
          }, 1500);
        })
        .catch((err) => ErrorHandler("#DF5354", err.response.data.errors));
    },
  });

  const handlePost = () => {
    setPosted(!posted);
    values.published = !values.published;
  };

  const loadOptions = async (inputValue: string) => {
    try {
      const { data } = await axiosCelestine().get("public/product/search", {
        params: {
          search: inputValue,
        },
      });
      const option = data.data.map((el: any) => ({
        label: el.name,
        values: el.id,
      }));
      return option;
    } catch (err) {
      console.log(err);
    }
  };

  const loadProductCategory = async (inputValue: string) => {
    try {
      const { data } = await axiosCelestine().get("admin/product-category", {
        params: {
          name: inputValue,
          limit: 30,
        },
      });
      const option = data.data.map((el: any) => ({
        label: el.name,
        values: el.id,
      }));
      return option;
    } catch (err) {
      console.log(err);
    }
  };

  const getBrandImg = () => {
    const id = product?.values;
    axiosCelestine()
      .get(`admin/product/${id}`)
      .then(({ data }) => {
        setproductDetail(data);
      })
      .catch((err) => {
        return null;
      });
  };

  useEffect(() => {
    getBrandImg();
  }, [product]);

  const handleChangeCategory = (ctgry: any) => {
    setProductCategory(ctgry);
  };

  useEffect(() => {
    setValues({ ...values, product_category_id: productCategory.values });
  }, [productCategory]);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-full mt-8 md:flex justify-between"
    >
      {/* form image */}
      <div className="md:w-1/3 h-full rounded-md border-2 border-stroke">
        <div className="md:h-16 h-14 flex items-center md:mx-6 mx-5">
          <h1 className="font-medium md:text-lg text-base">
            Detail Category Images
          </h1>
        </div>
        <hr />

        <div className="md:mx-6 mx-5 mt-7">
          <div>
            <h1 className="md:text-sm text-textPrimary">Choose Image Type</h1>
            <Select
              options={imageType}
              defaultValue={imageType[0]}
              onChange={setTypeImg}
              className="mt-2 font-rom text-sm"
            />
          </div>
          {typeimg.values === "upload" ? (
            <div className="mt-6">
              <h1 className="md:text-sm text-textPrimary">Display Image</h1>
              <h1 className="md:text-xs text-textPrimary md:mt-2 mt-1 mb-1">
                (1 x 1), (PNG, JPG, JPEG) max. 1 MB
              </h1>
              <div className="w-full my-5">
                <FilePonds
                  setFiles={setFiles}
                  imageType={["image/png", "image/jpg", "image/jpeg"]}
                />
              </div>
            </div>
          ) : (
            <div className="my-6">
              <h1 className="md:text-sm text-textPrimary">Display Image</h1>
              {productDetail !== null ? (
                <div className="flex justify-center">
                  <img
                    src={productDetail.display_image_url}
                    alt="no images uploaded"
                    className="w-44 mt-7"
                  />
                </div>
              ) : (
                <h1 className="mt-7 text-primary">Please Select Brand!</h1>
              )}
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onChange={setProduct}
                className="my-5 font-rom text-sm"
              />
            </div>
          )}
        </div>
      </div>

      {/* form information */}

      <div className="md:w-custom h-full md:mt-0 mt-8">
        <div className="rounded-md rounded-b-none border-2 border-stroke">
          <div className="h-16 flex items-center justify-between md:mx-6 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Detail Category Information
            </h1>

            <div className="flex">
              <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                Posted
              </h1>
              <div className="flex justify-between items-center">
                <div
                  className={
                    posted
                      ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                      : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                  }
                >
                  <div
                    className={
                      posted
                        ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3 translate-x-3"
                        : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                    }
                    onClick={handlePost}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="md:flex w-full mb-8">
            <div className="md:mx-6 mx-5 mt-7 md:w-full">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Category
                </h1>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={loadProductCategory}
                  onChange={handleChangeCategory}
                  className="my-3 font-rom text-sm"
                />
                {touched.product_category_id && errors.product_category_id ? (
                  <p className="text-primary">{errors.product_category_id}</p>
                ) : null}
              </div>
              <div className="mt-4">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Detail Categories Name (From Brand / Sub-Brand)
                </h1>
                <Input
                  type={"text"}
                  disable={true}
                  placeholder="Place Holder"
                  name="name"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
            <button type="reset" className="focus:outline-none">
              <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                Save
              </h1>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TypeToBrand;
