import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Input from "../../components/input/Input";
import Backdrop from "./login/Backdrop";
import * as Yup from "yup";
import AuthRequest from "../../apis/Auth/AuthRequest";
import { showPass, unshowPass } from "../../assets/icons/Web";
import { logo } from "../../assets/icons";
import SmallButton from "../../components/button/SmallButton";
import { useMutation } from "react-query";
import ErrorHandler from "../../components/toast/ErrorHandler";
import LoginHelper from "../../helpers/LoginHelper";

const Loginuser = () => {
  const history = useHistory();
  const props = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: -1000 },
  });
  const [passType, setPassType] = useState<string>("password");
  const { pushNavigate } = LoginHelper();

  const handleVisibleType = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  const {
    handleSubmit,
    handleChange,
    errors,
    touched,
    values,
    setErrors,
    dirty,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Required!"),
      password: Yup.string()
        .required("Required!")
        .min(6, "The password must be of minimum 6 characters!"),
    }),
    onSubmit: async () => {
      await mutation.mutate();
    },
  });

  const mutation = useMutation(() => AuthRequest.LoginUser(values), {
    onMutate: () => {
      console.log("mutate login user");
    },
    onSettled: async (data: any) => {
      if (data.err) {
        setErrors({ password: data.err });
      } else {
        document.body.classList.remove("h-screen", "overflow-hidden");
        await pushNavigate(data.token, "user");
        ErrorHandler("#00C62E", "Login Successfully");
      }
    },
  });

  return (
    <>
      <Backdrop active={true} />
      <animated.div
        style={props}
        className="w-full h-screen flex items-center justify-center font-med"
      >
        <div className="flex flex-col bg-white rounded-md md:w-Wlogin md:h-Hlogin w-4/5 shadow-xl transform-gpu">
          <form onSubmit={handleSubmit}>
            <div className="md:mt-7 mt-8 md:mb-8 mb-8 flex flex-col justify-center items-center">
              <img
                alt="logo"
                src={logo}
                onClick={() => history.push("/login")}
                className="md:w-12 w-10 cursor-pointer"
              />
              <div className="flex md:mt-4 mt-3">
                <h1 className="text-primary md:text-xl text-lg">DEPATU</h1>
                <h1 className="md:text-xl text-lg ml-1">Marketplace User</h1>
              </div>
            </div>

            <div className="md:mx-14 mx-8 mb-5">
              <div>
                <h1 className="md:text-sm text-xs text-textPrimary">Username</h1>
                <Input
                  type={"text"}
                  placeholder={"username"}
                  value={values.username}
                  name={"username"}
                  handleChange={handleChange}
                />
                {touched.username && errors.username && (
                  <h1 className="text-xs font-nor text-primary -mt-2 mb-2">
                    {errors.username}
                  </h1>
                )}
              </div>
              <div>
                <h1 className="md:text-sm text-xs text-textPrimary">Password</h1>
                <div className="md:h-10 h-8 rounded-md border-2 my-2 border-stroke flex justify-between">
                  <input
                    type={passType}
                    placeholder="password"
                    className="md:text-sm font-rom text-xs px-3 w-full mr-5 focus:outline-none"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {passType === "password" ? (
                    <img
                      src={showPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt="showPass"
                    />
                  ) : (
                    <img
                      src={unshowPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt="unshowPass"
                    />
                  )}
                </div>
                {touched.password && errors.password && (
                  <h1 className="text-xs font-nor text-primary -mt-1 mb-1">
                    {errors.password}
                  </h1>
                )}
              </div>
            </div>
            <div
              className={
                "flex justify-center md:mb-0 mb-5 " +
                (errors.password || errors.username ? "-mt-3" : "mt-5")
              }
            >
              <SmallButton
                isSubmitting={mutation.isLoading}
                dirty={dirty}
                loadColor={"white"}
                type={"submit"}
                color={"bg-primary"}
                hoverColor={"bg-red-300"}
                text={"Login"}
                textColor={"text-white"}
              />
            </div>
          </form>
        </div>
      </animated.div>
    </>
  );
};

export default Loginuser;
