import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { detail, edit } from "../../../../../assets/icons";
import Pagination from "../../../../../components/pagination/Pagination";

export interface IProductDepatuTableProps {
  data: any;
}

const ProductDepatuTable: React.FC<IProductDepatuTableProps> = ({ data }) => {
  const history = useHistory();
  return (
    <div>
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r">Product Image</th>
            <th className="border-r">Product Code</th>
            <th className="border-r">Product Name</th>
            <th className="border-r">Status</th>
            <th className="border-r">Release Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r w-1/4">
                <div className="flex justify-center">
                  <img
                    loading="lazy"
                    src={el.display_image_url}
                    alt={el.slug}
                    className="w-1/3 p-2 rounded-md"
                  />
                </div>
              </td>
              <td className="border-r">{el.code || "-"}</td>
              <td className="border-r  w-1/4">{el.name || "-"}</td>
              <td className="border-r">
                <div className="flex flex-col justify-center">
                  <h1
                    className={
                      "font-med " +
                      (el.is_active ? "text-success" : "text-primary")
                    }
                  >
                    {el.is_active ? "Posted" : "Not Posted"}
                  </h1>
                </div>
              </td>

              <td className="border-r">
                {el.release_date ? (
                  <h1>{moment(el.release_date).format("lll") || "-"}</h1>
                ) : el?.season?.name ? (
                  <div>
                    <h1>{`${el.season.name}, ${el.season_year}`}</h1>
                    <h1>{`(${moment(el.season.start_date).format(
                      "MMMM"
                    )} - ${moment(el.season.end_date).format("MMMM")})`}</h1>
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="">
                <div className="w-full flex justify-evenly">
                  <img
                    onClick={() =>
                      history.push(
                        `/marketplace/product-depatu/edit-product/${el.id}/code/${el.code}`
                      )
                    }
                    src={edit}
                    alt="edit"
                    className="w-6 cursor-pointer"
                  />

                  <img
                    onClick={() =>
                      history.push(`/marketplace/product-depatu/${el.id}`)
                    }
                    src={detail}
                    alt="see-all"
                    className="w-5 cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default ProductDepatuTable;
