import ErrorHandler from "../../components/toast/ErrorHandler";
import { queryparamsCreditEffect } from "../../pages/credit_effect/helpers/list";
import { ActionType } from "../../store/credit_effect/action";
import ApplyChipTS from "../../utils/applyChip/ApplyChipTS";
import { AxiosInstance } from "../../config/AxiosInstance";

export class CreditEffectRequest {
  static getCreditEffect = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Cygnus().get(`requirement/`, {
        params: {
          ...(query.get("scope") !== null && {
            scope: query.get("scope"),
          }),
          ...(query.get("search") !== null && {
            search: query.get("search"),
          }),
          ...(query.get("get_credit") !== null && {
            "result.campaign_id": query.get("get_credit"),
          }),
          ...(query.get("effect_from") !== null && {
            "valid_time_until_before": query.get("effect_from"),
          }),
          ...(query.get("effect_to") !== null && {
            "valid_time_from_after": query.get("effect_to"),
          }),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      ApplyChipTS(queryparamsCreditEffect, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler(
        "#DF5354",
        error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
        ].detail
          .split("_")
          .join(" ") || "Something went wrong!"
      );
    }
  };

  static getCreditEffectById = async (id: string) => {
    try {
      const results = await AxiosInstance.Cygnus().get(`requirement/${id}/`);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addCreditEffect = async (payload: any) => {
    try {
      const results = await AxiosInstance.Cygnus().post(
        `requirement/`,
        payload
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler(
        "#ed4846",
        `${Object.keys(error.response.data.errors)[0]}: ${
          Object.values(error.response.data.errors)[0]
        }` || "Something went wrong!"
      );
    }
  };

  static editCreditEffect = async (payload: any, id: string | undefined) => {
    try {
      const results = await AxiosInstance.Cygnus().patch(
        `requirement/${id}/`,
        payload
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler(
        "#ed4846",
        `${Object.keys(error.response.data.errors)[0]}: ${
          Object.values(error.response.data.errors)[0]
        }` || "Something went wrong!"
      );
    }
  };

  static deleteCreditEffect = async (id: number) => {
    try {
      const result = await AxiosInstance.Cygnus().delete(`requirement/${id}/`);
      const status = result.status;
      return status;
    } catch (error: any) {
      ErrorHandler(
        "#DF5354",
        error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
        ]?.detail
          ?.split("_")
          ?.join(" ") || "Something went wrong!"
      );
    }
  };
}
