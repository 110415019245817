import React, { Suspense, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import NavDetail from "../../components/navbar/NavDetail";

const OConfirmStockMove = React.lazy(
  () => import("../../components/organisms/bnib_transaction/OConfirmStockMove")
);

const ScanStockMove = () => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState({
    location_dest_name: "",
    status: false,
  });

  const handleScan = (result: any, error: any) => {
    if (!!result) {
      setOpenDialog({
        location_dest_name: result,
        status: true,
      });
    }
  };

  const handleClosed = () => {
    setOpenDialog({
      location_dest_name: "",
      status: false,
    });
  };

  return (
    <>
      <Suspense fallback={<></>}>
        <OConfirmStockMove
          isOpen={openDialog.status}
          handleClosed={handleClosed}
          location_dest_name={openDialog.location_dest_name}
        />
      </Suspense>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Scan"
          title2="Stock Move"
          handleBack={() => history.goBack()}
        />
      </div>
      <div className="flex flex-col h-screen max-h-screen items-center justify-center">
        <div className="xl:w-1/2 lg:w-10/12 w-full h-full xl:-mt-28 sm:-mt-14 -mt-8">
          <QrReader
            scanDelay={300}
            onResult={handleScan}
            constraints={{ facingMode: "user" }}
          />
        </div>
      </div>
    </>
  );
};

export default ScanStockMove;
