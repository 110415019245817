import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import CreditEffectContext from "../../store/credit_effect/CreditEffectContext";
import CreditEffectCard from "./component/CreditEffectCard";
import {
  optRoutesCreditEffect,
  optSortingCreditEffect,
  queryparamsCreditEffect,
} from "./helpers/list";
import GetCreditEffect from "./helpers/useHooks/GetCreditEffect";
import DeleteCreditEffectModal from "./component/DeleteCreditEffectModal";
import { ActionType } from "../../store/credit_effect/action";
import CreditEffectFilter from "./component/CreditEffectFilter";
import Chip from "../../components/chip/Chip";
import NewSorting from "../../components/sorting/NewSorting";
import LocalStorage from "../../config/LocalStorage";
import { validateRole } from "../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../layout/marketplace/interfaces";

const CreditEffect = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const { CreditEffectDispatch, CreditEffectState } =
    useContext(CreditEffectContext);
  const { showDelete, showFilter } = CreditEffectState;
  const history = useHistory();
  const { url } = useRouteMatch();
  const { data, isFetching, isLoading } = GetCreditEffect();

  return (
    <div>
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesCreditEffect} />
        {showDelete.status && <DeleteCreditEffectModal />}
        {showFilter && <CreditEffectFilter />}

        <div className="md:mt-12 mt-10">
          {validateRole(role, [
            AcceptedRole.Admin,
            AcceptedRole.Operation,
            AcceptedRole.KeyAccount,
            AcceptedRole.Marketing,
          ]) && (
            <div className="flex justify-end">
              <h1
                onClick={() => history.push(`${url}/add-credit-effect`)}
                className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
              >
                Add Credit Effect
              </h1>
            </div>
          )}

          <div className="flex justify-between my-2.5">
            <div className="flex">
              <h1
                onClick={() =>
                  CreditEffectDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingCreditEffect} />
          </div>

          <Chip
            dispatchContext={CreditEffectDispatch}
            stateContext={CreditEffectState}
            queryParams={queryparamsCreditEffect}
            ActionType={ActionType}
          />
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-10">
              {data?.data.map((el: any) => (
                <CreditEffectCard data={el} key={el.id} />
              ))}
            </div>
            <div className="my-5">
              <Pagination
                length={data?.data?.length}
                total={data?.meta?.total}
                isTable={false}
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default CreditEffect;
