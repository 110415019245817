import { ActionType, SendCreditAction } from "./action";
import { ISendCreditState } from "./state";

export const SendCreditReducer = (
  state: ISendCreditState,
  action: SendCreditAction
) => {
  switch (action.type) {
    case ActionType.ShowSendCredit:
      let showSendModal = state.showSendModal;
      document.body.classList[!showSendModal ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSendModal: !showSendModal };
    case ActionType.ShowDelete:
      let showDelete = action.payload;
      document.body.classList[showDelete.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDelete };
  }
};
