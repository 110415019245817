import React, { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import Input from "../../../../components/input/Input";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../components/loading/Loading";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesSellproductcampaign } from "../../helpers/list";
import GetProductId from "../../helpers/use_query_hook/GetProductById";
import GetUser from "../../helpers/use_query_hook/GetUser";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import { useMutation } from "react-query";
import CircularLoad from "../../../../components/loading/CircularLoad";
import { formatRupiah } from "../../../../utils/format_rupiah/FormatRupiah";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { ActionType } from "../../../../store/campaign/action";
import ModalSecurityCode from "./sell_product/ModalSecurityCode";
import PriceInput from "../../../../components/input/PriceInput";

const SellProduct = () => {
  const history = useHistory();
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const location: any = useLocation();
  const campaign_product_template_id =
    location?.state?.campaign_product_template_id;
  const params: { id: string; productId: string } = useParams();
  const { data, isLoading } = GetProductId(campaign_product_template_id);
  const { data: dataUser, isLoading: loaduser } = GetUser();

  const { values, setValues, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        is_simulated: true,
        price: "",
        pre_order: false,
        campaign_product_template_id: campaign_product_template_id,
        product_size_id: 0,
        coupon_code: "",
      },
      validationSchema: Yup.object({
        product_size_id: Yup.number()
          .min(1, "Please select product size")
          .required("Product size is Required"),
        price: Yup.string().required("Price is Required"),
      }),
      onSubmit: async () => {
        const payload: any = {
          ...values,
          coupon_code: "",
          refund_shipping_address_id:
            dataUser?.active_refund_shipping_address_id,
        };
        payload.is_simulated = false;
        mutateResult(payload);
      },
    });

  const handleChangePrice = (e: any) => {
    setValues({ ...values, price: e.target.value });
    mutate();
  };

  const setRetailPrice = () => {
    setValues({ ...values, price: data?.retail_price });
    mutate();
  };

  const debouncedEventHandler = useMemo(
    () => debounce((e: any) => handleChangePrice(e), 1000),
    [values]
  );

  const debouncedEventHandlerSetRetailPrice = useMemo(
    () => debounce(setRetailPrice, 1000),
    [values]
  );

  const {
    data: dataSell,
    mutate,
    isLoading: loadSell,
  } = useMutation(() => CampaignRequest.sellProduct(values), {
    onMutate: () => {
      console.log("mutate sell product");
    },
    onSettled: async (data: any, error: any) => {
      if (error) {
        console.log(error);
      }
    },
  });

  const { mutate: mutateResult, isLoading: loadResult } = useMutation(
    (payload: any) => CampaignRequest.sellProduct(payload),
    {
      onMutate: () => {
        console.log("mutate sell product");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          CampaignDispatch({
            type: ActionType.ShowModalSecurityCode,
            payload: {
              status: true,
              code: data.code,
            },
          });
        }
      },
    }
  );

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Sell"
          title2="This Product"
          handleBack={() => history.goBack()}
        />
      </div>
      {CampaignState.showModalSecurityCode.status && <ModalSecurityCode />}
      <div>
        <DynamicBreadcrumb
          data={optRoutesSellproductcampaign(params.id, params.productId)}
        />
        {isLoading || loaduser ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="my-20">
            <aside className="flex my-10">
              <img
                src={data.display_image_url}
                alt="-"
                className="border-2 rounded-md p-4 w-32"
              />
              <div className="ml-10">
                <h1 className="text-primary text-xl">{data.code}</h1>
                <h1 className="text-2xl">{data.name}</h1>
              </div>
            </aside>
            <hr />
            <section className="my-7">
              <h1 className="text-2xl">Select Type</h1>
            </section>
            <hr />
            <section className="my-10 flex">
              <div
                onClick={() => setValues({ ...values, pre_order: false })}
                className={
                  "rounded-full w-36 py-4 flex justify-center cursor-pointer hover:bg-primary hover:bg-opacity-70 border hover:text-white " +
                  (values.pre_order === false
                    ? "bg-primary text-white"
                    : "text-breadscrumbColor")
                }
              >
                <h1>Brand New</h1>
              </div>
              <div
                onClick={() => setValues({ ...values, pre_order: true })}
                className={
                  "ml-5 rounded-full w-36 py-4 flex justify-center cursor-pointer hover:bg-primary hover:bg-opacity-70 hover:text-white border " +
                  (values.pre_order
                    ? "bg-primary text-white"
                    : "text-breadscrumbColor")
                }
              >
                <h1>Pre Order</h1>
              </div>
            </section>
            <section>
              <h1 className="text-2xl">Select Size</h1>
              <div className="my-7">
                <hr />
              </div>
              <div className="grid grid-cols-12 gap-5">
                {data.product_sizes.map((el: any) => (
                  <div
                    onClick={() =>
                      setValues({ ...values, product_size_id: el.id })
                    }
                    className={
                      "border rounded-md py-6 cursor-pointer flex flex-col items-center justify-center " +
                      (el.id === values.product_size_id
                        ? "border-primary"
                        : "")
                    }
                  >
                    <h1 className="text-primary text-xl">{el.size}</h1>
                    <h1 className="text-xs">
                      {formatRupiah(el.bnib_lowest_sell_price)}
                    </h1>
                  </div>
                ))}
              </div>
              {errors.product_size_id && touched.product_size_id && (
                <p className="text-primary text-xs font-rom mt-3 mb-1">
                  {errors.product_size_id}
                </p>
              )}
            </section>
            <section className="my-10">
              <h1 className="text-2xl">Sell Information</h1>
              <div className="my-7">
                <hr />
              </div>
              <div>
                <div>
                  <div className="flex justify-between">
                    <h1>Selling Price</h1>
                    <h1
                      onClick={debouncedEventHandlerSetRetailPrice}
                      className="hover:text-primary cursor-pointer"
                    >
                      Use Retail Price
                    </h1>
                  </div>
                  <PriceInput
                    displayType={"input"}
                    value={values.price}
                    handleChange={debouncedEventHandler}
                    name="price"
                  />
                  {errors.price && touched.price && (
                    <p className="text-primary text-xs font-rom -mt-2 mb-1">
                      {errors.price}
                    </p>
                  )}
                  {loadSell ? (
                    <div className="flex justify-center mt-10">
                      <CircularLoad color={"#ed4846"} height={32} width={32} />
                    </div>
                  ) : (
                    <div>
                      {dataSell?.payout_fees[1] && (
                        <div className="flex justify-between mt-5">
                          <h1 className="font-rom">
                            {dataSell.payout_fees[1].name}
                          </h1>
                          <h1 className="font-rom">
                            {formatRupiah(dataSell.payout_fees[1].amount)}
                          </h1>
                        </div>
                      )}
                      {dataSell?.payout_fees[3] && (
                        <div className="flex justify-between mt-5">
                          <h1 className="font-rom">
                            {dataSell.payout_fees[3].name}
                          </h1>
                          <h1 className="font-rom">
                            {formatRupiah(dataSell.payout_fees[3].amount)}
                          </h1>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="mt-5">
                    <div className="flex justify-between">
                      <h1 className="font-rom">Promo Code</h1>
                      <h1 className="font-rom text-primary">
                        {dataSell?.payout_fees[4] &&
                          formatRupiah(dataSell?.payout_fees[4]?.amount)}
                      </h1>
                    </div>
                    <div className="flex items-center">
                      <Input
                        name="coupon_code"
                        type={"text"}
                        handleChange={handleChange}
                      />
                      <div
                        onClick={() => mutate()}
                        className="bg-primary text-white h-10 flex justify-center items-center px-10 rounded-r-md cursor-pointer hover:bg-opacity-70"
                      >
                        Use
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex justify-between">
                      <h1 className="text-lg">Total Payout</h1>
                      <h1 className="text-lg text-primary">
                        {dataSell?.payout_fee_total
                          ? formatRupiah(dataSell?.payout_fee_total)
                          : "-"}
                      </h1>
                    </div>
                    <hr className="mt-7" />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <h1 className="text-2xl">Billing</h1>
              <hr className="mt-7" />
              {loadSell ? (
                <div className="flex justify-center mt-10">
                  <CircularLoad color={"#ed4846"} height={32} width={32} />
                </div>
              ) : (
                <div>
                  <div className="flex justify-between mt-7">
                    <h1 className="font-rom">
                      {dataSell?.billing_fees[0]?.name
                        ? dataSell?.billing_fees[0]?.name
                        : "Protection Fee"}
                    </h1>
                    <h1 className="font-rom">
                      {dataSell?.billing_fees[0]?.amount
                        ? formatRupiah(dataSell?.billing_fees[0]?.amount)
                        : "-"}
                    </h1>
                  </div>
                  <div>
                    <h1 className="font-rom text-breadscrumbColor">
                      Protection Fee will be refunded after transaction is
                      successfull.
                    </h1>
                  </div>
                </div>
              )}
            </section>

            <section className="mt-10">
              <h1 className="text-2xl">Payment</h1>
              <hr className="mt-7" />
              <div className="mt-7">
                <Input
                  name="payment"
                  type={"text"}
                  value={
                    dataSell?.amount
                      ? `DEPATU WALLET (${formatRupiah(dataSell.amount)})`
                      : "-"
                  }
                  disable
                />
              </div>
              <hr className="mt-7" />
              <div className="text-breadscrumbColor text-sm font-rom my-5">
                <h1 className="mt-1">
                  -Payout and Protection Fee will be paid back to you after
                  products is sold and transaction completed within 1x36 hours.
                </h1>
                <h1 className="mt-1">
                  -Make sure to send the product with correct SKU, size and
                  condition. If product sent to DEPATU didn't meet above product
                  condition it will be cancelled without a refund of protection
                  fee.
                </h1>
                <h1 className="mt-1">
                  -Transaction will be cancelled without a refund of protection,
                  if product is authenticated fake or fraud
                </h1>
              </div>
              <hr className="" />
              <div className="flex justify-center mt-7">
                {loadResult ? (
                  <div className="flex justify-center mt-10">
                    <CircularLoad color={"#ed4846"} height={32} width={32} />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="hover:text-primary cursor-pointer text-2xl focus:outline-none"
                  >
                    Continue
                  </button>
                )}
              </div>
            </section>
          </form>
        )}
      </div>
    </>
  );
};

export default SellProduct;
