import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";

export class TrackingRequest {
  static getTrackingLocation = async (
    limit: number,
    offset: number,
    sortValue: string,
    isAsc: string
  ) => {
    try {
      const data = await axiosCelestine().get("admin/custom-tracking-origin", {
        params: {
          limit: limit,
          ...(offset && { offset: offset }),
          sort: `${sortValue} ${isAsc}`,
        },
      });
      const result = data.data;
      return result;
    } catch (error) {
      console.log(error.response.data.errors);
    }
  };

  static getTrackingLocationDetail = async (id: number) => {
    try {
      const data = await axiosCelestine().get(
        `admin/custom-tracking-origin/${id}`
      );
      const result = data.data;
      return result;
    } catch (error) {
      console.log(error.response.data.errors);
    }
  };

  static addLocation = async (
    payload: any,
    setSubmitting: any,
    goBack: () => void
  ) => {
    try {
      setSubmitting(true);
      await axiosCelestine().post("admin/custom-tracking-origin", payload);
      setSubmitting(false);
      goBack();
      ErrorHandler("#00C62E", "Add Completed");
    } catch (error) {
      setSubmitting(false);
      ErrorHandler("#ed4846", error.response.data.errors);
    }
  };

  static editLocation = async (
    payload: any,
    goBack: () => void,
    id: number,
    refetch: () => void,
    setSubmitting: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCelestine().patch(`admin/custom-tracking-origin/${id}`, {
        name: payload,
      });
      refetch();
      goBack();
      setSubmitting(false);
      ErrorHandler("#00C62E", "Edit Completed");
    } catch (error) {
      setSubmitting(false);
      ErrorHandler("#ed4846", error.response.data.errors);
    }
  };

  static deleteLocation = async (id: number, goBack: any) => {
    try {
      await axiosCelestine().delete(`admin/custom-tracking-origin/${id}`);
      goBack();
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error) {
      ErrorHandler("#ed4846", error.response.data.errors);
    }
  };

  static getTrackingStep = async (id: number) => {
    try {
      const data = await axiosCelestine().get(`admin/custom-tracking-step`, {
        params: {
          custom_tracking_origin_id: id,
          sort: "priority asc",
          limit: 50,
        },
      });
      const result = data.data.data;
      return result;
    } catch (error) {
      console.log(error.response.data.errors);
    }
  };

  static addStep = async (payload: any) => {
    try {
      await axiosCelestine().post("admin/custom-tracking-step", payload);
      ErrorHandler("#00C62E", "Add Completed");
    } catch (error) {
      ErrorHandler("#ed4846", error.response.data.errors);
    }
  };

  static editPriorityStep = async (
    payload: any,
    setDataOfStep: any,
    data: any,
    setLoading: any
  ) => {
    try {
      await axiosCelestine().patch(
        `admin/custom-tracking-step/${payload.filterData.id}`,
        {
          priority: payload.destinationIndex + 1,
        }
      );
      setLoading(false);
      ErrorHandler("#00C62E", "Edit Completed");
    } catch (error) {
      setLoading(false);
      ErrorHandler("#ed4846", error.response.data.errors);
      setDataOfStep(data);
    }
  };

  static editDescStep = async (
    payload: any,
    id: number,
    setSubmitting: any,
    goBack: () => void
  ) => {
    try {
      setSubmitting(true);
      await axiosCelestine().patch(`admin/custom-tracking-step/${id}`, payload);
      setSubmitting(false);
      goBack();
      ErrorHandler("#00C62E", "Edit Completed");
    } catch (error) {
      setSubmitting(false);
      ErrorHandler("#ed4846", error.response.data.errors);
    }
  };

  static deleteStep = async (id: number, goBack: any) => {
    try {
      await axiosCelestine().delete(`admin/custom-tracking-step/${id}`);
      goBack();
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error) {
      ErrorHandler("#ed4846", error.response.data.errors);
    }
  };
}
