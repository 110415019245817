import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";

export class FeaturedProductRequest {
  static getFeaturedProduct = async (dispatch?: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(`admin/featured-product`, {
        params: {
          group: true,
          ...(query.get("display_name") !== null && {
            display_name: query.get("display_name"),
          }),
          ...(query.get("for_reward") !== null && {
            for_reward: query.get("for_reward"),
          }),
          ...(query.get("scopes") !== null && {
            scopes: query.get("scopes"),
          }),
          // ...(query.get("selling_time_from") !== null &&
          // query.get("selling_time_to") !== null
          //   ? {
          //       created_at: `${query.get("selling_time_from")},${query.get(
          //         "selling_time_to"
          //       )}`,
          //     }
          //   : ""),
          // ...(query.get("selling_time_from") !== null &&
          // query.get("selling_time_to") === null
          //   ? { created_at: `${query.get("selling_time_from")},2100-01-01` }
          //   : ""),
          // ...(query.get("selling_time_from") === null &&
          // query.get("selling_time_to") !== null
          //   ? { created_at: `2000-01-01,${query.get("selling_time_to")}` }
          //   : ""),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      // ApplyChipTS(queryparamsSizing, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getProduct = async (id: string) => {
    try {
      const results = await axiosCelestine().get(`admin/product/${id}`);
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error.response.data.errors);
    }
  };

  static getFeaturedProductById = async (id: string) => {
    try {
      const results = await axiosCelestine().get(
        `admin/featured-product/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addFeaturedProductTag = async (payload: any) => {
    const data: any = {
      product_id: payload.name.value,
      priority: payload.priority,
      published: payload.published,
      tags: [],
    };
    if (payload.product_tag_1.length > 0) {
      data.tags.push({
        label: payload.product_tag_1,
        color: payload.color_1.value,
      });
    }
    if (payload.product_tag_2.length > 0) {
      data.tags.push({
        label: payload.product_tag_2,
        color: payload.color_2.value,
      });
    }

    try {
      const results = await axiosCelestine().post(
        `admin/featured-product`,
        data
      );
      ErrorHandler("#00C62E", "Add Featured Product Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Add Featured Product Failed"
        );
      }
    }
  };

  static editFeaturedProductTag = async (
    payload: any,
    id: string,
    data: any
  ) => {
    const dataEdit: any = {
      priority: payload.priority,
      published: payload.published,
      tags: [],
    };
    if (payload.product_tag_1 !== undefined) {
      dataEdit.tags.push({
        label: payload.product_tag_1,
        color: payload.color_1.value,
      });
    }
    if (payload.product_tag_2 !== undefined) {
      dataEdit.tags.push({
        label: payload.product_tag_2,
        color: payload.color_2.value,
      });
    }
    if (data.product_id !== payload.name.value) {
      dataEdit["product_id"] = payload.name.value;
    }

    try {
      const results = await axiosCelestine().patch(
        `admin/featured-product/${id}`,
        dataEdit
      );
      ErrorHandler("#00C62E", "Edit Featured Product Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Featured Product Failed"
        );
      }
    }
  };

  static deleteTag = async (payload: any) => {
    const dataDelete: any = {
      tags: payload.tags,
    };
    try {
      const results = await axiosCelestine().patch(
        `admin/featured-product/${payload.id}`,
        dataDelete
      );
      ErrorHandler("#00C62E", "Delete Featured Product Tag Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Featured Product Failed"
        );
      }
    }
  };

  static deleteFeaturedProduct = async (id: number, goBack: any) => {
    try {
      await axiosCelestine().delete(`admin/featured-product/${id}`);
      ErrorHandler("#00C62E", "Delete Completed");
      goBack();
    } catch (error) {
      ErrorHandler("#ed4846", "Delete Failed");
    }
  };
}
