import { ActionType, CouponAction } from "./action";
import { ICouponState } from "./state";

export const couponReducer = (state: ICouponState, action: CouponAction) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowSendCoupon:
      let showSendCoupon = state.showSendCoupon;
      document.body.classList[!showSendCoupon ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSendCoupon: !showSendCoupon };
    case ActionType.ShowConfirmAdd:
      let showConfirmAdd = state.showConfirmAdd;
      document.body.classList[!showConfirmAdd ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      const payload = action.payload;
      return {
        ...state,
        showConfirmAdd: !showConfirmAdd,
        simulateAddCouponData: payload,
      };
    case ActionType.HandleFormDataAdd:
      const addForm = action.payload;
      return {
        ...state,
        formdataAddCoupon: addForm,
      };

    case ActionType.HandleFormDataEdit:
      const editForm = action.payload;
      return {
        ...state,
        formdataEditCoupon: editForm,
      };
    case ActionType.ShowConfirmEdit:
      let showConfirmEdit = state.showConfirmEdit;
      document.body.classList[!showConfirmEdit ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      const payloadEdit = action.payload;
      return {
        ...state,
        showConfirmEdit: !showConfirmEdit,
        simulateEditCouponData: payloadEdit,
      };
    case ActionType.showDelete:
      let showDelete = action.payload;
      document.body.classList[showDelete.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDelete };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
