export const optRoutesFeaturedProduct = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/featured-product",
    name: "Featured Product Tag",
  },
];

export const optRoutesAddFeaturedProduct = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/featured-product",
    name: "Featured Product Tag",
  },
  {
    id: 3,
    path: "/marketplace/featured-product/add-featured-product",
    name: "Add Featured Product Tag",
  },
];

export const optRoutesEditFeaturedProduct = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/featured-product",
      name: "Featured Product Tag",
    },
    {
      id: 3,
      path: `/marketplace/featured-product/edit-featured-product/${id}`,
      name: "Edit Featured Product Tag",
    },
  ];
};

export const optSortingFeaturedProduct = [
  {
    value: "created_at",
    name: "Date",
  },
];

export const listColor = [
  {
    value: "#ed4846",
    label: "Red",
  },
  {
    value: "#00C62E",
    label: "Green",
  },
];
