import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  BrandtoCategory,
  BrandtoSubBrand,
  CategoryToBrand,
  TypeToBrand,
} from "./formAddDetailCategories";
import NavDetail from "../../../../components/navbar/NavDetail";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import { optRoutesDetailCategories, typeCategories } from "../../helpers/list";
import Select from "react-select";
import ProductBrandOfficialStore from "./formAddDetailCategories/ProductBrandOfficialStore";

const AddDetailCategories: React.FC = () => {
  const history = useHistory();
  const params: {
    id: string;
    detail: string;
  } = useParams();
  const [type, setType] = useState({
    value: "product_brand",
    label: "Brand to Sub-Brand",
  });

  const goBack = () => {
    history.push(
      `/marketplace/product-category-group/categories/${params.id}/detail-categories/${params.detail}`
    );
  };

  return (
    <div>
      <NavDetail
        handleBack={goBack}
        title1={"Add"}
        title2={"Detail Categories"}
      />

      <div className="pt-28 font-med">
        <DynamicBreadcrumb
          data={optRoutesDetailCategories(params.id, params.detail)}
        />
        <div className="my-14">
          <h1 className="my-2.5">Select Type</h1>
          <Select
            className="font-rom"
            options={typeCategories}
            value={type}
            onChange={(e: any) => setType(e)}
          />
        </div>

        <div>
          {type.value === "product_brand" && <BrandtoSubBrand />}
          {type.value === "product_category" && <BrandtoCategory />}
          {type.value === "special_category" && <TypeToBrand />}
          {type.value === "product_category_to_brand" && <CategoryToBrand />}
          {type.value === "retail_shop" && (
            <ProductBrandOfficialStore />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddDetailCategories;
