import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Tags from "../../../pages/tags/Tags";
import EditTags from "../../../pages/tags/tags_component/EditTags";

const TagsRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/marketplace/tags" exact component={Tags} />
        <Route path="/marketplace/tags/:id" exact component={EditTags} />
        <Redirect from="/marketplace" to="/marketplace/tags" exact />
      </Switch>
    </div>
  );
};

export default TagsRoute;
