import React, { useRef, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";
import Loading from "../../../../components/loading/Loading";
import Pagination from "../../../../components/pagination/Pagination";
import { ActionType } from "../../../../store/sizing/action";
import SizingContext from "../../../../store/sizing/SizingContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const SeeExceptSubBrand = () => {
  const params: { product_category_id: string; product_brand_id: string } =
    useParams();
  const wrapperRef = useRef<any>(null);
  const query = useQueries(useLocation);
  const { SizingDispatch } = useContext(SizingContext);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        SizingDispatch({
          type: ActionType.HandleShowExceptSubBrand,
          payload: {
            status: false,
            product_category_id: "0",
            product_brand_id: "0",
          },
        });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const { data, isLoading } = useQuery(
    ["getExceptionBrand", query.get("limit"), query.get("offset")],
    () =>
      SizingRequest.getExceptionBrand(
        params.product_category_id,
        params.product_brand_id
      )
  );

  return (
    <div className="fixed bg-black bg-opacity-80 px-20 h-screen z-30 w-full inset-0 flex items-end cursor-pointer">
      <div
        id="class"
        ref={wrapperRef}
        className="bg-white h-5/6 overflow-auto w-full rounded-t-3xl inset-y-0 cursor-default transition duration-500 ease-in-out"
      >
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="mt-5">
            <input
              type="text"
              name=""
              placeholder="Search"
              className="text-xl mx-10 my-4 focus:outline-none"
            />
            <hr />
            {data.data.map((el: any) => (
              <>
                <div className="flex justify-between my-6 mx-10 items-center">
                  <h1 className="text-lg">{el.product_brand_name}</h1>
                </div>
                <hr />
              </>
            ))}
            <div className="bg-white sticky bottom-0 shadow-inner px-10 pt-5">
              <Pagination
                length={data?.data.length}
                total={data?.meta.total}
                isTable={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SeeExceptSubBrand;
