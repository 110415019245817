import React, { useEffect, useState } from "react";
import Input from "../../../../components/input/Input";
import { isAppValidator } from "../../helpers/checkingDevice";
import AsyncSelect from "react-select/async";
import {
  loadOptionsBrand,
  loadOptionsCategory,
  loadOptionsCategoryGroup,
  loadOptionsProduct,
  loadOptionsProductStore,
  loadOptionsStore,
  loadOptionsTag,
  loadOptTicket,
} from "../../../../utils/loadOption/LoadOption";
import NewFilePonds from "../../../../components/addImage/NewFilepond";
import Toggle from "../../../../components/button/Toggle";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  handleChangeProduct,
  handleChangeStore,
  handleChangeProductstore,
  handleActive,
  handleChangeBrand,
  handleChangeCategory,
  handleChangeCategoryGroup,
  handleChangeChecked,
  handleChangeTag,
} from "./FormHandle";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { BannerRequest } from "../../../../apis/banner/BannerRequest";
import { useHistory, useParams } from "react-router";
import CircularLoad from "../../../../components/loading/CircularLoad";
import { handleChangeTicket } from "../../helpers/formHandle";

interface IProps {
  data: any;
}

const EditForm: React.FC<IProps> = ({ data }) => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const [files, setFiles] = useState<any>(null);
  const [brand, setBrand] = useState<boolean>(false);
  const [tag, setTag] = useState<boolean>(false);
  const [category, setCategory] = useState<boolean>(false);
  const [category_group, setCategoryGroup] = useState<boolean>(false);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      expired_at: "",
      is_active: true,
      scope: "4",
      banner_action: "2",
      navigation_type: "",
      url: "",
      screen_name: "",
      product_id: "",
      product_name: "",
      ticket_id: "",
      ticket_name: "",
      retail_shop_id: "",
      retail_name: "",
      retail_product_id: "",
      product_retail_name: "",
      product_brand_id: "",
      product_brand_name: "",
      tag_id: "",
      tag_name: "",
      product_category_group_id: "",
      product_category_group_name: "",
      product_category_id: "",
      product_category_name: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("title is required"),
      expired_at: Yup.string().required("expired at is required"),
    }),
    onSubmit: async (values: any) => {
      const form_data = new FormData();
      //main
      form_data.append("title", values.title);
      form_data.append("is_active", values.is_active);
      form_data.append("expired_at", values.expired_at.slice(0, 10));
      form_data.append("scope", values.scope);
      form_data.append("action", values.banner_action);
      if (files !== null) {
        form_data.append("image", files);
      }

      //payload
      values.scope === "3" &&
        values.description.length > 0 &&
        form_data.append("description", values.description);
      values.url && form_data.append("url", values.url);
      values.navigation_type &&
        form_data.append("navigation_type", values.navigation_type);
      values.screen_name && form_data.append("screen_name", values.screen_name);
      values.product_id &&
        form_data.append("product_id", values.product_id.toString());
      values.ticket_id &&
        values.screen_name === "5" &&
        form_data.append("ticket_id", values.ticket_id.toString());
      values.product_brand_id &&
        brand &&
        form_data.append("product_brand_id", values.product_brand_id);
      values.tag_id && tag && form_data.append("tag_id", values.tag_id);
      values.product_category_id &&
        category &&
        form_data.append("product_category_id", values.product_category_id);
      values.product_category_group_id &&
        category_group &&
        form_data.append(
          "product_category_group_id",
          values.product_category_group_id
        );
      values.retail_product_id &&
        form_data.append("retail_product_id", values.retail_product_id);
      values.retail_shop_id &&
        form_data.append("retail_shop_id", values.retail_shop_id);

      await BannerRequest.editBanner(
        +params.id,
        form_data,
        () => history.goBack(),
        setSubmitting
      );
    },
  });

  useEffect(() => {
    if (data.data.payload.length <= 0) {
      setValues({
        ...values,
        title: data?.data.title,
        description: data?.data.description,
        expired_at: data?.data.expired_at,
        scope: data?.data.scope.toString() || data?.data.type.toString(),
        banner_action: data?.data.action.toString(),
        is_active: data.data.is_active,
      });
    } else if (data.data.payload.length > 0) {
      setValues({
        ...values,
        title: data?.data.title,
        expired_at: data?.data.expired_at,
        scope: data?.data.scope.toString() || data?.data.type.toString(),
        banner_action: data?.data.action.toString(),
        is_active: data.data.is_active,
        description: data?.data.description,

        navigation_type: JSON.parse(
          data.data.payload
        ).navigation_type?.toString(),
        screen_name: JSON.parse(data.data.payload).screen_name?.toString(),
        product_brand_id: JSON.parse(
          data.data.payload
        ).product_brand_id?.toString(),
        url: JSON.parse(data.data.payload).url,
        product_id: JSON.parse(data.data.payload).product_id?.toString(),
        ticket_id: JSON.parse(data.data.payload).ticket_id?.toString(),
        tag_id: JSON.parse(data.data.payload).tag_id?.toString(),
        product_category_group_id: JSON.parse(
          data.data.payload
        ).product_category_group_id?.toString(),
        product_category_id: JSON.parse(
          data.data.payload
        ).product_category_id?.toString(),
        retail_shop_id: JSON.parse(
          data.data.payload
        ).retail_shop_id?.toString(),
        retail_product_id: JSON.parse(
          data.data.payload
        ).retail_product_id?.toString(),
      });
    }

    return () => {
      setValues({
        ...values,
        title: "",
        expired_at: "",
        scope: "4",
        is_active: true,
        banner_action: "2",
        screen_name: "",
        product_brand_id: "",
        product_id: "",
        product_category_id: "",
        product_category_group_id: "",
        tag_id: "",
        retail_product_id: "",
        retail_shop_id: "",
        navigation_type: "",
        url: "",
      });
    };
  }, [data]);

  useEffect(() => {
    data.data.product_brand !== null && setBrand(true);
    data.data.tag !== null && setTag(true);
    data.data.product_category !== null && setCategory(true);
    data.data.product_category_group !== null && setCategoryGroup(true);
    return () => {
      setBrand(false);
      setTag(false);
      setCategory(false);
      setCategoryGroup(false);
    };
  }, [data]);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-full my-12 md:flex justify-between"
    >
      {/* form image */}
      <div className="md:w-1/3 h-full rounded-md border-2 border-stroke">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-lg text-base">Banner Images</h1>
        </div>
        <hr />
        <div className="md:mx-8 mx-5 my-7">
          <div>
            <h1 className="md:text-base text-sm text-mobile text-textPrimary">
              Display Image
            </h1>
            <h1 className="md:text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
              {isAppValidator(values.scope)
                ? "(4 x 3), (PNG,JPG,JPEG) max. 150 KB"
                : "(3 x 1), (PNG,JPG,JPEG) max. 300 KB"}
            </h1>
            <div className="w-full flex justify-center">
              <img
                src={data?.data?.image_url}
                alt="img_banner"
                className="mt-5 rounded-lg shadow-lg w-11/12"
              />
            </div>
            <NewFilePonds
              setFiles={setFiles}
              imageType={["image/png", "image/jpg", "image/jpeg"]}
              cropRatio={isAppValidator(values.scope) ? "4:3" : "3:1"}
              maxFileSize={isAppValidator(values.scope) ? "150KB" : "300KB"}
            />
          </div>
        </div>
      </div>

      {/* form information */}
      <div className="md:w-custom h-full md:mt-0 my-8">
        <div className="rounded-md rounded-b-none border-2 border-stroke">
          <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Banner Information
            </h1>

            <div className="flex">
              <h1 className="md:text-base text-sm mr-3 text-textPrimary">
                Posted
              </h1>
              <div className="flex justify-between items-center">
                <Toggle
                  handleClick={() => handleActive(setValues, values)}
                  input={values.is_active}
                />
              </div>
            </div>
          </div>
          <hr />

          <div className="md:flex w-full">
            <div className="md:mx-8 mx-5 my-7 md:w-full">
              <div className="w-full">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Banner Name
                </h1>
                <Input
                  type={"text"}
                  handleChange={handleChange}
                  disable={false}
                  name={"title"}
                  value={values.title}
                  placeholder={"Place holder"}
                />
                {touched.title && errors.title && (
                  <h1 className="text-primary -mt-2 text-xs">
                    {errors.title}
                  </h1>
                )}
              </div>

              <div className="flex justify-between w-full my-2">
                <div className="w-1/2 mr-7">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Banner Post in
                  </h1>
                  <select
                    onChange={handleChange}
                    name={"scope"}
                    value={values.scope}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="4">Web (All)</option>
                    <option value="5">Web (Legit Check)</option>
                    <option value="6">Web (Market Place)</option>
                    <option value="7">Web (Multi Brand)</option>
                    <option value="0">App (All)</option>
                    <option value="1">App (Legit Check)</option>
                    <option value="2">App (Market Place)</option>
                    <option value="3">App (Multi Brand)</option>
                  </select>
                </div>

                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Expired at
                  </h1>
                  <Input
                    type={"date"}
                    handleChange={handleChange}
                    value={moment(values.expired_at).format().slice(0, 10)}
                    disable={false}
                    name={"expired_at"}
                    placeholder={"Place holder"}
                  />
                  {touched.expired_at && errors.expired_at && (
                    <h1 className="text-primary -mt-2 text-xs">
                      {errors.expired_at}
                    </h1>
                  )}
                </div>
              </div>
              {values.scope === "3" && (
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Banner Description
                  </h1>
                  <Input
                    type={"text"}
                    handleChange={handleChange}
                    disable={false}
                    name={"description"}
                    value={values.description}
                    placeholder={"Place holder"}
                  />
                </div>
              )}
              <div className="flex justify-between w-full my-2">
                <div
                  className={
                    "w-1/2 " +
                    (values.banner_action === "0" ||
                    values.banner_action === "1"
                      ? "mr-7"
                      : "pr-3")
                  }
                >
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Banner Action
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.banner_action}
                    name={"banner_action"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="2">No Action</option>
                    {isAppValidator(values.scope) && (
                      <option value="1">App</option>
                    )}
                    <option value="0">Web</option>
                  </select>
                </div>
                {values.banner_action === "0" ? (
                  <div className="w-1/2">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Open With
                    </h1>
                    <select
                      onChange={handleChange}
                      value={values.navigation_type}
                      name={"navigation_type"}
                      className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                    >
                      <option selected hidden>
                        Select Here
                      </option>
                      {isAppValidator(values.scope) ? (
                        <option value="0">Web View</option>
                      ) : (
                        <option value="3">New Tab</option>
                      )}
                      {isAppValidator(values.scope) ? (
                        <option value="1">Browser</option>
                      ) : (
                        <option value="2">Current Tab</option>
                      )}
                    </select>
                  </div>
                ) : (
                  isAppValidator(values.scope) &&
                  values.banner_action === "1" && (
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Link to
                      </h1>
                      <select
                        onChange={handleChange}
                        value={values.screen_name}
                        name={"screen_name"}
                        className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                      >
                        <option selected hidden>
                          Select Here
                        </option>
                        <option value="1">Product Detail (Market Place)</option>
                        <option value="0">Product Group (Market Place)</option>
                        <option value="3">Product Detail (Multi Brand)</option>
                        <option value="2">Store (Multi Brand)</option>
                        <option value="4">Featured Store (Multi Brand)</option>
                        <option value="5">Ticket Detail (Multi Brand)</option>
                        <option value="6">Ticket Variant (Multi Brand)</option>
                      </select>
                      {touched.screen_name && errors.screen_name && (
                        <p className="text-primary text-xs">
                          {errors.screen_name}
                        </p>
                      )}
                    </div>
                  )
                )}
              </div>

              {values.banner_action === "0" && (
                <div className="flex justify-between w-full my-2">
                  <div className="w-full">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Web Url
                    </h1>
                    <Input
                      type={"text"}
                      handleChange={handleChange}
                      disable={false}
                      name={"url"}
                      placeholder={"Place holder"}
                      value={values.url}
                    />
                  </div>
                </div>
              )}

              {values.screen_name === "1" &&
                isAppValidator(values.scope) &&
                values.banner_action === "1" && (
                  <div className="flex justify-between w-full my-2">
                    <div className="w-full">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Product Detail
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={{
                          value: values.product_id,
                          label:
                            values.product_name ||
                            data?.data?.product?.name ||
                            "Select here",
                        }}
                        loadOptions={loadOptionsProduct}
                        onChange={(payload: any) =>
                          handleChangeProduct(payload, setValues, values)
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                )}

              {values.screen_name === "2" &&
                isAppValidator(values.scope) &&
                values.banner_action === "1" && (
                  <div className="flex justify-between w-full my-2">
                    <div className="w-full">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Store
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={{
                          value: values.retail_shop_id,
                          label:
                            values.retail_name ||
                            data?.data?.retail_shop_id?.name ||
                            "Select here",
                        }}
                        loadOptions={loadOptionsStore}
                        onChange={(payload: any) =>
                          handleChangeStore(payload, setValues, values)
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                )}

              {values.screen_name === "3" &&
                isAppValidator(values.scope) &&
                values.banner_action === "1" && (
                  <div className="flex justify-between w-full my-2">
                    <div className="w-full">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Product Detail
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={{
                          value: values.retail_product_id,
                          label:
                            values.product_retail_name ||
                            data?.data?.retail_product?.name ||
                            "Select here",
                        }}
                        loadOptions={loadOptionsProductStore}
                        onChange={(payload: any) =>
                          handleChangeProductstore(payload, setValues, values)
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                )}

              {values.screen_name === "5" &&
                isAppValidator(values.scope) &&
                values.banner_action === "1" && (
                  <div className="flex justify-between w-full my-2">
                    <div className="w-full">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Ticket Detail
                      </h1>
                      <AsyncSelect
                        value={{
                          value: values.ticket_id,
                          label:
                            values.ticket_name ||
                            values.ticket_id ||
                            data?.data?.ticket_name ||
                            "Select here",
                        }}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptTicket}
                        onChange={(payload: any) =>
                          handleChangeTicket(payload, setValues, values)
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                )}

              {isAppValidator(values.scope) &&
                values.banner_action === "1" &&
                values.screen_name === "0" && (
                  <div>
                    {values.screen_name === "0" &&
                      isAppValidator(values.scope) &&
                      values.banner_action === "1" && (
                        <div className="w-full my-2">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Select Category (Minimal 1)
                          </h1>
                          <div className="my-2 flex justify-between">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={brand}
                                  onChange={(e: any) =>
                                    handleChangeChecked(e, brand, setBrand)
                                  }
                                  name="brand"
                                  style={{ color: "#ed4846" }}
                                />
                              }
                              label="Product Brand"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={tag}
                                  onChange={(e: any) =>
                                    handleChangeChecked(e, tag, setTag)
                                  }
                                  name="tag"
                                  style={{ color: "#ed4846" }}
                                />
                              }
                              label="Custom Tags"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={category}
                                  onChange={(e: any) =>
                                    handleChangeChecked(
                                      e,
                                      category,
                                      setCategory,
                                      setCategoryGroup
                                    )
                                  }
                                  name="category"
                                  style={{ color: "#ed4846" }}
                                />
                              }
                              label="Category"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={category_group}
                                  onChange={(e: any) =>
                                    handleChangeChecked(
                                      e,
                                      category_group,
                                      setCategoryGroup,
                                      setCategory
                                    )
                                  }
                                  name="category_group"
                                  style={{ color: "#ed4846" }}
                                />
                              }
                              label="Category Group"
                            />
                          </div>
                          <h1
                            className="text-sm font-rom"
                            style={{ color: "#707070" }}
                          >
                            Note : Cannot choose both Category and Category
                            Group.
                          </h1>
                        </div>
                      )}

                    <div className="mt-4">
                      {brand && isAppValidator(values.scope) && (
                        <div className="flex justify-between w-full my-2">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              Product Brand
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              value={{
                                value: values.product_brand_id,
                                label:
                                  values.product_brand_name ||
                                  data?.data?.product_brand?.name ||
                                  "Select here",
                              }}
                              loadOptions={loadOptionsBrand}
                              onChange={(payload: any) =>
                                handleChangeBrand(payload, setValues, values)
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}

                      {tag && isAppValidator(values.scope) && (
                        <div className="flex justify-between w-full my-2">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              Tag
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              value={{
                                value: values.tag_id,
                                label:
                                  values.tag_name ||
                                  data?.data?.tag?.name ||
                                  "Select here",
                              }}
                              loadOptions={loadOptionsTag}
                              onChange={(payload: any) =>
                                handleChangeTag(payload, setValues, values)
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}

                      {category && isAppValidator(values.scope) && (
                        <div className="flex justify-between w-full my-2">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              Category
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              value={{
                                value: values.product_category_id,
                                label:
                                  values.product_category_name ||
                                  data?.data?.product_category?.name ||
                                  "Select here",
                              }}
                              loadOptions={loadOptionsCategory}
                              onChange={(payload: any) =>
                                handleChangeCategory(payload, setValues, values)
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}

                      {category_group && isAppValidator(values.scope) && (
                        <div className="flex justify-between w-full my-2">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              Category Group
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              value={{
                                value: values.product_category_group_id,
                                label:
                                  values.product_category_group_name ||
                                  data?.data?.product_category_group?.name ||
                                  "Select here",
                              }}
                              loadOptions={loadOptionsCategoryGroup}
                              onChange={(payload: any) =>
                                handleChangeCategoryGroup(
                                  payload,
                                  setValues,
                                  values
                                )
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
            <button
              type="reset"
              onClick={() => handleReset({})}
              className="focus:outline-none"
            >
              <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              {isSubmitting ? (
                <CircularLoad color={"black"} height={16} width={16} />
              ) : (
                <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                  Save
                </h1>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditForm;
