import React, { useContext, useEffect } from "react";
import Pagination from "../../../components/pagination/Pagination";
import OnBiddingContext from "../../../store/on_bidding/OnBiddingContext";
import OnBiddingCard from "./OnBiddingCard";

interface IProps {
  data: any;
}

const OnBiddingList: React.FC<IProps> = ({ data }) => {
  const { onBiddingDispatch } = useContext(OnBiddingContext);
  useEffect(() => {
    onBiddingDispatch({
      type: "set_default_size",
      payload: data?.data?.data.map((el: any) => ({
        size: el.selected_bnib_buy_order_detail?.product_size?.size,
        id: el.selected_bnib_buy_order_detail?.product_size_id,
      })),
    });
    onBiddingDispatch({
      type: "set_default_product",
      payload: data?.data?.data.map((el: any) => el.id),
    });
    onBiddingDispatch({
      type: "set_default_buyer",
      payload: data?.data?.data.map((el: any) => ({
        username: el.selected_bnib_buy_order_detail?.buyer?.username,
        id: el.selected_bnib_buy_order_detail?.buyer?.id,
      })),
    });
    onBiddingDispatch({
      type: "set_default_status",
      payload: data?.data?.data.map(
        (el: any) => el?.selected_bnib_buy_order_detail?.pre_order
      ),
    });
  }, [data]);

  if (data?.data?.data.length === 0) {
    return (
      <div className="flex justify-center h-24 w-full my-10">
        <div className="w-full h-full border rounded-md flex justify-center items-center">
          <h1>Empty Data</h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="w-full">
        {data?.data?.data.map((el: any, index: number) => (
          <OnBiddingCard data={el} index={index} key={index} />
        ))}
      </div>
      <Pagination
        length={data?.data.data.length}
        total={data?.data.meta.total}
        isTable={false}
      />
    </div>
  );
};

export default OnBiddingList;
