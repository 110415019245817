import { cancel } from "../../assets/icons";
import LanguageSelector from "../input/SelectLanguange";

export interface NavDetailProps {
  handleBack: () => void;
  title1: string;
  title2: string;
}

const NavDetail: React.FC<NavDetailProps> = ({
  handleBack,
  title1,
  title2,
}) => {
  return (
    <nav className="font-med fixed z-30 flex flex-wrap items-center justify-between px-6 md:px-12 xl:px-16 py-2 w-full md:h-20 sm:h-16 h-12 left-0 bg-black shadow-md">
      <div className="w-full flex items-center justify-between">
        <div className="w-1/5" />
        <div className="flex items-center justify-center w-3/5">
          <h1 className="text-primary mr-1.5 md:text-2xl text-base text-center">
            {title1}
          </h1>
          <h1 className="text-white md:text-2xl text-base">{title2}</h1>
        </div>
        <div className="flex items-center justify-end sm:w-1/6 w-1/2">
          {/* <LanguageSelector /> */}
          <img
            src={cancel}
            onClick={handleBack}
            className="md:w-5 w-4 cursor-pointer"
            alt="cancel"
          />
        </div>
      </div>
    </nav>
  );
};

export default NavDetail;
