import React from "react";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { formatRupiah } from "../../helpers/common";
import Abutton from "../atoms/Abutton";
import MproductListCard from "./MproductListCard";
import Countdown from "react-countdown";
import Acountdown from "../atoms/Acountdown";
import {
  EBnibBuyOrderStatus,
  IBnibBuyOrder,
} from "../../core/bnib_buy_order/entities";
import { Awhatsapp } from "../atoms/icons";

export const renderStatusColor = (status: number) => {
  const statusColor: any = {
    4: "text-primary",
    11: "text-warning",
    12: "text-primary",
  };
  return statusColor[status];
};
interface IMbnibBuyOrderCardProps {
  status: number;
  data: IBnibBuyOrder;
}

const MbnibBuyOrderCard: React.FC<IMbnibBuyOrderCardProps> = ({
  status,
  data,
}) => {
  const navigate = useHistory();
  const { pathname } = useLocation();
  // const [more, setMore] = useState(false);
  // const length = 1;

  const WHATSAPP_ROUTE = "https://api.whatsapp.com/send?phone";

  const goDetail = (code: string) => {
    navigate.push(`${pathname}/${code}`);
  };
  const goDetailMobile = (code: string) => {
    navigate.push(`${pathname}/detail/${code}`);
  };

  return (
    <aside className="w-full bg-white rounded-lg shadow-md sm">
      <main>
        <article className="sm:px-5 sm:pt-5 sm:pb-5 px-3 pt-3 pb-0 flex justify-between sm:border-b-2 sm:text-sm text-xxxs">
          <div className="flex lg:flex-row flex-col gap-1 w-4/5">
            <span className="text-primary">{data?.code}</span>
            <span>| ID: {data?.id} |</span>
            <span className="text-textSecondary">
              Order Date : {moment(data?.created_at).format("llll")}
            </span>
          </div>

          {data.status === EBnibBuyOrderStatus.WaitingPayment && (
            <div className="sm:w-auto w-full gap-1">
              <div className="flex justify-between items-center ">
                <h1 className="font-rom mr-1">Time Remaining:</h1>
                <Countdown
                  zeroPadTime={2}
                  date={data?.payment_invoice?.expired_at}
                  renderer={(props) => (
                    <Acountdown
                      seconds={props.seconds}
                      minutes={props.minutes}
                      hours={props.hours}
                      days={props.days}
                      completed={props.completed}
                    />
                  )}
                />
              </div>
              <hr className="sm:hidden sm:mt-1.5 mt-0.5" />
            </div>
          )}
        </article>

        <article className="sm:p-5 p-3">
          <aside className="grid sm:grid-cols-4 grid-cols-1 sm:gap-5 gap-2">
            <div className="sm:border-r-2 col-span-3 sm:pr-5">
              <div className="grid sm:grid-cols-5 grid-cols-4 sm:gap-3 gap-1 sm:text-xs text-xxxs text-textPrimary">
                <div className="border-r-2 pr-3 sm:col-span-2 col-span-2">
                  <MproductListCard
                    image={data?.product_detail?.display_image_url}
                    name={data?.product_detail?.name || "-"}
                    sku={data?.product_detail?.code}
                  />
                </div>

                <div className="flex flex-col border-r-2 ">
                  <h1 className="">Condition & Size</h1>
                  <h1 className="font-rom sm:mt-1.5 mt-0.5 break-words">
                    {data.pre_order ? "Pre-Order" : "BNIB"} |{" "}
                    {data?.product_size}
                  </h1>
                </div>

                <div className="flex sm:border-r-2 flex-col">
                  <h1 className="">Price</h1>
                  <h1 className="font-rom sm:mt-1.5 mt-0.5 break-words">
                    {formatRupiah(data?.price)}
                  </h1>
                </div>
                <div className="sm:flex hidden flex-col">
                  <h1 className="">Seller</h1>
                  <div className="sm:mt-1.5 mt-0.5 font-rom flex items-center gap-1">
                    <h1 className="break-words">
                      {data?.seller_username|| "-"}
                    </h1>
                    {data?.seller_phone && (
                      <a
                        href={`${WHATSAPP_ROUTE}=62${data.seller_phone}`}
                        target="blank"
                      >
                        <Awhatsapp />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <hr className="mt-3" />
            </div>

            <div className="grid sm:grid-cols-1 grid-cols-2 gap-x-3">
              <div className="flex flex-col text-textPrimary sm:text-xs text-xxxs">
                <h1>Customer</h1>
                <div className="sm:mt-1.5 only:font-rom flex items-center gap-1">
                  <h1>{data?.buyer_username || "-"}</h1>
                  <a
                    href={`${WHATSAPP_ROUTE}=62${data.buyer_phone}`}
                    target="blank"
                  >
                    <Awhatsapp />
                  </a>
                </div>
                <div className="sm:mt-1.5">
                  <h1>Status</h1>
                  <h1
                    className={"sm:mt-1.5 " + renderStatusColor(data?.status)}
                  >
                    {data?.status_message}
                  </h1>
                </div>
              </div>

              <div className="sm:hidden flex border-l-2 pl-2 flex-col text-textPrimary sm:text-xs text-xxxs">
                <h1 className="">Seller</h1>
                <div className="sm:mt-1.5 mt-0.5 font-rom flex items-center gap-1">
                  <h1 className="break-words">
                    {data?.transaction?.seller_username || "-"}
                  </h1>
                  {data?.transaction?.seller_username && (
                    <a
                      href={`${WHATSAPP_ROUTE}=62${data.seller_phone}`}
                      target="blank"
                    >
                      <Awhatsapp />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </aside>
          <aside className="block my-4">
            <div className="bg-stroke rounded-md py-2.5 px-5 flex justify-between sm:text-xs text-xxs">
              <h1>Total Selling</h1>
              <h1 className="text-primary">{formatRupiah(data?.price || 0)}</h1>
            </div>
          </aside>
          <hr className="block" />
        </article>
      </main>
      <article className="flex sm:px-5 px-3 sm:pb-5 pb-3 justify-between">
        <div
          onClick={() => goDetailMobile(data.code)}
          className="flex items-center gap-5 font-rom sm:text-xs text-xxxs"
        >
          <div className="flex items-center gap-1 hover:text-textPrimary cursor-pointer">
            <span>Payment Method</span>
            <span>: {data.payment_invoice.payment_type}</span>
          </div>
        </div>

        <div className="flex sm:gap-3">
          <Abutton
            title="Details"
            theme="primary"
            model="long"
            onClick={() => goDetail(data.code)}
          />
        </div>
      </article>
    </aside>
  );
};

export default MbnibBuyOrderCard;
