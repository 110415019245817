import { IShippingAddress } from "../entities";
import IShippingAddressRepository from "../repository";
import IShippingAddressUsecase from "../usecase";

export default class ShippingAddressUsecase implements IShippingAddressUsecase {
  private ShippingAddressRepo: IShippingAddressRepository;

  constructor(ShippingAddressRepo: IShippingAddressRepository) {
    this.ShippingAddressRepo = ShippingAddressRepo;
  }

  async getShippingAddressById(id: number): Promise<IShippingAddress> {
    const ShippingAddress =
      await this.ShippingAddressRepo.getShippingAddressById(id);
    return ShippingAddress;
  }

  async getShippingAddressByUserId(
    user_id: number
  ): Promise<IShippingAddress[]> {
    const ShippingAddress =
      await this.ShippingAddressRepo.getShippingAddressByUserId(user_id);
    return ShippingAddress;
  }
}
