import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import Input from "../../../../components/input/Input";
import * as Yup from "yup";
import { TagsRequest } from "../../../../apis/tags/TagsRequest";
import { useHistory, useParams } from "react-router-dom";
import CircularLoad from "../../../../components/loading/CircularLoad";
import Toggle from "../../../../components/button/Toggle";

interface IProps {
  data: any;
  dataBanner: any;
}

const TagInformation: React.FC<IProps> = ({ data, dataBanner }) => {
  const params: { id: string } = useParams();
  const history = useHistory();
  
  useEffect(() => {
    setValues({
      ...values,
      name: (data as any)?.name,
      is_active: (data as any)?.is_active,
    });
    return () => {
      setValues({ ...values, name: "", is_active: false });
    };
  }, [data]);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      is_active: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const handleActive = (setValues: any, values: any) => {
    setValues({ ...values, is_active: !values.is_active });
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TagsRequest.updateTags(+params.id, values, goBack, setSubmitting),
    {
      onMutate: () => {
        console.log("mutatte");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTags");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  const goBack = () => {
    history.push("/marketplace/tags");
  };

  return (
    <>
      <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">Tagged in Banner</h1>
        </div>
        <hr />

        <div className="md:mx-8 mx-5 md:my-6 my-5">
          <div className="flex flex-col justify-center">
            {dataBanner.length <= 0 ? (
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">Banner</h1>
                <Input
                  name={"banner"}
                  value={"-"}
                  disable={true}
                  type={"text"}
                />
              </div>
            ) : (
              dataBanner?.map((el: any, index: number) => (
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    {`Banner ${index + 1}`}
                  </h1>
                  <Input
                    name={"banner"}
                    value={el.title}
                    disable={true}
                    type={"text"}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="md:w-custom md:mt-0 mt-10 w-full h-full"
      >
        <div className="rounded-md rounded-b-none border-2 border-stroke">
          <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Tag Information
            </h1>

            <div className="flex">
              <h1 className="font-medium md:text-lg text-sm mr-3 text-textPrimary">
                Active
              </h1>
              <Toggle
                handleClick={() => handleActive(setValues, values)}
                input={values.is_active}
              />
            </div>
          </div>
          <hr />

          <div className="md:flex w-full mb-8">
            <div className="md:mx-8 mx-5 mt-7 md:w-full">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">Name</h1>
                <Input
                  type={"text"}
                  handleChange={handleChange}
                  value={values.name}
                  disable={false}
                  name={"name"}
                  placeholder={"Place holder"}
                />
                {touched.name && errors.name ? (
                  <h1 className="text-primary -mt-1 text-sm">{errors.name}</h1>
                ) : null}
              </div>
              <div className="mt-3">
                <h1 className="md:text-base text-sm text-textPrimary">Slug</h1>
                <Input
                  type={"text"}
                  value={data.slug}
                  disable={true}
                  name={"slug"}
                  placeholder={"Place holder"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
            <button
              type="reset"
              onClick={() => handleReset({})}
              className="focus:outline-none"
            >
              <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              {isSubmitting ? (
                <CircularLoad color={"black"} height={16} width={16} />
              ) : (
                <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                  Save
                </h1>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default TagInformation;
