import React from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";

import {
  AddProductCategoryGroup,
  ProductCategoryGroup,
  AddCategories,
  Categories,
  DeleteCategories,
  DetailCategories,
  AddDetailCategories,
  DeleteDetailCategories,
  EditDetailCategories,
} from "../../../pages/product_category_group";
const ProductCategoryGroupRoute = () => {
  return (
    <div>
      <Switch>
        <Route
          path="/marketplace/product-category-group"
          exact
          component={ProductCategoryGroup}
        />
        <Route
          path="/marketplace/product-category-group/add-product-category-group"
        //   exact
          component={AddProductCategoryGroup}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id"
          exact
          component={Categories}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id/add-categories"
          component={AddCategories}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id/delete/:id"
          component={DeleteCategories}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id/detail-categories/:detail"
          exact
          component={DetailCategories}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id/detail-categories/:detail/add-detail-categories"
          component={AddDetailCategories}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id/detail-categories/:detail/delete/:subDetail"
          component={DeleteDetailCategories}
        />
        <Route
          path="/marketplace/product-category-group/categories/:id/detail-categories/:detail/edit/:subDetail"
          component={EditDetailCategories}
        />
        <Redirect
          from="/marketplace"
          to="/marketplace/product-category-group"
          exact
        />
      </Switch>
    </div>
  );
};

export default ProductCategoryGroupRoute;
