import _ from "lodash";
import qs from "qs";
import { AxiosInstance } from "../../../config/AxiosInstance";
import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  EBnibTransactionSortField,
  IBnibTransaction,
  IBnibTransactionCounter,
  PBnibTransactionFilter,
} from "../entities";
import IBnibTransactionRepository from "../repository";

export default class BnibTransactionRepository
  implements IBnibTransactionRepository
{
  // api routes
  private BNIB_TRANSCTION_ROUTE = "/admin/bnib-transaction";

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  buildFilter(filter: PBnibTransactionFilter) {
    const created_at = filter.date;
    const status = filter.status?.split(",");
    const clonedFilter = { ...filter, status };
    delete clonedFilter.date;
    return {
      ...clonedFilter,
      created_at,
    };
  }

  buildSort(sort: IBuiltinSort<EBnibTransactionSortField>[]) {
    let strSort = "";
    sort.map((el) => (strSort += el.field));
    return strSort;
  }

  async getBnibTransactions(
    pagination: IPagination,
    filter: PBnibTransactionFilter,
    sorts: IBuiltinSort<EBnibTransactionSortField>[]
  ): Promise<[IBnibTransaction[], IMeta]> {
    try {
      const params = _.pickBy(
        {
          ...pagination,
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const sort = this.buildSort(sorts);

      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_TRANSCTION_ROUTE}?sort=${sort}`,
        {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      const { data, meta } = response.data;
      return [data, meta];
    } catch (error) {
      throw this.getError(error);
    }
  }

  async getBnibTransaction(code: string): Promise<IBnibTransaction> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async getBnibTransactionTrack(code: string): Promise<any[]> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/track`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async getBnibTransactionProcess(code: string): Promise<any[]> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/transaction-process`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async getBnibTransactionCounter(
    filter: PBnibTransactionFilter
  ): Promise<IBnibTransactionCounter[]> {
    try {
      const params = _.pickBy(
        {
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_TRANSCTION_ROUTE}/counter`,
        {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async arriveBnibTransaction(
    code: string,
    payload: {
      location_dest_name: string;
    }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/arrived`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async disputeBnibTransaction(
    code: string,
    payload: { reject_reason: string; location_dest_name: string }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/disputed`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async acceptBnibTransaction(
    code: string,
    payload: {
      location_dest_name: string;
    }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/accepted`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async rejectBnibTransaction(
    code: string,
    payload: { reject_reason: string; location_dest_name: string }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/rejected`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async defectBnibTransaction(
    code: string,
    payload: FormData
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/defect`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async readyForLegitCheck(code: string): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/ready-for-legit-check`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async confirmFinalResult(
    code: string,
    payload: { result: string }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/lc`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async readyShippingToBuyer(code: string): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/ready-shipping-for-buyer`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async sendProductToBuyer(
    code: string,
    payload: { pickup_time: string }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/send-product-to-buyer`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async readyShippingToSeller(code: string): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/ready-shipping-for-seller`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async sendProductToSeller(
    code: string,
    payload: { pickup_time: string }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/send-product-to-seller`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async receipt(code: string, payload: FormData): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/receipt`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async transferStock(
    code: string,
    payload: {
      location_dest_name: string;
    }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.BNIB_TRANSCTION_ROUTE}/${code}/transfer`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async printShippingLabel(code: string): Promise<{ data: string }> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `admin/popaket/${code}/download-label`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }
}
