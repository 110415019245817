import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddFeaturedProduct from "../../../pages/featured_product/AddFeaturedProduct";
import EditFeaturedProduct from "../../../pages/featured_product/EditFeaturedProduct";
import FeaturedProduct from "../../../pages/featured_product/FeaturedProduct";
import {
  FeaturedProductContextProvider,
  initialFeaturedProductState,
} from "../../../store/featured_product/FeaturedProductContext";
import { FeaturedProductReducer } from "../../../store/featured_product/FeaturedProductReducer";

const FeaturedProductRoute = () => {
  const [FeaturedProductState, FeaturedProductDispatch] = useReducer(
    FeaturedProductReducer,
    initialFeaturedProductState
  );
  return (
    <FeaturedProductContextProvider
      value={{ FeaturedProductState, FeaturedProductDispatch }}
    >
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route
            path="/marketplace/featured-product"
            exact
            component={FeaturedProduct}
          />
          <Route
            path="/marketplace/featured-product/add-featured-product"
            exact
            component={AddFeaturedProduct}
          />
          <Route
            path="/marketplace/featured-product/edit-featured-product/:id"
            exact
            component={EditFeaturedProduct}
          />
          <Redirect
            from="/marketplace"
            to="/marketplace/featured-product"
            exact
          />
        </Switch>
      </div>
    </FeaturedProductContextProvider>
  );
};

export default FeaturedProductRoute;
