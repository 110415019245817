import React, { useReducer } from "react";
import { useQuery } from "react-query";
import Loading from "../../components/loading/Loading";
import AddTopup from "./top_up_component/AddTopup";
import TopupFilter from "./top_up_component/TopupFilter";
import TopupTable from "./top_up_component/TopupTable";
import TopupRequest from "../../apis/top_up/Topup";
import {
  initialTopupState,
  TopupContextProvider,
} from "../../store/topup/TopupContext";
import { TopupReducer } from "../../store/topup/TopupReducer";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import { optRoutes, optSorting, queryparamsTopUp } from "./helpers/list";
import Sorting from "../../components/sorting/Sorting";
import Chip from "../../components/chip/Chip";
import { default_sort } from "./helpers/setDefaultSorting";
import CircularLoad from "../../components/loading/CircularLoad";
import CancelTopup from "./top_up_component/CancelTopup";
import { useLocation } from "react-router-dom";
import { useQueries } from "../../utils/query_searchParams/QuerySearchParams";
import LocalStorage from "../../config/LocalStorage";

const Topup = () => {
  const [topupState, topupDispatch] = useReducer(
    TopupReducer,
    initialTopupState
  );
  const query = useQueries(useLocation);
  const role = LocalStorage.ROLE || localStorage.getItem('ROLE');

  const { data, isLoading, isFetching } = useQuery(
    [
      "getTopup",
      query.get("payment_channel"),
      query.get("payment_status"),
      query.get("code"),
      query.get("username"),
      query.get("top_up_from"),
      query.get("top_up_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => TopupRequest.getTopup(topupDispatch, role),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <TopupContextProvider value={{ topupDispatch, topupState }}>
      <div className="md:pt-28 sm:pt-20 pt-16">
        <DynamicBreadcrumb data={optRoutes} />
        {topupState.showCancel.status && <CancelTopup />}
        <TopupFilter />
        <AddTopup />

        <div className="md:mt-12 mt-10">
          <div className="flex justify-end">
            <h1
              onClick={() => topupDispatch({ type: "show_topup" })}
              className="text-primary md:text-base cursor-pointer inline-flex"
            >
              Top Up Wallet (Admin)
            </h1>
          </div>

          <div className="flex justify-between mt-4">
            <div className="flex">
              <h1
                onClick={() => topupDispatch({ type: "show_filter" })}
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <Sorting
              dispatchContext={topupDispatch}
              stateContext={topupState}
              option={optSorting}
              defaultSort={default_sort}
            />
          </div>

          <Chip
            dispatchContext={topupDispatch}
            stateContext={topupState}
            queryParams={queryparamsTopUp}
          />
          <div className="flex justify-end mt-5">
            {isFetching ? (
              <div className="flex items-center">
                <CircularLoad color="#DF5354" height={20} width={20} />
                <h1 className="ml-2">Updating</h1>
              </div>
            ) : (
              <h1>Auto Update</h1>
            )}
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div className="flex">
            <TopupTable data={data} />
          </div>
        )}
      </div>
    </TopupContextProvider>
  );
};

export default Topup;
