const location = document.location.search;
const queryParams = new URLSearchParams(location);

export let default_sort = queryParams.get("sort");
if (default_sort === "created_at") {
  default_sort = "Date";
} else if (default_sort === "name") {
  default_sort = "Name";
} else {
  default_sort = "Date";
}
