import moment from "moment";
import React, { useState } from "react";
import { down, up } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import PriceInput from "../../../../components/input/PriceInput";
import TextArea from "../../../../components/input/TextArea";

export interface TopupInformationProps {
  data: any;
}

const TopupInformation: React.FC<TopupInformationProps> = ({ data }) => {
  const [dropdownTrans, setdropdownTrans] = useState<boolean>(true);

  const handledropdownTrans = () => {
    setdropdownTrans(!dropdownTrans);
  };

  return (
    <div className="w-full h-full">
      <div className="rounded-md border-2 border-stroke">
        {/*transactions information title and toggle*/}
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">
            Transaction Information
          </h1>

          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdownTrans ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownTrans}
                />
              ) : (
                <img
                  src={down}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownTrans}
                />
              )}
            </div>
          </div>
        </div>
        {dropdownTrans && <hr className="border-t-2" />}

        {dropdownTrans && (
          <div>
            {/*transactions Code*/}
            <div className="md:mx-8 mx-5 md:mt-6 mt-3 md:pt-0 pt-2">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Top up Code
                </h1>
                <Input
                  disable={true}
                  type={"text"}
                  name={"code"}
                  value={data?.code}
                  placeholder={"Place holder"}
                />
              </div>
            </div>

            <div className="md:flex w-full mt-4">
              {/*transactions data left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">Top Up Id</h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.id}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Payment Status
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Status"}
                    value={data?.payment_status}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Payment Method
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"method"}
                    value={data?.payment_type?.split("_").join(" ")}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Top up Date
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"date"}
                    value={moment(data?.created_at).format("LL HH:mm")}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">Bank</h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"bank"}
                    value={data.bank}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              {/*transactions data right*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Payment Channel
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"channel"}
                    value={data?.payment_channel?.split("_").join(" ")}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Fraud Status
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"fraud"}
                    value={data?.fraud_status}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    VA Number
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"va_number"}
                    value={data?.virtual_account_number}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4 mb-5">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Top up by
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"by"}
                    value={data?.created_by}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">Bank</h1>
                  <PriceInput
                    disabled={true}
                    placeholder="Place holder"
                    displayType={"input"}
                    value={data.masked_card}
                    name={"card_number"}
                    format="#### #### #### ####"
                  />
                </div>
              </div>
            </div>

            <div className="md:mx-8 mx-5 mb-4 md:pt-0 pt-2">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Description
                </h1>
                <TextArea
                  rows={2}
                  disable={true}
                  name={"description"}
                  value={data?.description}
                  placeholder={"Place holder"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopupInformation;
