import { ActionType, CampaignAction } from "./action";
import { ICampaignState } from "./state";

export const CampaignReducer = (
  state: ICampaignState,
  action: CampaignAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowAddTermsCondition:
      let showAddTermsCondition = state.showAddTermsCondition;
      document.body.classList[!showAddTermsCondition ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddTermsCondition: !showAddTermsCondition };
    case ActionType.ShowAddQuantityShuffle:
      let showAddQuantityShuffle = action.payload;
      document.body.classList[showAddQuantityShuffle.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddQuantityShuffle };
    case ActionType.ShowDownloadQrCode:
      let showDownloadQrCode = state.showDownloadQrCode;
      document.body.classList[!showDownloadQrCode ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDownloadQrCode: !showDownloadQrCode };
    case ActionType.ShowAddCampaign:
      let showAddCampaign = state.showAddCampaign;
      document.body.classList[!showAddCampaign ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddCampaign: !showAddCampaign };
    case ActionType.ShowModalSecurityCode:
      let showModalSecurityCode = action.payload;
      document.body.classList[showModalSecurityCode.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showModalSecurityCode };
    case ActionType.ShowModalListProduct:
      let showModalListProduct = action.payload;
      document.body.classList[showModalListProduct.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showModalListProduct };
    case ActionType.ShowProductPerSize:
      let showProductPerSize = action.payload;
      document.body.classList[showProductPerSize.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showProductPerSize };
    case ActionType.ShowDeleteCampaign:
      let showDeleteCampaign = action.payload;
      document.body.classList[showDeleteCampaign.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDeleteCampaign };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.HandleLimit:
      let new_limit = action.payload;
      return { ...state, limit: new_limit };
    case ActionType.HandleOffset:
      let new_offset = action.payload;
      return { ...state, offset: new_offset };
    case ActionType.AddDataSpinner:
      let data = action.payload;
      return { ...state, data_spinner: data };
    case ActionType.DropWinner:
      let drop_winner = action.payload;
      return { ...state, drop_winner };
    case ActionType.ConfirmWinner:
      let confirm_winner = action.payload;
      return { ...state, confirm_winner };
  }
};
