import { useQuery } from "react-query";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";

const GetSizingGroup = (
  product_category_id: string,
  product_brand_id: string,
  sex: string
) => {
  const { data, isLoading } = useQuery(
    ["GetSizingGroup", product_category_id, product_brand_id, sex],
    () => SizingRequest.getSizingGroup(product_category_id, product_brand_id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetSizingGroup;
