export const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/on-bidding",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc&&filter=false",
    name: "On Bidding",
  },
];

export const optRoutesDetail = (code: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/on-bidding",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc&&filter=false",
      name: "On Bidding",
    },
    {
      id: 3,
      path: `/marketplace/on-bidding/${code}`,
      name: "Details",
    },
  ];
};

export const optRoutesSeeAll = (code: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/on-bidding",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc&&filter=false",
      name: "On Bidding",
    },
    {
      id: 3,
      path: `/marketplace/on-bidding/${code}/see-all`,
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
      name: "See all",
    },
  ];
};

export const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
];

export const queryparams: any[] = [
  { real_name: "product_id", show_name: "product" },
  { real_name: "pre_order", show_name: "status" },
  { real_name: "seller_id", show_name: "username" },
  { real_name: "exclude_buyer_id", show_name: "remove_username" },
];

export const queryparamsGroupProductSize: any[] = [
  { real_name: "product_id", show_name: "product" },
  { real_name: "pre_order", show_name: "status" },
  { real_name: "seller_id", show_name: "username" },
  { real_name: "selling_time_from", show_name: "selling_time_from" },
  { real_name: "selling_time_to", show_name: "selling_time_to" },
  { real_name: "exclude_buyer_id", show_name: "remove_username" },
];
