import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import moment from "moment";
import { useEffect } from "react";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import { ScopeType } from "../../../helpers/list";
import isEmpty from "lodash.isempty";
import { isNumber } from "lodash";

const AddCampaignProductForm = (data: any) => {
  const history = useHistory();
  const params: { id: string; templateId: string } = useParams();
  const { data: dataTemplate, isLoading: loadTemplate } = GetCampaignTemplateId(
    params.templateId
  );

  useEffect(() => {
    if (dataTemplate) {
      setValues({
        ...values,
        form: "edit",
        scope: { value: dataTemplate.scope, label: dataTemplate.scope },
        product_name: dataTemplate.product_name,
        name: {
          value: dataTemplate.product_id,
          label: dataTemplate.product_name,
        },
        active: dataTemplate.active,
        color: dataTemplate.color,
        transaction_room_capacity: dataTemplate.transaction_room_capacity,
        transaction_room_duration_minutes:
          dataTemplate.transaction_room_duration_minutes,
        start_join_waiting_room_at: moment(
          dataTemplate.start_join_waiting_room_at
        ).format("YYYY-MM-DDTHH:mm"),
        start_pre_waiting_room_at: moment(
          dataTemplate.start_pre_waiting_room_at
        ).format("YYYY-MM-DDTHH:mm"),
        freeze_time_at: dataTemplate.freeze_time_at
          ? moment(dataTemplate.freeze_time_at).format("YYYY-MM-DDTHH:mm")
          : "",
        estimate_start_time_at: dataTemplate.estimate_start_time_at
          ? moment(dataTemplate.estimate_start_time_at).format(
              "YYYY-MM-DDTHH:mm"
            )
          : "",
        end_campaign_at: moment(dataTemplate.end_campaign_at).format(
          "YYYY-MM-DDTHH:mm"
        ),
        option_for_video: dataTemplate?.detail_video_url ? "true" : "false",
        detail_video_url: dataTemplate?.detail_video_url
          ? dataTemplate?.detail_video_url
          : "",
        subtitle: dataTemplate?.subtitle,
        term_and_condition: dataTemplate?.term_and_condition,
        credit_price:
          dataTemplate?.credit_price > 0 ? dataTemplate?.credit_price : "",
        is_available_for_buy:
          dataTemplate?.credit_price > 0 ? "available" : "not_available",
        delivery_option: dataTemplate?.delivery_option,
      });
    }
    return function () {
      handleReset({});
    };
  }, [dataTemplate]);

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      form: "add",
      campaign_id: params.id,
      name: { label: "", value: "" },
      product_name: "",
      active: true,
      option: true,
      option_for_video: "false",
      color: "",
      transaction_room_capacity: "",
      transaction_room_duration_minutes: "",
      start_pre_waiting_room_at: "",
      start_join_waiting_room_at: "",
      end_campaign_at: "",
      freeze_time_at: "",
      detail_video_url: "",
      detail_image: "",
      scope: { label: "Special Price (FIFO)", value: ScopeType.BASIC },
      subtitle: "",
      term_and_condition: "",
      estimate_start_time_at: "",
      delivery_option: "onsite",
      is_available_for_buy: "available",
      credit_price: "",
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        value: Yup.string().required("Product Name is Required"),
      }),
      color: Yup.string().required("Background color is Required"),
      product_name: Yup.string().required("Display name is Required"),
      detail_video_url: Yup.string().when("option_for_video", {
        is: "true",
        then: Yup.string().required("Detail video url is Required"),
      }),
      subtitle: Yup.string().when("scope.value", {
        is: (scope: string) =>
          scope === ScopeType.GIVEAWAYRAFFLE ||
          scope === ScopeType.GIVEAWAYTOPCREDIT,
        then: Yup.string().required("Show price is Required"),
      }),
      transaction_room_capacity: Yup.number().when("scope.value", {
        is: ScopeType.BASIC,
        then: Yup.number()
          .min(1, "Minimum of room capacity is 1 person")
          .required("Transaction room capacity is Required"),
      }),
      transaction_room_duration_minutes: Yup.number().when("scope.value", {
        is: (scope: string) =>
          scope === ScopeType.BASIC ||
          scope === ScopeType.SPECIALPRICERAFFLE ||
          scope === ScopeType.GIVEAWAYRAFFLE,
        then: Yup.number()
          .min(1, "Minimum of room duration is 1 minute")
          .required("Transaction room duration is Required"),
      }),
      start_pre_waiting_room_at: Yup.date().when("scope.value", {
        is: ScopeType.BASIC,
        then: Yup.date().required("Start join waiting room is required"),
      }),
      start_join_waiting_room_at: Yup.date().when("scope.value", {
        is: ScopeType.BASIC,
        then: Yup.date().required("Start join raffle is required"),
      }),
      end_campaign_at: Yup.date().when("scope.value", {
        is: (scope: string) =>
          scope === ScopeType.BASIC ||
          scope === ScopeType.SPECIALPRICETOPCREDIT ||
          scope === ScopeType.GIVEAWAYTOPCREDIT,
        then: Yup.date().required("End raffle is required"),
      }),
      freeze_time_at: Yup.date().when("scope.value", {
        is: (scope: string) =>
          scope === ScopeType.GIVEAWAYRAFFLE ||
          scope === ScopeType.GIVEAWAYTOPCREDIT ||
          scope === ScopeType.SPECIALPRICERAFFLE ||
          scope === ScopeType.SPECIALPRICETOPCREDIT,
        then: Yup.date().required("Freeze time is required"),
      }),
      estimate_start_time_at: Yup.date().when("scope.value", {
        is: (scope: string) =>
          scope === ScopeType.GIVEAWAYRAFFLE ||
          scope === ScopeType.GIVEAWAYTOPCREDIT ||
          scope === ScopeType.SPECIALPRICERAFFLE ||
          scope === ScopeType.SPECIALPRICETOPCREDIT,
        then: Yup.date().required("Estimate start time is required"),
      }),
      credit_price: Yup.string().when(["scope.value", "is_available_for_buy"], {
        is: (scope: string, avail: string) =>
          scope !== ScopeType.BASIC && avail === "available",
        then: Yup.string().required("Credit price is required"),
      }),
    }),
    onSubmit: async () => {
      const formdata = new FormData();
      const date = new Date(data?.start_at);
      const end_date = new Date(data?.end_at);
      const start_pre_at = date.setSeconds(1);
      const start_at = date.setSeconds(2);
      const end_at = end_date.setSeconds(-1);

      formdata.append("product_id", values.name.value);
      formdata.append("scope", values.scope.value);
      formdata.append("product_name", values.product_name);
      formdata.append("campaign_id", values.campaign_id);
      formdata.append("color", values.color);
      formdata.append("delivery_option", values.delivery_option);
      formdata.append("active", values.active.toString());
      if (valueCardImage.card_image) {
        formdata.append("card_image", valueCardImage.card_image);
      }
      if (valuePreviewImage.preview_image) {
        formdata.append("preview_image", valuePreviewImage.preview_image);
      }
      if (values.option_for_video === "true") {
        formdata.append("detail_video_url", values.detail_video_url);
      } else if (
        values.option_for_video === "false" &&
        valueDetailImage.detail_image
      ) {
        formdata.append("detail_image", valueDetailImage.detail_image);
      }
      if (values.term_and_condition) {
        formdata.append("term_and_condition", values.term_and_condition);
      }
      if (
        values.is_available_for_buy === "available" &&
        values.scope.value !== ScopeType.BASIC
      ) {
        formdata.append(
          "credit_price",
          isNumber(values.credit_price)
            ? `${values.credit_price}`
            : values.credit_price.substring(4).split(",").join("").trim()
        );
      } else {
        formdata.append("credit_price", "0");
      }

      if (values.scope.value === ScopeType.BASIC) {
        formdata.append(
          "start_pre_waiting_room_at",
          `${values.start_pre_waiting_room_at}:00+07:00`
        );
        formdata.append(
          "start_join_waiting_room_at",
          `${values.start_join_waiting_room_at}:00+07:00`
        );
        formdata.append(
          "end_campaign_at",
          `${values.end_campaign_at}:00+07:00`
        );
        formdata.append(
          "transaction_room_capacity",
          values.transaction_room_capacity.toString()
        );
      } else if (
        values.scope.value === ScopeType.SPECIALPRICETOPCREDIT ||
        values.scope.value === ScopeType.GIVEAWAYTOPCREDIT
      ) {
        formdata.append(
          "start_pre_waiting_room_at",
          `${moment(start_pre_at)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss")
            .substring(0, 19)}+07:00`
        );
        formdata.append(
          "start_join_waiting_room_at",
          `${moment(start_at)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss")
            .substring(0, 19)}+07:00`
        );
        formdata.append(
          "end_campaign_at",
          `${values.end_campaign_at}:00+07:00`
        );
        formdata.append("freeze_time_at", `${values.freeze_time_at}:00+07:00`);
        formdata.append(
          "estimate_start_time_at",
          `${values.estimate_start_time_at}:00+07:00`
        );
        formdata.append("transaction_room_capacity", "1");
        formdata.append("transaction_room_duration_minutes", "1");
        if (valueSpinImage.spin_image) {
          formdata.append("cool_image", valueSpinImage.spin_image);
        }
      } else {
        formdata.append(
          "start_pre_waiting_room_at",
          `${moment(start_pre_at)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss")
            .substring(0, 19)}+07:00`
        );
        formdata.append(
          "start_join_waiting_room_at",
          `${moment(start_at)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss")
            .substring(0, 19)}+07:00`
        );
        formdata.append(
          "end_campaign_at",
          `${moment(end_at)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss")
            .substring(0, 19)}+07:00`
        );
        formdata.append("freeze_time_at", `${values.freeze_time_at}:00+07:00`);
        formdata.append(
          "estimate_start_time_at",
          `${values.estimate_start_time_at}:00+07:00`
        );
        formdata.append("transaction_room_capacity", "1");
        if (valueSpinImage.spin_image) {
          formdata.append("cool_image", valueSpinImage.spin_image);
        }
      }

      if (
        values.scope.value === ScopeType.GIVEAWAYRAFFLE ||
        values.scope.value === ScopeType.GIVEAWAYTOPCREDIT
      ) {
        formdata.append("subtitle", values.subtitle);
      }

      if (
        values.scope.value === ScopeType.BASIC ||
        values.scope.value === ScopeType.SPECIALPRICERAFFLE ||
        values.scope.value === ScopeType.GIVEAWAYRAFFLE
      ) {
        formdata.append(
          "transaction_room_duration_minutes",
          values.transaction_room_duration_minutes.toString()
        );
      }

      if (
        Object.keys(errors).length <= 0 &&
        Object.keys(errorsCardimage).length <= 0 &&
        Object.keys(errorsPreviewImage).length <= 0 &&
        Object.keys(errorsDetailImage).length <= 0
      ) {
        mutation.mutate(formdata);
      }
    },
  });

  const {
    values: valueCardImage,
    setValues: setValueCardImage,
    handleSubmit: submitCardImage,
    errors: errorsCardimage,
    touched: touchedCardImage,
  } = useFormik({
    initialValues: {
      card_image: "",
    },
    validationSchema: Yup.object({
      card_image: Yup.string().when(values.form, {
        is: (form: string) => form === "add",
        then: Yup.string().required("Card Image is Required"),
      }),
    }),
    onSubmit: async () => {},
  });

  const {
    values: valuePreviewImage,
    setValues: setValuePreviewImage,
    handleSubmit: submitPreviewImage,
    errors: errorsPreviewImage,
    touched: touchedPreviewImage,
  } = useFormik({
    initialValues: {
      preview_image: "",
    },
    validationSchema: Yup.object({
      preview_image: Yup.string().when(values.form, {
        is: (form: string) => form === "add",
        then: Yup.string().required("Preview Image is Required"),
      }),
    }),
    onSubmit: async () => {},
  });

  const {
    values: valueDetailImage,
    setValues: setValueDetailImage,
    handleSubmit: submitDetailImage,
    errors: errorsDetailImage,
    touched: touchedDetailImage,
  } = useFormik({
    initialValues: {
      detail_image: "",
      option_for_video: values.option_for_video,
    },
    validationSchema: Yup.object({
      detail_image: Yup.string().when("option_for_video", {
        is: "false",
        then: Yup.string().when(values.form, {
          is: (form: string) => form === "add",
          then: Yup.string().required("Detail Image is Required"),
        }),
      }),
    }),
    onSubmit: async () => {},
  });

  const {
    values: valueSpinImage,
    setValues: setValueSpinImage,
    handleSubmit: submitSpinImage,
    errors: errorsSpinImage,
    touched: touchedSpinImage,
  } = useFormik({
    initialValues: {
      spin_image: "",
    },
    validationSchema: Yup.object({
      spin_image: Yup.string().when("values.scope", {
        is: () =>
          ScopeType.GIVEAWAYRAFFLE ||
          ScopeType.GIVEAWAYTOPCREDIT ||
          ScopeType.SPECIALPRICERAFFLE ||
          ScopeType.SPECIALPRICETOPCREDIT,
        then: Yup.string().when(values.form, {
          is: (form: string) => form === "add",
          then: Yup.string().required("Spin Image is Required"),
        }),
      }),
    }),
    onSubmit: async () => {},
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: any) =>
      params.templateId
        ? CampaignRequest.editCampaignProduct(payload, params.templateId)
        : CampaignRequest.addCampaignProduct(payload),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (!isEmpty(data)) {
          await queryClient.invalidateQueries("GetCampaignTemplate");
          history.goBack();
        }
      },
    }
  );

  return {
    values,
    setValues,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
    valuePreviewImage,
    setValuePreviewImage,
    submitPreviewImage,
    errorsPreviewImage,
    touchedPreviewImage,
    valueCardImage,
    setValueCardImage,
    submitCardImage,
    errorsCardimage,
    touchedCardImage,
    valueDetailImage,
    setValueDetailImage,
    submitDetailImage,
    errorsDetailImage,
    touchedDetailImage,
    mutation,
    valueSpinImage,
    setValueSpinImage,
    submitSpinImage,
    errorsSpinImage,
    touchedSpinImage,
    loadTemplate,
    dataTemplate,
  };
};

export default AddCampaignProductForm;
