import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import NewFilePonds from "../../components/addImage/NewFilepond";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import Toggle from "../../components/button/Toggle";
import NavDetail from "../../components/navbar/NavDetail";
import { optRoutesAddCoupon } from "./helpers/list";
import Input from "../../components/input/Input";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  loadOptCoupon,
  loadOptionsCategory,
  loadOptionsCategoryGroup,
  loadOptionsTag,
  loadOptPaymentMethod,
} from "../../utils/loadOption/LoadOption";
import CircularLoad from "../../components/loading/CircularLoad";
import AddCouponForm from "./components/add_coupon/AddCouponForm";
import CouponContext from "../../store/coupon/CouponContext";
import ShowConfirmAddCoupon from "./components/add_coupon/ShowConfirmAddCoupon";

const AddCoupon = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    touched,
    errors,
    isSubmitting,
    setFile,
  } = AddCouponForm();

  const { couponState } = useContext(CouponContext);

  return (
    <>
      <nav className="fixed top-0 z-30">
        <NavDetail title1="Add" title2="Coupon" handleBack={goBack} />
      </nav>
      {couponState.showConfirmAdd && <ShowConfirmAddCoupon />}
      <section className="mb-14">
        <DynamicBreadcrumb data={optRoutesAddCoupon} />
      </section>
      <form onSubmit={handleSubmit} className="flex justify-between">
        <aside className="md:w-1/3 h-full rounded-md border-2 border-stroke">
          <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">Coupon Images</h1>
          </div>
          <hr />

          <div className="md:mx-8 mx-5 my-7">
            <div>
              <h1 className="md:text-base text-sm text-textPrimary">
                Display Image
              </h1>
              <h1 className="text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
                "(3 x 3), (PNG,JPG,JPEG) max. 1 MB"
              </h1>
              <NewFilePonds
                setFiles={setFile}
                imageType={["image/png", "image/jpg", "image/jpeg"]}
                cropRatio={"3:3"}
                maxFileSize={"1mb"}
              />
            </div>
          </div>
        </aside>
        <aside className="md:w-custom h-full md:mt-0">
          <div className="rounded-md rounded-b-none border-2 border-stroke">
            <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
              <h1 className="font-medium md:text-lg text-base">
                Coupon Information
              </h1>

              <div className="flex">
                <h1 className="md:text-base text-sm mr-3 text-textPrimary">
                  Posted
                </h1>
                <div className="flex justify-between items-center">
                  <Toggle
                    handleClick={() =>
                      setValues({
                        ...values,
                        active_status: !values.active_status,
                      })
                    }
                    input={values.active_status}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="w-full px-8 py-6">
              <div className="w-full flex">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm mb-1 text-textPrimary">
                    Coupon Type
                  </h1>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="for_reward"
                      name="for_reward"
                      row
                      value={values.for_reward}
                      onChange={(e: any) =>
                        setValues({ ...values, for_reward: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio className="bg-primary to-primary" />}
                        label="Public"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Reward"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {values.for_reward === "true" ? (
                  <div className="w-1/2 ml-8">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Expired in (Day)
                    </h1>
                    <Input
                      disable={false}
                      type={"number"}
                      name={"rewardable_valid_in_days"}
                      value={values.rewardable_valid_in_days}
                      handleChange={handleChange}
                    />
                    {errors.rewardable_valid_in_days && touched.rewardable_valid_in_days && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.rewardable_valid_in_days}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="w-1/2 ml-8">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Expired At
                    </h1>
                    <Input
                      disable={false}
                      type={"date"}
                      name={"expired_at"}
                      value={values.expired_at}
                      handleChange={handleChange}
                    />
                    {errors.expired_at && touched.expired_at && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.expired_at}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="w-full my-2.5">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Coupon Name
                  </h1>
                  <Input
                    disable={false}
                    type={"text"}
                    name={"display_name"}
                    value={values.display_name}
                    handleChange={handleChange}
                    placeholder={"Place holder"}
                  />
                  {errors.display_name && touched.display_name && (
                    <p className="text-primary text-xs font-rom -mt-2 mb-1">
                      {errors.display_name}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full my-2.5">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Coupon Code
                  </h1>
                  <Input
                    disable={false}
                    type={"text"}
                    name={"code"}
                    value={values.code}
                    handleChange={handleChange}
                    placeholder={"Place holder"}
                  />
                  {errors.code && touched.code && (
                    <p className="text-primary text-xs font-rom -mt-2 mb-1">
                      {errors.code}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full my-2.5">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Global Limit Using Coupon
                  </h1>
                  <Input
                    disable={false}
                    type={"number"}
                    name={"global_limit_using_coupon"}
                    value={values.global_limit_using_coupon}
                    handleChange={handleChange}
                    placeholder={"Place holder"}
                  />
                  {errors.global_limit_using_coupon &&
                    touched.global_limit_using_coupon && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.global_limit_using_coupon}
                      </p>
                    )}
                </div>
              </div>
              <div className="w-full flex my-2.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Coupon For
                  </h1>
                  <Select
                    options={[
                      {
                        value: [
                          "bnib_buy_order_direct",
                          "bnib_buy_order_payment",
                        ],
                        label: "Marketplace (Buy)",
                      },
                      {
                        value: ["bnib_product", "bnib_product_direct"],
                        label: "Marketplace (Sell)",
                      },
                      { value: ["retail"], label: "Multibrand (Buy)" },
                    ]}
                    value={values.scope}
                    onChange={(e: any) => setValues({ ...values, scope: e })}
                    className="my-3.5 font-rom text-sm"
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Hide in Application
                  </h1>
                  <Select
                    value={values.hide_in_coupon_list}
                    options={[
                      { value: false, label: "No" },
                      { value: true, label: "Yes" },
                    ]}
                    onChange={(e: any) =>
                      setValues({ ...values, hide_in_coupon_list: e })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                </div>
              </div>
              <div className="w-full flex my-2.5">
                <div className="w-full">
                  <h1 className="md:text-base text-sm mb-1 text-textPrimary">
                    Discount Type
                  </h1>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="discount_type"
                      row
                      value={values.discount_type}
                      onChange={(e: any) =>
                        setValues({ ...values, discount_type: e.target.value })
                      }
                    >
                      {values.scope.label === "Marketplace (Buy)" ||
                      values.scope.label === "Multibrand (Buy)" ? (
                        <FormControlLabel
                          value="subtotal_product"
                          control={
                            <Radio className="bg-primary to-primary" />
                          }
                          label="Product Price"
                        />
                      ) : (
                        ""
                      )}
                      {values.scope.label === "Marketplace (Buy)" && (
                        <FormControlLabel
                          value="shipping_cost"
                          control={<Radio />}
                          label="Shipping Cost"
                        />
                      )}
                      {values.scope.label === "Marketplace (Sell)" && (
                        <FormControlLabel
                          value="admin_fee"
                          control={<Radio />}
                          label="Admin Fee"
                        />
                      )}
                      <FormControlLabel
                        value="coupon_reward"
                        control={<Radio />}
                        label="Reward Coupon"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {values.discount_type !== "coupon_reward" ? (
                <div className="w-full flex my-2.5">
                  <div className="w-1/2">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Discount By
                    </h1>
                    <Select
                      options={[
                        { label: "Percent", value: "percentage" },
                        { label: "Amount", value: "amount" },
                      ]}
                      value={values.discount_mode}
                      onChange={(e: any) =>
                        setValues({ ...values, discount_mode: e })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                  </div>
                  <div className="w-1/2 ml-8">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Input Value
                    </h1>
                    <Input
                      disable={false}
                      type={"number"}
                      name={"discount_value"}
                      value={values.discount_value}
                      handleChange={handleChange}
                      placeholder={"Place holder"}
                    />
                    {errors.discount_value && touched.discount_value && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.discount_value}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-full flex my-2.5">
                  <div className="w-full">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Select Coupon for Reward
                    </h1>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptCoupon}
                      // value={values.coupon_for_reward}
                      onChange={(e: any) =>
                        setValues({ ...values, rewardable_coupon_id: e })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {/* {errors.subject && touched.subject && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errors.subject}
                          </p>
                        )} */}
                  </div>
                </div>
              )}

              <div className="w-full flex my-2.5">
                <div className="w-full">
                  <h1 className="md:text-base text-sm mb-1 text-textPrimary">
                    For Type
                  </h1>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="pre_order"
                      row
                      value={values.pre_order}
                      onChange={(e: any) =>
                        setValues({ ...values, pre_order: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio className="bg-primary to-primary" />}
                        label="Brand New"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Pre Order"
                      />
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="Brand New & Pre Order"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex w-full my-2.5">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Description
                  </h1>
                  <textarea
                    name="detail"
                    placeholder={"Place holder"}
                    className="w-full border-stroke border rounded-md px-3 py-2 font-rom h-16 mt-1.5 text-sm focus:outline-none focus:ring-1"
                    value={values.detail}
                    onChange={handleChange}
                  ></textarea>
                  {errors.detail && touched.detail && (
                    <p className="text-primary text-xs font-rom -mt-1 mb-1">
                      {errors.detail}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex w-full my-2.5">
                <h1 className="text-2xl text-textPrimary">Term & Conditions</h1>
              </div>
              <hr className="mb-5" />
              <div className="w-full flex my-2.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Minimal Transaction
                  </h1>
                  {/* <div className="mt-4 flex">
                    <Toggle
                      input={values.minimum_transaction}
                      handleClick={() => {
                        setValues({
                          ...values,
                          minimum_transaction: !values.minimum_transaction,
                        });
                      }}
                    />
                    <h1 className="text-sm text-textPrimary ml-5">
                      {values.minimum_transaction ? "Enabled" : "Disabled"}
                    </h1>
                  </div> */}
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Input Value
                  </h1>
                  <Input
                    // disable={!values.minimum_transaction}
                    type={"number"}
                    name={"min_subtotal"}
                    value={values.min_subtotal}
                    handleChange={handleChange}
                    placeholder={"Place holder"}
                  />
                  {errors.min_subtotal && touched.min_subtotal && (
                    <p className="text-primary text-xs font-rom -mt-2 mb-1">
                      {errors.min_subtotal}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full flex my-2.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Maximal Discount Price
                  </h1>
                  <div className="mt-4 flex">
                    <Toggle
                      input={values.max_discount_price}
                      handleClick={() => {
                        setValues({
                          ...values,
                          max_discount_price: !values.max_discount_price,
                        });
                      }}
                    />
                    <h1 className="text-sm text-textPrimary ml-5">
                      {values.max_discount_price ? "Enabled" : "Disabled"}
                    </h1>
                  </div>
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Input Value
                  </h1>
                  <Input
                    disable={!values.max_discount_price}
                    type={"number"}
                    name={"max_discount_amount"}
                    value={values.max_discount_amount}
                    handleChange={handleChange}
                    placeholder={"Place holder"}
                  />
                  {errors.max_discount_amount &&
                    touched.max_discount_amount && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.max_discount_amount}
                      </p>
                    )}
                </div>
              </div>

              <div className="w-full flex my-2.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Limit Using Coupon
                  </h1>
                  <div className="mt-4 flex">
                    <Toggle
                      input={values.limit_using_coupon}
                      handleClick={() => {
                        setValues({
                          ...values,
                          limit_using_coupon: !values.limit_using_coupon,
                        });
                      }}
                    />
                    <h1 className="text-sm text-textPrimary ml-5">
                      {values.limit_using_coupon ? "Enabled" : "Disabled"}
                    </h1>
                  </div>
                </div>
                <div className="w-1/2 ml-8">
                  <div className="">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Select Type
                    </h1>
                    <Select
                      isDisabled={!values.limit_using_coupon}
                      options={[
                        { label: "Hour", value: "hourly" },
                        { label: "Day", value: "daily" },
                        { label: "Week", value: "weekly" },
                        { label: "Month", value: "monthly" },
                      ]}
                      // value={values.periodic_limit_type}
                      onChange={(e: any) =>
                        setValues({ ...values, periodic_limit_type: e })
                      }
                      className="my-3.5 font-rom text-sm"
                    />
                    {errors.periodic_limit_type &&
                      touched.periodic_limit_type && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.periodic_limit_type.value}
                        </p>
                      )}
                  </div>

                  <div className="">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Input Limit
                    </h1>
                    <Input
                      disable={!values.limit_using_coupon}
                      type={"number"}
                      name={"periodic_limit_value"}
                      value={values.periodic_limit_value}
                      handleChange={handleChange}
                      placeholder={"Place holder"}
                    />
                    {errors.periodic_limit_value &&
                      touched.periodic_limit_value && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.periodic_limit_value}
                        </p>
                      )}
                  </div>
                </div>
              </div>

              <div className="w-full flex my-2.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Time Using Coupon (GMT + 7)
                  </h1>
                  <div className="mt-4 flex">
                    <Toggle
                      input={values.time_using_coupon}
                      handleClick={() => {
                        setValues({
                          ...values,
                          time_using_coupon: !values.time_using_coupon,
                        });
                      }}
                    />
                    <h1 className="text-sm text-textPrimary ml-5">
                      {values.time_using_coupon ? "Enabled" : "Disabled"}
                    </h1>
                  </div>
                </div>
                <div className="w-1/2 ml-8">
                  <div className="">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      From
                    </h1>
                    <Input
                      disable={!values.time_using_coupon}
                      type={"time"}
                      name={"available_time_range_of_the_day.start"}
                      value={values.available_time_range_of_the_day.start}
                      handleChange={handleChange}
                      placeholder={"Place holder"}
                    />
                    {errors.available_time_range_of_the_day &&
                      touched.available_time_range_of_the_day && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.available_time_range_of_the_day.start}
                        </p>
                      )}
                  </div>

                  <div className="">
                    <h1 className="md:text-base text-sm text-textPrimary">To</h1>
                    <Input
                      disable={!values.time_using_coupon}
                      type={"time"}
                      name={"available_time_range_of_the_day.end"}
                      value={values.available_time_range_of_the_day.end}
                      handleChange={handleChange}
                      placeholder={"Place holder"}
                    />
                    {errors.available_time_range_of_the_day &&
                      touched.available_time_range_of_the_day && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.available_time_range_of_the_day.end}
                        </p>
                      )}
                  </div>
                </div>
              </div>

              <div className="flex w-full my-3">
                <div className="w-full">
                  <div className="flex justify-between">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Day Using Coupon
                    </h1>
                    <div className="flex">
                      <h1 className="mr-3 self-end text-sm">Active</h1>
                      <Toggle
                        input={values.day_using_coupon}
                        handleClick={() =>
                          setValues({
                            ...values,
                            day_using_coupon: !values.day_using_coupon,
                          })
                        }
                      />
                    </div>
                  </div>
                  <Select
                    placeholder="Select"
                    isMulti
                    isDisabled={!values.day_using_coupon}
                    // value={values.available_days_of_the_week}
                    options={[
                      { label: "Sunday", value: "0" },
                      { label: "Monday", value: "1" },
                      { label: "Tuesday", value: "2" },
                      { label: "Wednesday", value: "3" },
                      { label: "Thursday", value: "4" },
                      { label: "Friday", value: "5" },
                      { label: "Saturday", value: "6" },
                    ]}
                    onChange={(e: any) =>
                      setValues({
                        ...values,
                        available_days_of_the_week: e,
                      })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                  {errors.available_days_of_the_week &&
                    touched.available_days_of_the_week && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.available_days_of_the_week}
                      </p>
                    )}
                </div>
              </div>

              <div className="flex w-full my-3">
                <div className="w-full">
                  <div className="flex justify-between">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Payment Method
                    </h1>
                    <div className="flex">
                      <h1 className="mr-3 self-end text-sm">Active</h1>
                      <Toggle
                        input={values.payment_method}
                        handleClick={() =>
                          setValues({
                            ...values,
                            payment_method: !values.payment_method,
                          })
                        }
                      />
                    </div>
                  </div>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    isDisabled={!values.payment_method}
                    defaultOptions
                    loadOptions={loadOptPaymentMethod}
                    // value={values.eligible_payment_methods}
                    onChange={(e: any) =>
                      setValues({
                        ...values,
                        eligible_payment_methods: e,
                      })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                  {errors.eligible_payment_methods &&
                    touched.eligible_payment_methods && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.eligible_payment_methods}
                      </p>
                    )}
                </div>
              </div>

              <div className="flex w-full my-3">
                <div className="w-full">
                  <div className="flex justify-between">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Select Category Group
                    </h1>
                    <div className="flex">
                      <h1 className="mr-3 self-end text-sm">Active</h1>
                      <Toggle
                        input={values.category_group}
                        handleClick={() =>
                          setValues({
                            ...values,
                            category_group: !values.category_group,
                          })
                        }
                      />
                    </div>
                  </div>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    isDisabled={!values.category_group}
                    defaultOptions
                    loadOptions={loadOptionsCategoryGroup}
                    onChange={(e: any) =>
                      setValues({
                        ...values,
                        market_product_category_group_ids: e,
                      })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                  {errors.market_product_category_group_ids &&
                    touched.market_product_category_group_ids && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.market_product_category_group_ids}
                      </p>
                    )}
                </div>
              </div>

              <div className="flex w-full my-3">
                <div className="w-full">
                  <div className="flex justify-between">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Select Category
                    </h1>
                    <div className="flex">
                      <h1 className="mr-3 self-end text-sm">Active</h1>
                      <Toggle
                        input={values.category}
                        handleClick={() =>
                          setValues({
                            ...values,
                            category: !values.category,
                          })
                        }
                      />
                    </div>
                  </div>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    isDisabled={!values.category}
                    defaultOptions
                    loadOptions={loadOptionsCategory}
                    onChange={(e: any) =>
                      setValues({
                        ...values,
                        market_product_category_ids: e,
                      })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                  {errors.market_product_category_ids &&
                    touched.market_product_category_ids && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.market_product_category_ids}
                      </p>
                    )}
                </div>
              </div>

              <div className="flex w-full my-3">
                <div className="w-full">
                  <div className="flex justify-between">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Select Tag
                    </h1>
                    <div className="flex">
                      <h1 className="mr-3 self-end text-sm">Active</h1>
                      <Toggle
                        input={values.tag}
                        handleClick={() =>
                          setValues({
                            ...values,
                            tag: !values.tag,
                          })
                        }
                      />
                    </div>
                  </div>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    isDisabled={!values.tag}
                    defaultOptions
                    loadOptions={loadOptionsTag}
                    onChange={(e: any) =>
                      setValues({
                        ...values,
                        market_tag_ids: e,
                      })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                  {errors.market_tag_ids && touched.market_tag_ids && (
                    <p className="text-primary text-xs font-rom -mt-2 mb-1">
                      {errors.market_tag_ids}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
            <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
              <button
                type="reset"
                onClick={() => {
                  //   handleReset({});
                  //   setFile(null);
                  //   const removeFile: any = document.getElementsByClassName(
                  //     "filepond--file-action-button filepond--action-remove-item"
                  //   );
                  //   removeFile[0].click();
                }}
                className="focus:outline-none"
              >
                <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                  Clear
                </h1>
              </button>
            </div>
            <div className="w-1/2 flex items-center justify-center">
              <button type="submit" className="focus:outline-none">
                {isSubmitting ? (
                  <CircularLoad color={"black"} height={16} width={16} />
                ) : (
                  <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                    Add
                  </h1>
                )}
              </button>
            </div>
          </div>
        </aside>
      </form>
    </>
  );
};

export default AddCoupon;
