import React from "react";
import { useParams } from "react-router-dom";
import { useMutateArriveBnibtransaction } from "../../../hooks/bnib_transaction";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";

interface IOconfirmArriveBnibTransactionProps {
  isOpen: boolean;
  handleClosed: () => void;
  location_dest_name: string;
  handleBack: () => void;
}

const OconfirmArriveBnibTransaction: React.FC<
  IOconfirmArriveBnibTransactionProps
> = ({ isOpen, handleClosed, location_dest_name, handleBack }) => {
  const params: { code: string } = useParams();

  const { mutate, isLoading } = useMutateArriveBnibtransaction(
    params?.code,
    handleClosed,
    handleBack
  );

  return (
    <ADialogBox
      content={{
        title: "",
        body: (
          <form className="flex flex-col">
            <div>
              <p className="text-sm font-med">
                Are you sure this product already arrive at DEPATU Warehouse?
              </p>
            </div>
            <div className="mt-7 flex gap-3 justify-end">
              <Abutton
                title="Cancel"
                theme="secondary"
                onClick={handleClosed}
              />
              <Abutton
                isLoading={isLoading}
                title="Confirm"
                onClick={() => mutate({ location_dest_name })}
              />
            </div>
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={handleClosed}
    />
  );
};

export default OconfirmArriveBnibTransaction;
