const SetQueryString = (
  array: any[],
  new_value: string,
  name: string,
  search: string,
  pathname: string,
  history: any,
  hash?: string
) => {
  const location = document.location.search;
  const query = new URLSearchParams(location);
  let new_search;
  for (const key in array) {
    let value = query.get(array[key].real_name);
    if (value !== null && array[key].show_name === name) {
      new_search = search
        .replace(
          `${array[key].real_name}=${value}`,
          `${array[key].real_name}=${new_value}`
        )
        .replace(`offset=${query.get("offset")}`, "offset=0");
    } else if (array[key].show_name === name) {
      let search_offset = search.replace(
        `offset=${query.get("offset")}`,
        "offset=0"
      );
      new_search = `${search_offset}&&${array[key].real_name}=${new_value}`;
    }
  }
  return history.push({ pathname, search: new_search, hash });
};

export default SetQueryString;
