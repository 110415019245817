import React from "react";
import { Link } from "react-router-dom";
import { product, store, wallet } from "../../../assets/icons/Web";

interface IProps {
  dropdownTransaction: boolean;
  handleShowSideBar: () => void;
}

const TransactionSubsidebar: React.FC<IProps> = ({
  dropdownTransaction,
  handleShowSideBar,
}) => {
  return (
    <div>
      {dropdownTransaction && (
        <li className="py-2 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={wallet}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf("/marketplace/transaction") !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to="/marketplace/transaction"
            >
              Transaction
            </Link>
          </div>
        </li>
      )}

      {dropdownTransaction && (
        <li className="py-2 mt-5 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={product}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf("/marketplace/order") !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to={{
                pathname: "/marketplace/order",
              }}
            >
              Order
            </Link>
          </div>
        </li>
      )}

      {dropdownTransaction && (
        <li className="py-2 mt-5 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={store}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf("/marketplace/on-selling") !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to={{
                pathname: "/marketplace/on-selling",
                search:
                  "limit=10&&offset=0&&sort=created_at&&isAsc=desc&&filter=false",
              }}
            >
              On Selling
            </Link>
          </div>
        </li>
      )}

      {dropdownTransaction && (
        <li className="py-2 mt-5 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={product}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf("/marketplace/on-bidding") !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to={{
                pathname: "/marketplace/on-bidding",
                search:
                  "limit=10&&offset=0&&sort=created_at&&isAsc=desc&&filter=false",
              }}
            >
              On Bidding
            </Link>
          </div>
        </li>
      )}

      {dropdownTransaction && <hr className="md:my-5 my-3 md:min-w-full" />}
    </div>
  );
};

export default TransactionSubsidebar;
