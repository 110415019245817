export const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/tracking",
    name: "Tracking",
  },
];

export const optRoutesEdit = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/tracking",
      name: "Tracking",
    },
    {
      id: 3,
      path: `/marketplace/tracking/edit-tracking/${id}`,
      name: "Edit Location",
    },
  ];
};
