import isEmpty from "lodash.isempty";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { BnibTransactionService } from "../../apis/bnib_transaction";
import {
  EBnibTransactionSortField,
  listFilter,
} from "../../core/bnib_transaction/entities";
import { useQueries } from "../../helpers/QuerySearchParams";

const useFetchBnibTransactions = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "BnibTransactions",
      query.get("sort"),
      query.get("status"),
      query.get("code"),
      query.get("limit"),
      query.get("offset"),
      query.get("date"),
    ],
    () =>
      BnibTransactionService.getBnibTransactions(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {
          status: query.get("status") || listFilter[0].status.toString(),
          code: query.get("code") || undefined,
          date: query.get("date") || `${date.startDate},${date.endDate}`,
        },
        [
          {
            field: query.get("sort") || EBnibTransactionSortField.newest,
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useFetchBnibTransaction = (code: string) => {
  return useQuery(
    [`BnibTransaction-${code}`],
    () => BnibTransactionService.getBnibTransaction(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchBnibTransactionTrack = (code: string) => {
  return useQuery(
    [`BnibTransaction-track-${code}`],
    () => BnibTransactionService.getBnibTransactionTrack(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchBnibTransactionProcess = (code: string) => {
  return useQuery(
    [`BnibTransaction-process-${code}`, code],
    () => BnibTransactionService.getBnibTransactionProcess(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchBnibTransactionCounter = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(
    ["BnibTransactionsCounter", query.get("date")],
    () =>
      BnibTransactionService.getBnibTransactionCounter({
        status: listFilter[0].status.toString(),
        date: query.get("date") || `${date.startDate},${date.endDate}`,
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useMutateArriveBnibtransaction = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  handleBack: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { location_dest_name: string }) =>
      BnibTransactionService.arriveBnibTransaction(code, payload),
    {
      onMutate: () => {
        console.log("mutate arrive bnib transaction");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          setOpen(false);
          handleBack();
        }
      },
    }
  );
};

const useMutateDisputeBnibtransaction = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  handleNext: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { reject_reason: string; location_dest_name: string }) =>
      BnibTransactionService.disputeBnibTransaction(code, values),
    {
      onMutate: () => {
        console.log("mutate dispute bnib transaction");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateAcceptBnibtransaction = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  handleNext: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { location_dest_name: string }) =>
      BnibTransactionService.acceptBnibTransaction(code, payload),
    {
      onMutate: () => {
        console.log("mutate accept bnib transaction");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateRejectBnibtransaction = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  handleNext: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { reject_reason: string; location_dest_name: string }) =>
      BnibTransactionService.rejectBnibTransaction(code, values),
    {
      onMutate: () => {
        console.log("mutate reject bnib transaction");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateDefectBnibtransaction = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  handleNext: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { is_defect: boolean; defect_images?: any[] }) =>
      BnibTransactionService.defectBnibTransaction(code, values),
    {
      onMutate: () => {
        console.log("mutate defect bnib transaction");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateReadyForLegitCheck = (code: string, handleNext: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(() => BnibTransactionService.readyForLegitCheck(code), {
    onMutate: () => {
      console.log("mutate ready ld");
    },
    onSettled: async (data) => {
      if (data?.message) {
        queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
        handleNext();
      }
    },
  });
};

const useMutateConfirmFinalResult = (
  code: string,
  handleNext: () => void,
  setOpen: (isOpen: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { confirm_result: string }) =>
      BnibTransactionService.confirmFinalResult(code, values),
    {
      onMutate: () => {
        console.log("mutate confirm final result");
      },
      onSettled: async (data, err) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateReadyShippingToBuyer = (
  code: string,
  handleNext: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => BnibTransactionService.readyShippingToBuyer(code), {
    onMutate: () => {
      console.log("mutate ready shipping to buyer");
    },
    onSettled: async (data) => {
      if (data?.message) {
        queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
        handleNext();
      }
    },
  });
};

const useMutateSendProductToBuyer = (
  code: string,
  handleNext: () => void,
  setOpen: (isOpen: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { pickup_time: string }) =>
      BnibTransactionService.sendProductToBuyer(code, values),
    {
      onMutate: () => {
        console.log("mutate send product to buyer");
      },
      onSettled: async (data, err) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateReadyShippingToSeller = (
  code: string,
  handleNext: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => BnibTransactionService.readyShippingToSeller(code), {
    onMutate: () => {
      console.log("mutate ready shipping to seller");
    },
    onSettled: async (data) => {
      if (data?.message) {
        queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
        handleNext();
      }
    },
  });
};

const useMutateSendProductToSeller = (
  code: string,
  handleNext: () => void,
  setOpen: (isOpen: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { pickup_time: string }) =>
      BnibTransactionService.sendProductToSeller(code, values),
    {
      onMutate: () => {
        console.log("mutate send product to seller");
      },
      onSettled: async (data, err) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          handleNext();
          setOpen(false);
        }
      },
    }
  );
};

const useMutateReceipt = (code: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { images?: Blob[] | any; external_url?: string }) =>
      BnibTransactionService.receipt(code, values),
    {
      onMutate: () => {
        console.log("mutate receipt");
      },
      onSettled: async (data, _err, variables) => {
        if (!isEmpty(data)) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          const removeFile: any = document.getElementsByClassName(
            "filepond--file-action-button filepond--action-remove-item"
          );
          variables.images.map((_el: any, idx: string | number) =>
            removeFile[idx]?.click()
          );
        }
      },
    }
  );
};

const useMutateTransferStock = (code: string, handleClosed: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { location_dest_name: string }) =>
      BnibTransactionService.transferStock(code, payload),
    {
      onMutate: () => {
        console.log("mutate transfer stock");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          handleClosed();
        }
      },
    }
  );
};

const useMutatePrintShippingLabel = () => {
  return useMutation(
    (payload: { code: string }) =>
      BnibTransactionService.printShippingLabel(payload.code),
    {
      onMutate: () => {
        console.log("mutate print shipping label");
      },
      onSettled: async (data) => {
        console.log(data?.data);
        if (data?.data) {
          window.open(data?.data);
        }
      },
    }
  );
};

export {
  useFetchBnibTransactions,
  useFetchBnibTransaction,
  useFetchBnibTransactionCounter,
  useFetchBnibTransactionTrack,
  useFetchBnibTransactionProcess,
  useMutateArriveBnibtransaction,
  useMutateDisputeBnibtransaction,
  useMutateAcceptBnibtransaction,
  useMutateRejectBnibtransaction,
  useMutateDefectBnibtransaction,
  useMutateReadyForLegitCheck,
  useMutateConfirmFinalResult,
  useMutateReadyShippingToBuyer,
  useMutateSendProductToBuyer,
  useMutateReadyShippingToSeller,
  useMutateSendProductToSeller,
  useMutateReceipt,
  useMutateTransferStock,
  useMutatePrintShippingLabel,
};
