import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { BannerRequest } from "../../../apis/banner/BannerRequest";
import { cancel } from "../../../assets/icons";
import SmallButton from "../../../components/button/SmallButton";
import BannerContext from "../../../store/banner/BannerContext";

const Deletebanner = () => {
  const context = useContext(BannerContext);
  const { bannerState, bannerDispatch } = context;
  const { showDelete } = bannerState;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const goBack = () => {
    bannerDispatch({
      type: "show_delete",
      payload: {
        id: "",
        status: false,
      },
    });
  };

  const deleteTags = async () => {
    await mutation.mutate();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => BannerRequest.deleteBanner(showDelete.id, goBack),
    {
      onMutate: () => {
        setSubmitting(true);
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getBanner");
        setSubmitting(false);
        if (error) {
          console.log(error);
          setSubmitting(false);
        }
      },
    }
  );

  return (
    showDelete.status && (
      <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
        <div className="bg-white w-1/3 h-auto rounded-xl my-auto mx-auto font-med">
          <div className="flex w-full justify-end items-center px-8 pb-5 pt-7">
            <img
              src={cancel}
              alt="cancel"
              className="cursor-pointer w-5"
              onClick={goBack}
            />
          </div>
          <div className="px-10">
            <div className="flex flex-col items-center">
              <h1 className="text-xl">Delete Banner</h1>
              <h1 className="text-base text-center w-60 text-textPrimary mt-4 font-rom">
                Are you sure you want to delete the banner ?
              </h1>
            </div>

            <div className="flex justify-center my-8 outline-none">
              <SmallButton
                loadColor={"white"}
                type={"button"}
                handleClick={goBack}
                color={"bg-white"}
                border={"border-2"}
                borderColor={"border-breadscrumbColor"}
                hoverColor={"opacity-70"}
                text={"Cancel"}
                textColor={"text-textPrimary"}
              />
              <div className="mx-1.5"></div>
              <SmallButton
                isSubmitting={isSubmitting}
                loadColor={"white"}
                type={"button"}
                handleClick={deleteTags}
                color={"bg-primary"}
                hoverColor={"bg-red-300"}
                text={"Delete"}
                textColor={"text-white"}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Deletebanner;
