export const BannerReducer = (state: any, action: any) => {
    switch (action.type) {
      case "show_filter":
        let showFilter = state.showFilter;
        document.body.classList[!showFilter ? "add" : "remove"](
          "h-screen",
          "overflow-hidden"
        );
        return { ...state, showFilter: !showFilter };
      case "show_delete":
        let deleted = action.payload;
        document.body.classList[deleted.status ? "add" : "remove"](
          "h-screen",
          "overflow-hidden"
        );
        return { ...state, showDelete: deleted };
      case "handle_chip":
        let new_chip = action.payload;
        return { ...state, chip: new_chip };
    }
  };
  