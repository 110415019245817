import React, { useContext, useEffect, useState } from "react";
import {
  breadcrumbnext,
  cancel,
  drag as dragIcon,
} from "../../../../../assets/icons";
import { optSelectPicture } from "../../helpers/list";
import Select from "react-select";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";
import { ActionType } from "../../../../../store/product/product_need_review/actions";
import { ProductScrapingRequest } from "../../../../../apis/product/ProductScrapingRequest";
import NewFilePonds from "../../../../../components/addImage/NewFilepond";
import { Slider } from "@material-ui/core";
import { useMutation } from "react-query";

interface IProps {
  data: any;
}

const ReviewProductImage: React.FC<IProps> = ({ data }) => {
  const [editPosition, setEditPosition] = useState<boolean>(false);
  const { productReviewDispatch, productReviewState } =
    useContext(ProductReviewContext);
  const { dataImage, displayImage, pictureInit } = productReviewState;

  const [displayImageFilePond, setDisplayImageFilePond] = useState<any>(null);
  const [detailImage, setDetailImage] = useState<any>(null);
  const [source, setSource] = useState<string>("");
  const [index, setIndex] = useState(0);
  const [pictureOpt, setPictureOpt] = useState(optSelectPicture[0]);
  // const [pictureInit, setPictureInit] = useState(optSelectPicture[0]);
  const [imgSliderSource, setImgSliderSource] = React.useState<number>(0);
  const [imgSliderDepatu, setImgSliderDepatu] = React.useState<number>(0);

  useEffect(() => {
    setSource(data?.foreign_products[index]?.source);
    if (data?.foreign_products[index]?.three_d_image_urls.length > 0) {
      setPictureOpt(optSelectPicture[1]);
    } else {
      setPictureOpt(optSelectPicture[0]);
    }
  }, [index, data]);

  const handleChangeImgSourceSlider = (
    event: any,
    newValue: number | number[]
  ) => {
    setImgSliderSource(newValue as number);
  };

  const handleChangeImgDepatuSlider = (
    event: any,
    newValue: number | number[]
  ) => {
    setImgSliderDepatu(newValue as number);
  };

  function drag(e: any) {
    e.dataTransfer.setData("text", e.target.id);
  }
  function drop(e: any) {
    e.preventDefault();
    var data = e.dataTransfer.getData("text");
    var nodeCopy: any = document.getElementById(data)?.cloneNode(true);
    const new_data = dataImage.concat(nodeCopy?.src);
    productReviewDispatch({
      type: ActionType.AddImage,
      payload: new_data,
    });
  }
  function dropDisplay(e: any) {
    e.preventDefault();
    var data = e.dataTransfer.getData("text");
    var nodeCopy: any = document.getElementById(data)?.cloneNode(true);
    const new_data = nodeCopy?.src;
    productReviewDispatch({
      type: ActionType.AddDisplayImg,
      payload: new_data,
    });
  }

  const handleChangeSource = (opt: string) => {
    if (opt === "+") {
      setIndex(index + 1);
    } else {
      setIndex(index - 1);
    }
  };

  function allowDrop(e: any) {
    e.preventDefault();
  }

  //drag line
  function dragLine(e: any) {
    e.dataTransfer.setData("text", e.target.id);
  }

  function dropLine(e: any) {
    e.preventDefault();
    const source_index = e.dataTransfer.getData("text");
    const data = [...dataImage];
    let temp_data = data[source_index];

    if (e.target.id === source_index) {
      return;
    }
    if (source_index < e.target.id) {
      data.splice(+e.target.id + 1, 0, temp_data);
      delete data[+source_index];
    } else {
      data.splice(e.target.id, 0, temp_data);
      delete data[+source_index + 1];
    }

    const filter_data = data.filter((el) => el !== null || el !== undefined);
    productReviewDispatch({
      type: ActionType.AddImage,
      payload: filter_data,
    });
  }

  const deleteImageFromList = (i: number) => {
    const data = [...dataImage].filter((el, index) => index !== i);
    productReviewDispatch({
      type: ActionType.AddImage,
      payload: data,
    });
  };

  const deleteDisplayImage = () => {
    productReviewDispatch({
      type: ActionType.AddDisplayImg,
      payload: null,
    });
  };

  const mutation = useMutation(
    (form_data: any) => ProductScrapingRequest.uploadImage(form_data),
    {
      onMutate: () => {
        console.log("mutate display image");
      },
      onSettled: async (data: any, error: any) => {
        productReviewDispatch({
          type: ActionType.AddDisplayImg,
          payload: data,
        });
      },
    }
  );

  useEffect(() => {
    const form_data = new FormData();
    if (
      displayImageFilePond?.type === "image/jpeg" ||
      displayImageFilePond?.type === "image/jpg" ||
      displayImageFilePond?.type === "image/png"
    ) {
      form_data.append("type", "display");
      form_data.append("image", displayImageFilePond);
      mutation.mutate(form_data);
    }
  }, [displayImageFilePond]);

  const mutationDetail = useMutation(
    (form_data: any) => ProductScrapingRequest.uploadDetailImage(form_data),
    {
      onMutate: () => {
        console.log("mutate detail");
      },
      onSuccess: (data: any, error: any) => {
        if (data) {
          const removeFile: any = document.getElementsByClassName(
            "filepond--file-action-button filepond--action-remove-item"
          );
          removeFile[0]?.click();
          const new_data = [...dataImage].concat(data);
          productReviewDispatch({
            type: ActionType.AddImage,
            payload: new_data,
          });
          setDetailImage(null);
        }
      },
    }
  );

  useEffect(() => {
    const form_data_detail = new FormData();
    if (
      detailImage?.type === "image/jpeg" ||
      detailImage?.type === "image/jpg" ||
      detailImage?.type === "image/png"
    ) {
      if (pictureInit.value === "2d") {
        form_data_detail.append("type", "detail");
        form_data_detail.append("image", detailImage);
      } else if ("3d") {
        form_data_detail.append("type", "carousel");
        form_data_detail.append("image", detailImage);
      }

      mutationDetail.mutate(form_data_detail);
    }
  }, [detailImage]);

  const removeFile: any = document.getElementsByClassName(
    "filepond--file-action-button filepond--action-remove-item"
  );

  return (
    <main className="max-w-full h-auto mt-14">
      <section className="flex justify-between">
        <div className="w-2/3">
          <h1>Picture Option</h1>
          <Select
            value={pictureOpt}
            onChange={(e: any) => setPictureOpt(e)}
            className="w-1/2 my-2 font-rom"
            options={optSelectPicture}
          />
        </div>
        <div className="w-2/3 ml-8">
          <h1>Picture Option</h1>
          <Select
            value={pictureInit}
            onChange={(e: any) =>
              productReviewDispatch({
                type: ActionType.HandlePictureInit,
                payload: e,
              })
            }
            className="w-1/2 my-2 font-rom"
            options={optSelectPicture}
          />
        </div>
      </section>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-7 mt-7">
        <section className="border border-stroke rounded-md h-auto">
          <div className="flex justify-between px-8 py-6 border-b">
            <div className="flex">
              <button
                onClick={() => handleChangeSource("-")}
                disabled={
                  data.foreign_products[0].source === source ? true : false
                }
                className={
                  "focus:outline-none " +
                  (data.foreign_products[0].source === source
                    ? "cursor-default"
                    : "cursor-pointer")
                }
              >
                <img
                  src={breadcrumbnext}
                  alt="next"
                  className="w-2 transform rotate-180 mr-4"
                />
              </button>
              <h1 className="text-lg uppercase">
                {source?.split("_").join(" ")}
              </h1>
              <button
                onClick={() => handleChangeSource("+")}
                disabled={
                  data.foreign_products[data.foreign_products.length - 1]
                    .source === source
                    ? true
                    : false
                }
                className={
                  "focus:outline-none " +
                  (data.foreign_products[data.foreign_products.length - 1]
                    .source === source
                    ? "cursor-default"
                    : "cursor-pointer")
                }
              >
                <img src={breadcrumbnext} alt="next" className="w-2 ml-4" />
              </button>
            </div>
            <h1
              onClick={
                pictureOpt.value === "3d"
                  ? () => {
                      productReviewDispatch({
                        type: ActionType.AddImage,
                        payload:
                          data.foreign_products[index].three_d_image_urls,
                      });
                      productReviewDispatch({
                        type: ActionType.AddDisplayImg,
                        payload: data.foreign_products[index].display_image_url,
                      });
                    }
                  : () => {
                      productReviewDispatch({
                        type: ActionType.AddDisplayImg,
                        payload: data.foreign_products[index].display_image_url,
                      });
                      productReviewDispatch({
                        type: ActionType.AddImage,
                        payload: data.foreign_products[index].detail_image_urls,
                      });
                    }
              }
              className="hover:text-primary cursor-pointer text-lg"
            >
              Use All Picture
            </h1>
          </div>

          <div className="p-8">
            <div className="flex">
              <div className="border w-2/5 rounded-md flex flex-col justify-center items-center p-5">
                <img
                  src={
                    pictureOpt.value === "2d"
                      ? data.foreign_products[index].detail_image_urls[
                          imgSliderSource
                        ]
                      : data.foreign_products[index].three_d_image_urls[
                          imgSliderSource
                        ]
                  }
                  alt="img"
                  className="rounded-md shadow-lg"
                />
                <Slider
                  aria-labelledby="continuous-slider"
                  color="secondary"
                  min={0}
                  max={
                    pictureOpt.value === "2d"
                      ? data.foreign_products[index].detail_image_urls.length -
                        1
                      : data.foreign_products[index].three_d_image_urls.length -
                        1
                  }
                  step={1}
                  className="mt-2"
                  value={imgSliderSource}
                  onChange={handleChangeImgSourceSlider}
                />
              </div>
              <div className="w-3/5 px-8">
                <h1 className="text-textPrimary">Display Picture</h1>
                <div className="w-1/2 border rounded-md flex justify-center mt-2 ">
                  <img
                    src={data.foreign_products[index].display_image_url}
                    draggable={editPosition ? "false" : "true"}
                    onDragStart={drag}
                    id={"1"}
                    alt="img"
                    className="cursor-move rounded-md shadow-lg"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h1 className="text-textPrimary">Detail Picture</h1>
              <div className="mt-2 grid grid-cols-4 gap-4">
                {pictureOpt.value === "2d"
                  ? data.foreign_products[index].detail_image_urls.map(
                      (el: string, index: number) => (
                        <div
                          className={"border rounded-md flex justify-center"}
                        >
                          <img
                            loading="lazy"
                            onClick={() => setImgSliderSource(index)}
                            src={el}
                            draggable={editPosition ? "false" : "true"}
                            onDragStart={drag}
                            id={`#img-${index + 1}`}
                            alt="img"
                            className={
                              "flex justify-center border rounded-md cursor-move " +
                              (index === imgSliderSource
                                ? "border-primary"
                                : "")
                            }
                          />
                        </div>
                      )
                    )
                  : data.foreign_products[index].three_d_image_urls.map(
                      (el: string, index: number) => (
                        <div
                          className={"border rounded-md flex justify-center "}
                        >
                          <img
                            loading="lazy"
                            onClick={() => setImgSliderSource(index)}
                            src={el}
                            draggable={editPosition ? "false" : "true"}
                            onDragStart={drag}
                            id={`#img-${index + 1}`}
                            alt="img"
                            className={
                              "flex justify-center border rounded-md cursor-move " +
                              (index === imgSliderSource
                                ? "border-primary"
                                : "")
                            }
                          />
                        </div>
                      )
                    )}
              </div>
            </div>
          </div>
        </section>

        <section className="border border-stroke rounded-md h-auto">
          <div className="flex justify-between px-8 py-6 border-b">
            <h1 className="text-lg">DEPATU</h1>
            <h1
              onClick={() => {
                productReviewDispatch({
                  type: ActionType.AddDisplayImg,
                  payload: null,
                });
                removeFile[0]?.click();
                productReviewDispatch({
                  type: ActionType.AddImage,
                  payload: [],
                });
              }}
              className="hover:text-primary cursor-pointer text-lg"
            >
              Revert Change
            </h1>
          </div>

          <div className="p-8">
            <div className="flex">
              <div className="border w-2/5 rounded-md flex flex-col justify-center items-center p-5">
                <img
                  src={dataImage[imgSliderDepatu]}
                  alt=""
                  className="rounded-md shadow-lg"
                />
                {dataImage.length > 0 && (
                  <Slider
                    aria-labelledby="continuous-slider"
                    className="mt-1"
                    color="secondary"
                    min={0}
                    max={dataImage.length - 1}
                    step={1}
                    value={imgSliderDepatu}
                    onChange={handleChangeImgDepatuSlider}
                  />
                )}
              </div>

              <div className="w-2/5 px-8">
                <h1 className="text-textPrimary">Display Picture</h1>
                <div
                  onDragOver={allowDrop}
                  onDrop={function () {}}
                  className="w-full rounded-md mt-2 h-2/5"
                >
                  {displayImage === null ? (
                    <div
                      onDrop={dropDisplay}
                      onDragOver={allowDrop}
                      className={
                        "border-2 border-dotted rounded-md flex justify-center items-center h-full w-10/12"
                      }
                    >
                      <h1 className="text-sm text-textPrimary">Drag Here</h1>
                    </div>
                  ) : (
                    <div className="border rounded-md flex justify-center  w-10/12">
                      <img
                        src={displayImage}
                        alt="img"
                        key={displayImage}
                        className="rounded-md shadow-lg"
                      />
                      <div
                        onClick={deleteDisplayImage}
                        className="bg-textPrimary bg-opacity-80 hover:bg-opacity-50 -ml-6 rounded-tr-md rounded-bl-md w-6 h-6 flex justify-center items-center cursor-pointer"
                      >
                        <img src={cancel} alt="icon" className="w-2.5" />
                      </div>
                    </div>
                  )}

                  <NewFilePonds
                    setFiles={setDisplayImageFilePond}
                    imageType={["image/png", "image/jpg", "image/jpeg"]}
                    cropRatio={"1:1"}
                    maxFileSize={"1mb"}
                  />
                </div>
              </div>
            </div>

            <div className="mt-10">
              <div className="flex justify-between">
                <h1 className="text-textPrimary">Detail Picture</h1>
                <h1
                  onClick={() => setEditPosition(!editPosition)}
                  className="hover:text-primary cursor-pointer"
                >
                  Edit Picture Position
                </h1>
              </div>
              <div
                onDragOver={allowDrop}
                onDrop={editPosition ? dropLine : function () {}}
                className="grid grid-cols-4 gap-4 mt-2"
              >
                {dataImage.map((el: any, i: number) => (
                  <div className="border rounded-md flex justify-center ">
                    <img
                      key={i + 1}
                      src={el}
                      draggable={editPosition ? "true" : "false"}
                      onDragStart={dragLine}
                      id={`${i}`}
                      alt="test"
                      className={
                        "rounded-md shadow-lg " +
                        (editPosition ? "cursor-move" : "cursor-default")
                      }
                    />
                    {!editPosition ? (
                      <div
                        onClick={() => deleteImageFromList(i)}
                        className="bg-textPrimary bg-opacity-80 hover:bg-opacity-50 -ml-6 rounded-tr-md rounded-bl-md w-6 h-6 flex justify-center items-center cursor-pointer"
                      >
                        <img src={cancel} alt="icon" className="w-2.5" />
                      </div>
                    ) : (
                      <div className="bg-textPrimary bg-opacity-80 -ml-6 rounded-tr-md rounded-bl-md w-6 h-6 flex justify-center items-center cursor-move">
                        <img src={dragIcon} alt="icon" className="w-3" />
                      </div>
                    )}
                  </div>
                ))}
                {!editPosition && (
                  <div
                    onDrop={drop}
                    onDragOver={allowDrop}
                    className={
                      "border-2 border-dotted rounded-md flex justify-center items-center " +
                      (dataImage.length <= 0 || dataImage.length % 4 === 0
                        ? "w-full md:py-8 xl:py-12"
                        : "")
                    }
                  >
                    <h1 className="text-sm text-textPrimary">Drag Here</h1>
                  </div>
                )}
              </div>
              <h1 className="font-rom text-xs mt-5 -mb-3">
                (1 x 1), (PNG, JPG, JPEG) max. 1 MB
              </h1>
              <div className="w-1/3">
                <NewFilePonds
                  setFiles={setDetailImage}
                  imageType={["image/png", "image/jpg", "image/jpeg"]}
                  cropRatio={"1:1"}
                  maxFileSize={"1mb"}
                  allowMultiple={true}
                  maxFile={30}
                  file={detailImage}
                />
                {/* <FilepondServer
                  setFiles={setDetailImage}
                  imageType={["image/png", "image/jpg", "image/jpeg"]}
                  cropRatio={"1:1"}
                  maxFileSize={"1mb"}
                  allowMultiple={true}
                  maxFile={30}
                  file={detailImage}
                  dataImage={dataImage}
                  dispatch={productReviewDispatch}
                  type={ActionType.AddImage}
                /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ReviewProductImage;
