import { isUndefined } from "lodash";
export interface InputProps {
  value?: any;
  handleChange?: any;
  type: string | any;
  name: string;
  placeholder?: string;
  disable?: boolean;
  required?: boolean;
  ref?: string | any;
  pattern?: string;
  textColor?: string;
}

const Input: React.FC<InputProps> = ({
  required,
  disable,
  value,
  handleChange,
  type,
  name,
  placeholder,
  ref,
  pattern,
  textColor,
}) => {
  return (
    <input
      pattern={pattern}
      ref={ref}
      required={required}
      disabled={disable}
      type={type}
      value={value}
      onChange={handleChange}
      name={name}
      placeholder={placeholder || "Place holder"}
      className={
        "md:h-10 h-8 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke focus:ring-1 focus:outline-none " +
        (!isUndefined(textColor) ? textColor : "text-black")
      }
    />
  );
};

export default Input;
