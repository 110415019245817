import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Campaign from "../../../pages/campaign/Campaign";
import CampaignInfo from "../../../pages/campaign/components/campaign/CampaignInfo";
import EditCampaign from "../../../pages/campaign/components/campaign/EditCampaign";
import AddCampaignProduct from "../../../pages/campaign/components/campaign_template/AddCampaignProduct";
import AnnounceWinner from "../../../pages/campaign/components/campaign_template/announce_product/AnnounceWinner";
import ParticipantPage from "../../../pages/campaign/components/campaign_template/announce_product/ParticipantPage";
import CampaignProductDetail from "../../../pages/campaign/components/campaign_template/CampaignProductDetail";
import EditCampaignProduct from "../../../pages/campaign/components/campaign_template/EditCampaignProduct";
import ListProduct from "../../../pages/campaign/components/campaign_template/ListProduct";
import PreAnnounceProduct from "../../../pages/campaign/components/campaign_template/PreAnnounceProduct";
import SellProduct from "../../../pages/campaign/components/campaign_template/SellProduct";
import SpinProduct from "../../../pages/campaign/components/campaign_template/SpinProduct";
import ResultsPage from "../../../pages/campaign/components/campaign_template/spin_product/ResultsPage";
import ShuffleWinner from "../../../pages/campaign/components/campaign_template/spin_product/ShuffleWinner";
import SummaryPage from "../../../pages/campaign/components/campaign_template/spin_product/SummaryPage";
import WinnerPage from "../../../pages/campaign/components/campaign_template/spin_product/WinnerPage";
import {
  initialCampaignState,
  CampaignContextProvider,
} from "../../../store/campaign/CampaignContext";
import { CampaignReducer } from "../../../store/campaign/CampaignReducer";

const CampaignRoute = () => {
  const [CampaignState, CampaignDispatch] = useReducer(
    CampaignReducer,
    initialCampaignState
  );

  return (
    <CampaignContextProvider value={{ CampaignState, CampaignDispatch }}>
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route path="/marketplace/campaign" exact component={Campaign} />
          <Route
            path="/marketplace/campaign/info"
            exact
            component={CampaignInfo}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id"
            exact
            component={EditCampaign}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/list-product/:productId"
            exact
            component={ListProduct}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/sell-product/:productId"
            exact
            component={SellProduct}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/spin-winner/:templateId/:totalWinner"
            exact
            component={SpinProduct}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/spin-winner/:templateId/shuffle/:totalWinner/:winner"
            exact
            component={ShuffleWinner}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/spin-winner/:templateId/winner/:totalWinner/:winner"
            exact
            component={WinnerPage}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/spin-winner/:templateId/summary/:totalWinner/:winner"
            exact
            component={SummaryPage}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/add-campaign-product"
            exact
            component={AddCampaignProduct}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/edit-campaign-product/:templateId"
            exact
            component={EditCampaignProduct}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/details/:templateId"
            exact
            component={CampaignProductDetail}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/pre-announce-winner/:templateId"
            exact
            component={PreAnnounceProduct}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/announce-winner/:templateId"
            exact
            component={AnnounceWinner}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/results/:templateId"
            exact
            component={ResultsPage}
          />
          <Route
            path="/marketplace/campaign/edit-campaign/:id/participants/:templateId"
            exact
            component={ParticipantPage}
          />
          <Redirect from="/marketplace" to="/marketplace/campaign" exact />
        </Switch>
      </div>
    </CampaignContextProvider>
  );
};

export default CampaignRoute;
