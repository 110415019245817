import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import LocalStorage from "../../../../../config/LocalStorage";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";

interface IProps {
  setId: (id: number) => void;
}

const DropWinnerModal: React.FC<IProps> = ({ setId }) => {
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const { drop_winner } = CampaignState;
  const params: { templateId: string } = useParams();
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  const goBack = () => {
    CampaignDispatch({
      type: ActionType.DropWinner,
      payload: {
        id: 0,
        status: false,
      },
    });
  };

  const drop = async () => {
    await mutation.mutate();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      CampaignRequest.dropWinner(
        Number(params.templateId),
        drop_winner.id,
        role
      ),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else {
          await queryClient.invalidateQueries("GetCampaignTemplate");
          setId(0);
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-1/4 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10 pb-3">
          <div className="flex flex-col items-center">
            <h1 className="text-xl">Delete Username</h1>
            <h1 className="text-base text-center w-60 text-textPrimary mt-4 font-rom">
              Are you sure you want to delete the username ?
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <SmallButton
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Cancel"}
              textColor={"text-textPrimary"}
              height={10}
            />
            <div className="mx-1.5"></div>
            <SmallButton
              isSubmitting={mutation.isLoading}
              loadColor={"white"}
              type={"button"}
              height={10}
              handleClick={drop}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Delete"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropWinnerModal;
