import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { cancel } from "../../../../../assets/icons";
import Input from "../../../../../components/input/Input";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import SmallButton from "../../../../../components/button/SmallButton";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";
import { ActionType } from "../../../../../store/product/product_need_review/actions";
import { ProductScrapingRequest } from "../../../../../apis/product/ProductScrapingRequest";
import { useHistory } from "react-router";

const CrawlModal = () => {
  const { productReviewState, productReviewDispatch } =
    useContext(ProductReviewContext);

  const goBack = () => {
    productReviewDispatch({
      type: ActionType.ShowCrawl,
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      keyword: "",
    },
    validationSchema: Yup.object({
      keyword: Yup.string().required("Keyword is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });
  
  const mutation = useMutation(
    () => ProductScrapingRequest.crawlProduct(values, setSubmitting),
    {
      onMutate: () => {
        console.log("mutate crawl product");
      },
      onSettled: async (data: any, error: any) => {
        if (data) {
          const win: any = window.open(
            `/marketplace/product-need-review/crawl-result?scrap_batch_id=${data.scrap_batch_id}`,
            "_blank"
          );
          win.focus();
        } else if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-10/12 sm:w-11/12 md:w-1/2 lg:w-1/3 xl:w-1/4  h-1/3 sm:h-1/2 md:h-1/2 lg:h-2/5 xl:h-1/3  rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center h-1/4 px-10">
          <div className="flex items-center">
            <h1 className="text-xl flex">Crawl</h1>
            <h1 className="ml-1 text-xl text-primary">Product</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 h-3/4">
          <div className="h-1/2 flex flex-col justify-end">
            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-textPrimary">
                Please input product you want to crawl
              </h1>
            </div>
            <Input
              value={values.keyword}
              type={"text"}
              handleChange={handleChange}
              disable={false}
              name={"keyword"}
              placeholder={"Place holder"}
            />
            {touched.keyword && errors.keyword && (
              <h1 className="text-primary font-rom -mt-1 text-xs">
                {errors.keyword}
              </h1>
            )}
          </div>
          <div className="flex justify-center items-center outline-none h-1/2">
            <SmallButton
              isSubmitting={isSubmitting}
              dirty={dirty}
              loadColor={"white"}
              height={10}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Crawl"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CrawlModal;
