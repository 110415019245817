import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { ActionType } from "../../../../store/campaign/action";
import LocalStorage from "../../../../config/LocalStorage";
import { validateRole } from "../../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../../layout/marketplace/interfaces";

interface IProps {
  data?: any;
}

const CampaignCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { CampaignDispatch } = useContext(CampaignContext);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  return (
    <div className="w-full h-auto border-2 border-stroke rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4 py-5">
        <div className="border-r-2 border-stroke flex flex-col items-center justify-center px-8 ">
          <div>
            <h1 className="text-lg font-rom">Campaign</h1>
            <h1 className="text-xl mt-1.5">{data.title}</h1>
          </div>
        </div>
        <div className="col-span-2 pl-7">
          <div className="flex">
            <h1 className="text-base">Total Product:</h1>
            <h1 className="text-base text-softPin ml-1">-</h1>
          </div>
          <div className="flex mt-4">
            <div className="w-1/2 flex flex-col items-start justify-center border-r-2 border-stroke">
              <h1 className="text-base font-rom ">Start Time</h1>

              <h1 className="text-lg mt-0.5">
                {moment(data.start_at).format("LL")} |{" "}
                {moment(data.start_at).format("HH:mm A")}
              </h1>
            </div>

            <div className="w-1/2 flex flex-col items-start justify-evenly px-10">
              <h1 className="text-base font-rom">End Time</h1>
              <h1 className="text-lg mt-0.5">
                {moment(data.end_at).format("LL")} |{" "}
                {moment(data.end_at).format("HH:mm A")}
              </h1>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-evenly mx-10 border-l-2 border-stroke">
          <h1
            onClick={() =>
              history.push({
                pathname: "/marketplace/campaign/info",
                search: `campaign=${data.title}`,
              })
            }
            className="text-base hover:text-primary cursor-pointer"
          >
            Campaign Info
          </h1>

          {validateRole(role, [
            AcceptedRole.Admin,
            AcceptedRole.User,
            AcceptedRole.Spinner,
            AcceptedRole.Operation,
            AcceptedRole.Product,
            AcceptedRole.Marketing,
          ]) && (
            <div className="flex items-center gap-5">
              <h1
                onClick={() =>
                  history.push(`/marketplace/campaign/edit-campaign/${data.id}`)
                }
                className="text-base hover:text-primary cursor-pointer"
              >
                Edit
              </h1>
              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.Marketing,
              ]) && (
                <h1
                  onClick={() =>
                    CampaignDispatch({
                      type: ActionType.ShowDeleteCampaign,
                      payload: {
                        status: true,
                        id: data.id,
                      },
                    })
                  }
                  className="text-base hover:text-primary cursor-pointer"
                >
                  Delete
                </h1>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
