export enum ActionType {
  SetLanguage,
}

export interface SetLanguage {
  type: ActionType.SetLanguage;
  payload: { lang: string };
}

export type GlobalAction = SetLanguage;
