import React, { useContext, useRef } from "react";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import { loadOptionsCategory, loadOptionsProduct } from "../../../../../utils/loadOption/LoadOption";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Input from "../../../../../components/input/Input";
import ProductDepatuContext from "../../../../../store/product/product_depatu/ProductDepatuContext";
import HandleClickOutside from "../../../../../utils/click_outside_box/HandleClickOutside";
import { ActionType } from "../../../../../store/product/product_depatu/actions";
import SetQueryString from "../../../../../utils/query_searchParams/SetQueryString";
import { useHistory, useLocation } from "react-router-dom";
import { queryparamsProductDepatu } from "../../helpers/list";

const statusOptions: any[] = [
  { value: "true", label: "Posted" },
  { value: "false", label: "Not Posted" },
];

const ProductDepatuFilter = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { productDepatuDispatch, productDepatuState } =
    useContext(ProductDepatuContext);
  const { showFilter } = productDepatuState;
  const wrapperRef = useRef(null);
  HandleClickOutside(
    wrapperRef,
    productDepatuDispatch,
    showFilter,
    ActionType.ShowFilter
  );

  const handleShowFilter = () => {
    productDepatuDispatch({ type: ActionType.ShowFilter });
  };

  const handleChangeFilter = (event: any, default_name?: string) => {
    let name;
    let value;
    if (!event?.label) {
      name = event.target.name;
      value = event.target.value;
    } else {
      value = event.value;
      name = default_name;
    }
    SetQueryString(
      queryparamsProductDepatu,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  const applyFilter = () => {
    setTimeout(
      () => productDepatuDispatch({ type: ActionType.ShowFilter }),
      500
    );
  };

  return (
    <div className="fixed z-50 cursor-pointer h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        ref={wrapperRef}
        className="bg-white md:rounded-lg cursor-default rounded-t-none rounded-b-lg overflow-hidden h-3/4 shadow-xl transform transition-all md:w-1/2 w-full"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/6">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full" />
        <div className="h-4/6 overflow-y-auto">
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Product Name
              </h1>
              <Input
                type="text"
                name="product"
                handleChange={handleChangeFilter}
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Product Code (SKU)
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptionsProduct}
                onChange={(e) => handleChangeFilter(e, "product_id")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">Status</h1>
              <Select
                isSearchable={true}
                className="my-3.5 font-rom text-sm"
                options={statusOptions}
                onChange={(e) => handleChangeFilter(e, "status")}
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Category
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptionsCategory}
                onChange={(e) => handleChangeFilter(e, "category")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          {/* <div className="grid grid-cols-2 gap-8 w-full px-10 md:my-0 my-3 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Add Time (from)
              </h1>
              <Input
                type={"date"}
                name={"add_time_from"}
                handleChange={handleChangeFilter}
              />
            </div>
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Add Time (to)
              </h1>
              <Input
                type={"date"}
                name={"add_time_to"}
                handleChange={handleChangeFilter}
              />
            </div>
          </div>
          <hr className="w-full" /> */}
          <div className="grid grid-cols-2 gap-8 w-full px-10 md:my-0 my-3 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Release Date (from)
              </h1>
              <Input
                type={"date"}
                name={"release_date_from"}
                handleChange={handleChangeFilter}
              />
            </div>
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Release Date (to)
              </h1>
              <Input
                type={"date"}
                name={"release_date_to"}
                handleChange={handleChangeFilter}
              />
            </div>
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-center items-center h-1/6">
          <SmallButton
            type={"button"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDepatuFilter;
