import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import { AcceptedRole } from "../../../../layout/marketplace/interfaces";

const GetCampaignId = (role: string) => {
  const params: { id: string } = useParams();
  const { data, isLoading } = useQuery(
    [`GetCampaignId-${params.id}`],
    role === AcceptedRole.Admin
      ? () => CampaignRequest.getCampaignByIdAdmin(params.id)
      : () => CampaignRequest.getCampaignById(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetCampaignId;
