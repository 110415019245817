import React, { useContext } from "react";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { formatRupiah } from "../../helpers/common";
import Abutton from "../atoms/Abutton";
import MproductListCard from "./MproductListCard";
import Countdown from "react-countdown";
import Acountdown from "../atoms/Acountdown";
import { Atruck, Awhatsapp } from "../atoms/icons";
import {
  EBnibTransactionStatus,
  IBnibTransaction,
} from "../../core/bnib_transaction/entities";
import { ActionType } from "../../store/transaction/action";
import TransactionContext from "../../store/transaction/TransactionContext";

export const renderStatusColor = (status: number) => {
  const statusColor: any = {
    0: "text-warning",
    1: "text-warning",
    2: "text-primary",
    3: "text-primary",
    4: "text-success",
    5: "text-success",
    6: "text-warning",
    7: "text-success",
    8: "text-warning",
    9: "text-primary",
    10: "text-warning",
    11: "text-warning",
    12: "text-warning",
    13: "text-success",
    14: "text-primary",
    15: "text-success",
    16: "text-success",
    17: "text-success",
    18: "text-success",
    19: "text-success",
    20: "text-primary",
    21: "text-success",
    22: "text-success",
    23: "text-success",
    24: "text-success",
    25: "text-success",
    26: "text-warning",
  };
  return statusColor[status];
};

interface IMbnibTransactionCardProps {
  data: IBnibTransaction;
}

const MbnibTransactionCard: React.FC<IMbnibTransactionCardProps> = ({
  data,
}) => {
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { TransactionDispatch } = useContext(TransactionContext);
  // const [more, setMore] = useState(false);
  // const length = 1;

  const WHATSAPP_ROUTE = "https://api.whatsapp.com/send?phone";

  const goDetail = (code: string) => {
    navigate.push(`${pathname}/${code}`);
  };

  const goTransactionProcess = (code: string) => {
    navigate.push(`${pathname}/${code}/process`);
  };

  const setOpenTrackDialog = (code: string) => {
    TransactionDispatch({
      type: ActionType.ShowTrackProduct,
      payload: {
        status: true,
        code,
      },
    });
  };

  return (
    <aside className="w-full bg-white rounded-lg shadow-md">
      <main>
        <article className="sm:px-5 sm:pt-5 sm:pb-5 px-3 pt-3 pb-0 flex justify-between sm:border-b-2 sm:text-sm text-xxxs">
          <div className="flex lg:flex-row flex-col gap-1 w-4/5">
            <span className="text-primary">{data?.code}</span>
            <span>| ID: {data?.id} |</span>
            <span className="text-textSecondary">
              Transaction Date : {moment(data?.created_at).format("llll")}
            </span>
          </div>

          {data.status === EBnibTransactionStatus.WaitingPaymentFromBuyer && (
            <div className="sm:w-auto w-full gap-1">
              <div className="flex justify-between items-center ">
                <h1 className="font-rom mr-1">Time Remaining:</h1>
                <Countdown
                  zeroPadTime={2}
                  date={data?.payment_invoice?.expired_at}
                  renderer={(props) => (
                    <Acountdown
                      seconds={props.seconds}
                      minutes={props.minutes}
                      hours={props.hours}
                      days={props.days}
                      completed={props.completed}
                    />
                  )}
                />
              </div>
              <hr className="sm:hidden sm:mt-1.5 mt-0.5" />
            </div>
          )}
        </article>
        <article className="sm:px-5 sm:pt-3.5 px-3 pt-3 pb-0 sm:text-sm text-xxxs">
          <div className="flex flex-wrap justify-between">
            <div className="flex lg:flex-row flex-col gap-1">
              <span>Location: -</span>
            </div>

            <div className="sm:w-auto w-full flex gap-1">
              Status:
              <span className={renderStatusColor(data?.status)}>
                {data?.status_message}
              </span>
            </div>
          </div>
          <hr className="sm:mt-3.5 mt-0.5" />
        </article>

        <article className="sm:p-5 p-3">
          <aside className="grid sm:grid-cols-3 grid-cols-1 sm:gap-5 gap-2">
            <div className="sm:border-r-2 col-span-2 sm:pr-5">
              <div className="grid sm:grid-cols-5 grid-cols-4 sm:gap-3 gap-1 sm:text-xs text-xxxs text-textPrimary">
                <div className="border-r-2 pr-3 sm:col-span-3 col-span-2">
                  <MproductListCard
                    image={data?.product_detail?.display_image_url}
                    name={data?.product_detail?.name || "-"}
                    sku={data?.product_detail?.code}
                  />
                </div>

                <div className="flex flex-col">
                  <h1 className="">Condition & Size</h1>
                  <h1 className="font-rom sm:mt-1.5 mt-0.5 break-words">
                    {data.pre_order ? "Pre-Order" : "BNIB"} |{" "}
                    {data?.product_detail.product_size.display_size}
                  </h1>
                </div>
              </div>
              <hr className="mt-3" />
              <aside className="flex gap-3 my-3">
                <div className="flex gap-1 items-center text-textPrimary sm:text-xs text-xxxs border-r pr-3">
                  <h1>Customer :</h1>
                  <div className="font-rom flex items-center gap-1">
                    <h1>{data?.buyer_username || "-"}</h1>
                    <a
                      href={`${WHATSAPP_ROUTE}=62${data.buyer_phone}`}
                      target="blank"
                    >
                      <Awhatsapp />
                    </a>
                  </div>
                </div>
                <div className="flex gap-1 sm:text-xs text-xxxs">
                  <h1 className="">Seller :</h1>
                  <div className="font-rom flex items-center gap-1">
                    <h1 className="break-words">
                      {data?.seller_username || "-"}
                    </h1>
                    {data?.seller_username && (
                      <a
                        href={`${WHATSAPP_ROUTE}=62${data.seller_phone}`}
                        target="blank"
                      >
                        <Awhatsapp />
                      </a>
                    )}
                  </div>
                </div>
              </aside>
            </div>

            <div className="grid sm:grid-cols-1 grid-cols-2 gap-x-3">
              <div className="flex flex-col gap-1 text-textPrimary sm:text-xs text-xxxs">
                {data.bid_payment_invoice !== null
                  ? data.bid_payment_invoice.extra_fees.map((el, idx) => (
                      <div className="flex justify-between">
                        <span>{el.name}</span>
                        <span className="font-rom">
                          {formatRupiah(el.amount)}
                        </span>
                      </div>
                    ))
                  : data.payment_invoice.extra_fees.map((el, idx) => (
                      <div className="flex justify-between">
                        <span>{el.name}</span>
                        <span className="font-rom">
                          {formatRupiah(el.amount)}
                        </span>
                      </div>
                    ))}
              </div>
            </div>
          </aside>
          <aside className="my-4 grid grid-cols-3 font-rom sm:text-xs text-xxxs">
            <div className="border-t col-span-2 pt-3.5">
              <span>
                Payment Method:{" "}
                {data.bid_payment_invoice !== null
                  ? data.bid_payment_invoice.payment_type
                  : data.payment_invoice.payment_type || "-"}
                (
                {data.bid_payment_invoice !== null
                  ? moment(data.bid_payment_invoice.created_at).format(
                      "DD/MM/YYYY"
                    )
                  : moment(data.payment_invoice.created_at).format(
                      "DD/MM/YYYY"
                    )}
                )
              </span>
            </div>
            <div className="border-t pt-3.5 pl-3 flex justify-between">
              <span className="font-med">Total Price</span>
              <span className="text-primary font-med">
                {data.bid_payment_invoice !== null
                  ? formatRupiah(data.bid_payment_invoice.amount)
                  : formatRupiah(data.payment_invoice.amount)}
              </span>
            </div>
          </aside>
          <hr className="block" />
        </article>
      </main>
      <article className="sm:flex sm:px-5 px-3 sm:pb-5 pb-3 justify-between">
        <div
          // onClick={() => goDetailMobile(data.code)}
          className="flex items-center gap-5 font-rom sm:text-xs text-xxxs"
        >
          <div
            onClick={() => setOpenTrackDialog(data.code)}
            className="flex items-center gap-2 hover:text-textPrimary cursor-pointer"
          >
            <Atruck width="22" />
            <span>Track Transaction</span>
          </div>
        </div>

        <div className="flex gap-3 sm:mt-0 mt-3">
          <Abutton
            title="Details"
            theme="secondary"
            model="long"
            onClick={() => goDetail(data.code)}
          />
          <Abutton
            title="Transaction Process"
            theme="primary"
            model="long"
            onClick={() => goTransactionProcess(data.code)}
          />
        </div>
      </article>
    </aside>
  );
};

export default MbnibTransactionCard;
