import React, { useContext } from "react";
import { cancel } from "../../../../assets/icons/index";
import AsyncSelect from "react-select/async";
import SmallButton from "../../../../components/button/SmallButton";
import Input from "../../../../components/input/Input";
import CouponContext from "../../../../store/coupon/CouponContext";
import { ActionType } from "../../../../store/coupon/action";
import {
  loadOptCoupon,
  loadOptionsUsernameById,
} from "../../../../utils/loadOption/LoadOption";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CouponRequest } from "../../../../apis/coupon/CouponRequest";
import moment from "moment";

const SendCouponModal = () => {
  const { couponDispatch } = useContext(CouponContext);
  const goBack = () => {
    couponDispatch({ type: ActionType.ShowSendCoupon });
  };

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      id: { label: "", value: "" },
      user_ids: [],
      expired_at: "",
    },
    validationSchema: Yup.object({
      expired_at: Yup.string().required("Expired At is required"),
      user_ids: Yup.array().min(1, "Username is required"),
      id: Yup.object().shape({
        value: Yup.string().required("Coupon is required"),
      }),
    }),
    onSubmit: async () => {
      const payload: any = {
        id: values.id.value,
        expired_at: moment(values.expired_at).format(),
      };
      const user_ids = values.user_ids.map((el: any) => el.value);
      payload["user_ids"] = user_ids;
      await CouponRequest.sendCoupon(payload, setSubmitting, goBack);
    },
  });
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-lg h-2/3 shadow-xl w-10/12 md:w-2/5"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 flex justify-between items-center w-full h-1/5">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Send Coupon
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={goBack}
          />
        </div>
        <hr className="w-full" />
        <div className="h-3/5">
          <div className="flex px-10 items-center h-1/2">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">Username</h1>
              <AsyncSelect
                name="username"
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsUsernameById}
                onChange={(e: any) => setValues({ ...values, user_ids: e })}
                className="my-3.5 font-rom text-sm"
              />
              {errors.user_ids && touched.user_ids && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.user_ids}
                </p>
              )}
            </div>
          </div>
          <hr className="w-full " />
          <div className="flex w-full px-10 items-center h-1/2">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Select Package
              </h1>
              <AsyncSelect
                name="coupon"
                cacheOptions
                defaultOptions
                loadOptions={loadOptCoupon}
                onChange={(e: any) => setValues({ ...values, id: e })}
                className="my-3.5 font-rom text-sm"
              />
              {errors.id && touched.id && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.id.value}
                </p>
              )}
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired At
              </h1>
              <Input
                disable={false}
                type={"date"}
                name={"expired_at"}
                value={values.expired_at}
                handleChange={handleChange}
              />
              {errors.expired_at && touched.expired_at && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.expired_at}
                </p>
              )}
            </div>
          </div>
        </div>
        <hr className="w-full " />

        <div className="flex justify-center items-center h-1/5">
          <SmallButton
            type={"submit"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            text={"Send"}
            loadColor={"white"}
          />
        </div>
      </form>
    </div>
  );
};

export default SendCouponModal;
