import { isEmpty, isNumber } from "lodash";
import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import { queryparamsTopUp } from "../../pages/top_up/helpers/list";
import ApplyChip from "../../utils/applyChip/ApplyChip";

class TopupRequest {
  static getTopup = async (TopUpDispatch: any, role: string | null) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(
        `${role === AcceptedRole.Cashier ? "cashier" : "admin"}/topup`,
        {
          params: {
            ...(query.get("payment_channel") !== null && {
              payment_channel: query.get("payment_channel"),
            }),
            ...(role === AcceptedRole.Cashier && {
              payment_channel: "manual_counter",
            }),
            ...(query.get("payment_status") !== null && {
              payment_status: query.get("payment_status"),
            }),
            ...(query.get("code") !== null && {
              code: query.get("code"),
            }),
            ...(query.get("username") !== null && {
              username: query.get("username"),
            }),
            ...(query.get("top_up_from") !== null &&
            query.get("top_up_to") !== null
              ? {
                  release_date: `${query.get("top_up_from")},${query.get(
                    "top_up_to"
                  )}`,
                }
              : ""),
            ...(query.get("top_up_from") !== null &&
            query.get("top_up_to") === null
              ? { release_date: `${query.get("top_up_from")},2100-01-01` }
              : ""),
            ...(query.get("top_up_from") === null &&
            query.get("top_up_to") !== null
              ? { release_date: `2000-01-01,${query.get("top_up_to")}` }
              : ""),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChip(queryparamsTopUp, TopUpDispatch);
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static getTopupId = async (id: string, role: string | null) => {
    try {
      const results = await axiosCelestine().get(
        `${role === AcceptedRole.Cashier ? "cashier" : "admin"}/topup/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getAdminUser = async (id: string) => {
    try {
      const results = await axiosCelestine().get(`admin/user/${id}`);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static postTopup = async (
    values: {
      username: string;
      amount: string;
      password: string;
      payment_method: string;
      bank: string;
      description: string;
      card_number: string;
      invoice_id: number;
    },
    handleHideModal: () => void,
    role: string | null
  ) => {
    try {
      const payload: any = {
        username: values.username,
        amount: isNumber(values.amount)
          ? values.amount
          : Number(values.amount.substring(4).split(",").join("").trim()),
        password: values.password,
        payment_type: values.payment_method,
        bank: values.bank,
        card_number: values.card_number.split(" ").join(""),
        description: values.description,
      };
      if (values.invoice_id) {
        payload["invoice_id"] = values.invoice_id;
      }
      await axiosCelestine()
        .post(
          `${role === AcceptedRole.Cashier ? "cashier" : "admin"}/topup`,
          payload
        )
        .then(({ data }) => {
          handleHideModal();
          ErrorHandler("#00C62E", "Top up Completed");
        })
        .catch((err) => {
          if (typeof err.response.data.errors === "object") {
            ErrorHandler(
              "#DF5354",
              `${Object.keys(err.response.data.errors)[0]} : ${
                Object.values(err.response.data.errors)[0]
              }`
            );
          } else {
            ErrorHandler("#DF5354", err.response.data.errors);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  static cancelTopup = async (
    values: any,
    handleHideModal: () => void,
    showCancel: { id: string },
    role: string | null
  ) => {
    try {
      await axiosCelestine()
        .post(
          `${
            role === AcceptedRole.Cashier ? "cashier" : "admin"
          }/topup/revert/${showCancel.id}`,
          values
        )
        .then(({ data }) => {
          handleHideModal();
          ErrorHandler("#00C62E", "Cancel Completed");
        })
        .catch((err) => {
          ErrorHandler("#DF5354", err.response.data.errors);
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export default TopupRequest;
