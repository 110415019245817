import React from "react";

interface IProps {
  color: string;
}

const Increase: React.FC<IProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 48 48"
    >
      <g
        id="Group_6836"
        data-name="Group 6836"
        transform="translate(-954 -498)"
      >
        <g
          id="Ellipse_557"
          data-name="Ellipse 557"
          transform="translate(954 498)"
          fill="#fff"
          stroke={color}
          stroke-width="2"
        >
          <circle cx="24" cy="24" r="24" stroke="none" />
          <circle cx="24" cy="24" r="23" fill="none" />
        </g>
        <rect
          id="Rectangle_2467"
          data-name="Rectangle 2467"
          width="27"
          height="5"
          transform="translate(965 520)"
          fill={color}
        />
        <rect
          id="Rectangle_2468"
          data-name="Rectangle 2468"
          width="27"
          height="5"
          transform="translate(981 509) rotate(90)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Increase;
