import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { OnBiddingRequest } from "../../../../apis/on_bidding/OnBiddingRequest";
import { down, up } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";

export interface SellerInfoProps {
  data: any;
}

const SellerInfo: React.FC<SellerInfoProps> = ({ data }) => {
  const [dropdown, setdropdown] = useState<boolean>(true);

  const handledropdown = () => {
    setdropdown(!dropdown);
  };

  const { data: dataBuyer, isLoading: loadBuyer } = useQuery(
    ["getOnSellingUser", data?.seller_id],
    () => OnBiddingRequest.getOnBiddingUser(data?.seller_id)
  );

  return (
    <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
      <div className="rounded-md border-2 border-stroke">
        <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-lg text-base">
            Seller Information
          </h1>

          <div className="flex justify-between items-center">
            {dropdown ? (
              <img
                src={up}
                alt="up"
                className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                onClick={handledropdown}
              />
            ) : (
              <img
                src={down}
                alt="down"
                className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                onClick={handledropdown}
              />
            )}
          </div>
        </div>
        {dropdown ? <hr className="border-t-2" /> : ""}

        {dropdown && !loadBuyer && (
          <div className="md:flex w-full px-8 py-6">
            <div className="md:w-full">
              <div className="flex w-full">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Username
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Username"}
                    value={dataBuyer?.username || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Full Name
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Name"}
                    value={dataBuyer?.full_name || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Email
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Email"}
                    value={dataBuyer?.email}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Verified Email
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"VerifiedEmail"}
                    value={dataBuyer?.verified_email}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Gender
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={dataBuyer?.gender}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Birthday
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={moment(dataBuyer?.birthday).format("LL")}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Referral Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={dataBuyer?.referral_code}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Country Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={dataBuyer?.country_code}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Phone
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={dataBuyer?.phone || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Verified Phone
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={dataBuyer?.verified_phone || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Joined at
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={moment(dataBuyer?.created_at).format("LL")}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SellerInfo;
