import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cancel } from "../../../assets/icons";
import Input from "../../../components/input/Input";
import TopupContext from "../../../store/topup/TopupContext";
import SetQueryString from "../../../utils/query_searchParams/SetQueryString";
import { queryparamsTopUp } from "../helpers/list";

const TopupFilter: React.FC = () => {
  const history = useHistory();
  const context = useContext(TopupContext);
  const { pathname, search } = useLocation();
  const { topupDispatch, topupState } = context;
  const { showFilter } = topupState;

  const handleShowFilter = () => {
    topupDispatch({ type: "show_filter" });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    SetQueryString(queryparamsTopUp, value, name, search, pathname, history);
  };

  const applyFilter = () => {
    setTimeout(() => topupDispatch({ type: "show_filter" }), 500);
  };

  return (
    <div>
      {showFilter && (
        <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
          <div
            className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all md:max-w-2xl max-w-lg"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="px-10 py-7 flex justify-between w-full">
              <h1
                className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
                id="modal-headline"
              >
                Filter
              </h1>
              <img
                src={cancel}
                alt="back"
                className="cursor-pointer md:w-5 w-3"
                onClick={handleShowFilter}
              />
            </div>
            <hr className="w-full " />
            <div className="flex px-10 py-5">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Top Up Code
                </h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"code"}
                  handleChange={handleChangeFilter}
                />
              </div>
              <div className=" w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Username
                </h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"username"}
                  handleChange={handleChangeFilter}
                />
              </div>
            </div>
            <hr className="w-full " />
            <div className="flex px-10 py-5">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Payment Channel
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="payment_channel"
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected disabled hidden>
                    Select Here
                  </option>
                  <option value="midtrans">Midtrans</option>
                  <option value="manual_counter">Manual Counter</option>
                </select>
              </div>
              <div className="w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Payment Status
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="payment_status"
                  id=""
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected hidden>
                    Select Here
                  </option>
                  <option value="initiated">Initiated</option>
                  <option value="success">Success</option>
                  <option value="pending">Pending</option>
                  <option value="expired">Expired</option>
                  <option value="failed">Failed</option>
                  <option value="revert">Revert</option>
                  <option value="capture">Capture</option>
                  <option value="cancel">Cancel</option>
                </select>
              </div>
            </div>
            <hr className="w-full" />

            <div className="flex px-10 py-5">
              <div className="md:w-96 w-34">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Top Up (From)
                </h1>
                <input
                  type="date"
                  onChange={handleChangeFilter}
                  name="top_up_from"
                  className="md:h-10 h-8 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                />
              </div>
              <div className="md:w-96 w-34 ml-12 md:ml-12">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Top Up (To)
                </h1>
                <input
                  type="date"
                  onChange={handleChangeFilter}
                  name="top_up_to"
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                />
              </div>
            </div>
            <hr className="w-full " />
            <div className="flex justify-center m-5">
              <button
                onClick={applyFilter}
                className="bg-primary w-36 h-9 rounded-full focus:ring-0 focus:outline-none hover:bg-red-300"
              >
                <h1 className="text-white text-base mb-0.5">Apply</h1>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopupFilter;
