import React, { useContext } from "react";
import { cancel } from "../../../assets/icons";
import Input from "../../../components/input/Input";
import TagsContext from "../../../store/tags/TagsContext";
import { applyChip } from "../../../utils/applyChip/ApplyChip";
import { useQueryClient } from "react-query";
import SmallButton from "../../../components/button/SmallButton";

const TagsFilter = () => {
  const queryClient = useQueryClient();
  const context = useContext(TagsContext);
  const { tagsState, tagsDispatch } = context;
  const { showFilter, filter } = tagsState;

  const handleShowFilter = () => {
    tagsDispatch({ type: "show_filter" });
    tagsDispatch({ type: "reset_filter" });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    tagsDispatch({
      type: "handle_filter",
      payload: { ...filter, [name]: value },
    });
  };

  const applyFilter = () => {
    queryClient.invalidateQueries("getTags");
    applyChip(filter, tagsDispatch);
    setTimeout(() => tagsDispatch({ type: "show_filter" }), 1000);
  };

  return (
    showFilter && (
      <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
        <div
          className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all md:max-w-2xl max-w-lg"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="px-10 py-7 flex justify-between w-full">
            <h1
              className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
              id="modal-headline"
            >
              Filter
            </h1>
            <img
              src={cancel}
              alt="back"
              className="cursor-pointer md:w-5 w-3"
              onClick={handleShowFilter}
            />
          </div>
          <hr className="w-full " />
          <div className="flex px-10 py-5">
            <div className="mr-12 w-96">
              <h1 className="md:text-base text-sm text-textPrimary">Tag Name</h1>
              <Input
                type={"text"}
                placeholder={"Place holder"}
                name={"name"}
                handleChange={handleChangeFilter}
              />
            </div>
            <div className=" w-96">
              <h1 className="md:text-base text-sm text-textPrimary">Status</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="true">Active</option>
                <option value="false">Not Active</option>
              </select>
            </div>
          </div>
          <hr className="w-full " />

          <div className="flex justify-center m-5">
            <SmallButton
              handleClick={applyFilter}
              loadColor={"white"}
              type={"button"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Apply"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default TagsFilter;
