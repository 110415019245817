import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosCelestine from "../../../config/AxiosCelestine";
import FilePonds from "../../../components/addImage/Filepond";
import Loading from "../../../components/loading/Loading";
import Input from "../../../components/input/Input";
import ErrorHandler from "../../../components/toast/ErrorHandler";

export interface EditProductCategoryGroupProps {
  showEdit: number;
  setShowEdit: any;
  refetch: any;
}

const EditProductCategoryGroup: React.FC<EditProductCategoryGroupProps> = ({
  refetch,
  showEdit,
  setShowEdit,
}) => {
  const [editSlug, setEditSlug] = useState(false);
  const [files, setFiles] = useState<any>(null);
  const [edited, setEdited] = useState<any>({
    name: "",
    slug: "",
    is_active: false,
    is_active_legit_check: false,
  });

  const goBack = () => {
    setShowEdit(0);
  };

  const handleEditSlug = () => {
    setEditSlug(!editSlug);
  };

  const handlePostinLegit = () => {
    if (edited.is_active_legit_check === true) {
      setEdited({ ...edited, is_active_legit_check: false });
    } else {
      setEdited({ ...edited, is_active_legit_check: true });
    }
  };

  const handlePost = () => {
    if (edited.is_active === true) {
      setEdited({ ...edited, is_active: false });
    } else {
      setEdited({ ...edited, is_active: true });
    }
  };

  const getProductCategoryGroupById = async () => {
    try {
      const results = await axiosCelestine().get(
        `/admin/product-category-group/${showEdit}`
      );
      const result = await results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const { data, isSuccess } = useQuery(
    ["getProductCategoryGroupById", showEdit],
    getProductCategoryGroupById,
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setEdited({
      name: data?.name,
      slug: data?.slug,
      is_active: data?.is_active,
      is_active_legit_check: data?.is_active_legit_check,
    });
    return function () {
      return setEdited({
        name: "",
        slug: "",
        is_active: false,
        is_active_legit_check: false,
      });
    };
  }, [data]);

  const handleChangeInput = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    setEdited({ ...edited, [name]: value });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // if (!editSlug) {
    //   setEdited({ ...edited, slug: edited.name.split(" ").join("-") });
    // }
    const edited_product_category_group = new FormData();
    if (files !== null) {
      edited_product_category_group.append("image", files);
    }
    if (editSlug) {
      edited_product_category_group.append(
        "slug",
        edited.slug.split(" ").join("-")
      );
    } else if (edited.slug !== data.slug) {
      edited_product_category_group.append("slug", edited.slug);
    }
    if (edited.name !== data.name) {
      edited_product_category_group.append("name", edited.name);
      edited_product_category_group.append(
        "slug",
        edited.name.split(" ").join("-")
      );
    }
    edited_product_category_group.append(
      "is_active",
      edited.is_active.toString()
    );
    edited_product_category_group.append(
      "is_active_legit_check",
      edited.is_active_legit_check.toString()
    );

    axiosCelestine()
      .patch(
        `admin/product-category-group/${showEdit}`,
        edited_product_category_group
      )
      .then(({ data }) => {
        refetch();
        ErrorHandler("#00C62E", "Edit Completed");
        setShowEdit(0);
      })
      .catch((err) => ErrorHandler("#DF5354", "Edit Failed"));
  };
  return isSuccess ? (
    <form
      onSubmit={handleSubmit}
      className="md:w-productCategory md:max-w-md lg:w-productCategory lg:max-w-xl xl:w-productCategoryxl xl:max-w-customxl 2xl:w-productCategory2xl 2xl:max-w-2xl 3xl:w-productCategory3xl 3xl:max-w-3xl h-auto my-5 xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke "
    >
      <div className="flex justify-between w-full p-6 pb-5">
        <div>
          <img
            src={data?.image_path}
            className="xl:w-40 xl:h-40 xl:mt-10 2xl:w-44 2xl:h-44 2xl:mt-5 md:w-36 md:h-36 md:mt-10  border-2 border-stroke rounded-xl"
            alt="logo_path"
          />
        </div>
        <div className="w-2/3 h-52 xl:ml-6 lg:ml:4 md:ml-3">
          <div className="flex justify-end">
            <h1 className="font-medium xl:text-sm md:text-xs mr-3 text-textPrimary">
              Posted
            </h1>
            <div className="flex justify-between items-center">
              <div
                className={
                  edited.is_active
                    ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                    : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                }
              >
                <div
                  className={
                    edited.is_active
                      ? "bg-white  md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3"
                      : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                  }
                  onClick={handlePost}
                ></div>
              </div>
            </div>
          </div>
          <div className="w-full mt-9 xl:pr-0 md:ml-1 xl:ml-0 2xl:ml-0">
            <h1 className="xl:text-sm md:text-xs  text-textPrimary">
              Display Image
            </h1>
            <h1 className="xl:text-xs md:text-xxs font-rom text-textPrimary my-1">
              (1 x 1), (PNG) max. 1 MB
            </h1>
            <FilePonds setFiles={setFiles} imageType={["image/png"]} />
          </div>
        </div>
      </div>

      {files !== null ? <hr className="mt-52 mb-5" /> : <hr className="my-5" />}

      <div className="md:flex w-full mb-8">
        <div className="md:mx-8 mx-5 md:w-full">
          <div>
            <h1 className="xl:text-sm md:text-xs  text-textPrimary">
              Category Name
            </h1>
            <Input
              type={"text"}
              handleChange={handleChangeInput}
              value={edited.name}
              name={"name"}
              placeholder={"Place holder"}
            />
          </div>
          <div className="md:mt-3 mt-3">
            <div className="flex justify-between">
              <h1 className="xl:text-sm md:text-xs text-textPrimary">Slug</h1>
              <div className="flex justify-between items-center">
                <div
                  className={
                    !editSlug
                      ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                      : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                  }
                >
                  <div
                    className={
                      !editSlug
                        ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                        : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3"
                    }
                    onClick={handleEditSlug}
                  ></div>
                </div>
                <h1 className="xl:text-sm md:text-xs font-rom ml-3">edit</h1>
              </div>
            </div>
            {editSlug === false ? (
              <Input
                type={"text"}
                value={edited.slug}
                disable={true}
                name={"slug"}
                placeholder={"Place holder"}
              />
            ) : (
              <Input
                type={"text"}
                handleChange={handleChangeInput}
                value={edited.slug}
                disable={false}
                name={"slug"}
                placeholder={"Place holder"}
              />
            )}
          </div>
          <div className="flex md:mt-3">
            <h1 className="xl:text-sm md:text-xs  text-textPrimary">
              Post in Legit Check ?
            </h1>
            <div className="flex justify-between items-center ml-5">
              <div
                className={
                  edited.is_active_legit_check
                    ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                    : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                }
              >
                <div
                  className={
                    edited.is_active_legit_check
                      ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3 translate-x-3"
                      : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                  }
                  onClick={handlePostinLegit}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-around xl:text-sm md:text-xs  h-20 w-full">
        <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1 onClick={goBack} className="hover:text-primary">
            Cancel
          </h1>
        </div>
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <button type="submit" className="focus:outline-none">
            <h1 className="hover:text-primary">Edit</h1>
          </button>
        </div>
      </div>
    </form>
  ) : (
    <div className="flex h-72 items-center justify-center md:w-productCategory md:max-w-md lg:w-productCategory lg:max-w-xl xl:w-productCategoryxl xl:max-w-customxl 2xl:w-productCategory2xl 2xl:max-w-2xl 3xl:w-productCategory3xl 3xl:max-w-3xl">
      <Loading />
    </div>
  );
};

export default EditProductCategoryGroup;
