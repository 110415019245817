import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { revenue } from "../../../../assets/icons/Web";
import Pagination from "../../../../components/pagination/Pagination";
import { formatRupiah } from "../../../../utils/format_rupiah/FormatRupiah";
import ReactTooltip from "react-tooltip";
import { detail } from "../../../../assets/icons";
import InvoiceContext from "../../../../store/invoice/InvoiceContext";
import { ActionType } from "../../../../store/invoice/action";

export interface IWaitingInvoiceTableProps {
  data: any;
}

export const renderSellerType = (type: string) => {
  const helper: { [key: string]: string } = {
    bnib_product: "Marketplace",
    bnib_buy_order_direct: "Marketplace",
    bnib_buy_order: "Marketplace",
    bnib_buy_order_payment: "Marketplace",
    bnib_product_direct: "Marketplace",
    retail: "Official Store (Product)",
    retail_ticket_bundle: "Official Store (Ticket)",
    raffle_ticket_campaign: "Campaign Credit",
    entry_fee_bidding: "Bidding Entry Fee",
  };
  const sellerType: string = helper[type];
  return sellerType;
};

const WaitingInvoiceTable: React.FC<IWaitingInvoiceTableProps> = ({ data }) => {
  const history = useHistory();
  const { InvoiceDispatch } = useContext(InvoiceContext);

  return (
    <div className="w-full my-5">
      <table className="w-full border relative text-center text-sm">
        <thead className="sticky z-30">
          <tr className="h-18 sticky z-30 top-20 bg-white shadow-sm">
            <th className="border-r w-10%">Transaction Date</th>
            <th className="border-r w-10%">Code</th>
            <th className="border-r w-10%">Buyer Username</th>
            <th className="border-r w-10%">Seller Type</th>
            <th className="border-r w-15%">Product</th>
            <th className="border-r w-10%">Amount</th>
            <th className="border-r px-2">Invoice Status</th>
            <th className="border-r px-2">Topup Status</th>
            <th className="border-r w-10%">Expired At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">
                {moment(el.created_at).format("ll HH:mm a") || "-"}
              </td>
              <td className="border-r text-sm">{el.code || "-"}</td>
              <td className="border-r">{el.buyer_username}</td>
              <td className="border-r">
                <div>
                  <h1>{renderSellerType(el.type)}</h1>
                  {renderSellerType(el.type) === "Official Store (Product)" && (
                    <h1 className="font-med">
                      Store: {` ${el?.retail_product?.retail_shop_name}`}
                    </h1>
                  )}
                </div>
              </td>
              <td className="border-r">
                {renderSellerType(el.type) === "Marketplace" ? (
                  <div>
                    <h1>{el?.product_detail?.name}</h1>
                    <h1 className="font-med">Size: {el?.product_size?.size}</h1>
                  </div>
                ) : renderSellerType(el.type) === "Campaign Credit" ? (
                  <div>
                    <h1>Credit</h1>
                  </div>
                ) : (
                  <div>
                    <h1>{el?.detail?.title || el?.retail_product?.name}</h1>
                    <h1 className="font-med">
                      Variant:{" "}
                      {el?.detail?.sub_title ||
                        el?.retail_product?.retail_product_stock?.label}
                    </h1>
                  </div>
                )}
              </td>
              <td className="border-r">{formatRupiah(el.amount) || "-"}</td>
              <td className="border-r font-med">
                {el.status === "success" ? (
                  <h1 className="text-success">{el.status}</h1>
                ) : el.status === "expired" || el.status === "revert" ? (
                  <h1 className="text-primary">{el.status}</h1>
                ) : (
                  <h1 className="text-yellow-400">{el.status}</h1>
                )}
              </td>
              <td className="border-r font-med">
                {el?.top_up?.payment_status === "success" ? (
                  <h1 className="text-success">{el?.top_up?.payment_status}</h1>
                ) : el?.top_up?.payment_status === "expired" ||
                  el?.top_up?.payment_status === "revert" ? (
                  <h1 className="text-primary">
                    {el?.top_up?.payment_status}
                  </h1>
                ) : (
                  <h1>-</h1>
                )}
              </td>
              <td className="border-r">
                {moment(el.expired_at).format("ll HH:mm a") || "-"}
              </td>
              <td>
                <div className="flex justify-evenly mx-2">
                  {el?.status !== "expired" && (
                    <div className="mr-2">
                      <img
                        data-tip
                        data-for="topup"
                        className="cursor-pointer md:w-7"
                        src={revenue}
                        alt="topup"
                        onClick={() =>
                          InvoiceDispatch({
                            type: ActionType.ShowTopup,
                            payload: {
                              status: true,
                              id: el.id,
                            },
                          })
                        }
                      />
                      <ReactTooltip id="topup" type="dark">
                        <span>Top up</span>
                      </ReactTooltip>
                    </div>
                  )}
                  <div>
                    <img
                      data-tip
                      data-for="detail"
                      className="cursor-pointer md:w-5"
                      onClick={() =>
                        history.push(
                          `/marketplace/invoice/waiting-invoice/${el.id}/${el.user_id}`
                        )
                      }
                      src={detail}
                      alt="detail"
                    />
                    <ReactTooltip id="detail" type="info">
                      <span>Details</span>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};
export default WaitingInvoiceTable;
