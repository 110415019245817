import { useFormik } from "formik";
import React, { useContext } from "react";
import { cancel } from "../../../assets/icons";
import SmallButton from "../../../components/button/SmallButton";
import Input from "../../../components/input/Input";
import { ActionType } from "../../../store/send_credit/action";
import SendCreditContext from "../../../store/send_credit/SendCreditContext";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import {
  loadOptCampaign,
  loadOptionsUsernameById,
} from "../../../utils/loadOption/LoadOption";
import { SendCreditRequest } from "../../../apis/send_credit/SendCreditRequest";
import { useMutation, useQueryClient } from "react-query";
import ErrorHandler from "../../../components/toast/ErrorHandler";
import LocalStorage from "../../../config/LocalStorage";
import { validateRole } from "../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../layout/marketplace/interfaces";
import { useParams } from "react-router-dom";

const SendCreditModal = () => {
  const params: { id: string } = useParams();
  const { SendCreditDispatch } = useContext(SendCreditContext);
  const goBack = () => {
    SendCreditDispatch({ type: ActionType.ShowSendCredit });
  };
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "giver";

  const { values, setValues, handleSubmit, handleChange, touched, errors } =
    useFormik({
      initialValues: {
        role: role,
        username: "",
        user_ids: [],
        send_data: [{ campaign_id: 0, qty: 0 }],
      },
      validationSchema: Yup.object({
        username: Yup.string().when("role", {
          is: (role: string) => role === AcceptedRole.Giver,
          then: Yup.string().required("Username is required"),
        }),
        user_ids: Yup.array().when("role", {
          is: (role: string) => role === AcceptedRole.Admin,
          then: Yup.array().min(1, "Username is required"),
        }),
      }),
      onSubmit: async () => {
        const payload: any = { ...values };
        delete payload.role;
        if (role === AcceptedRole.Admin) {
          const user_ids = values.user_ids.map((el: any) => el.value);
          payload["user_ids"] = user_ids;
          delete payload.username;
        } else {
          payload["username"] = values.username.trim();
          delete payload.user_ids;
        }
        await mutation.mutate(payload);
      },
    });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload) => SendCreditRequest.sendCredit(payload, role),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(`GetSendCredit-${params.id}`);
          goBack();
          setTimeout(() => {
            ErrorHandler("#00C62E", `Send Credit Completed`);
          }, 100);
        }
      },
    }
  );

  const addInformationColumn = () => {
    const column = { campaign_id: 0, qty: 0 };
    const new_send_data = [...values.send_data].concat(column);
    setValues({ ...values, send_data: new_send_data });
  };

  const removeColumn = (idx: number) => {
    const new_send_data = values.send_data.filter(
      (a: any, i: number) => i !== idx
    );
    setValues({ ...values, send_data: new_send_data });
  };

  const handleSelectCampaign = (e: any, idx: number) => {
    const new_send_data = [...values.send_data];
    new_send_data[idx].campaign_id = e.value;
    setValues({ ...values, send_data: new_send_data });
  };

  const handleChangeQuantity = (e: any, idx: number) => {
    const new_send_data = [...values.send_data];
    new_send_data[idx].qty = +e.target.value;
    setValues({ ...values, send_data: new_send_data });
  };

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-lg shadow-xl w-10/12 md:w-2/5 h-auto"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-5 flex justify-between items-center w-full">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Send Credit
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={goBack}
          />
        </div>
        <hr className="w-full" />
        <div>
          <div className="flex px-10 items-center pt-5 pb-2">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">Username</h1>
              {validateRole(role, AcceptedRole.Admin) ? (
                <AsyncSelect
                  name="username"
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptionsUsernameById}
                  onChange={(e: any) => setValues({ ...values, user_ids: e })}
                  className="my-3.5 font-rom text-sm"
                />
              ) : (
                <Input
                  type={"text"}
                  name={"username"}
                  handleChange={handleChange}
                />
              )}
              {errors.user_ids && touched.user_ids && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.user_ids}
                </p>
              )}
            </div>
          </div>
          <section className="2xl:max-h-80 max-h-64 overflow-auto">
            {values.send_data.map((el: any, idx: number) => (
              <aside key={idx}>
                <div className="flex w-full px-10 items-center">
                  <div className="mr-5 w-3/5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Campaign
                    </h1>
                    <AsyncSelect
                      name="campaign"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptCampaign}
                      onChange={(e) => handleSelectCampaign(e, idx)}
                      className="my-3.5 font-rom text-sm"
                    />
                  </div>
                  <div className="w-3/5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Quantity
                    </h1>
                    <Input
                      disable={false}
                      type={"number"}
                      name={"Qty"}
                      handleChange={(e: any) => handleChangeQuantity(e, idx)}
                    />
                  </div>
                  <div className="mt-5 ml-6 mr-2 h-full flex justify-center items-center">
                    <img
                      onClick={() => removeColumn(idx)}
                      src={cancel}
                      alt="-"
                      className="w-5 cursor-pointer"
                    />
                  </div>
                </div>
                <div className="px-10 my-3">
                  <hr className="w-full" />
                </div>
              </aside>
            ))}
          </section>
        </div>

        <div className="flex justify-center items-center pt-3 pb-5">
          <h1
            onClick={addInformationColumn}
            className="hover:text-primary cursor-pointer"
          >
            Add Information
          </h1>
        </div>

        <hr className="w-full" />
        <div className="flex justify-center items-center py-5">
          <SmallButton
            type={"submit"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            text={"Send"}
            loadColor={"white"}
            isSubmitting={mutation.isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default SendCreditModal;
