import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { BannerRequest } from "../../../apis/banner/BannerRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../components/navbar/NavDetail";
import Loading from "../../../components/loading/Loading";
import EditForm from "./edit_banner_component/EditForm";

const EditBanner = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const optRoutesEditBanner = [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/banner",
      name: "Banner",
    },
    {
      id: 3,
      path: `/marketplace/banner/edit-banner/${params.id}`,
      name: "Edit Banner",
    },
  ];

  const { data, isLoading } = useQuery(
    ["getBannerByid", params],
    () => BannerRequest.getBannerById(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return (
    <div>
      <NavDetail title1={"Edit"} title2={"Banner"} handleBack={() => history.goBack()} />

      <div className="md:pt-28">
        <DynamicBreadcrumb data={optRoutesEditBanner} />

        {isLoading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <EditForm data={data} />
        )}
      </div>
    </div>
  );
};

export default EditBanner;
