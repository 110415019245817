import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";

export class TransactionRequest {
  static selectCustomTrackingLocation = async (
    code: string,
    id: number,
    goBack: any
  ) => {
    try {
      await axiosCelestine().post(
        `admin/bnib-transaction/${code}/custom-track-origin`,
        {
          custom_tracking_origin_id: id,
        }
      );
      ErrorHandler("#00C62E", "Select Location Success");
      goBack();
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getBnibTransaction = async (code: string) => {
    try {
      const results = await axiosCelestine().get(
        `admin/bnib-transaction/${code}`
      );
      const res = results.data;
      return res;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getCustomTrackingStep = async (id: string) => {
    try {
      const results = await axiosCelestine().get(`admin/custom-tracking-step`, {
        params: {
          custom_tracking_origin_id: id,
          sort: "priority asc",
          limit: 50,
        },
      });
      const { data } = results.data;
      return data;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static handleChangeStep = async (code: string, id: number, goBack: any) => {
    try {
      await axiosCelestine().post(
        `admin/bnib-transaction/${code}/custom-track-step`,
        {
          custom_tracking_step_id: id,
        }
      );
      ErrorHandler("#00C62E", "Edit Completed");
      goBack();
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getTrack = async (code: string) => {
    try {
      const results = await axiosCelestine().get(
        `admin/bnib-transaction/${code}/track`
      );
      const res = results.data;
      return res;
    } catch (error) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };
}
