import React, { useContext, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { cancel } from "../../../assets/icons/index";
import SmallButton from "../../../components/button/SmallButton";
import AsyncSelect from "react-select/async";
import ApplyChip from "../../../utils/applyChip/ApplyChip";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { loadOptionsOnBiddingBidder } from "../../../utils/loadOption/LoadOption";
import HandleClickOutside from "../../../utils/click_outside_box/HandleClickOutside";
import SetQueryString from "../../../utils/query_searchParams/SetQueryString";
import { queryparamsGroupProductSize } from "../helpers/list";
import OnBiddingContext from "../../../store/on_bidding/OnBiddingContext";
import { OnBiddingRequest } from "../../../apis/on_bidding/OnBiddingRequest";
import MultiSelect from "react-multi-select-component";
import CircularLoad from "../../../components/loading/CircularLoad";

const SeeAllFilter = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { pathname, search } = useLocation();
  const queryClient = useQueryClient();
  const context = useContext(OnBiddingContext);
  const { onBiddingState, onBiddingDispatch } = context;
  const { showFilterSeeAll } = onBiddingState;
  const wrapperRef = useRef(null);
  HandleClickOutside(
    wrapperRef,
    onBiddingDispatch,
    showFilterSeeAll,
    "show_filter_see_all"
  );
  const [removeUsernameList, setRemoveUsernameList] = useState<any[]>([]);

  const { data, isLoading } = useQuery("getBuyerUsername", () =>
    OnBiddingRequest.getBuyerUsername(params.id)
  );

  const handleShowFilter = () => {
    onBiddingDispatch({ type: "show_filter_see_all" });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    SetQueryString(
      queryparamsGroupProductSize,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  const applyFilter = () => {
    queryClient.invalidateQueries("getOnBiddingGroupByProductSize");
    ApplyChip(queryparamsGroupProductSize, onBiddingDispatch);
    history.push({ pathname, search });
    setTimeout(() => onBiddingDispatch({ type: "show_filter_see_all" }), 500);
  };

  const handleChangeUsername = (user: any) => {
    SetQueryString(
      [{ real_name: "seller_id", show_name: "username" }],
      user.values,
      "username",
      search,
      pathname,
      history
    );
  };

  const handleChangeRemoveUsername = (user: any) => {
    const arr: any[] = [];
    user.map((el: any) => {
      return arr.push(el.value);
    });
    SetQueryString(
      [{ real_name: "exclude_buyer_id", show_name: "remove_username" }],
      arr.toString(),
      "remove_username",
      search,
      pathname,
      history
    );
    setRemoveUsernameList(user);
  };

  return (
    <div className="fixed z-50 cursor-pointer h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        ref={wrapperRef}
        className="bg-white md:rounded-lg cursor-default rounded-t-none rounded-b-lg overflow-hidden h-3/4 shadow-xl transform transition-all w-1/2"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/6">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full " />
        <div className="h-4/6">
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Remove Username
              </h1>
              {isLoading ? (
                <div className="mx-3 my-4">
                  <CircularLoad color="#DF5354" height={24} width={24} />
                </div>
              ) : (
                <MultiSelect
                  isLoading={isLoading ? true : false}
                  debounceDuration={0}
                  className="my-3 font-rom text-sm"
                  options={data}
                  value={removeUsernameList}
                  onChange={(user: any) => handleChangeRemoveUsername(user)}
                  labelledBy="Select"
                />
              )}
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">Status</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="false">Brand new</option>
                <option value="true">Pre Order</option>
              </select>
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Bidder Username
              </h1>
              <AsyncSelect
                name="username"
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsOnBiddingBidder}
                onChange={handleChangeUsername}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Bidding Time (from)
              </h1>
              <input
                onChange={handleChangeFilter}
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke shadow-sm focus:ring-2 focus:outline-none"
                type="date"
                name="bidding_time_from"
              />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Bidding Time (to)
              </h1>
              <input
                onChange={handleChangeFilter}
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke shadow-sm focus:ring-2 focus:outline-none"
                type="date"
                name="bidding_time_to"
              />
            </div>
          </div>
        </div>
        <hr className="w-full " />

        <div className="flex justify-center items-center h-1/6">
          <SmallButton
            type={"button"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default SeeAllFilter;
