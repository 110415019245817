import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { ListManager } from "react-beautiful-dnd-grid";
import axiosCelestine from "../../../config/AxiosCelestine";

//components
import Loading from "../../../components/loading/Loading";
import EditCategories from "./EditCategories";

//icons
import { drag } from "../../../assets/icons";
import ErrorHandler from "../../../components/toast/ErrorHandler";

interface IProps {
  refetch: any;
  isLoading: boolean;
  dataList: any;
}

const CategoriesCard: React.FC<IProps> = ({ refetch, isLoading, dataList }) => {
  const params: {
    id: string;
  } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(0);

  const handleDrop = (sourceIndex: number, destinationIndex: number) => {
    const filterIndex = dataList[sourceIndex];
    if (sourceIndex === destinationIndex) {
      ErrorHandler("#DF5354", "Edit Failed");
    } else {
      setLoading(true);
      axiosCelestine()
        .patch(`admin/special-category/${filterIndex.id}`, {
          priority: destinationIndex + 1,
        })
        .then(() => {
          refetch();
          setTimeout(() => ErrorHandler("#00C62E", "Edit Completed"), 2000);
        })
        .catch((err) => {
          ErrorHandler("#DF5354", "Edit Failed");
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, [loading]);

  const handleShowEdit = (id: number) => {
    setShowEdit(id);
  };

  const handleShowDelete = (id: number) => {
    const group_id = params.id;
    history.push(
      `/marketplace/product-category-group/categories/${group_id}/delete/${id}`,
      { group_id }
    );
  };

  const goDetailPage = (id: number) => {
    history.push(`${url}/detail-categories/${id}`);
  };

  const ListElement = (item: any) => {
    return (
      <div className="md:w-productCategory md:max-w-md lg:w-productCategory lg:max-w-xl xl:w-productCategoryxl xl:max-w-customxl 2xl:w-productCategory2xl 2xl:max-w-2xl 3xl:w-productCategory3xl 3xl:max-w-3xl  xl:h-64 md:h-52 my-5 xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke">
        <div className="flex justify-between items-start h-2/3 p-6">
          <div className="flex">
            <div className="ml-5 flex flex-col justify-around">
              <h1 className="text-2xl">{item.item.name}</h1>
              <h1 className="text-lg mt-6 font-rom">
                Priority : {item.item.priority}
              </h1>
              <div className="flex mt-2">
                <h1 className="mr-1 text-lg">Status :</h1>{" "}
                {item.item.published ? (
                  <h1 className="text-success text-lg">Posted</h1>
                ) : (
                  <h1 className="text-primary text-lg">Not Posted</h1>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center ">
            <img src={drag} alt="" />
          </div>
        </div>

        <div className="flex justify-around xl:text-base md:text-sm w-full h-1/4 mt-5">
          <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/3 flex justify-center items-center">
            <h1
              onClick={() => goDetailPage(item.item.id)}
              className="hover:text-primary"
            >
              Detail
            </h1>
          </div>
          <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/3 flex justify-center items-center">
            <h1
              onClick={() => handleShowEdit(item.item.id)}
              className="hover:text-primary"
            >
              Edit
            </h1>
          </div>
          <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/3 flex justify-center items-center">
            <h1
              onClick={() => handleShowDelete(item.item.id)}
              className="hover:text-primary"
            >
              Delete
            </h1>
          </div>
        </div>
      </div>
    );
  };

  return isLoading ? (
    <div className="flex justify-center mt-20">
      <Loading />
    </div>
  ) : (
    <div className="w-full">
      {loading ? (
        <div className="flex justify-center items-center bg-black bg-opacity-40 h-screen w-full absolute left-0 right-0 top-0 z-50">
          <Loading />
        </div>
      ) : (
        <ListManager
          items={dataList}
          direction="horizontal"
          maxItems={2}
          render={(item) =>
            showEdit === item.id ? (
              <EditCategories
                setShowEdit={setShowEdit}
                showEdit={showEdit}
                refetch={refetch}
              />
            ) : (
              <ListElement item={item} />
            )
          }
          onDragEnd={handleDrop}
        />
      )}
    </div>
  );
};

export default CategoriesCard;
