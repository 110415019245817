import { ActionType, ProductDepatuAction } from "./actions";
import { IProductDepatuState } from "./state";

export const productDepatuReducer = (
  state: IProductDepatuState,
  action: ProductDepatuAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.AddImageEdit:
      const payload = action.payload;
      return { ...state, dataImageEdit: payload };
    case ActionType.AddImageAdd:
      const dataImageAdd = action.payload;
      return { ...state, dataImageAdd };
    case ActionType.AddDisplayImgEdit:
      const displayImageEdit = action.payload;
      return { ...state, displayImageEdit };

    case ActionType.AddDisplayImgAdd:
      const displayImageAdd = action.payload;
      return { ...state, displayImageAdd };

    case ActionType.ShowConfirmProductEdit:
      let showConfirmEdit = state.showConfirmEdit;
      document.body.classList[!showConfirmEdit ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return {
        ...state,
        showConfirmEdit: !showConfirmEdit,
      };

    case ActionType.ShowConfirmProductAdd:
      let showConfirmAdd = state.showConfirmAdd;
      document.body.classList[!showConfirmAdd ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return {
        ...state,
        showConfirmAdd: !showConfirmAdd,
      };

    case ActionType.ShowAddInfoEdit:
      let showAddInfoEdit = state.showAddInfoEdit;
      document.body.classList[!showAddInfoEdit ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddInfoEdit: !showAddInfoEdit };
    case ActionType.ShowAddInfoAdd:
      let showAddInfoAdd = state.showAddInfoAdd;
      document.body.classList[!showAddInfoAdd ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddInfoAdd: !showAddInfoAdd };

    case ActionType.AddInfoEdit:
      const info = action.payload;
      const new_data_info = [...state.dimensionEdit].concat({
        label: info,
        value: "",
      });
      return { ...state, dimensionEdit: new_data_info };

    case ActionType.AddInfoAdd:
      const infoAdd = action.payload;
      const new_data_info_add = [...state.dimensionAdd].concat({
        label: infoAdd,
        value: "",
      });
      return { ...state, dimensionAdd: new_data_info_add };

    case ActionType.AddInfoValueEdit:
      const value = action.payload;
      const name = action.name;
      const dimensionEdit: any = [...state.dimensionEdit];
      for (let index = 0; index < dimensionEdit.length; index++) {
        if (dimensionEdit[index]?.label === name) {
          dimensionEdit[index].value = value;
        }
      }
      return { ...state, dimensionEdit };
    case ActionType.AddInfoValueAdd:
      const val = action.payload;
      const nam = action.name;
      const dimensionAdd: any = [...state.dimensionAdd];
      for (let index = 0; index < dimensionAdd.length; index++) {
        if (dimensionAdd[index]?.label === nam) {
          dimensionAdd[index].value = val;
        }
      }
      return { ...state, dimensionAdd };

    case ActionType.DeleteInfoEdit:
      const deleted_info = action.payload;
      const data_info = state.dimensionEdit.filter((el) => el !== deleted_info);
      return { ...state, dimensionEdit: data_info };

    case ActionType.DeleteInfoAdd:
      const deleted_info_add = action.payload;
      const data_info_add = state.dimensionAdd.filter(
        (el) => el !== deleted_info_add
      );
      return { ...state, dimensionAdd: data_info_add };

    case ActionType.SetDefaultDimension:
      const new_dimension = action.payload;
      return { ...state, dimensionEdit: new_dimension };
      
    case ActionType.HandlePictureInit:
      const pictureInit = action.payload;
      return { ...state, pictureInit };
  }
};
