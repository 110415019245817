import { useQuery } from "react-query";
import { useParams } from "react-router";
import { CouponRequest } from "../../../../apis/coupon/CouponRequest";

const GetCouponById = () => {
  const params: { id: string } = useParams();
  const { data, isLoading } = useQuery(
    "getCouponById",
    () => CouponRequest.getCouponById(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetCouponById;
