import { IBuiltinSort, IPagination } from "../../core/helpers/helper";
import {
  EBnibBuyOrderSortField,
  PBnibBuyOrderFilter,
} from "../../core/bnib_buy_order/entities";
import BnibBuyOrderRepository from "../../core/bnib_buy_order/repository/repository";
import BnibBuyOrderUsecase from "../../core/bnib_buy_order/usecase/usecase";

export class BnibBuyOrderService {
  static getBnibBuyOrders = async (
    pagination: IPagination,
    filter: PBnibBuyOrderFilter,
    sorts: IBuiltinSort<EBnibBuyOrderSortField>[]
  ) => {
    try {
      const bnibBuyOrderRepo = new BnibBuyOrderRepository();
      const bnibBuyOrderUsecase = new BnibBuyOrderUsecase(bnibBuyOrderRepo);
      const bnibBuyOrder = await bnibBuyOrderUsecase.getBnibBuyOrders(
        pagination,
        filter,
        sorts
      );
      return bnibBuyOrder;
    } catch (error) {
      console.log(error);
    }
  };

  static getBnibBuyOrderCounter = async (filter: PBnibBuyOrderFilter) => {
    try {
      const bnibBuyOrderRepo = new BnibBuyOrderRepository();
      const bnibBuyOrderUsecase = new BnibBuyOrderUsecase(bnibBuyOrderRepo);
      const counter = await bnibBuyOrderUsecase.getBnibBuyOrderCounter(filter);
      return counter;
    } catch (error) {
      console.log(error);
    }
  };

  static getBnibBuyOrder = async (code: string) => {
    try {
      const bnibBuyOrderRepo = new BnibBuyOrderRepository();
      const bnibBuyOrderUsecase = new BnibBuyOrderUsecase(bnibBuyOrderRepo);
      const bnibBuyOrder = await bnibBuyOrderUsecase.getBnibBuyOrder(code);
      return bnibBuyOrder;
    } catch (error) {
      console.log(error);
    }
  };
  static getBnibBuyOrderById = async (id: number) => {
    try {
      const bnibBuyOrderRepo = new BnibBuyOrderRepository();
      const bnibBuyOrderUsecase = new BnibBuyOrderUsecase(bnibBuyOrderRepo);
      const bnibBuyOrder = await bnibBuyOrderUsecase.getBnibBuyOrderById(id);
      return bnibBuyOrder;
    } catch (error) {
      console.log(error);
    }
  };
}
