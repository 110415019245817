import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  home,
  iProfile,
  product,
  transaction,
  revenue,
  withdraw,
  logout,
  wallet,
} from "../../assets/icons/Web/index";
import { cancel, down, up } from "../../assets/icons";
import NavbarContext from "../../store/navbar/NavbarContext";
import TransactionSubsidebar from "./transaction_subsidebar/Transaction_subsidebar";
import LocalStorage from "../../config/LocalStorage";
import { CrossStorageClient } from "cross-storage";
import ProductSubsidebar from "./product_subsidebar/ProductSubsidebar";
import { validateRole } from "../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import InvoiceSubsidebar from "./invoice_subsidebar/InvoiceSubsidebar";
import { ActionType } from "../../store/navbar/action";

const Sidebar: React.FC = () => {
  const { NavbarDispatch, NavbarState } = useContext(NavbarContext);
  const { showSidebar, dropdownTransaction, dropdownProduct, dropdownInvoice } =
    NavbarState;
  const history = useHistory();
  const storage = new CrossStorageClient(
    process.env.REACT_APP_CROSS_STORAGE_URL || "",
    {}
  );
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "user";

  const handleShowSideBar = () => {
    NavbarDispatch({
      payload: false,
      type: ActionType.ShowSidebar,
    });
  };

  const handleDropdownTransaction = () => {
    NavbarDispatch({
      type: ActionType.ShowTransactionList,
    });
  };

  const handleDropdownInvoice = () => {
    NavbarDispatch({
      type: ActionType.ShowInvoiceList,
    });
  };

  const handleDropdownProduct = () => {
    NavbarDispatch({
      type: ActionType.ShowProductList,
    });
  };

  const handleLogout = () => {
    LocalStorage.AUTH_TOKEN = "";
    LocalStorage.ROLE = "";
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem("ROLE");
    storage
      .onConnect()
      .then(function () {
        return storage.del("AUTH_TOKEN");
      })
      .then(function () {
        return storage.del("ROLE");
      });
    history.push("/login");
  };

  const renderUsername = (role: string) => {
    const helper: { [key: string]: string } = {
      admin: "Admin",
      crawler: "Crawler",
      spinner: "Spinner",
      cashier: "Cashier",
      user: "User",
      giver: "Giver",
      admin__operation: "Operation Manager",
      admin__product: "Product Analyst",
      admin__key_account: "Customer & Partnership Analyst",
      admin__marketing: "Marketing Analyst",
      admin__finance_analyst: "Finance Analyst",
      admin__finance_support: "Finance Support",
    };
    const username: string = helper[role];
    return username;
  };

  return (
    <div
      className={
        showSidebar ? "fixed inset-0 bg-black bg-opacity-70 z-50 w-full" : ""
      }
    >
      <nav
        className={
          "shadow-xl fixed flex flex-wrap lg:w-1/4 w-10/12 z-50 py-6 duration-500 transform ease-in-out transition h-full  bg-white overflow-auto " +
          (!showSidebar && "-translate-x-full")
        }
      >
        <div className="flex-col bg  items-center justify-between w-full font-med">
          <div className="flex justify-between items-center w-full md:px-7 px-5">
            <h1
              onClick={handleShowSideBar}
              className="md:block text-left md:text-lg cursor-default text-sm font-med"
            >
              {renderUsername(role)}
            </h1>
            <img
              src={cancel}
              alt="back"
              onClick={handleShowSideBar}
              className="w-4 h-4 cursor-pointer"
            />
          </div>
          <hr className="mt-5 mb-2 w-full" />
          <div className="w-full items-center flex-1 lg:px-7 px-5 lg:mt-3 mt-1">
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf(
                          "/marketplace/product-category-group"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/product-category-group"
                    >
                      <img
                        src={home}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Product Category Group
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Cashier,
                AcceptedRole.finance_analyst,
                AcceptedRole.finance_support,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf("/marketplace/topup") !==
                        -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/topup"
                    >
                      <img
                        src={iProfile}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Top up
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Cashier,
                AcceptedRole.finance_analyst,
                AcceptedRole.finance_support,
              ]) && (
                <li>
                  <div>
                    <div className="py-2 flex flex-row flex-wrap justify-between items-center">
                      <div className="flex">
                        <img
                          src={wallet}
                          className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                          alt="icon"
                        />
                        <h1
                          onClick={handleDropdownInvoice}
                          className={
                            "md:text-lg text-sm font-medium items-center cursor-pointer " +
                            (window.location.href.indexOf(
                              "/marketplace/default"
                            ) !== -1
                              ? "text-black hover:text-gray-300"
                              : "text-gray-600 hover:text-gray-300")
                          }
                        >
                          Invoice
                        </h1>
                      </div>
                      <div>
                        <img
                          onClick={handleDropdownInvoice}
                          src={dropdownInvoice ? up : down}
                          className="w-4 mr-1 cursor-pointer"
                          alt="down"
                        />
                      </div>
                    </div>
                    <hr className="md:my-5 my-3 md:min-w-full" />
                    <InvoiceSubsidebar
                      dropdownInvoice={dropdownInvoice}
                      handleShowSideBar={handleShowSideBar}
                    />
                  </div>
                </li>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.KeyAccount,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf("/marketplace/tags") !==
                        -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/tags"
                    >
                      <img
                        src={withdraw}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Custom Tags
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.Marketing,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf("/marketplace/banner") !==
                        -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to={{ pathname: "/marketplace/banner", hash: "#general" }}
                    >
                      <img
                        src={product}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Banner
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.KeyAccount,
                AcceptedRole.Marketing,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf(
                          "/marketplace/couponn"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/coupon"
                    >
                      <img
                        src={product}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Coupon
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.KeyAccount,
              ]) && (
                <li>
                  <div>
                    <div className="py-2 flex flex-row flex-wrap justify-between items-center">
                      <div className="flex">
                        <img
                          src={transaction}
                          className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                          alt="icon"
                        />
                        <h1
                          onClick={handleDropdownTransaction}
                          className={
                            "md:text-lg text-sm font-medium items-center cursor-pointer " +
                            (window.location.href.indexOf(
                              "/marketplace/sss"
                            ) !== -1
                              ? "text-black hover:text-gray-300"
                              : "text-gray-600 hover:text-gray-300")
                          }
                        >
                          Transaction
                        </h1>
                      </div>
                      <div>
                        <img
                          onClick={handleDropdownTransaction}
                          src={dropdownTransaction ? up : down}
                          className="w-4 mr-1 cursor-pointer"
                          alt="down"
                        />
                      </div>
                    </div>
                    <hr className="md:my-5 my-3 md:min-w-full" />
                    <TransactionSubsidebar
                      dropdownTransaction={dropdownTransaction}
                      handleShowSideBar={handleShowSideBar}
                    />
                  </div>
                </li>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf(
                          "/marketplace/tracking"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/tracking"
                    >
                      <img
                        src={revenue}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Tracking
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Crawler,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.KeyAccount,
                AcceptedRole.Marketing,
              ]) && (
                <li>
                  <div>
                    <div className="py-2 flex flex-row flex-wrap justify-between items-center">
                      <div className="flex">
                        <img
                          src={product}
                          className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                          alt="icon"
                        />
                        <h1
                          onClick={handleDropdownProduct}
                          className={
                            "md:text-lg text-sm font-medium items-center cursor-pointer " +
                            (window.location.href.indexOf(
                              "/marketplace/sss"
                            ) !== -1
                              ? "text-black hover:text-gray-300"
                              : "text-gray-600 hover:text-gray-300")
                          }
                        >
                          Product
                        </h1>
                      </div>
                      <div>
                        <img
                          onClick={handleDropdownProduct}
                          src={dropdownProduct ? up : down}
                          className="w-4 mr-1 cursor-pointer"
                          alt="down"
                        />
                      </div>
                    </div>
                    <hr className="md:my-5 my-3 md:min-w-full" />
                    <ProductSubsidebar
                      dropdownProduct={dropdownProduct}
                      handleShowSideBar={handleShowSideBar}
                    />
                  </div>
                </li>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Spinner,
                AcceptedRole.User,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.KeyAccount,
                AcceptedRole.Marketing,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf(
                          "/marketplace/campaign"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/campaign"
                    >
                      <img
                        src={product}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Campaign
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.Marketing,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf(
                          "/marketplace/featured-product"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/featured-product"
                    >
                      <img
                        src={product}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Featured Product
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf("/marketplace/sizing") !==
                        -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/sizing"
                    >
                      <img
                        src={revenue}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Sizing
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.KeyAccount,
                AcceptedRole.Marketing,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf(
                          "/marketplace/credit-effect"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/marketplace/credit-effect"
                    >
                      <img
                        src={transaction}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Credit Effect
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              {validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Giver,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.KeyAccount,
                AcceptedRole.Marketing,
              ]) && (
                <>
                  <li className="py-2">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center " +
                        (window.location.href.indexOf(
                          "/marketplace/send-credit"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to={
                        role === AcceptedRole.Admin
                          ? "/marketplace/send-credit"
                          : `/marketplace/send-credit/history/id`
                      }
                    >
                      <img
                        src={transaction}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Send Credit
                    </Link>
                  </li>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </>
              )}

              <li className="py-2">
                <h1
                  onClick={handleLogout}
                  className={
                    "flex flex-row md:text-lg text-sm font-medium items-center cursor-pointer  " +
                    (window.location.href.indexOf("/home") !== -1
                      ? "text-black hover:text-gray-300"
                      : "text-gray-600 hover:text-gray-300")
                  }
                >
                  <img
                    src={logout}
                    className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                    alt="icon"
                  />
                  Logout
                </h1>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
