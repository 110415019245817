export const TrackingReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_add":
      let add = state.showAdd;
      document.body.classList[!add ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAdd: !add };
    case "show_add_step":
      let add_Step = state.showAddStep;
      document.body.classList[!add_Step ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddStep: !add_Step };
    case "show_edit_name":
      let editName = state.showEditName;
      return { ...state, showEditName: !editName };
    case "show_delete_location":
      let showDeleteLocation = action.payload;
      document.body.classList[showDeleteLocation.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDeleteLocation };
    case "show_delete_step":
      let showDeleteStep = action.payload;
      document.body.classList[showDeleteStep.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDeleteStep };
    case "show_edit_step":
      let editStep = action.payload;
      return { ...state, editStep };
    case "handle_asc":
      let new_asc = action.payload;
      return { ...state, isAsc: new_asc };
    case "handle_sort":
      let new_sort = action.payload;
      return { ...state, sortValue: new_sort };
    case "handle_limit":
      let new_limit = action.payload;
      return { ...state, limit: new_limit };
    case "handle_offset":
      let new_offset = action.payload;
      return { ...state, offset: new_offset };
  }
};
