/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";
import ATextField from "../../atoms/AtextField";
import * as Yup from "yup";
import Aselect from "../../atoms/Aselect";
import {
  useFetchLegitCheckDetail,
  useMutateCreateLegitCheckDetail,
  useMutateUpdateLegitCheckDetail,
} from "../../../hooks/legit_check";
import { Aloading } from "../../atoms/Aloading";

interface IOcreateLegitCheckDetailProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  legit_check_id: number;
  type: string;
  legit_check_detail_id?: number;
}

const OcreateLegitCheckDetail: React.FC<IOcreateLegitCheckDetailProps> = ({
  isOpen,
  setOpen,
  legit_check_id,
  legit_check_detail_id,
  type,
}) => {
  const params: { code: string } = useParams();

  const { data: dataLCDetail, isLoading: loadLCDetail } =
    useFetchLegitCheckDetail(legit_check_detail_id || 0);

  const { mutate, isLoading } = useMutateCreateLegitCheckDetail(
    params?.code,
    setOpen,
    legit_check_id
  );

  const { mutate: mutateUpdateLC, isLoading: loadUpdateLC } =
    useMutateUpdateLegitCheckDetail(
      params?.code,
      setOpen,
      legit_check_detail_id || 0,
      legit_check_id || 0
    );

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleReset,
    setValues,
  } = useFormik({
    initialValues: {
      checker_initial: "",
      currency: "",
      legit_check_id: legit_check_id,
      price: "",
      result: "",
    },
    validationSchema: Yup.object({
      checker_initial: Yup.string().required("Checker Initial is required!"),
      currency: Yup.string().required("Currency is required!"),
      price: Yup.string().required("Price is required!"),
      result: Yup.string().required("Result is required!"),
    }),
    onSubmit: (values) => {
      const payload: any = { ...values };
      payload.price = +values.price;
      if (legit_check_detail_id && type === "edit") {
        mutateUpdateLC(payload);
      } else {
        mutate(payload);
      }
    },
  });

  useEffect(() => {
    if (legit_check_detail_id && dataLCDetail && type === "edit") {
      setValues({
        ...values,
        price: dataLCDetail.price.toString(),
        result: dataLCDetail.result,
        checker_initial: dataLCDetail.checker_initial,
        currency: dataLCDetail.currency,
      });
    } else {
      handleReset({});
    }
  }, [legit_check_detail_id, dataLCDetail, type]);

  return (
    <ADialogBox
      content={{
        title: `${type === "edit" ? "Edit" : "Create"} Legit Check Details`,
        body: (
          <>
            {loadLCDetail ? (
              <div className="flex justify-center items-center h-full my-20">
                <Aloading />
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col">
                <div
                  className={
                    "h-10 mt-2 " +
                    (touched.checker_initial && errors.checker_initial
                      ? "mb-5"
                      : "mb-1")
                  }
                >
                  <ATextField
                    label="Checker Initial"
                    type="text"
                    name="checker_initial"
                    placeholder="Place Holder"
                    value={values.checker_initial}
                    handleChange={handleChange}
                    error={
                      touched.checker_initial && errors.checker_initial
                        ? true
                        : false
                    }
                    helperText={errors.checker_initial}
                  />
                </div>

                <div
                  className={
                    "h-10 mt-5 " +
                    (touched.currency && errors.currency ? "mb-5" : "mb-1")
                  }
                >
                  <Aselect
                    options={[
                      { value: "usd", label: "USD" },
                      { value: "cny", label: "CNY" },
                      { value: "idr", label: "IDR" },
                      { value: "sgd", label: "SGD" },
                    ]}
                    name="currency"
                    value={values.currency}
                    handleChange={handleChange}
                    error={touched.currency && errors.currency ? true : false}
                    helperText={errors.currency}
                    label="Currency"
                  />
                </div>

                <div
                  className={
                    "h-10 mt-5 " +
                    (touched.price && errors.price ? "mb-5" : "mb-1")
                  }
                >
                  <ATextField
                    label="Price"
                    type="number"
                    name="price"
                    placeholder="Place Holder"
                    value={values.price}
                    handleChange={handleChange}
                    error={touched.price && errors.price ? true : false}
                    helperText={errors.price}
                  />
                </div>

                <div
                  className={
                    "h-10 mt-5 " +
                    (touched.result && errors.result ? "mb-5" : "mb-1")
                  }
                >
                  <Aselect
                    options={[
                      { value: "authentic", label: "Authentic" },
                      { value: "fake", label: "Fake" },
                      { value: "indefinable", label: "Indefinable" },
                    ]}
                    name="result"
                    value={values.result}
                    handleChange={handleChange}
                    error={touched.result && errors.result ? true : false}
                    helperText={errors.result}
                    label="Result"
                  />
                </div>

                <div className="mt-7 flex gap-3 justify-end">
                  <Abutton
                    title="Cancel"
                    theme="secondary"
                    onClick={() => setOpen(false)}
                  />
                  <Abutton
                    isLoading={isLoading || loadUpdateLC}
                    type="submit"
                    title="Submit"
                  />
                </div>
              </form>
            )}
          </>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={setOpen}
    />
  );
};

export default OcreateLegitCheckDetail;
