import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";
import SizingContext from "../../../../store/sizing/SizingContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetSizing = () => {
  const query = useQueries(useLocation);
  const { SizingDispatch } = useContext(SizingContext);
  const { data, isLoading, isFetching } = useQuery(
    [
      "getSizing",
      query.get("product_category"),
      query.get("gender"),
      query.get("add_time_from"),
      query.get("add_time_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => SizingRequest.getSizing(SizingDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetSizing;
