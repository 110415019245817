import React, { useContext } from "react";
import DynamicBreadcrumb from "../../../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../../components/loading/Loading";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";
import { optRoutesCrawlResult } from "../../helpers/list";
import GetProductScraped from "../../helpers/useQueryHooks/GetProductScraped";
import ProductNeedReviewTable from "./ProductNeedReviewTable";

const CrawlResult = () => {
  const {productReviewDispatch} = useContext(ProductReviewContext)
  const { data, isLoading } = GetProductScraped(productReviewDispatch);

  return (
    <div>
      <DynamicBreadcrumb data={optRoutesCrawlResult} />
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <Loading />
        </div>
      ) : (
        <div className="mt-20">
          <ProductNeedReviewTable data={data} />
        </div>
      )}
    </div>
  );
};

export default CrawlResult;
