import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import Input from "../../../../components/input/Input";
import * as Yup from "yup";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import { cancel } from "../../../../assets/icons";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { ActionType } from "../../../../store/campaign/action";
import SmallButton from "../../../../components/button/SmallButton";
import ColorPicker from "rc-color-picker";
import Toggle from "../../../../components/button/Toggle";
import ErrorHandler from "../../../../components/toast/ErrorHandler";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddCampaignModal = () => {
  const { CampaignDispatch } = useContext(CampaignContext);

  const goBack = () => {
    CampaignDispatch({
      type: ActionType.ShowAddCampaign,
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    handleReset,
    setValues,
  } = useFormik({
    initialValues: {
      title: "",
      start_at: "",
      end_at: "",
      token_raffle_ticket_count: 0,
      color_hex: "#ffffff",
      active_color_hex: "1",
      active_ticket_count: "1",
      is_always_show: true,
      pick_up_address: "",
      pick_up_location: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Campaign Name is required"),
      pick_up_address: Yup.string().required("Location is required"),
      pick_up_location: Yup.string().required("Location link is required"),
      start_at: Yup.date().required("Start time is required"),
      end_at: Yup.date().required("End time is required"),
      color_hex: Yup.string().when("active_color_hex", {
        is: "1",
        then: Yup.string().required("Hexa code is Required"),
      }),
      token_raffle_ticket_count: Yup.number().when("active_ticket_count", {
        is: "1",
        then: Yup.number()
          .min(0, "Minimum of credit from scan is more than 0")
          .required("Get credit from scan is required"),
      }),
    }),
    onSubmit: async () => {
      await mutation.mutate();
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(() => CampaignRequest.addCampaign(values), {
    onMutate: () => {
      console.log("mutate add campaign");
    },
    onSettled: async (data: any, error: any) => {
      if (error) {
        console.log(error);
      } else if (data) {
        await queryClient.invalidateQueries("getCampaign");
        ErrorHandler("#00C62E", "Add Campaign Completed");
        goBack();
      }
    },
  });

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-productCategory rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center px-10 py-5">
          <div className="flex items-center">
            <h1 className="text-xl flex">Add</h1>
            <h1 className="ml-2 text-xl text-primary">Campaign</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 py-5 w-full">
          <div className="h-1/6">
            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-textPrimary">
                Campaign Name
              </h1>
              <div className="flex">
                <h1 className="md:text-sm text-xs font-rom text-textPrimary mr-2">
                  always show campaign
                </h1>
                <Toggle
                  input={values.is_always_show}
                  handleClick={() => {
                    setValues({
                      ...values,
                      is_always_show: !values.is_always_show,
                    });
                  }}
                />
              </div>
            </div>
            <Input
              type={"text"}
              handleChange={handleChange}
              disable={false}
              name={"title"}
              placeholder={"Place holder"}
            />
            {touched.title && errors.title && (
              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                {errors.title}
              </p>
            )}
          </div>
          <div className="flex h-1/6">
            <div className="w-1/2">
              <div className="flex justify-between">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Available for Scan
                </h1>
                <Toggle
                  input={values.active_ticket_count === "1" ? true : false}
                  handleClick={() => {
                    setValues({
                      ...values,
                      active_ticket_count:
                        values.active_ticket_count === "1" ? "0" : "1",
                    });
                  }}
                />
              </div>
              <Input
                type={"number"}
                disable={values.active_ticket_count === "0" ? true : false}
                handleChange={handleChange}
                name={"token_raffle_ticket_count"}
                placeholder={"Place holder"}
              />
              {touched.token_raffle_ticket_count &&
                errors.token_raffle_ticket_count && (
                  <p className="text-primary text-xs font-rom -mt-2 mb-1">
                    {errors.token_raffle_ticket_count}
                  </p>
                )}
            </div>
            <div className="w-1/2 ml-5">
              <div className="flex justify-between">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Hexa Code
                </h1>
                <Toggle
                  input={values.active_color_hex === "1" ? true : false}
                  handleClick={() => {
                    setValues({
                      ...values,
                      active_color_hex:
                        values.active_color_hex === "1" ? "0" : "1",
                    });
                  }}
                />
              </div>
              <div className="flex items-center">
                <Input
                  disable={values.active_color_hex === "0" ? true : false}
                  type={"text"}
                  name={"color_hex"}
                  value={values.color_hex}
                  handleChange={handleChange}
                  placeholder={"Place holder"}
                />
                <ColorPicker
                  animation="slide-up"
                  color={values.color_hex}
                  placement="bottomLeft"
                  className="mt-1 ml-3"
                  onChange={(e: any) =>
                    setValues({ ...values, color_hex: e.color })
                  }
                />
              </div>
              {errors.color_hex && touched.color_hex && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.color_hex}
                </p>
              )}
            </div>
          </div>
          <div className="flex h-1/6">
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Start Time
              </h1>
              <Input
                type={"datetime-local"}
                disable={false}
                handleChange={handleChange}
                name={"start_at"}
                placeholder={"Place holder"}
              />
              {touched.start_at && errors.start_at && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.start_at}
                </p>
              )}
            </div>
            <div className="w-1/2 ml-5">
              <h1 className="md:text-base text-sm text-textPrimary">End Time</h1>
              <Input
                type={"datetime-local"}
                disable={false}
                handleChange={handleChange}
                name={"end_at"}
                placeholder={"Place holder"}
              />
              {touched.end_at && errors.end_at && (
                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                  {errors.end_at}
                </p>
              )}
            </div>
          </div>
          <div className="h-1/6">
            <h1 className="md:text-base text-sm text-textPrimary">
              Location Link
            </h1>
            <Input
              type={"text"}
              disable={false}
              handleChange={handleChange}
              name={"pick_up_location"}
              placeholder={"Place holder"}
            />
            {touched.pick_up_location && errors.pick_up_location && (
              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                {errors.pick_up_location}
              </p>
            )}
          </div>
          <div className="h-1/6">
            <h1 className="md:text-base text-sm text-textPrimary">Location</h1>
            <div className="mt-3 font-rom">
              <CKEditor
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "link",
                    "blockQuote",
                    "|",
                    "undo",
                    "redo",
                  ],
                }}
                editor={ClassicEditor}
                data={values.pick_up_address}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  setValues({ ...values, pick_up_address: data });
                }}
                placeholder={"Place holder"}
              />
            </div>

            {touched.pick_up_address && errors.pick_up_address && (
              <p className="text-primary text-xs font-rom  mb-1">
                {errors.pick_up_address}
              </p>
            )}
          </div>

          <div className="flex justify-center items-center mt-3 outline-none">
            <SmallButton
              isSubmitting={mutation.isLoading}
              dirty={dirty}
              loadColor={"white"}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Add"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCampaignModal;
