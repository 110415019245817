import { ActionType, GlobalAction } from "./action";
import { IGlobalState } from "./state";

export const GlobalReducer = (state: IGlobalState, action: GlobalAction) => {
  switch (action.type) {
    case ActionType.SetLanguage:
      let payload = action.payload;
      return { ...state, lang: payload.lang };
  }
};
