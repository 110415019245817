import moment from "moment";
import React, { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Abutton from "../../components/atoms/Abutton";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../components/loading/Loading";
import { renderStatusColor } from "../../components/molecules/MbnibTransactionCard";
import MproductListCard from "../../components/molecules/MproductListCard";
import NavDetail from "../../components/navbar/NavDetail";
import { IBnibTransaction } from "../../core/bnib_transaction/entities";
import { formatRupiah } from "../../helpers/common";
import { useFetchBnibTransaction } from "../../hooks/bnib_transaction";

export const transactionRoute = (code: string) => {
  return [
    {
      id: 1,
      path: "/marketplace/transaction",
      name: "Transaction",
    },
    {
      id: 2,
      path: `/marketplace/transaction/${code}`,
      name: "Details",
    },
  ];
};

interface IShippingAddressDetailProps {
  data: IBnibTransaction;
}

const ShippingAddressDetail: FC<IShippingAddressDetailProps> = ({ data }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  // const { data: dataShippingSeller, isLoading } =
  //   useFetchShippingAddressByUserId(data?.seller_id);

  const goDetailProcess = () => {
    history.push(`${pathname}/process`);
  };

  // if (isLoading) {
  //   <div>
  //     <CircularLoad color={"#ed4846"} height={20} width={20} />
  //   </div>;
  // }
  return (
    <section className="text-textPrimary sm:text-sm text-xxxs">
      <div className="flex justify-between">
        <h1>Warehouse Address</h1>
        <h1>
          Courier:
          <span className="font-rom">
            {data?.office_shipping_courier_name || ""} -{" "}
            {/* {formatRupiah(data?.transaction?.buyer_shipping_cost || 0)}{" "} */}
          </span>
        </h1>
      </div>
      <hr className="sm:mt-4 mt-2" />
      <div className="my-4 font-rom">
        <span>
          {data?.warehouse?.name} | {data?.warehouse?.phone}
        </span>
        <p>
          {`${data?.warehouse?.address}, 
          ${data?.warehouse?.sub_district_name}, 
          ${data?.warehouse?.city_name},
          ${data?.warehouse?.province_name},${data?.warehouse?.postal_code}`}
        </p>
      </div>
      <hr />
      <div className="flex justify-between mt-4">
        <h1>Customer Address</h1>
        <h1>
          Courier:
          <span className="font-rom">
            {data?.buyer_shipping_courier_name || ""} -{" "}
            {formatRupiah(data?.buyer_shipping_cost || 0)}{" "}
          </span>
        </h1>
      </div>
      <hr className="sm:mt-4 mt-2" />
      <div className="my-4 font-rom">
        <span>
          {data?.shipping_address?.recipient} | {data?.shipping_address?.phone}
        </span>
        <p>
          {`${data?.shipping_address?.address}, 
          ${data?.shipping_address?.sub_district}, 
          ${data?.shipping_address?.city},
          ${data?.shipping_address?.province},${data?.shipping_address?.country}`}
        </p>
      </div>
      <hr />
      <div className="flex justify-between mt-4">
        <h1>Seller Address</h1>
        <h1>
          Courier:
          <span className="font-rom">
            {data?.refund_shipping_courier_name || ""} -{" "}
            {/* {formatRupiah(data?.transaction?.buyer_shipping_cost || 0)}{" "} */}
          </span>
        </h1>
      </div>
      <hr className="sm:mt-4 mt-2" />
      <div className="my-4 font-rom">
        <span>
          {data?.seller_shipping_address?.recipient} |{" "}
          {data?.seller_shipping_address?.phone}
        </span>
        <p>
          {`${data?.seller_shipping_address?.address}, 
          ${data?.seller_shipping_address?.sub_district}, 
          ${data?.seller_shipping_address?.city},
          ${data?.seller_shipping_address?.province},${data?.seller_shipping_address?.country}`}
        </p>
      </div>
      <hr />
      <div className="flex w-full justify-end mt-4">
        <Abutton
          title="Transaction Process"
          theme="primary"
          model="long"
          onClick={goDetailProcess}
        />
      </div>
    </section>
  );
};

const TransactionDetail = () => {
  const history = useHistory();
  const params: { code: string } = useParams();
  const { data, isLoading } = useFetchBnibTransaction(params?.code);

  if (isLoading) {
    return (
      <div className="w-full flex justify-center pt-36">
        <Loading />
      </div>
    );
  } else if (data) {
    return (
      <main className="sm:pt-28 pt-16">
        <div className="fixed top-0 z-30">
          <NavDetail
            title1="Transaction"
            title2="Details"
            handleBack={() => history.goBack()}
          />
        </div>
        <aside>
          <DynamicBreadcrumb data={transactionRoute(params?.code)} />
          <hr className="mt-5" />
        </aside>

        <main className="w-full mb-10">
          <section className="mt-5">
            <article>
              <h1 className="text-lg font-Bold">Transaction Details</h1>
            </article>
          </section>
          <section className="mt-5 w-full h-full gap-[2%]">
            <aside className="lg:w-2/3 sm:w-11/12 w-full h-auto">
              <div id="info" className="rounded-lg shadow-md bg-white p-5">
                <article className="w-full ">
                  <div className="flex">
                    <div className="w-1/2 sm:text-sm text-xxxs">
                      <h1 className="text-textPrimary">Transaction ID</h1>
                      <h1 className="text-textSecondary sm:mt-1.5 mt-0.5">
                        {data?.code}
                      </h1>
                    </div>
                    <div className="w-1/2 sm:text-sm text-xxxs">
                      <h1 className="text-textPrimary">Status</h1>
                      <h1
                        className={
                          "sm:mt-1.5 mt-0.5 " + renderStatusColor(data?.status)
                        }
                      >
                        {data?.status_message}
                      </h1>
                    </div>
                  </div>
                  <hr className="sm:my-4 my-2" />
                </article>
                {data && <ShippingAddressDetail data={data} />}
              </div>
            </aside>

            <aside className="w-full h-auto mt-8">
              <div
                id="invoice"
                className="rounded-lg shadow-md bg-white p-5 mt-5"
              >
                <article className="w-full">
                  <div className="flex justify-between w-full sm:text-sm text-xxxs">
                    <div className="w-1/2 flex justify-start gap-1">
                      <h1 className="text-textPrimary">Customer :</h1>
                      <h1 className="text-textPrimary font-rom">
                        {data.buyer_username}
                      </h1>
                    </div>
                    <div className="w-1/2 flex justify-end gap-1 font-rom">
                      <h1 className="text-textPrimary">Order Date :</h1>
                      <h1 className="text-textPrimary">
                        {moment(data.created_at).format("llll")}
                      </h1>
                    </div>
                  </div>
                  <hr className="mt-4" />
                </article>
                <article className="mt-4 flex flex-col">
                  <div className="grid grid-cols-6 gap-x-5 bg-stroke rounded-md sm:px-4 py-2 font-rom sm:text-sm text-xxxs">
                    <div className="col-span-2 w-2/5">Product</div>
                    <div className="flex justify-end">Price(IDR)</div>
                    <div className="flex justify-end">Shipping Fee (IDR)</div>
                    <div className="flex justify-end">Admin Fee (IDR)</div>
                    <div className="flex justify-end">Protection Fee (IDR)</div>
                  </div>
                  <div>
                    {
                      <div>
                        <div className="grid grid-cols-6 gap-x-5 sm:px-4 px-0 py-2 font-rom my-2.5">
                          <div className="col-span-2">
                            <MproductListCard
                              image={data.product_detail.display_image_url}
                              name={data.product_detail.name}
                              sku={data.product_detail.code}
                            />
                          </div>
                          <div className="flex justify-end sm:text-sm text-xxxs">
                            <span>{formatRupiah(data.buy_price)}</span>
                          </div>
                          <div className="flex justify-end sm:text-sm text-xxxs">
                            <span>
                              {formatRupiah(data?.buyer_shipping_cost || 0)}
                            </span>
                          </div>
                          <div className="flex justify-end sm:text-sm text-xxxs">
                            <span>{formatRupiah(data?.admin_fee || 0)}</span>
                          </div>
                          <div className="flex justify-end sm:text-sm text-xxxs">
                            <span>
                              {formatRupiah(data?.commitment_fee || 0)}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </div>
                    }
                  </div>
                  <div className="flex justify-end gap-2 sm:w-2/5 w-2/3 mt-4 self-end">
                    <div className="w-1/2 border-r b-border-stroke flex flex-col gap-y-1.5 font-rom sm:text-sm text-xxxs text-right pr-4">
                      <h1>Total Order</h1>
                      <h1>Total Shipping Fee</h1>
                      <h1 className="font-med">Total Price</h1>
                    </div>
                    <div className="w-1/2 flex flex-col items-end gap-y-1.5 font-rom sm:text-sm text-xxxs pr-4">
                      <h1>{formatRupiah(data.buy_price)}</h1>
                      <h1>{formatRupiah(data?.buyer_shipping_cost || 0)}</h1>
                      <h1 className="font-med text-primary">
                        {data.bid_payment_invoice !== null
                          ? formatRupiah(data.bid_payment_invoice.amount)
                          : formatRupiah(data.payment_invoice.amount)}
                      </h1>
                    </div>
                  </div>
                </article>
              </div>
            </aside>
          </section>
        </main>
      </main>
    );
  }
  return (
    <h1 className="pt-28 text-center">
      Something went wrong! Please refresh the page
    </h1>
  );
};

export default TransactionDetail;
