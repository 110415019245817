import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosCelestine from "../../../config/AxiosCelestine";

// components
import Filepond from "../../../components/addImage/Filepond";
import ErrorHandler from "../../../components/toast/ErrorHandler";

// icons
import NavDetail from "../../../components/navbar/NavDetail";
import Input from "../../../components/input/Input";

const AddProductCategoryGroup: React.FC = () => {
  const history = useHistory();
  const [posted, setPosted] = useState(true);
  const [editSlug, setEditSlug] = useState(false);
  const [postInLegit, setPostInLegit] = useState(false);
  const [files, setFiles] = useState<any>(null);
  const [errFile, setErrFile] = useState<boolean>(false);

  const goBack = () => {
    history.push("/marketplace/product-category-group");
  };

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name: "",
      slug: "",
      image: null,
      is_active: true,
      post_to_legit: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
    }),
    onSubmit: (values) => {
      if (!editSlug) {
        values.slug = values.name.split(" ").join("-");
      }
      const new_product_category_group = new FormData();
      if (files !== null) {
        new_product_category_group.append("image", files);
      }
      new_product_category_group.append("name", values.name);
      new_product_category_group.append("slug", values.slug);
      new_product_category_group.append(
        "is_active",
        values.is_active.toString()
      );
      new_product_category_group.append(
        "is_active_legit_check",
        values.post_to_legit.toString()
      );

      if (files === null) {
        setErrFile(!errFile);
      } else {
        setErrFile(!errFile);
        axiosCelestine()
          .post("admin/product-category-group", new_product_category_group)
          .then(({ data }) => {
            ErrorHandler("#00C62E", "Add Complete");
            setTimeout(() => {
              history.push("/marketplace/product-category-group");
            }, 1500);
          })
          .catch((err) => {
            ErrorHandler("#DF5354", "Category name is already exist!");
          });
      }
    },
  });

  const handleEditSlug = () => {
    setEditSlug(!editSlug);
  };

  const handlePostinLegit = () => {
    setPostInLegit(!postInLegit);
    values.post_to_legit = !values.post_to_legit;
  };

  const handlePost = () => {
    setPosted(!posted);
    values.is_active = !values.is_active;
  };

  return (
    <div>
      {/*navbar*/}
      <NavDetail
        handleBack={goBack}
        title1={"Add"}
        title2={"Product Category group"}
      />

      <div className="pt-28 font-med">
        {/* breadscrumb */}
        <div>
          <ul className="flex text-breadscrumbColor md:text-base text-xs">
            <li className="inline-flex items-center ">
              <Link to="/home" className="hover:text-black">
                Home
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="text-breadscrumbColor"
                to="/marketplace/product-category-group"
              >
                Product Category Group
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="text-black"
                to="/marketplace/add-product-category-group"
              >
                Add Product Category Group
              </Link>
            </li>
          </ul>
        </div>

        {/**Form */}
        <form
          onSubmit={handleSubmit}
          className="w-full h-full mt-12 md:flex justify-between"
        >
          {/* form image */}
          <div className="md:w-1/3 h-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
              <h1 className="font-medium md:text-lg text-base">
                Product Category Group Images
              </h1>
            </div>
            <hr />

            <div className="md:mx-8 mx-5 my-7">
              <div>
                <h1 className="md:text-base text-sm text-mobile text-textPrimary">
                  Display Image
                </h1>
                <h1 className="md:text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
                  (1 x 1), (PNG) max. 1 MB
                </h1>
                <Filepond setFiles={setFiles} imageType={["image/png"]} />
                {errFile ? (
                  <h1 className="text-primary -mt-1 text-sm">
                    Image is required
                  </h1>
                ) : null}
              </div>
            </div>
          </div>

          {/* form information */}
          <div className="md:w-custom h-full md:mt-0 mt-8">
            <div className="rounded-md rounded-b-none border-2 border-stroke">
              <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
                <h1 className="font-medium md:text-lg text-base">
                  Product Category Group Information
                </h1>

                <div className="flex">
                  <h1 className="font-medium md:text-lg text-sm mr-3 text-textPrimary">
                    Posted
                  </h1>
                  <div className="flex justify-between items-center">
                    <div
                      className={
                        posted
                          ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                          : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                      }
                    >
                      <div
                        className={
                          posted
                            ? "bg-white  md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3"
                            : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                        }
                        onClick={handlePost}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div className="md:flex w-full mb-10">
                <div className="md:mx-8 mx-5 mt-7 md:w-full">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Category Name
                    </h1>
                    <Input
                      type={"text"}
                      handleChange={handleChange}
                      disable={false}
                      name={"name"}
                      placeholder={"Place holder"}
                    />
                    {touched.name && errors.name ? (
                      <h1 className="text-primary -mt-1 text-sm">
                        {errors.name}
                      </h1>
                    ) : null}
                  </div>
                  <div className="md:mt-4 mt-3">
                    <div className="flex justify-between">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Slug
                      </h1>
                      <div className="flex justify-between items-center">
                        <div
                          className={
                            !editSlug
                              ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                              : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                          }
                        >
                          <div
                            className={
                              !editSlug
                                ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                                : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3"
                            }
                            onClick={handleEditSlug}
                          ></div>
                        </div>
                        <h1 className="text-base ml-3 font-rom">edit</h1>
                      </div>
                    </div>
                    {editSlug === false ? (
                      <Input
                        type={"text"}
                        handleChange={handleChange}
                        disable={true}
                        name={"slug"}
                        placeholder={"Place holder"}
                      />
                    ) : (
                      <Input
                        type={"text"}
                        handleChange={handleChange}
                        disable={false}
                        name={"slug"}
                        placeholder={"Place holder"}
                      />
                    )}
                  </div>
                  <div className="flex md:mt-4 mt-3">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Post in Legit Check ?
                    </h1>
                    <div className="flex justify-between items-center ml-5">
                      <div
                        className={
                          postInLegit
                            ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                            : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                        }
                      >
                        <div
                          className={
                            postInLegit
                              ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3 translate-x-3"
                              : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                          }
                          onClick={handlePostinLegit}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
              <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
                <button type="reset" className="focus:outline-none">
                  <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                    Clear
                  </h1>
                </button>
              </div>
              <div className="w-1/2 flex items-center justify-center">
                <button type="submit" className="focus:outline-none">
                  <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                    Save
                  </h1>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProductCategoryGroup;
