import { TFunction } from "react-i18next";

export const optRoutesCreditEffect = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/credit-effect",
    name: "Credit Effect",
  },
];

export const optRoutesAddCreditEffect = (t: TFunction) => {
  return [
    {
      id: 1,
      path: "/",
      name: t("creditEffect.breadcrumb.home"),
    },
    {
      id: 2,
      path: "/marketplace/credit-effect",
      name: t("creditEffect.breadcrumb.creditEffect"),
    },
    {
      id: 3,
      path: "/marketplace/credit-effect/add-credit-effect",
      name: t("creditEffect.breadcrumb.addCreditEffect"),
    },
  ];
};

export const optRoutesEditCreditEffect = (id: string, t: TFunction) => {
  return [
    {
      id: 1,
      path: "/",
      name: t("creditEffect.breadcrumb.home"),
    },
    {
      id: 2,
      path: "/marketplace/credit-effect",
      name: t("creditEffect.breadcrumb.creditEffect"),
    },
    {
      id: 3,
      path: `/marketplace/credit-effect/edit-credit-effect/${id}`,
      name: t("creditEffect.breadcrumb.editCreditEffect"),
    },
  ];
};

export const queryparamsCreditEffect: any[] = [
  { real_name: "search", show_name: "credit_effect_name" },
  { real_name: "scope", show_name: "effect_for" },
  { real_name: "get_credit", show_name: "get_credit" },
  {
    real_name: "effect_from",
    show_name: "effect_from",
  },
  { real_name: "effect_to", show_name: "effect_to" },
];

export const optSortingCreditEffect = [
  {
    value: "created_at",
    name: "Date",
  },
];
