import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ProductRequest } from "../../../../../apis/product/ProductRequest";

const GetProductDetail = () => {
  const params: { id: string } = useParams();
  const { data, isLoading } = useQuery(
    ["GetProductDetail"],
    () => ProductRequest.getProductDetail(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetProductDetail;
