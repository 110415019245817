import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { CreditEffectRequest } from "../../../../apis/credit_effect/CreditEffectRequest";
import CreditEffectContext from "../../../../store/credit_effect/CreditEffectContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetCreditEffect = () => {
  const query = useQueries(useLocation);
  const { CreditEffectDispatch } = useContext(CreditEffectContext);
  const { data, isLoading, isFetching } = useQuery(
    [
      "getCreditEffect",
      query.get("search"),
      query.get("scope"),
      query.get("get_credit"),
      query.get("effect_from"),
      query.get("effect_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => CreditEffectRequest.getCreditEffect(CreditEffectDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetCreditEffect;
