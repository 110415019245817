import { ILegitCheck, ILegitCheckDetail } from "../entities";
import ILegitCheckRepository from "../repository";
import ILegitCheckUsecase from "../usecase";

export default class LegitCheckUsecase implements ILegitCheckUsecase {
  private legitCheckRepo: ILegitCheckRepository;

  constructor(legitCheckRepo: ILegitCheckRepository) {
    this.legitCheckRepo = legitCheckRepo;
  }

  async createLegitCheck(payload: FormData): Promise<{ message: string }> {
    const data = await this.legitCheckRepo.createLegitCheck(payload);
    return data;
  }

  async updateLegitCheck(
    id: number,
    payload: FormData
  ): Promise<{ message: string }> {
    const data = await this.legitCheckRepo.updateLegitCheck(id, payload);
    return data;
  }

  async getLegitCheck(id: number): Promise<ILegitCheck> {
    const data = await this.legitCheckRepo.getLegitCheck(id);
    return data;
  }

  async createLegitCheckDetail(
    legitCheckDetail: Partial<ILegitCheckDetail>
  ): Promise<{ message: string }> {
    const data = await this.legitCheckRepo.createLegitCheckDetail(
      legitCheckDetail
    );
    return data;
  }

  async getLegitCheckDetail(id: number): Promise<ILegitCheckDetail> {
    const data = await this.legitCheckRepo.getLegitCheckDetail(id);
    return data;
  }

  async updateLegitCheckDetail(
    legitCheckDetail: Partial<ILegitCheckDetail>,
    legitCheckDetailId: number
  ): Promise<{ message: string }> {
    const data = await this.legitCheckRepo.updateLegitCheckDetail(
      legitCheckDetail,
      legitCheckDetailId
    );
    return data;
  }

  async publishFinalResult(
    legitCheck: Partial<ILegitCheck>,
    legitCheckId: number
  ): Promise<{ message: string }> {
    const data = await this.legitCheckRepo.publishFinalResult(
      legitCheck,
      legitCheckId
    );
    return data;
  }
}
