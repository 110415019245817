import React from "react";
import { useQuery } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import ProductCategoryGroupCards from "./product_category_group_component/ProductCategoryGroupCard";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import { optRoutes } from "./helpers/list";
import { ProductCategoryGroupRequest } from "../../apis/product_category_group/ProductCategoryGroupRequest";
import Loading from "../../components/loading/Loading";

function ProductCategoryGroup() {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { data, isLoading, refetch } = useQuery(
    "getProductCtgry",
    ProductCategoryGroupRequest.getProductCtgryGroup,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="pt-28 font-med">
      <DynamicBreadcrumb data={optRoutes} />

      <div className="flex justify-end mt-10 xl:mb-3 md:mb-1">
        <h1
          onClick={() => history.push(`${path}/add-product-category-group`)}
          className="text-primary cursor-pointer"
        >
          Add Product Category Group
        </h1>
      </div>

      {isLoading ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <div className="w-full">
          <ProductCategoryGroupCards refetch={refetch} dataList={data} />
        </div>
      )}
    </div>
  );
}

export default ProductCategoryGroup;
