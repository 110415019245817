const location = document.location.search;
const queryParams = new URLSearchParams(location);

export let default_sort = queryParams.get("sort");
if (default_sort === "created_at") {
  default_sort = "Date";
} else if (default_sort === "code") {
  default_sort = "Top up Code";
} else if (default_sort === "username") {
  default_sort = "Username";
} else if (default_sort === "amount") {
  default_sort = "Amount";
} else if (default_sort === "payment_channel") {
  default_sort = "Payment Channel";
} else if (default_sort === "payment_status") {
  default_sort = "Payment Status";
} else if (default_sort === "payment_type") {
  default_sort = "Payment Method";
} else {
  default_sort = "Date";
}
