export enum ActionType {
  ShowFilter,
  ShowCrawl,
  HandleChip,
  AddImage,
  ShowAddInfo,
  AddInfo,
  AddInfoValue,
  DeleteInfo,
  AddDisplayImg,
  ShowConfirmProduct,
  ShowDelete,
  HandlePictureInit,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}
export interface ShowDelete {
  type: ActionType.ShowDelete;
  payload: any;
}

export interface ShowConfirmProduct {
  type: ActionType.ShowConfirmProduct;
}

export interface ShowAddInfo {
  type: ActionType.ShowAddInfo;
}

export interface DeleteInfo {
  type: ActionType.DeleteInfo;
  payload: string;
}

export interface AddInfo {
  type: ActionType.AddInfo;
  payload: string;
}

export interface AddInfoValue {
  type: ActionType.AddInfoValue;
  payload: string;
  name: string;
}

export interface ShowCrawl {
  type: ActionType.ShowCrawl;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface AddImage {
  type: ActionType.AddImage;
  payload: any[];
}

export interface AddDisplayImg {
  type: ActionType.AddDisplayImg;
  payload: string | null;
}

export interface HandlePictureInit {
  type: ActionType.HandlePictureInit;
  payload: any;
}

export type ProductReviewAction =
  | ShowFilter
  | HandleChip
  | ShowCrawl
  | AddImage
  | ShowAddInfo
  | AddInfo
  | DeleteInfo
  | AddDisplayImg
  | AddInfoValue
  | ShowConfirmProduct
  | ShowDelete
  | HandlePictureInit;
