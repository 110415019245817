import { ActionType, TransactionAction } from "./action";
import { ITransactionState } from "./state";

export const TransactionReducer = (
  state: ITransactionState,
  action: TransactionAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowConfirm:
      let confirm = {
        status: action.payload.status,
        location: action.payload.location,
        id: action.payload.id,
      };
      document.body.classList[confirm.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showConfirmLocation: confirm };
    case ActionType.ShowChangeTracking:
      let showChangeTracking = state.showChangeTracking;
      document.body.classList[!showChangeTracking ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showChangeTracking: !showChangeTracking };
    case ActionType.SetPriorityTracking:
      let priority_tracking = action.payload;
      return { ...state, changeTrackingId: priority_tracking };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.ShowTrackProduct:
      let openTrackDialog = {
        status: action.payload.status,
        code: action.payload.code,
      };
      return { ...state, openTrackDialog };
  }
};
