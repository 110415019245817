import React, { useContext } from "react";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import CircularLoad from "../../../components/loading/CircularLoad";
import { ActionType } from "../../../store/product/product_need_review/actions";
import ProductReviewContext from "../../../store/product/product_need_review/ProductReviewContext";
import {
  optRoutesProductReview,
  optSortingproductReview,
  queryparamsProductReview,
} from "./helpers/list";
import NewSorting from "../../../components/sorting/NewSorting";
import ProductReviewFilter from "./components/product_need_review/ProductReviewFilter";
import CrawlModal from "./components/product_need_review/CrawlModal";
import ProductNeedReviewTable from "./components/product_need_review/ProductNeedReviewTable";
import GetProductScraped from "./helpers/useQueryHooks/GetProductScraped";
import Loading from "../../../components/loading/Loading";
import { ProductScrapingRequest } from "../../../apis/product/ProductScrapingRequest";
import { useMutation } from "react-query";
import DeleteProductModal from "./components/product_need_review/DeleteProductModal";
import NewChip from "../../../components/chip/NewChip";

const ProductNeedReview = () => {
  const { productReviewState, productReviewDispatch } =
    useContext(ProductReviewContext);
  const { showFilter, showCrawl, showDelete } = productReviewState;
  const { data, isFetching, isLoading } = GetProductScraped(productReviewDispatch);

  const mutation = useMutation(
    () => ProductScrapingRequest.getStatusScraping(),
    {
      onMutate: () => {
        console.log("mutate get status scraping");
      },
      onSettled: async (data: any, error: any) => {
        if (data.status === "STANDBY") {
          productReviewDispatch({
            type: ActionType.ShowCrawl,
          });
        } else {
          const win: any = window.open(
            `/marketplace/product-need-review/crawl-result?scrap_batch_id=${data.scrap_batch_id}`,
            "_blank"
          );
          win.focus();
        }
      },
    }
  );
  const handleCheckStatusCrawl = () => {
    mutation.mutate()
  };
  return (
    <div>
      {showFilter && <ProductReviewFilter />}
      {showCrawl && <CrawlModal />}
      {showDelete.status && <DeleteProductModal />}
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesProductReview} />
        <div className="md:mt-12 mt-10">
          <div className="flex justify-end">
            <h1
              onClick={handleCheckStatusCrawl}
              className="text-primary text-sm cursor-pointer inline-flex"
            >
              Crawl Product
            </h1>
          </div>

          <div className="flex justify-between mt-2">
            <div className="flex">
              <h1
                onClick={() =>
                  productReviewDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-sm mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingproductReview} />
          </div>

          <NewChip
            dispatchContext={productReviewDispatch}
            stateContext={productReviewState}
            queryParams={queryparamsProductReview}
          />
        </div>
        <div className="flex justify-end my-4 text-sm">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <ProductNeedReviewTable data={data} />
          </div>
        )}
      </section>
    </div>
  );
};

export default ProductNeedReview;
