import React, { FC } from "react";

interface IMproductListCardProps {
  image: string;
  name: string;
  sku: string;
  border?: boolean;
}

const MproductListCard: FC<IMproductListCardProps> = ({
  image,
  name,
  sku,
  border = false,
}) => {
  return (
    <div
      className={
        "w-full flex justify-between " +
        (border && "border border-stroke rounded-md p-4")
      }
    >
      <div className="flex sm:gap-3 gap-1 text-textPrimary">
        <div className="sm:w-12 w-8 sm:h-12 h-8 flex justify-center">
          <img src={image} alt="-" className="rounded-md shadow-md w-full" />
        </div>
        <div className="flex-1">
          <h1 className="font-med leading-tight sm:text-xs text-xxxs break-words">{name}</h1>
          <p className="font-rom sm:text-xs text-xxxs sm:mt-1.5 mt-0.5 break-words">SKU : {sku}</p>
        </div>
      </div>
    </div>
  );
};

export default MproductListCard;
