import _ from "lodash";
import qs from "qs";
import { AxiosInstance } from "../../../config/AxiosInstance";
import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  EBnibBuyOrderSortField,
  IBnibBuyOrder,
  IBnibBuyOrderCounter,
  PBnibBuyOrderFilter,
} from "../entities";
import IBnibBuyOrderRepository from "../repository";

export default class BnibBuyOrderRepository implements IBnibBuyOrderRepository {
  // api routes
  private BNIB_BUY_ORDER_ROUTE = "/admin/bnib-buy-order";
  private BNIB_BUY_ORDER_COUNTER_ROUTE = "/admin/bnib-buy-order/counter";

  buildFilter(filter: PBnibBuyOrderFilter) {
    const created_at = filter.date;
    const status = filter.status?.split(",");
    const clonedFilter = { ...filter, status };
    delete clonedFilter.date;
    return {
      ...clonedFilter,
      created_at,
    };
  }

  buildSort(sort: IBuiltinSort<EBnibBuyOrderSortField>[]) {
    let strSort = "";
    sort.map((el) => (strSort += el.field));
    return strSort;
  }

  async getBnibBuyOrders(
    pagination: IPagination,
    filter: PBnibBuyOrderFilter,
    sorts: IBuiltinSort<EBnibBuyOrderSortField>[]
  ): Promise<[IBnibBuyOrder[], IMeta]> {
    try {
      const params = _.pickBy(
        {
          ...pagination,
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const sort = this.buildSort(sorts);

      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_BUY_ORDER_ROUTE}?sort=${sort}`,
        {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      const { data, meta } = response.data;
      return [data, meta];
    } catch (error) {
      throw error;
    }
  }

  async getBnibBuyOrder(code: string): Promise<IBnibBuyOrder> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_BUY_ORDER_ROUTE}/${code}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getBnibBuyOrderById(id: number): Promise<IBnibBuyOrder> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_BUY_ORDER_ROUTE}/${id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getBnibBuyOrderCounter(
    filter: PBnibBuyOrderFilter
  ): Promise<IBnibBuyOrderCounter[]> {
    try {
      const params = _.pickBy(
        {
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const response = await AxiosInstance.Celestine().get(
        `${this.BNIB_BUY_ORDER_COUNTER_ROUTE}`,
        {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }
}
