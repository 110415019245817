import {
    createTheme,
    experimental_sx as sx
} from "@mui/material";

export const theme = createTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: 'pink',
                },
                '&$active': {
                    color: 'red',
                },
            }
        },
        palette: {
            success: {
                main: "#00C62E",
            },
            primary: {
                main: "#2191FB",
            },
            secondary: {
                main: "#E1F5FE",
            },
            neutral: {
                main: "#9E9D9D",
                contrastText: "#FFF"
            }
        },
    },
});