import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { TrackingReducer } from "../../../store/tracking/TrackingReducer";
import {
  initialTrackingState,
  TrackingContextProvider,
} from "../../../store/tracking/TrackingContext";
import Tracking from "../../../pages/tracking/tracking/Tracking";
import EditTracking from "../../../pages/tracking/tracking/edit_tracking/EditTracking";

const TrackingRoute = () => {
  const [trackingState, trackingDispatch] = useReducer(
    TrackingReducer,
    initialTrackingState
  );

  return (
    <TrackingContextProvider value={{ trackingState, trackingDispatch }}>
      <div className="font-med">
        <Switch>
          <Route path="/marketplace/tracking" exact component={Tracking} />
          <Route path="/marketplace/tracking/edit-tracking/:id" component={EditTracking} />
          <Redirect from="/marketplace" to="/marketplace/tracking" exact />
        </Switch>
      </div>
    </TrackingContextProvider>
  );
};

export default TrackingRoute;
