import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import TopupRequest from "../../../apis/top_up/Topup";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Input from "../../../components/input/Input";
import PriceInput from "../../../components/input/PriceInput";
import TextArea from "../../../components/input/TextArea";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import { formatRupiah } from "../../../utils/format_rupiah/FormatRupiah";
import { optRoutesWaitingInvoiceDetail } from "../helpers/list";
import GetWaitingInvoiceId from "../helpers/use_hooks/GetWaitingInvoiceId";
import { renderSellerType } from "./waiting_invoice_components/WaitingInvoiceTable";

const WaitingInvoiceDetail = () => {
  const params: { id: string; userId: string } = useParams();
  const history = useHistory();
  const { data, isLoading } = GetWaitingInvoiceId(+params.id);

  const { data: dataUser, isLoading: loadUser } = useQuery("getAdminUser", () =>
    TopupRequest.getAdminUser(params.userId)
  );

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1={"Invoice"}
          title2={"Details"}
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb
          data={optRoutesWaitingInvoiceDetail(params.id, params.userId)}
        />
        {isLoading || loadUser ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
            {/* Product Images */}
            <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                <h1 className="font-medium md:text-xl text-base">
                  Product Images
                </h1>
              </div>
              <hr />

              <div className="md:mx-8 mx-5 md:my-6 my-5">
                <div className="flex flex-col justify-center">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Display Image
                  </h1>
                  <div className="mt-2 mx-auto">
                    {renderSellerType(data.type) ===
                    "Official Store (Product)" ? (
                      <img
                        src={data.retail_product.display_image_path}
                        alt="-"
                      />
                    ) : renderSellerType(data.type) === "Marketplace" ? (
                      <img
                        src={data.product_detail.display_image_url}
                        alt="-"
                      />
                    ) : (
                      <img src={data.detail.image_path} alt="-" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
              <div className="w-full h-full">
                <div className="rounded-md border-2 border-stroke">
                  {/*transactions information title and toggle*/}
                  <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                    <h1 className="font-medium md:text-xl text-base">
                      Transaction Information
                    </h1>
                  </div>
                  <hr />
                  <div>
                    {/*transactions Code*/}
                    <div className="md:mx-8 mx-5 md:mt-6 mt-3 md:pt-0 pt-2">
                      <div>
                        <h1 className="md:text-base text-sm text-textPrimary">
                          transaction Code
                        </h1>
                        <Input
                          disable={true}
                          type={"text"}
                          name={"code"}
                          value={data?.code}
                          placeholder={"Place holder"}
                        />
                      </div>
                    </div>
                    <div className="md:mx-8 mx-5 md:mt-4 mt-2 md:pt-0 pt-2">
                      <div>
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Product Name
                        </h1>
                        <Input
                          disable={true}
                          type={"text"}
                          name={"product"}
                          value={
                            renderSellerType(data.type) ===
                            "Official Store (Product)"
                              ? data.retail_product.name
                              : renderSellerType(data.type) === "Marketplace"
                              ? data.product_detail.name
                              : data.detail.title
                          }
                          placeholder={"Place holder"}
                        />
                      </div>
                    </div>

                    <div className="md:flex w-full mt-4">
                      {/*transactions data left*/}
                      <div className="md:mx-8 mx-5 md:w-1/2">
                        <div>
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Buyer Username
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"buyer_username"}
                            value={data?.buyer_username}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Transaction Date
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"created_at"}
                            value={moment(data?.created_at).format(
                              "LL hh:mm a"
                            )}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Transaction Status
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"status"}
                            value={data?.status}
                            placeholder={"Place holder"}
                          />
                        </div>
                      </div>

                      {/*transactions data right*/}
                      <div className="md:mx-8 mx-5 md:w-1/2">
                        <div>
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Seller Type
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"type"}
                            value={renderSellerType(data?.type)}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Expired At
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"va_number"}
                            value={moment(data?.expired_at).format(
                              "LL hh:mm a"
                            )}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Amount
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"amount"}
                            value={formatRupiah(data?.amount)}
                            placeholder={"Place holder"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-full mt-10">
                <div className="rounded-md border-2 border-stroke">
                  <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                    <h1 className="font-medium md:text-xl text-base">
                      Buyer Information
                    </h1>

                    <div className="flex"></div>
                  </div>
                  <hr className="border-t-2" />

                  <div className="py-2">
                    <div className="md:flex w-full mt-4">
                      <div className="md:mx-8 mx-5 md:w-1/2">
                        <div>
                          <h1 className="md:text-base text-sm text-textPrimary">
                            User Id
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"id"}
                            value={data?.user_id}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Email
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"email"}
                            value={dataUser?.email}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Birthday
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"method"}
                            value={moment(dataUser?.birthday)
                              .add(1, "days")
                              .format("LL")}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Verified Email
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"verifemail"}
                            value={dataUser?.verified_email || "-"}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Phone
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"date"}
                            value={dataUser?.phone}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Joined at
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"date"}
                            value={moment(dataUser?.created_at).format("lll")}
                            placeholder={"Place holder"}
                          />
                        </div>
                      </div>

                      {/*transactions data right*/}
                      <div className="md:mx-8 mx-5 md:w-1/2">
                        <div>
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Username
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"username"}
                            value={dataUser?.username}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Gender
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"gender"}
                            value={dataUser?.gender}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Referral Code
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"referral_code"}
                            value={dataUser?.referral_code}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Country Code
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"by"}
                            value={`+ ${dataUser?.country_code}`}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <div className="my-4 mb-5">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Verified Phone
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"by"}
                            value={dataUser?.verified_phone || "-"}
                            placeholder={"Place holder"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WaitingInvoiceDetail;
