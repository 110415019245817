import {
  IBnibTransaction,
  IPaymentInvoice,
} from "../bnib_transaction/entities";
import { IProduct } from "../product/entities";
import { IShippingAddress } from "../retail_transaction/entities";

export enum EBnibBuyOrderStatus {
  OnBidding,
  WaitingPaymentFromBuyer,
  WaitingTrackingCode,
  Shipping,
  Expired,
  Dropped,
  Refunded,
  Cancelled,
  Disputed,
  Done,
  ProtectioFee,
  WaitingPayment,
  PaymentCancelled,
}

export interface IShippingRateCourierDetail {
  duration_type: string;
  insurance_price: number;
  is_available_pickup_today: boolean;
  logistic_logo_url: string;
  logistic_name: string;
  max_duration: number;
  min_duration: number;
  name: string;
  price: number;
  rate_code: string;
  rate_name: string;
  rate_type: string;
  total: number;
  volume_weight: number;
  weight: number;
}

export interface IBnibBuyOrder {
  bid_fee: number;
  bid_invoice_id: number;
  bid_refund_wallet_mutation_id: number;
  bid_status: boolean | number;
  bid_wallet_mutation_id: number;
  bought_status: boolean | number;
  buyer_id: number;
  buyer_username: string;
  code: string;
  created_at: string;
  direct_match: boolean | number;
  drop_wallet_mutation_id: number;
  dropped: boolean | number;
  id: number;
  locked_status: boolean | number;
  matched: boolean | number;
  matched_bnib_product_id: number;
  payment_invoice: IPaymentInvoice;
  payment_invoice_id: number;
  payment_wallet_mutation_id: number;
  pre_order: boolean | number;
  price: number;
  product_detail: IProduct;
  product_id: number;
  product_size: string;
  product_size_id: number;
  promo_code_id: number;
  refund_wallet_mutation_id: number;
  refunded: boolean | number;
  refunded_reason: string;
  reviewed: boolean | number;
  shipping_address: IShippingAddress;
  shipping_address_id: number;
  shipping_cost: number;
  shipping_cost_after_promo: number;
  status: number;
  status_message: string;
  bnib_transaction: IBnibTransaction;
  transaction: IBnibTransaction;
  seller_admin_fee: number;
  seller_username: string;
  seller_commitment_fee: number;
  buyer_phone: string;
  seller_phone: string;
  shipping_rate_courier_detail: IShippingRateCourierDetail;
}
export type PBnibBuyOrder = Partial<IBnibBuyOrder>;

export interface IBnibBuyOrderCounter {
  status: number;
  status_message: string;
  count: number;
}
export type PBnibBuyOrderCounter = Partial<IBnibBuyOrderCounter>;

interface IBnibBuyOrderFilter {
  code: string;
  status: string;
  pre_order: string;
  matched: string;
  buyer_username: string;
  date: string;
}
export type PBnibBuyOrderFilter = Partial<IBnibBuyOrderFilter>;

export enum EBnibBuyOrderSortField {
  newest = "created_at desc",
  oldest = "created_at asc",
  mostPrice = "price desc",
  leastPrice = "price asc",
}

export const listFilter = [
  {
    title: "All transaction",
    status: [
      EBnibBuyOrderStatus.Expired,
      EBnibBuyOrderStatus.WaitingPayment,
      EBnibBuyOrderStatus.PaymentCancelled,
    ],
  },
  {
    title: "Waiting Payment",
    status: [EBnibBuyOrderStatus.WaitingPayment],
  },
  {
    title: "Payment Expired",
    status: [EBnibBuyOrderStatus.Expired],
  },
  {
    title: "Payment Canceled",
    status: [EBnibBuyOrderStatus.PaymentCancelled],
  },
];

export const listSort = [
  {
    label: "Newest Transaction",
    value: `${EBnibBuyOrderSortField.newest}`,
  },
  {
    label: "Oldest Transaction",
    value: `${EBnibBuyOrderSortField.oldest}`,
  },
  {
    label: "Most Price",
    value: `${EBnibBuyOrderSortField.mostPrice}`,
  },
  {
    label: "Least Price",
    value: `${EBnibBuyOrderSortField.leastPrice}`,
  },
];
