import { IRetailProduct } from "../retail_product/entities";

export enum ERetailTransactionStatus {
  WaitingTrackingCode,
  ShippingToBuyer,
  ArrivedAtBuyer,
  BuyerComplain,
  SellerOutOfStock,
  SellerExpired,
  Disputed,
  Done,
}

export enum EAccessLogStatus {
  WaitingSellerInputTrack = "waiting-seller-input-track",
  SellerInputTrack = "seller-input-track",
  SellerExpired = "seller-expired",
  SellerOutOfStock = "seller-out-of-stock",
  ArrivedAtBuyer = "arrived-at-buyer",
  BuyerComplain = "buyer-complain",
  Disputed = "disputed",
  SellerCancel = "seller-cancel",
  BuyerConfirmation = "buyer-confirmation",
}

export interface IAccessLogItem {
  admin_username: string;
  user_id: number;
  time: string;
}
export interface IShippingAddress {
  additional_info: string;
  address: string;
  address_city_id: number;
  address_city_name: string;
  address_province_id: number;
  address_province_name: string;
  address_subdistrict_id: number;
  address_subdistrict_name: string;
  country: string;
  name: string;
  phone: string;
  recipient: string;
  zip_code: string;
}

export interface IRetailTransaction {
  access_log: string;
  accessLog: Record<EAccessLogStatus, IAccessLogItem>;
  admin_fee_percentage: number;
  buyer: {
    username: string;
  };
  buyer_confirmation_expired_at: string;
  buyer_id: number;
  buyer_shipping_address: IShippingAddress;
  buyer_shipping_address_id: number;
  buyer_shipping_cost: number;
  buyer_username: string;
  code: string;
  created_at: string;
  id: number;
  is_complained: boolean;
  is_pre_order: boolean;
  is_shipping_fee_charged: boolean;
  price: number;
  quantity: number;
  retail_address_id: number;
  retail_product_detail: IRetailProduct;
  retail_product_id: number;
  retail_product_stock_id: number;
  retail_shop_id: number;
  retail_transaction_complain: {
    action: number;
    action_message: string;
    buyer_contact: string;
    created_at: string;
    id: number;
    image_path: string;
    admin_note: string;
    message: string;
    retail_transaction_code: string;
    retail_transaction_id: number;
    status: number;
    status_message: string;
  };
  shipping_input_expired_at: string;
  shipping_provider: string;
  shipping_tracking_code: string;
  status: ERetailTransactionStatus;
  status_message: string;
  total: number;
  updated_at: string;
  weight: number;
}
export type PRetailTransaction = Partial<IRetailTransaction>;

export interface IRetailTransactionCounter {
  status: number;
  status_message: string;
  count: number;
}
export type PRetailTransactionCounter = Partial<IRetailTransactionCounter>;

export interface IRetailTransactionTrack {
  tracking_code: string;
  tag: string;
  history: [
    {
      date_time: string;
      status: string;
      receiver: string;
      data: string[];
      data_type: string;
    },
  ];
}
export type PRetailTransactionTrack = Partial<IRetailTransactionTrack>;

interface IRetailTransactionFilter {
  status: string;
  code: string;
  retail_shop_id: string;
  date: string;
}
export type PRetailTransactionFilter = Partial<IRetailTransactionFilter>;

export enum ERetailTransactionSortField {
  newest = "created_at desc",
  oldest = "created_at asc",
  mostQuantity = "quantity desc",
  leastQuantity = "quantity asc",
  mostAmount = "total desc",
  leastAmount = "total asc",
}

export const listFilter = [
  {
    title: "All transaction",
    status: [
      ERetailTransactionStatus.WaitingTrackingCode,
      ERetailTransactionStatus.ShippingToBuyer,
      ERetailTransactionStatus.ArrivedAtBuyer,
      ERetailTransactionStatus.BuyerComplain,
      ERetailTransactionStatus.SellerOutOfStock,
      ERetailTransactionStatus.SellerExpired,
      ERetailTransactionStatus.Disputed,
      ERetailTransactionStatus.Done,
    ],
  },
  {
    title: "Ready For Shipping",
    status: [ERetailTransactionStatus.WaitingTrackingCode],
  },
  {
    title: "On Shipping",
    status: [
      ERetailTransactionStatus.ShippingToBuyer,
      ERetailTransactionStatus.ArrivedAtBuyer,
    ],
  },
  { title: "Complain", status: [ERetailTransactionStatus.BuyerComplain] },
  { title: "Done", status: [ERetailTransactionStatus.Done] },
  {
    title: "Cancelled",
    status: [
      ERetailTransactionStatus.SellerExpired,
      ERetailTransactionStatus.SellerOutOfStock,
      ERetailTransactionStatus.Disputed,
    ],
  },
];

export const listSort = [
  {
    label: "Newest Transaction",
    value: `${ERetailTransactionSortField.newest}`,
  },
  {
    label: "Oldest Transaction",
    value: `${ERetailTransactionSortField.oldest}`,
  },
  {
    label: "Most Quantity",
    value: `${ERetailTransactionSortField.mostQuantity}`,
  },
  {
    label: "Least Quantity",
    value: `${ERetailTransactionSortField.leastQuantity}`,
  },
  { label: "Most Amount", value: `${ERetailTransactionSortField.mostAmount}` },
  {
    label: "Least Amount",
    value: `${ERetailTransactionSortField.leastAmount}`,
  },
];
