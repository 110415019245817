import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import ScrollToTop from "../../utils/scrollToTop/ScrollToTop";
import ProductCategoryGroupRoute from "../../layout/marketplace/product_category_group/ProductCategoryGroup";
import TopupRoute from "./top_up/TopupRoute";
import TagsRoute from "./tags/TagsRoute";
import BannerRoute from "./banner/BannerRoute";
import TransactionRoute from "./transaction/TransactionRoute";
import TrackingRoute from "./tracking/TrackingRoute";
import OnSellingRoute from "./on_selling/OnSellingRoute";
import OnBiddingRoute from "./on_bidding/OnBiddingRoute";
import OrderRoute from "./order/OrderRoute";
import ProductDepatuRoute from "./product/product_depatu/ProductDepatu";
import ProductNeedReviewRoute from "./product/product_need_review/ProductNeedReview";
import RoleGuard from "../../utils/guards/RoleGuard";
import LocalStorage from "../../config/LocalStorage";
import CouponRoute from "./coupon/CouponRoute";
import SizingRoute from "./sizing/SizingRoute";
import FeaturedProductRoute from "./featured_product/FeaturedProductRoute";
import CampaignRoute from "./campaign/CampaignRoute";
import { AcceptedRole } from "./interfaces";
import CreditEffectRoute from "./credit_effect/CreditEffectRoute";
import SendCreditRoute from "./send_credit/SendCreditRoute";
import InvoiceRoute from "./invoice/InvoiceRoute";

const Marketplace = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  return (
    <div>
      <Navbar />
      <div className="px-6 md:px-12 xl:px-16">
        <ScrollToTop />
        <Switch>
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
            ]}
            role={role}
            path="/marketplace/product-category-group"
            component={ProductCategoryGroupRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Cashier,
              AcceptedRole.finance_analyst,
              AcceptedRole.finance_support,
            ]}
            role={role}
            path="/marketplace/topup"
            component={TopupRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Cashier,
              AcceptedRole.finance_analyst,
              AcceptedRole.finance_support,
            ]}
            role={role}
            path="/marketplace/invoice"
            component={InvoiceRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/tags"
            component={TagsRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/banner"
            component={BannerRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/coupon"
            component={CouponRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/transaction"
            component={TransactionRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/on-selling"
            component={OnSellingRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/on-bidding"
            component={OnBiddingRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/order"
            component={OrderRoute}
          />
          <RoleGuard
            accepted_role={[AcceptedRole.Admin, AcceptedRole.Operation]}
            role={role}
            path="/marketplace/tracking"
            component={TrackingRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Crawler,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/product-depatu"
            component={ProductDepatuRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Crawler,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/product-need-review"
            component={ProductNeedReviewRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Spinner,
              AcceptedRole.User,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/campaign"
            component={CampaignRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/featured-product"
            component={FeaturedProductRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
            ]}
            role={role}
            path="/marketplace/sizing"
            component={SizingRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/credit-effect"
            component={CreditEffectRoute}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Giver,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/send-credit"
            component={SendCreditRoute}
          />

          {role === AcceptedRole.Admin ? (
            <Redirect
              from="/marketplace"
              exact
              to="/marketplace/product-category-group"
            />
          ) : role === AcceptedRole.Crawler ? (
            <Redirect
              from="/marketplace"
              exact
              to="/marketplace/product-depatu"
            />
          ) : role === AcceptedRole.User ? (
            <Redirect from="/marketplace" exact to="/marketplace/campaign" />
          ) : role === AcceptedRole.Spinner ? (
            <Redirect from="/marketplace" exact to="/marketplace/campaign" />
          ) : role === AcceptedRole.Cashier ? (
            <Redirect from="/marketplace" exact to="/marketplace/topup" />
          ) : role === AcceptedRole.finance_analyst ? (
            <Redirect from="/marketplace" exact to="/marketplace/topup" />
          ) : role === AcceptedRole.finance_support ? (
            <Redirect from="/marketplace" exact to="/marketplace/topup" />
          ) : role === AcceptedRole.Giver ? (
            <Redirect from="/marketplace" exact to="/marketplace/send-credit" />
          ) : role === AcceptedRole.Operation ? (
            <Redirect
              from="/marketplace"
              exact
              to="/marketplace/product-category-group"
            />
          ) : role === AcceptedRole.Product ? (
            <Redirect
              from="/marketplace"
              exact
              to="/marketplace/product-category-group"
            />
          ) : role === AcceptedRole.KeyAccount ? (
            <Redirect from="/marketplace" exact to="/marketplace/tags" />
          ) : role === AcceptedRole.Marketing ? (
            <Redirect
              from="/marketplace"
              exact
              to="/marketplace/product-need-review"
            />
          ) : (
            <Redirect from="/" exact to="/login" />
          )}
        </Switch>
      </div>
    </div>
  );
};

export default Marketplace;
