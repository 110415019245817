import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";

const GetProductId = (campaign_product_template_id:string) => {
  const params: { productId: string } = useParams();
  const { data, isLoading } = useQuery(
    ["GetProductId"],
    () => CampaignRequest.getProductById(params.productId, campaign_product_template_id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetProductId;
