import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import TrackingContext from "../../../../store/tracking/TrackingContext";

interface IProps {
  data: any;
}
const TrackingCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { trackingDispatch } = useContext(TrackingContext);

  return (
    <div className="h-40 rounded-xl border-2 border-stroke hover:border-blue-300 hover:shadow-lg">
      <div className="flex justify-between items-start h-3/5">
        <div className="flex items-center h-full px-8 w-full">
          <div className="flex flex-col justify-around">
            <h1 className="text-xl">{data.name}</h1>
            {/* <h1 className="text-base mt-2 font-rom">
              Total Step : {data.total_product}
            </h1> */}
            {/* <div className="flex mt-1">
              <h1 className="mr-1 text-base">Status :</h1>
              {data.is_active ? (
                <h1 className="text-success text-base">Active</h1>
              ) : (
                <h1 className="text-primary text-base">Not Active</h1>
              )}
            </div> */}
          </div>
        </div>
      </div>

      <div className="flex justify-around xl:text-base md:text-sm w-full h-2/5">
        <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1
            onClick={() =>
              history.push(`/marketplace/tracking/edit-tracking/${data.id}`)
            }
            className="hover:text-primary cursor-pointer"
          >
            Edit
          </h1>
        </div>
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1
            onClick={() =>
              trackingDispatch({
                type: "show_delete_location",
                payload: {
                  id: data.id,
                  status: true,
                },
              })
            }
            className="hover:text-primary cursor-pointer"
          >
            Delete
          </h1>
        </div>
      </div>
    </div>
  );
};

export default TrackingCard;
