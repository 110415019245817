export const optRoutes = [
  {
    id: 1,
    path: "/marketplace",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/product-category-group",
    name: "Product Category Group",
  },
];

export const optRoutesDetailCategories = (id: string, detail: string) => {
  return [
    {
      id: 1,
      path: "/marketplace",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/product-category-group",
      name: "Product Category Group",
    },
    {
      id: 3,
      path: `/marketplace/product-category-group/categories/${id}`,
      name: "Categories",
    },
    {
      id: 4,
      path: `/marketplace/product-category-group/categories/${id}/detail-categories/${detail}`,
      name: "Detail Categories",
    },
    {
      id: 5,
      path: `/marketplace/product-category-group/categories/${id}/detail-categories/${detail}/add-detail-categories`,
      name: "Add Detail Categories",
    },
  ];
};

export const typeCategories = [
  { value: "product_brand", label: "Brand to Sub-Brand" },
  { value: "product_category", label: "Specific Brand Category" },
  { value: "special_category", label: "All Brand Sub-Category" },
  {
    value: "product_category_to_brand",
    label: "Specific Category Brand",
  },
  {
    value: "retail_shop",
    label: "Category to Official Store (Brand)",
  },
];
