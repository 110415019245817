export const applyChip = (filter: any, dispatch: any) => {
  const new_data = [];
  for (const key in filter) {
    if (filter[key].length > 0 || filter[key] > 0) {
      new_data.push(key);
    }
  }
  dispatch({
    type: "handle_chip",
    payload: new_data,
  });
};

const ApplyChip = (queryParams: any, dispatch: any) => {
  const new_data: any[] = [];
  const location = document.location.search;
  const query = new URLSearchParams(location);
  for (const key in queryParams) {
    let value: any = query.get(queryParams[key].real_name);
    if (value !== null) {
      new_data.push(queryParams[key].show_name);
    }
  }
  return dispatch({
    type: "handle_chip",
    payload: new_data,
  });
};

export default ApplyChip;