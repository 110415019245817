import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";
import { ceklis, saveSize, deleteIcon } from "../../../../assets/icons";
import CircularLoad from "../../../../components/loading/CircularLoad";
import Loading from "../../../../components/loading/Loading";
import GetSizingVariant from "../../helpers/use_query_hooks/GetSizingVariant";

interface IProps {
  parentForm: any;
}

const InfantForm: React.FC<IProps> = ({ parentForm }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [id, setId] = useState<number>(0);
  const [formatSizeInput, setFormatSizeInput] = useState<any>({});
  const [formatSizeOrderInput, setFormatSizeOrderInput] = useState({});
  const [showFormatSize, setShowFormatSize] = useState(false);
  const [showDeleteFormatSize, setShowDeleteFormatSize] = useState(false);
  const [sizeColumn, setSizeColumn] = useState<any>([]);
  const [sizeColumnLength, setSizeColumnLength] = useState<number>(0);

  const [formatSizeOrderArr, setFormatSizeOrderArr] = useState<any>([]);
  const [formatSizeOrderValue, setFormatSizeOrderValue] = useState<any>([]);

  const { values, setValues, handleSubmit, isSubmitting, setSubmitting } =
    useFormik<any>({
      initialValues: {
        id: 0,
        gender: 3,
        display_variant_id: "0",
        deletedSizeId: [],
      },
      onSubmit: async () => {
        for (let i = 0; i < sizeColumn.length; i++) {
          delete sizeColumn[i].variants.product_size_id;
        }
        let payload = [...sizeColumn];
        let payloadEdit = [...sizeColumn];
        let payloadOrder = [...sizeColumn];
        if (sizeColumnLength > 0) {
          for (let i = 0; i < sizeColumnLength; i++) {
            payload.splice(payload[i], 1);
          }
          if (payloadEdit.length > sizeColumnLength) {
            payloadEdit.splice(
              sizeColumnLength,
              payloadEdit.length - sizeColumnLength
            );
          }

          let obj: any = {};
          payloadOrder.map((el: any, i: number) => (obj[el.id] = i));

          await SizingRequest.addProductSize(payload, setSubmitting);
          await SizingRequest.EditProductSize(payloadEdit, setSubmitting);
          await SizingRequest.EditProductSizeOrder(obj, values, parentForm);
        } else {
          await SizingRequest.addProductSize(sizeColumn, setSubmitting);
        }
      },
    });

  const { data, isLoading } = GetSizingVariant(parentForm, values.gender);

  const { data: datagroup, isLoading: loadGroup } = useQuery(
    [
      `GetSizingGroupById${id}`,
      parentForm?.product_brand?.value,
      parentForm?.product_category?.value,
      parentForm?.sex,
    ],
    () =>
      SizingRequest.getSizingGroup(
        parentForm?.product_category?.value,
        parentForm?.product_brand?.value
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  useEffect(() => {
    if (datagroup?.available_variants[3]) {
      let dataSize = datagroup?.available_variants[3];
      let arr = [];
      for (let i = 0; i < dataSize?.length; i++) {
        let res = {
          gender: data?.data[0].gender,
          id: Number(dataSize[i].product_size_id),
          product_brand_id: parentForm.product_brand.value,
          product_category_id: parentForm.product_category.value,
          old: false,
          variants: dataSize[i],
        };

        arr.push(res);
      }
      setSizeColumn(arr);
      setSizeColumnLength(dataSize.length);
    }
  }, [datagroup]);

  useEffect(() => {
    if (data?.data.length > 0 && !isLoading) {
      setId(Object.keys(data?.data[0].variant_orders).length);
      setValues({
        ...values,
        id: data?.data[0].id,
        display_variant_id: `${data?.data[0].display_variant_id}`,
      });
      setFormatSizeInput(data?.data[0].variants);
      setFormatSizeOrderInput(data?.data[0].variant_orders);
      const arr_orders = Object.keys(data?.data[0].variant_orders).map(
        (key) => [key, data?.data[0].variant_orders[key]]
      );
      const result = arr_orders.sort((a: any, b: any) => a[1] - b[1]);
      setFormatSizeOrderArr(result);
      if (formatSizeOrderArr.length < 1) {
        setSizeColumn(
          [...sizeColumn].concat(
            Object.assign({
              gender: data?.data[0].gender,
              product_brand_id: parentForm.product_brand.value,
              product_category_id: parentForm.product_category.value,
              old: false,
              variants: {},
            })
          )
        );
      }
    } else {
      setFormatSizeInput({});
      setFormatSizeOrderInput({});
      setFormatSizeOrderArr([]);
      setValues({
        ...values,
        id: 0,
        display_variant_id: "0",
      });
      setId(0);
      setSizeColumn([]);
    }
  }, [data]);

  const handleDrop = (sourceIndex: any, destinationIndex: any) => {
    if (sourceIndex !== destinationIndex) {
      // setLoading(true);
      // mutation.mutate(payload);
      const prevDataIndex = formatSizeOrderArr[sourceIndex];
      let prevData = [...formatSizeOrderArr];
      if (sourceIndex < destinationIndex) {
        prevData.splice(destinationIndex + 1, 0, prevDataIndex);
        const result = prevData.filter((_e, i) => i !== +sourceIndex);
        // setFormatSize(result);
      } else {
        prevData.splice(destinationIndex, 0, prevDataIndex);
        const result = prevData.filter((_e, i) => i !== +sourceIndex + 1);
        // setFormatSize(result);
      }
    }
  };

  function drag(e: any) {
    e.dataTransfer.setData("text", e.target.id);
  }

  function allowDrop(e: any) {
    e.preventDefault();
  }

  function drop(e: any) {
    e.preventDefault();
    const source_index = e.dataTransfer.getData("text");
    const data = [...sizeColumn];
    let temp_data = data[source_index];
    if (e.target.id === source_index || e.target.id.length > 3) {
      return;
    }
    if (source_index < e.target.id) {
      data.splice(+e.target.id + 1, 0, temp_data);
      delete data[+source_index];
    } else {
      data.splice(e.target.id, 0, temp_data);
      delete data[+source_index + 1];
    }

    const filter_data = data.filter((el) => el !== null || el !== undefined);
    setSizeColumn(filter_data);
  }

  const addSizeColumn = () => {
    setSizeColumn(
      [...sizeColumn].concat(
        Object.assign({
          gender: values.gender,
          product_brand_id: parentForm.product_brand.value,
          product_category_id: parentForm.product_category.value,
          old: false,
          variants: {},
        })
      )
    );
  };

  const handleChangeSize = (e: any, el: any, row: number) => {
    let temp = sizeColumn[row];
    const fixTemp = { ...temp?.variants, [el[0]]: e.target.value };
    temp.variants = fixTemp;
    let newSize = [...sizeColumn];
    newSize[row] = temp;
    setSizeColumn(newSize);
    // setNewSizeColumn(temp);
  };

  const handleSubmitFormatSize = () => {
    mutation.mutate();
  };

  const mutation = useMutation(
    () =>
      formatSizeOrderArr.length < 1
        ? SizingRequest.addSizingVariant(
            values,
            parentForm,
            formatSizeInput,
            formatSizeOrderInput
          )
        : SizingRequest.editSizingVariant(
            values,
            parentForm,
            formatSizeInput,
            formatSizeOrderInput
          ),
    {
      onMutate: () => {
        console.log("mutate add sizing variant");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log({ error });
        } else if (data) {
          setId(id + 1);
          setShowFormatSize(false);
          setValues({
            ...values,
            id: data.id,
            display_variant_id: `${data.display_variant_id}`,
          });
          setFormatSizeInput(data?.variants);
          setFormatSizeOrderInput(data?.variant_orders);

          const arr_orders = Object.keys(data.variant_orders).map((key) => [
            key,
            data.variant_orders[key],
          ]);
          const result = arr_orders.sort((a: any, b: any) => a[1] - b[1]);
          setFormatSizeOrderArr(result);
          if (formatSizeOrderArr.length < 1) {
            setSizeColumn(
              [...sizeColumn].concat(
                Object.assign({
                  gender: data.gender,
                  product_brand_id: parentForm.product_brand.value,
                  product_category_id: parentForm.product_category.value,
                  old: false,
                  variants: {},
                })
              )
            );
          }
        }
      },
    }
  );

  useEffect(() => {
    let formatSizeValue = [];
    for (let i = 0; i < formatSizeOrderArr.length; i++) {
      for (const key in formatSizeInput) {
        if (key === formatSizeOrderArr[i][0]) {
          formatSizeValue.push(formatSizeInput[key]);
        }
      }
    }
    setFormatSizeOrderValue(formatSizeValue);
  }, [formatSizeInput, formatSizeOrderArr]);

  const deleteFormatSize = (id: number, index: number) => {
    const newFormatSizeInput = { ...formatSizeInput };
    const newFormatSizeOrderInput: any = { ...formatSizeOrderInput };
    const newFormatOrderArr = [...formatSizeOrderArr];
    delete newFormatSizeInput[id];
    delete newFormatSizeOrderInput[id];
    newFormatOrderArr.splice(index, 1);
    setFormatSizeInput(newFormatSizeInput);
    setFormatSizeOrderInput(newFormatSizeOrderInput);
    setFormatSizeOrderArr(newFormatOrderArr);
  };

  const cancelDeleteFormatSize = (data: any) => {
    setFormatSizeInput(data?.data[0].variants);
    setFormatSizeOrderInput(data?.data[0].variant_orders);
    const arr_orders = Object.keys(data?.data[0].variant_orders).map((key) => [
      key,
      data?.data[0].variant_orders[key],
    ]);
    const result = arr_orders.sort((a: any, b: any) => a[1] - b[1]);
    setFormatSizeOrderArr(result);
    setShowDeleteFormatSize(false);
  };

  const mutateDeleteVariant = useMutation(
    () =>
      SizingRequest.deleteSizingVariantMultiple(
        parentForm,
        values,
        formatSizeInput,
        formatSizeOrderInput
      ),
    {
      onMutate: () => {
        console.log("mutate delete sizing variant");
      },
      onSettled: async (_data: any, _error: any) => {
        queryClient.invalidateQueries(`GetSizingVariant`);
        setShowDeleteFormatSize(false);
      },
    }
  );
  const handleCheckedDeleteRow = (e: any, el: any, i: number) => {
    if (e.target.checked) {
      setValues({
        ...values,
        deletedSizeId: [...values.deletedSizeId].concat(
          el.variants.product_size_id ? el.variants.product_size_id : i
        ),
      });
    } else {
      let temp = values.deletedSizeId
        .filter((prev: any) => prev !== el.variants.product_size_id)
        .filter((prev: any) => prev !== i);
      setValues({ ...values, deletedSizeId: temp });
    }
  };

  const handleDeleteRow = () => {
    let status = false;
    let newSizeColumn = [...sizeColumn];

    for (let i = 0; i < sizeColumn.length; i++) {
      for (let j = 0; j < values.deletedSizeId.length; j++) {
        if (i === values.deletedSizeId[j]) {
          let arr = delete newSizeColumn[i];
          setSizeColumn(arr);
        } else if (
          values.deletedSizeId[j] === sizeColumn[i].variants.product_size_id
        ) {
          status = true;
        }
      }
    }
    let res = newSizeColumn.filter((n) => n);
    setSizeColumn(res);
    if (status) {
      let newDeleteid = [];
      for (let j = 0; j < sizeColumn.length; j++) {
        for (let i = 0; i < values.deletedSizeId.length; i++) {
          if (
            sizeColumn[j].variants.product_size_id === values.deletedSizeId[i]
          ) {
            newDeleteid.push(+values.deletedSizeId[i]);
          }
        }
      }
      mutateDeleteProductSize.mutate(newDeleteid);
    }
  };

  const mutateDeleteProductSize = useMutation(
    (newDeleteid: any[]) => SizingRequest.deleteFormatSizeRow(newDeleteid),
    {
      onMutate: () => {
        console.log("mutate delete sizing variant");
      },
      onSettled: async (_data: any, error: any) => {
        if (!error) {
          setValues({ ...values, deletedSizeId: [] });
          queryClient.invalidateQueries(`GetSizingGroupById${id}`);
        }
      },
    }
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="my-2 mt-12">
        <div className="flex justify-between">
          <h1>Fill Format Size</h1>
          {showDeleteFormatSize ? (
            <div className="flex">
              <h1
                onClick={() => cancelDeleteFormatSize(data)}
                className="hover:text-primary cursor-pointer mr-4"
              >
                Cancel
              </h1>
              <h1
                onClick={() => mutateDeleteVariant.mutate()}
                className="hover:text-primary cursor-pointer"
              >
                Save
              </h1>
            </div>
          ) : (
            <h1
              onClick={() => setShowDeleteFormatSize(!showDeleteFormatSize)}
              className="hover:text-primary cursor-pointer"
            >
              Delete Format Size
            </h1>
          )}
        </div>
        <div className="my-3 flex">
          <div>
            <ListManager
              items={formatSizeOrderArr}
              direction="horizontal"
              maxItems={12}
              render={(el: any) => (
                <div
                  onClick={
                    !showDeleteFormatSize
                      ? () =>
                          setValues({
                            ...values,
                            display_variant_id: el[0],
                          })
                      : () => {}
                  }
                  className={
                    "border rounded-md w-32 h-14 flex justify-center items-center mx-1 " +
                    (values.display_variant_id === el[0]
                      ? "border-primary"
                      : "")
                  }
                >
                  {showDeleteFormatSize && (
                    <div
                      className="bg-textPrimary p-1 mb-9 ml-24 -mr-2.5 absolute rounded-bl-md rounded-tr-md cursor-pointer"
                      onClick={() => deleteFormatSize(el[0], el[1])}
                    >
                      <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        className="w-3 h-3"
                      />
                    </div>
                  )}
                  <h1 className="text-lg">{formatSizeOrderValue[el[1]]}</h1>
                </div>
              )}
              onDragEnd={handleDrop}
            />
          </div>
          <div className="mx-1.5">
            {showFormatSize && (
              <div className="border rounded-md  w-32 h-14 flex justify-center items-center">
                <div
                  className="bg-textPrimary p-1 mb-8 ml-24 -mr-1.5 absolute rounded-bl-md rounded-tr-md cursor-pointer"
                  onClick={handleSubmitFormatSize}
                >
                  <img src={ceklis} alt="ceklis" className="w-4 h-4" />
                </div>
                <input
                  type="text"
                  onChange={(e: any) => {
                    setFormatSizeInput({
                      ...formatSizeInput,
                      [id]: e.target.value,
                    });
                    setFormatSizeOrderInput({
                      ...formatSizeOrderInput,
                      [id]: id,
                    });
                  }}
                  className="h-full w-full text-lg rounded-md flex text-center justify-center items-center p-3 focus:ring-1 focus:outline-none"
                />
              </div>
            )}
            {!showFormatSize && !showDeleteFormatSize && (
              <div
                onClick={() => setShowFormatSize(true)}
                className="border rounded-md w-32 h-14 flex justify-center items-center cursor-pointer hover:opacity-40"
              >
                <h1 className="text-2xl">+</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="my-5">
        <div className="flex justify-between">
          <h1 className="font-rom">
            Note: You can set your size priority by drag the card
          </h1>
          <h1
            onClick={handleDeleteRow}
            className="font-rom cursor-pointer hover:text-primary"
          >
            Delete Row
          </h1>
        </div>
      </div>
      {loadGroup ? (
        <div className="flex justify-center my-20">
          <Loading />
        </div>
      ) : (
        <div className="">
          <div className="w-full">
            {sizeColumn.map((el: any, i: number) => (
              <div
                key={i}
                id={i.toString()}
                onDragStart={drag}
                draggable={true}
                onDragOver={allowDrop}
                onDrop={drop}
                className="flex w-full justify-between cursor-move"
              >
                <div
                  className={`ml-1 grid gap-2 my-4 grid-cols-${formatSizeOrderArr.length}`}
                >
                  {formatSizeOrderArr.map((el: any, index: number) => (
                    <div
                      key={index}
                      className="border rounded-md h-14 w-32 flex justify-center items-center"
                    >
                      <input
                        type="text"
                        name={el[0]}
                        id={el[0]}
                        value={sizeColumn[i].variants[el[0]]}
                        onChange={(e: any) => handleChangeSize(e, el, i)}
                        className="h-full w-full text-lg rounded-md flex text-center justify-center items-center p-3 focus:ring-1 focus:outline-none"
                      />
                    </div>
                  ))}
                </div>
                <div key={i} className=" mt-4 h-14 flex justify-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e: any) => handleCheckedDeleteRow(e, el, i)}
                        name="deletedSizeId"
                        style={{ color: "#ed4846" }}
                      />
                    }
                    label=""
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10">
            <hr />
            <div className="flex justify-center my-5">
              <h1
                onClick={addSizeColumn}
                className="hover:text-primary cursor-pointer"
              >
                Add Size
              </h1>
            </div>
          </div>
        </div>
      )}
      <button
        type="submit"
        disabled={isSubmitting ? true : false}
        className={
          "w-16 h-16 focus:outline-none rounded-full bg-primary flex justify-center items-center shadow-md fixed bottom-0 cursor-pointer hover:bg-opacity-70 right-0 m-10 " +
          (isSubmitting && "bg-opacity-80")
        }
      >
        {isSubmitting ? (
          <div className="flex justify-center items-center">
            <CircularLoad color={"#EFEFEF"} height={20} width={20} />
          </div>
        ) : (
          <img src={saveSize} alt="-" className="w-6" />
        )}
      </button>
    </form>
  );
};

export default InfantForm;
