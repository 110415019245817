import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { cancel } from "../../../assets/icons";
import SmallButton from "../../../components/button/SmallButton";
import Input from "../../../components/input/Input";
import BannerContext from "../../../store/banner/BannerContext";
import SetQueryString from "../../../utils/query_searchParams/SetQueryString";
import { queryparamsBanner } from "../helpers/list";

const BannerFilter = () => {
  const history = useHistory();
  const { pathname, search, hash } = useLocation();
  const { bannerState, bannerDispatch } = useContext(BannerContext);
  const { showFilter } = bannerState;

  const handleShowFilter = () => {
    bannerDispatch({ type: "show_filter" });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    SetQueryString(
      queryparamsBanner,
      value,
      name,
      search,
      pathname,
      history,
      hash
    );
  };

  const applyFilter = () => {
    setTimeout(() => bannerDispatch({ type: "show_filter" }), 1000);
  };

  return (
    showFilter && (
      <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
        <div
          className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all md:max-w-2xl max-w-lg"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="px-10 py-7 flex justify-between w-full">
            <h1
              className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
              id="modal-headline"
            >
              Filter
            </h1>
            <img
              src={cancel}
              alt="back"
              className="cursor-pointer md:w-5 w-3"
              onClick={handleShowFilter}
            />
          </div>
          <hr className="w-full " />
          <div className="flex px-10 py-5">
            {hash === "#general" && (
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Banner Post In
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="banner_post_in"
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected disabled hidden>
                    Select Here
                  </option>
                  <option value="4">Web (All)</option>
                  <option value="5">Web (Legit Check)</option>
                  <option value="6">Web (Market Place)</option>
                  <option value="7">Web (Multi Brand)</option>
                  <option value="0">App (All)</option>
                  <option value="1">App (Legit Check)</option>
                  <option value="2">App (Market Place)</option>
                  <option value="3">App (Multi Brand)</option>
                </select>
              </div>
            )}
            <div className={hash === "#general" ? "w-96" : "w-full"}>
              <h1 className="md:text-base text-sm text-textPrimary">Status</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="true">Posted</option>
                <option value="false">Not Posted</option>
              </select>
            </div>
          </div>
          <hr className="w-full " />
          {/* 
          <div className="flex px-10 py-5">
            <div className="pr-6 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Banner Action
              </h1>
              <select
                onChange={handleChangeFilter}
                name="banner_action"
                className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="0">Web</option>
                <option value="1">App</option>
                <option value="2">No Action</option>
              </select>
            </div>
          </div>

          <hr className="w-full " /> */}

          <div className="flex px-10 py-5">
            <div className="mr-12 w-96">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired At (from)
              </h1>
              <Input
                type="date"
                handleChange={handleChangeFilter}
                name="expired_at_from"
              />
            </div>

            <div className=" w-96">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired At (to)
              </h1>
              <Input
                type="date"
                handleChange={handleChangeFilter}
                name="expired_at_to"
              />
            </div>
          </div>

          <hr className="w-full " />
          <div className="flex justify-center m-5">
            <SmallButton
              handleClick={applyFilter}
              loadColor={"white"}
              type={"button"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Apply"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default BannerFilter;
