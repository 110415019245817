import { AcceptedRole } from "../../../layout/marketplace/interfaces";

export const optRoutesSendCredit = [
  {
    id: 1,
    path: "/marketplace",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/send-credit",
    name: "Send Credit",
  },
];

export const optRoutesSendCreditHistory = (id: string, role: string) => {
  return [
    {
      id: 1,
      path:
        role === AcceptedRole.Admin
          ? `/marketplace`
          : `/marketplace/send-credit/history/${id}`,
      name: "Home",
    },
    {
      id: 2,
      path:
        role === AcceptedRole.Admin
          ? `/marketplace/send-credit`
          : `/marketplace/send-credit/history/${id}`,
      name: "Send Credit",
    },
    {
      id: 3,
      path: `/marketplace/send-credit/history/${id}`,
      name: "Send Credit Details",
    },
  ];
};
