import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesEditCampaign } from "../../helpers/list";
import GetCampaignId from "../../helpers/use_query_hook/GetCampaignId";
import * as Yup from "yup";
import Loading from "../../../../components/loading/Loading";
import { penEdit, saveSize } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import moment from "moment";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import CampaignTemplate from "../campaign_template/CampaignTemplate";
import DeleteProductcampaignModal from "../campaign_template/campaign_product/DeleteProductCampaignModal";
import CircularLoad from "../../../../components/loading/CircularLoad";
import LocalStorage from "../../../../config/LocalStorage";
import DownloadQrCode from "../campaign_template/campaign_product/DownloadQrCode";
import { ActionType } from "../../../../store/campaign/action";
import { validateRole } from "../../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../../layout/marketplace/interfaces";
import AddQuantityShuffle from "../campaign_template/campaign_product/AddQuantityShuffle";
import Toggle from "../../../../components/button/Toggle";
import ColorPicker from "rc-color-picker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditCampaign = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const params: { id: string } = useParams();
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { showDeleteCampaign, showDownloadQrCode, showAddQuantityShuffle } =
    CampaignState;
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "user";
  const { data, isLoading } = GetCampaignId(role);

  useEffect(() => {
    if (data) {
      setValues({
        ...values,
        title: data.title,
        start_at: moment(data?.start_at).format("YYYY-MM-DDTHH:mm"),
        end_at: moment(data?.end_at).format("YYYY-MM-DDTHH:mm"),
        token_raffle_ticket_count: data?.token_raffle_ticket_count || 0,
        color_hex: data.color_hex,
        is_always_show: data.is_always_show,
        pick_up_address: data?.pick_up_address,
        pick_up_location: data?.pick_up_location,
      });
    }
  }, [data]);

  const { values, handleChange, handleSubmit, errors, touched, setValues } =
    useFormik({
      initialValues: {
        title: "",
        start_at: "",
        end_at: "",
        token_raffle_ticket_count: 0,
        color_hex: "#ffffff",
        active_color_hex: "1",
        active_ticket_count: "1",
        showEdit: false,
        is_always_show: true,
        pick_up_address: "",
        pick_up_location: "",
      },
      validationSchema: Yup.object({
        title: Yup.string().required("Campaign Name is required"),
        start_at: Yup.date().required("Start time is required"),
        end_at: Yup.date().required("End time is required"),
        color_hex: Yup.string().when("active_color_hex", {
          is: "1",
          then: Yup.string().required("Hexa code is Required"),
        }),
        token_raffle_ticket_count: Yup.number().when("active_ticket_count", {
          is: "1",
          then: Yup.number()
            .min(0, "Minimum of credit from scan is more than 0")
            .required("Get credit from scan is required"),
        }),
      }),
      onSubmit: async () => {
        await mutation.mutate();
      },
    });

  const mutation = useMutation(
    () => CampaignRequest.editCampaign(values, params.id),
    {
      onMutate: () => {
        console.log("mutate edit campaign");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        }
      },
    }
  );

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Edit"
          title2="Campaign"
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb data={optRoutesEditCampaign(params.id)} />
        {showDeleteCampaign.status && <DeleteProductcampaignModal />}
        {showDownloadQrCode && <DownloadQrCode data={data} />}
        {showAddQuantityShuffle.status && <AddQuantityShuffle />}
        {isLoading ? (
          <div className="flex justify-center my-20">
            <Loading />
          </div>
        ) : (
          <main className="my-14">
            <form className="mb-7">
              <div className="flex justify-between items-end">
                <div className="flex items-center w-1/3">
                  <input
                    type="text"
                    disabled={!values.showEdit}
                    className="text-3xl focus:outline-none w-full bg-white"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                  />
                  <img
                    src={penEdit}
                    alt="-"
                    onClick={
                      validateRole(role, [
                        AcceptedRole.Admin,
                        AcceptedRole.Operation,
                        AcceptedRole.Product,
                        AcceptedRole.Marketing,
                      ])
                        ? () =>
                            setValues({ ...values, showEdit: !values.showEdit })
                        : () => {}
                    }
                    className="w-6 cursor-pointer"
                  />
                </div>
                <div className="flex">
                  {validateRole(role, [
                    AcceptedRole.Admin,
                    AcceptedRole.Operation,
                    AcceptedRole.Product,
                    AcceptedRole.Marketing,
                  ]) && (
                    <div className="flex items-start">
                      <h1>Change Hexa Code</h1>
                      <ColorPicker
                        animation="slide-up"
                        color={values.color_hex}
                        placement="bottomLeft"
                        className="-mt-2 ml-3"
                        onChange={(e: any) =>
                          setValues({ ...values, color_hex: e.color })
                        }
                      />
                    </div>
                  )}
                  {validateRole(role, [
                    AcceptedRole.Admin,
                    AcceptedRole.Operation,
                    AcceptedRole.Product,
                    AcceptedRole.Marketing,
                  ]) && (
                    <div>
                      <h1
                        onClick={() =>
                          CampaignDispatch({
                            type: ActionType.ShowDownloadQrCode,
                          })
                        }
                        className="cursor-pointer hover:text-primary ml-5"
                      >
                        Download QR Code
                      </h1>
                    </div>
                  )}
                  {validateRole(role, [
                    AcceptedRole.Admin,
                    AcceptedRole.Operation,
                    AcceptedRole.Product,
                    AcceptedRole.Marketing,
                  ]) && (
                    <h1
                      onClick={() =>
                        history.push(`${url}/add-campaign-product`)
                      }
                      className="cursor-pointer hover:text-primary ml-5"
                    >
                      Add Campaign Product
                    </h1>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-5 gap-5 w-full mt-10">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Start Time
                  </h1>
                  <Input
                    type={"datetime-local"}
                    disable={
                      validateRole(role, [
                        AcceptedRole.Admin,
                        AcceptedRole.Operation,
                        AcceptedRole.Product,
                        AcceptedRole.Marketing,
                      ])
                        ? false
                        : true
                    }
                    handleChange={handleChange}
                    name={"start_at"}
                    value={values.start_at}
                    placeholder={"Place holder"}
                  />
                  {touched.start_at && errors.start_at && (
                    <h1 className="text-primary -mt-1 text-sm font-rom">
                      {errors.start_at}
                    </h1>
                  )}
                </div>
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    End Time
                  </h1>
                  <Input
                    type={"datetime-local"}
                    disable={
                      validateRole(role, [
                        AcceptedRole.Admin,
                        AcceptedRole.Operation,
                        AcceptedRole.Product,
                        AcceptedRole.Marketing,
                      ])
                        ? false
                        : true
                    }
                    handleChange={handleChange}
                    value={values.end_at}
                    name={"end_at"}
                    placeholder={"Place holder"}
                  />
                  {touched.end_at && errors.end_at && (
                    <h1 className="text-primary -mt-1 text-sm font-rom">
                      {errors.end_at}
                    </h1>
                  )}
                </div>
                <div>
                  <div className="flex justify-between">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Available for Scan
                    </h1>
                    <Toggle
                      disabled={
                        validateRole(role, [
                          AcceptedRole.Admin,
                          AcceptedRole.Operation,
                          AcceptedRole.Product,
                          AcceptedRole.Marketing,
                        ])
                          ? false
                          : true
                      }
                      input={values.active_ticket_count === "1" ? true : false}
                      handleClick={() => {
                        setValues({
                          ...values,
                          active_ticket_count:
                            values.active_ticket_count === "1" ? "0" : "1",
                        });
                      }}
                    />
                  </div>
                  <Input
                    type={"number"}
                    disable={
                      !validateRole(role, [
                        AcceptedRole.Admin,
                        AcceptedRole.Operation,
                        AcceptedRole.Product,
                        AcceptedRole.Marketing,
                      ])
                        ? true
                        : values.active_ticket_count === "0"
                        ? true
                        : false
                    }
                    handleChange={handleChange}
                    value={values.token_raffle_ticket_count}
                    name={"token_raffle_ticket_count"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="col-span-2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Campaign Effect
                  </h1>
                  <Input
                    type={"text"}
                    disable={true}
                    handleChange={handleChange}
                    value={"-"}
                    name={"effect"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 w-full mt-3">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Location
                  </h1>
                  <div className="mt-3 font-rom">
                    <CKEditor
                      disabled={
                        validateRole(role, [
                          AcceptedRole.Admin,
                          AcceptedRole.Operation,
                          AcceptedRole.Product,
                          AcceptedRole.Marketing,
                        ])
                          ? false
                          : true
                      }
                      config={{
                        toolbar: [
                          "bold",
                          "italic",
                          "link",
                          "blockQuote",
                          "|",
                          "undo",
                          "redo",
                        ],
                      }}
                      editor={ClassicEditor}
                      data={values.pick_up_address}
                      onReady={(editor: any) => {
                        values.pick_up_address !== null &&
                          editor.setData(values.pick_up_address);
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setValues({ ...values, pick_up_address: data });
                      }}
                      placeholder={"Place holder"}
                    />
                  </div>
                  {touched.pick_up_address && errors.pick_up_address && (
                    <h1 className="text-primary -mt-1 text-sm font-rom">
                      {errors.pick_up_address}
                    </h1>
                  )}
                </div>
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Location Link
                  </h1>
                  <Input
                    type={"text"}
                    disable={
                      validateRole(role, [
                        AcceptedRole.Admin,
                        AcceptedRole.Operation,
                        AcceptedRole.Product,
                        AcceptedRole.Marketing,
                      ])
                        ? false
                        : true
                    }
                    handleChange={handleChange}
                    value={values.pick_up_location}
                    name={"pick_up_location"}
                    placeholder={"Place holder"}
                  />
                  {touched.pick_up_location && errors.pick_up_location && (
                    <h1 className="text-primary -mt-1 text-sm font-rom">
                      {errors.pick_up_location}
                    </h1>
                  )}
                </div>
              </div>
            </form>
            <hr className="border border-stroke" />
            <CampaignTemplate dataCampaign={data} />
          </main>
        )}
      </div>

      {validateRole(role, [
        AcceptedRole.Admin,
        AcceptedRole.Operation,
        AcceptedRole.Product,
        AcceptedRole.Marketing,
      ]) && (
        <button
          type="button"
          onClick={() => handleSubmit()}
          disabled={mutation.isLoading ? true : false}
          className={
            "w-16 h-16 focus:outline-none rounded-full bg-primary flex justify-center items-center shadow-md fixed bottom-0 cursor-pointer hover:bg-opacity-70 right-0 m-10 " +
            (mutation.isLoading && "bg-opacity-80")
          }
        >
          {mutation.isLoading ? (
            <div className="flex justify-center items-center">
              <CircularLoad color={"#EFEFEF"} height={20} width={20} />
            </div>
          ) : (
            <img src={saveSize} alt="-" className="w-6" />
          )}
        </button>
      )}
    </>
  );
};

export default EditCampaign;
