import { createContext } from "react";
import { IOnBiddingContext, IinitialOnBiddingState } from "./interface";

export const initialOnBiddingState: IinitialOnBiddingState = {
  showFilter: false,
  showFilterSeeAll: false,
  showSeeAllPerSize: {
    status: false,
    size: 0,
  },
  size: [],
  product: [],
  sellOptions: [],
  buyer: [],
  chip: [],
  limit: 10,
  offset: 0,
};

const OnBiddingContext = createContext<IOnBiddingContext>({
  onBiddingState: initialOnBiddingState,
  onBiddingDispatch: () => {},
});

export const OnBiddingContextProvider = OnBiddingContext.Provider;
export const OnBiddingContextConsumer = OnBiddingContext.Consumer;

export default OnBiddingContext;
