const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/transaction",
    name: "Transaction",
  },
];

const optRoutesDetail = (code: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/transaction",
      name: "Transaction",
    },
    {
      id: 3,
      path: `/marketplace/transaction/${code}`,
      name: "Details",
    },
  ];
};

const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "code",
    name: "Top up Code",
  },
  {
    value: "username",
    name: "Username",
  },
  {
    value: "amount",
    name: "Amount",
  },
  {
    value: "payment_channel",
    name: "Payment Channel",
  },
  {
    value: "payment_status",
    name: "Payment Status",
  },
  {
    value: "payment_type",
    name: "Payment Method",
  },
];

export { optRoutes, optSorting, optRoutesDetail };
