import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Order from "../../../pages/order/Order";
import OrderDetail from "../../../pages/order/OrderDetail";
import {
  initialOrderState,
  OrderContextProvider,
} from "../../../store/order/OrderContext";
import { OrderReducer } from "../../../store/order/OrderReducer";

const OrderRoute = () => {
  const [OrderState, OrderDispatch] = useReducer(
    OrderReducer,
    initialOrderState
  );
  return (
    <OrderContextProvider value={{ OrderState, OrderDispatch }}>
      <div className="font-med">
        <Switch>
          <Route path="/marketplace/order" exact component={Order} />
          <Route path="/marketplace/order/:code" exact component={OrderDetail} />
          <Redirect from="/marketplace" to="/marketplace/order" exact />
        </Switch>
      </div>
    </OrderContextProvider>
  );
};

export default OrderRoute;
