import React, { useContext } from "react";
import { useHistory } from "react-router";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import NewSorting from "../../components/sorting/NewSorting";
import { ActionType } from "../../store/featured_product/action";
import FeaturedProductContext from "../../store/featured_product/FeaturedProductContext";
import DeleteFeaturedProductModal from "./components/DeleteFeaturedProductModal";
import FeaturedProductCard from "./components/FeaturedProductCard";
import {
  optRoutesFeaturedProduct,
  optSortingFeaturedProduct,
} from "./helpers/list";
import GetFeaturedProduct from "./use_query_hook/GetFeaturedProduct";

const FeaturedProduct = () => {
  const history = useHistory();
  const { FeaturedProductDispatch, FeaturedProductState } = useContext(
    FeaturedProductContext
  );
  const { showDelete } = FeaturedProductState;
  const { data, isFetching, isLoading } = GetFeaturedProduct();
  return (
    <div className="w-full">
      {showDelete.status && <DeleteFeaturedProductModal />}
      <section className="w-full" id="top">
        <DynamicBreadcrumb data={optRoutesFeaturedProduct} />
        <div className="md:mt-12 sm:mt-10 mt-8">
          <div className="flex justify-end">
            <h1
              onClick={() =>
                history.push(
                  `/marketplace/featured-product/add-featured-product`
                )
              }
              className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
            >
              Add Featured Product Tag
            </h1>
          </div>

          <div className="sm:flex justify-between mt-2">
            <div className="flex">
              <h1
                onClick={() =>
                  FeaturedProductDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingFeaturedProduct} />
          </div>

          {/* <Chip
            dispatchContext={couponDispatch}
            stateContext={couponState}
            queryParams={queryparamsCoupon}
            ActionType={ActionType}
          /> */}
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 mb-10">
            {data?.data.map((el: any) => (
              <FeaturedProductCard data={el} key={el.id} />
            ))}
          </div>
        )}

        <Pagination
          length={data?.data?.length}
          total={data?.meta?.total}
          isTable={false}
        />
      </section>
    </div>
  );
};

export default FeaturedProduct;
