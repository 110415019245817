import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { TransactionRequest } from "../../../../../apis/transactions/Transaction";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import { ActionType } from "../../../../../store/transaction/action";
import TransactionContext from "../../../../../store/transaction/TransactionContext";

const ConfirmChangeTracking = () => {
  const params: { code: string } = useParams();
  const { TransactionState, TransactionDispatch } =
    useContext(TransactionContext);
  const { changeTrackingId } = TransactionState;

  const goBack = () => {
    TransactionDispatch({
      type: ActionType.ShowChangeTracking,
    });
  };

  const handleSubmitTracking = () => {
    mutation.mutate();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      TransactionRequest.handleChangeStep(
        params.code,
        changeTrackingId,
        goBack
      ),
    {
      onMutate: () => {
        console.log("mutate change tracking");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getBnibTransaction");
        await queryClient.invalidateQueries("getTrack");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 pt-14">
      <div
        className="bg-white w-smallAlert
       h-auto rounded-xl my-auto mx-auto font-med"
      >
        <div className="flex justify-end items-center px-8 pt-6 pb-4">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>

        <div className="px-8">
          <div className="flex flex-col items-center">
            <h1 className="text-lg">Change Tracking Status</h1>
            <h1 className="md:text-base text-sm font-rom text-center px-14 mt-2 text-textPrimary">
              Are you sure you want to Change Tracking Status?
            </h1>
          </div>

          <div className="flex justify-between px-6 my-6 outline-none">
            <SmallButton
              loadColor={"white"}
              handleClick={goBack}
              height={9}
              type={"submit"}
              color={"bg-white"}
              hoverColor={"bg-gray-100"}
              text={"Close"}
              textColor={"text-placeholderColor"}
              border={"border-2"}
              borderColor={"border-stroke"}
            />
            <SmallButton
              loadColor={"white"}
              height={9}
              handleClick={handleSubmitTracking}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Continue"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmChangeTracking;
