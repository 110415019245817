import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Topup from "../../../pages/top_up/Topup";
import TopupDetail from "../../../pages/top_up/top_up_component/TopupDetail";

const TopupRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/marketplace/topup" exact component={Topup} />
        <Route path="/marketplace/topup/:id/:userId" component={TopupDetail} />
        <Redirect from="/marketplace" to="/marketplace/topup" exact />
      </Switch>
    </div>
  );
};

export default TopupRoute;
