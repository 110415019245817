import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import { bgDepatu } from "../../../../../assets/icons";
import isEmpty from "lodash.isempty";
import moment from "moment";
import SmallButton from "../../../../../components/button/SmallButton";
import GetParticipantWinner from "../../../helpers/use_query_hook/GetParticipantWinner";
import GetUsedTicket from "../../../helpers/use_query_hook/GetUsedTicket";
import { useQueries } from "../../../../../utils/query_searchParams/QuerySearchParams";
import CampaignSideCard from "../spin_product/CampaignSideCard";

const ParticipantPage = () => {
  const history = useHistory();
  const { search, pathname, hash } = useLocation();
  const params: { id: string; templateId: string } = useParams();
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const { data: dataParticipant, isLoading: loadParticipant } =
    GetParticipantWinner();
  const { data: dataUsedTicket, isLoading: loadUsedTicket } = GetUsedTicket();
  const query = useQueries(useLocation);

  const offset = query.get("offset") || 0;
  const limit = query.get("limit") || 8;

  const handleDownPage = () => {
    let totalOffset = Number(offset) - Number(limit);
    let new_search =
      offset !== null && offset !== 0
        ? search.replace(`offset=${offset}`, `offset=${totalOffset}`)
        : search.length > 0
        ? search + `&&offset=${totalOffset}`
        : `?offset=${totalOffset}`;
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  const handleUpPage = () => {
    let totalOffset = Number(offset) + Number(limit);
    let new_search = offset
      ? search.replace(`offset=${offset}`, `offset=${totalOffset}`)
      : search.length > 0
      ? search + `&&offset=${totalOffset}`
      : `offset=${totalOffset}`;
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  return (
    <div className="absolute overflow-hidden top-0 left-0 z-30 bg-black w-screen h-screen flex">
      {isLoading || loadParticipant || loadUsedTicket ? (
        <div className="flex justify-center w-full mt-20">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <>
        <CampaignSideCard data={data} dataUsedTicket={dataUsedTicket} title="SPECIAL PRICE" />
          <div className="w-2/3 h-full flex justify-center items-center text-white">
            <section className="absolute flex justify-start items-end bottom-0 w-2/3 h-full top-20">
              <img src={bgDepatu} alt="-" />
            </section>
            {!isEmpty(dataParticipant) && (
              <div className="flex flex-col items-center justify-center z-40 w-10/12 h-full py-4">
                <div className="my-5 w-full h-4/5 overflow-auto px-2">
                  <div className="grid grid-cols-2 gap-5">
                    {dataParticipant.data.map((el: any, i: number) => (
                      <div key={i} className="border border-white flex h-28 xl:h-32">
                        <div className="w-1/4 flex justify-center items-center">
                          <h1 className="text-3xl">{i + Number(offset) + 1}.</h1>
                        </div>
                        <div className="w-3/4 flex flex-col items-start justify-center">
                          <h1 className="text-white text-base">{`@${el.username} (${el.total} Credits)`}</h1>
                          <h1 className="text-placeholderColor mt-0.5 font-rom text-sm">
                            Last Update Credit:
                          </h1>
                          <h1 className="text-placeholderColor mt-0.5 font-rom text-sm">
                            {moment(el.latest_used_ticket_at).format(
                              "ll HH:mm a"
                            )}
                          </h1>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex w-full justify-between">
                  {!isLoading && !loadParticipant && (
                    <SmallButton
                      borderColor="border-white"
                      border={"border-2"}
                      color={"bg-black"}
                      text={"Back"}
                      height={12}
                      width={"44"}
                      type={"button"}
                      handleClick={() =>
                        history.push(
                          `/marketplace/campaign/edit-campaign/${params.id}/announce-winner/${params.templateId}`
                        )
                      }
                    />
                  )}

                  {!isLoading && !loadParticipant && (
                    <div className="ml-5 flex">
                      <SmallButton
                        disabled={offset === "0" || offset === 0 ? true : false}
                        text={"Prev"}
                        borderColor="border-white"
                        border={"border-2"}
                        color={"bg-black"}
                        height={12}
                        width={"44"}
                        type={"button"}
                        handleClick={handleDownPage}
                      />
                      <div className="ml-5">
                        <SmallButton
                          disabled={dataParticipant?.data?.length === dataParticipant?.meta?.total - Number(offset) ? true : false}
                          text={"Next"}
                          height={12}
                          width={"44"}
                          type={"button"}
                          handleClick={handleUpPage}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ParticipantPage;
