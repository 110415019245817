import moment from "moment";
import React, { useContext } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import BannerContext from "../../../store/banner/BannerContext";

interface IProps {
  data: any;
}

const BannerCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { hash } = useLocation();
  const context = useContext(BannerContext);
  const { bannerDispatch } = context;

  return (
    <div className="w-full h-auto border-2 border-stroke rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4">
        <div className="border-r-2 border-stroke flex justify-center">
          <img
            src={data.image_url}
            alt="banner_img"
            className="w-40 m-1 rounded-md shadow-lg"
          />
        </div>

        <div className="flex flex-col items-start pl-7 justify-center border-r-2 border-stroke">
          <h1 className="text-xl">{data.title}</h1>
          <h1 className="text-base font-rom mt-3">
            Action: {data.banner_action}
          </h1>
          <div className="flex mt-1">
            <h1 className="mr-1 text-base">Status :</h1>
            {data.is_active ? (
              <h1 className="text-success text-base">Posted</h1>
            ) : (
              <h1 className="text-primary text-base">Not Posted</h1>
            )}
          </div>
        </div>

        <div className="flex flex-col items-start justify-center px-10 border-r-2 border-stroke">
          {hash === "#general" ? (
            <div>
              <h1 className="text-base font-rom">Banner Post in</h1>
              <h1 className="text-xl">{data.banner_scope}</h1>
            </div>
          ) : (
            <div>
              <h1 className="text-base font-rom">Expired at</h1>
              <h1 className="text-lg mt-1">
                {moment(data.expired_at).format("LL")}
              </h1>
            </div>
          )}
        </div>

        <div className="flex items-center justify-evenly mx-10">
          <h1
            onClick={() => history.push(`${url}/edit-banner/${data.id}`)}
            className="text-base hover:text-primary cursor-pointer"
          >
            Edit
          </h1>
          <h1
            onClick={() =>
              bannerDispatch({
                type: "show_delete",
                payload: {
                  status: true,
                  id: data.id,
                },
              })
            }
            className="text-base hover:text-primary cursor-pointer"
          >
            Delete
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
