import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { bgDepatu } from "../../../../../assets/icons";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import db from "../../../../../config/InitFirebase";
import { collection, onSnapshot } from "firebase/firestore";
import isEmpty from "lodash.isempty";
import GetUsedTicket from "../../../helpers/use_query_hook/GetUsedTicket";
import CampaignSideCard from "./CampaignSideCard";

const ShuffleWinner = () => {
  const params: {
    id: string;
    templateId: string;
    winner: string;
    totalWinner: string;
  } = useParams();
  const history = useHistory();
  const [name, setName] = useState("");
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const [startShuffle, setStartShuffle] = useState(false);
  const [load, setLoad] = useState(false);
  const [dataSpinner, setDataSpinner] = useState<any>({});
  const { data: dataUsedTicket, isLoading: loadUsedTicket } = GetUsedTicket();
  const generateShuffle = () => {
    let random = Math.ceil(
      Math.random() * dataSpinner?.randomable_usernames?.length
    );
    setName(dataSpinner.randomable_usernames[random]);
  };

  const getSnapshot = () => {
    setLoad(true);
    return onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === +params.templateId &&
              doc.campaign_id === +params.id
          )[0];
        setDataSpinner(data);
        setLoad(false);
        setStartShuffle(true);
      }
    );
  };

  useEffect(() => {
    setDataSpinner({});
    getSnapshot();
    if (!isEmpty(dataSpinner) && startShuffle && !load) {
      const interval = setInterval(function () {
        console.log(new Date());
        console.log(
          new Date(
            dataSpinner?.winners[params.winner]?.end_animation_at?.seconds *
              1000
          )
        );
        if (
          new Date() >=
          new Date(
            dataSpinner?.winners[params.winner]?.end_animation_at?.seconds *
              1000
          )
        ) {
          clearInterval(interval);
          setStartShuffle(false);
          history.push(
            `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${params.templateId}/winner/${params.totalWinner}/${params.winner}`
          );
          return;
        }
        generateShuffle();
      }, 300);
    }
  }, [startShuffle]);

  return (
    <div className="absolute overflow-hidden top-0 left-0 z-30 bg-black w-screen h-screen flex">
      {isLoading || load || loadUsedTicket ? (
        <div className="flex justify-center w-full mt-20">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <>
          <CampaignSideCard
            data={data}
            dataUsedTicket={dataUsedTicket}
            title="GIVEAWAY"
          />
          <div className="w-2/3 flex justify-center items-center text-white py-10">
            <section className="absolute flex justify-start items-end bottom-0 w-2/3 h-full top-20 z-40">
              <img src={bgDepatu} className="" alt="" />
            </section>
            {!isEmpty(dataSpinner) && (
              <div className="flex flex-col items-center justify-center z-40 w-10/12">
                <h1 className="text-white font-med text-4xl mt-8 uppercase">
                  {data.scope === "campaign_giveawaydirect_raffle"
                    ? "Giveaway Onsite Raffle"
                    : "GIVEAWAY"}
                </h1>
                <h1 className="text-white font-rom text-3xl mt-2">
                  {data.product_name}
                </h1>
                <div className="my-10 border border-yellow-400 w-1/3 h-28 flex justify-center items-center">
                  <h1 text-white text-2xl>
                    {name}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ShuffleWinner;
