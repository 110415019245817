export enum ActionType {
  ShowFilter,
  HandleChip,
  ShowConfirm,
  ShowChangeTracking,
  SetPriorityTracking,
  ShowTrackProduct,
}

export interface ShowFiler {
  type: ActionType.ShowFilter;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface ShowConfirm {
  type: ActionType.ShowConfirm;
  payload: {
    status: boolean;
    location: string;
    id: number;
  };
}

export interface SetPriorityTracking {
  type: ActionType.SetPriorityTracking;
  payload: number;
}

export interface ShowChangeTracking {
  type: ActionType.ShowChangeTracking;
}
export interface ShowTrackProduct {
  type: ActionType.ShowTrackProduct;
  payload: {
    status: boolean;
    code: string;
  };
}

export type TransactionAction =
  | ShowFiler
  | ShowConfirm
  | HandleChip
  | SetPriorityTracking
  | ShowTrackProduct
  | ShowChangeTracking;
