import { Route, Redirect } from "react-router-dom";
import { validateRole } from "../checkRole/CheckRole";

const RoleGuard = ({ role, accepted_role, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        validateRole(role, accepted_role) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/404" }} />
        )
      }
    />
  );
};

export default RoleGuard;
