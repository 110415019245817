export const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/order",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
    name: "Order",
  },
];

export const optRoutesDetail = (code: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/order33",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
      name: "Order",
    },
    {
      id: 3,
      path: `/marketplace/order/${code}`,
      name: "Details",
    },
  ];
};

export const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
];

export const queryparams: any[] = [
  { real_name: "product_id", show_name: "product" },
  { real_name: "pre_order", show_name: "status" },
  { real_name: "seller_id", show_name: "username" },
  { real_name: "exclude_seller_id", show_name: "remove_username" },
];
