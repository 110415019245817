import { useContext } from "react";
import { drag } from "../../../../../assets/icons";
import TrackingContext from "../../../../../store/tracking/TrackingContext";

interface IPropsEl {
  item: any;
}

const ListElement: React.FC<IPropsEl> = ({ item }) => {
  const { trackingDispatch } = useContext(TrackingContext);
  return (
    <div className="flex h-auto my-8">
      <div className="w-5% flex justify-center items-center">
        <h1 className="text-3xl">{item.priority}.</h1>
      </div>
      <div className="border rounded-md w-3/4 h-auto py-4 flex justify-between items-center px-6">
        <h1 className="text-base">{item.description}</h1>
        <img src={drag} alt="drag" className="w-5" />
      </div>
      <div className="flex justify-evenly items-center w-1/5">
        <h1
          onClick={() =>
            trackingDispatch({
              type: "show_edit_step",
              payload: item.id,
            })
          }
          className="cursor-pointer hover:text-primary"
        >
          Edit
        </h1>
        <h1
          onClick={() =>
            trackingDispatch({
              type: "show_delete_step",
              payload: {
                status: true,
                id: item.id,
              },
            })
          }
          className="cursor-pointer hover:text-primary"
        >
          Delete
        </h1>
      </div>
    </div>
  );
};

export default ListElement;
