import React, { useState } from "react";
import { down, up } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import moment from "moment";

export interface UserInformationProps {
  data: any;
}

const UserInformation: React.FC<UserInformationProps> = ({ data }) => {
  const [dropdownTrans, setdropdownTrans] = useState<boolean>(true);

  const handledropdownTrans = () => {
    setdropdownTrans(!dropdownTrans);
  };

  return (
    <div className="w-full h-full mt-10">
      <div className="rounded-md border-2 border-stroke">
        {/*user information title*/}
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">User Information</h1>

          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdownTrans ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownTrans}
                />
              ) : (
                <img
                  src={down}
                  alt="down"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownTrans}
                />
              )}
            </div>
          </div>
        </div>
        {dropdownTrans && <hr className="border-t-2" />}

        {dropdownTrans && (
          <div className="py-2">
            <div className="md:flex w-full mt-4">
              {/*transactions data left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">User Id</h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.user_id}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Email
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"email"}
                    value={data?.user?.email}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Birthday
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"method"}
                    value={moment(data?.user?.birthday)
                      .add(1, "days")
                      .format("LL")}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Verified Email
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"verifemail"}
                    value={data?.user?.verified_email}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Phone
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"date"}
                    value={data?.user?.phone}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Joined at
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"date"}
                    value={moment(data?.created_at).format("lll")}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              {/*transactions data right*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Username
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"username"}
                    value={data?.username}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Gender
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"gender"}
                    value={data?.user?.gender}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Referral Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"referral_code"}
                    value={data?.user?.referral_code}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Country Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"by"}
                    value={`+ ${data?.user?.country_code}`}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4 mb-5">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Verified Phone
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"by"}
                    value={data?.user?.verified_phone}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInformation;
