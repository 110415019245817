import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TopupRequest from "../../../apis/top_up/Topup";
import { useMutation, useQueryClient } from "react-query";
import Input from "../../../components/input/Input";
import { cancel } from "../../../assets/icons";
import TopupContext from "../../../store/topup/TopupContext";
import LocalStorage from "../../../config/LocalStorage";
import SmallButton from "../../../components/button/SmallButton";

const CancelTopup: React.FC = () => {
  const context = useContext(TopupContext);
  const { topupDispatch, topupState } = context;
  const { showCancel } = topupState;
  const role = LocalStorage.ROLE || localStorage.getItem('ROLE');

  const { values, handleChange, handleSubmit, errors, touched, dirty } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: Yup.object({
        password: Yup.string()
          .required("password is required")
          .min(6, "The password must be of minimum 6 characters!"),
      }),
      onSubmit: async (values: any) => {
        await mutation.mutate(values);
      },
    });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TopupRequest.cancelTopup(values, handleHideModal, showCancel, role),
    {
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTopup");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const handleHideModal = () => {
    topupDispatch({
      type: "show_cancel",
      payload: {
        id: "",
        status: false,
      },
    });
  };

  return (
    <div className="w-full min-h-full font-med bg-black bg-opacity-70 inset-0 fixed z-50 flex justify-center items-center">
      <form
        onSubmit={handleSubmit}
        className="md:w-WModal md:h-auto md:py-6 py-4 bg-white rounded-lg shadow-md absolute"
      >
        <div className="flex justify-between md:px-10 px-5">
          <div className="flex items-center">
            <h1 className="md:text-xl flex text-primary">Cancel</h1>
            <h1 className="text-black md:text-xl ml-1.5">Top up</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            onClick={handleHideModal}
            className="cursor-pointer md:w-5 w-3"
          />
        </div>
        <hr className="my-5" />
        <div className="flex flex-col md:mt-7 mt-0 md:px-10 px-5">
          <h1 className="md:text-base text-sm font-medium text-textPrimary">
            Please write your 'Password'
          </h1>
          <Input
            type={"password"}
            placeholder={"Place holder"}
            handleChange={handleChange}
            name={"password"}
          />
          {touched.password && errors.password ? (
            <p className="text-primary font-rom text-xs">{errors.password}</p>
          ) : null}
        </div>
        <div className="flex justify-center mt-5 px-20">
          <SmallButton
            text={"Confirm"}
            type={"submit"}
            dirty={dirty}
            isSubmitting={mutation.isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CancelTopup;
