import { AxiosInstance } from "../../../config/AxiosInstance";
import { IShippingAddress } from "../entities";
import IShippingAddressRepository from "../repository";

export default class ShippingAddressRepository
  implements IShippingAddressRepository
{
  // api routes
  private SHIPPING_ADDRESS_ROUTE = "/admin/shipping-address";

  async getShippingAddressById(id: number): Promise<IShippingAddress> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.SHIPPING_ADDRESS_ROUTE}/${id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getShippingAddressByUserId(
    user_id: number
  ): Promise<IShippingAddress[]> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.SHIPPING_ADDRESS_ROUTE}/${user_id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }
}
