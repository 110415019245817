import ErrorHandler from "../../components/toast/ErrorHandler";
import { PLegitCheckDetail } from "../../core/legit_check/entities";
import LegitCheckRepository from "../../core/legit_check/repository/repository";
import LegitCheckUsecase from "../../core/legit_check/usecase/usecase";

export class LegitCheckService {
  static createLegitCheck = async (payload: {
    bnib_transaction_id: number;
    images: Blob[] | any;
    notes?: string;
  }) => {
    try {
      const formdata = new FormData();
      formdata.append(
        "bnib_transaction_id",
        payload.bnib_transaction_id.toString()
      );
      if (payload?.images) {
        payload?.images?.map((image: any) =>
          formdata.append("images[]", image)
        );
      }
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const legitCheck = await legitCheckUsecase.createLegitCheck(formdata);
      return legitCheck;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static updateLegitCheck = async (payload: {
    id: number;
    images: Blob[] | any;
    notes?: string;
  }) => {
    try {
      const formdata = new FormData();
      if (payload?.images) {
        payload?.images?.map((image: any) =>
          formdata.append("new_images[]", image)
        );
      }
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const legitCheck = await legitCheckUsecase.updateLegitCheck(
        payload.id,
        formdata
      );
      return legitCheck;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static getLegitCheck = async (id: number) => {
    try {
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const legitCheck = await legitCheckUsecase.getLegitCheck(id);
      return legitCheck;
    } catch (error) {
      console.log(error);
    }
  };

  static createLegitCheckDetail = async (
    legitCheckDetail: PLegitCheckDetail
  ) => {
    try {
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const legitCheck = await legitCheckUsecase.createLegitCheckDetail(
        legitCheckDetail
      );
      return legitCheck;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static updateLegitCheckDetail = async (
    legitCheckDetail: PLegitCheckDetail,
    legitCheckDetailId: number
  ) => {
    try {
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const legitCheck = await legitCheckUsecase.updateLegitCheckDetail(
        legitCheckDetail,
        legitCheckDetailId
      );
      return legitCheck;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };

  static getLegitCheckDetail = async (id: number) => {
    try {
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const legitCheck = await legitCheckUsecase.getLegitCheckDetail(id);
      return legitCheck;
    } catch (error) {
      console.log(error);
    }
  };

  static publishFinalResult = async (
    legitCheck: { final_result: string; confirm_result?: string },
    legitCheckId: number
  ) => {
    try {
      const payload = { ...legitCheck };
      delete payload?.confirm_result;
      const legitCheckRepo = new LegitCheckRepository();
      const legitCheckUsecase = new LegitCheckUsecase(legitCheckRepo);
      const lc = await legitCheckUsecase.publishFinalResult(
        payload,
        legitCheckId
      );
      return lc;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };
}
