import { useQuery } from "react-query";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";

const GetSizingVariant = (payload: any, gender: string) => {
  const { data, isLoading } = useQuery(
    ["GetSizingVariant", payload, gender],
    () =>
      SizingRequest.getSizeVariant(
        gender,
        payload.product_brand.value,
        payload.product_category.value
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetSizingVariant;
