import axios from "axios";
import LocalStorage from "./LocalStorage";

const axiosDicket = () => {
  let value = LocalStorage.AUTH_TOKEN || localStorage.getItem("AUTH_TOKEN");
  return axios.create({
    baseURL: process.env.REACT_APP_DICKET_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${value}`,
    },
  });
};

export default axiosDicket;
