import React from "react";
import CircularLoad from "../../../../components/loading/CircularLoad";
import Loading from "../../../../components/loading/Loading";
import Pagination from "../../../../components/pagination/Pagination";
import { optSortingCampaign } from "../../helpers/list";
import GetCampaignTemplate from "../../helpers/use_query_hook/GetCampaignTemplate";
import NewSorting from "../../../../components/sorting/NewSorting";
import CampaignTemplateCard from "./campaign_product/CampaignTemplateCard";
import LocalStorage from "../../../../config/LocalStorage";
import { validateRole } from "../../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../../layout/marketplace/interfaces";

interface IProps {
  dataCampaign: any;
}

const CampaignTemplate: React.FC<IProps> = ({ dataCampaign }) => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  const { data, isLoading, isFetching } = GetCampaignTemplate(role);

  return (
    <section>
      <div className="mt-10">
        <div className="flex justify-between mt-2">
          <div className="flex">
            {/* <h1
              // onClick={() =>
              //   couponDispatch({
              //     type: ActionType.ShowFilter,
              //   })
              // }
              className="text-base mr-5 cursor-pointer hover:text-primary"
            >
              Show Filter
            </h1> */}
          </div>
          <NewSorting option={optSortingCampaign} />
        </div>

        {/* <Chip
      dispatchContext={couponDispatch}
      stateContext={couponState}
      queryParams={queryparamsCoupon}
      ActionType={ActionType}
    /> */}
      </div>
      <div className="flex justify-end my-4">
        {isFetching ? (
          <div className="flex items-center">
            <CircularLoad color="#DF5354" height={20} width={20} />
            <h1 className="ml-2">Updating</h1>
          </div>
        ) : (
          <h1>Auto Update</h1>
        )}
      </div>

      {isLoading ? (
        <div className="flex justify-center my-20">
          <Loading />
        </div>
      ) : (
        <section>
          <div
            className={
              "w-full grid " +
              (validateRole(role, [
                AcceptedRole.Admin,
                AcceptedRole.Operation,
                AcceptedRole.Product,
                AcceptedRole.Marketing,
              ])
                ? "grid-cols-1 my-5"
                : "md:grid-cols-2 grid-cols-1 gap-12 my-10")
            }
          >
            {data?.data.map((el: any, idx: number) => (
              <CampaignTemplateCard
                idx={idx}
                data={el}
                key={el.id}
                dataCampaign={dataCampaign}
              />
            ))}
          </div>
          <Pagination
            length={data?.data?.length}
            total={data?.meta?.total}
            isTable={false}
          />
        </section>
      )}
    </section>
  );
};

export default CampaignTemplate;
