import React from "react";
import { useParams } from "react-router-dom";
import { useMutateDefectBnibtransaction } from "../../../hooks/bnib_transaction";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";

interface IOdefectBnibTransactionProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  handleNext: () => void;
  payload: { is_defect: boolean; defect_images?: any[] };
}

const OdefectBnibTransaction: React.FC<IOdefectBnibTransactionProps> = ({
  isOpen,
  setOpen,
  handleNext,
  payload,
}) => {
  const params: { code: string } = useParams();

  const { mutate, isLoading } = useMutateDefectBnibtransaction(
    params?.code,
    setOpen,
    handleNext
  );
  return (
    <ADialogBox
      content={{
        title: "",
        body: (
          <form className="flex flex-col">
            <div>
              <p className="text-sm font-med">
                Are you sure this product is defect?
              </p>
            </div>
            <div className="mt-7 flex gap-3 justify-end">
              <Abutton
                title="Cancel"
                theme="secondary"
                onClick={() => setOpen(false)}
              />
              <Abutton
                isLoading={isLoading}
                title="Confirm"
                onClick={() =>
                  mutate({
                    is_defect: payload?.is_defect,
                    defect_images: payload?.defect_images,
                  })
                }
              />
            </div>
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={setOpen}
    />
  );
};

export default OdefectBnibTransaction;
