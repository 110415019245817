import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { TrackingRequest } from "../../../../apis/tracking/TrackingRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import TrackingContext from "../../../../store/tracking/TrackingContext";

const DeleteLocationCard = () => {
  const { trackingState, trackingDispatch } = useContext(TrackingContext);
  const { showDeleteLocation } = trackingState;
  const { id } = showDeleteLocation;

  const goBack = () => {
    trackingDispatch({
      type: "show_delete_location",
      payload: {
        status: false,
        id: 0,
      },
    });
  };

  const handleDelete = () => {
    mutation.mutate();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TrackingRequest.deleteLocation(id, goBack),
    {
      onMutate: () => {
        console.log("mutate delete tracking");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTrackingLocation");
        if (error) {
          console.log(error);
        }
      },
    }
  );
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 pt-14">
      <div
        className="bg-white w-smallAlert
       h-auto rounded-xl my-auto mx-auto font-med"
      >
        <div className="flex justify-end items-center px-8 pt-6 pb-4">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>

        <div className="px-8">
          <div className="flex flex-col items-center">
            <h1 className="text-lg">Delete Location</h1>
            <h1 className="md:text-base text-sm font-rom text-center px-14 mt-2 text-textPrimary">
              Are you sure you want to delete the location?
            </h1>
          </div>

          <div className="flex justify-between px-6 my-6 outline-none">
            <SmallButton
              loadColor={"white"}
              handleClick={goBack}
              height={9}
              type={"submit"}
              color={"bg-white"}
              hoverColor={"bg-gray-100"}
              text={"Close"}
              textColor={"text-placeholderColor"}
              border={"border-2"}
              borderColor={"border-stroke"}
            />
            <SmallButton
              loadColor={"white"}
              height={9}
              handleClick={handleDelete}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Continue"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteLocationCard;
