export const handleChangeChecked = (
  event: React.ChangeEvent<HTMLInputElement>,
  check: any,
  setCheck: any,
  setCheck2?: any
) => {
  if (event.target.name === "category_group") {
    setCheck(!check);
    setCheck2(false);
  } else if (event.target.name === "category") {
    setCheck(!check);
    setCheck2(false);
  } else {
    setCheck(event.target.checked);
  }
};

export const handleChangeProduct = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({
    ...values,
    product_id: payload.value,
    product_name: payload.label,
  });
};

export const handleChangeStore = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({
    ...values,
    retail_shop_id: payload.value,
    retail_name: payload.label,
  });
};

export const handleChangeProductstore = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({
    ...values,
    retail_product_id: payload.value,
    product_retail_name: payload.label,
  });
};

export const handleChangeBrand = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({
    ...values,
    product_brand_id: payload.value,
    product_brand_name: payload.label,
  });
};

export const handleChangeCategory = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({
    ...values,
    product_category_id: payload.value,
    product_category_name: payload.label,
  });
};

export const handleChangeCategoryGroup = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({
    ...values,
    product_category_group_id: payload.value,
    product_category_group_name: payload.label,
  });
};

export const handleChangeTag = (payload: any, setValues: any, values: any) => {
  setValues({ ...values, tag_id: payload.value, tag_name: payload.label });
};

export const handleActive = (setValues: any, values: any) => {
  setValues({ ...values, is_active: !values.is_active });
};
