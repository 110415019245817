import moment from "moment";
import { useState } from "react";
import { down, up } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import { formatRupiah } from "../../../../utils/format_rupiah/FormatRupiah";

export interface ProductInfoProps {
  data: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ data }) => {
  const [dropdown, setdropdown] = useState<boolean>(true);

  const handledropdown = () => {
    setdropdown(!dropdown);
  };

  return (
    <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
      <div className="rounded-md border-2 border-stroke">
        <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-lg text-base">
            Product Information
          </h1>

          <div className="flex justify-between items-center">
            {dropdown ? (
              <img
                src={up}
                alt="up"
                className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                onClick={handledropdown}
              />
            ) : (
              <img
                src={down}
                alt="down"
                className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                onClick={handledropdown}
              />
            )}
          </div>
        </div>
        {dropdown ? <hr className="border-t-2" /> : ""}

        {dropdown && (
          <div className="md:flex w-full px-8 py-6">
            <div className="md:w-full">
              <div className="flex w-full">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Name
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Username"}
                    value={data?.product_detail.name || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Email"}
                    value={data?.product_detail.code || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Verified Email
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"VerifiedEmail"}
                    value={data?.product_detail.code || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Color
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Email"}
                    value={data?.product_detail.color || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Color Detail
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"VerifiedEmail"}
                    value={data?.product_detail.color_detail || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Status
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Email"}
                    value={data?.status_message || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Release Date
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"VerifiedEmail"}
                    value={
                      moment(data?.product_detail.release_date).format("LL") ||
                      "-"
                    }
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Retail Price
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"price"}
                    value={
                      `${data?.product_detail.retail_price_currency} ${data?.product_detail.retail_price}` ||
                      "-"
                    }
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Price (sell)
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={formatRupiah(data?.price) || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Buyer Username
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.a || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Sold
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.sold_count || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product View
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.view_count || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8"></div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Highest Bid Price (Brand New)
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.s || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Lowest Bid Price (Brand New)
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.s || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              <div className="flex w-full mt-1.5">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Highest Bid Price (Pre Order)
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.s || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="w-1/2 ml-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Lowest Bid Price (Pre Order)
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"id"}
                    value={data?.product_detail.s || "-"}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductInfo;
