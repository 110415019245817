import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { OnBiddingRequest } from "../../../apis/on_bidding/OnBiddingRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import CircularLoad from "../../../components/loading/CircularLoad";
import Loading from "../../../components/loading/Loading";
import NewSorting from "../../../components/sorting/NewSorting";
import OnBiddingContext from "../../../store/on_bidding/OnBiddingContext";
import { useQueries } from "../../../utils/query_searchParams/QuerySearchParams";
import { optRoutes, optSorting, queryparams } from "../helpers/list";
import OnBiddingFilter from "./OnBiddingFilter";
import OnBiddingList from "./OnBiddingList";

const OnBidding = () => {
  const query = useQueries(useLocation);
  const { onBiddingDispatch, onBiddingState } = useContext(OnBiddingContext);
  const { showFilter, size, product, sellOptions, buyer } = onBiddingState;

  const { data, isLoading, isFetching } = useQuery(
    [
      "getOnBidding",
      size,
      buyer,
      sellOptions,
      product,
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("seller_id"),
      query.get("pre_order"),
      query.get("product_id"),
      query.get("exclude_buyer_id"),
    ],
    () =>
      OnBiddingRequest.getOnBidding(
        "getOnBidding",
        onBiddingDispatch,
        size,
        buyer,
        sellOptions,
        product
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 15000,
      refetchInterval: 30000,
      refetchOnReconnect: true,
    }
  );
  return (
    <div className="md:pt-28 sm:pt-20 pt-16">
      {showFilter && <OnBiddingFilter />}
      <DynamicBreadcrumb data={optRoutes} />
      <div className="md:mt-12 mt-10">
        <div className="flex justify-between mt-4">
          <div className="flex">
            <h1
              onClick={() => onBiddingDispatch({ type: "show_filter" })}
              className="text-base mr-5 cursor-pointer hover:text-primary"
            >
              Show Filter
            </h1>
          </div>
          <NewSorting option={optSorting} />
        </div>

        <Chip
          dispatchContext={onBiddingDispatch}
          stateContext={onBiddingState}
          queryParams={queryparams}
        />

        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>

        {isLoading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <OnBiddingList data={data} />
        )}
      </div>
    </div>
  );
};

export default OnBidding;
