const ApplyChipTS = (queryParams: any, Dispatch: any, actionType: any) => {
    const new_data: any[] = [];
    const location = document.location.search;
    const query = new URLSearchParams(location);
    for (const key in queryParams) {
      let value: any = query.get(queryParams[key].real_name);
      if (value !== null) {
        new_data.push(queryParams[key].show_name);
      }
    }
    return Dispatch({
      type: actionType ,
      payload: new_data,
    });
  };
  
  export default ApplyChipTS;