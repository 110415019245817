import moment from "moment";
import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import { queryparamsCoupon } from "../../pages/coupon/helpers/list";
import { ActionType } from "../../store/coupon/action";
import ApplyChipTS from "../../utils/applyChip/ApplyChipTS";

export class CouponRequest {
  static getCoupon = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(`admin/coupon`, {
        params: {
          ...(query.get("display_name") !== null && {
            display_name: query.get("display_name"),
          }),
          ...(query.get("for_reward") !== null && {
            for_reward: query.get("for_reward"),
          }),
          ...(query.get("scopes") !== null && {
            scopes: query.get("scopes"),
          }),
          // ...(query.get("selling_time_from") !== null &&
          // query.get("selling_time_to") !== null
          //   ? {
          //       created_at: `${query.get("selling_time_from")},${query.get(
          //         "selling_time_to"
          //       )}`,
          //     }
          //   : ""),
          // ...(query.get("selling_time_from") !== null &&
          // query.get("selling_time_to") === null
          //   ? { created_at: `${query.get("selling_time_from")},2100-01-01` }
          //   : ""),
          // ...(query.get("selling_time_from") === null &&
          // query.get("selling_time_to") !== null
          //   ? { created_at: `2000-01-01,${query.get("selling_time_to")}` }
          //   : ""),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      ApplyChipTS(queryparamsCoupon, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getCouponById = async (id: string) => {
    try {
      const results = await axiosCelestine().get(`admin/coupon/${id}`);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static sendCoupon = async (payload: any, setSubmitting: any, goBack: any) => {
    try {
      const results = await axiosCelestine().post(`admin/coupon/send`, payload);
      setSubmitting(false);
      ErrorHandler("#00C62E", "Sent Completed");
      goBack();
    } catch (error: any) {
      setSubmitting(false);
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static deleteCoupon = async (id: number, goBack: () => void) => {
    try {
      await axiosCelestine().delete(`admin/coupon/${id}`);
      ErrorHandler("#00C62E", "Delete Completed");
      goBack();
    } catch (error) {
      ErrorHandler("#ed4846", "Delete Failed");
    }
  };

  static simulatedaddCoupon = async (payload: any, setSubmitting: any) => {
    try {
      const results = await axiosCelestine().post(`admin/coupon`, payload);
      setSubmitting(false);
      return results;
    } catch (error: any) {
      setSubmitting(false);
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addCoupon = async (payload: any, goBack: any) => {
    try {
      const results = await axiosCelestine().post(`admin/coupon`, payload);
      ErrorHandler("#00C62E", "Add Completed");
      goBack();
      return results;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static simulatedEditCoupon = async (
    payload: any,
    setSubmitting: any,
    id: string
  ) => {
    try {
      const results = await axiosCelestine().put(`admin/coupon/${id}`, payload);
      setSubmitting(false);
      return results;
    } catch (error: any) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Edit Failed");
      }
    }
  };

  static editCoupon = async (payload: any, goBack: any, id: string) => {
    try {
      const results = await axiosCelestine().put(`admin/coupon/${id}`, payload);
      ErrorHandler("#00C62E", "Add Completed");
      goBack();
      return results;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getPaymentMethod = async () => {
    try {
      const { data } = await axiosCelestine().get(
        "admin/invoice/payment-method"
      );
      const res: any = [];
      data.map((el: any) =>
        el.methods.map((e: any) =>
          res.push({
            label: e.name,
            value: e.code,
          })
        )
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  };
}
