import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { ProductScrapingRequest } from "../../../../../apis/product/ProductScrapingRequest";
import { useQueries } from "../../../../../utils/query_searchParams/QuerySearchParams";

const GetProductScraped = (productReviewDispatch:any) => {
  const query = useQueries(useLocation);
 
  const { data, isLoading, isFetching } = useQuery(
    [
      "getProductScraped",
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("scrap_batch_id"),
      query.get("source"),
      query.get("code"),
      query.get("need_review"),
      query.get("status"),
      query.get("crawl_time_from"),
      query.get("crawl_time_to"),
    ],
   () => ProductScrapingRequest.getProductScraped(productReviewDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );
  
  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetProductScraped;
