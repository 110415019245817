import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import axiosCelestineUser from "../../config/AxiosCelestineUser";
import { AxiosInstance } from "../../config/AxiosInstance";
import LocalStorage from "../../config/LocalStorage";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import qs from "qs";

export class CampaignRequest {
  static getCampaign = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Pandora().get(`public/campaign`, {
        params: {
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      // ApplyChipTS(queryparamsSizing, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getCampaignById = async (id: string) => {
    try {
      const results = await AxiosInstance.Pandora().get(
        `public/campaign/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getCampaignByIdAdmin = async (id: string) => {
    try {
      const results = await AxiosInstance.Pandora().get(`admin/campaign/${id}`);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getProductById = async (
    id: string,
    campaign_product_template_id: string
  ) => {
    try {
      const results = await axiosCelestine().get(`public/product/${id}`, {
        params: {
          campaign_product_template_id,
        },
      });
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addCampaign = async (payload: any) => {
    let data = {
      title: payload.title,
      start_at: `${payload.start_at}:00+07:00`,
      end_at: `${payload.end_at}:00+07:00`,
      color_hex: payload.color_hex,
      token_raffle_ticket_count: payload.token_raffle_ticket_count,
      is_always_show: payload.is_always_show,
      pick_up_location: payload.pick_up_location,
      pick_up_address: payload.pick_up_address,
    };
    try {
      const results = await AxiosInstance.Pandora().post(
        `admin/campaign`,
        data
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static deleteCampaign = async (id: number) => {
    try {
      await AxiosInstance.Pandora().delete(`admin/campaign/${id}`);
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Product Size Failed"
        );
      }
    }
  };

  static editCampaign = async (payload: any, id: string) => {
    let data = {
      title: payload.title,
      start_at: `${payload.start_at}:00+07:00`,
      end_at: `${payload.end_at}:00+07:00`,
      token_raffle_ticket_count: payload.token_raffle_ticket_count,
      color_hex: payload.color_hex,
      is_always_show: payload.is_always_show,
      pick_up_location: payload.pick_up_location,
      pick_up_address: payload.pick_up_address,
    };
    try {
      const results = await AxiosInstance.Pandora().patch(
        `admin/campaign/${id}`,
        data
      );
      ErrorHandler("#00C62E", "Edit Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Edit Failed");
      }
    }
  };

  static getCampaignProductTemplate = async (
    dispatch: any,
    campaign_id: string,
    role: string | null
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Pandora().get(
        `${
          role === AcceptedRole.Spinner
            ? AcceptedRole.Spinner
            : role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : "public"
        }/campaign-product-template`,
        {
          params: {
            campaign_id,
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      // ApplyChipTS(queryparamsSizing, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getCampaignProductbyId = async (
    id: string | any,
    role: string | null
  ) => {
    try {
      const results = await AxiosInstance.Pandora().get(
        `${
          role === AcceptedRole.Spinner
            ? AcceptedRole.Spinner
            : role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : "public"
        }/campaign-product-template/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error.response.data.errors);
    }
  };

  static addCampaignProduct = async (payload: any) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `admin/campaign-product-template`,
        payload
      );
      ErrorHandler("#00C62E", "Add Campaign Product Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Add Campaign Product Failed"
        );
      }
    }
  };

  static editCampaignProduct = async (payload: any, id: string) => {
    try {
      const results = await AxiosInstance.Pandora().patch(
        `admin/campaign-product-template/${id}`,
        payload
      );
      ErrorHandler("#00C62E", "Edit Campaign Product Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Campaign Product Failed"
        );
      }
    }
  };

  static deleteCampaignProductTemplate = async (id: number) => {
    try {
      const data = await AxiosInstance.Pandora().delete(
        `admin/campaign-product-template/${id}`
      );
      const status = data.status;
      return status;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Product Size Failed"
        );
      }
    }
  };

  static sellProduct = async (payload: any) => {
    try {
      const data = { ...payload };
      if (data.is_simulated) {
        delete data.pre_order;
      }
      if (data.coupon_code.length <= 0) {
        delete data.coupon_code;
      }
      if (data.price) {
        data.price = Number(data.price.substring(4).split(",").join("").trim());
      }
      const results = await AxiosInstance.Pandora().post(
        `invoice/restock`,
        data
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Sell Product Failed"
        );
      }
    }
  };

  static getUser = async () => {
    try {
      const results = await axiosCelestineUser().get(`user`);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static processPaymentWallet = async (payload: any, code: string) => {
    try {
      const results = await axiosCelestineUser().post(
        `/invoice/process-wallet/${code}`,
        payload
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Selling Failed");
      }
    }
  };

  static getOnSellingStockPerSize = async (
    product_id: number,
    campaign_product_template_id: string,
    dispatch: any
  ) => {
    try {
      const role = localStorage.getItem("ROLE");
      const admin_role = LocalStorage.ROLE;
      const location = document.location.search;
      const query = new URLSearchParams(location);
      if (admin_role === "admin") {
        const results = await axiosCelestine().get(
          `admin/bnib-product/${product_id}/on-selling-group-by-product-size`,
          {
            params: {
              scope: "campaign",
              campaign_product_template_id,
              limit: query.get("limit") !== null ? query.get("limit") : 10,
              offset: query.get("offset") !== null ? query.get("offset") : 0,
              sort: `${
                query.get("sort") !== null ? query.get("sort") : "created_at"
              } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
            },
          }
        );
        const result = results.data;
        return result;
      } else if (role === "user") {
        const results = await axiosCelestineUser().get(
          `bnib-product/${product_id}/on-selling-group-by-product-size`,
          {
            params: {
              scope: "campaign",
              campaign_product_template_id,
              limit: query.get("limit") !== null ? query.get("limit") : 10,
              offset: query.get("offset") !== null ? query.get("offset") : 0,
              sort: `${
                query.get("sort") !== null ? query.get("sort") : "created_at"
              } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
            },
          }
        );
        const result = results.data;
        return result;
      }
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getAllProductSize = async (
    product_id: number,
    product_size_id: number | string,
    campaign_product_template_id: string,
    sort: { value: string; isAsc: string },
    limit: number,
    offset: number
  ) => {
    try {
      const role = localStorage.getItem("ROLE");
      const admin_role = LocalStorage.ROLE;
      if (admin_role === "admin") {
        const results = await axiosCelestine().get(`admin/bnib-product`, {
          params: {
            scope: "campaign",
            campaign_product_template_id,
            product_id,
            product_size_id,
            limit,
            offset,
            sort: `${sort.value} ${sort.isAsc}`,
          },
        });
        const result = results.data;
        return result;
      } else if (role === "user") {
        const results = await axiosCelestineUser().get(`bnib-product`, {
          params: {
            scope: "campaign",
            campaign_product_template_id,
            product_id,
            product_size_id,
            limit,
            offset,
            sort: `${sort.value} ${sort.isAsc}`,
          },
        });
        const result = results.data;
        return result;
      }
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static ShuffleRaffle = async (payload: any, role: string | null) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/campaign-product-template/shuffle`,
        payload
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Sell Product Failed"
        );
      }
    }
  };

  static reshuffle = async (templateId: number, role: string | null) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/campaign-product-template/reshuffle`,
        { campaign_product_template_id: templateId }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Reshuffle Failed"
        );
      }
    }
  };

  static dropWinner = async (
    templateId: number,
    userId: number,
    role: string | null
  ) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/campaign-product-template/drop-winner`,
        { campaign_product_template_id: templateId, user_id: userId }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Remove winner Failed"
        );
      }
    }
  };

  static preCompute = async (templateId: number, role: string | null) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/winner-candidate/precompute`,
        { campaign_product_template_id: templateId }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Pre-Compute Failed"
        );
      }
    }
  };

  static getWinnerTopCredit = async (payload: any, role: string | null) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/winner-candidate/select-winner`,
        payload
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Get Winner Failed"
        );
      }
    }
  };

  static getResultWinners = async (
    campaign_product_template_id: string,
    role: string | null
  ) => {
    try {
      const results = await AxiosInstance.Pandora().get(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/campaign-claim`,
        {
          params: {
            campaign_product_template_id,
            status: "done",
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Get Winner Failed"
        );
      }
    }
  };

  static confirmWinner = async (invoice_code: string, role: string | null) => {
    try {
      const results = await AxiosInstance.Pandora().post(
        `${
          role === AcceptedRole.Admin
            ? AcceptedRole.Admin
            : AcceptedRole.Spinner
        }/campaign-claim/confirm-claim`,
        { invoice_code }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Confirm winner Failed"
        );
      }
    }
  };

  static getParticipantWinner = async (
    campaign_product_template_id: string
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Pandora().get(
        `public/winner-candidate`,
        {
          params: {
            campaign_product_template_id,
            limit: query.get("limit") !== null ? query.get("limit") : 8,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: 'total desc'
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };
  static getUsedTicket = async (campaign_product_template_id: string) => {
    try {
      const results = await AxiosInstance.Pandora().get(
        `public/used-ticket-raffle`,
        {
          params: {
            campaign_product_template_id,
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };
}
