import axiosCelestine from "../../config/AxiosCelestine";
import axiosDicket from "../../config/AxiosDicket";
import axiosMercury from "../../config/AxiosMercury";
import { AxiosInstance } from "../../config/AxiosInstance";
import moment from "moment";

export const loadOptionsCategory = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("public/product-category", {
      params: {
        name: inputValue,
        limit: 30,
        sort: "created_at asc",
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsProduct = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("public/product/search", {
      params: {
        search: inputValue,
        limit: 100,
      },
    });

    const option = data.data.map((el: any) => ({
      label: `${el.name} (${el.code})`,
      value: el.id,
      img: el.display_image_url,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsBrand = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("public/product-brand", {
      params: {
        name: inputValue,
        limit: 100,
        sort: "created_at asc",
      },
    });

    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
      img: el.image_path,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsSubBrand = async (
  inputValue: string,
  product_brand_id: string,
  product_category_id: string
) => {
  try {
    const { data } = await axiosCelestine().get(
      "admin/product-size-variant/sub_brand",
      {
        params: {
          name: inputValue,
          limit: 100,
          product_brand_id,
          product_category_id,
        },
      }
    );

    const option = data.data.map((el: any) => ({
      label: el.product_brand_name,
      value: el.product_brand_id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsUsername = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/user", {
      params: {
        username: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.username,
      value: el.username,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsUsernameById = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/user", {
      params: {
        username: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.username,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsOnSellingSeller = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get(
      "admin/bnib-product/on-selling-seller",
      {
        params: {
          username: inputValue,
        },
      }
    );
    const option = data.data.map((el: any) => ({
      label: el.username,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsOnBiddingBidder = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get(
      "admin/bnib-buy-order/on-bidding-buyer",
      {
        params: {
          username: inputValue,
        },
      }
    );
    const option = data.data.map((el: any) => ({
      label: el.username,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsStore = async (inputValue: string) => {
  try {
    const { data } = await axiosMercury().get("admin/retail-shop", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
      img: el.logo_path,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsProductStore = async (inputValue: string) => {
  try {
    const { data } = await axiosMercury().get("admin/retail-product", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
      img: el.display_image_path,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsTag = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/tag", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsCategoryGroup = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get(
      "admin/product-category-group",
      {
        params: {
          name: inputValue,
          sort: "created_at asc",
        },
      }
    );
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadLocationTracking = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get(
      "admin/custom-tracking-origin",
      {
        params: {
          name: inputValue,
        },
      }
    );
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptCoupon = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/coupon", {
      params: {
        display_name: inputValue,
        for_reward: true,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.display_name,
      value: el._id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptFilter = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/coupon", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.display_name,
      value: el._id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsSeason = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/season", {
      params: {
        name: inputValue,
        limit: 30,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptPaymentMethod = async (inputValue?: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/invoice/payment-method");
    const res: any = [];
    data.map((el: any) =>
      el.methods.map((e: any) =>
        res.push({
          label: e.name,
          value: e.code,
        })
      )
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptCampaign = async (inputValue: string) => {
  try {
    const { data } = await AxiosInstance.Pandora().get("public/campaign", {
      params: {
        title: inputValue,
        limit: 50,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.title,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptCampaignEndAt = async (inputValue: string) => {
  try {
    const { data } = await AxiosInstance.Pandora().get("public/campaign", {
      params: {
        title: inputValue,
        limit: 50,
        end_at: `${moment().format('YYYY-MM-DD')},2500-01-01`
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.title,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptTicket = async (inputValue: string) => {
  try {
    const { data } = await axiosDicket().get("admin/ticket-bundle/", {
      params: {
        search: inputValue,
        limit: 50,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
      img: el.display_image_path,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptTicketVariant = async (
  inputValue: string,
  ticket_bundle_id: number
) => {
  try {
    const { data } = await axiosDicket().get("admin/ticket/", {
      params: {
        search: inputValue,
        limit: 50,
        ticket_bundle_id,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.label,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptCreditEffect = async (inputValue: string) => {
  try {
    const { data } = await AxiosInstance.Cygnus().get("requirement/", {
      params: {
        search: inputValue,
        limit: 50,
      },
    });

    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.name,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};
