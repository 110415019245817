import React, { useContext } from "react";
import { useQuery } from "react-query";
import { TrackingRequest } from "../../../apis/tracking/TrackingRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../components/loading/Loading";
import TrackingContext from "../../../store/tracking/TrackingContext";
import { optRoutes } from "../helpers/list";
import NonTablePagination from "../../../components/pagination/NonTablePagination";
import TrackingCard from "./tracking_component/TrackingCard";
import AddLocationCard from "./tracking_component/AddLocationCard";
import DeleteLocationCard from "./tracking_component/DeleteLocationCard";

const Tracking = () => {
  const { trackingDispatch, trackingState } = useContext(TrackingContext);
  const { limit, offset, sortValue, isAsc, showAdd, showDeleteLocation } =
    trackingState;

  const { data, isLoading } = useQuery(
    ["getTrackingLocation"],
    () => TrackingRequest.getTrackingLocation(limit, offset, sortValue, isAsc),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="md:pt-28 sm:pt-20 pt-16">
      {showAdd && <AddLocationCard />}
      {showDeleteLocation.status && <DeleteLocationCard />}

      <DynamicBreadcrumb data={optRoutes} />
      <div className="flex justify-end mt-6">
        <h1
          onClick={() =>
            trackingDispatch({
              type: "show_add",
            })
          }
          className="hover:text-primary cursor-pointer"
        >
          Add Location
        </h1>
      </div>
      {isLoading ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <div className="grid grid-cols-2 w-full gap-10 my-10">
          {data?.data.map((el: any) => (
            <TrackingCard data={el} key={el.id} />
          ))}
        </div>
      )}
      <NonTablePagination
        offset={offset}
        limit={limit}
        total={data?.meta?.total}
        length={data?.data?.length}
        dispatchContext={trackingDispatch}
      />
    </div>
  );
};

export default Tracking;
