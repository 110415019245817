import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import Toggle from "../../components/button/Toggle";
import CircularLoad from "../../components/loading/CircularLoad";
import NavDetail from "../../components/navbar/NavDetail";
import { listColor, optRoutesEditFeaturedProduct } from "./helpers/list";
import Input from "../../components/input/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { loadOptionsProduct } from "../../utils/loadOption/LoadOption";
import GetProduct from "./use_query_hook/GetProduct";
import { useMutation } from "react-query";
import { FeaturedProductRequest } from "../../apis/featured_product/FeaturedProductRequest";
import GetFeaturedProductId from "./use_query_hook/GetFeaturedProductId";

const EditFeaturedProduct = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: { label: "", value: "" },
      published: true,
      priority: 0,
      show_product_tag_1: true,
      show_product_tag_2: true,
      product_tag_1: "",
      product_tag_2: "",
      color_1: { label: "", value: "" },
      color_2: { label: "", value: "" },
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        value: Yup.string().required("Product Name is Required"),
      }),
      priority: Yup.number()
        .min(1, "Priority minimum is 1")
        .required("Priority is required"),
      show_product_tag_1: Yup.boolean(),
      product_tag_1: Yup.string().when("show_product_tag_1", {
        is: true,
        then: Yup.string().required("Product tag 1 is required"),
      }),
      color_1: Yup.object().when("show_product_tag_1", {
        is: true,
        then: Yup.object().shape({
          value: Yup.string().required("Color is Required"),
        }),
      }),
      product_tag_2: Yup.string().when("show_product_tag_2", {
        is: true,
        then: Yup.string().required("Product tag 2 is required"),
      }),
      color_2: Yup.object().when("show_product_tag_2", {
        is: true,
        then: Yup.object().shape({
          value: Yup.string().required("Color is Required"),
        }),
      }),
    }),
    onSubmit: async () => {
      mutation.mutate();
    },
  });

  const { data, isLoading } = GetFeaturedProductId(params.id);
  const { data: dataImage, isLoading: loadImage } = GetProduct(
    values.name.value
  );

  useEffect(() => {
    setValues({
      ...values,
      name: { value: data?.product?.id, label: data?.product.name },
      priority: data?.priority,
      published: data?.published,
      show_product_tag_1: data?.tags[0]?.label !== undefined  ? true : false,
      show_product_tag_2: data?.tags[1]?.label !== undefined ? true : false,
      product_tag_1: data?.tags[0]?.label,
      product_tag_2: data?.tags[1]?.label,
      color_1: {
        label:
          data?.tags[0]?.color === "#ed4846"
            ? "Red"
            : data?.tags[0]?.color === "#00C62E"
            ? "Green"
            : "",
        value: data?.tags[0]?.color,
      },
      color_2: {
        label:
          data?.tags[1]?.color === "#ed4846"
            ? "Red"
            : data?.tags[1]?.color === "#00C62E"
            ? "Green"
            : "",
        value: data?.tags[1]?.color,
      },
    });
  }, [data]);

  const mutation = useMutation(
    () =>
      FeaturedProductRequest.editFeaturedProductTag(values, params.id, data),
    {
      onMutate: () => {
        console.log("mutate add featured product");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          history.goBack();
        }
      },
    }
  );

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Edit"
          title2="Featured Product"
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb data={optRoutesEditFeaturedProduct(params.id)} />
        <form onSubmit={handleSubmit} className="flex justify-between my-14">
          <aside className="md:w-1/3 h-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
              <h1 className="font-medium md:text-lg text-base">
                Product Image
              </h1>
            </div>
            <hr />

            <div className="md:mx-8 mx-5 py-5">
              <h1 className="md:text-base text-sm text-textPrimary">
                Display Image
              </h1>
              <div className="flex justify-center">
                {isLoading || loadImage ? (
                  <div className="flex justify-center my-20">
                    <CircularLoad color={"#ed4846"} height={32} width={32} />
                  </div>
                ) : dataImage?.display_image_url ? (
                  <img
                    src={dataImage?.display_image_url}
                    alt=""
                    className="shadow-sm rounded-md"
                  />
                ) : data?.product?.display_image_url ? (
                  <img
                    src={data?.product?.display_image_url}
                    alt=""
                    className="shadow-sm rounded-md"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </aside>
          <aside className="md:w-custom h-full md:mt-0">
            <div className="rounded-md rounded-b-none border-2 border-stroke">
              <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
                <h1 className="font-medium md:text-lg text-base">
                  Featured Product Tag Information
                </h1>

                <div className="flex">
                  <h1 className="md:text-base text-sm mr-3 text-textPrimary">
                    Posted
                  </h1>
                  <div className="flex justify-between items-center">
                    <Toggle
                      handleClick={() =>
                        setValues({
                          ...values,
                          published: !values.published,
                        })
                      }
                      input={values.published}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="w-full px-8 py-4">
                <div className="w-full">
                  <div className="w-full my-2.5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Product Name
                    </h1>
                    <AsyncSelect
                      loadOptions={loadOptionsProduct}
                      cacheOptions
                      defaultOptions
                      value={values.name}
                      // isDisabled={!values.show_product_tag_1 && true}
                      onChange={(e: any) => setValues({ ...values, name: e })}
                      className="mt-3.5 mb-4 font-rom text-sm"
                    />
                    {errors.name && touched.name && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.name.value}
                      </p>
                    )}
                  </div>
                  <div className="w-full my-2.5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Priority
                    </h1>
                    <Input
                      disable={false}
                      type={"number"}
                      name={"priority"}
                      value={values.priority}
                      handleChange={handleChange}
                      placeholder={"Place holder"}
                    />
                    {errors.priority && touched.priority && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.priority}
                      </p>
                    )}
                  </div>
                  <div className="w-full my-2.5 flex">
                    <div className="w-1/2 mr-8">
                      <div className="flex justify-between">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Product Tag 1
                        </h1>
                        <Toggle
                          handleClick={() =>
                            setValues({
                              ...values,
                              show_product_tag_1: !values.show_product_tag_1,
                            })
                          }
                          input={values.show_product_tag_1}
                        />
                      </div>
                      <Input
                        disable={!values.show_product_tag_1 && true}
                        type={"text"}
                        name={"product_tag_1"}
                        value={values.product_tag_1}
                        handleChange={handleChange}
                        placeholder={"Place holder"}
                      />
                      {errors.product_tag_1 && touched.product_tag_1 && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.product_tag_1}
                        </p>
                      )}
                    </div>
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Color
                      </h1>
                      <Select
                        options={listColor}
                        value={values.color_1}
                        isDisabled={!values.show_product_tag_1 && true}
                        onChange={(e: any) =>
                          setValues({ ...values, color_1: e })
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                      {errors.color_1 && touched.color_1 && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.color_1?.value}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-full my-2.5 flex">
                    <div className="w-1/2 mr-8">
                      <div className="flex justify-between">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Product Tag 2
                        </h1>
                        <Toggle
                          handleClick={() =>
                            setValues({
                              ...values,
                              show_product_tag_2: !values.show_product_tag_2,
                            })
                          }
                          input={values.show_product_tag_2}
                        />
                      </div>
                      <Input
                        disable={!values.show_product_tag_2 && true}
                        type={"text"}
                        name={"product_tag_2"}
                        value={values.product_tag_2}
                        handleChange={handleChange}
                        placeholder={"Place holder"}
                      />
                      {errors.product_tag_2 && touched.product_tag_2 && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.product_tag_2}
                        </p>
                      )}
                    </div>
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Color
                      </h1>
                      <Select
                        options={listColor}
                        value={values.color_2}
                        isDisabled={!values.show_product_tag_2 && true}
                        onChange={(e: any) =>
                          setValues({ ...values, color_2: e })
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                      {errors.color_2 && touched.color_2 && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.color_2?.value}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
              <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
                <button
                  type="reset"
                  onClick={() => {
                    handleReset({});
                  }}
                  className="focus:outline-none"
                >
                  <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                    Clear
                  </h1>
                </button>
              </div>
              <div className="w-1/2 flex items-center justify-center">
                <button type="submit" className="focus:outline-none">
                  {isSubmitting ? (
                    <CircularLoad color={"black"} height={16} width={16} />
                  ) : (
                    <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                      Edit
                    </h1>
                  )}
                </button>
              </div>
            </div>
          </aside>
        </form>
      </div>
    </>
  );
};

export default EditFeaturedProduct;
