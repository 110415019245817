import React, { useEffect, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import axiosCelestine from "../../../../config/AxiosCelestine";
import { useQuery } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

//components
import Loading from "../../../../components/loading/Loading";
import ErrorHandler from "../../../../components/toast/ErrorHandler";

//icons
import { drag } from "../../../../assets/icons";

const DetailCategoriesCard = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const params: {
    id: string;
    detail: string;
  } = useParams();
  const [loading, setLoading] = useState(false);

  const getCategories = async () => {
    try {
      const results = await axiosCelestine().get(
        `admin/special-category-list`,
        {
          params: {
            special_category_id: params.detail,
          },
        }
      );
      const result = results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data: dataList, isLoading, refetch } = useQuery(
    "getCategories",
    getCategories,
    { refetchOnWindowFocus: false }
  );

  const handleDrop = (sourceIndex: number, destinationIndex: number) => {
    const filterIndex = dataList[sourceIndex];
    if (sourceIndex === destinationIndex) {
      ErrorHandler("#DF5354", 'Please put in another place!')
    } else {
      setLoading(true);
      axiosCelestine()
        .patch(`admin/special-category-list/${filterIndex.id}`, {
          priority: destinationIndex + 1,
        })
        .then(() => {
          refetch();
          setTimeout(() => {
            ErrorHandler("#00C62E", "Edit Completed");
          }, 2500);
        })
        .catch((err) => ErrorHandler("#DF5354", err.response.data.errors));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, [loading]);

  const goDelete = (id: number) => {
    history.push(`${url}/delete/${id}`);
  };

  const goEdit = (id: number) => {
    history.push(`${url}/edit/${id}`);
  };

  const ListElement = (item: any) => {
    return (
      <div className="md:w-detailCategories md:max-w-xs lg:w-detailCategorieslg lg:max-w-sm xl:w-detailCategoriesxl xl:max-w-md 2xl:w-detailCategories2xl 2xl:max-w-sm 3xl:w-detailCategories3xl 3xl:max-w-md h-auto my-5 xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke">
        <div className="flex justify-between items-start px-8 py-10">
          <div></div>
          <div className="flex border-2 border-stroke rounded-md p-7">
            <img src={item.item.image_path} className="w-32 h-32" alt="img" />
          </div>
          <div className="flex items-center">
            <img src={drag} alt="" />
          </div>
        </div>
        <hr />
        <div className="ml-10 mt-8 flex flex-col justify-around">
          <h1 className="text-xl">{item.item.name}</h1>
          {item.item.type === "product_brand" ? (
            <h1 className="text-base mt-6 font-rom">
              Category Type : Brand to Sub-Brand
            </h1>
          ) : item.item.type === "product_category" ? (
            <h1 className="text-base mt-6 font-rom">
              Category Type : Specific Brand Category
            </h1>
          ) : item.item.type === "special_category" ? (
            <h1 className="text-base mt-6 font-rom">
              Category Type : All Brand Sub-Category
            </h1>
          ) : (
            <h1 className="text-base mt-6 font-rom">
              Category Type : Specific Category Brand
            </h1>
          )}
          <h1 className="text-base mt-2 font-rom">
            Priority : {item.item.priority}
          </h1>
          <div className="flex mt-2">
            <h1 className="mr-1 text-base">Status :</h1>{" "}
            {item.item.published ? (
              <h1 className="text-success text-base">Posted</h1>
            ) : (
              <h1 className="text-primary text-base">Not Posted</h1>
            )}
          </div>
        </div>
        <div className="flex justify-around text-lg h-20 mt-12 w-full">
          <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
            <h1
              onClick={() => goEdit(item.item.id)}
              className="hover:text-primary"
            >
              Edit
            </h1>
          </div>
          <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
            <h1
              onClick={() => goDelete(item.item.id)}
              className="hover:text-primary"
            >
              Delete
            </h1>
          </div>
        </div>
      </div>
    );
  };

  return isLoading ? (
    <div className="flex justify-center mt-20">
      <Loading />
    </div>
  ) : (
    <div className="w-full">
      {loading ? (
        <div className="flex justify-center items-center bg-black bg-opacity-90 h-screen w-full absolute left-0 right-0 top-0 z-50">
          <Loading />
        </div>
      ) : (
        <ListManager
          items={dataList}
          direction="horizontal"
          maxItems={3}
          render={(item) => <ListElement item={item} />}
          onDragEnd={handleDrop}
        />
      )}
    </div>
  );
};

export default DetailCategoriesCard;
