import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";
import Pagination from "../../../../components/pagination/Pagination";

interface IProps {
  data: any;
}

const ExceptionBrandList: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const params: { product_category_id: string; product_brand_id: string } =
    useParams();
  const QueryClient = useQueryClient();
  const handleDeleteExceptionBrand = (el: any) => {
    mutation.mutate(el);
  };

  const mutation = useMutation(
    (el: any) => SizingRequest.deleteFormatSize(el.product_size_variant_id),
    {
      onMutate: () => {
        console.log("mutate delete product size");
      },
      onSettled: async (data: any, error: any) => {
        QueryClient.invalidateQueries("GetExceptionBrand");
      },
    }
  );

  return (
    <div className="border border-stroke rounded-md px-8 pt-6">
      <aside className="mb-6">
        <div>
          <input
            className="font-rom text-xl mb-2 w-full focus:outline-none"
            placeholder="Search"
          />
        </div>
        <hr />
      </aside>

      {data.data.map((el: any) => (
        <>
          <div className="flex justify-between my-3.5 items-center">
            <h1 className="text-lg">{el.product_brand_name}</h1>
            <div className="flex">
              <h1
                onClick={() => handleDeleteExceptionBrand(el)}
                className="cursor-pointer hover:text-primary font-rom text-sm"
              >
                Remove Sizing
              </h1>
              <h1
                onClick={() =>
                  history.push(
                    `/marketplace/sizing/edit-sizing/${params.product_category_id}/${params.product_brand_id}/exception-size/${el.product_brand_id}`
                  )
                }
                className="ml-3 cursor-pointer hover:text-primary font-rom text-sm"
              >
                Edit Sizing
              </h1>
            </div>
          </div>
          <hr />
        </>
      ))}
      <div className="mt-5">
        <Pagination
          length={data?.data?.length}
          total={data?.meta?.total}
          isTable={false}
        />
      </div>
    </div>
  );
};

export default ExceptionBrandList;
