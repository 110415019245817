import { createContext } from "react";
import { ISizingContext, ISizingState } from "./state";

export const initialSizingState: ISizingState = {
  showFilter: false,
  showSubBrand: {
    status: false,
    product_category_id: "0",
    product_brand_id: "0",
  },
  showExceptSubBrand: {
    status: false,
    product_category_id: "0",
    product_brand_id: "0",
  },
  chip: [],
};

const SizingContext = createContext<ISizingContext>({
  SizingState: initialSizingState,
  SizingDispatch: () => undefined,
});

export const SizingContextProvider = SizingContext.Provider;
export const SizingContextConsumer = SizingContext.Consumer;

export default SizingContext;
