import React from "react";
import { useQuery } from "react-query";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import axiosCelestine from "../../../config/AxiosCelestine";

//components
import CategoriesCard from "./CategoriesCard";
import Loading from "../../../components/loading/Loading";

const ProductCategoryDetail: React.FC = () => {

  const params: {
    id: string;
  } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const goAddProductCategories = () => {
    history.push(`${url}/add-categories`);
  };

  const getProductCategoryGroup = async () => {
    try {
      const results = await axiosCelestine().get(
        `/admin/product-category-group/${params.id}`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery(
    "getProductCategoryGroup",
    getProductCategoryGroup,
    { refetchOnWindowFocus: false }
  );

  const getCategories = async () => {
    try {
      const results = await axiosCelestine().get(`admin/special-category`, {
        params: {
          product_category_group_id: params.id,
        },
      });
      const result = results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: dataList,
    isLoading: isLoadingCategories,
    refetch,
  } = useQuery("getCategories", getCategories, { refetchOnWindowFocus: false });

  return (
    <div className="font-med">

      <div className="pt-28">
        {/* breadscrumb */}
        <div>
          <ul className="flex text-breadscrumbColor md:text-base text-xs">
            <li className="inline-flex items-center ">
              <Link to="/marketplace" className="hover:text-black">
                Home
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="hover:text-black"
                to="/marketplace/product-category-group"
              >
                Product Category Group
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <h1 className="text-black cursor-pointer">Categories</h1>
            </li>
          </ul>
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="text-3xl flex justify-center mt-16">
              <h1>{data?.name}</h1>
            </div>

            <div className="flex justify-end mt-10">
              <h1
                onClick={goAddProductCategories}
                className="text-xl text-primary cursor-pointer"
              >
                Add Categories
              </h1>
            </div>

            <div>
              <CategoriesCard
                isLoading={isLoadingCategories}
                refetch={refetch}
                dataList={dataList}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCategoryDetail;
