import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQueries } from "../../utils/query_searchParams/QuerySearchParams";

interface IPagination {
  length: number;
  total: number;
  isTable: boolean;
}

const Pagination: React.FC<IPagination> = ({ length, total, isTable }) => {
  const query = useQueries(useLocation);
  const history = useHistory();
  const { pathname, search, hash } = useLocation();
  const offsetParams = query.get("offset") || 0;
  const limitParams = query.get("limit") || 10;

  const handleDownPage = () => {
    let totalOffset = Number(offsetParams) - Number(limitParams);
    let new_search =
      offsetParams !== null && offsetParams !== 0
        ? search.replace(`offset=${offsetParams}`, `offset=${totalOffset}`)
        : search.length > 0
        ? search + `&&offset=${totalOffset}`
        : `?offset=${totalOffset}`;
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  const handleUpPage = () => {
    let totalOffset = Number(offsetParams) + Number(limitParams);
    let new_search = offsetParams
      ? search.replace(`offset=${offsetParams}`, `offset=${totalOffset}`)
      : search.length > 0
      ? search + `&&offset=${totalOffset}`
      : `offset=${totalOffset}`;
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  const setRows = (e: any) => {
    let value = e.target.value;
    let new_search =
      offsetParams !== null &&
      offsetParams !== 0 &&
      limitParams !== null &&
      limitParams !== 10
        ? search.replace(
            `limit=${limitParams}&&offset=${offsetParams}`,
            `limit=${value}&&offset=0`
          )
        : search.length > 0
        ? search + `&&limit=${value}&&offset=0`
        : `?limit=${value}&&offset=0`;
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  const handleToFirstPage = () => {
    let new_search: any =
      offsetParams !== null &&
      search.replace(`offset=${offsetParams}`, `offset=0`);
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  const handleToLastPage = () => {
    const summary =
      Number(limitParams) * Math.floor(total / Number(limitParams));
    let new_search =
      offsetParams !== null && offsetParams !== 0
        ? search.replace(`offset=${offsetParams}`, `offset=${summary}`)
        : `?offset=${summary}`;
    history.push({
      pathname,
      search: new_search,
      hash,
    });
  };

  return (
    <div
      className={
        "w-full flex justify-between items-center  " +
        (isTable ? "border-2 border-t-0 border-stroke px-8 h-20 " : "mb-4")
      }
    >
      <div className="flex items-center">
        <h1 className="text-base">Rows</h1>
        <select
          onChange={setRows}
          name="rows"
          value={limitParams || "10"}
          className="h-12 ml-4 focus:outline-none text-base cursor-pointer"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
        </select>
      </div>

      <div className="flex flex-col items-center">
        <div className="flex items-center">
          {Number(offsetParams) === 0 ? (
            <h1 className="text-gray-400 cursor-default mr-3">1</h1>
          ) : (
            <h1
              onClick={handleToFirstPage}
              className="cursor-pointer text-black hover:text-opacity-70  mr-3"
            >
              1
            </h1>
          )}
          <div className="h-12 w-12 mr-1 flex justify-center items-center rounded-full">
            {Number(offsetParams) === 0 ? (
              <h1 className="text-base text-gray-400 cursor-default">Prev</h1>
            ) : (
              <h1
                onClick={handleDownPage}
                className="text-base text-black cursor-pointer hover:text-opacity-70"
              >
                Prev
              </h1>
            )}
          </div>
          <div className="flex h-12 font-medium rounded-full">
            <div className="w-12 md:flex justify-center items-center hidden cursor-default leading-5 transition duration-150 ease-in rounded-full text-base">
              {Number(offsetParams) / Number(limitParams) + 1}
            </div>
          </div>
          <div className="h-12 w-12 ml-1 flex justify-center items-center rounded-full">
            {length === total - Number(offsetParams) ? (
              <h1 className="text-base text-gray-400 cursor-default">Next</h1>
            ) : (
              <h1
                onClick={handleUpPage}
                className="text-base text-black cursor-pointer hover:text-opacity-70"
              >
                Next
              </h1>
            )}
          </div>
          {length === total - Number(offsetParams) ? (
            <h1 className="text-gray-400 cursor-default ml-3">
              {`${Math.ceil(total / (limitParams as any))}`}
            </h1>
          ) : (
            <h1
              onClick={handleToLastPage}
              className="cursor-pointer text-black hover:text-opacity-70 ml-3"
            >
              {`${Math.ceil(total / Number(limitParams))}`}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
