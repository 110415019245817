import React, { useContext } from "react";
import { cancel } from "../../../../assets/icons";
import TagsContext from "../../../../store/tags/TagsContext";
import SmallButton from "../../../../components/button/SmallButton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TagsRequest } from "../../../../apis/tags/TagsRequest";
import Loading from "../../../../components/loading/Loading";
import { useParams } from "react-router-dom";

interface IProps {
  setShowFullTag: any;
  product: any;
}

const TagFullCard: React.FC<IProps> = ({ setShowFullTag, product }) => {
  const context = useContext(TagsContext);
  const { tagsState, tagsDispatch } = context;
  const { showFullTag } = tagsState;
  const params: { id: string } = useParams();

  const { data, isLoading } = useQuery(
    "getProductById",
    () => TagsRequest.getProductById(+product.value),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  const goBack = () => {
    setShowFullTag(false);
    // tagsDispatch({
    //   type: "show_full",
    //   payload: {
    //     id: 0,
    //     status: false,
    //   },
    // });
  };

  const queryClient = useQueryClient();
  const mutationTag = useMutation(
    (id: number) => TagsRequest.deleteTagFromProduct(id, data.id),
    {
      onMutate: () => {
        console.log("mutate");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getProductById");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const mutation = useMutation(
    (id: number) => TagsRequest.addProductToTagsContinue(id, params.id, setShowFullTag),
    {
      onMutate: () => {
        console.log("mutate");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getProductPerTag");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const handleDeleteTagFromProduct = (id: number) => {
    mutationTag.mutate(id);
  };

  const handleAddProduct = (id:number) => {
    mutation.mutate(id);
  };

  return (
    // showFullTag.status && (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-2/5 h-auto rounded-xl my-auto mx-auto font-med">
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-44">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="flex w-full justify-between items-center px-8 pb-5 pt-7">
              <div className="flex">
                <h1 className="text-2xl font-med">Tag</h1>
                <h1 className="text-2xl font-med text-primary ml-1.5">Full</h1>
              </div>
              <img
                src={cancel}
                alt="cancel"
                className="cursor-pointer w-5"
                onClick={goBack}
              />
            </div>
            <hr />
            <div className="px-8">
              <div className="flex justify-start">
                <h1 className="text-sm text-textPrimary mt-4 font-rom">
                  Please remove a tag
                </h1>
              </div>

              <div className="border-2 rounded-md my-2">
                <div className="flex items-start px-8 pt-8">
                  <img
                    src={data.display_image_url}
                    className="w-20 border p-3 rounded-md"
                    alt="img"
                  />
                  <div className="mx-7">
                    <h1 className="font-rom">SKU: {data.code}</h1>
                    <h1 className="font-med text-xl mt-1.5">{data.name}</h1>
                  </div>
                </div>

                <div className="my-4 flex items-center px-8 py-3">
                  <h1 className="font-rom">Tags:</h1>
                  <div className="md:flex hidden">
                    {data.tags.map((el: any) => (
                      <div className="inline-flex items-center rounded ml-3 bg-white border-2 border-stroke p-1">
                        <span className="px-2 text-sm">{el.name}</span>
                        <button
                          type="button"
                          className="h-6 w-6 p-1 focus:outline-none"
                        >
                          <img
                            onClick={() => handleDeleteTagFromProduct(el.id)}
                            src={cancel}
                            alt="cancel"
                            className="cursor-pointer w-2.5"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex justify-center my-8 outline-none">
                <SmallButton
                  loadColor={"white"}
                  type={"button"}
                  handleClick={() => handleAddProduct(data.id)}
                  color={"bg-primary"}
                  hoverColor={"bg-red-300"}
                  text={"Continue"}
                  textColor={"text-white"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    // )
  );
};

export default TagFullCard;
