import { useQuery } from "react-query";
import { InvoiceRequest } from "../../../../apis/invoice/InvoiceRequest";
import LocalStorage from "../../../../config/LocalStorage";

const GetWaitingInvoiceId = (id: number) => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "admin";
  const { data, isLoading } = useQuery([`GetWaitingInvoiceId-${id}`], () =>
    InvoiceRequest.getInvoiceWaitingById(id, role)
  );
  return {
    data,
    isLoading,
  };
};

export default GetWaitingInvoiceId;
