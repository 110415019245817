import { useHistory, useRouteMatch } from "react-router-dom";
import { drag } from "../../../assets/icons";

interface IProps {
  item: any;
  handleShowEdit: any;
}

const ListElementProductCategoryGroup: React.FC<IProps> = (
  item,
  handleShowEdit
) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  return (
    <div className="md:w-productCategory md:max-w-md lg:w-productCategory lg:max-w-xl xl:w-productCategoryxl xl:max-w-customxl 2xl:w-productCategory2xl 2xl:max-w-2xl 3xl:w-productCategory3xl 3xl:max-w-3xl xl:h-64 md:h-52 my-5 xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke">
      <div className="flex justify-between items-start w-full h-2/3 p-6">
        <div className="flex xl:max-w-md xl:w-productCategory md:max-w-xs">
          <img
            src={item.item.image_path}
            className="xl:w-32 xl:h-32 md:w-24 md:h-24 border-2 border-stroke rounded-xl"
            alt="logo_path"
          />

          <div className="ml-5 flex flex-col justify-around">
            <h1 className="xl:text-xl md:text-base">{item.item.name}</h1>
            <h1 className="xl:text-base md:text-xs xl:mt-3 md:mt-1 font-rom">
              Priority : {item.item.priority}
            </h1>
            <div className="flex">
              <h1 className="mr-1 xl:text-base md:text-xs">Status :</h1>{" "}
              {item.item.is_active ? (
                <h1 className="text-success xl:text-base md:text-xs">Posted</h1>
              ) : (
                <h1 className="text-primary  xl:text-base md:text-xs">
                  Not Posted
                </h1>
              )}
            </div>
            <div className="flex">
              <h1 className="mr-1 xl:text-base md:text-xs">
                Post in legit check :{" "}
              </h1>{" "}
              {item.item.is_active_legit_check ? (
                <h1 className="text-success xl:text-base md:text-xs">Posted</h1>
              ) : (
                <h1 className="text-primary xl:text-base md:text-xs">
                  Not Posted
                </h1>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center ">
          <img src={drag} className="md:w-4 xl:w-6" alt="drag" />
        </div>
      </div>

      <div className="flex justify-around xl:text-base md:text-sm w-full h-1/4 mt-5">
        <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1
            onClick={() =>
              history.push(`${url}/categories/${item.item.id}`, item.item.name)
            }
            className="hover:text-primary"
          >
            Detail
          </h1>
        </div>
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1
            onClick={() => item.handleShowEdit(item.item.id)}
            className="hover:text-primary"
          >
            Edit
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ListElementProductCategoryGroup;
