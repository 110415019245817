import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { CreditEffectRequest } from "../../../apis/credit_effect/CreditEffectRequest";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../../components/toast/ErrorHandler";
import moment from "moment";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash.isempty";
import axiosDicket from "../../../config/AxiosDicket";

export enum scope {
  MARKETPLACE = "marketplace",
  RETAIL = "retail",
}

export type ICheckType = {
  min_purchase_active: boolean;
  min_quantity_active: boolean;
  payment_method_active: boolean;
  brand_active: boolean;
  product_active: boolean;
  tag_active: boolean;
  store_active: boolean;
  retail_product_active: boolean;
  ticket_active: boolean;
};

type FormType = {
  form_type: string;
  name: string;
  is_active: boolean;
  scope: string;
  valid_time_from: string;
  valid_time_until: string;
  results: { quantity: string; campaign_id: string }[];
  min_quantity: string;
  min_subtotal: string;
  check: ICheckType;
  payment_methods: { label: string; value: number }[];
  retail_products: { label: string; value: number; img: string }[];
  market_product_brands: { label: string; value: number; img: string }[];
  market_products: { label: string; value: number; img: string }[];
  market_tags: { label: string; value: number }[];
  retail_shops: { label: string; value: number; img: string }[];
  retail_ticket_bundles: { label: string; value: number };
  retail_tickets: { label: string; value: number }[];
};

const FormControlCreditEffect = (
  type: "Add" | "Edit",
  data?: any,
  id?: string
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [check, setCheck] = useState<ICheckType>({
    min_purchase_active: false,
    min_quantity_active: false,
    payment_method_active: false,
    brand_active: false,
    product_active: false,
    tag_active: false,
    store_active: false,
    retail_product_active: false,
    ticket_active: false,
  });
  const [variantOpt, setVariantOpt] = useState([]);

  useEffect(() => {
    setValues({ ...values, check: check });
  }, [check]);

  useEffect(() => {
    if (data && !isEmpty(data)) {
      setCheck({
        ...check,
        min_purchase_active: +data.min_subtotal > 0 ? true : false,
        min_quantity_active: +data.min_quantity > 0 ? true : false,
        payment_method_active: data.payment_methods.length > 0 ? true : false,
        brand_active:
          data.market_product_brands.length > 0 &&
          data.scope === scope.MARKETPLACE
            ? true
            : false,
        product_active:
          data.market_products.length > 0 && data.scope === scope.MARKETPLACE
            ? true
            : false,
        tag_active:
          data.market_tags.length > 0 && data.scope === scope.MARKETPLACE
            ? true
            : false,
        retail_product_active:
          data.retail_products.length > 0 && data.scope === scope.RETAIL
            ? true
            : false,
        store_active:
          data.retail_shops.length > 0 && data.scope === scope.RETAIL
            ? true
            : false,
        ticket_active:
          !isEmpty(data.retail_ticket_bundles) && data.scope === scope.RETAIL
            ? true
            : false,
      });
      setValues({
        ...values,
        form_type: type,
        name: data.name,
        is_active: data.is_active,
        scope: data.scope,
        valid_time_from: data.valid_time_from,
        valid_time_until: data.valid_time_until,
        results: data.results,
        min_subtotal: +data.min_subtotal > 0 ? data.min_subtotal : "",
        min_quantity: +data.min_quantity > 0 ? data.min_quantity : "",
        payment_methods:
          data.payment_methods.length > 0
            ? data.payment_methods.map((el: any) => ({
                label: el,
                value: el,
              }))
            : [],
        market_product_brands:
          data.market_product_brands.length > 0
            ? data.market_product_brands.map((el: any) => ({
                label: el.name,
                value: el.id,
                img: el.image_path,
              }))
            : [],
        market_products:
          data.market_products.length > 0
            ? data.market_products.map((el: any) => ({
                label: el.name,
                value: el.id,
                img: el.display_image_url,
              }))
            : [],
        retail_products:
          data.retail_products.length > 0
            ? data.retail_products.map((el: any) => ({
                label: el.name,
                value: el.id,
                img: el.display_image_path,
              }))
            : [],
        retail_shops:
          data.retail_shops.length > 0
            ? data.retail_shops.map((el: any) => ({
                label: el.name,
                value: el.id,
                img: el.logo_path,
              }))
            : [],
        retail_ticket_bundles: !isEmpty(data.retail_ticket_bundles)
          ? {
              value: data.retail_ticket_bundles[0].id,
              label: data.retail_ticket_bundles[0].name,
            }
          : { value: 0, label: "" },
        retail_tickets: !isEmpty(data.retail_tickets)
          ? data.retail_tickets.map((el: any) => ({
              label: el.label,
              value: el.id,
            }))
          : [],
        market_tags:
          data.market_tags.length > 0
            ? data.market_tags.map((el: any) => ({
                label: el.name,
                value: el.id,
              }))
            : [],
      });
    }
  }, [data]);

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    check: ICheckType,
    setCheck: any
  ) => {
    switch (event.target.name) {
      case "product_active":
        setCheck({
          ...check,
          [event.target.name]: event.target.checked,
          brand_active: false,
          tag_active: false,
        });
        setValues({
          ...values,
          market_product_brands: [],
          market_tags: [],
          retail_products: [],
          retail_shops: [],
          retail_ticket_bundles: { value: 0, label: "" },
          retail_tickets: [],
        });
        break;
      case "brand_active":
        setCheck({
          ...check,
          [event.target.name]: event.target.checked,
          product_active: false,
          tag_active: false,
        });
        setValues({
          ...values,
          market_products: [],
          market_tags: [],
          retail_products: [],
          retail_shops: [],
          retail_ticket_bundles: { value: 0, label: "" },
          retail_tickets: [],
        });
        break;
      case "tag_active":
        setCheck({
          ...check,
          [event.target.name]: event.target.checked,
          product_active: false,
          brand_active: false,
        });
        setValues({
          ...values,
          market_product_brands: [],
          market_tags: [],
          retail_products: [],
          retail_shops: [],
          retail_ticket_bundles: { value: 0, label: "" },
          retail_tickets: [],
        });
        break;
      case "store_active":
        setCheck({
          ...check,
          [event.target.name]: event.target.checked,
          retail_product_active: false,
          ticket_active: false,
        });
        setValues({
          ...values,
          market_products: [],
          market_tags: [],
          market_product_brands: [],
          retail_products: [],
          retail_ticket_bundles: { value: 0, label: "" },
          retail_tickets: [],
        });
        break;
      case "retail_product_active":
        setCheck({
          ...check,
          [event.target.name]: event.target.checked,
          store_active: false,
          ticket_active: false,
        });
        setValues({
          ...values,
          market_products: [],
          market_tags: [],
          market_product_brands: [],
          retail_shops: [],
          retail_ticket_bundles: { value: 0, label: "" },
          retail_tickets: [],
        });
        break;
      case "ticket_active":
        setCheck({
          ...check,
          [event.target.name]: event.target.checked,
          store_active: false,
          retail_product_active: false,
        });
        setValues({
          ...values,
          market_products: [],
          market_tags: [],
          market_product_brands: [],
          retail_products: [],
          retail_shops: [],
        });
        break;
      default:
        setCheck({ ...check, [event.target.name]: event.target.checked });
        break;
    }
  };

  const loadTicketVariant = async (s: string, ticket_bundle_id: number) => {
    try {
      const { data } = await axiosDicket().get("admin/ticket/", {
        params: {
          search: s,
          limit: 50,
          ticket_bundle_id,
        },
      });
      const option = await data.data.map((el: any) => ({
        label: el.label,
        value: el.id,
      }));
      return setVariantOpt(option);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCreditReward = () => {
    const new_credit = {
      campaign_id: "",
      quantity: "",
    };
    setValues({
      ...values,
      results: values.results.concat(new_credit),
    });
  };

  const handleChangeQuantity = (e: any, idx: number) => {
    const new_credit = [...values.results];
    new_credit[idx].quantity = e.target.value;
    setValues({ ...values, results: new_credit });
  };

  const handleChangeCampaign = (e: any, idx: number) => {
    const new_credit = [...values.results];
    new_credit[idx].campaign_id = e.value;
    setValues({ ...values, results: new_credit });
  };

  const handleChangeScope = (e: any) => {
    setValues({ ...values, scope: e.target.value });
  };

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
  } = useFormik<FormType>({
    initialValues: {
      form_type: "add",
      name: "",
      is_active: true,
      scope: "marketplace",
      valid_time_from: "",
      valid_time_until: "",
      results: [
        {
          campaign_id: "",
          quantity: "",
        },
      ],
      min_quantity: "",
      min_subtotal: "",
      payment_methods: [],
      market_product_brands: [],
      market_products: [],
      market_tags: [],
      retail_shops: [],
      retail_products: [],
      retail_ticket_bundles: { value: 0, label: "" },
      retail_tickets: [],
      check: check,
    },
    validationSchema: Yup.object({
      // results: Yup.array().of(
      //   Yup.object().shape({
      //     campaign_id: Yup.string().required(
      //       t("creditEffect.errorMessageCreditEffect.campaign")
      //     ),
      //     quantity: Yup.string()
      //       .required(t("creditEffect.errorMessageCreditEffect.quantity"))
      //       .matches(/^[0-9]+$/, t("common.validationInput.number")),
      //   })
      // ),
      name: Yup.string().required(
        t("creditEffect.errorMessageCreditEffect.name")
      ),
      valid_time_from: Yup.string().required(
        t("creditEffect.errorMessageCreditEffect.valid_time_from")
      ),
      valid_time_until: Yup.string().required(
        t("creditEffect.errorMessageCreditEffect.valid_time_until")
      ),
      min_subtotal: Yup.string().when("check.min_purchase_active", {
        is: true,
        then: Yup.string().required(
          t("creditEffect.errorMessageCreditEffect.min_purchase")
        ),
      }),
      min_quantity: Yup.string().when(["check.min_quantity_active", "scope"], {
        is: (check: boolean, scp: string) =>
          check === true && scp === scope.RETAIL,
        then: Yup.string().required(
          t("creditEffect.errorMessageCreditEffect.min_quantity")
        ),
      }),
      payment_methods: Yup.array().when("check.payment_method_active", {
        is: true,
        then: Yup.array().min(
          1,
          t("creditEffect.errorMessageCreditEffect.payment_methods")
        ),
      }),
      market_product_brands: Yup.array().when(["check.brand_active", "scope"], {
        is: (check: boolean, scp: string) =>
          check === true && scp === scope.MARKETPLACE,
        then: Yup.array().min(
          1,
          t("creditEffect.errorMessageCreditEffect.market_product_brands")
        ),
      }),
      market_products: Yup.array().when(["check.product_active", "scope"], {
        is: (check: boolean, scp: string) =>
          check === true && scp === scope.MARKETPLACE,
        then: Yup.array().min(
          1,
          t("creditEffect.errorMessageCreditEffect.market_products")
        ),
      }),
      market_tags: Yup.array().when(["check.tag_active", "scope"], {
        is: (check: boolean, scp: string) =>
          check === true && scp === scope.MARKETPLACE,
        then: Yup.array().min(
          1,
          t("creditEffect.errorMessageCreditEffect.market_tags")
        ),
      }),
      retail_shops: Yup.array().when(["check.store_active", "scope"], {
        is: (check: boolean, scp: string) =>
          check === true && scp === scope.RETAIL,
        then: Yup.array().min(
          1,
          t("creditEffect.errorMessageCreditEffect.retail_shops")
        ),
      }),
      retail_products: Yup.array().when(
        ["check.retail_product_active", "scope"],
        {
          is: (check: boolean, scp: string) =>
            check === true && scp === scope.RETAIL,
          then: Yup.array().min(
            1,
            t("creditEffect.errorMessageCreditEffect.retail_products")
          ),
        }
      ),
      retail_ticket_bundles: Yup.object().when(
        ["check.ticket_active", "scope"],
        {
          is: (check: boolean, scp: string) =>
            check === true && scp === scope.RETAIL,
          then: Yup.object().shape({
            value: Yup.number().min(
              1,
              t("creditEffect.errorMessageCreditEffect.retail_ticket_bundles")
            ),
          }),
        }
      ),
    }),
    onSubmit: async () => {
      const payload: any = {};
      payload["name"] = values.name;
      payload["is_active"] = values.is_active;
      payload["scope"] = values.scope;
      payload["valid_time_from"] = moment(values.valid_time_from).toISOString();
      payload["valid_time_until"] = moment(
        values.valid_time_until
      ).toISOString();
      payload["results"] = values.results;

      if (check.min_purchase_active) {
        payload["min_subtotal"] =
          typeof values.min_subtotal === "number"
            ? values.min_subtotal
            : Number(
                values.min_subtotal.substring(4).split(",").join("").trim()
              );
      }
      if (check.min_quantity_active && values.scope === scope.RETAIL) {
        payload["min_quantity"] = +values.min_quantity;
      }
      if (check.payment_method_active) {
        payload["payment_methods"] = values.payment_methods.map(
          (el: any) => el.value
        );
      }
      if (check.product_active && values.scope === scope.MARKETPLACE) {
        payload["market_product_ids"] = values.market_products.map(
          (el: any) => el.value
        );
      }
      if (check.tag_active && values.scope === scope.MARKETPLACE) {
        payload["market_tag_ids"] = values.market_tags.map(
          (el: any) => el.value
        );
      }
      if (check.brand_active && values.scope === scope.MARKETPLACE) {
        payload["market_product_brand_ids"] = values.market_product_brands.map(
          (el: any) => el.value
        );
      }
      if (check.retail_product_active && values.scope === scope.RETAIL) {
        payload["retail_product_ids"] = values.retail_products.map(
          (el: any) => el.value
        );
      }
      if (check.store_active && values.scope === scope.RETAIL) {
        payload["retail_shop_ids"] = values.retail_shops.map(
          (el: any) => el.value
        );
      }
      if (check.ticket_active && values.scope === scope.RETAIL) {
        payload["retail_ticket_bundle_ids"] = [
          values.retail_ticket_bundles.value,
        ];
      }
      if (
        check.ticket_active &&
        values.scope === scope.RETAIL &&
        !isEmpty(values.retail_tickets)
      ) {
        payload["retail_ticket_ids"] = values.retail_tickets.map(
          (el: any) => el.value
        );
      }
      await mutation.mutate(payload);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload) =>
      type === "Edit"
        ? CreditEffectRequest.editCreditEffect(payload, id)
        : CreditEffectRequest.addCreditEffect(payload),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("getCreditEffect");
          ErrorHandler("#00C62E", `${type} Credit Effect Completed`);
          history.goBack();
        }
      },
    }
  );

  return {
    values,
    setValues,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
    check,
    setCheck,
    mutation,
    handleChangeChecked,
    handleAddCreditReward,
    handleChangeCampaign,
    handleChangeScope,
    handleChangeQuantity,
    variantOpt,
    setVariantOpt,
    loadTicketVariant,
  };
};

export default FormControlCreditEffect;
