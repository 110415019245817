export const TagsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_add":
      let showAdd = state.showAdd;
      document.body.classList[!showAdd ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAdd: !showAdd };
    case "show_filter":
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case "show_full":
      let edit = action.payload;
      document.body.classList[edit.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFull: edit };
    case "show_delete":
      let deleted = action.payload;
      document.body.classList[deleted.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDelete: deleted };
    case "handle_asc":
      let new_asc = action.payload;
      return { ...state, isAsc: new_asc };
    case "handle_sort":
      let new_sort = action.payload;
      return { ...state, sortValue: new_sort };
    case "handle_limit":
      let new_limit = action.payload;
      return { ...state, limit: new_limit };
    case "handle_offset":
      let new_offset = action.payload;
      return { ...state, offset: new_offset };
    case "handle_filter":
      let new_filter = action.payload;
      return { ...state, filter: new_filter };
    case "reset_filter":
      let reset_filter = {
        name: "",
        status: "",
      };
      return { ...state, filter: reset_filter };
    case "handle_chip":
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
