import React, { useReducer } from "react";
import { useQuery } from "react-query";
import { TagsRequest } from "../../apis/tags/TagsRequest";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../components/chip/Chip";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import NonTablePagination from "../../components/pagination/NonTablePagination";
import Sorting from "../../components/sorting/Sorting";
import {
  initialTagsState,
  TagsContextProvider,
} from "../../store/tags/TagsContext";
import { TagsReducer } from "../../store/tags/TagsReducer";
import { optRoutes, optSorting } from "./helpers/list";
import { default_sort } from "./helpers/setDefaultSort";
import AddTags from "./tags_component/Add_tags";
import DeleteTags from "./tags_component/Delete_tags";
import TagsCard from "./tags_component/Tags_card";
import TagsFilter from "./tags_component/Tags_filter";

const Tags = () => {
  const [tagsState, tagsDispatch] = useReducer(TagsReducer, initialTagsState);
  const {
    isAsc,
    sortValue,
    filter,
    limit,
    offset,
    showFilter,
    showDelete,
    showAdd
  } = tagsState;

  const { data, isLoading, isFetching } = useQuery(
    ["getTags", sortValue, isAsc, limit, offset, filter],
    () =>
      TagsRequest.getTags(
        "getTags",
        sortValue,
        isAsc,
        limit,
        offset,
        filter,
        tagsDispatch
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <TagsContextProvider value={{ tagsDispatch, tagsState }}>
      <div className="md:pt-28 sm:pt-20 pt-16">
        <DynamicBreadcrumb data={optRoutes} />
        
        {showAdd && <AddTags />}
        {showDelete?.status && <DeleteTags />}
        {showFilter && <TagsFilter />}

        <div className="md:mt-12 mt-10">
          <div className="flex justify-end">
            <h1
              onClick={() => tagsDispatch({ type: "show_add" })}
              className="text-primary md:text-base cursor-pointer inline-flex"
            >
              Add Tags
            </h1>
          </div>

          <div className="flex justify-between mt-4">
            <div className="flex">
              <h1
                onClick={() => tagsDispatch({ type: "show_filter" })}
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <Sorting
              dispatchContext={tagsDispatch}
              stateContext={tagsState}
              option={optSorting}
              defaultSort={default_sort}
            />
          </div>

          <Chip dispatchContext={tagsDispatch} stateContext={tagsState} />
          <div className="flex justify-end my-4">
            {isFetching ? (
              <div className="flex items-center">
                <CircularLoad color="#DF5354" height={20} width={20} />
                <h1 className="ml-2">Updating</h1>
              </div>
            ) : (
              <h1>Auto Update</h1>
            )}
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <Loading />
            </div>
          ) : (
            <div className="grid md:grid-cols-2 grid-cols-1 w-full md:gap-10 gap-5 my-10">
              {data?.data.map((el: any) => (
                <TagsCard data={el} key={el.id} />
              ))}
            </div>
          )}
          <NonTablePagination
            offset={offset}
            limit={limit}
            total={data?.meta?.total}
            length={data?.data?.length}
            dispatchContext={tagsDispatch}
          />
        </div>
      </div>
    </TagsContextProvider>
  );
};

export default Tags;
