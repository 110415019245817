import { collection, onSnapshot } from "@firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import { cancel } from "../../../../../assets/icons";
import Decrease from "../../../../../assets/icons/decrease";
import Increase from "../../../../../assets/icons/increase";
import SmallButton from "../../../../../components/button/SmallButton";
import Loading from "../../../../../components/loading/Loading";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import db from "../../../../../config/InitFirebase";
import { ScopeType } from "../../../helpers/list";
import isEmpty from "lodash.isempty";
import LocalStorage from "../../../../../config/LocalStorage";

const AddQuantityShuffle: React.FC = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const { showAddQuantityShuffle, data_spinner } = CampaignState;
  const { id } = showAddQuantityShuffle;
  const [winner, setWinner] = useState<number>(0);
  const { data: dataTemplate, isLoading } = GetCampaignTemplateId(
    id?.toString()
  );
  const max =
    dataTemplate?.available_shuffle_count >= 0
      ? dataTemplate?.available_shuffle_count
      : 0;
  const min = 0;
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  const getSnapshot = () => {
    return onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === id &&
              doc.campaign_id === +params.id
          )[0];
        CampaignDispatch({
          type: ActionType.AddDataSpinner,
          payload: data,
        });
      }
    );
  };

  useEffect(() => {
    getSnapshot();
  }, []);

  const goBack = () => {
    CampaignDispatch({
      type: ActionType.ShowAddQuantityShuffle,
      payload: { status: false, id: 0 },
    });
  };

  const handleIncreaseWinner = () => {
    if (winner < max) {
      setWinner(winner + 1);
    }
  };

  const handleDecreaseWinner = () => {
    if (winner > min) setWinner(winner - 1);
  };

  const mutation = useMutation(
    () =>
      CampaignRequest.ShuffleRaffle(
        {
          shuffle_count: winner,
          campaign_product_template_id: id,
        },
        role
      ),
    {
      onMutate: () => {
        console.log("mutate add shuffle");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data && data_spinner.batch_count === 0) {
          await getSnapshot();
          setTimeout(() => {
            goBack();
            history.push(
              `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${id}/${winner}`
            );
          }, 1000);
        } else if (data && data_spinner.batch_count > 0) {
          await getSnapshot();
          await goBack();
          await history.push(
            `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${id}/shuffle/${winner}/0`
          );
        }
      },
    }
  );

  const mutationTopCredit = useMutation(
    () =>
      CampaignRequest.getWinnerTopCredit(
        {
          winner_candidate_count: winner,
          campaign_product_template_id: id,
        },
        role
      ),
    {
      onMutate: () => {
        console.log("mutate get winner top credit");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (!isEmpty(data) && data_spinner.batch_count === 1) {
          await getSnapshot();
          await goBack();
          await history.push(
            `/marketplace/campaign/edit-campaign/${params.id}/pre-announce-winner/${dataTemplate.id}`
          );
        } else {
          await getSnapshot();
          await goBack();
          await history.push(
            `/marketplace/campaign/edit-campaign/${params.id}/announce-winner/${dataTemplate.id}`
          );
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-10/12 md:w-1/3 xl:w-1/4 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        {isLoading ? (
          <div className="flex justify-center mt-20 mb-28">
            <Loading />
          </div>
        ) : (
          <>
            <div className="px-20 pb-3">
              <div className="flex flex-col items-center">
                <h1 className="text-xl">How many winner do you want ?</h1>
              </div>
              <section className="flex w-full justify-between items-center my-7">
                <div>
                  <h1 className="text-textPrimary text-lg">Quantity:</h1>
                </div>
                <div className="flex justify-center items-center">
                  <div
                    onClick={handleDecreaseWinner}
                    className={
                      winner === min ? "cursor-not-allowed" : "cursor-pointer"
                    }
                  >
                    <Decrease color={winner === min ? "#EFEFEF" : "#ED4846"} />
                  </div>
                  <div className="mx-8 text-xl">{winner}</div>
                  <div
                    onClick={handleIncreaseWinner}
                    className={
                      winner === max ? "cursor-not-allowed" : "cursor-pointer"
                    }
                  >
                    <Increase color={winner === max ? "#EFEFEF" : "#ED4846"} />
                  </div>
                </div>
              </section>
              <div className="flex justify-center">
                <h1>Max Winner: {max} |</h1>
                <h1
                  onClick={() => setWinner(max)}
                  className="text-primary ml-1 cursor-pointer"
                >
                  Use All
                </h1>
              </div>
            </div>
            <hr />
            <div className="flex justify-center my-7 outline-none">
              <SmallButton
                loadColor={"white"}
                type={"button"}
                isSubmitting={
                  dataTemplate.scope === ScopeType.GIVEAWAYRAFFLE ||
                  dataTemplate.scope === ScopeType.SPECIALPRICERAFFLE
                    ? mutation.isLoading
                    : mutationTopCredit.isLoading
                }
                handleClick={
                  dataTemplate.scope === ScopeType.GIVEAWAYRAFFLE ||
                  dataTemplate.scope === ScopeType.SPECIALPRICERAFFLE
                    ? () => mutation.mutate()
                    : () => mutationTopCredit.mutate()
                }
                color={"bg-primary"}
                hoverColor={"opacity-70"}
                text={"Continue"}
                textColor={"text-white"}
                height={10}
              />
              <div className="mx-1.5"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddQuantityShuffle;
