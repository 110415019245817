export const isAppValidator = (type: string) => {
  const temp = Number(type);
  if (
    temp === 0 ||
    temp === 1 ||
    temp === 2 ||
    temp === 3 ||
    temp === 4 ||
    temp === 8 ||
    temp === 9
  ) {
    return true;
  } else {
    return false;
  }
};
