import React, { useContext } from "react";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import NewSorting from "../../components/sorting/NewSorting";
import LocalStorage from "../../config/LocalStorage";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import { ActionType } from "../../store/campaign/action";
import CampaignContext from "../../store/campaign/CampaignContext";
import { validateRole } from "../../utils/checkRole/CheckRole";
import AddCampaignModal from "./components/campaign/AddCampaignModal";
import CampaignCard from "./components/campaign/CampaignCard";
import DeleteCampaignModal from "./components/campaign/DeleteCampaignModal";
import { optRoutesCampaign, optSortingCampaign } from "./helpers/list";
import GetCampaign from "./helpers/use_query_hook/GetCampaign";

const Campaign = () => {
  const { data, isFetching, isLoading } = GetCampaign();
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const { showAddCampaign, showDeleteCampaign } = CampaignState;
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  return (
    <div>
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesCampaign} />
        {showAddCampaign && <AddCampaignModal />}
        {showDeleteCampaign.status && <DeleteCampaignModal />}
        <div className="md:mt-12 mt-10">
          {validateRole(role, [
            AcceptedRole.Admin,
            AcceptedRole.Operation,
            AcceptedRole.Product,
            AcceptedRole.Marketing,
          ]) && (
            <div className="flex justify-end">
              <h1
                onClick={() =>
                  CampaignDispatch({
                    type: ActionType.ShowAddCampaign,
                  })
                }
                className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
              >
                Add Campaign
              </h1>
            </div>
          )}

          <div className="flex justify-between mt-2">
            <div className="flex">
              <h1 className="text-base mr-5 cursor-pointer hover:text-primary">
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingCampaign} />
          </div>
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            {data?.data.map((el: any) => (
              <CampaignCard data={el} key={el.id} />
            ))}
          </div>
        )}

        <Pagination
          length={data?.data?.length}
          total={data?.meta?.total}
          isTable={false}
        />
      </section>
    </div>
  );
};

export default Campaign;
