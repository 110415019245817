import { createContext } from "react";
import { IGlobalContext, IGlobalState } from "./state";

export const initialGlobalState: IGlobalState = {
  lang: "en",
};

const GlobalContext = createContext<IGlobalContext>({
  GlobalState: initialGlobalState,
  GlobalDispatch: () => undefined,
});

export const GlobalContextProvider = GlobalContext.Provider;
export const GlobalContextConsumer = GlobalContext.Consumer;

export default GlobalContext;
