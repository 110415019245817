import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import { queryparamsSizing } from "../../pages/sizing/helpers/list";
import { ActionType } from "../../store/sizing/action";
import ApplyChipTS from "../../utils/applyChip/ApplyChipTS";

export class SizingRequest {
  static getSizing = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(`admin/product-size`, {
        params: {
          group: true,
          ...(query.get("product_category") !== null && {
            product_category_id: query.get("product_category"),
          }),
          ...(query.get("gender") !== null && {
            gender: query.get("gender"),
          }),
          ...(query.get("add_time_from") !== null &&
          query.get("add_time_to") !== null
            ? {
                created_at: `${query.get("add_time_from")},${query.get(
                  "add_time_to"
                )}`,
              }
            : ""),
          ...(query.get("add_time_from") !== null &&
          query.get("add_time_to") === null
            ? { created_at: `${query.get("add_time_from")},2100-01-01` }
            : ""),
          ...(query.get("add_time_from") === null &&
          query.get("add_time_to") !== null
            ? { created_at: `2000-01-01,${query.get("add_time_to")}` }
            : ""),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      ApplyChipTS(queryparamsSizing, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getSizeVariant = async (
    gender: string,
    product_brand_id: string,
    product_category_id: string
  ) => {
    try {
      const results = await axiosCelestine().get(`admin/product-size-variant`, {
        params: {
          gender,
          product_brand_id,
          product_category_id,
        },
      });
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getSizingGroup = async (
    product_category_id: string,
    product_brand_id: string
  ) => {
    try {
      const results = await axiosCelestine().get(`admin/product-size/group`, {
        params: {
          group: true,
          product_category_id,
          product_brand_id,
        },
      });
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error.response.data.errors);
      // ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getExceptionBrand = async (
    product_category_id: string,
    product_brand_id: string
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(
        `admin/product-size-variant/exception_brand`,
        {
          params: {
            product_category_id,
            product_brand_id,
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error.response.data.errors);
      // ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static GetBrand = async (
    product_category_id: string,
    product_brand_id: string
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(
        `admin/product-size-variant/brand`,
        {
          params: {
            product_category_id,
            product_brand_id,
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error.response.data.errors);
      // ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static GetSubBrand = async (
    product_category_id: string,
    product_brand_id: string
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCelestine().get(
        `admin/product-size-variant/sub_brand`,
        {
          params: {
            product_category_id,
            product_brand_id,
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
          },
        }
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error.response.data.errors);
      // ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addSizingVariant = async (
    payload: any,
    parentPayload: any,
    formatSize: any,
    formatSizeOrderInput: any
  ) => {
    const data = {
      display_variant_id: payload.display_variant_id.toString(),
      gender: payload.gender,
      description: parentPayload.description,
      product_brand_id: parentPayload.product_brand.value,
      product_category_id: parentPayload.product_category.value,
      variants: formatSize,
      variant_orders: formatSizeOrderInput,
    };

    try {
      const results = await axiosCelestine().post(
        `admin/product-size-variant/`,
        data
      );
      ErrorHandler("#00C62E", "Add Format Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static addSizingVariantMultiple = async (payload: any) => {
    try {
      const results = await axiosCelestine().post(
        `admin/product-size-variant/multiple`,
        { data: payload }
      );
      ErrorHandler("#00C62E", "Add Format Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static editSizingVariant = async (
    payload: any,
    parentPayload: any,
    formatSizeInput: any,
    formatSizeOrderInput: any
  ) => {
    const data = {
      display_variant_id: payload.display_variant_id,
      gender: payload.gender,
      description: parentPayload.description,
      product_brand_id: parentPayload.product_brand.value,
      product_category_id: parentPayload.product_category.value,
      variants: formatSizeInput,
      variant_orders: formatSizeOrderInput,
    };
    try {
      const results = await axiosCelestine().patch(
        `admin/product-size-variant/${payload.id}`,
        data
      );
      ErrorHandler("#00C62E", "Add Format Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static deleteSizingVariantMultiple = async (
    parentForm: any,
    values: any,
    formatSizeInput: any,
    formatSizeOrderInput: any
  ) => {
    try {
      const results = await axiosCelestine().patch(
        `admin/product-size-variant/multiple`,
        {
          data: [
            {
              display_variant_id: values.display_variant_id,
              gender: values.gender,
              id: values.id,
              product_brand_id: parentForm.product_brand.value,
              product_category_id: parentForm.product_category.value,
              variant_orders: formatSizeOrderInput,
              variants: formatSizeInput,
            },
          ],
        }
      );
      ErrorHandler("#00C62E", "Delete Format Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Delete Failed");
      }
    }
  };

  static editSizingVariantMultiple = async (payload: any) => {
    try {
      const results = await axiosCelestine().patch(
        `admin/product-size-variant/multiple`,
        {
          data: payload,
        }
      );
      ErrorHandler("#00C62E", "Add Format Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static addProductSize = async (payload: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const results = await axiosCelestine().post(
        `admin/product-size/multiple`,
        { data: payload }
      );
      setSubmitting(false);
      ErrorHandler("#00C62E", "Add Product Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Add Product Size Failed"
        );
      }
    }
  };

  static EditProductSize = async (payload: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const results = await axiosCelestine().patch(
        `admin/product-size/multiple`,
        { data: payload }
      );
      setSubmitting(false);
      ErrorHandler("#00C62E", "Edit Product Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Product Size Failed"
        );
      }
    }
  };

  static EditProductSizeOrder = async (
    payload: any,
    data: any,
    parentData: any
  ) => {
    try {
      const results = await axiosCelestine().patch(
        `admin/product-size-variant/${data.id}`,
        {
          size_orders: payload,
          gender: data.gender,
          display_variant_id: data.display_variant_id,
          product_brand_id: parentData.product_brand.value,
          product_category_id: parentData.product_category.value,
        }
      );
      // ErrorHandler("#00C62E", "Edit Product Size Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error);
      // if (typeof error.response.data.errors === "object") {
      //   ErrorHandler(
      //     "#ed4846",
      //     error.response.data.errors[Object.keys(error.response.data.errors)[0]]
      //       .split("_")
      //       .join(" ")
      //   );
      // } else {
      //   ErrorHandler(
      //     "#ed4846",
      //     error.response.data.errors || "Edit Product Size Failed"
      //   );
      // }
    }
  };

  static deleteFormatSizeRow = async (id: any[]) => {
    try {
      await axiosCelestine().delete(`admin/product-size/multiple`, {
        data: {
          ids: id,
        },
      });
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error) {
      ErrorHandler("#ed4846", "Delete Failed");
    }
  };

  static deleteFormatSize = async (id: number) => {
    try {
      await axiosCelestine().delete(`admin/product-size-variant/${id}`);
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Edit Product Size Failed"
        );
      }
    }
  };
}
