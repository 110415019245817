export enum ActionType {
  ShowFilter,
  HandleChip,
  HandleShowSubBrand,
  HandleShowExceptSubBrand,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface HandleShowSubBrand {
  type: ActionType.HandleShowSubBrand;
  payload: any;
}

export interface HandleShowExceptSubBrand {
  type: ActionType.HandleShowExceptSubBrand;
  payload: any;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export type SizingAction =
  | ShowFilter
  | HandleChip
  | HandleShowSubBrand
  | HandleShowExceptSubBrand;
