import { useQuery } from "react-query";
import { FeaturedProductRequest } from "../../../apis/featured_product/FeaturedProductRequest";

const GetProduct = (id: string) => {
  const { data, isLoading } = useQuery(
    ["GetProduct", id],
    () => FeaturedProductRequest.getProduct(id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetProduct;
