import React, { useContext, useState } from "react";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import Input from "../../../../../components/input/Input";
import { ActionType } from "../../../../../store/product/product_need_review/actions";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";

interface IProps {}

const AddInformationModal: React.FC<IProps> = () => {
  const { productReviewDispatch } =
    useContext(ProductReviewContext);
  const [text, setText] = useState<string>("");
  const goBack = () => {
    productReviewDispatch({
      type: ActionType.ShowAddInfo,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    productReviewDispatch({
      type: ActionType.AddInfo,
      payload: text,
    });
    goBack();
  };

  return (
    <div className="fixed z-50 h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-10/12 sm:w-11/12 md:w-1/2 lg:w-1/3 xl:w-1/4 h-1/3 sm:h-1/2 md:h-1/2 lg:h-2/5 rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center h-1/4 px-10">
          <div className="flex items-center">
            <h1 className="text-xl flex">Add</h1>
            <h1 className="ml-1 text-xl text-primary">Information</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 h-3/4">
          <div className="h-1/2 flex flex-col justify-end">
            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-textPrimary">
                Please input your Information title
              </h1>
            </div>
            <Input
              value={text}
              type={"text"}
              handleChange={(e: any) => setText(e.target.value)}
              disable={false}
              name={"name"}
              placeholder={"Place holder"}
            />
          </div>
          <div className="flex justify-center items-center outline-none h-1/2">
            <SmallButton
              loadColor={"white"}
              height={10}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Add"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddInformationModal;
