import React, { useContext } from "react";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import { ActionType } from "../../../../../store/campaign/action";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const dummy_placeholder = `<p>1. After confirmation of offer by the seller, the buyer should pay the bill within 24 hours. The seller will deliver and send the package within 48 hour.</p><p>&nbsp;</p><p>&nbsp;2. If seller doesn’t send the packages within 36 hours, you will received compensate fee for IDR xxx.xxx,xx and your money will be refunded.&nbsp;</p><p>&nbsp;</p><p>3. Each product that you purchase in our platform will surely being checked by our professional checker, whereas the exact condition of the products, authentication, and also details of the product. If product that sender send to our platform dosen’t match the category in the content or fake product, DEPATU will send back the packages to seller and buyer will received compensate fee for IDR xxx.xxx,xx .</p>`;

interface IProps {
  setValues: any;
  values: any;
}

const AddTermsAndCondition: React.FC<IProps> = ({ setValues, values }) => {
  const { CampaignDispatch } = useContext(CampaignContext);

  const goBack = () => {
    CampaignDispatch({ type: ActionType.ShowAddTermsCondition });
  };

  return (
    <div className="fixed z-50 h-screen w-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 md:px-0 px-2">
      <div className="bg-white md:w-productCategory sm:w-10/12 w-full h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center p-7 pb-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Terms &</h1>
            <h1 className="ml-2 text-xl text-primary">Condition</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <section className="w-full">
          <div className="p-8 font-rom">
            <CKEditor
              config={{
                toolbar: ["|", "bold", "italic", "link", "blockQuote"],
              }}
              editor={ClassicEditor}
              data={values.term_and_condition}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setValues({ ...values, term_and_condition: data });
              }}
              placeholder={dummy_placeholder}
            />
          </div>
          <hr />

          <div className="flex justify-center my-5 outline-none">
            <SmallButton
              handleClick={goBack}
              loadColor={"white"}
              height={12}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Save"}
              textColor={"text-white"}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddTermsAndCondition;
