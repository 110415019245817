import { collection, onSnapshot } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ErrorHandler from "../../../../../components/toast/ErrorHandler";
import LocalStorage from "../../../../../config/LocalStorage";
import { AcceptedRole } from "../../../../../layout/marketplace/interfaces";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import { validateRole } from "../../../../../utils/checkRole/CheckRole";
import { ScopeType } from "../../../helpers/list";
import db from "../../../../../config/InitFirebase";
import { useMutation } from "react-query";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import CircularLoad from "../../../../../components/loading/CircularLoad";

interface IProps {
  data: any;
  dataCampaign: any;
  idx: number;
}

const CampaignTemplateCard: React.FC<IProps> = ({
  data,
  dataCampaign,
  idx,
}) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { CampaignDispatch } = useContext(CampaignContext);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "admin";
  const [dataSpinner, setDataSpinner] = useState<any>([]);
  const [load, setLoad] = useState(false);

  const getSnapshot = () => {
    setLoad(true);
    onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const firebaseData = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === +data.id &&
              doc.campaign_id === +params.id
          )[0];
        setDataSpinner(firebaseData);
        setLoad(false);
        return;
      }
    );
  };

  useEffect(() => {
    if (data) {
      getSnapshot();
    }
  }, []);

  const checkWinner = (winners: any[]) => {
    for (let i = winners.length - 1; i >= 0; i--) {
      if (winners[i].start_animation_at && i === 0) {
        return history.push(
          `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${data.id}/${winners.length}`
        );
      } else if (winners[i].start_animation_at && i > 0) {
        return history.push(
          `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${data.id}/shuffle/${winners.length}/${i}`
        );
      }
    }
  };

  const isRunOnBackground = (winners: any[]) => {
    for (let i = winners.length - 1; i >= 0; i--) {
      if (
        winners[i].start_animation_at &&
        winners[i].winner_status === "pending"
      ) {
        return true;
      }
    }
  };

  const handleShowSpin = () => {
    if (dataSpinner?.winners && isRunOnBackground(dataSpinner?.winners)) {
      checkWinner(dataSpinner.winners);
    } else if (!data?.available_shuffle_count) {
      ErrorHandler("#ed4846", "No enough available shuffle count");
    } else {
      CampaignDispatch({
        type: ActionType.AddDataSpinner,
        payload: null,
      });
      CampaignDispatch({
        type: ActionType.ShowAddQuantityShuffle,
        payload: { status: true, id: data.id },
      });
    }
  };

  const isAnnounced = (winners: any[]) => {
    for (let i = winners.length - 1; i >= 0; i--) {
      if (winners[i].winner_status === "pending") {
        return true;
      }
    }
  };

  const handleShowAnnounce = () => {
    if (dataSpinner?.winners && isAnnounced(dataSpinner?.winners)) {
      history.push(
        `/marketplace/campaign/edit-campaign/${params.id}/announce-winner/${data.id}`
      );
    } else if (!data?.available_shuffle_count) {
      ErrorHandler("#ed4846", "No enough available shuffle count");
    } else {
      CampaignDispatch({
        type: ActionType.AddDataSpinner,
        payload: null,
      });
      CampaignDispatch({
        type: ActionType.ShowAddQuantityShuffle,
        payload: { status: true, id: data.id },
      });
    }
  };

  const preCompute = (id: number) => {
    mutation.mutate(id);
  };

  const mutation = useMutation(
    (id: number) => CampaignRequest.preCompute(id, role),
    {
      onMutate: () => {
        console.log("mutate pre compute");
      },
      onSettled: async (data: any) => {
        if (data) {
          ErrorHandler("#00C62E", "Pre-Compute Success");
        }
      },
    }
  );

  const goResults = () => {
    history.push(
      `/marketplace/campaign/edit-campaign/${params.id}/results/${data.id}`
    );
  };

  if (
    validateRole(role, [
      AcceptedRole.Admin,
      AcceptedRole.Operation,
      AcceptedRole.Product,
      AcceptedRole.Marketing,
    ])
  ) {
    return (
      <div className="w-full h-auto border-2 border-stroke rounded-md my-5 hover:border-blue-300 hover:shadow-lg">
        <div className="grid grid-cols-2">
          <div className="border-r-2 border-stroke flex flex-col items-start justify-center px-10">
            <div className="flex items-center py-8">
              <img
                src={data.product_image}
                className="w-32 shadow-md rounded-md"
                alt="img"
              />
              <div className="mx-7">
                <h1 className="font-rom">
                  Campaign Type: {data.scope.split("_").join(" ")}
                </h1>
                <h1 className="font-med text-xl mt-1">{data.product_name}</h1>
                {data.scope === "campaign_giveawaydirect_raffle" ? (
                  <h1 className="font-rom mt-1">
                    Start raffle at:{" "}
                    {moment(data.estimate_start_time_at).format("ll hh:mm a")}
                  </h1>
                ) : data.scope === "campaign_basic" ? (
                  <h1 className="font-rom mt-1">
                    Start join waiting room at:{" "}
                    {moment(data.start_join_waiting_room_at).format(
                      "ll hh:mm a"
                    )}
                  </h1>
                ) : (
                  ""
                )}
                <h1 className="font-rom mt-1">Stock: {data.stock_count}</h1>
                <div className="flex mt-1">
                  <h1 className="mr-1 text-base">Status :</h1>
                  {data.active ? (
                    <h1 className="text-success text-base">Posted</h1>
                  ) : (
                    <h1 className="text-primary text-base">Not Posted</h1>
                  )}
                </div>
              </div>
            </div>
          </div>

          <aside className="h-full">
            <div className="flex justify-around text-base w-full border-b-2 border-stroke h-3/5">
              <div className="h-full w-1/2 flex justify-center items-center">
                <h1
                  onClick={() =>
                    history.push({
                      pathname: `/marketplace/campaign/info`,
                      search: `campaign=${dataCampaign.title}&&product_campaign=${data.product_name}`,
                    })
                  }
                  className="hover:text-primary cursor-pointer"
                >
                  Campaign Info
                </h1>
              </div>
              <div className="h-full w-1/2 flex justify-center items-center">
                <h1
                  onClick={() =>
                    history.push(
                      `/marketplace/campaign/edit-campaign/${params.id}/details/${data.id}`
                    )
                  }
                  className="hover:text-primary cursor-pointer"
                >
                  Details
                </h1>
              </div>
              <div className="h-full w-1/2 flex justify-center items-center">
                <h1
                  onClick={() =>
                    history.push(
                      `/marketplace/campaign/edit-campaign/${params.id}/edit-campaign-product/${data.id}`
                    )
                  }
                  className="hover:text-primary cursor-pointer"
                >
                  Edit
                </h1>
              </div>
              <div className="h-full w-1/2 flex justify-center items-center">
                <h1
                  onClick={() =>
                    CampaignDispatch({
                      type: ActionType.ShowDeleteCampaign,
                      payload: { status: true, id: data.id },
                    })
                  }
                  className="hover:text-primary cursor-pointer"
                >
                  Delete
                </h1>
              </div>
            </div>
            <div className="flex justify-around text-base w-full h-2/5">
              <div className="h-full flex justify-center items-center w-full">
                {data.scope !== ScopeType.BASIC && (
                  <div className="h-full w-1/2 flex justify-center items-center">
                    {mutation.isLoading ? (
                      <div>
                        <CircularLoad
                          color={"#ed4846"}
                          height={20}
                          width={20}
                        />
                      </div>
                    ) : (
                      <h1
                        onClick={
                          data.scope === ScopeType.SPECIALPRICERAFFLE ||
                          data.scope === ScopeType.GIVEAWAYRAFFLE
                            ? () => handleShowSpin()
                            : (data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                                data.scope ===
                                  ScopeType.SPECIALPRICETOPCREDIT) &&
                              dataSpinner.batch_count === 0
                            ? () => preCompute(data.id)
                            : data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                              data.scope === ScopeType.SPECIALPRICETOPCREDIT
                            ? () => handleShowAnnounce()
                            : () => {}
                        }
                        className="hover:text-primary cursor-pointer"
                      >
                        {data.scope === ScopeType.SPECIALPRICERAFFLE ||
                        data.scope === ScopeType.GIVEAWAYRAFFLE
                          ? "Spin Winner"
                          : (data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                              data.scope === ScopeType.SPECIALPRICETOPCREDIT) &&
                            dataSpinner.batch_count === 0
                          ? "Prepare Winner"
                          : data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                            data.scope === ScopeType.SPECIALPRICETOPCREDIT
                          ? "Announce Winner"
                          : ""}
                      </h1>
                    )}
                  </div>
                )}

                <div
                  className={
                    "h-full flex justify-center items-center  " +
                    (data.scope !== ScopeType.BASIC
                      ? "w-1/2 border-l-2"
                      : "w-full border-l-0")
                  }
                >
                  <h1
                    onClick={goResults}
                    className="hover:text-primary cursor-pointer"
                  >
                    Summary
                  </h1>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    );
  }

  return (
    <div className="h-auto xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke">
      <div className="px-8 pt-8">
        <h1 className="mb-3.5 font-rom">
          Campaign Type: {data.scope.split("_").join(" ")}
        </h1>
        <div className="flex items-center">
          <img
            src={data.product_image}
            className="w-32 shadow-md rounded-md"
            alt="img"
          />
          <div className="mx-7">
            <h1 className="font-med text-xl mt-1">{data.product_name}</h1>
            {data.scope === "campaign_giveawaydirect_raffle" ? (
              <h1 className="font-rom mt-1">
                Start raffle at:{" "}
                {moment(data.estimate_start_time_at).format("ll hh:mm a")}
              </h1>
            ) : data.scope === "campaign_basic" ? (
              <h1 className="font-rom mt-1">
                Start join waiting room at:{" "}
                {moment(data.start_join_waiting_room_at).format("ll hh:mm a")}
              </h1>
            ) : (
              ""
            )}
            <h1 className="font-rom mt-1">Stock: {data.stock_count}</h1>
            <div className="flex mt-1">
              <h1 className="mr-1 text-base">Status :</h1>
              {data.active ? (
                <h1 className="text-success text-base">Posted</h1>
              ) : (
                <h1 className="text-primary text-base">Not Posted</h1>
              )}
            </div>
          </div>
        </div>
      </div>

      {validateRole(role, AcceptedRole.User) && (
        <div className="flex justify-around text-base h-20 mt-8 w-full">
          <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
            <h1
              onClick={() =>
                history.push(
                  `/marketplace/campaign/edit-campaign/${params.id}/details/${data.id}`
                )
              }
              className="hover:text-primary cursor-pointer"
            >
              Details
            </h1>
          </div>
          <div className="border-2 border-b-0 border-r-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
            <h1
              onClick={() =>
                history.push(
                  `/marketplace/campaign/edit-campaign/${params.id}/${
                    data.scope === ScopeType.BASIC ||
                    data.scope === ScopeType.SPECIALPRICERAFFLE ||
                    data.scope === ScopeType.SPECIALPRICETOPCREDIT
                      ? "sell-product"
                      : data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                        data.scope === ScopeType.GIVEAWAYRAFFLE
                      ? "list-product"
                      : ""
                  }/${data.product_id}`,
                  { campaign_product_template_id: data.id }
                )
              }
              className="hover:text-primary cursor-pointer"
            >
              {data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
              data.scope === ScopeType.GIVEAWAYRAFFLE
                ? "List this product"
                : data.scope === ScopeType.BASIC ||
                  data.scope === ScopeType.SPECIALPRICERAFFLE ||
                  data.scope === ScopeType.SPECIALPRICETOPCREDIT
                ? "Sell this product"
                : ""}
            </h1>
          </div>
        </div>
      )}

      {validateRole(role, AcceptedRole.Spinner) && (
        <div className="flex justify-around text-base h-20 mt-8 w-full">
          <div
            className={
              "border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full flex justify-center items-center " +
              (data.scope !== ScopeType.BASIC ? "w-1/2 border-r-2" : "w-full")
            }
          >
            <h1
              onClick={() =>
                history.push({
                  pathname: `/marketplace/campaign/info`,
                  search: `campaign=${dataCampaign.title}&&product_campaign=${data.product_name}`,
                })
              }
              className="hover:text-primary cursor-pointer"
            >
              Campaign Info
            </h1>
          </div>

          {data.scope !== ScopeType.BASIC && (
            <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
              {mutation.isLoading ? (
                <div>
                  <CircularLoad color={"#ed4846"} height={20} width={20} />
                </div>
              ) : (
                <h1
                  onClick={
                    data.scope === ScopeType.SPECIALPRICERAFFLE ||
                    data.scope === ScopeType.GIVEAWAYRAFFLE
                      ? () => handleShowSpin()
                      : (data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                          data.scope === ScopeType.SPECIALPRICETOPCREDIT) &&
                        dataSpinner.batch_count === 0
                      ? () => preCompute(data.id)
                      : data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                        data.scope === ScopeType.SPECIALPRICETOPCREDIT
                      ? () => handleShowAnnounce()
                      : () => {}
                  }
                  className="hover:text-primary cursor-pointer"
                >
                  {data.scope === ScopeType.SPECIALPRICERAFFLE ||
                  data.scope === ScopeType.GIVEAWAYRAFFLE
                    ? "Spin Winner"
                    : (data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                        data.scope === ScopeType.SPECIALPRICETOPCREDIT) &&
                      dataSpinner.batch_count === 0
                    ? "Prepare Winner"
                    : data.scope === ScopeType.GIVEAWAYTOPCREDIT ||
                      data.scope === ScopeType.SPECIALPRICETOPCREDIT
                    ? "Announce Winner"
                    : ""}
                </h1>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CampaignTemplateCard;
