import isEmpty from "lodash.isempty";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import CircularLoad from "../../../../components/loading/CircularLoad";
import GetCampaignTemplateId from "../../helpers/use_query_hook/GetCampaignTemplateId";

const PreAnnounceProduct = () => {
  const params: { id: string; templateId: string } = useParams();
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const history = useHistory();

  useEffect(() => {
    if (!isEmpty(data)) {
      setTimeout(() => {
        history.push(
          `/marketplace/campaign/edit-campaign/${params.id}/announce-winner/${params.templateId}`
        );
      }, 2000);
    }
  }, []);

  return (
    <div className="fixed top-0 left-0 z-30 bg-black w-screen h-screen flex flex-col justify-center items-center">
      {isEmpty(data) || isLoading ? (
        <div className="flex justify-center mt-20">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img src={data?.cool_image} alt="-" className="w-48" />
          <h1 className="text-white font-med text-4xl mt-8 uppercase">
            {data.scope === "campaign_giveawaydirect_raffle"
              ? "Giveaway Onsite Raffle"
              : "GIVEAWAY"}
          </h1>
          <h1 className="text-white font-rom text-3xl mt-2">
            {data?.product_name}
          </h1>
        </div>
      )}
    </div>
  );
};

export default PreAnnounceProduct;
