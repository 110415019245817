import { createContext } from "react";
import { IinitialBannerState, IBannerContext } from "./interface";

export const initialBannerState: IinitialBannerState = {
  showFilter: false,
  showDelete: {
    id: "",
    status: false,
  },
  chip: [],
};

const BannerContext = createContext<IBannerContext>({
  bannerState: initialBannerState,
  bannerDispatch: () => {},
});

export const BannerContextProvider = BannerContext.Provider;
export const BannerContextConsumer = BannerContext.Consumer;

export default BannerContext;
