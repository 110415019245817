/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginGuard from "./utils/guards/Guards";
import Marketplace from "./layout/marketplace/Marketplace";
import { CrossStorageClient } from "cross-storage";
import LocalStorage from "./config/LocalStorage";
import jwtDecode from "jwt-decode";
import moment from "moment";
import Login from "./pages/auth/Login";
import CircularLoad from "./components/loading/CircularLoad";
import Loginuser from "./pages/auth/LoginUser";
import Pages404 from "./pages/auth/404";
import { GlobalReducer } from "./store/global/GlobalReducer";
import {
  GlobalContextProvider,
  initialGlobalState,
} from "./store/global/GlobalContext";
import { ActionType } from "./store/global/action";
import { initReactI18next } from "react-i18next";
import i18n from "./i18n";
import isEmpty from "lodash.isempty";

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const storage = new CrossStorageClient(
    process.env.REACT_APP_CROSS_STORAGE_URL || "",
    {}
  );
  const [GlobalState, GlobalDispatch] = useReducer(
    GlobalReducer,
    initialGlobalState
  );

  const valid_token = (token: string) => {
    const decoded: any = jwtDecode(token);
    if (decoded.exp * 1000 < moment().valueOf()) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("LANG") || "en";
    GlobalDispatch({ type: ActionType.SetLanguage, payload: { lang } });
    i18n.use(initReactI18next).changeLanguage(lang);
  }, []);

  useEffect(() => {
    const local = localStorage.getItem("AUTH_TOKEN") || "";
    if (!isEmpty(local) && !valid_token(local)) {
      localStorage.removeItem("AUTH_TOKEN");
      localStorage.removeItem("ROLE");
    }

    storage
      .onConnect()
      .then(function () {
        return storage.get("AUTH_TOKEN");
      })
      .then(function (res) {
        if (res) {
          if (!valid_token(res)) {
            storage.del("AUTH_TOKEN");
            storage.del("ROLE");
            LocalStorage.AUTH_TOKEN = "";
            LocalStorage.ROLE = "";
          } else {
            LocalStorage.AUTH_TOKEN = res;
            (window as any).LocalStorage = LocalStorage;
          }
        }
      })
      .then(function () {
        return storage.get("ROLE");
      })
      .then(function (res) {
        LocalStorage.ROLE = res;
        (window as any).LocalStorage = LocalStorage;
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center pt-32">
        <CircularLoad color="#ed4846" height={40} width={40} />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <GlobalContextProvider value={{ GlobalState, GlobalDispatch }}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/login-user" exact component={Loginuser} />
          <Route path="/404" exact component={Pages404} />
          <LoginGuard path="/marketplace" component={Marketplace} />
          <Redirect from="/" exact to="/marketplace" />
        </Switch>
      </GlobalContextProvider>
    </BrowserRouter>
  );
}

export default App;
