import isEmpty from "lodash.isempty";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { LegitCheckService } from "../../apis/legit_check";
import ErrorHandler from "../../components/toast/ErrorHandler";
import {
  PLegitCheck,
  PLegitCheckDetail,
} from "../../core/legit_check/entities";

const useMutateCreateLegitCheck = (code: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: {
      bnib_transaction_id: number;
      images: any[];
      notes?: string | undefined;
    }) => LegitCheckService.createLegitCheck(values),
    {
      onMutate: () => {
        console.log("mutate create Legit Check");
      },
      onSettled: async (data, _err, variables) => {
        if (!isEmpty(data)) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          const removeFile: any = document.getElementsByClassName(
            "filepond--file-action-button filepond--action-remove-item"
          );
          variables.images.map((_el, idx) => removeFile[idx]?.click());
        }
      },
    }
  );
};

const useMutateUpdateLegitCheck = (code: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { id: number; images: any[] }) =>
      LegitCheckService.updateLegitCheck(values),
    {
      onMutate: () => {
        console.log("mutate update Legit Check");
      },
      onSettled: async (data, _err, variables) => {
        if (!isEmpty(data)) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          const removeFile: any = document.getElementsByClassName(
            "filepond--file-action-button filepond--action-remove-item"
          );
          variables.images.map((_el, idx) => removeFile[idx]?.click());
        }
      },
    }
  );
};

const useFetchLegitCheck = (id: number) => {
  return useQuery(
    [`legitCheck-${id}`],
    () => LegitCheckService.getLegitCheck(id),
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );
};

const useMutateCreateLegitCheckDetail = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  legitCheckId: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: PLegitCheckDetail) =>
      LegitCheckService.createLegitCheckDetail(values),
    {
      onMutate: () => {
        console.log("mutate create Legit Check Detail");
      },
      onSettled: async (data) => {
        if (data) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          queryClient.invalidateQueries(`legitCheck-${legitCheckId}`);
          setOpen(false);
        }
      },
    }
  );
};

const useMutateUpdateLegitCheckDetail = (
  code: string,
  setOpen: (isOpen: boolean) => void,
  legitCheckDetailId: number,
  legitCheckId: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: PLegitCheckDetail) =>
      LegitCheckService.updateLegitCheckDetail(values, legitCheckDetailId),
    {
      onMutate: () => {
        console.log("mutate update Legit Check Detail");
      },
      onSettled: async (data) => {
        if (data) {
          queryClient.invalidateQueries(`BnibTransaction-process-${code}`);
          queryClient.invalidateQueries(`BnibTransaction-${code}`);
          queryClient.invalidateQueries(`legitCheck-${legitCheckId}`);
          setOpen(false);
        }
      },
    }
  );
};

const useFetchLegitCheckDetail = (id: number) => {
  return useQuery(
    [`legitCheckdetail-${id}`, id],
    () => LegitCheckService.getLegitCheckDetail(id),
    {
      refetchOnWindowFocus: false,
      enabled: id && id !== 0 ? true : false,
    }
  );
};

const useMutatePublishFinalResult = (
  legitCheckId: number,
  confirmMutate: any,
  values: PLegitCheck
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { final_result: string; confirm_result?: string }) =>
      LegitCheckService.publishFinalResult(values, legitCheckId),
    {
      onMutate: () => {
        console.log("mutate publish final result");
      },
      onSettled: async (data) => {
        if (typeof data === "string") {
          ErrorHandler("#ed4846", data);
        } else {
          queryClient.invalidateQueries(`legitCheck-${legitCheckId}`);
          confirmMutate(values);
        }
      },
    }
  );
};

export {
  useMutateCreateLegitCheck,
  useMutateUpdateLegitCheck,
  useFetchLegitCheck,
  useMutateCreateLegitCheckDetail,
  useFetchLegitCheckDetail,
  useMutateUpdateLegitCheckDetail,
  useMutatePublishFinalResult,
};
