import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import LocalStorage from "../../../../config/LocalStorage";
import { AcceptedRole } from "../../../../layout/marketplace/interfaces";
import { ActionType } from "../../../../store/coupon/action";
import CouponContext from "../../../../store/coupon/CouponContext";
import { validateRole } from "../../../../utils/checkRole/CheckRole";

interface IProps {
  data: any;
}

const CouponCard: React.FC<IProps> = ({ data }) => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const history = useHistory();
  const { couponDispatch } = useContext(CouponContext);

  return (
    <div className="w-full h-auto border-2 border-stroke rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4">
        <div className="border-r-2 border-stroke flex justify-center">
          <img
            src={data.display_image_path}
            alt="banner_img"
            className="w-36 m-1 rounded-md shadow-lg"
          />
        </div>

        <div className="flex flex-col items-start pl-7 justify-center border-r-2 border-stroke">
          <h1 className="text-xl">{data.display_name}</h1>
          <h1 className="text-base font-rom mt-3">
            Coupon Type: {data.for_reward ? "Reward" : "Public"}
          </h1>
          <div className="flex mt-1">
            <h1 className="mr-1 text-base">Status :</h1>
            {data.active_status ? (
              <h1 className="text-success text-base">Posted</h1>
            ) : (
              <h1 className="text-primary text-base">Not Posted</h1>
            )}
          </div>
        </div>

        <div className="flex flex-col items-start justify-evenly py-4 px-10 border-r-2 border-stroke">
          <h1 className="text-base font-rom">
            {data.expired_at ? "Expired At" : "Expired in (Day)"}
          </h1>
          <h1 className="text-lg">
            {data.expired_at
              ? moment(data.expired_at).format("LL")
              : data.rewardable_valid_in_day}
          </h1>
          <h1 className="text-base font-rom">
            Discount Type: {data.discount_type?.split("_").join(" ")}
          </h1>
        </div>

        {validateRole(role, [
          AcceptedRole.Admin,
          AcceptedRole.Operation,
          AcceptedRole.KeyAccount,
          AcceptedRole.Marketing,
        ]) && (
          <div className="flex items-center justify-evenly mx-10">
            <h1
              onClick={() =>
                history.push(`/marketplace/coupon/edit-coupon/${data._id}`)
              }
              className="text-base hover:text-primary cursor-pointer"
            >
              Edit
            </h1>
            {data.usage_count === 0 && (
              <h1
                onClick={() =>
                  couponDispatch({
                    type: ActionType.showDelete,
                    payload: {
                      status: true,
                      id: data._id,
                    },
                  })
                }
                className="text-base hover:text-primary cursor-pointer"
              >
                Delete
              </h1>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponCard;
