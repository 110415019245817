import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Banner from "../../../pages/banner/Banner";
import AddBanner from "../../../pages/banner/banner_component/AddBanner";
import EditBanner from "../../../pages/banner/banner_component/EditBanner";

const BannerRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/marketplace/banner" exact component={Banner} />
        <Route
          path="/marketplace/banner/add-banner"
          exact
          component={AddBanner}
        />
        <Route
          path="/marketplace/banner/edit-banner/:id"
          exact
          component={EditBanner}
        />

        <Redirect from="/marketplace" to="/marketplace/banner" exact />
      </Switch>
    </div>
  );
};

export default BannerRoute;
