import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddExceptionBrand from "../../../pages/sizing/AddExceptionBrand";
import AddSizing from "../../../pages/sizing/AddSizing";
import EditExceptionBrand from "../../../pages/sizing/components/edit_exception_brand/EditExceptionBrand";
import SeeBrand from "../../../pages/sizing/components/sizing/SeeBrand";
import EditSizing from "../../../pages/sizing/EditSizing";
import Sizing from "../../../pages/sizing/Sizing";
import {
  initialSizingState,
  SizingContextProvider,
} from "../../../store/sizing/SizingContext";
import { SizingReducer } from "../../../store/sizing/SizingReducer";

const SizingRoute = () => {
  const [SizingState, SizingDispatch] = useReducer(
    SizingReducer,
    initialSizingState
  );

  return (
    <SizingContextProvider value={{ SizingState, SizingDispatch }}>
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route path="/marketplace/sizing" exact component={Sizing} />
          <Route
            path="/marketplace/sizing/see-brand/:product_category_id/:product_brand_id"
            exact
            component={SeeBrand}
          />
          <Route
            path="/marketplace/sizing/add-sizing"
            exact
            component={AddSizing}
          />
          <Route
            path="/marketplace/sizing/edit-sizing/:product_category_id/:product_brand_id"
            exact
            component={EditSizing}
          />
          <Route
            path="/marketplace/sizing/edit-sizing/:product_category_id/:product_brand_id/add-exception-size"
            exact
            component={AddExceptionBrand}
          />

          <Route
            path="/marketplace/sizing/edit-sizing/:product_category_id/:product_brand_id/exception-size/:exceptionId"
            exact
            component={EditExceptionBrand}
          />
          <Redirect from="/marketplace" to="/marketplace/sizing" exact />
        </Switch>
      </div>
    </SizingContextProvider>
  );
};

export default SizingRoute;
