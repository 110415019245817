import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SendCredit from "../../../pages/send_credit/SendCredit";
import SendHistory from "../../../pages/send_credit/SendHistory";
import {
  initialSendCreditState,
  SendCreditContextProvider,
} from "../../../store/send_credit/SendCreditContext";
import { SendCreditReducer } from "../../../store/send_credit/SendCreditReducer";

const SendCreditRoute = () => {
  const [SendCreditState, SendCreditDispatch] = useReducer(
    SendCreditReducer,
    initialSendCreditState
  );

  return (
    <SendCreditContextProvider value={{ SendCreditState, SendCreditDispatch }}>
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route path="/marketplace/send-credit" exact component={SendCredit} />
          <Route
            path="/marketplace/send-credit/history/:id"
            exact
            component={SendHistory}
          />
          <Redirect from="/marketplace" to="/marketplace/send-credit" exact />
        </Switch>
      </div>
    </SendCreditContextProvider>
  );
};

export default SendCreditRoute;
