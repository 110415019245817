import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import axiosCelestine from "../../../config/AxiosCelestine";

//icons
import { cancel } from "../../../assets/icons";
import Input from "../../../components/input/Input";
import ErrorHandler from "../../../components/toast/ErrorHandler";

function AddCategories() {
  const history = useHistory();
  const params: {
    id: string;
  } = useParams();
  const [posted, setPosted] = useState(true);

  const goBack = (id: string) => {
    history.push(`/marketplace/product-category-group/categories/${id}`);
  };

  const handleposted = () => {
    setPosted(!posted);
    values.published = !values.published;
  };

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name: "",
      published: true,
      product_category_group_id: Number(params.id),
      priority: 4,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
    }),
    onSubmit: (values) => {
      values.product_category_group_id = Number(
        values.product_category_group_id
      );
      axiosCelestine()
        .post("admin/special-category", values)
        .then(({ data }) => {
          ErrorHandler("#00C62E", "Add Completed");
          setTimeout(() => {
            history.push(
              `/marketplace/product-category-group/categories/${params.id}`
            );
          }, 1500);
        })
        .catch((err) => {
          ErrorHandler("#DF5354", err.response.data.errors);
        });
    },
  });

  return (
    <div className="absolute w-full left-0 z-50 h-screen pt-10 bg-black bg-opacity-70 flex justify-center">
      <div className="bg-white w-productCategory h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center p-7 pb-6">
          <h1 className="text-xl flex">
            Add <h1 className="ml-2 text-primary">Categories</h1>
          </h1>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={() => goBack(params.id)}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 pt-3">
          <div className="md:mt-6 mt-3">
            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-textPrimary">Name</h1>
              <div className="flex justify-between items-center">
                <h1 className="text-base mr-3">Posted</h1>
                <div
                  className={
                    !posted
                      ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                      : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                  }
                >
                  <div
                    className={
                      !posted
                        ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                        : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3 translate-x-3"
                    }
                    onClick={handleposted}
                  ></div>
                </div>
              </div>
            </div>
            <Input
              type={"text"}
              handleChange={handleChange}
              disable={false}
              name={"name"}
              placeholder={"Place holder"}
            />
            {touched.name && errors.name ? (
              <h1 className="text-primary -mt-1 text-sm">{errors.name}</h1>
            ) : null}
            {/* {error ? (
              <h1 className="text-primary -mt-1 text-sm">
                Category name is already exists!
              </h1>
            ) : null} */}
          </div>
          <div className="mt-4">
            <h1 className="md:text-base text-sm text-textPrimary">
              Product Category Group
            </h1>
            <Input
              type={"text"}
              handleChange={handleChange}
              disable={true}
              value={values.product_category_group_id}
              name={"product_category_group_id"}
              placeholder={"Place holder"}
            />
          </div>
          <div className="flex justify-center mt-5 mb-7 outline-none">
            <button
              type="submit"
              className="w-40 h-10 rounded-full p-2 bg-primary focus:outline-none hover:opacity-70"
            >
              <h1 className="text-white text-sm">Add</h1>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCategories;
