import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Awhatsapp: FC<IIconProps> = ({
  stroke = "#666666",
  width = "14",
  height = "14",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83854 10.0593C1.09332 8.80197 0.832651 7.31589 1.10547 5.88002C1.37829 4.44415 2.16583 3.15723 3.32026 2.26087C4.47468 1.3645 5.91659 0.920338 7.37529 1.01176C8.83399 1.10317 10.2092 1.72388 11.2426 2.75736C12.2761 3.79084 12.8968 5.16601 12.9882 6.62471C13.0797 8.08341 12.6355 9.52532 11.7391 10.6797C10.8428 11.8342 9.55585 12.6217 8.11998 12.8945C6.68411 13.1673 5.19803 12.9067 3.94073 12.1615V12.1615L1.86357 12.7496C1.77846 12.7745 1.68823 12.776 1.60233 12.754C1.51643 12.7321 1.43802 12.6874 1.37532 12.6247C1.31262 12.562 1.26795 12.4836 1.24597 12.3977C1.224 12.3118 1.22554 12.2215 1.25043 12.1364L1.83854 10.0593Z"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.51441 10.4973C7.85583 10.4989 7.20342 10.3704 6.59465 10.1192C5.98589 9.8679 5.43277 9.49882 4.96709 9.03313C4.5014 8.56745 4.13232 8.01433 3.88106 7.40557C3.62979 6.7968 3.50129 6.14439 3.50295 5.48581C3.5046 5.02229 3.6899 4.57831 4.01825 4.25113C4.3466 3.92395 4.79124 3.74024 5.25477 3.74025V3.74025C5.33124 3.73964 5.40647 3.75965 5.47253 3.79819C5.53859 3.83672 5.59305 3.89235 5.63016 3.95923L6.36217 5.23555C6.4057 5.31314 6.42804 5.40082 6.42694 5.48978C6.42584 5.57874 6.40135 5.66584 6.35591 5.74233L5.7678 6.7246C6.06904 7.39473 6.60549 7.93118 7.27562 8.23242L8.25789 7.64431C8.33438 7.59887 8.42148 7.57438 8.51044 7.57328C8.5994 7.57218 8.68708 7.59452 8.76467 7.63805L10.041 8.37006C10.1079 8.40717 10.1635 8.46163 10.202 8.52769C10.2406 8.59375 10.2606 8.66898 10.26 8.74545C10.2583 9.20848 10.0741 9.65217 9.74728 9.98016C9.42045 10.3082 8.97743 10.494 8.51441 10.4973V10.4973Z"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
