import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";

export class ProductCategoryGroupRequest {
  static getProductCtgryGroup = async () => {
    try {
      const results = await axiosCelestine().get(
        `admin/product-category-group`
      );
      const result = await results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static editPriorityProductCategoryGroup = async (
    payload: any,
    setDataOfStep: any,
    data: any,
    setLoading: any
  ) => {
    try {
      await axiosCelestine().patch(
        `admin/product-category-group/${payload.filterData.id}`,
        {
          priority: payload.destinationIndex + 1,
        }
      );
      setLoading(false);
      ErrorHandler("#00C62E", "Edit Completed");
    } catch (error) {
      setLoading(false);
      ErrorHandler("#ed4846", error.response.data.errors);
      setDataOfStep(data);
    }
  };
}
