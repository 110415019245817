import { createContext } from "react";
import { ICampaignContext, ICampaignState } from "./state";

export const initialCampaignState: ICampaignState = {
  showFilter: false,
  showAddCampaign: false,
  showAddTermsCondition: false,
  showDownloadQrCode: false,
  showModalSecurityCode: {
    status: false,
    code: "",
  },
  showModalListProduct: {
    status: false,
    payload: {
      campaign_product_template_id: 0,
      product_size_id: 0,
    },
  },
  showProductPerSize: {
    status: false,
    size: "",
  },
  showDeleteCampaign: {
    status: false,
    id: 0,
  },
  chip: [],
  limit: 10,
  offset: 0,
  data_spinner: {},
  showAddQuantityShuffle: {
    status: false,
    id: 0,
  },
  drop_winner: {
    status: false,
    id: 0,
  },
  confirm_winner: {
    status: false,
    id: '',
  },
};

const CampaignContext = createContext<ICampaignContext>({
  CampaignState: initialCampaignState,
  CampaignDispatch: () => undefined,
});

export const CampaignContextProvider = CampaignContext.Provider;
export const CampaignContextConsumer = CampaignContext.Consumer;

export default CampaignContext;
