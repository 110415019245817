import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
// import SizingContext from "../../../../store/sizing/SizingContext";
import { useQueries } from "../../../utils/query_searchParams/QuerySearchParams";
import { FeaturedProductRequest } from "../../../apis/featured_product/FeaturedProductRequest";

const GetFeaturedProduct = () => {
  const query = useQueries(useLocation);
//   const { sizingDispatch } = useContext(SizingContext);
  const { data, isLoading, isFetching } = useQuery(
    [
      "GetFeaturedProduct",
      query.get("product_id"),
      query.get("status"),
      query.get("release_date_from"),
      query.get("release_date_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => FeaturedProductRequest.getFeaturedProduct(),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetFeaturedProduct;
