import { useFormik } from "formik";
import React, { useContext } from "react";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { TrackingRequest } from "../../../../apis/tracking/TrackingRequest";
import SmallButton from "../../../../components/button/SmallButton";
import TrackingContext from "../../../../store/tracking/TrackingContext";
import { useParams } from "react-router-dom";

const AddStepCard = () => {
  const params: { id: string } = useParams();
  const context = useContext(TrackingContext);
  const { trackingDispatch } = context;

  const goBack = () => {
    trackingDispatch({
      type: "show_add_step",
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    handleReset,
  } = useFormik({
    initialValues: {
      description: "",
      custom_tracking_origin_id: +params.id,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(() => TrackingRequest.addStep(values), {
    onMutate: () => {
      console.log("mutate add step");
    },
    onSettled: async (data: any, error: any) => {
      await queryClient.invalidateQueries("getTrackingStep");
      if (error) {
        console.log(error);
        handleReset({});
      }
      goBack();
    },
  });
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-productCategory h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center p-7 pb-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Add</h1>
            <h1 className="ml-1 text-xl text-primary">Tracking Process</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 pt-3">
          <div className="mt-2">
            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-textPrimary">
                Description
              </h1>
            </div>
            <textarea
              onChange={handleChange}
              name={"description"}
              placeholder={"Place holder"}
              className="w-full border rounded-md px-3 py-1.5 my-1.5 h-20 font-rom text-sm focus:ring-1 outline-none"
            ></textarea>
            {touched.description && errors.description ? (
              <h1 className="text-primary -mt-1 text-sm">
                {errors.description}
              </h1>
            ) : null}
          </div>
          <div className="flex justify-center mt-5 mb-7 outline-none">
            <SmallButton
              isSubmitting={mutation.isLoading}
              dirty={dirty}
              loadColor={"white"}
              height={10}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Add"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStepCard;
