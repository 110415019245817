import { createContext } from "react";
import { IProductDepatuContext, IProductDepatuState } from "./state";

export const initialProductDepatuState: IProductDepatuState = {
  showFilter: false,
  chip: [],
  dataImageEdit: [],
  displayImageEdit: null,
  showAddInfoEdit: false,
  dimensionEdit: [],
  showConfirmEdit: false,
  dataImageAdd: [],
  displayImageAdd: null,
  showAddInfoAdd: false,
  dimensionAdd: [],
  showConfirmAdd: false,
  pictureInit: { value: "2d", label: "2D" },
};

const ProductDepatuContext = createContext<IProductDepatuContext>({
  productDepatuState: initialProductDepatuState,
  productDepatuDispatch: () => undefined,
});

export const ProductDepatuContextProvider = ProductDepatuContext.Provider;
export const ProductDepatuContextConsumer = ProductDepatuContext.Consumer;

export default ProductDepatuContext;
