import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../../config/LocalStorage";
import { AcceptedRole } from "../../../layout/marketplace/interfaces";
import { ActionType } from "../../../store/credit_effect/action";
import CreditEffectContext from "../../../store/credit_effect/CreditEffectContext";
import { validateRole } from "../../../utils/checkRole/CheckRole";

interface IProps {
  data: {
    id: number;
    name: string;
    scope: string;
    results: any[];
    is_active: boolean;
  };
}
const CreditEffectCard: React.FC<IProps> = ({ data }) => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const history = useHistory();
  const { CreditEffectDispatch } = useContext(CreditEffectContext);

  return (
    <div className="h-auto rounded-xl border-2 border-stroke hover:border-blue-300 hover:shadow-lg">
      <div className="flex justify-between items-start h-2/3 pb-5">
        <div className="flex pt-5 pb-10 px-8">
          <div className="flex flex-col justify-around">
            <h1 className="text-xl">{data.name}</h1>
            <h1 className="text-base mt-2 font-rom">
              Effect for : {data.scope}
            </h1>
            <h1 className="text-base mt-2 font-rom">
              Get Credit :{" "}
              {data.results.map(
                (el: any, idx: number) =>
                  ` ${el.quantity} ${el.campaign_name} ` +
                  (idx !== data.results.length - 1 ? "|" : "")
              )}
            </h1>
            <div className="flex mt-1">
              <h1 className="mr-1 text-base">Status :</h1>
              {data.is_active ? (
                <h1 className="text-success text-base">Active</h1>
              ) : (
                <h1 className="text-primary text-base">Not Active</h1>
              )}
            </div>
          </div>
        </div>
      </div>

      {validateRole(role, [
        AcceptedRole.Admin,
        AcceptedRole.Operation,
        AcceptedRole.KeyAccount,
        AcceptedRole.Marketing,
      ]) && (
        <div className="flex justify-around xl:text-base md:text-sm w-full h-1/3 pt-1 ">
          <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
            <h1
              onClick={() =>
                history.push(
                  `/marketplace/credit-effect/edit-credit-effect/${data.id}`
                )
              }
              className="hover:text-primary cursor-pointer"
            >
              Edit
            </h1>
          </div>
          <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
            <h1
              onClick={() =>
                CreditEffectDispatch({
                  type: ActionType.showDelete,
                  payload: {
                    id: data.id,
                    status: true,
                  },
                })
              }
              className="hover:text-primary cursor-pointer"
            >
              Delete
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditEffectCard;
