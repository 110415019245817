import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import { ActionType } from "../../../../store/invoice/action";
import InvoiceContext from "../../../../store/invoice/InvoiceContext";
import SetQueryString from "../../../../utils/query_searchParams/SetQueryString";
import { queryParamsWaitingInvoice } from "../../helpers/list";

const WaitingInvoiceFilter: React.FC = () => {
  const history = useHistory();
  const { InvoiceState, InvoiceDispatch } = useContext(InvoiceContext);
  const { pathname, search } = useLocation();
  const { showFilter } = InvoiceState;

  const handleShowFilter = () => {
    InvoiceDispatch({ type: ActionType.ShowFilter });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    SetQueryString(
      queryParamsWaitingInvoice,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  const applyFilter = () => {
    setTimeout(() => InvoiceDispatch({ type: ActionType.ShowFilter }), 500);
  };

  return (
    <div>
      {showFilter && (
        <div className="fixed z-50 h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
          <div
            className="bg-white rounded-md overflow-hidden shadow-xl transform transition-all md:w-1/3 w-10/12"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="px-10 py-7 flex justify-between w-full">
              <h1
                className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
                id="modal-headline"
              >
                Filter
              </h1>
              <img
                src={cancel}
                alt="back"
                className="cursor-pointer md:w-5 w-3"
                onClick={handleShowFilter}
              />
            </div>
            <hr className="w-full " />
            <div className="flex px-10 py-5">
              <div className="w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">Code</h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"code"}
                  handleChange={handleChangeFilter}
                />
              </div>
              <div className="w-1/2 ml-8">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Username
                </h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"username"}
                  handleChange={handleChangeFilter}
                />
              </div>
            </div>
            <hr className="w-full " />
            <div className="flex justify-center m-5">
              <button
                onClick={applyFilter}
                className="bg-primary w-36 h-9 rounded-full focus:ring-0 focus:outline-none hover:bg-red-300"
              >
                <h1 className="text-white text-base mb-0.5">Apply</h1>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaitingInvoiceFilter;
