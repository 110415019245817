import { createContext } from "react";
import { ISendCreditContext, ISendCreditState } from "./state";

export const initialSendCreditState: ISendCreditState = {
  showDelete: {
    status: false,
    id: 0,
  },
  showSendModal: false,
};

const SendCreditContext = createContext<ISendCreditContext>({
  SendCreditState: initialSendCreditState,
  SendCreditDispatch: () => undefined,
});

export const SendCreditContextProvider = SendCreditContext.Provider;
export const SendCreditContextConsumer = SendCreditContext.Consumer;

export default SendCreditContext;
