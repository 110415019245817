import { createContext } from "react";
import { IinitialTagsState, ITagsContext } from "./interface";

const location = document.location.search;
const pathname = document.location.pathname;
const queryParams = new URLSearchParams(location);
const offset = queryParams.get("offset");
const limit = queryParams.get("limit");
const isAsc = queryParams.get("isAsc");
const sort = queryParams.get("sort");
const name = queryParams.get("name");
const status = queryParams.get("status");

export const initialTagsState: IinitialTagsState = {
  showAdd: false,
  showFilter: false,
  showFullTag: {
    id: 0,
    status: false,
  },
  showDelete: {
    id: "",
    status: false,
  },
  isAsc: pathname !== "/marketplace/tags" ? "desc" : isAsc || "desc",
  sortValue: pathname !== "/marketplace/tags" ? "created_at" : sort || "created_at",
  limit: pathname !== "/marketplace/tags" ? 10 : Number(limit) || 10,
  offset: pathname !== "/marketplace/tags" ? 0 : Number(offset) || 0,
  filter: {
    name: name || "",
    status: status || "",
  },
  chip: [],
};

const TagsContext = createContext<ITagsContext>({
  tagsState: initialTagsState,
  tagsDispatch: () => {},
});

export const TagsContextProvider = TagsContext.Provider;
export const TagsContextConsumer = TagsContext.Consumer;

export default TagsContext;
