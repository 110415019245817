import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScanStockMove from "../../../pages/transaction/ScanStockMove";
import ScanStockArrive from "../../../pages/transaction/ScanStockArrive";
import Transaction from "../../../pages/transaction/Transaction";
import TransactionDetail from "../../../pages/transaction/TransactionDetail";
import TransactionProcess from "../../../pages/transaction/TransactionProcess";
import TransactionDetailTracking from "../../../pages/transaction/transaction_detail/TransactionDetail";
import {
  InitialTransactionState,
  TransactionContextProvider,
} from "../../../store/transaction/TransactionContext";
import { TransactionReducer } from "../../../store/transaction/TransactionReducer";
import ScanStockCorrect from "../../../pages/transaction/ScanStockCorrect";

const TransactionRoute = () => {
  const [TransactionState, TransactionDispatch] = useReducer(
    TransactionReducer,
    InitialTransactionState
  );

  return (
    <TransactionContextProvider
      value={{ TransactionState, TransactionDispatch }}
    >
      <div className="font-med">
        <Switch>
          <Route
            path="/marketplace/transaction"
            exact
            component={Transaction}
          />
          <Route
            exact
            path="/marketplace/transaction/:code"
            component={TransactionDetail}
          />
          <Route
            exact
            path="/marketplace/transaction/:code/tracking"
            component={TransactionDetailTracking}
          />
          <Route
            exact
            path="/marketplace/transaction/:code/process"
            component={TransactionProcess}
          />
          <Route
            exact
            path="/marketplace/transaction/:code/scan"
            component={ScanStockMove}
          />
          <Route
            exact
            path="/marketplace/transaction/:code/scan-arrive"
            component={ScanStockArrive}
          />
          <Route
            exact
            path="/marketplace/transaction/:code/scan-correct"
            component={ScanStockCorrect}
          />
          <Redirect from="/marketplace" to="/marketplace/transaction" exact />
        </Switch>
      </div>
    </TransactionContextProvider>
  );
};

export default TransactionRoute;
