import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { FeaturedProductRequest } from "../../../apis/featured_product/FeaturedProductRequest";
import { cancel } from "../../../assets/icons";
import { ActionType } from "../../../store/featured_product/action";
import FeaturedProductContext from "../../../store/featured_product/FeaturedProductContext";

interface IProps {
  data: any;
}

const FeaturedProductCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { FeaturedProductDispatch } = useContext(FeaturedProductContext);

  const handleDeleteTag = (index: number) => {
    const newData: any = data;
    newData.tags.splice(index, 1);
    mutatation.mutate(newData);
  };

  const mutatation = useMutation(
    (newData: any) => FeaturedProductRequest.deleteTag(newData),
    {
      onMutate: () => {
        console.log("mutate delete sizing variant");
      },
      onSettled: async (data: any, error: any) => {
        queryClient.invalidateQueries(`GetFeaturedProduct`);
      },
    }
  );

  return (
    <div className="h-auto xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke">
      <div className="flex justify-between datas-start px-8 py-10">
        <div></div>
        <div className="flex border-2 border-stroke rounded-md p-7">
          <img
            src={data.product.display_image_url}
            className="w-32 h-32"
            alt="img"
          />
        </div>
        <div></div>
      </div>
      <hr />
      {data.tags.length > 0 ? (
        <div className="mx-10">
          {data.tags.map((el: any, index: number) => (
            <div
              className={
                "inline-flex items-center rounded mt-5 mr-5 p-1 border-2 " +
                (el.color === "#ed4846" ? "border-primary" : "border-success")
              }
            >
              <span className="px-2 text-sm">{el.label}</span>
              <button type="button" className="h-6 w-6 p-1 focus:outline-none">
                <img
                  onClick={() => handleDeleteTag(index)}
                  src={cancel}
                  alt="cancel"
                  className="cursor-pointer w-2.5"
                />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="pt-14"></div>
      )}
      <div className="ml-10 mt-3 flex flex-col justify-around">
        <h1 className="text-xl">{data.product.name}</h1>
        <h1 className="text-base mt-2 font-rom">Priority : {data.priority}</h1>
        <div className="flex mt-2">
          <h1 className="mr-1 text-base">Status :</h1>{" "}
          {data.published ? (
            <h1 className="text-success text-base">Posted</h1>
          ) : (
            <h1 className="text-primary text-base">Not Posted</h1>
          )}
        </div>
      </div>
      <div className="flex justify-around text-lg h-20 mt-8 w-full">
        <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1
            onClick={() =>
              history.push(
                `/marketplace/featured-product/edit-featured-product/${data.id}`
              )
            }
            className="hover:text-primary cursor-pointer"
          >
            Edit
          </h1>
        </div>
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1
            onClick={() =>
              FeaturedProductDispatch({
                type: ActionType.showDelete,
                payload: { status: true, id: data.id },
              })
            }
            className="hover:text-primary cursor-pointer"
          >
            Delete
          </h1>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProductCard;
