export const handleChangeChecked = (
  event: React.ChangeEvent<HTMLInputElement>,
  check: any,
  setCheck: any
) => {
  if (check.category && event.target.name === "category_group") {
    setCheck({
      ...check,
      [event.target.name]: event.target.checked,
      category: false,
    });
  } else if (check.category_group && event.target.name === "category") {
    setCheck({
      ...check,
      [event.target.name]: event.target.checked,
      category_group: false,
    });
  } else {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  }
};

export const handleChangeProduct = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, product_id: payload.value });
};

export const handleChangeTicket = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, ticket_id: payload.value });
};

export const handleChangeStore = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, retail_shop_id: payload.value });
};

export const handleChangeProductstore = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, retail_product_id: payload.value });
};

export const handleChangeBrand = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, product_brand_id: payload.value });
};

export const handleChangeCategory = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, product_category_id: payload.value });
};

export const handleChangeCategoryGroup = (
  payload: any,
  setValues: any,
  values: any
) => {
  setValues({ ...values, product_category_group_id: payload.value });
};

export const handleChangeTag = (payload: any, setValues: any, values: any) => {
  setValues({ ...values, tag_id: payload.value });
};

export const handleActive = (setValues: any, values: any) => {
  setValues({ ...values, is_active: !values.is_active });
};
