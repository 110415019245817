import React, { useContext } from "react";
import { useHistory } from "react-router";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import {
  optRoutesSizing,
  optSortingSizing,
  queryparamsSizing,
} from "./helpers/list";
import NewSorting from "../../components/sorting/NewSorting";
import SizingCard from "./components/sizing/SizingCard";
import GetSizing from "./helpers/use_query_hooks/GetSizing";
import SizingContext from "../../store/sizing/SizingContext";
import { ActionType } from "../../store/sizing/action";
import SizingFilter from "./components/sizing/SizingFilter";
import Chip from "../../components/chip/Chip";

const Sizing = () => {
  const history = useHistory();
  const { SizingDispatch, SizingState } = useContext(SizingContext);
  const { data, isFetching, isLoading } = GetSizing();

  return (
    <div>
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesSizing} />
        {SizingState.showFilter && <SizingFilter />}
        <div className="md:mt-12 mt-10">
          <div className="flex justify-end">
            <h1
              onClick={() => history.push(`/marketplace/sizing/add-sizing`)}
              className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
            >
              Add Sizing
            </h1>
          </div>

          <div className="flex justify-between mt-2">
            <div className="flex">
              <h1
                onClick={() =>
                  SizingDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingSizing} />
          </div>

          <Chip
            queryParams={queryparamsSizing}
            ActionType={ActionType}
            dispatchContext={SizingDispatch}
            stateContext={SizingState}
          />
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            {data?.data.map((el: any) => (
              <SizingCard data={el} key={el.id} />
            ))}
          </div>
        )}

        <Pagination
          length={data?.data?.length}
          total={data?.meta?.total}
          isTable={false}
        />
      </section>
    </div>
  );
};

export default Sizing;
