import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import { applyChip } from "../../utils/applyChip/ApplyChip";
import { SetQuery } from "./setQuery";

export class TagsRequest {
  static getTags = async (
    key: string,
    sortValue: string,
    isAsc: string,
    limit: number,
    offset: number,
    filter: any,
    tagsDispatch: any
  ) => {
    try {
      const results: any = await axiosCelestine().get(`admin/tag`, {
        params: {
          ...(filter.name !== 0 && filter.name.length !== 0
            ? { name: filter.name }
            : ""),
          ...(filter.status.length !== 0 ? { is_active: filter.status } : ""),
          limit: limit,
          ...(offset !== 0 ? { offset: offset } : ""),
          sort: `${sortValue} ${isAsc}`,
        },
      });
      const res = results.data;
      window.history.replaceState(
        null,
        "",
        SetQuery(filter, limit, offset, sortValue, isAsc)
      );
      applyChip(filter, tagsDispatch);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteTags = async (id: number, goBack: () => void) => {
    try {
      await axiosCelestine().delete(`admin/tag/${id}`);
      ErrorHandler("#00C62E", "Delete Completed");
      goBack();
    } catch (error) {
      ErrorHandler("#ed4846", "Delete Failed");
    }
  };

  static postTags = async (
    values: any,
    goBack: () => void,
    setSubmitting: any
  ) => {
    try {
      await axiosCelestine().post(`admin/tag`, values);
      ErrorHandler("#00C62E", "Add Completed");
      setSubmitting(false);
      goBack();
    } catch (error) {
      ErrorHandler("#ed4846", "Add Failed");
      setSubmitting(false);
    }
  };

  static getTagsBySlug = async (key: string, id: number) => {
    try {
      const result = await axiosCelestine().get(`admin/tag/${id}`);
      const res = result.data;
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  static updateTags = async (
    id: number,
    values: any,
    goBack: () => void,
    setSubmitting: any
  ) => {
    try {
      await axiosCelestine().put(`admin/tag/${id}`, values);
      ErrorHandler("#00C62E", "Edit Completed");
      setSubmitting(false);
      // goBack();
    } catch (error) {
      ErrorHandler("#ed4846", "Edit Failed");
      setSubmitting(false);
    }
  };

  static addProductToTags = async (
    payload: any,
    id: string,
    setShowFullTag: any
  ) => {
    try {
      await axiosCelestine().post(`admin/product/${payload.value}/tag`, {
        tag_ids: [+id],
      });
      ErrorHandler("#00C62E", "Add Completed");
    } catch (error) {
      if (error.response.data.errors === "tag already exists") {
        ErrorHandler("#ed4846", error.response.data.errors);
      } else {
        setShowFullTag(true);
      }
    }
  };

  static getProductPerTag = async (key: string, id: string) => {
    try {
      const result = await axiosCelestine().get(`admin/tag/${id}/product`, {
        params: { limit: 10 },
      });
      const data = result.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteProductFromTag = async (product_id: number, tag_id: number) => {
    try {
      await axiosCelestine().delete(`admin/tag/${tag_id}/product`, {
        data: {
          product_ids: [product_id],
        },
      });
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error) {
      ErrorHandler("#ed4846", "Failed delete product from tag");
    }
  };

  static deleteTagFromProduct = async (tag_id: number, product_id: number) => {
    try {
      await axiosCelestine().delete(`admin/product/${product_id}/tag`, {
        data: {
          tag_ids: [tag_id],
        },
      });
      ErrorHandler("#00C62E", "Delete Completed");
    } catch (error) {
      ErrorHandler("#ed4846", "Failed delete tag from product");
    }
  };

  static getProductById = async (id: number) => {
    try {
      const res = await axiosCelestine().get(`admin/product/${id}`);
      const result = res.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static addProductToTagsContinue = async (
    product_id: number,
    tag_id: string,
    setShowFullTag: any
  ) => {
    try {
      await axiosCelestine().post(`admin/product/${product_id}/tag`, {
        tag_ids: [+tag_id],
      });
      ErrorHandler("#00C62E", "Add Completed");
      setShowFullTag(false);
    } catch (error) {
      ErrorHandler("#ed4846", "Please remove a tag");
    }
  };

  static getTagsinBanner = async (key: string, id: number) => {
    try {
      const results: any = await axiosCelestine().get(`admin/banner`, {
        params: {
          tag_id: id,
        },
      });
      const res = results.data.data;
      return res;
    } catch (error) {
      console.log(error);
    }
  };
}
