import React, { Fragment, useContext } from "react";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import CircularLoad from "../../../components/loading/CircularLoad";
import Loading from "../../../components/loading/Loading";
import NewSorting from "../../../components/sorting/NewSorting";
import { ActionType } from "../../../store/invoice/action";
import InvoiceContext from "../../../store/invoice/InvoiceContext";
import { optSortingCreditEffect } from "../../credit_effect/helpers/list";
import {
  optRoutesWaitingInvoice,
  queryParamsWaitingInvoice,
} from "../helpers/list";
import GetWaitingInvoice from "../helpers/use_hooks/GetWaitingInvoice";
import TopupInvoice from "./waiting_invoice_components/TopupInvoice";
import WaitingInvoiceFilter from "./waiting_invoice_components/WaitingInvoiceFilter";
import WaitingInvoiceTable from "./waiting_invoice_components/WaitingInvoiceTable";

const WaitingInvoice = () => {
  const { InvoiceState, InvoiceDispatch } = useContext(InvoiceContext);
  const { showTopup, showFilter } = InvoiceState;
  const { data, isLoading, isFetching } = GetWaitingInvoice();

  return (
    <Fragment>
      <section id="top">
        <DynamicBreadcrumb data={optRoutesWaitingInvoice} />
        {showTopup.status && <TopupInvoice />}
        {showFilter && <WaitingInvoiceFilter />}
        <div className="md:mt-12 mt-10">
          <div className="flex justify-between my-2.5">
            <div className="flex items-end">
              <h1
                onClick={() =>
                  InvoiceDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingCreditEffect} />
          </div>
          <Chip
            dispatchContext={InvoiceDispatch}
            stateContext={InvoiceState}
            queryParams={queryParamsWaitingInvoice}
            ActionType={ActionType}
          />
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <article className="flex">
            <WaitingInvoiceTable data={data} />
          </article>
        )}
      </section>
    </Fragment>
  );
};

export default WaitingInvoice;
