import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { TagsRequest } from "../../../../apis/tags/TagsRequest";
import { cancel } from "../../../../assets/icons";

interface IProps {
  data: any;
  setOffset: any;
}

const ProductCard: React.FC<IProps> = ({ data, setOffset }) => {
  const params: { id: string } = useParams();

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TagsRequest.deleteProductFromTag(data.id, +params.id),
    {
      onMutate: () => {
        console.log("mutate");
      },
      onSettled: async (data: any, error: any) => {
        await setOffset(10);
        await queryClient.invalidateQueries("getProductPerTag");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const mutationTag = useMutation(
    (id: number) => TagsRequest.deleteTagFromProduct(id, data.id),
    {
      onMutate: () => {
        console.log("mutate");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getProductPerTag");
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const handleDeleteProductFromTag = () => {
    mutation.mutate();
  };

  const handleDeleteTagFromProduct = (id: number) => {
    mutationTag.mutate(id);
  };

  return (
    <div className="border-2 rounded-md">
      <div className="flex items-start px-8 pt-8">
        <img
          src={data.display_image_url}
          className="w-24 border p-3 rounded-md"
          alt="img"
        />
        <div className="mx-7">
          <h1 className="font-rom">SKU: {data.code}</h1>
          <h1 className="font-med text-xl mt-1.5">{data.name}</h1>
        </div>
      </div>

      <div className="my-4 flex items-center px-8 py-3">
        <h1 className="font-rom">Tags:</h1>
        <div className="md:flex hidden">
          {data.tags.map((el: any) => (
            <div
              className={
                "inline-flex items-center rounded ml-3 p-1 " +
                (el.is_active
                  ? "border-stroke bg-white border-2"
                  : "bg-stroke")
              }
            >
              <span className="px-2 text-sm">{el.name}</span>
              <button type="button" className="h-6 w-6 p-1 focus:outline-none">
                <img
                  onClick={() => handleDeleteTagFromProduct(el.id)}
                  src={cancel}
                  alt="cancel"
                  className="cursor-pointer w-2.5"
                />
              </button>
            </div>
          ))}
        </div>
      </div>

      <hr />
      <div className="flex items-center w-full justify-center py-5">
        <h1
          onClick={handleDeleteProductFromTag}
          className="cursor-pointer hover:text-primary"
        >
          Delete
        </h1>
      </div>
    </div>
  );
};

export default ProductCard;
