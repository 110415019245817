const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/banner",
    name: "Banner",
    hash: "#general",
  },
];

const optRoutesAddBanner = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/banner",
    name: "Banner",
    hash: "#general",
  },
  {
    id: 3,
    path: "/marketplace/banner/add-banner",
    name: "Add Banner",
  },
];

const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "expired_at",
    name: "Expired at",
  },
  {
    value: "scope",
    name: "Banner Post in",
  },
  {
    value: "is_active",
    name: "Status",
  },
];

export const queryparamsBanner: any[] = [
  { real_name: "status", show_name: "status" },
  { real_name: "scope", show_name: "banner_post_in" },
  { real_name: "action", show_name: "banner_action" },
  { real_name: "expired_at_from", show_name: "expired_at_from" },
  { real_name: "expired_at_to", show_name: "expired_at_to" },
];

export { optRoutes, optRoutesAddBanner, optSorting };
