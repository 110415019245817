import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import Countdown, { zeroPad } from "react-countdown";
import isEmpty from "lodash.isempty";
import GetCampaignTemplateId from "../../helpers/use_query_hook/GetCampaignTemplateId";
import CircularLoad from "../../../../components/loading/CircularLoad";
import { collection, onSnapshot } from "@firebase/firestore";
import { ActionType } from "../../../../store/campaign/action";
import db from "../../../../config/InitFirebase";

const renderer = (minutes: number, seconds: number, completed: boolean) => {
  if (completed) {
    return <ToShufflePage />;
  } else {
    return (
      <div className="text-white text-3xl font-rom flex items-center my-8">
        <h1>Start in</h1>
        <div className="text-black ml-4 flex">
          <div className="h-16 w-12 bg-white shadow-md rounded-md flex justify-center items-center mr-1">
            {zeroPad(minutes)[0]}
          </div>
          <div className="h-16 w-12 bg-white shadow-md rounded-md flex justify-center items-center">
            {zeroPad(minutes)[1]}
          </div>
          <div className="text-white flex justify-center items-center mx-2">
            :
          </div>
          <div className="h-16 w-12 bg-white shadow-md rounded-md flex justify-center items-center mr-1">
            {zeroPad(seconds)[0]}
          </div>
          <div className="h-16 w-12 bg-white shadow-md rounded-md flex justify-center items-center">
            {zeroPad(seconds)[1]}
          </div>
        </div>
      </div>
    );
  }
};

const ToShufflePage = () => {
  const history = useHistory();
  const params: { totalWinner: string; id: string; templateId: string } =
    useParams();
  useEffect(() => {
    history.push(
      `/marketplace/campaign/edit-campaign/${params.id}/spin-winner/${params.templateId}/shuffle/${params.totalWinner}/0`
    );
  }, []);
  return <div>End</div>;
};

const SpinProduct = () => {
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { data_spinner } = CampaignState;
  const params: { id: string; templateId: string } = useParams();
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);

  const getSnapshot = () => {
    return onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === +params.templateId &&
              doc.campaign_id === +params.id
          )[0];
        CampaignDispatch({
          type: ActionType.AddDataSpinner,
          payload: data,
        });
      }
    );
  };

  useEffect(() => {
    getSnapshot();
  }, []);

  return (
    <div className="fixed top-0 left-0 z-30 bg-black w-screen h-screen flex flex-col justify-center items-center">
      {isEmpty(data_spinner) ||
      data_spinner?.winners?.length <= 0 ||
      isLoading ? (
        <div className="flex justify-center mt-20">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img src={data?.cool_image} alt="-" className="w-48" />
          <h1 className="text-white font-med text-4xl mt-8 uppercase">
            GIVEAWAY
          </h1>
          <h1 className="text-white font-rom text-3xl mt-2">
            {data?.product_name}
          </h1>

          <div className="text-red-500">
            <Countdown
              zeroPadTime={2}
              date={
                new Date(
                  data_spinner?.winners[0]?.start_animation_at?.seconds * 1000
                )
              }
              renderer={(props) =>
                renderer(props.minutes, props.seconds, props.completed)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SpinProduct;
