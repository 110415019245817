import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetParticipantWinner = () => {
  const params: { templateId: string } = useParams();
  const query = useQueries(useLocation);
  const { data, isLoading, isFetching } = useQuery(
    [
      "GetParticipantWinner",
      query.get("limit"),
      query.get("offset"),
      params.templateId,
    ],
    () => CampaignRequest.getParticipantWinner(params.templateId),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetParticipantWinner;
