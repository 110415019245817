import moment from "moment";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  arrowDown,
  arrowUp,
  asc,
  desc,
  eye,
  detail,
} from "../../../assets/icons";
import Pagination from "../../../components/pagination/Pagination";
import OnSellingContext from "../../../store/on_selling/OnSellingContext";
import { formatRupiah } from "../../../utils/format_rupiah/FormatRupiah";
import { useQueries } from "../../../utils/query_searchParams/QuerySearchParams";

interface IProps {
  data: any;
}

const OnSellingSeeAllTable: React.FC<IProps> = ({ data }) => {
  const { onSellingDispatch } = useContext(OnSellingContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = useQueries(useLocation);
  const sorting: any = query.get("sort");
  const isAsc: any = query.get("isAsc");

  if (data?.data.length === 0) {
    return (
      <div className="flex justify-center w-full mt-20">
        <h1>Empty Data</h1>
      </div>
    );
  }

  return (
    <div>
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Size</h1>
                <img
                  src={sorting === "size" && isAsc === "asc" ? asc : desc}
                  onClick={() => {
                    let new_search = search.replace(sorting, "size");
                    let fix_search;
                    isAsc === "asc"
                      ? (fix_search = new_search.replace(isAsc, "desc"))
                      : (fix_search = new_search.replace(isAsc, "asc"));
                    history.push({ pathname, search: fix_search });
                  }}
                  alt="asc-desc"
                  className="w-4 ml-3 cursor-pointer"
                />
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Seller</h1>
                {/* <img
                  src={sort.seller === "asc" ? asc : desc}
                  onClick={() =>
                    setSort({
                      ...sort,
                      seller: sort.seller === "asc" ? "desc" : "asc",
                    })
                  }
                  alt="asc-desc"
                  className="w-4 ml-3 cursor-pointer"
                /> */}
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Status</h1>
                {/* <img
                  src={sort.status === "asc" ? asc : desc}
                  onClick={() =>
                    setSort({
                      ...sort,
                      status: sort.status === "asc" ? "desc" : "asc",
                    })
                  }
                  alt="asc-desc"
                  className="w-4 ml-3 cursor-pointer"
                /> */}
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Price</h1>
                {/* <img
                  src={sort.status === "asc" ? asc : desc}
                  onClick={() =>
                    setSort({
                      ...sort,
                      status: sort.status === "asc" ? "desc" : "asc",
                    })
                  }
                  alt="asc-desc"
                  className="w-4 ml-3 cursor-pointer"
                /> */}
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Selling Time</h1>
                <img
                  src={sorting === "created_at" && isAsc === "asc" ? asc : desc}
                  onClick={() => {
                    let new_search = search.replace(sorting, "created_at");
                    let a;
                    isAsc === "asc"
                      ? (a = new_search.replace(isAsc, "desc"))
                      : (a = new_search.replace(isAsc, "asc"));
                    history.push({ pathname, search: a });
                  }}
                  alt="asc-desc"
                  className="w-4 ml-3 cursor-pointer"
                />
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">{el.product_size}</td>
              <td className="border-r">{el.seller_username}</td>
              <td
                className={
                  "border-r font-med " +
                  (el.pre_order ? "text-yellow-400" : "text-success")
                }
              >
                {el.pre_order ? "Pre-Order" : "Brand New"}
              </td>
              <td className="border-r">
                <div className="flex flex-col justify-center">
                  <h1>{formatRupiah(el.price)}</h1>
                  <h1 className="text-xs mt-0.5">
                    {el.previous_price_diff && el.previous_price_diff > 0 ? (
                      <div className="flex justify-center text-textPrimary">
                        <h1>(</h1>
                        <h1>{formatRupiah(el.previous_price_diff)}</h1>
                        <h1>)</h1>
                        <img
                          src={arrowUp}
                          alt="arrowdown"
                          className="w-2 ml-2"
                        />
                      </div>
                    ) : el.previous_price_diff && el.previous_price_diff < 0 ? (
                      <div className="flex justify-center text-textPrimary">
                        <h1>(</h1>
                        <h1>
                          {formatRupiah(Math.abs(el.previous_price_diff))}
                        </h1>
                        <h1>)</h1>
                        <img
                          src={arrowDown}
                          alt="arrowdown"
                          className="w-2 ml-2"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </h1>
                </div>
              </td>

              <td className="border-r">
                <div>
                  <h1>{moment(el.created_at).format("lll")}</h1>
                  <h1 className="text-sm mt-0.5">
                    {moment().diff(moment(el.created_at), "days") >= 1
                      ? `${moment().diff(moment(el.created_at), "days")} days`
                      : "Same day"}
                  </h1>
                </div>
              </td>

              <td className="">
                <div className="w-full flex justify-evenly">
                  <img
                    onClick={() =>
                      history.push(
                        `/marketplace/on-selling/${data?.data[0]?.code}`
                      )
                    }
                    src={eye}
                    alt="eyes"
                    className="w-6 cursor-pointer"
                  />
                  {el.has_multiple_sellers && (
                    <img
                      onClick={() =>
                        onSellingDispatch({
                          type: "show_see_all_per_size",
                          payload: {
                            status: true,
                            size: el.product_size_id,
                          },
                        })
                      }
                      src={detail}
                      alt="see-all"
                      className="w-5 cursor-pointer"
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default OnSellingSeeAllTable;
