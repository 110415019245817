const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/topup",
    name: "Top up",
  },
];

const optRoutesDetail = (id: string, userId: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/topup",
      name: "Top up",
    },
    {
      id: 3,
      path: `/marketplace/topup/${id}/${userId}`,
      name: "Details",
    },
  ];
};

const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "code",
    name: "Top up Code",
  },
  {
    value: "username",
    name: "Username",
  },
  {
    value: "amount",
    name: "Amount",
  },
  {
    value: "payment_channel",
    name: "Payment Channel",
  },
  {
    value: "payment_status",
    name: "Payment Status",
  },
  {
    value: "payment_type",
    name: "Payment Method",
  },
];

export const queryparamsTopUp: any[] = [
  { real_name: "payment_channel", show_name: "payment_channel" },
  { real_name: "payment_status", show_name: "payment_status" },
  { real_name: "code", show_name: "code" },
  { real_name: "username", show_name: "username" },
  { real_name: "top_up_from", show_name: "top_up_from" },
  { real_name: "top_up_to", show_name: "top_up_to" },
];

export { optRoutes, optSorting, optRoutesDetail };
