import React, { useReducer } from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import CrawlResult from "../../../../pages/product/product_need_review/components/product_need_review/CrawlResult";
import ProductNeedReview from "../../../../pages/product/product_need_review/ProductNeedReview";
import ReviewProduct from "../../../../pages/product/product_need_review/ReviewProduct";
import {
  initialProductReviewState,
  ProductReviewContextProvider,
} from "../../../../store/product/product_need_review/ProductReviewContext";
import { productReviewReducer } from "../../../../store/product/product_need_review/ProductReviewReducer";

const ProductNeedReviewRoute = () => {
  const [productReviewState, productReviewDispatch] = useReducer(
    productReviewReducer,
    initialProductReviewState
  );
  return (
    <ProductReviewContextProvider
      value={{ productReviewState, productReviewDispatch }}
    >
      <div className="md:pt-28 sm:pt-20 pt-16 md:pb-14 pb-7 font-med">
        <Switch>
          <Route
            path="/marketplace/product-need-review"
            exact
            component={ProductNeedReview}
          />
          <Route
            path="/marketplace/product-need-review/crawl-result"
            exact
            component={CrawlResult}
          />
          <Route
            path="/marketplace/product-need-review/review-product/:id"
            exact
            component={ReviewProduct}
          />
          <Redirect
            from="/marketplace"
            to="/marketplace/product-need-review"
            exact
          />
        </Switch>
      </div>
    </ProductReviewContextProvider>
  );
};

export default ProductNeedReviewRoute;
