import React, { useContext } from "react";
import { cancel } from "../../../../../assets/icons";
import Input from "../../../../../components/input/Input";
import SmallButton from "../../../../../components/button/SmallButton";
import TransactionContext from "../../../../../store/transaction/TransactionContext";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TransactionRequest } from "../../../../../apis/transactions/Transaction";
import { useParams } from "react-router-dom";
import { ActionType } from "../../../../../store/transaction/action";

interface IProps {
  location: string;
}

const ConfirmLocationCard: React.FC<IProps> = ({ location }) => {
  const params: { code: string } = useParams();
  const { TransactionDispatch, TransactionState } =
    useContext(TransactionContext);
  const { showConfirmLocation } = TransactionState;

  const goBack = () => {
    TransactionDispatch({
      type: ActionType.ShowConfirm,
      payload: {
        id: 0,
        location: "",
        status: false,
      },
    });
  };

  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    setErrors,
    handleReset,
    isSubmitting,
  } = useFormik({
    initialValues: {
      location: "",
    },
    validationSchema: Yup.object({
      location: Yup.string().required("Location is required"),
    }),
    onSubmit: async (values: any) => {
      if (values.location === showConfirmLocation.location) {
        await mutation.mutate(values);
      } else {
        setErrors({ location: "Incorrect confirm text!" });
      }
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      TransactionRequest.selectCustomTrackingLocation(
        params.code,
        showConfirmLocation.id,
        goBack
      ),
    {
      onMutate: () => {
        console.log("mutate select location");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getBnibTransaction");
        await queryClient.invalidateQueries("getCustomTrackingStep");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 pt-14">
      <div className="bg-white w-1/3 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center px-8 py-6">
          <div className="flex items-center">
            <h1 className="text-xl flex text-primary">Confirm</h1>
            <h1 className="ml-1 text-xl">Location</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-8">
          <div>
            <div className="flex justify-between mt-5">
              <h1 className="md:text-base text-sm text-textPrimary">
                Please write <strong>'{location}'</strong> to continue
              </h1>
            </div>
            <Input
              type={"text"}
              handleChange={handleChange}
              disable={false}
              name={"location"}
              placeholder={"Place holder"}
            />
            {touched.location && errors.location ? (
              <h1 className="text-primary font-rom mb-1 -mt-1 text-sm">
                {errors.location}
              </h1>
            ) : null}
          </div>

          <div className="flex justify-center my-5 outline-none">
            <SmallButton
              isSubmitting={isSubmitting}
              loadColor={"white"}
              dirty={dirty}
              height={10}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Confirm"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmLocationCard;
