export enum ActionType {
  ShowDelete,
  ShowSendCredit,
}

export interface ShowSendCredit {
  type: ActionType.ShowSendCredit;
}

export interface ShowDelete {
  type: ActionType.ShowDelete;
  payload: {
    status: boolean;
    id: number;
  };
}

export type SendCreditAction = ShowDelete | ShowSendCredit;
