import React, { useContext } from "react";
import { useHistory } from "react-router";
import { CouponRequest } from "../../../../apis/coupon/CouponRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import { ActionType } from "../../../../store/coupon/action";
import CouponContext from "../../../../store/coupon/CouponContext";

const ShowConfirmAddCoupon: React.FC = () => {
  const { couponState, couponDispatch } = useContext(CouponContext);
  const { simulateAddCouponData, formdataAddCoupon } = couponState;
  const history = useHistory();
  const handleShowConfirm = () => {
    couponDispatch({
      type: ActionType.ShowConfirmAdd,
      payload: [],
    });
  };

  const handleSubmit = (formdata: any) => {
    formdata.delete("simulated");
    CouponRequest.addCoupon(formdata, () => history.goBack());
    couponDispatch({
      type: ActionType.ShowConfirmAdd,
      payload: [],
    });
  };

  return (
    <div className="fixed z-50 h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white rounded-lg cursor-default overflow-hidden h-5/6 shadow-xl w-2/5 xl:1/2">
        <div className="flex justify-between items-center px-10 py-5 h-12% shadow-sm">
          <div className="text-xl xl:text-2xl flex">
            <h1>Add</h1>
            <h1 className="text-primary ml-1">Coupon</h1>
          </div>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowConfirm}
          />
        </div>
        <hr />
        <div className="h-3/4 overflow-auto">
          <div className="px-10 py-5">
            <h1 className="text-xl">{simulateAddCouponData?.display_name}</h1>
            {/* <div className="grid grid-cols-5 gap-3 my-3">
              {dataImage.map((el) => (
                <div className="border rounded-md p-1 shadow-md">
                  <img src={el} alt={el} />
                </div>
              ))}
            </div> */}
            <div className="flex my-2">
              <h1 className="mr-1.5">Status:</h1>
              <h1
                className={
                  simulateAddCouponData.active_status
                    ? "text-success"
                    : "text-primary"
                }
              >
                {simulateAddCouponData.active_status ? "Posted" : " Not Posted"}
              </h1>
            </div>
          </div>
          <hr />
          <div className="px-10 py-5 text-textPrimary overflow-auto">
            <div className="flex">
              <h1 className="w-1/2">Coupon Type</h1>
              <h1 className="w-1/2 font-rom">
                : {simulateAddCouponData.for_reward ? "For Reward" : "Public"}
              </h1>
            </div>

            <div className="flex my-1">
              <h1 className="w-1/2">Coupon For</h1>
              <h1 className="w-1/2 font-rom">
                : {simulateAddCouponData.scopes.map((el: any) => el + ", ")}
              </h1>
            </div>

            <div className="flex my-1">
              <h1 className="w-1/2">Hide in Application</h1>
              <h1 className="w-1/2 font-rom">
                : {simulateAddCouponData.hide_in_coupon_list ? "Yes" : "No"}
              </h1>
            </div>

            <div className="flex my-1">
              <h1 className="w-1/2">Discount Type</h1>
              <h1 className="w-1/2 font-rom">
                : {simulateAddCouponData.discount_type}
              </h1>
            </div>
            <div className="flex my-1">
              <h1 className="w-1/2">Discount by</h1>
              <h1 className="w-1/2 font-rom">
                :
                {` ${simulateAddCouponData.discount_mode} (${
                  simulateAddCouponData.discount_mode === "amount"
                    ? `${simulateAddCouponData.discount_value}`
                    : `${simulateAddCouponData.discount_value}%`
                })`}
              </h1>
            </div>
            <div className="flex my-1">
              <h1 className="w-1/2">For Type</h1>
              <h1 className="w-1/2 font-rom">
                : {simulateAddCouponData.pre_order ? "Pre Order" : "Brand New"}
              </h1>
            </div>
            <div className="flex my-1">
              <h1 className="w-1/2">Description</h1>
              <h1 className="w-1/2 font-rom">
                :
                {` ${simulateAddCouponData?.description?.available_time_range_of_the_day}, ${simulateAddCouponData?.description?.discount_type}, ${simulateAddCouponData?.description.expired_at}, ${simulateAddCouponData?.description?.max_discount_amount}, ${simulateAddCouponData?.description?.min_subtotal},  ${simulateAddCouponData?.description?.periodic_limit}, ${simulateAddCouponData?.description?.pre_order}, ${simulateAddCouponData?.description?.scopes}`}
              </h1>
            </div>
            {/* <div className="flex my-1">
              <h1 className="w-1/2">Release Date</h1>
              <h1 className="w-1/2">
                : {moment(data.release_date).format("LL")}
              </h1>
            </div> */}
          </div>
          <hr />
        </div>

        <div className="flex justify-center items-center pt-2 outline-none h-12% shadow-inner">
          <SmallButton
            loadColor={"white"}
            height={10}
            type={"button"}
            color={"bg-primary"}
            hoverColor={"bg-red-300"}
            text={"Submit"}
            textColor={"text-white"}
            handleClick={() => handleSubmit(formdataAddCoupon)}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowConfirmAddCoupon;
