export enum ActionType {
  ShowFilter,
  HandleChip,
}

export interface ShowFiler {
  type: ActionType.ShowFilter;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export type OrderAction = HandleChip | ShowFiler;
