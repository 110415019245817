import { createContext } from "react";
import { ICouponContext, ICouponState } from "./state";

export const initialCouponState: ICouponState = {
  showFilter: false,
  showSendCoupon: false,
  showConfirmAdd: false,
  simulateAddCouponData: [],
  simulateEditCouponData: [],
  formdataAddCoupon: null,
  formdataEditCoupon: null,
  showConfirmEdit: false,
  showDelete: {
    status: false,
    id: 0,
  },
  chip: [],
};

const CouponContext = createContext<ICouponContext>({
  couponState: initialCouponState,
  couponDispatch: () => undefined,
});

export const CouponContextProvider = CouponContext.Provider;
export const CouponContextConsumer = CouponContext.Consumer;

export default CouponContext;
