import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cancel } from "../../assets/icons";
import { queryparams } from "../../pages/order/helpers/list";
import { useQueries } from "../../utils/query_searchParams/QuerySearchParams";

interface IProps {
  dispatchContext: any;
  stateContext: any;
  queryParams?: any;
  ActionType?: any;
}

const Chip: React.FC<IProps> = ({
  dispatchContext,
  stateContext,
  queryParams,
  ActionType,
}) => {
  const query = useQueries(useLocation);
  const { search, pathname, hash } = useLocation();
  const history = useHistory();
  const { chip, filter } = stateContext;

  const handleChipByKey = (el: any) => {
    const new_data = chip.filter((item: any) => item !== el);
    dispatchContext({
      type: ActionType?.HandleChip ? ActionType?.HandleChip : "handle_chip",
      payload: new_data,
    });
    const new_filter = { ...filter, [el]: "" };
    if (!ActionType && !queryparams) {
      dispatchContext({
        type: "handle_filter",
        payload: new_filter,
      });
    } else {
    }
    for (const key in queryParams) {
      let new_search;
      let value: any = query.get(queryParams[key].real_name);
      if (value !== null && queryParams[key].show_name === el) {
        new_search = search.replace(
          `&&${queryParams[+key].real_name}=${value}`,
          ""
        );
        let filter: any = query.get("filter");
        let new_search_next = new_search.replace(filter, "false");
        history.push({ pathname, search: new_search_next, hash });
      }
    }
  };

  return (
    chip && (
      <div className="md:flex hidden">
        {chip.map((el: any, idx: number) => (
          <div
            key={idx}
            className="inline-flex items-center rounded mr-3 bg-white border-2 border-stroke p-1 mt-1"
          >
            <span className="px-2 text-sm">{el.split("_").join(" ")}</span>
            <button type="button" className="h-6 w-6 p-1 focus:outline-none">
              <img
                onClick={() => handleChipByKey(el)}
                src={cancel}
                alt="cancel"
                className="cursor-pointer w-2.5"
              />
            </button>
          </div>
        ))}
      </div>
    )
  );
};

export default Chip;
