import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import OnSellingContext from "../../../store/on_selling/OnSellingContext";
import { formatRupiah } from "../../../utils/format_rupiah/FormatRupiah";

interface IProps {
  data: any;
  index: number;
}

const OnSellingCard: React.FC<IProps> = ({ data, index }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { onSellingDispatch } = useContext(OnSellingContext);

  return (
    <div className="w-full h-auto border-2 border-stroke rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4">
        <div className="border-r-2 border-stroke flex justify-center">
          <img
            src={data.display_image_url}
            alt={data.slug}
            className="w-40 m-1 rounded-md shadow-lg"
          />
        </div>

        <div className="flex flex-col items-start px-6 justify-center border-r-2 border-stroke">
          <div className="flex">
            <h1 className="text-base font-rom mr-1">Size: </h1>
            <select
              name="size"
              value={data?.selected_bnib_product_detail?.product_size_id}
              onChange={(e: any) => {
                onSellingDispatch({
                  type: "set_size",
                  payload: { index, value: e.target.value },
                });
                onSellingDispatch({
                  type: "set_seller",
                  payload: { index, value: 0 },
                });
                onSellingDispatch({
                  type: "set_product",
                  payload: { index, value: data.id },
                });
                onSellingDispatch({
                  type: "set_status",
                  payload: { index, value: "-" },
                });
              }}
              className="focus:outline-none cursor-pointer font-rom"
            >
              {data?.selected_bnib_product_detail?.available_product_sizes?.map(
                (el: any) => (
                  <option value={el.id}>{el.size}</option>
                )
              )}
            </select>
          </div>
          <div>
            <h1 className="text-sm mt-1.5 font-rom">SKU: {data.code}</h1>
            <h1 className="text-base mt-1">{data.name}</h1>
          </div>
          <div className="flex items-center mt-1">
            <h1 className="mr-1 text-base">Status :</h1>
            <select
              name="status"
              onChange={(e: any) => {
                onSellingDispatch({
                  type: "set_status",
                  payload: { index, value: e.target.value },
                });
                onSellingDispatch({
                  type: "set_seller",
                  payload: { index, value: 0 },
                });
              }}
              value={data?.selected_bnib_product_detail.pre_order}
              className={
                "focus:outline-none cursor-pointer text-base " +
                (data?.selected_bnib_product_detail.pre_order === false
                  ? "text-success"
                  : "text-yellow-300")
              }
            >
              {data?.selected_bnib_product_detail?.available_sell_type_options?.map(
                (el: any) => (
                  <option value={el.value}>{el.label}</option>
                )
              )}
            </select>
          </div>
        </div>

        <div className="flex flex-col items-start justify-evenly py-3 px-6 border-r-2 border-stroke">
          <div className="w-full">
            <h1 className="text-base">Seller</h1>
            <select
              name="seller"
              value={data?.selected_bnib_product_detail.seller.id}
              onChange={(e: any) => {
                onSellingDispatch({
                  type: "set_seller",
                  payload: { index, value: e.target.value },
                });
              }}
              className="w-full font-rom py-1 -ml-1 mt-1 focus:outline-none cursor-pointer ease-in-out"
            >
              {data?.selected_bnib_product_detail?.available_sellers?.map(
                (el: any) => (
                  <option value={el.id}>{el.username}</option>
                )
              )}
            </select>
          </div>
          <div>
            <h1 className="text-lg mt-2">
              {formatRupiah(data.selected_bnib_product_detail.price)}
            </h1>
          </div>
        </div>

        <div className="flex items-center justify-evenly mx-10">
          <h1
            onClick={() =>
              history.push({
                pathname: `${url}/${data.id}/see-all`,
                search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
              })
            }
            className="text-base hover:text-primary cursor-pointer"
          >
            See All
          </h1>
          <h1
            onClick={() =>
              history.push(`${url}/${data?.selected_bnib_product_detail?.code}`)
            }
            className="text-base hover:text-primary cursor-pointer"
          >
            Details
          </h1>
        </div>
      </div>
    </div>
  );
};

export default OnSellingCard;
