import React, { useEffect, useState } from "react";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import { optRoutesEditSizing } from "./helpers/list";
import AsyncSelect from "react-select/async";
import {
  loadOptionsBrand,
  loadOptionsCategory,
} from "../../utils/loadOption/LoadOption";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import NavDetail from "../../components/navbar/NavDetail";
import { useHistory, useParams } from "react-router-dom";
import MenForm from "./components/add_sizing/MenForm";
import WomenForm from "./components/add_sizing/WomenForm";
import GetSizingGroup from "./helpers/use_query_hooks/GetSizingGroup";
import Loading from "../../components/loading/Loading";
import UnisexForm from "./components/add_sizing/UnisexForm";
import GetExceptionBrand from "./helpers/use_query_hooks/GetExceptionBrand";
import ExceptionBrandList from "./components/exception_brand/ExceptionBrandList";
import YouthForm from "./components/add_sizing/YouthForm";
import InfantForm from "./components/add_sizing/InfantForm";

const EditSizing = () => {
  const history = useHistory();
  const params: { product_category_id: string; product_brand_id: string } =
    useParams();

  const { values, setValues, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        sex: "men",
        men: false,
        women: false,
        unisex: false,
        youth: false,
        infant: false,
        product_category: {
          value: 0,
          label: "",
        },
        product_brand: {
          value: 0,
          label: "",
        },
        description: "",
      },
      validationSchema: Yup.object({
        product_brand: Yup.object().shape({
          value: Yup.string().required("Product brand is required"),
        }),
        product_category: Yup.object().shape({
          value: Yup.string().required("Product Category is required"),
        }),
      }),
      onSubmit: async () => {
        //   await productDepatuDispatch({
        //     type: ActionType.ShowConfirmProductAdd,
        //   });
      },
    });

  const { data, isLoading } = GetSizingGroup(
    params.product_category_id,
    params.product_brand_id,
    values.sex
  );
  const { data: dataException, isLoading: loadException } = GetExceptionBrand(
    params.product_category_id,
    params.product_brand_id
  );

  useEffect(() => {
    if (data) {
      setValues({
        ...values,
        product_category: {
          value: data.product_category_id,
          label: data.product_category_name,
        },
        product_brand: {
          value: data.product_brand_id,
          label: data.product_brand_name,
        },
        men: data.available_genders[0] === "men" ? true : false,
        women: data.available_genders[1] === "women" ? true : false,
        youth: data.available_genders[2] === "youth" ? true : false,
        unisex: data.available_genders[5] === "unisex" ? true : false,
        infant: data.available_genders[3] === "infant" ? true : false,
      });
    }
  }, [data]);

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Edit"
          title2="Sizing"
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb
          data={optRoutesEditSizing(
            params.product_category_id,
            params.product_brand_id
          )}
        />
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="my-10">
              <div className="my-2">
                <h1 className="text-textPrimary">Select a Category</h1>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isSearchable={true}
                  loadOptions={loadOptionsCategory}
                  value={values.product_category}
                  onChange={(e: any) =>
                    setValues({ ...values, product_category: e })
                  }
                  className="font-rom mt-3 pb-3.5 text-sm"
                />
                {touched.product_category && errors.product_category && (
                  <h1 className="text-primary font-rom -mt-1 text-xs">
                    {errors.product_category.value}
                  </h1>
                )}
              </div>
              <div className="my-2">
                <h1 className="text-textPrimary">Select a Brand</h1>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isSearchable={true}
                  loadOptions={loadOptionsBrand}
                  value={values.product_brand}
                  onChange={(e: any) =>
                    setValues({ ...values, product_brand: e })
                  }
                  className="font-rom mt-3 pb-3.5 text-sm"
                />
                {touched.product_brand && errors.product_brand && (
                  <h1 className="text-primary font-rom -mt-1 text-xs">
                    {errors.product_brand.value}
                  </h1>
                )}
              </div>
              <div className="my-2">
                <h1 className="text-textPrimary">Description</h1>
                <div className="w-full  flex items-center h-full mt-3 pb-3.5">
                  <textarea
                    value={values.description}
                    onChange={handleChange}
                    placeholder="Place holder"
                    name="description"
                    className="w-full border-2 border-stroke rounded-md font-rom md:text-sm text-xs px-3 py-2 border-r-2 focus:ring-1 focus:outline-none"
                  ></textarea>
                </div>
                {touched.description && errors.description && (
                  <h1 className="text-primary font-rom -mt-1 text-xs">
                    {errors.description}
                  </h1>
                )}
              </div>
              <div className="my-2">
                <h1 className="text-textPrimary">Select Gender</h1>
                <div className="my-2 flex justify-start">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.men}
                        onChange={() =>
                          setValues({ ...values, men: !values.men })
                        }
                        name="men"
                        style={{ color: "#ed4846" }}
                      />
                    }
                    label="Men"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.women}
                        onChange={() =>
                          setValues({ ...values, women: !values.women })
                        }
                        name="women"
                        style={{ color: "#ed4846" }}
                      />
                    }
                    label="Women"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.unisex}
                        onChange={() =>
                          setValues({ ...values, unisex: !values.unisex })
                        }
                        name="unisex"
                        style={{ color: "#ed4846" }}
                      />
                    }
                    label="Unisex"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.youth}
                        onChange={() =>
                          setValues({ ...values, youth: !values.youth })
                        }
                        name="youth"
                        style={{ color: "#ed4846" }}
                      />
                    }
                    label="Youth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.infant}
                        onChange={() =>
                          setValues({ ...values, infant: !values.infant })
                        }
                        name="infant"
                        style={{ color: "#ed4846" }}
                      />
                    }
                    label="Infant"
                  />
                </div>
              </div>
              <div>
                <div className="mt-12 mb-5 w-full grid grid-cols-5 gap-10">
                  <div className="flex flex-col">
                    <h1
                      onClick={() => setValues({ ...values, sex: "men" })}
                      className="self-center cursor-pointer"
                    >
                      Men
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1
                      onClick={() => setValues({ ...values, sex: "women" })}
                      className="self-center cursor-pointer"
                    >
                      Women
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1
                      onClick={() => setValues({ ...values, sex: "unisex" })}
                      className="self-center cursor-pointer"
                    >
                      Unisex
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1
                      onClick={() => setValues({ ...values, sex: "youth" })}
                      className="self-center cursor-pointer"
                    >
                      Youth
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1
                      onClick={() => setValues({ ...values, sex: "infant" })}
                      className="self-center cursor-pointer"
                    >
                      Infant
                    </h1>
                  </div>
                </div>
                <div>
                  <hr
                    className={
                      "border-t-4 border-solid border-primary w-1/5 transform-gpu transition duration-300 " +
                      (values.sex === "women"
                        ? "translate-x-full"
                        : values.sex === "unisex"
                        ? "translate-x-200%"
                        : values.sex === "youth"
                        ? "translate-x-300%"
                        : values.sex === "infant"
                        ? "translate-x-400%"
                        : "")
                    }
                  />
                  <hr className="border-t-2 -mt-0.5 border-solid" />
                </div>
              </div>
              {isLoading || loadException ? (
                <div className="flex justify-center my-20">
                  <Loading />
                </div>
              ) : (
                <div>
                  {values.men && values.sex === "men" && (
                    <MenForm parentForm={values} />
                  )}
                  {values.women && values.sex === "women" && (
                    <WomenForm parentForm={values} />
                  )}
                  {values.unisex && values.sex === "unisex" && (
                    <UnisexForm parentForm={values} />
                  )}
                  {values.youth && values.sex === "youth" && (
                    <YouthForm parentForm={values} />
                  )}
                  {values.infant && values.sex === "infant" && (
                    <InfantForm parentForm={values} />
                  )}
                </div>
              )}
            </div>
            <section>
              <div className="flex justify-between items-end">
                <h1 className="text-3xl">Exception Size</h1>
                <h1
                  onClick={() =>
                    history.push(
                      `/marketplace/sizing/edit-sizing/${params.product_category_id}/${params.product_brand_id}/add-exception-size`
                    )
                  }
                  className="hover:text-primary cursor-pointer"
                >
                  Add Exception Size
                </h1>
              </div>
              <hr className="my-3" />
              {loadException ? (
                <div className="flex justify-center mt-20">
                  <Loading />
                </div>
              ) : (
                <div className="my-10">
                  <ExceptionBrandList data={dataException} />
                </div>
              )}
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default EditSizing;
