import React, { useState } from "react";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import { optRoutesAddExceptionSize } from "./helpers/list";
import AsyncSelect from "react-select/async";
import {
  loadOptionsBrand,
  loadOptionsSubBrand,
} from "../../utils/loadOption/LoadOption";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import NavDetail from "../../components/navbar/NavDetail";
import { useHistory, useParams } from "react-router-dom";
import MenForm from "./components/add_sizing/MenForm";
import WomenForm from "./components/add_sizing/WomenForm";
import ExceptionWomenForm from "./components/exception_brand/ExceptionWomenForm";

const AddExceptionSize = () => {
  const history = useHistory();
  const [sex, setSex] = useState("men");
  const params: { product_category_id: string; product_brand_id: string } =
    useParams();

  const { values, setValues, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        men: false,
        women: false,
        unisex: false,
        youth: false,
        infant: false,
        product_category: {
          value: params.product_category_id,
          label: "",
        },
        product_brand: [],
        description: "",
      },
      validationSchema: Yup.object({
        product_brand: Yup.object().shape({
          value: Yup.string().required("Product brand is required"),
        }),
        product_category: Yup.object().shape({
          value: Yup.string().required("Product Category is required"),
        }),
      }),
      onSubmit: async () => {
        //   await productDepatuDispatch({
        //     type: ActionType.ShowConfirmProductAdd,
        //   });
      },
    });

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Add"
          title2="Exception Size"
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb
          data={optRoutesAddExceptionSize(
            params.product_category_id,
            params.product_brand_id
          )}
        />
        <div className="my-10">
          <div className="my-2">
            <h1 className="text-textPrimary">Except (Sub Brand)</h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              isSearchable={true}
              loadOptions={(input: any) =>
                loadOptionsSubBrand(input, params.product_brand_id, params.product_category_id)
              }
              isMulti
              // value={values.product_brand}
              onChange={(e: any) => setValues({ ...values, product_brand: e })}
              className="font-rom mt-3 pb-3.5 text-sm"
            />
            {/* {touched.product_brand && errors.product_brand && (
              <h1 className="text-primary font-rom -mt-1 text-xs">
                {errors.product_brand.value}
              </h1>
            )} */}
          </div>
          <div className="my-2">
            <h1 className="text-textPrimary">Description</h1>
            <div className="w-full  flex items-center h-full mt-3 pb-3.5">
              <textarea
                value={values.description}
                onChange={handleChange}
                placeholder="Place holder"
                name="description"
                className="w-full border-2 border-stroke rounded-md font-rom md:text-sm text-xs px-3 py-2 border-r-2 focus:ring-1 focus:outline-none"
              ></textarea>
            </div>
            {touched.description && errors.description && (
              <h1 className="text-primary font-rom -mt-1 text-xs">
                {errors.description}
              </h1>
            )}
          </div>
          <div className="my-2">
            <h1 className="text-textPrimary">Select Gender</h1>
            <div className="my-2 flex justify-start">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.men}
                    onChange={() => setValues({ ...values, men: !values.men })}
                    name="men"
                    style={{ color: "#ed4846" }}
                  />
                }
                label="Men"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.women}
                    onChange={() =>
                      setValues({ ...values, women: !values.women })
                    }
                    name="women"
                    style={{ color: "#ed4846" }}
                  />
                }
                label="Women"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.unisex}
                    onChange={() =>
                      setValues({ ...values, unisex: !values.unisex })
                    }
                    name="unisex"
                    style={{ color: "#ed4846" }}
                  />
                }
                label="Unisex"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.youth}
                    onChange={() =>
                      setValues({ ...values, youth: !values.youth })
                    }
                    name="youth"
                    style={{ color: "#ed4846" }}
                  />
                }
                label="Youth"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.infant}
                    onChange={() =>
                      setValues({ ...values, infant: !values.infant })
                    }
                    name="infant"
                    style={{ color: "#ed4846" }}
                  />
                }
                label="Infant"
              />
            </div>
          </div>
          <div>
            <div className="mt-12 mb-5 w-full grid grid-cols-5 gap-10">
              <div className="flex flex-col">
                <h1
                  onClick={() => setSex("men")}
                  className="self-center cursor-pointer"
                >
                  Men
                </h1>
              </div>
              <div className="flex flex-col">
                <h1
                  onClick={() => setSex("women")}
                  className="self-center cursor-pointer"
                >
                  Women
                </h1>
              </div>
              <div className="flex flex-col">
                <h1
                  onClick={() => setSex("unisex")}
                  className="self-center cursor-pointer"
                >
                  Unisex
                </h1>
              </div>
              <div className="flex flex-col">
                <h1
                  onClick={() => setSex("youth")}
                  className="self-center cursor-pointer"
                >
                  Youth
                </h1>
              </div>
              <div className="flex flex-col">
                <h1
                  onClick={() => setSex("infant")}
                  className="self-center cursor-pointer"
                >
                  Infant
                </h1>
              </div>
            </div>
            <div>
              <hr
                className={
                  "border-t-4 border-solid border-primary w-1/5 transform-gpu transition duration-300 " +
                  (sex === "women"
                    ? "translate-x-full"
                    : sex === "unisex"
                    ? "translate-x-200%"
                    : sex === "youth"
                    ? "translate-x-300%"
                    : sex === "infant"
                    ? "translate-x-400%"
                    : "")
                }
              />
              <hr className="border-t-2 -mt-0.5 border-solid" />
            </div>
          </div>
          {values.men && sex === "men" && <MenForm parentForm={values} />}
          {values.women && sex === "women" && (
            <ExceptionWomenForm parentForm={values} />
          )}
        </div>
      </div>
    </>
  );
};

export default AddExceptionSize;
