import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LocalStorage from "../../../config/LocalStorage";
import AddCreditEffect from "../../../pages/credit_effect/AddCreditEffect";
import CreditEffect from "../../../pages/credit_effect/CreditEffect";
import EditCreditEffect from "../../../pages/credit_effect/EditCreditEffect";
import {
  CreditEffectContextProvider,
  initialCreditEffectState,
} from "../../../store/credit_effect/CreditEffectContext";
import { CreditEffectReducer } from "../../../store/credit_effect/CreditEffectReducer";
import RoleGuard from "../../../utils/guards/RoleGuard";
import { AcceptedRole } from "../interfaces";

const CreditEffectRoute = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const [CreditEffectState, CreditEffectDispatch] = useReducer(
    CreditEffectReducer,
    initialCreditEffectState
  );
  return (
    <CreditEffectContextProvider
      value={{ CreditEffectState, CreditEffectDispatch }}
    >
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route
            path="/marketplace/credit-effect"
            exact
            component={CreditEffect}
          />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/credit-effect/add-credit-effect"
            component={AddCreditEffect}
          />
          <Route />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/credit-effect/edit-credit-effect/:id"
            exact
            component={EditCreditEffect}
          />
          <Route />

          <Redirect from="/marketplace" to="/marketplace/credit-effect" exact />
        </Switch>
      </div>
    </CreditEffectContextProvider>
  );
};

export default CreditEffectRoute;
