import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { bgDepatu } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import AddQuantityShuffle from "../campaign_product/AddQuantityShuffle";
import DropWinnerModal from "./DropWinnerModal";
import db from "../../../../../config/InitFirebase";
import isEmpty from "lodash.isempty";
import { validateRole } from "../../../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../../../layout/marketplace/interfaces";
import LocalStorage from "../../../../../config/LocalStorage";
import CampaignSideCard from "./CampaignSideCard";
import GetUsedTicket from "../../../helpers/use_query_hook/GetUsedTicket";

const SummaryPage = () => {
  const history = useHistory();
  const params: {
    templateId: string;
    totalWinner: string;
    winner: string;
    id: string;
  } = useParams();
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { data_spinner, drop_winner, showAddQuantityShuffle } = CampaignState;
  const [id, setId] = useState<number>(0);
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const { data: dataUsedTicket, isLoading: loadUsedTicket } = GetUsedTicket();
  const [status, setStatus] = useState<boolean>(false);
  const [load, setLoad] = useState(false);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  const getSnapshot = () => {
    setLoad(true);
    return onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === +params.templateId &&
              doc.campaign_id === +params.id
          )[0];
        CampaignDispatch({
          type: ActionType.AddDataSpinner,
          payload: data,
        });
        setLoad(false);
      }
    );
  };

  useEffect(() => {
    getSnapshot();
  }, []);

  const spinAgain = () => {
    CampaignDispatch({
      type: ActionType.AddDataSpinner,
      payload: null,
    });
    CampaignDispatch({
      type: ActionType.ShowAddQuantityShuffle,
      payload: {
        status: true,
        id: Number(params.templateId),
      },
    });
  };

  const isAvailableSpin = (winners: any[]) => {
    let status = true;
    for (let i = 0; i < winners?.length; i++) {
      if (winners[i]?.winner_status === "pending") {
        status = false;
      } else {
        status = true;
      }
    }
    setStatus(status);
  };

  useEffect(() => {
    isAvailableSpin(data_spinner?.winners);
  }, [data_spinner?.winners]);

  const goResults = () => {
    history.push(
      `/marketplace/campaign/edit-campaign/${params.id}/results/${params.templateId}`
    );
  };

  return (
    <div className="absolute overflow-hidden top-0 left-0 z-30 bg-black w-screen h-screen flex">
      {drop_winner.status && <DropWinnerModal setId={setId} />}
      {showAddQuantityShuffle.status && <AddQuantityShuffle />}
      {isLoading || load || loadUsedTicket ? (
        <div className="flex justify-center mt-20 w-full">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <>
          <CampaignSideCard
            onClick={() => setId(0)}
            data={data}
            dataUsedTicket={dataUsedTicket}
            title="GIVEAWAY"
          />
          <div className="w-2/3 flex justify-center items-center text-white py-10">
            <section className="absolute flex justify-start items-end bottom-0 w-2/3 h-full">
              <img src={bgDepatu} alt="-" />
            </section>
            {!isEmpty(data_spinner) && (
              <div className="flex flex-col items-center justify-center z-40 w-10/12">
                <h1 className="text-white font-med text-4xl mt-8">
                  CONGRATULATIONS!
                </h1>
                <div
                  className={
                    `grid content-center justify-items-center ` +
                    (data_spinner?.winners?.length <= 2
                      ? `grid-cols-${data_spinner?.winners?.length} w-full`
                      : "grid-cols-3 w-full gap-x-8")
                  }
                >
                  {data_spinner?.winners?.map((el: any) => (
                    <div
                      id={`box-${el.winner_id}`}
                      className={
                        "square mt-10 border h-28 flex justify-center items-center cursor-pointer " +
                        (el.winner_status === "done"
                          ? "border-success cursor-not-allowed "
                          : el.winner_status === "failed"
                          ? "border-primary cursor-not-allowed "
                          : el.winner_id === id
                          ? "border-yellow-400 "
                          : el.winner_status === "pending"
                          ? "border-white "
                          : "border-white ") +
                        (data_spinner?.winners?.length >= 3
                          ? "w-full cursor-pointer "
                          : data_spinner?.winners?.length >= 2
                          ? "w-3/4 cursor-pointer "
                          : data_spinner?.winners?.length >= 1
                          ? "w-1/3 cursor-pointer "
                          : "w-1/3 cursor-pointer ")
                      }
                      onClick={
                        el.winner_status === "pending"
                          ? () => setId(el.winner_id)
                          : () => {}
                      }
                    >
                      <h1 text-white text-2xl>
                        {el.winner_username}
                      </h1>
                    </div>
                  ))}
                </div>
                <div className="flex w-full justify-center items-center mt-10 mx-auto">
                  {status && (
                    <SmallButton
                      borderColor="border-white"
                      border={"border-2"}
                      color={"bg-black"}
                      text={"Back"}
                      height={14}
                      width={"1/4"}
                      type={"button"}
                      handleClick={() =>
                        history.push(
                          `/marketplace/campaign/edit-campaign/${params.id}`
                        )
                      }
                    />
                  )}
                  <div className="ml-5 w-1/4">
                    <SmallButton
                      borderColor="border-white"
                      border={"border-2"}
                      color={"bg-black"}
                      text={"Remove"}
                      textColor={id ? "text-white" : "text-textPrimary"}
                      height={14}
                      width={"full"}
                      type={"button"}
                      handleClick={
                        id > 0
                          ? () =>
                              CampaignDispatch({
                                type: ActionType.DropWinner,
                                payload: { status: true, id: id },
                              })
                          : () => {}
                      }
                    />
                  </div>
                  {!isLoading && status && (
                    <div className="ml-5 w-1/4">
                      <SmallButton
                        text={"Spin Again"}
                        height={14}
                        width={"full"}
                        type={"button"}
                        handleClick={spinAgain}
                      />
                    </div>
                  )}
                  {status &&
                    !isLoading &&
                    validateRole(role, AcceptedRole.Admin) && (
                      <div className="ml-5 w-1/4">
                        <SmallButton
                          text={"Summary"}
                          height={14}
                          width={"full"}
                          type={"button"}
                          handleClick={goResults}
                        />
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SummaryPage;
