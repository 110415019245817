import React from "react";
import { Link } from "react-router-dom";
import { wallet } from "../../../assets/icons/Web";

interface IProps {
  dropdownInvoice: boolean;
  handleShowSideBar: () => void;
}

const InvoiceSubsidebar: React.FC<IProps> = ({
  dropdownInvoice,
  handleShowSideBar,
}) => {
  return (
    <div>
      {dropdownInvoice && (
        <li className="py-2 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={wallet}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf(
                  "/marketplace/invoice/waiting-invoice"
                ) !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to="/marketplace/invoice/waiting-invoice"
            >
              Waiting Invoice
            </Link>
          </div>
        </li>
      )}

      {dropdownInvoice && (
        <li className="py-2 mt-5 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={wallet}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf(
                  "/marketplace/invoice/invoice-history"
                ) !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to={{
                pathname: "/marketplace/invoice/invoice-history",
              }}
            >
              Invoice History
            </Link>
          </div>
        </li>
      )}

      {dropdownInvoice && <hr className="md:my-5 my-3 md:min-w-full" />}
    </div>
  );
};

export default InvoiceSubsidebar;
