import { ActionType, SizingAction } from "./action";
import { ISizingState } from "./state";

export const SizingReducer = (state: ISizingState, action: SizingAction) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.HandleShowSubBrand:
      let showSubBrand = action.payload;
      return { ...state, showSubBrand };
    case ActionType.HandleShowExceptSubBrand:
      let showExceptSubBrand = action.payload;
      return { ...state, showExceptSubBrand };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
