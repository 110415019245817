import React, { useReducer } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { BannerRequest } from "../../apis/banner/BannerRequest";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../components/loading/CircularLoad";
import Chip from "../../components/chip/Chip";
import NewSorting from "../../components/sorting/NewSorting";
import Loading from "../../components/loading/Loading";
import {
  BannerContextProvider,
  initialBannerState,
} from "../../store/banner/BannerContext";
import { BannerReducer } from "../../store/banner/BannerReducer";
import { useQueries } from "../../utils/query_searchParams/QuerySearchParams";
import BannerFilter from "./banner_component/BannerFilter";
import Deletebanner from "./banner_component/Deletebanner";
import { optRoutes, optSorting, queryparamsBanner } from "./helpers/list";
import { default_sort } from "./helpers/setDefaultSort";
import Pagination from "../../components/pagination/Pagination";
import BannerCard from "./banner_component/BannerCard";

const Banner = () => {
  const query = useQueries(useLocation);
  const { hash, search } = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [bannerState, bannerDispatch] = useReducer(
    BannerReducer,
    initialBannerState
  );
  const { showFilter, showDelete } = bannerState;

  const { data, isLoading, isFetching } = useQuery(
    [
      "getBanner",
      hash,
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
      query.get("status"),
      query.get("scope"),
      query.get("action"),
      query.get("expired_at_from"),
      query.get("expired_at_to"),
    ],
    () => BannerRequest.getBanner(bannerDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <BannerContextProvider value={{ bannerDispatch, bannerState }}>
      <div className="md:pt-28 sm:pt-20 pt-16">
        <DynamicBreadcrumb data={optRoutes} />

        {showDelete?.status && <Deletebanner />}
        {showFilter && <BannerFilter />}
        <div className="mt-16 mb-5 w-full grid grid-cols-3 gap-10 text-lg">
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#general",
                search,
              }}
              className="self-center cursor-pointer"
            >
              General Banner
            </Link>
          </div>
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#marketplace",
                search,
              }}
              className="self-center cursor-pointer"
            >
              Marketplace Promotion
            </Link>
          </div>
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#multibrand",
                search,
              }}
              className="self-center cursor-pointer"
            >
              Explore More (Multibrand)
            </Link>
          </div>
        </div>
        <div>
          <hr
            className={
              "border-t-4 border-solid border-primary w-1/3 transform-gpu transition duration-300 " +
              (hash === "#marketplace"
                ? "translate-x-full"
                : hash === "#multibrand"
                ? "translate-x-200%"
                : "")
            }
          />
          <hr className="border-t-2 -mt-0.5 border-solid" />
        </div>

        <div className="flex justify-end mt-6">
          <h1
            onClick={() => history.push(`${url}/add-banner`, { hash })}
            className="text-primary md:text-base cursor-pointer inline-flex "
          >
            Add Banner
          </h1>
        </div>

        <div className="flex justify-between mt-4">
          <div className="flex">
            <h1
              onClick={() => bannerDispatch({ type: "show_filter" })}
              className="text-base mr-5 cursor-pointer hover:text-primary"
            >
              Show Filter
            </h1>
          </div>
          <NewSorting option={optSorting} defaultSort={default_sort} />
        </div>

        <Chip
          dispatchContext={bannerDispatch}
          stateContext={bannerState}
          queryParams={queryparamsBanner}
        />

        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="mt-6">
            <div className="w-full">
              {data?.data.map((el: any) => (
                <BannerCard data={el} key={el.id} />
              ))}
            </div>

            <Pagination
              isTable={false}
              total={data?.meta?.total}
              length={data?.data?.length}
            />
          </div>
        )}
      </div>
    </BannerContextProvider>
  );
};

export default Banner;
