import { toJpeg } from "html-to-image";
import React, { useContext, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { cancel, logowhite } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import Loading from "../../../../../components/loading/Loading";
import ErrorHandler from "../../../../../components/toast/ErrorHandler";
import LocalStorage from "../../../../../config/LocalStorage";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import {
  downloadFile,
  reduceImageSize,
} from "../../../../../utils/download_file";
import GetCampaignId from "../../../helpers/use_query_hook/GetCampaignId";

interface IProps {
  data: any;
}

const DownloadQrCode: React.FC<IProps> = ({ data }) => {
  const qrCodeRef = useRef<any>();
  const { CampaignDispatch } = useContext(CampaignContext);
  const goBack = () => {
    CampaignDispatch({ type: ActionType.ShowDownloadQrCode });
  };
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "user";
  const { isLoading, data: dataAdmin } = GetCampaignId(role);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const downloadJpeg = () => {
    setSubmitting(true);
    toJpeg(qrCodeRef.current, { quality: 1 }).then((dataUrl) => {
      reduceImageSize(dataUrl, "jpeg")
        .then((compressed) => {
          downloadFile(
            `${
              data.title.trim().toLowerCase().split(" ").join("-") +
                "-qr-code" || "qr-code"
            }.jpeg`,
            compressed,
            () => ErrorHandler("#00C62E", "Success Download QR Code")
          );
        })
        .then(() => {
          setSubmitting(false);
          goBack();
        });
    });
  };

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-auto h-auto rounded-xl my-auto mx-auto flex flex-col items-center justify-center px-10 py-8">
        <div className="flex justify-end w-full mb-7">
          <img
            src={cancel}
            className="w-5 cursor-pointer"
            alt="-"
            onClick={goBack}
          />
        </div>
        {isLoading ? (
          <div className="flex justify-center m-40">
            <Loading />
          </div>
        ) : (
          <div className="border border-textPrimary rounded-md p-2 ">
            <div ref={qrCodeRef} className="bg-white p-2">
              <QRCode
                size={300}
                qrStyle="squares"
                eyeRadius={2}
                value={dataAdmin.token}
                logoWidth={75}
                logoImage={logowhite}
                bgColor="#FFFFFF"
              />
            </div>
          </div>
        )}
        <div className="flex justify-center w-full mt-4 px-14">
          <SmallButton
            isSubmitting={isSubmitting}
            width={"full"}
            height={12}
            text={"Download"}
            type={"button"}
            handleClick={downloadJpeg}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadQrCode;
