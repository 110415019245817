import { useFormik } from "formik";
import React, { useContext } from "react";
import { cancel } from "../../../assets/icons";
import Input from "../../../components/input/Input";
import TagsContext from "../../../store/tags/TagsContext";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import Toggle from "../../../components/button/Toggle";
import { TagsRequest } from "../../../apis/tags/TagsRequest";
import SmallButton from "../../../components/button/SmallButton";

const AddTags = () => {
  const context = useContext(TagsContext);
  const { tagsState, tagsDispatch } = context;
  const { showAdd } = tagsState;

  const goBack = () => {
    tagsDispatch({
      type: "show_add",
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    dirty,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      is_active: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TagsRequest.postTags(values, goBack, setSubmitting),
    {
      onMutate: () => {
        console.log("mutatte");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTags");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  const handleToggle = () => {
    setValues({ ...values, is_active: !values.is_active });
  };

  return (
    showAdd && (
      <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
        <div className="bg-white w-productCategory h-auto rounded-xl my-auto mx-auto font-med">
          <div className="flex justify-between items-center p-7 pb-6">
            <div className="flex items-center">
              <h1 className="text-xl flex">Add</h1>
              <h1 className="ml-2 text-xl text-primary">Tag</h1>
            </div>
            <img
              src={cancel}
              alt="cancel"
              className="cursor-pointer w-5"
              onClick={goBack}
            />
          </div>
          <hr />
          <form onSubmit={handleSubmit} className="px-10 pt-3">
            <div className="md:mt-6 mt-3">
              <div className="flex justify-between">
                <h1 className="md:text-base text-sm text-textPrimary">Name</h1>
                <div className="flex justify-between items-center">
                  <h1 className="text-base mr-3">Active</h1>
                  <Toggle handleClick={handleToggle} input={values.is_active} />
                </div>
              </div>
              <Input
                type={"text"}
                handleChange={handleChange}
                disable={false}
                name={"name"}
                placeholder={"Place holder"}
              />
              {touched.name && errors.name ? (
                <h1 className="text-primary -mt-1 text-sm">{errors.name}</h1>
              ) : null}
            </div>
            <div className="mt-4">
              <h1 className="md:text-base text-sm text-textPrimary">Slug</h1>
              <Input
                type={"text"}
                disable={true}
                name={"slug"}
                placeholder={"Place holder"}
              />
            </div>
            <div className="flex justify-center mt-5 mb-7 outline-none">
              <SmallButton
                isSubmitting={isSubmitting}
                dirty={dirty}
                loadColor={"white"}
                type={"submit"}
                color={"bg-primary"}
                hoverColor={"bg-red-300"}
                text={"Add"}
                textColor={"text-white"}
              />
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default AddTags;
