import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import LocalStorage from "../../../../config/LocalStorage";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetResultWinners = () => {
  const query = useQueries(useLocation);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const params: { templateId: string } = useParams();
  const { data, isLoading, isFetching } = useQuery(
    [`GetResultWinners-${params.templateId}`, query.get("limit")],
    () => CampaignRequest.getResultWinners(params.templateId, role),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 3000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetResultWinners;
