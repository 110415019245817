export const downloadFile = (
  name: string,
  dataUrl: string,
  callback: Function
) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = dataUrl;
  link.click();
  setTimeout(callback, 1000);
};

export const reduceImageSize = async (
  dataUrl: string,
  type: string,
  width = 2480,
  height = 3508,
  maxSize = 2097152
) => {
  const base64Length = dataUrl.length - (dataUrl.indexOf(",") + 1);
  const padding =
    dataUrl.charAt(dataUrl.length - 2) === "="
      ? 2
      : dataUrl.charAt(dataUrl.length - 1) === "="
      ? 1
      : 0;
  const fileSize = base64Length * 0.75 - padding;
  if (fileSize > maxSize) {
    const img = new Image();
    img.src = dataUrl;
    await img.decode();
    const exceedRatio = maxSize / fileSize;
    const canvas: any = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(img, 0, 0, width, height);
    return canvas.toDataURL(`image/${type}`, exceedRatio);
  }
  return dataUrl;
};
