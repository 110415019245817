export const optRoutesSizing = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/sizing",
    name: "Product Sizing",
  },
];

export const optRoutesAddSizing = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/sizing",
    name: "Product Sizing",
  },
  {
    id: 3,
    path: "/marketplace/sizing/add-sizing",
    name: "Add Sizing",
  },
];

export const optRoutesAddExceptionSize = (
  category_id: string,
  brand_id: string
) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/sizing",
      name: "Product Sizing",
    },
    {
      id: 3,
      path: `/marketplace/sizing/edit-sizing/${category_id}/${brand_id}`,
      name: "Edit Sizing",
    },
    {
      id: 4,
      path: `/marketplace/sizing/edit-sizing/${category_id}/${brand_id}/add-exception-size`,
      name: "Add Exception Size",
    },
  ];
};

export const optRoutesEditSizing = (category_id: string, brand_id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/sizing",
      name: "Product Sizing",
    },
    {
      id: 3,
      path: `/marketplace/sizing/edit-sizing/${category_id}/${brand_id}`,
      name: "Edit Sizing",
    },
  ];
};

export const optRoutesEditExceptionBrand = (
  category_id: string,
  brand_id: string,
  exception_id: string
) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/sizing",
      name: "Product Sizing",
    },
    {
      id: 3,
      path: `/marketplace/sizing/edit-sizing/${category_id}/${brand_id}`,
      name: "Edit Sizing",
    },
    {
      id: 4,
      path: `/marketplace/sizing/edit-sizing/${category_id}/${brand_id}/exception-size/${exception_id}`,
      name: "Edit Exception Size",
    },
  ];
};

export const optRoutesSeeBrand = (category_id: string, brand_id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/sizing",
      name: "Product Sizing",
    },
    {
      id: 3,
      path: `/marketplace/sizing/see-brand/${category_id}/${brand_id}`,
      name: "See Brands",
    },
  ];
};

export const optSortingSizing = [
  {
    value: "created_at",
    name: "Date",
  },
];

export const queryparamsSizing: any[] = [
  { real_name: "product_category", show_name: "product_category" },
  { real_name: "gender", show_name: "gender" },
  { real_name: "add_time_from", show_name: "add_time_from" },
  { real_name: "add_time_to", show_name: "add_time_to" },
];
