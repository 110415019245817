export enum ActionType {
  ShowFilter,
  HandleChip,
  ShowSendCoupon,
  ShowConfirmAdd,
  ShowConfirmEdit,
  HandleFormDataAdd,
  HandleFormDataEdit,
  showDelete,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface ShowConfirmAdd {
  type: ActionType.ShowConfirmAdd;
  payload: any;
}

export interface HandleFormDataAdd {
  type: ActionType.HandleFormDataAdd;
  payload: any;
}

export interface ShowConfirmEdit {
  type: ActionType.ShowConfirmEdit;
  payload: any;
}

export interface HandleFormDataEdit {
  type: ActionType.HandleFormDataEdit;
  payload: any;
}

export interface ShowSendCoupon {
  type: ActionType.ShowSendCoupon;
}

export interface ShowDelete {
  type: ActionType.showDelete;
  payload: {
    status: boolean;
    id: number;
  };
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export type CouponAction =
  | ShowFilter
  | HandleChip
  | ShowConfirmAdd
  | HandleFormDataAdd
  | HandleFormDataEdit
  | ShowConfirmEdit
  | ShowDelete
  | ShowSendCoupon;
