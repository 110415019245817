import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { ProductRequest } from "../../../../../apis/product/ProductRequest";
import ProductDepatuContext from "../../../../../store/product/product_depatu/ProductDepatuContext";
import { useQueries } from "../../../../../utils/query_searchParams/QuerySearchParams";

const GetProduct = () => {
  const query = useQueries(useLocation);
  const { productDepatuDispatch } = useContext(ProductDepatuContext);
  const { data, isLoading, isFetching } = useQuery(
    [
      "getProduct",
      query.get("product"),
      query.get("product_id"),
      query.get("status"),
      query.get("category"),
      query.get("release_date_from"),
      query.get("release_date_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => ProductRequest.getProduct(productDepatuDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetProduct;
