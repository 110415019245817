import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OnSelling from "../../../pages/on_selling/on_selling/OnSelling";
import OnSellingDetail from "../../../pages/on_selling/on_selling_details/OnSellingDetail";
import OnSellingSeeAll from "../../../pages/on_selling/on_selling_see_all/OnSellingSeeAll";
import {
  initialOnSellingState,
  OnSellingContextProvider,
} from "../../../store/on_selling/OnSellingContext";
import { OnSellingReducer } from "../../../store/on_selling/OnSellingReducer";

const OnSellingRoute = () => {
  const [onSellingState, onSellingDispatch] = useReducer(
    OnSellingReducer,
    initialOnSellingState
  );
  return (
    <OnSellingContextProvider value={{ onSellingState, onSellingDispatch }}>
      <div className="font-med">
        <Switch>
          <Route path="/marketplace/on-selling" exact component={OnSelling} />
          <Route
            path="/marketplace/on-selling/:code"
            exact
            component={OnSellingDetail}
          />
          <Route
            path="/marketplace/on-selling/:id/see-all"
            exact
            component={OnSellingSeeAll}
          />
          <Redirect from="/marketplace" to="/marketplace/on-selling" exact />
        </Switch>
      </div>
    </OnSellingContextProvider>
  );
};

export default OnSellingRoute;
