import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { FeaturedProductRequest } from "../../../apis/featured_product/FeaturedProductRequest";
import { cancel } from "../../../assets/icons";
import SmallButton from "../../../components/button/SmallButton";
import { ActionType } from "../../../store/featured_product/action";
import FeaturedProductContext from "../../../store/featured_product/FeaturedProductContext";

const DeleteFeaturedProductModal = () => {
  const { FeaturedProductDispatch, FeaturedProductState } = useContext(
    FeaturedProductContext
  );
  const { showDelete } = FeaturedProductState;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const goBack = () => {
    FeaturedProductDispatch({
      type: ActionType.showDelete,
      payload: {
        id: 0,
        status: false,
      },
    });
  };

  const deleteFeaturedProduct = async () => {
    await mutation.mutate();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => FeaturedProductRequest.deleteFeaturedProduct(showDelete.id, goBack),
    {
      onMutate: () => {
        setSubmitting(true);
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getFeaturedProduct");
        setSubmitting(false);
        if (error) {
          setSubmitting(false);
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-1/3 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10 pb-3">
          <div className="flex flex-col items-center">
            <h1 className="text-xl">Delete Featured Product</h1>
            <h1 className="text-base text-center w-60 text-textPrimary mt-4 font-rom">
              Are you sure you want to delete the Featured product ?
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <SmallButton
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Cancel"}
              textColor={"text-textPrimary"}
              height={10}
            />
            <div className="mx-1.5"></div>
            <SmallButton
              isSubmitting={isSubmitting}
              loadColor={"white"}
              type={"button"}
              height={10}
              handleClick={deleteFeaturedProduct}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Delete"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFeaturedProductModal;
