import React from "react";
import { asc, desc } from "../../assets/icons";

interface IProps {
  option: any[];
  defaultSort?: any;
  dispatchContext: any;
  stateContext: any;
}

const Sorting: React.FC<IProps> = ({
  option,
  defaultSort,
  dispatchContext,
  stateContext,
}) => {
  const handleAsc = () => {
    if (stateContext.isAsc === "asc") {
      dispatchContext({
        type: "handle_asc",
        payload: "desc",
      });
    } else {
      dispatchContext({
        type: "handle_asc",
        payload: "asc",
      });
    }
  };

  const handleSort = (event: any) => {
    let target = event.target.value;
    dispatchContext({
      type: "handle_sort",
      payload: target,
    });
  };

  return (
    <div className="flex justify-end items-center">
      {stateContext.isAsc === "asc" ? (
        <img
          className="cursor-pointer md:w-7 w-5"
          onClick={handleAsc}
          src={asc}
          alt="icon"
        />
      ) : (
        <img
          className="cursor-pointer md:w-7 w-5"
          onClick={handleAsc}
          src={desc}
          alt="icon"
        />
      )}

      <div className="border-2 border-stroke h-10 w-28 rounded-md rounded-r-none flex justify-center items-center ml-5">
        <h1 className="text-base font-rom">Sort by</h1>
      </div>
      <div className="border-2 border-l-0 border-stroke h-10 w-44 rounded-md rounded-l-none flex justify-around items-center">
        <select
          onChange={handleSort}
          name="sorting"
          className="w-full h-full text-base font-rom my-3 px-2 shadow-sm transition duration-100 ease-in-out rounded-md rounded-l-none focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 cursor-pointer"
        >
          {defaultSort !== undefined ? (
            <option value={defaultSort as any} selected hidden>
              {defaultSort || 'Date'}
            </option>
          ) : (
            ""
          )}
          {option.map((el: any) => (
            <option value={el.value}>{el.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Sorting;
