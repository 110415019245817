import React, { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import TransactionContext from "../../../../../store/transaction/TransactionContext";
import ConfirmLocationCard from "../tracking_info_component/ConfirmLocationCard";
import InputTracking from "../tracking_info_component/InputTracking";
import { loadLocationTracking } from "../../../../../utils/loadOption/LoadOption";
import { TransactionRequest } from "../../../../../apis/transactions/Transaction";
import { useQuery } from "react-query";
import Loading from "../../../../../components/loading/Loading";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import ConfirmChangeTracking from "../tracking_info_component/ConfirmChangeTracking";
import TrackingInformation from "../tracking_info_component/TrackingInformation";
import { ActionType } from "../../../../../store/transaction/action";

interface IProps {
  data: any;
}

const TrackingInfo: React.FC<IProps> = ({ data }) => {
  const { TransactionState, TransactionDispatch } =
    useContext(TransactionContext);
  const { showConfirmLocation, showChangeTracking } = TransactionState;
  const [location, setLocation] = useState<any>({ label: "", values: 0 });
  const [isFetch, setisFetch] = useState(false);

  useEffect(() => {
    if (data.office_custom_tracking_origin_id) {
      setisFetch(true);
    }
  }, [data]);

  const { data: dataStep, isLoading: loadStep } = useQuery(
    ["getCustomTrackingStep", data?.office_custom_tracking_origin_id],
    () =>
      TransactionRequest.getCustomTrackingStep(
        data.office_custom_tracking_origin_id
      ),
    {
      refetchOnWindowFocus: false,
      enabled: isFetch,
    }
  );

  const handleChangeLocation = (payload: any) => {
    setLocation({
      label: payload.label,
      values: payload.value,
    });
  };

  useEffect(() => {
    setLocation({
      label: data?.office_custom_tracking_origin?.name || "Select here",
      values: data?.office_custom_tracking_origin?.id || 0,
    });
    return function () {
      setLocation({
        label: "Select here",
        values: 0,
      });
    };
  }, [data]);

  return (
    <>
      {showConfirmLocation.status && (
        <ConfirmLocationCard location={showConfirmLocation.location} />
      )}
      {showChangeTracking && <ConfirmChangeTracking />}

      <div className="my-10 flex w-full justify-between">
        <div className="md:w-1/3 w-full rounded-md border-2 border-stroke">
          <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Tracking Process
            </h1>
          </div>
          <hr />

          {loadStep ? (
            <div className="my-10 flex justify-center">
              <Loading />
            </div>
          ) : (
            <div className="md:mx-8 mx-5 md:my-6 my-5">
              <div className="flex flex-col justify-center">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Location (From)
                </h1>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={{
                    label: location.label,
                    values: location.values,
                  }}
                  isDisabled={
                    data?.office_custom_tracking_origin_id ? true : false
                  }
                  loadOptions={loadLocationTracking}
                  onChange={handleChangeLocation}
                  className="my-3.5 font-rom text-sm"
                />
              </div>
              {data?.office_custom_tracking_origin_id && (
                <InputTracking dataStep={dataStep} dataBnib={data} />
              )}
            </div>
          )}
          <div className="rounded-md rounded-t-none border-t-2 border-l-0 border-stroke flex w-ful lmd:h-18 h-16">
            <div className="w-full flex items-center justify-center border-stroke h-full ">
              <button
                type="button"
                disabled={location.values !== 0 ? false : true}
                onClick={
                  !data?.office_custom_tracking_origin_id
                    ? () =>
                        TransactionDispatch({
                          type: ActionType.ShowConfirm,
                          payload: {
                            status: true,
                            location: location.label,
                            id: location.values,
                          },
                        })
                    : data?.current_office_custom_tracking_step?.priority !==
                      dataStep?.length
                    ? () => {
                        TransactionDispatch({
                          type: ActionType.ShowChangeTracking,
                        });
                      }
                    : () => {}
                }
                className="focus:outline-none"
              >
                {loadStep ? (
                  <div>
                    <CircularLoad color={"black"} height={16} width={16} />
                  </div>
                ) : (
                  <h1
                    className={
                      "md:text-base text-base " +
                      (data?.current_office_custom_tracking_step?.priority &&
                      dataStep &&
                      data?.current_office_custom_tracking_step?.priority ===
                        dataStep?.length
                        ? "cursor-not-allowed"
                        : location.values !== 0 ||
                          data?.current_office_custom_tracking_step
                            ?.priority !== dataStep?.length
                        ? "cursor-pointer hover:text-primary"
                        : "cursor-not-allowed")
                    }
                  >
                    {!data?.office_custom_tracking_origin_id
                      ? "Continue"
                      : data?.current_office_custom_tracking_step?.priority !==
                        dataStep?.length
                      ? "Save"
                      : "End of Tracking Process"}
                  </h1>
                )}
              </button>
            </div>
          </div>
        </div>

        <TrackingInformation />
      </div>
    </>
  );
};

export default TrackingInfo;
