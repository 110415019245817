import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosCelestine from "../../../../config/AxiosCelestine";

//components
import {
  BrandtoCategory,
  BrandtoSubBrand,
  CategoryToBrand,
  ProductBrandOfficialStore,
  TypeToBrand,
} from "./formEditDetailCategories";
import Loading from "../../../../components/loading/Loading";
import NavDetail from "../../../../components/navbar/NavDetail";
import Select from "react-select";
import { typeCategories } from "../../helpers/list";

const EditDetailCategories: React.FC = () => {
  const history = useHistory();
  const params: {
    id: string;
    detail: string;
    subDetail: string;
  } = useParams();
  const [type, setType] = useState({
    value: "product_brand",
    label: "Brand to Sub-Brand",
  });

  const goBack = () => {
    refetch();
    history.push(
      `/marketplace/product-category-group/categories/${params.id}/detail-categories/${params.detail}`
    );
  };

  const getDetailById = async () => {
    try {
      const results = await axiosCelestine().get(
        `admin/special-category-list/${params.subDetail}`
      );
      const result = await results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading, refetch } = useQuery(
    "getDetailById",
    getDetailById,
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setType({
      value: data?.type,
      label:
        data?.type === "retail_shop"
          ? "Category to Official Store (Brand)"
          : data?.type === "product_brand"
          ? "Brand to Sub-Brand"
          : data?.type === "product_category"
          ? "Specific Brand Category"
          : data?.type === "special_category"
          ? "All Brand Sub-Category"
          : data?.type === "product_category_to_brand"
          ? "Specific Category Brand"
          : "",
    });
    return function () {
      setType({
        value: "product_brand",
        label: "Brand to Sub-Brand",
      });
    };
  }, [data]);

  return (
    <div>
      <NavDetail
        handleBack={goBack}
        title1={"Edit"}
        title2={"Detail Categories"}
      />

      <div className="pt-28 font-med">
        {/* breadscrumb */}
        <div>
          <ul className="flex text-breadscrumbColor md:text-base text-xs">
            <li className="inline-flex items-center ">
              <Link to="/marketplace" className="hover:text-black">
                Home
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="hover:text-black"
                to="/marketplace/product-category-group"
              >
                Product Category Group
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="hover:text-black"
                to={`/marketplace/product-category-group/categories/${params.id}`}
              >
                Categories
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="hover:text-black"
                to={`/marketplace/product-category-group/categories/${params.id}/detail-categories/${params.detail}`}
              >
                Detail Categories
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#A8A8A8">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <h1 className="text-black">Edit Detail Categories</h1>
            </li>
          </ul>
        </div>

        <div className="my-14">
          <h1 className="my-2.5">Select Type</h1>
          <Select
            className="font-rom"
            options={typeCategories}
            value={type}
            onChange={(e: any) => setType(e)}
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            {type.value === "product_brand" && <BrandtoSubBrand data={data} />}
            {type.value === "product_category" && (
              <BrandtoCategory data={data} />
            )}
            {type.value === "special_category" && <TypeToBrand data={data} />}
            {type.value === "product_category_to_brand" && (
              <CategoryToBrand data={data} />
            )}
            {type.value === "retail_shop" && (
              <ProductBrandOfficialStore data={data} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditDetailCategories;
