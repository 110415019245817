import React, { useContext } from "react";
import { useHistory, useParams } from "react-router";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesSeeBrand } from "../../helpers/list";
import GetBrand from "../../helpers/use_query_hooks/GetBrand";
import Loading from "../../../../components/loading/Loading";
import SizingContext from "../../../../store/sizing/SizingContext";
import { ActionType } from "../../../../store/sizing/action";
import SeeSubBrand from "./SeeSubBrand";
import SeeExceptSubBrand from "./SeeExceptSubBrand";

const SeeBrand = () => {
  const history = useHistory();
  const params: { product_category_id: string; product_brand_id: string } =
    useParams();
  const { SizingDispatch, SizingState } = useContext(SizingContext);
  const { showSubBrand, showExceptSubBrand } = SizingState;
  const { data, isLoading } = GetBrand(
    params.product_category_id,
    params.product_brand_id
  );

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="See"
          title2="Brands"
          handleBack={() => history.push('/marketplace/sizing')}
        />
      </div>
      {showSubBrand.status && <SeeSubBrand />}
      {showExceptSubBrand.status && <SeeExceptSubBrand />}
      <main>
        <DynamicBreadcrumb
          data={optRoutesSeeBrand(
            params.product_category_id,
            params.product_brand_id
          )}
        />
        {isLoading ? (
          <div className="flex justify-center my-20">
            <Loading />
          </div>
        ) : (
          <section className="my-14">
            <table className="w-full border relative text-center text-sm">
              <thead>
                <tr className="h-18 sticky top-0 bg-white shadow-sm">
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Brand</h1>
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Sub Brand</h1>
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Exception</h1>
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Exception Sub Brand</h1>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border h-24 font-rom hover:bg-gray-50">
                  <td className="border-r">
                    <h1 className="mx-10">
                      {data?.sub_product_brands?.[0]?.product_brand_parent_name || "-"}
                    </h1>
                  </td>

                  <td className="border-r flex justify-center items-center h-24 text-base">
                    {data.sub_product_brands.length > 0 ? (
                      <div className="flex justify-center w-full px-10">
                        <div className="flex">
                          {data.sub_product_brands
                            .slice(0, 4)
                            .map((el: any, index: number) => (
                              <h1 className="ml-1">
                                {`${el.product_brand_name} ${
                                  index !==
                                  data.sub_product_brands.slice(0, 3).length
                                    ? ","
                                    : "..."
                                }`}
                              </h1>
                            ))}
                        </div>
                        <h1
                          onClick={() =>
                            SizingDispatch({
                              type: ActionType.HandleShowSubBrand,
                              payload: {
                                status: true,
                                product_category_id: params.product_category_id,
                                product_brand_id: params.product_brand_id,
                              },
                            })
                          }
                          className="ml-2 font-med cursor-pointer hover:text-primary"
                        >
                          More
                        </h1>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td
                    className={
                      "border-r font-med px-10 " +
                      (data.exception_product_brands.length > 0
                        ? "text-success"
                        : "text-primary")
                    }
                  >
                    {data.exception_product_brands.length > 0
                      ? "Active"
                      : "Not Active"}
                  </td>

                  <td className="border-r flex justify-center items-center h-24 text-base">
                    {data.exception_product_brands.length > 0 ? (
                      <div className="flex justify-center w-full px-10">
                        <div className="flex">
                          {data.exception_product_brands
                            .slice(0, 4)
                            .map((el: any, index: number) => (
                              <h1 className="ml-1">
                                {`${el.product_brand_name} ${
                                  index !==
                                  data.exception_product_brands.slice(0, 3)
                                    .length
                                    ? ","
                                    : "..."
                                }`}
                              </h1>
                            ))}
                        </div>
                        <h1
                          onClick={() =>
                            SizingDispatch({
                              type: ActionType.HandleShowExceptSubBrand,
                              payload: {
                                status: true,
                                product_category_id: params.product_category_id,
                                product_brand_id: params.product_brand_id,
                              },
                            })
                          }
                          className="ml-2 font-med cursor-pointer hover:text-primary"
                        >
                          More
                        </h1>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        )}
      </main>
    </>
  );
};

export default SeeBrand;
