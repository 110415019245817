
import { createContext } from "react";
import { IOrderContext, IOrderState } from "./state";

export const initialOrderState: IOrderState = {
  showFilter: false,
  chip: [],
};

const OrderContext = createContext<IOrderContext>({
  OrderState: initialOrderState,
  OrderDispatch: () => undefined,
});

export const OrderContextProvider = OrderContext.Provider;
export const OrderContextConsumer = OrderContext.Consumer;

export default OrderContext;
