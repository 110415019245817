import { createContext } from "react";
import { IinitialTopupState, ITopupContext } from "./interface";


export const initialTopupState: IinitialTopupState = {
  showTopup: false,
  showFilter: false,
  showCancel: {
    id: "",
    status: false,
  },
  chip: [],
};

const TopupContext = createContext<ITopupContext>({
  topupState: initialTopupState,
  topupDispatch: () => {},
});

export const TopupContextProvider = TopupContext.Provider;
export const TopupContextConsumer = TopupContext.Consumer;

export default TopupContext;
