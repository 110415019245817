import { useFormik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { useMutateDisputeBnibtransaction } from "../../../hooks/bnib_transaction";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";
import ATextField from "../../atoms/AtextField";
import * as Yup from "yup";

interface IOdisputeBnibTransactionProps {
  isOpen: boolean;
  // setOpen: (isOpen: boolean) => void;
  // handleNext: () => void;
  handleClosed: () => void;
  location_dest_name: string;
  handleBack: () => void;
}

const OdisputeBnibTransaction: React.FC<IOdisputeBnibTransactionProps> = ({
  isOpen,
  // setOpen,
  // handleNext,
  handleBack,
  handleClosed,
  location_dest_name,
}) => {
  const params: { code: string } = useParams();

  const { mutate, isLoading } = useMutateDisputeBnibtransaction(
    params?.code,
    handleClosed,
    handleBack
  );

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      reject_reason: "",
    },
    validationSchema: Yup.object({
      reject_reason: Yup.string().required("Reject reason is required!"),
    }),
    onSubmit: (values) => {
      mutate({ reject_reason: values.reject_reason, location_dest_name });
    },
  });
  return (
    <ADialogBox
      content={{
        title: "",
        body: (
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div>
              <p className="text-sm font-med">
                Are you sure this product already not arrive at DEPATU
                Warehouse?
              </p>
              {/* <p className="text-xs font-rom">
                You will lost your Adds on order if you cancel this Adds on
              </p> */}
              <div className="mt-3">
                <ATextField
                  label="Reject reason"
                  placeholder="Reject reason"
                  name="reject_reason"
                  multiline
                  minRows={2}
                  value={values.reject_reason}
                  handleChange={handleChange}
                  error={
                    touched.reject_reason && errors.reject_reason ? true : false
                  }
                  helperText={errors.reject_reason}
                />
              </div>
            </div>
            <div className="mt-7 flex gap-3 justify-end">
              <Abutton
                title="Cancel"
                theme="secondary"
                onClick={handleClosed}
              />
              <Abutton isLoading={isLoading} type="submit" title="Confirm" />
            </div>
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={handleClosed}
    />
  );
};

export default OdisputeBnibTransaction;
