import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";

interface IAselectProps {
  name: string;
  options: { label: string; value: string | number }[];
  handleChange?: any;
  error?: boolean;
  helperText?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const Aselect: React.FC<IAselectProps> = ({
  value,
  options,
  name,
  label,
  helperText,
  error,
  handleChange,
  placeholder = "Place holder",
  disabled = false,
}) => {
  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel
        style={{
          marginLeft: 0,
          padding: "0",
          paddingBottom: "1rem",
          marginBottom: "1rem",
        }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        SelectDisplayProps={{
          style: {
            height: "20px",
            fontSize: "0.8rem",
            paddingTop: "0.7rem",
            paddingBottom: "0.8rem",
            marginTop: "0.4rem",
          },
        }}
        name={name}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map((el, idx) => (
          <MenuItem key={idx} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText
          style={{
            marginTop: "0.3rem",
            margin: 0,
            fontSize: "0.7rem",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Aselect;
