import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import TopupRequest from "../../../apis/top_up/Topup";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Input from "../../../components/input/Input";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import LocalStorage from "../../../config/LocalStorage";
import { AcceptedRole } from "../../../layout/marketplace/interfaces";
import { validateRole } from "../../../utils/checkRole/CheckRole";
import { formatRupiah } from "../../../utils/format_rupiah/FormatRupiah";
import { optRoutesDetail } from "../helpers/list";
import TopupInformation from "./topupDetailComponent/TopupInformation";
import UserInformation from "./topupDetailComponent/UserInformation";

const TopupDetail = () => {
  const history = useHistory();
  const params: {
    id: string;
    userId: string;
  } = useParams();
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  const goBack = () => {
    history.goBack();
  };

  const { data, isLoading } = useQuery("getTopupId", () =>
    TopupRequest.getTopupId(params.id, role)
  );

  const { data: dataUser, isLoading: loadUser } = useQuery(
    "getAdminUser",
    () => TopupRequest.getAdminUser(params.userId),
    {
      enabled: validateRole(role, AcceptedRole.Admin),
    }
  );

  return (
    <>
      <NavDetail title1={"Top up"} title2={"Details"} handleBack={goBack} />

      <div className="md:pt-28">
        <DynamicBreadcrumb data={optRoutesDetail(params.id, params.userId)} />

        {isLoading || loadUser ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
            {/* Product Images */}
            <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                <h1 className="font-medium md:text-xl text-base">
                  Top up Balance
                </h1>
              </div>
              <hr />

              <div className="md:mx-8 mx-5 md:my-6 my-5">
                <div className="flex flex-col justify-center">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Top up Balance
                  </h1>
                  <Input
                    name={"amount"}
                    value={formatRupiah(data.amount || 0)}
                    disable={true}
                    type={"text"}
                  />
                </div>
                {validateRole(role, AcceptedRole.Admin) && (
                  <div className="flex flex-col justify-center my-2">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Wallet Balance
                    </h1>
                    <Input
                      name={"amount"}
                      value={formatRupiah(dataUser?.wallet?.amount || 0)}
                      disable={true}
                      type={"text"}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
              {/* top up information */}
              <TopupInformation data={data} />

              {/* user information */}
              <UserInformation data={data} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TopupDetail;
