import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { OnBiddingRequest } from "../../../apis/on_bidding/OnBiddingRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import OnBiddingContext from "../../../store/on_bidding/OnBiddingContext";
import { useQueries } from "../../../utils/query_searchParams/QuerySearchParams";
import { optRoutesSeeAll, queryparamsGroupProductSize } from "../helpers/list";
import OnBiddingSeeAllperSize from "../on_bidding_see_all_per_size/OnBiddingSeeAllperSize";
import OnBiddingSeeAllTable from "./OnBiddingSeeAllTable";
import SeeAllFilter from "./SeeAllFilter";

const OnBiddingSeeAll = () => {
  const query = useQueries(useLocation);
  const history = useHistory();
  const params: { id: string } = useParams();
  const { onBiddingState, onBiddingDispatch } = useContext(OnBiddingContext);
  const { showFilterSeeAll, showSeeAllPerSize } = onBiddingState;

  const { data, isLoading } = useQuery(
    [
      "getOnBiddingGroupByProductSize",
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("pre_order"),
      query.get("seller_id"),
      query.get("product_id"),
      query.get("exclude_buyer_id"),
      query.get("bidding_time_from"),
      query.get("bidding_time_to"),
    ],
    () => OnBiddingRequest.getOnBiddingGroupByProductSize(params.id, onBiddingDispatch)
  );

  const { data: dataProduct, isLoading: loadProduct } = useQuery(
    "getProduct",
    () => OnBiddingRequest.getProduct(params.id)
  );
  return (
    <>
      <NavDetail
        title1={"See all"}
        title2={"Bidder"}
        handleBack={() => history.goBack()}
      />

      <div className="md:pt-28 sm:pt-20 pt-16">
        {showFilterSeeAll && <SeeAllFilter />}
        {showSeeAllPerSize.status && <OnBiddingSeeAllperSize />}
        <DynamicBreadcrumb data={optRoutesSeeAll(params.id)} />
        {isLoading && loadProduct ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <section>
            <div>
              <div className="flex mt-14 mb-10 w-full">
                <div className="w-1/12 flex justify-center">
                  <img
                    src={dataProduct?.display_image_url}
                    alt={dataProduct?.slug}
                    className="border rounded-md p-4"
                  />
                </div>
                <div className="w-11/12 flex flex-col justify-evenly py-4 px-8">
                  <div>
                    <h1 className="text-xl">{dataProduct?.name}</h1>
                  </div>
                  <div>
                    <h1 className="font-rom text-sm">{`SKU: ${dataProduct?.code}`}</h1>
                  </div>
                </div>
              </div>

              <div>
                <div className="w-1/12">
                  <h1
                    onClick={() =>
                      onBiddingDispatch({ type: "show_filter_see_all" })
                    }
                    className="hover:text-primary cursor-pointer"
                  >
                    Show Filter
                  </h1>
                </div>
                <div className="my-3">
                  <Chip
                    dispatchContext={onBiddingDispatch}
                    stateContext={onBiddingState}
                    queryParams={queryparamsGroupProductSize}
                  />
                </div>
              </div>
            </div>

            <div className="mb-16 mt-10">
              <OnBiddingSeeAllTable data={data} />
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default OnBiddingSeeAll;
