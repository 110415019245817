import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import SmallButton from "../../../../../components/button/SmallButton";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import * as Yup from "yup";
import { cancel } from "../../../../../assets/icons";
import VerificationInput from "react-verification-input";
import ErrorHandler from "../../../../../components/toast/ErrorHandler";

const ModalSecurityCode = () => {
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { showModalSecurityCode } = CampaignState;

  const goBack = () => {
    CampaignDispatch({
      type: ActionType.ShowModalSecurityCode,
      payload: {
        status: false,
        code: "",
      },
    });
  };

  const {
    values,
    handleSubmit,
    errors,
    touched,
    dirty,
    setValues,
  } = useFormik({
    initialValues: {
      security_code: "",
    },
    validationSchema: Yup.object({
      security_code: Yup.string()
        .length(6, "Minimum of security code is 6 characters")
        .required("security Code is required"),
    }),
    onSubmit: async () => {
      await mutation.mutate();
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      CampaignRequest.processPaymentWallet(values, showModalSecurityCode.code),
    {
      onMutate: () => {
        console.log("mutate input security code");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("getCampaignTemplate");
          ErrorHandler("#00C62E", "Selling Completed");
          goBack();
        }
      },
    }
  );
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-productCategory h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center p-7 pb-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Input</h1>
            <h1 className="ml-2 text-xl text-primary">Security Code</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="md:my-5 mt-3 px-10 py-5">
            <div className="flex justify-center">
              <h1 className="md:text-base text-sm">Input Security Code</h1>
            </div>
            <div className="flex justify-center my-3">
              <VerificationInput
                placeholder=""
                validChars={"0-9"}
                removeDefaultStyles
                debug={false}
                onChange={(e: any) =>
                  setValues({ ...values, security_code: e })
                }
                classNames={{
                  container: "w-full h-14 grid grid-cols-6 gap-3 mt-2",
                  character:
                    "w-14 h-14 border text-center rounded-md flex justify-center items-center ",
                  characterInactive: "border-stroke",
                  characterSelected: "border-primary",
                }}
              />
            </div>
            <div className="px-10 mt-1">
              {touched.security_code && errors.security_code && (
                <h1 className="text-primary -mt-1 text-sm font-rom">
                  {errors.security_code}
                </h1>
              )}
            </div>
          </div>
          <hr />
          <div className="flex justify-center my-5 outline-none">
            <SmallButton
              isSubmitting={mutation.isLoading}
              dirty={dirty}
              loadColor={"white"}
              height={12}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Continue"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalSecurityCode;
