export const optRoutesProductDepatu = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/product-depatu",
    name: "Product (DEPATU)",
  },
];

export const optRoutesAddProduct = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/product-depatu",
    name: "Product (DEPATU)",
  },
  {
    id: 3,
    path: "/marketplace/product-depatu/add-product",
    name: "Add Product",
  },
];

export const optRoutesProductDetail = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/product-depatu",
      name: "Product (DEPATU)",
    },
    {
      id: 3,
      path: `/marketplace/product-depatu/${id}`,
      name: "Product Details",
    },
  ];
};

export const optRoutesEditProduct = (id: string, code: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/product-depatu",
      name: "Product (DEPATU)",
    },
    {
      id: 3,
      path: `/marketplace/product-depatu/edit-product/${id}/code/${code}`,
      name: "Edit Product",
    },
  ];
};

export const optSortingproductDepatu = [
  {
    value: "created_at",
    name: "Date",
  },
];

export const queryparamsProductDepatu: any[] = [
  { real_name: "product", show_name: "product" },
  { real_name: "product_id", show_name: "product_id" },
  { real_name: "status", show_name: "status" },
  { real_name: "category", show_name: "category" },
  { real_name: "seller_id", show_name: "username" },
  { real_name: "add_time_from", show_name: "add_time_from" },
  { real_name: "add_time_to", show_name: "add_time_to" },
  { real_name: "release_date_from", show_name: "release_date_from" },
  { real_name: "release_date_to", show_name: "release_date_to" },
];

export const optSelectPicture = [
  { value: "2d", label: "2D" },
  { value: "3d", label: "3D" },
];

enum typeOptions {
  Packages = 1,
  OrganicGoods,
  GlasswareOrFragileGoods,
  LiquidGoods,
  Electronic,
}

export const optTypeOptions = [
  { value: typeOptions.Packages, label: "Regular" },
  { value: typeOptions.OrganicGoods, label: "Organic Goods" },
  {
    value: typeOptions.GlasswareOrFragileGoods,
    label: "Glassware or Fragile Goods",
  },
  { value: typeOptions.LiquidGoods, label: "Liquid Goods" },
  { value: typeOptions.Electronic, label: "Electronic" },
];
