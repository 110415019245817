import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Toggle from "../../../../components/button/Toggle";
import NavDetail from "../../../../components/navbar/NavDetail";
import Input from "../../../../components/input/Input";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { loadOptionsProduct } from "../../../../utils/loadOption/LoadOption";
import { optRoutesAddProductCampaign, ScopeType } from "../../helpers/list";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import { saveSize } from "../../../../assets/icons";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import NewFilePonds from "../../../../components/addImage/NewFilepond";
import AddCampaignProductForm from "./add_campaign_product/AddCampaignProductForm";
import CircularLoad from "../../../../components/loading/CircularLoad";
import GetCampaignId from "../../helpers/use_query_hook/GetCampaignId";
import Loading from "../../../../components/loading/Loading";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import AddTermsAndCondition from "../campaign_template/add_campaign_product/AddTermsAndCondition";
import { ActionType } from "../../../../store/campaign/action";
import LocalStorage from "../../../../config/LocalStorage";
import PriceInput from "../../../../components/input/PriceInput";

export const optionType = [
  { label: "Special Price (FIFO)", value: ScopeType.BASIC },
  {
    label: "Giveaway (Raffle)",
    value: ScopeType.GIVEAWAYRAFFLE,
  },
  {
    label: "Special Price (Raffle)",
    value: ScopeType.SPECIALPRICERAFFLE,
  },
  {
    label: "Giveaway (Top Credit)",
    value: ScopeType.GIVEAWAYTOPCREDIT,
  },
  {
    label: "Special Price (Top Credit)",
    value: ScopeType.SPECIALPRICETOPCREDIT,
  },
];

const AddCampaignProduct = () => {
  const params: { id: string } = useParams();
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const { showAddTermsCondition } = CampaignState;
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "user";
  const { data, isLoading } = GetCampaignId(role);

  const history = useHistory();
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    valueCardImage,
    setValueCardImage,
    submitCardImage,
    valuePreviewImage,
    setValuePreviewImage,
    submitPreviewImage,
    errors,
    errorsCardimage,
    errorsPreviewImage,
    touched,
    touchedCardImage,
    touchedPreviewImage,
    errorsDetailImage,
    setValueDetailImage,
    submitDetailImage,
    touchedDetailImage,
    valueDetailImage,
    mutation,
    errorsSpinImage,
    setValueSpinImage,
    submitSpinImage,
    touchedSpinImage,
    valueSpinImage,
  } = AddCampaignProductForm(data);

  const submitForm = () => {
    handleSubmit();
    submitCardImage();
    submitPreviewImage();
    if (values.scope.value !== ScopeType.BASIC) {
      submitSpinImage();
    }
    if (values.option_for_video === "false") {
      submitDetailImage();
    }
  };

  const handleChangeType = (e: any) => {
    setValues({ ...values, scope: e });
    // setValueSpinImage({ ...valueSpinImage, scope: e });
  };

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Add"
          title2="Campaign Product"
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb data={optRoutesAddProductCampaign(params.id)} />
        {showAddTermsCondition && (
          <AddTermsAndCondition setValues={setValues} values={values} />
        )}
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <form className="my-14">
            <section className="w-full">
              <div className="w-full mb-5">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Type
                </h1>
                <Select
                  options={optionType}
                  isDisabled={false}
                  value={values.scope}
                  onChange={handleChangeType}
                  className="mt-3.5 mb-4 font-rom text-sm"
                />
              </div>
              <div className="flex justify-between mb-4">
                <h1 className="text-xl">Product Information</h1>
                <div className="flex">
                  <h1 className="md:text-base text-sm mr-3 text-textPrimary">
                    Posted
                  </h1>
                  <div className="flex justify-between items-center">
                    <Toggle
                      handleClick={() =>
                        setValues({
                          ...values,
                          active: !values.active,
                        })
                      }
                      input={values.active}
                    />
                  </div>
                </div>
              </div>
              <hr className="border border-stroke" />
              <div className="flex w-full my-8">
                <div className="w-2/3 border-r-2 border-stroke mr-8 pr-8">
                  <div className="w-full mt-2.5 mb-5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Product Name
                    </h1>
                    <AsyncSelect
                      loadOptions={loadOptionsProduct}
                      cacheOptions
                      defaultOptions
                      isDisabled={false}
                      onChange={(e: any) => setValues({ ...values, name: e })}
                      className="my-3.5 font-rom text-sm"
                    />
                    {errors.name && touched.name && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.name.value}
                      </p>
                    )}
                  </div>
                  <div className="w-full my-2.5">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Display Name
                    </h1>
                    <Input
                      disable={false}
                      type={"text"}
                      name={"product_name"}
                      handleChange={handleChange}
                      placeholder={"Place holder"}
                    />
                    {errors.product_name && touched.product_name && (
                      <p className="text-primary text-xs font-rom -mt-2 mb-1">
                        {errors.product_name}
                      </p>
                    )}
                  </div>

                  {values.scope.value === ScopeType.BASIC ? (
                    <section>
                      <div className="w-full flex my-2.5">
                        <div className="w-1/2 mr-7">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Start Join Waiting Room
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"start_pre_waiting_room_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.start_pre_waiting_room_at &&
                            touched.start_pre_waiting_room_at && (
                              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                {errors.start_pre_waiting_room_at}
                              </p>
                            )}
                        </div>
                        <div className="w-1/2">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Start Join Raffle
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"start_join_waiting_room_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.start_join_waiting_room_at &&
                            touched.start_join_waiting_room_at && (
                              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                {errors.start_join_waiting_room_at}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="w-full flex my-2.5">
                        <div className="w-1/2">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Background Color (hexa)
                          </h1>
                          <div className="flex items-center">
                            <Input
                              disable={false}
                              type={"text"}
                              name={"color"}
                              value={values.color}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            <ColorPicker
                              animation="slide-up"
                              color={values.color}
                              placement="bottomLeft"
                              className="mt-1 ml-3"
                              onChange={(e: any) =>
                                setValues({ ...values, color: e.color })
                              }
                            />
                          </div>
                          {errors.color && touched.color && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.color}
                            </p>
                          )}
                        </div>
                        <div className="w-1/2 ml-7">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            End Raffle
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"end_campaign_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.end_campaign_at &&
                            touched.end_campaign_at && (
                              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                {errors.end_campaign_at}
                              </p>
                            )}
                        </div>
                      </div>
                    </section>
                  ) : values.scope.value === ScopeType.GIVEAWAYRAFFLE ||
                    values.scope.value === ScopeType.GIVEAWAYTOPCREDIT ? (
                    <section>
                      <div className="w-full flex my-2.5">
                        <div className="w-1/2 mr-7">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Freeze Time
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"freeze_time_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.freeze_time_at && touched.freeze_time_at && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.freeze_time_at}
                            </p>
                          )}
                        </div>
                        <div className="w-1/2">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Estimate Start Time
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"estimate_start_time_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.estimate_start_time_at &&
                            touched.estimate_start_time_at && (
                              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                {errors.estimate_start_time_at}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="w-full flex my-2.5">
                        <div className="w-1/2 mr-7">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Background Color (hexa)
                          </h1>
                          <div className="flex items-center">
                            <Input
                              disable={false}
                              type={"text"}
                              name={"color"}
                              value={values.color}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            <ColorPicker
                              animation="slide-up"
                              color={values.color}
                              placement="bottomLeft"
                              className="mt-1 ml-3"
                              onChange={(e: any) =>
                                setValues({ ...values, color: e.color })
                              }
                            />
                          </div>
                          {errors.color && touched.color && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.color}
                            </p>
                          )}
                        </div>
                        {values.scope.value === ScopeType.GIVEAWAYRAFFLE ? (
                          <div className="w-1/2">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              Claim Duration (Minute)
                            </h1>
                            <Input
                              disable={false}
                              type={"number"}
                              name={"transaction_room_duration_minutes"}
                              value={values.transaction_room_duration_minutes}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            {errors.transaction_room_duration_minutes &&
                              touched.transaction_room_duration_minutes && (
                                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                  {errors.transaction_room_duration_minutes}
                                </p>
                              )}
                          </div>
                        ) : (
                          <div className="w-1/2">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              End Claim Time
                            </h1>
                            <Input
                              disable={false}
                              type={"datetime-local"}
                              name={"end_campaign_at"}
                              value={values.end_campaign_at}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            {errors.end_campaign_at &&
                              touched.end_campaign_at && (
                                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                  {errors.end_campaign_at}
                                </p>
                              )}
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Show Price
                          </h1>
                          <h1 className="md:text-base text-sm text-primary cursor-pointer">
                            Use Retail Price
                          </h1>
                        </div>
                        <Input
                          disable={false}
                          type={"text"}
                          name={"subtitle"}
                          value={values.subtitle}
                          handleChange={handleChange}
                          placeholder={"Place holder"}
                        />
                        {errors.subtitle && touched.subtitle && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errors.subtitle}
                          </p>
                        )}
                      </div>
                    </section>
                  ) : values.scope.value === ScopeType.SPECIALPRICERAFFLE ||
                    values.scope.value === ScopeType.SPECIALPRICETOPCREDIT ? (
                    <section>
                      <div className="w-full flex my-2.5">
                        <div className="w-1/2 mr-7">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Freeze Time
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"freeze_time_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.freeze_time_at && touched.freeze_time_at && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.freeze_time_at}
                            </p>
                          )}
                        </div>
                        <div className="w-1/2">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Estimate Start Time
                          </h1>
                          <Input
                            disable={false}
                            type={"datetime-local"}
                            name={"estimate_start_time_at"}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.estimate_start_time_at &&
                            touched.estimate_start_time_at && (
                              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                {errors.estimate_start_time_at}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="w-full flex">
                        {values.scope.value === ScopeType.SPECIALPRICERAFFLE ? (
                          <div className="w-1/2">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              Transaction Duration (Minute)
                            </h1>
                            <Input
                              disable={false}
                              type={"number"}
                              name={"transaction_room_duration_minutes"}
                              value={values.transaction_room_duration_minutes}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            {errors.transaction_room_duration_minutes &&
                              touched.transaction_room_duration_minutes && (
                                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                  {errors.transaction_room_duration_minutes}
                                </p>
                              )}
                          </div>
                        ) : (
                          <div className="w-1/2">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              End Claim Time
                            </h1>
                            <Input
                              disable={false}
                              type={"datetime-local"}
                              name={"end_campaign_at"}
                              value={values.end_campaign_at}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            {errors.end_campaign_at &&
                              touched.end_campaign_at && (
                                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                  {errors.end_campaign_at}
                                </p>
                              )}
                          </div>
                        )}
                        <div className="w-1/2 ml-7">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Background Color (hexa)
                          </h1>
                          <div className="flex items-center">
                            <Input
                              disable={false}
                              type={"text"}
                              name={"color"}
                              value={values.color}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                            <ColorPicker
                              animation="slide-up"
                              color={values.color}
                              placement="bottomLeft"
                              className="mt-1 ml-3"
                              onChange={(e: any) =>
                                setValues({ ...values, color: e.color })
                              }
                            />
                          </div>
                          {errors.color && touched.color && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.color}
                            </p>
                          )}
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}
                </div>

                <div className="w-1/3">
                  <section className="w-full my-3.5">
                    <h1 className="md:text-base text-sm mb-1 text-textPrimary">
                      Claim Type
                    </h1>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="delivery_option"
                        row
                        value={values.delivery_option}
                        onChange={(e: any) =>
                          setValues({
                            ...values,
                            delivery_option: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="onsite"
                          control={<Radio />}
                          label="Onsite"
                        />
                        <FormControlLabel
                          value="shipping"
                          control={<Radio />}
                          label="Shipping"
                        />
                        <FormControlLabel
                          value="both"
                          control={<Radio />}
                          label="Both"
                        />
                      </RadioGroup>
                    </FormControl>
                  </section>

                  {values.scope.value === ScopeType.BASIC ? (
                    <section>
                      <div className="w-full mt-5">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Room Capacity (Person)
                        </h1>
                        <Input
                          disable={false}
                          type={"number"}
                          name={"transaction_room_capacity"}
                          handleChange={handleChange}
                          placeholder={"Place holder"}
                        />
                        {errors.transaction_room_capacity &&
                          touched.transaction_room_capacity && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.transaction_room_capacity}
                            </p>
                          )}
                      </div>
                      <div className="w-full mt-2.5">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Transaction Duration (Minute)
                        </h1>
                        <Input
                          disable={false}
                          type={"number"}
                          value={values.transaction_room_duration_minutes}
                          name={"transaction_room_duration_minutes"}
                          handleChange={handleChange}
                          placeholder={"Place holder"}
                        />
                        {errors.transaction_room_duration_minutes &&
                          touched.transaction_room_duration_minutes && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.transaction_room_duration_minutes}
                            </p>
                          )}
                      </div>
                    </section>
                  ) : (
                    <section>
                      <div className="mt-5">
                        <h1 className="md:text-base text-sm mb-1 text-textPrimary">
                          Available for Buy ?
                        </h1>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="gender"
                            name="is_available_for_buy"
                            row
                            value={values.is_available_for_buy}
                            onChange={(e: any) =>
                              setValues({
                                ...values,
                                is_available_for_buy: e.target.value,
                              })
                            }
                          >
                            <FormControlLabel
                              value="available"
                              control={<Radio />}
                              label="Available"
                            />
                            <FormControlLabel
                              value="not_available"
                              control={<Radio />}
                              label="Not Available"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="w-full">
                        <PriceInput
                          disabled={
                            values.is_available_for_buy === "available"
                              ? false
                              : true
                          }
                          displayType={"input"}
                          value={values.credit_price}
                          handleChange={handleChange}
                          name="credit_price"
                        />
                        {errors.credit_price && touched.credit_price && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errors.credit_price}
                          </p>
                        )}
                      </div>
                    </section>
                  )}

                  <div className="my-1">
                    <h1
                      onClick={() =>
                        CampaignDispatch({
                          type: ActionType.ShowAddTermsCondition,
                        })
                      }
                      className="text-primary cursor-pointer"
                    >
                      Add Terms & Conditions
                    </h1>
                  </div>
                </div>
              </div>
            </section>

            <section className="w-full">
              <div className="flex justify-between mb-4">
                <h1 className="text-xl">Product Images</h1>
              </div>
              <hr className="border border-stroke" />
              <div className="flex w-full my-8">
                <div className="w-2/3 border-r-2 border-stroke mr-8 pr-8">
                  <div className="">
                    <h1 className="md:text-base text-sm mb-2.5 text-textPrimary">
                      Select Option
                    </h1>
                    <FormControl component="fieldset">
                      <RadioGroup name="option" row value={values.option}>
                        <FormControlLabel
                          value={values.option}
                          control={
                            <Radio className="bg-primary to-primary" />
                          }
                          label="Photo"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="w-full mt-5 grid grid-cols-3 gap-10">
                    <div className="w-full">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Card Image (Homepage)
                      </h1>
                      <h1 className="text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
                        (5x8), (PNG,JPG,JPEG) max. 1mb
                      </h1>
                      <NewFilePonds
                        setFiles={setValueCardImage}
                        imageType={["image/png", "image/jpg", "image/jpeg"]}
                        cropRatio={"5:8"}
                        values={valueCardImage}
                        name="card_image"
                        maxFileSize={"1mb"}
                      />
                      {errorsCardimage.card_image &&
                        touchedCardImage.card_image && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errorsCardimage.card_image}
                          </p>
                        )}
                    </div>
                    <div className="w-full">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Preview Image
                      </h1>
                      <h1 className="text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
                        (1x2), (PNG,JPG,JPEG) max. 1mb
                      </h1>
                      <NewFilePonds
                        setFiles={setValuePreviewImage}
                        imageType={["image/png", "image/jpg", "image/jpeg"]}
                        cropRatio={"1:2"}
                        values={valuePreviewImage}
                        name="preview_image"
                        maxFileSize={"1mb"}
                      />
                      {errorsPreviewImage.preview_image &&
                        touchedPreviewImage.preview_image && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errorsPreviewImage.preview_image}
                          </p>
                        )}
                    </div>
                    {values.scope.value !== ScopeType.BASIC && (
                      <div className="w-full">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Spin Image
                        </h1>
                        <h1 className="text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
                          (1x1), (PNG,JPG,JPEG) max. 1mb
                        </h1>
                        <NewFilePonds
                          setFiles={setValueSpinImage}
                          imageType={["image/png", "image/jpg", "image/jpeg"]}
                          cropRatio={"1:2"}
                          values={valueSpinImage}
                          name="spin_image"
                          maxFileSize={"1mb"}
                        />
                        {errorsSpinImage && touchedSpinImage && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errorsSpinImage.spin_image}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-1/3">
                  <div className="">
                    <h1 className="md:text-base text-sm mb-2.5 text-textPrimary">
                      Select Option
                    </h1>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="for_reward"
                        name="option_for_video"
                        row
                        value={values.option_for_video}
                        onChange={(e: any) =>
                          setValues({
                            ...values,
                            option_for_video: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio className="bg-primary to-primary" />
                          }
                          label="Photo"
                        />
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio className="bg-primary to-primary" />
                          }
                          label="Video"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="w-full mt-5 flex">
                    {values.option_for_video === "false" ? (
                      <div className="w-4/5">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Product Detail
                        </h1>
                        <h1 className="text-xs font-rom text-textPrimary md:mt-2 mt-1 mb-1">
                          (9x16), (PNG,JPG,JPEG) max. 1mb
                        </h1>
                        <NewFilePonds
                          setFiles={setValueDetailImage}
                          imageType={["image/png", "image/jpg", "image/jpeg"]}
                          cropRatio={"9:16"}
                          values={valueDetailImage}
                          name="detail_image"
                          maxFileSize={"1mb"}
                        />
                        {errorsDetailImage.detail_image &&
                          touchedDetailImage.detail_image && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errorsDetailImage.detail_image}
                            </p>
                          )}
                      </div>
                    ) : (
                      <div className="w-full">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Detail Video Url
                        </h1>
                        <Input
                          disable={false}
                          type={"text"}
                          name={"detail_video_url"}
                          handleChange={handleChange}
                          placeholder={"Place holder"}
                        />
                        {errors.detail_video_url &&
                          touched.detail_video_url && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.detail_video_url}
                            </p>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <button
              type="button"
              onClick={submitForm}
              disabled={mutation.isLoading ? true : false}
              className={
                "w-16 h-16 focus:outline-none rounded-full bg-primary flex justify-center items-center shadow-md fixed bottom-0 cursor-pointer hover:bg-opacity-70 right-0 m-10 " +
                (mutation.isLoading && "bg-opacity-80")
              }
            >
              {mutation.isLoading ? (
                <div className="flex justify-center items-center">
                  <CircularLoad color={"#EFEFEF"} height={20} width={20} />
                </div>
              ) : (
                <img src={saveSize} alt="-" className="w-6" />
              )}
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default AddCampaignProduct;
