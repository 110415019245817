import React from "react";
import { useHistory } from "react-router-dom";

interface IProps {
  data: any;
}

const SendCreditCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  return (
    <div className="h-auto rounded-xl border-2 border-stroke hover:border-blue-300 hover:shadow-lg">
      <div className="flex justify-between items-start h-2/3 pb-5">
        <div className="flex pt-5 pb-10 px-8">
          <div className="flex flex-col justify-around">
            <h1 className="text-xl">{data.username}</h1>
            <h1 className="text-base mt-2 font-rom">Role : Giver</h1>
          </div>
        </div>
      </div>

      <div className="flex justify-around xl:text-base md:text-sm w-full h-1/3 pt-1">
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-full flex justify-center items-center">
          <h1
            onClick={() =>
              history.push(
                `/marketplace/send-credit/history/${data.giver_id}`
              )
            }
            className="hover:text-primary cursor-pointer"
          >
            Send History
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SendCreditCard;
