export const optRoutesProductReview = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/product-need-review",
    name: "Product (Need Review)",
  },
];

export const optRoutesCrawlResult = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/product-need-review",
    name: "Product (Need Review)",
  },
  {
    id: 3,
    path: `/marketplace/product-need-review/crawl-result`,
    name: "Crawl Result",
  },
];

export const optRoutesReviewProduct = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/product-need-review",
      name: "Product (Need Review)",
    },
    {
      id: 3,
      path: `/marketplace/product-need-review/review-product/${id}`,
      name: "Review Product",
    },
  ];
};

export const optSortingproductReview = [
  {
    value: "created_at",
    name: "Date",
  },
];

export const queryparamsProductReview: any[] = [
  { real_name: "code", show_name: "code" },
  { real_name: "source", show_name: "source" },
  { real_name: "status", show_name: "status" },
  { real_name: "need_review", show_name: "need_review" },
  { real_name: "crawl_time_from", show_name: "crawl_time_from" },
  { real_name: "crawl_time_to", show_name: "crawl_time_to" },
];

export const optSelectPicture = [
  { value: "2d", label: "2D" },
  { value: "3d", label: "3D" },
];

export const optSelectGender = [
  { value: "0", label: "Men" },
  { value: "1", label: "Women" },
  { value: "2", label: "Youth" },
  { value: "3", label: "Infant" },
  { value: "4", label: "Indefinable" },
  { value: "5", label: "Unisex" },
];

export const optSelectReleaseDate = [
  { value: "date", label: "Date" },
  {
    value: "season",
    label: "Season",
  },
];

export const optSelectYearList = () => {
  let maxOffset = 51;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }
  const yearList = allYears.map((year) => {
    return {
      value: year.toString(),
      label: year.toString(),
    };
  });
  return yearList;
};

export const optSelectCurrency = [
  { value: "USD", label: "USD" },
  { value: "IDR", label: "IDR" },
];
