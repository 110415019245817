import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import TopupRequest from "../../../../apis/top_up/Topup";
import { useMutation, useQueryClient } from "react-query";
import { loadOptionsUsername } from "../../../../utils/loadOption/LoadOption";
import SmallButton from "../../../../components/button/SmallButton";
import TextArea from "../../../../components/input/TextArea";
import LocalStorage from "../../../../config/LocalStorage";
import PriceInput from "../../../../components/input/PriceInput";
import { AcceptedRole } from "../../../../layout/marketplace/interfaces";
import InvoiceContext from "../../../../store/invoice/InvoiceContext";
import { ActionType } from "../../../../store/invoice/action";
import GetWaitingInvoiceId from "../../helpers/use_hooks/GetWaitingInvoiceId";
import Loading from "../../../../components/loading/Loading";
import isEmpty from "lodash.isempty";

const optionPaymentMethod = [
  { label: "Debit", value: "debit" },
  { label: "Credit", value: "credit_card" },
];

const TopupInvoice = () => {
  const { InvoiceState, InvoiceDispatch } = useContext(InvoiceContext);
  const { showTopup } = InvoiceState;
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const { data, isLoading } = GetWaitingInvoiceId(showTopup.id);

  useEffect(() => {
    if (!isEmpty(data)) {
      setValues({
        ...values,
        username: data?.buyer_username,
        amount: data.amount,
        confirm_amount: data.amount,
        invoice_id: data?.id,
      });
    }
  }, [data]);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    dirty,
    handleReset,
  } = useFormik({
    initialValues: {
      username: "",
      amount: "",
      confirm_amount: "",
      payment_method: "",
      password: "",
      bank: "",
      card_number: "",
      description: "",
      invoice_id: 0,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("username is required"),
      bank: Yup.string().required("Bank is required"),
      card_number: Yup.string().required("Card number is required"),
      amount: Yup.string().required("Amount is required"),
      confirm_amount: Yup.string()
        .required("Confirm amount is required")
        .oneOf([Yup.ref("amount")], "The confirm amount does not match!"),
      payment_method: Yup.string().required("Payment method is required"),
      password: Yup.string().required("Password is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TopupRequest.postTopup(values, handleHideModal, role),
    {
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("GetWaitingInvoice");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  const handleHideModal = () => {
    InvoiceDispatch({
      type: ActionType.ShowTopup,
      payload: { status: false, id: 0 },
    });
    handleReset({});
  };

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <form
        onSubmit={handleSubmit}
        className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-auto shadow-md md:w-2/5 w-10/12"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {isLoading ? (
          <div className="flex justify-center my-60">
            <Loading />
          </div>
        ) : (
          <section>
            <div className="px-10 py-7 flex justify-between w-full">
              <h1
                className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
                id="modal-headline"
              >
                Top up Wallet
              </h1>
              <img
                src={cancel}
                alt="back"
                className="cursor-pointer md:w-5 w-3"
                onClick={handleHideModal}
              />
            </div>
            <hr className="w-full " />
            <div className="flex px-10 py-4 w-full">
              <div className="mr-8 w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Username
                </h1>
                {role === AcceptedRole.Admin ? (
                  <AsyncSelect
                    cacheOptions
                    isDisabled={isEmpty(data?.buyer_username) ? false : true}
                    defaultOptions
                    value={{ label: values.username, value: values.username }}
                    loadOptions={loadOptionsUsername}
                    onChange={(e: any) =>
                      setValues({ ...values, username: e.value })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                ) : (
                  <Input
                    disable={isEmpty(values.username) ? false : true}
                    type={"text"}
                    value={values.username}
                    placeholder={"Place holder"}
                    name={"username"}
                    handleChange={handleChange}
                  />
                )}
                {touched.username && errors.username ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.username}
                  </p>
                ) : null}
              </div>
              <div className="w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Payment Method
                </h1>
                <Select
                  options={optionPaymentMethod}
                  onChange={(e: { label: string; value: string } | any) =>
                    setValues({ ...values, payment_method: e.value })
                  }
                  className="my-3.5 font-rom text-sm"
                />
                {touched.payment_method && errors.payment_method ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.payment_method}
                  </p>
                ) : null}
              </div>
            </div>
            <hr />
            <div className="flex px-10 py-4 w-full">
              <div className="mr-8 w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">Amount</h1>
                <PriceInput
                  disabled
                  displayType={"input"}
                  handleChange={handleChange}
                  value={values.amount}
                  name={"amount"}
                />
                {touched.amount && errors.amount ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.amount}
                  </p>
                ) : null}
              </div>
              <div className="w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Confirm Amount
                </h1>
                <PriceInput
                  disabled
                  displayType={"input"}
                  handleChange={handleChange}
                  value={values.confirm_amount}
                  name={"confirm_amount"}
                />
                {touched.confirm_amount && errors.confirm_amount ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.confirm_amount}
                  </p>
                ) : null}
              </div>
            </div>
            <hr />
            <div className="flex px-10 py-4 w-full">
              <div className="mr-8 w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">Bank</h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"bank"}
                  handleChange={handleChange}
                />
                {touched.bank && errors.bank ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.bank}
                  </p>
                ) : null}
              </div>
              <div className="w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Card Number
                </h1>
                <PriceInput
                  placeholder="Place holder"
                  displayType={"input"}
                  handleChange={handleChange}
                  value={values.card_number}
                  name={"card_number"}
                  format="#### #### #### ####"
                />
                {touched.card_number && errors.card_number ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.card_number}
                  </p>
                ) : null}
              </div>
            </div>
            <hr className="w-full" />

            <div className="flex px-10 py-4 w-full">
              <div className="mr-8 w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Password
                </h1>
                <Input
                  type={"password"}
                  placeholder={"Place holder"}
                  name={"password"}
                  handleChange={handleChange}
                />
                {touched.password && errors.password ? (
                  <p className="text-primary font-rom text-xs -mt-2">
                    {errors.password}
                  </p>
                ) : null}
              </div>
              <div className="w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Description
                </h1>
                <TextArea
                  name={"description"}
                  rows={1}
                  handleChange={handleChange}
                />
                {touched.description && errors.description ? (
                  <p className="text-primary font-rom text-xs -mt-4">
                    {errors.description}
                  </p>
                ) : null}
              </div>
            </div>
            <hr className="w-full" />
            <div className="flex justify-center m-7">
              <SmallButton
                height={10}
                text={"Top up"}
                type={"submit"}
                dirty={dirty}
                isSubmitting={mutation.isLoading}
              />
            </div>
          </section>
        )}
      </form>
    </div>
  );
};

export default TopupInvoice;
