import moment from "moment";
import React, { useRef, useContext, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { CampaignRequest } from "../../../../../apis/campaign/CampaignRequest";
import { desc, asc, eye } from "../../../../../assets/icons";
import Loading from "../../../../../components/loading/Loading";
import PrevPagination from "../../../../../components/pagination/PrevPagination";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import HandleClickOutside from "../../../../../utils/click_outside_box/HandleClickOutside";
import { formatRupiah } from "../../../../../utils/format_rupiah/FormatRupiah";

interface IProps {
  product_id: number;
}

const CampaignProductStockPerSize: React.FC<IProps> = ({ product_id }) => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  const params: { templateId: string } = useParams();
  const { CampaignDispatch, CampaignState } = useContext(CampaignContext);
  const { showProductPerSize, limit, offset } = CampaignState;
  const { size } = showProductPerSize;
  const [sort, setSort] = useState<{ value: string; isAsc: string }>({
    value: "created_at",
    isAsc: "desc",
  });

  HandleClickOutside(
    wrapperRef,
    CampaignDispatch,
    showProductPerSize,
    ActionType.ShowProductPerSize,
    { size: 0, status: false }
  );

  const { data, isLoading } = useQuery(
    ["getAllProductSizeCampaign", sort, limit, offset],
    () =>
      CampaignRequest.getAllProductSize(
        product_id,
        size,
        params.templateId,
        sort,
        limit,
        offset
      )
  );

  return (
    <div className="fixed bg-black bg-opacity-80 px-20 h-screen z-30 w-full inset-0 flex items-end cursor-pointer">
      <div
        id="class"
        ref={wrapperRef}
        className="bg-white h-5/6 overflow-auto w-full rounded-t-3xl inset-y-0 cursor-default transition duration-500 ease-in-out"
      >
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <table className="w-full border border-t-0 relative text-center text-sm border-collapse">
              <thead className="h-18 sticky top-0 bg-white shadow-sm border-b">
                <tr className="">
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Size</h1>
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Seller</h1>
                      <img
                        src={
                          sort.value === "seller_username" &&
                          sort.isAsc === "asc"
                            ? asc
                            : desc
                        }
                        onClick={() =>
                          setSort({
                            ...sort,
                            value: "seller_username",
                            isAsc: sort.isAsc === "desc" ? "asc" : "desc",
                          })
                        }
                        alt="asc-desc"
                        className="w-4 ml-3 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Status</h1>
                      <img
                        src={
                          sort.value === "pre_order" && sort.isAsc === "asc"
                            ? asc
                            : desc
                        }
                        onClick={() =>
                          setSort({
                            ...sort,
                            value: "pre_order",
                            isAsc: sort.isAsc === "desc" ? "asc" : "desc",
                          })
                        }
                        alt="asc-desc"
                        className="w-4 ml-3 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Price</h1>
                      <img
                        src={
                          sort.value === "price" && sort.isAsc === "asc"
                            ? asc
                            : desc
                        }
                        onClick={() =>
                          setSort({
                            ...sort,
                            value: "price",
                            isAsc: sort.isAsc === "desc" ? "asc" : "desc",
                          })
                        }
                        alt="asc-desc"
                        className="w-4 ml-3 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th className="border-r h-18">
                    <div className="flex justify-center items-center">
                      <h1>Selling Time</h1>
                      <img
                        src={
                          sort.value === "created_at" && sort.isAsc === "asc"
                            ? asc
                            : desc
                        }
                        onClick={() =>
                          setSort({
                            ...sort,
                            value: "created_at",
                            isAsc: sort.isAsc === "desc" ? "asc" : "desc",
                          })
                        }
                        alt="asc-desc"
                        className="w-4 ml-3 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((el: any) => (
                  <tr className="border h-24 font-rom hover:bg-gray-50">
                    <td className="border-r">{el.product_size}</td>
                    <td className="border-r">{el.seller_username}</td>
                    <td
                      className={
                        "border-r font-med " +
                        (el.pre_order ? "text-yellow-400" : "text-success")
                      }
                    >
                      {el.pre_order ? "Pre-Order" : "Brand New"}
                    </td>
                    <td className="border-r">{formatRupiah(el.price)}</td>
                    <td className="border-r">
                      <h1>{moment(el.created_at).format("lll")}</h1>
                      <h1 className="text-sm mt-0.5">
                        {moment().diff(moment(el.created_at), "days") >= 1
                          ? `${moment().diff(
                              moment(el.created_at),
                              "days"
                            )} days`
                          : "Same day"}
                      </h1>
                    </td>
                    <td className="">
                      <div className="w-full flex justify-center">
                        <img
                          onClick={() => {
                            CampaignDispatch({
                              type: ActionType.ShowProductPerSize,
                              payload: { status: false, size: "" },
                            });
                            history.push(
                              `/marketplace/on-selling/${data?.data[0]?.code}`
                            );
                          }}
                          src={eye}
                          alt="eyes"
                          className="w-7 cursor-pointer"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="bg-white sticky bottom-0  shadow-inner">
              <PrevPagination
                lengthData={data?.data.length}
                metaTotal={data?.meta.total}
                stateContext={CampaignState}
                dispatchContext={CampaignDispatch}
                ActionType={ActionType}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignProductStockPerSize;
