import _ from "lodash";
import { AxiosInstance } from "../../../config/AxiosInstance";
import { ILegitCheck, ILegitCheckDetail } from "../entities";
import ILegitCheckRepository from "../repository";

export default class LegitCheckRepository implements ILegitCheckRepository {
  // api routes
  private LEGIT_CHECK_ROUTE = "/admin/legit-check";
  private LEGIT_CHECK_DETAIL_ROUTE = "/admin/legit-check-detail";

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async createLegitCheck(payload: FormData): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.LEGIT_CHECK_ROUTE}`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async updateLegitCheck(
    id: number,
    payload: FormData
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().patch(
        `${this.LEGIT_CHECK_ROUTE}/${id}`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async getLegitCheck(id: number): Promise<ILegitCheck> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.LEGIT_CHECK_ROUTE}/${id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async createLegitCheckDetail(
    legitCheckDetail: Partial<ILegitCheckDetail>
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.LEGIT_CHECK_DETAIL_ROUTE}`,
        legitCheckDetail
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async updateLegitCheckDetail(
    legitCheckDetail: Partial<ILegitCheckDetail>,
    legitCheckDetailId: number
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().patch(
        `${this.LEGIT_CHECK_DETAIL_ROUTE}/${legitCheckDetailId}`,
        legitCheckDetail
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async getLegitCheckDetail(id: number): Promise<ILegitCheckDetail> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.LEGIT_CHECK_DETAIL_ROUTE}/${id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }

  async publishFinalResult(
    legitCheck: Partial<ILegitCheck>,
    legitCheckId: number
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Celestine().patch(
        `${this.LEGIT_CHECK_ROUTE}/${legitCheckId}/result`,
        legitCheck
      );
      const { data } = response;
      return data;
    } catch (error) {
      return this.getError(error);
    }
  }
}
