import React, { useContext } from "react";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../components/loading/CircularLoad";
import Loading from "../../components/loading/Loading";
import Pagination from "../../components/pagination/Pagination";
import { ActionType } from "../../store/send_credit/action";
import SendCreditContext from "../../store/send_credit/SendCreditContext";
import SendCreditCard from "./components/SendCreditCard";
import SendCreditModal from "./components/SendCreditModal";
import { optRoutesSendCredit } from "./helpers/list";
import GetListGiver from "./helpers/UseHooks/GetListGiver";

const SendCredit = () => {
  const { SendCreditDispatch, SendCreditState } = useContext(SendCreditContext);
  const { showSendModal } = SendCreditState;
  const { isFetching, isLoading, data } = GetListGiver();
  return (
    <div>
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesSendCredit} />
        {showSendModal && <SendCreditModal />}
        <div className="md:mt-12 mt-10">
          <div className="flex justify-end">
            <h1
              onClick={() =>
                SendCreditDispatch({ type: ActionType.ShowSendCredit })
              }
              className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
            >
              Send Credit
            </h1>
          </div>
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-10">
              {data?.data.map((el: any) => (
                <SendCreditCard data={el} key={el.id} />
              ))}
            </div>
            <div className="my-5">
              <Pagination
                length={data?.data?.length}
                total={data?.meta?.total}
                isTable={false}
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default SendCredit;
