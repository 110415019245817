import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { SendCreditRequest } from "../../../../apis/send_credit/SendCreditRequest";
import LocalStorage from "../../../../config/LocalStorage";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetSendCredit = () => {
  const query = useQueries(useLocation);
  const params: { id: string } = useParams();
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "giver";
  const { data, isLoading, isFetching } = useQuery(
    [
      `GetSendCredit-${params.id}`,
      query.get("sent_date_from"),
      query.get("sent_date_to"),
      query.get("limit"),
      query.get("offset"),
    ],
    () => SendCreditRequest.getCredit(params.id, role),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      refetchInterval: 5000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetSendCredit;
