import React from "react";
import { useHistory, useLocation } from "react-router";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";
import { optRoutesCampaignInfo } from "../../helpers/list";

const CampaignInfo = () => {
  const history = useHistory();
  const query = useQueries(useLocation);
  const product_campaign: any = query.get("product_campaign");

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Campaign"
          title2="Info"
          handleBack={() => history.goBack()}
        />
      </div>
      <DynamicBreadcrumb data={optRoutesCampaignInfo} />
      <section className="mt-20">
        <iframe
          className="w-full h-screen"
          src={
            product_campaign
              ? process.env.REACT_APP_CAMPAIGN_ANALYTIC +
                `?campaign=${query
                  .get("campaign")
                  ?.split(" ")
                  .join("%20")}&&campaign_product=${product_campaign
                  .split(" ")
                  .join("%20")}`
              : process.env.REACT_APP_CAMPAIGN_ANALYTIC +
                `?campaign=${query.get("campaign")?.split(" ").join("%20")}`
          }
          frameBorder="0"
          allowTransparency
          title={`campaign-info-$${query
            .get("campaign")
            ?.split(" ")
            .join("-")}`}
        ></iframe>
      </section>
    </>
  );
};

export default CampaignInfo;
