import React, { Suspense, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useHistory, useLocation } from "react-router-dom";
import Abutton from "../../components/atoms/Abutton";
import NavDetail from "../../components/navbar/NavDetail";
import OconfirmArriveBnibTransaction from "../../components/organisms/bnib_transaction/OconfirmArriveBnibTransaction";
import OdisputeBnibTransaction from "../../components/organisms/bnib_transaction/OdisputeBnibTransaction";
import OrejectBnibTransaction from "../../components/organisms/bnib_transaction/OrejectBnibTransaction";

const OacceptBnibTransaction = React.lazy(
  () =>
    import("../../components/organisms/bnib_transaction/OacceptBnibTransaction")
);

const ScanStockCorrect = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const [openDialog, setOpenDialog] = useState({
    location_dest_name: "",
    status: false,
  });

  const handleOpenDialog = (result: string) => {
    setOpenDialog({
      location_dest_name: result,
      status: true,
    });
  };

  const handleScan = (result: any, error: any) => {
    if (!!result) {
      handleOpenDialog(result);
    }
  };

  const handleClosed = () => {
    setOpenDialog({
      location_dest_name: "",
      status: false,
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  console.log(hash);

  return (
    <>
      <Suspense fallback={<></>}>
        {hash === "#correct" && (
          <OacceptBnibTransaction
            isOpen={openDialog.status}
            handleClosed={handleClosed}
            location_dest_name={openDialog.location_dest_name}
            handleBack={handleBack}
          />
        )}
      </Suspense>
      {hash === "#not-correct" && (
        <OrejectBnibTransaction
          isOpen={openDialog.status}
          handleClosed={handleClosed}
          location_dest_name={openDialog.location_dest_name}
          handleBack={handleBack}
        />
      )}
      {hash === "#arrive" && (
        <Suspense fallback={<></>}>
          <OconfirmArriveBnibTransaction
            isOpen={openDialog.status}
            handleClosed={handleClosed}
            location_dest_name={openDialog.location_dest_name}
            handleBack={handleBack}
          />
        </Suspense>
      )}
      {hash === "#not-arrive" && (
        <OdisputeBnibTransaction
          isOpen={openDialog.status}
          handleClosed={handleClosed}
          location_dest_name={openDialog.location_dest_name}
          handleBack={handleBack}
        />
      )}
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Scan"
          title2="Stock Move"
          handleBack={handleBack}
        />
      </div>
      <div className="flex flex-col h-screen max-h-screen items-center justify-center sm:pt-24 pt-16">
        <div className="flex justify-end w-full ">
          <Abutton title="Skip Scan QR" onClick={() => handleOpenDialog("")} />
        </div>
        <div className="xl:w-1/2 lg:w-10/12 w-full h-full xl:-mt-28 sm:-mt-14 -mt-8">
          <QrReader
            scanDelay={300}
            onResult={handleScan}
            constraints={{ facingMode: "user" }}
          />
        </div>
      </div>
    </>
  );
};

export default ScanStockCorrect;
