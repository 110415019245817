import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetCampaign = () => {
  const query = useQueries(useLocation);
  const { CampaignDispatch } = useContext(CampaignContext);
  const { data, isLoading, isFetching } = useQuery(
    [
      "getCampaign",
      query.get("product_id"),
      query.get("status"),
      query.get("release_date_from"),
      query.get("release_date_to"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => CampaignRequest.getCampaign(CampaignDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetCampaign;
