import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LocalStorage from "../../../config/LocalStorage";
import AddCoupon from "../../../pages/coupon/AddCoupon";
import Coupon from "../../../pages/coupon/Coupon";
import EditCoupon from "../../../pages/coupon/EditCoupon";
import {
  CouponContextProvider,
  initialCouponState,
} from "../../../store/coupon/CouponContext";
import { couponReducer } from "../../../store/coupon/CouponReducer";
import RoleGuard from "../../../utils/guards/RoleGuard";
import { AcceptedRole } from "../interfaces";

const CouponRoute = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const [couponState, couponDispatch] = useReducer(
    couponReducer,
    initialCouponState
  );
  return (
    <CouponContextProvider value={{ couponState, couponDispatch }}>
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route path="/marketplace/coupon" exact component={Coupon} />
          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/coupon/add-coupon"
            component={AddCoupon}
          />

          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Operation,
              AcceptedRole.KeyAccount,
              AcceptedRole.Marketing,
            ]}
            role={role}
            path="/marketplace/coupon/edit-coupon/:id"
            component={EditCoupon}
          />

          <Redirect from="/marketplace" to="/marketplace/coupon" exact />
        </Switch>
      </div>
    </CouponContextProvider>
  );
};

export default CouponRoute;
