import React, { useContext } from "react";
import { cancel } from "../../../../assets/icons/index";
import SmallButton from "../../../../components/button/SmallButton";
import AsyncSelect from "react-select/async";
import { useHistory, useLocation } from "react-router-dom";
import {
  loadOptFilter,
  loadOptionsCategory,
  loadOptionsCategoryGroup,
  loadOptionsTag,
  loadOptPaymentMethod,
} from "../../../../utils/loadOption/LoadOption";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";
import CouponContext from "../../../../store/coupon/CouponContext";
import { ActionType } from "../../../../store/coupon/action";
import Input from "../../../../components/input/Input";
import SetQueryString from "../../../../utils/query_searchParams/SetQueryString";
import { queryparamsCoupon } from "../../helpers/list";

const CouponFilter = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = useQueries(useLocation);
  const { couponDispatch } = useContext(CouponContext);
  let filter: any = query.get("filter");

  const handleShowFilter = () => {
    let new_search = search.replace(filter, "false");
    history.push({ pathname, search: new_search });
    couponDispatch({ type: ActionType.ShowFilter });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    SetQueryString(queryparamsCoupon, value, name, search, pathname, history);
  };

  const applyFilter = () => {
    // queryClient.invalidateQueries("getCoupon");
    // ApplyChip(queryparamsCoupon, couponDispatch);
    // let new_search = search.replace(filter, "false");
    // history.push({ pathname, search: new_search });
    setTimeout(() => couponDispatch({ type: ActionType.ShowFilter }), 500);
  };

  const handleChangeCoupon = (coupon: any) => {
    SetQueryString(
      [{ real_name: "display_name", show_name: "coupon_name" }],
      coupon.label,
      "coupon_name",
      search,
      pathname,
      history
    );
  };

  const handleChangeUsername = (user: any) => {
    // SetQueryString(
    //   [{ real_name: "seller_id", show_name: "username" }],
    //   user.values,
    //   "username",
    //   search,
    //   pathname,
    //   history
    // );
  };
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        className="bg-white md:rounded-lg rounded-t-none rounded-b-lg h-3/4 shadow-xl transform transition-all w-1/2"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/6">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full " />
        <div className="h-4/6 overflow-y-scroll overflow-x-hidden">
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Coupon Name
              </h1>
              <AsyncSelect
                name="coupon"
                cacheOptions
                defaultOptions
                loadOptions={loadOptFilter}
                onChange={handleChangeCoupon}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full " />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Coupon Type
              </h1>
              <select
                onChange={handleChangeFilter}
                name="coupon_type"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="false">Public</option>
                <option value="true">Reward</option>
              </select>
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Coupon For
              </h1>
              <select
                onChange={handleChangeFilter}
                name="coupon_for"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="bnib_buy_order_direct,bnib_buy_order_payment">
                  Marketplace (Buy)
                </option>
                <option value="bnib_product,bnib_product_direct">Marketplace (Sell)</option>
                <option value="retail">Multibrand (Buy)</option>
              </select>
            </div>
          </div>

          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired At (from)
              </h1>
              <Input name="expired_at_from" type="date" />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired At (to)
              </h1>
              <Input name="expired_at_to" type="date" />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired in Time (from)
              </h1>
              <Input name="expired_at_from" type="date" />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Expired in Time (to)
              </h1>
              <Input name="expired_at_to" type="date" />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Discount Type
              </h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="false">Amount</option>
                <option value="true">Percentage</option>
              </select>
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">For Type</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="false">Brand New</option>
                <option value="true">Pre Order</option>
              </select>
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Minimal Transaction
              </h1>
              <Input name="minimal_transaction" type="number" />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Maximal Discount Price
              </h1>
              <Input name="minimal_transaction" type="number" />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Limit Using Coupon (Type)
              </h1>
              <Input name="minimal_transaction" type="number" />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Limit Using Coupon (Amount)
              </h1>
              <Input name="minimal_transaction" type="number" />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Time Using Coupon (from)
              </h1>
              <Input name="expired_at_from" type="date" />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Time Using Coupon (to)
              </h1>
              <Input name="expired_at_to" type="date" />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Payment Method
              </h1>
              <AsyncSelect
                name="username"
                cacheOptions
                defaultOptions
                loadOptions={loadOptPaymentMethod}
                onChange={handleChangeUsername}
                className="my-3.5 font-rom text-sm"
              />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Category Group
              </h1>
              <AsyncSelect
                name="username"
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsCategoryGroup}
                onChange={handleChangeUsername}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">Category</h1>
              <AsyncSelect
                name="username"
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsCategory}
                onChange={handleChangeUsername}
                className="my-3.5 font-rom text-sm"
              />
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">Tags</h1>
              <AsyncSelect
                name="username"
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsTag}
                onChange={handleChangeUsername}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
        </div>
        <hr className="w-full " />

        <div className="flex justify-center items-center h-1/6">
          <SmallButton
            type={"button"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default CouponFilter;
