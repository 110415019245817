import axiosCelestine from "../../config/AxiosCelestine";
import { setWithExpiry } from "../../utils/setToken/SetToken";

class AuthRequest {
  static Login = async (values: { username: string; password: string }) => {
    try {
      const data: any = await axiosCelestine().post("admin/auth/signin", {
        username: values.username,
        password: values.password,
      });
      const token = data.data.token;
      const role = data.data.user.role.name;
      return { token, role };
    } catch (error: any) {
      const err = error.response.data.errors;
      return { err };
    }
  };

  static LoginUser = async (values: { username: string; password: string }) => {
    try {
      const data: any = await axiosCelestine().post(
        "auth/signin",
        {
          username: values.username,
          password: values.password,
        },
        {
          headers: { Version: "23.6" },
        }
      );
      const token = data.data.token;
      const role = "user";
      return { token, role };
    } catch (error: any) {
      const err = error.response.data.errors;
      return { err };
    }
  };

  static refreshToken = async () => {
    try {
      const data = await axiosCelestine().get("auth/refresh");
      const token = data.data.token;
      setWithExpiry("AUTH_TOKEN", token, 10800000);
    } catch (err) {
      console.log(err);
    }
  };
}

export default AuthRequest;
