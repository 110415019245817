export const SetQuery = (
    filter: any,
    limit: number,
    offset: number,
    sortValue: string,
    isAsc: string
  ) => {
    let query: string = "";
    query += `?limit=${limit}&&offset=${offset}&&sort=${sortValue}&&isAsc=${isAsc}`;
    if (filter.name.length > 0) {
      query += `&&name=${filter.name}`;
    }
    if (filter.status.length > 0) {
      query += `&&status=${filter.status}`;
    }
  
    return query;
  };
  