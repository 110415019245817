import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { InvoiceRequest } from "../../../../apis/invoice/InvoiceRequest";
import LocalStorage from "../../../../config/LocalStorage";
import InvoiceContext from "../../../../store/invoice/InvoiceContext";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetInvoiceHistory = () => {
  const query = useQueries(useLocation);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE") || "admin";
  const { InvoiceDispatch } = useContext(InvoiceContext);
  const { data, isFetching, isLoading } = useQuery(
    [
      "GetInvoiceHistory",
      query.get("username"),
      query.get("code"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => InvoiceRequest.getInvoiceHistory(role, InvoiceDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 5000,
      refetchOnReconnect: true,
    }
  );
  return {
    data,
    isFetching,
    isLoading,
  };
};

export default GetInvoiceHistory;
