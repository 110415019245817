import React, { useContext, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Loading from "../../../../components/loading/Loading";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { TrackingRequest } from "../../../../apis/tracking/TrackingRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesEdit } from "../../../tracking/helpers/list";
import { penEdit, plus } from "../../../../assets/icons";
import TrackingContext from "../../../../store/tracking/TrackingContext";
import FormEditStep from "./FormEditStep";
import DeleteStepCard from "./form_edit_step_component/DeleteStepCard";
import AddStepCard from "./AddStepCard";
import CircularLoad from "../../../../components/loading/CircularLoad";

const EditTracking = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const { trackingDispatch, trackingState } = useContext(TrackingContext);
  const { showEditName, showDeleteStep, showAddStep } = trackingState;
  const [name, setName] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const { data, isLoading, refetch } = useQuery(
    ["getTrackingLocationDetail"],
    () => TrackingRequest.getTrackingLocationDetail(+params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataStep, isLoading: loadStep } = useQuery(
    ["getTrackingStep"],
    () => TrackingRequest.getTrackingStep(+params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleShowEditName = () => {
    trackingDispatch({
      type: "show_edit_name",
    });
  };

  const editLocationName = () => {
    TrackingRequest.editLocation(
      name,
      handleShowEditName,
      +params.id,
      refetch,
      setSubmitting
    );
  };

  return (
    <div>
      <NavDetail title1={"Edit"} title2={"Location"} handleBack={goBack} />
      {showDeleteStep.status && <DeleteStepCard />}
      {showAddStep && <AddStepCard />}

      <div className="pt-28 flex flex-col">
        <DynamicBreadcrumb data={optRoutesEdit(params.id)} />
        <div>
          {isLoading ? (
            <div className="flex justify-center w-full mt-20">
              <Loading />
            </div>
          ) : (
            <div className="flex justify-center mt-20">
              <div className="flex justify-center">
                {showEditName ? (
                  <TextField
                    onChange={(e) => setName(e.target.value)}
                    id="standard-basic"
                    label={data?.name}
                  />
                ) : (
                  <h1 className="text-2xl">{data?.name}</h1>
                )}

                {showEditName ? (
                  <div className="flex justify-center items-center text-sm pt-2 mx-4">
                    <h1
                      onClick={() =>
                        trackingDispatch({
                          type: "show_edit_name",
                        })
                      }
                      className="cursor-pointer hover:text-primary"
                    >
                      Cancel
                    </h1>
                    {isSubmitting ? (
                      <div className="ml-5">
                        <CircularLoad
                          color={"#A5A5A5"}
                          height={17}
                          width={17}
                        />
                      </div>
                    ) : (
                      <h1
                        onClick={editLocationName}
                        className="cursor-pointer hover:text-primary ml-3"
                      >
                        Save
                      </h1>
                    )}
                  </div>
                ) : (
                  <img
                    onClick={() =>
                      trackingDispatch({
                        type: "show_edit_name",
                      })
                    }
                    src={penEdit}
                    alt="edit"
                    className="w-5 ml-10 cursor-pointer"
                  />
                )}
              </div>
            </div>
          )}
          <div className="my-12">
            <div className="flex justify-end w-full mb-5">
              <h1
                onClick={() =>
                  trackingDispatch({
                    type: "show_add_step",
                  })
                }
                className="hover:text-primary cursor-pointer"
              >
                Add Location
              </h1>
            </div>

            <hr />
            {loadStep ? (
              <div className="flex justify-center mt-20">
                <Loading />
              </div>
            ) : (
              <div>
                <FormEditStep data={dataStep} />
              </div>
            )}
          </div>
        </div>
        <div>
          <button
            onClick={() =>
              trackingDispatch({
                type: "show_add_step",
              })
            }
            className="w-14 h-14 fixed bottom-8 right-16 shadow-xl rounded-full flex justify-center items-center hover:bg-opacity-70 bg-primary focus:outline-none"
          >
            <img src={plus} alt="plus" className="w-5 shadow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTracking;
