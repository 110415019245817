export const OnSellingReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_filter":
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case "show_filter_see_all":
      let showFilterSeeAll = state.showFilterSeeAll;
      document.body.classList[!showFilterSeeAll ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilterSeeAll: !showFilterSeeAll };
    case "show_see_all_per_size":
      let showSeeAllPerSize = action.payload;
      document.body.classList[showSeeAllPerSize.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSeeAllPerSize };
    case "handle_limit":
      let new_limit = action.payload;
      return { ...state, limit: new_limit };
    case "handle_offset":
      let new_offset = action.payload;
      return { ...state, offset: new_offset };
    case "handle_filter":
      let new_filter = action.payload;
      return { ...state, filter: new_filter };
    case "handle_chip":
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case "set_default_product":
      let default_product = action.payload;
      return { ...state, product: default_product };
    case "set_default_size":
      let default_size = action.payload;
      return { ...state, size: default_size };
    case "set_default_seller":
      let default_seller = action.payload;
      return { ...state, seller: default_seller };
    case "set_default_status":
      let default_sellOptions = action.payload;
      return { ...state, sellOptions: default_sellOptions };
    case "set_size":
      let size = state.size;
      let index = action.payload.index;
      let value = action.payload.value;
      size[index] = { size: "", id: +value };
      return { ...state, size };
    case "set_seller":
      let seller = state.seller;
      let indexSeller = action.payload.index;
      let valueSeller = action.payload.value;
      seller[indexSeller] = { username: "", id: +valueSeller };
      return { ...state, seller };
    case "set_product":
      let product = state.product;
      let indexProduct = action.payload.index;
      let valueProduct = action.payload.value;
      product[indexProduct] = valueProduct;
      return { ...state, product };
    case "set_status":
      let sellOptions = state.sellOptions;
      let indexStatus = action.payload.index;
      let valueStatus = action.payload.value;
      sellOptions[indexStatus] = valueStatus;
      return { ...state, sellOptions };
  }
};
