import qs from "qs";
import { AxiosInstance } from "../../../config/AxiosInstance";
import { formatDate } from "../../../helpers/common";

export const getBnibBuyOrder = async (
  limit: number,
  offset: number,
  setLoad: (load: boolean) => void,
  date: any[],
  status: string
) => {
  try {
    const arrStatus = status?.split(",");
    setLoad(true);
    const results = await AxiosInstance.Celestine().get(
      `/admin/bnib-buy-order`,
      {
        params: {
          status: arrStatus,
          limit: limit,
          created_at: `${formatDate(date[0].startDate)},${formatDate(
            date[0].endDate
          )}`,
          offset: offset,
          sort: `created_at desc`,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      }
    );
    const result = await results.data.data;
    setLoad(false);
    return result;
  } catch (error) {
    console.log(error);
  }
};
