import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import { CouponRequest } from "../../../../apis/coupon/CouponRequest";
import CouponContext from "../../../../store/coupon/CouponContext";

import { ActionType } from "../../../../store/coupon/action";

const EditCouponForm = (data: any) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const form_data = new FormData();
  const { couponDispatch, couponState } = useContext(CouponContext);
  const { showConfirmEdit } = couponState;
  const [file, setFile] = useState<any>(null);
  const { data: dataPayment } = useQuery("getPaymentMethod", () =>
    CouponRequest.getPaymentMethod()
  );

  useEffect(() => {
    setValues({
      ...values,
      active_status: data?.active_status,
      code: data?.code,
      display_name: data?.display_name,
      detail: data?.detail,
      for_reward: data?.for_reward ? "true" : "false",
      global_limit_using_coupon: data?.global_limit_value,
      expired_at: moment(data?.expired_at).format("YYYY-MM-DD"),
      rewardable_valid_in_days: data?.rewardable_valid_in_day,
      hide_in_coupon_list: {
        label: data?.hide_in_coupon_list ? "Yes" : "No",
        value: data?.hide_in_coupon_list ? true : false,
      },
      scope: {
        label:
          data?.scopes[0] === "bnib_buy_order_direct"
            ? "Marketplace (Buy)"
            : data?.scopes[0] === "bnib_product"
            ? "Marketplace (Sell)"
            : data?.scopes[0] === "retail"
            ? "Multibrand (Buy)"
            : "",
        value:
          data?.scopes[0] === "bnib_buy_order_direct"
            ? ["bnib_buy_order_direct", "bnib_buy_order_payment"]
            : data?.scopes[0] === "bnib_product"
            ? ["bnib_product", "bnib_product_direct"]
            : data?.scopes[0] === "retail"
            ? ["retail"]
            : [""],
      },
      discount_type: data?.discount_type,
      discount_mode: {
        value: data?.discount_mode,
        label: data?.discount_mode === "percentage" ? "Percent" : "Amount",
      },
      discount_value: data?.discount_value,
      pre_order: data?.pre_order
        ? "true"
        : data?.pre_order === false
        ? "false"
        : "",
      minimum_transaction: data?.min_subtotal > 0 ? true : false,
      min_subtotal: data?.min_subtotal,
      max_discount_price: data?.max_discount_amount > 0 ? true : false,
      max_discount_amount: data?.max_discount_amount,
      limit_using_coupon:
        data?.periodic_limit_type.length > 0 && data?.periodic_limit_value > 0
          ? true
          : false,
      periodic_limit_type: {
        value: data?.periodic_limit_type,
        label: data?.periodic_limit_type,
      },
      periodic_limit_value: data?.periodic_limit_value,
      time_using_coupon: data?.available_time_range_of_the_day ? true : false,
      available_time_range_of_the_day: {
        start: moment(
          `3001-10-10T${data?.available_time_range_of_the_day?.start}`
        ).format("HH:mm"),
        end: moment(
          `3001-10-10T${data?.available_time_range_of_the_day?.end}`
        ).format("HH:mm"),
      },
      day_using_coupon:
        data?.available_days_of_the_week.length > 0 ? true : false,
      available_days_of_the_week: data?.available_days_of_the_week.map(
        (el: any) => ({
          label:
            el === 0
              ? "Sunday"
              : el === 1
              ? "Monday"
              : el === 2
              ? "Tuesday"
              : el === 3
              ? "Wednesday"
              : el === 4
              ? "Thursday"
              : el === 5
              ? "Friday"
              : el === 6
              ? "Saturday"
              : "",
          value: el.toString(),
        })
      ),
      payment_method: data?.eligible_payment_methods?.length > 0 ? true : false,
      eligible_payment_methods: data?.eligible_payment_methods?.map(
        (el: any) => ({ label: el.split("_").join(" "), value: el })
      ),
      category_group:
        data?.market_product_category_group_ids?.length > 0 ? true : false,
      market_product_category_group_ids:
        data?.payload?.market_product_category_groups?.map((el: any) => ({
          value: el.id,
          label: el.name,
        })),
      category: data?.market_product_category_ids?.length > 0 ? true : false,
      market_product_category_ids:
        data?.payload?.market_product_categories?.map((el: any) => ({
          value: el.id,
          label: el.name,
        })),
      tag: data?.market_tag_ids?.length > 0 ? true : false,
      market_tag_ids: data?.payload?.market_tags?.map((el: any) => ({
        value: el.id,
        label: el.name,
      })),
    });
  }, [data]);

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      code: "",
      for_reward: "false",
      expired_at: "",
      rewardable_valid_in_days: 0,
      global_limit_using_coupon: 0,
      active_status: true,
      hide_in_coupon_list: { label: "No", value: false },
      scope: {
        label: "Marketplace (Buy)",
        value: ["bnib_buy_order_direct", "bnib_buy_order_payment"],
      },
      display_name: "",
      discount_type: "subtotal_product",
      discount_mode: { value: "percentage", label: "Percent" },
      discount_value: 0,
      pre_order: "false",
      rewardable_coupon_id: { label: "", value: "" },
      detail: "",
      minimum_transaction: false,
      min_subtotal: 0,
      max_discount_price: false,
      max_discount_amount: 0,
      limit_using_coupon: false,
      periodic_limit_type: { label: "", value: "" },
      periodic_limit_value: 0,
      time_using_coupon: false,
      available_time_range_of_the_day: {
        end: "",
        start: "",
      },
      day_using_coupon: false,
      available_days_of_the_week: [{}],
      payment_method: false,
      eligible_payment_methods: [{}],
      category_group: false,
      market_product_category_group_ids: [],
      category: false,
      market_product_category_ids: [],
      tag: false,
      market_tag_ids: [],
    },
    validationSchema: Yup.object({
      display_name: Yup.string().required("Coupon Name is required"),
      code: Yup.string()
        .required("Coupon Code is required")
        .matches(/^[A-Z0-9]+$/, "Code must contain A-Z or 0-9"),

      //   for_reward: Yup.string(),
      //   expired_at: Yup.date().when("for_reward", {
      //     is: "false",
      //     then: Yup.string().required("Must enter date"),
      //   }),
      global_limit_using_coupon: Yup.number().min(
        1,
        "Global limit using coupon minimum is 1"
      ),
      minimum_transaction: Yup.boolean(),
      min_subtotal: Yup.number().when("minimum_transaction", {
        is: true,
        then: Yup.number()
          .min(1, "Minimum of value is 1")
          .required("Minimum Transaction is Required"),
      }),
      max_discount_price: Yup.boolean(),
      max_discount_amount: Yup.number().when("max_discount_price", {
        is: true,
        then: Yup.number()
          .min(1, "Minimum of value is 1")
          .required("Maximum Discount Price is Required"),
      }),
      limit_using_coupon: Yup.boolean(),
      periodic_limit_type: Yup.object().when("limit_using_coupon", {
        is: true,
        then: Yup.object().shape({
          value: Yup.string().required("Limit Using Coupon Type is Required"),
        }),
      }),
      periodic_limit_value: Yup.number().when("limit_using_coupon", {
        is: true,
        then: Yup.number()
          .min(1, "Minimum of value is 1")
          .required("Limit Using Coupon Value is Required"),
      }),
      time_using_coupon: Yup.boolean(),
      available_time_range_of_the_day: Yup.object().when("time_using_coupon", {
        is: true,
        then: Yup.object().shape({
          start: Yup.string().required("Time Using Coupon Start is Required"),
          end: Yup.string().required("Time Using Coupon End is Required"),
        }),
      }),
      detail: Yup.string().required("Description is required"),
      day_using_coupon: Yup.boolean(),
      available_days_of_the_week: Yup.array().when("day_using_coupon", {
        is: true,
        then: Yup.array()
          .required("Day using coupon is Required")
          .min(1, "Select minimum 1 of day"),
      }),

      payment_method: Yup.boolean(),
      eligible_payment_methods: Yup.array().when("payment_method", {
        is: true,
        then: Yup.array()
          .required("Payment Method is Required")
          .min(1, "Select minimum 1 of payment method"),
      }),

      category_group: Yup.boolean(),
      market_product_category_group_ids: Yup.array().when("category_group", {
        is: true,
        then: Yup.array()
          .required("Product category group is Required")
          .min(1, "Select minimum 1 of product category group"),
      }),

      category: Yup.boolean(),
      market_product_category_ids: Yup.array().when("category", {
        is: true,
        then: Yup.array()
          .required("Product category is Required")
          .min(1, "Select minimum 1 of product category"),
      }),

      tag: Yup.boolean(),
      market_tag_ids: Yup.array().when("tag", {
        is: true,
        then: Yup.array()
          .required("Tag is Required")
          .min(1, "Select minimum 1 of tag"),
      }),
    }),
    onSubmit: async () => {
      if (!showConfirmEdit) {
        form_data.append("simulated", "true");
      }
      form_data.append("for_reward", values.for_reward.toString());
      if (values.for_reward === "true") {
        form_data.append(
          "rewardable_valid_in_day",
          values.rewardable_valid_in_days.toString()
        );
      } else {
        form_data.append("expired_at", moment(values.expired_at).format());
      }

      form_data.append("display_name", values.display_name);
      form_data.append("active_status", values.active_status.toString());
      form_data.append(
        "global_limit_value",
        values.global_limit_using_coupon.toString()
      );
      form_data.append("code", values.code);
      if (file !== null) {
        form_data.append("image", file);
      }
      form_data.append(
        "hide_in_coupon_list",
        values.hide_in_coupon_list.value.toString()
      );
      values.scope.value.map((el: any) => form_data.append("scopes[]", el));
      form_data.append("discount_type", values.discount_type);

      if (values.discount_type === "coupon_reward") {
        form_data.append("discount_mode", values.discount_mode.value);
        form_data.append("discount_value", "0");
        form_data.append(
          "rewardable_coupon_id",
          values.rewardable_coupon_id.value
        );
      } else {
        form_data.append("discount_mode", values.discount_mode.value);
        form_data.append("discount_value", values.discount_value.toString());
      }
      form_data.append("pre_order", values.pre_order.toString());
      form_data.append("detail", values.detail);

      if (values.minimum_transaction) {
        form_data.append("min_subtotal", values.min_subtotal.toString());
      } else {
        form_data.append("min_subtotal", "999999999");
      }

      if (values.max_discount_price) {
        form_data.append(
          "max_discount_amount",
          values.max_discount_amount.toString()
        );
      } else {
        form_data.append("max_discount_amount", "999999999");
      }

      if (values.limit_using_coupon) {
        form_data.append(
          "periodic_limit_type",
          values.periodic_limit_type.value
        );
        form_data.append(
          "periodic_limit_value",
          values.periodic_limit_value.toString()
        );
      } else {
        form_data.append("periodic_limit_type", "monthly");
        form_data.append("periodic_limit_value", "999999999");
      }

      if (values.time_using_coupon) {
        const payload = {
          start: `${values.available_time_range_of_the_day.start}:00+07:00`,
          end: `${values.available_time_range_of_the_day.end}:00+07:00`,
        };
        form_data.append(
          "available_time_range_of_the_day",
          JSON.stringify(payload)
        );
      } else {
        const payload = {
          start: "",
          end: "",
        };
        form_data.append(
          "available_time_range_of_the_day",
          JSON.stringify(payload)
        );
      }

      if (values.day_using_coupon) {
        const day_of_the_week = values.available_days_of_the_week.map(
          (el: any) => el.value
        );
        day_of_the_week.map((el: any) =>
          form_data.append("available_days_of_the_week[]", el.toString())
        );
      } else {
        const arr = [0, 1, 2, 3, 4, 5, 6];
        arr.map((el: any) =>
          form_data.append("available_days_of_the_week[]", el.toString())
        );
      }

      if (values.payment_method) {
        const payment_method = values.eligible_payment_methods.map(
          (el: any) => el.value
        );
        payment_method.map((el: any) =>
          form_data.append("eligible_payment_methods[]", el)
        );
      } else {
        dataPayment &&
          dataPayment.map((el: any) =>
            form_data.append("eligible_payment_methods[]", el.value)
          );
      }

      if (values.category_group) {
        const category_group = values.market_product_category_group_ids.map(
          (el: any) => el.value
        );
        category_group.map((el: any) =>
          form_data.append("market_product_category_group_ids[]", el)
        );
      }

      if (values.category) {
        const category = values.market_product_category_ids.map(
          (el: any) => el.value
        );
        category.map((el: any) =>
          form_data.append("market_product_category_ids[]", el)
        );
      }

      if (values.tag) {
        const tag = values.market_tag_ids.map((el: any) => el.value);
        tag.map((el: any) => form_data.append("market_tag_ids[]", el));
      }

      mutation.mutate();
    },
  });
  
  const mutation = useMutation(
    () =>
      CouponRequest.simulatedEditCoupon(form_data, setSubmitting, params.id),
    {
      onMutate: () => {
        console.log("mutate edit coupon");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          const payload = data?.data;
          couponDispatch({ type: ActionType.ShowConfirmEdit, payload });
          couponDispatch({
            type: ActionType.HandleFormDataEdit,
            payload: form_data,
          });
        }
      },
    }
  );

  return {
    values,
    setValues,
    handleChange,
    handleSubmit,
    touched,
    errors,
    isSubmitting,
    setFile,
  };
};

export default EditCouponForm;
