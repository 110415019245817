import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import axiosCelestine from '../../../config/AxiosCelestine';

//components
import ErrorHandler from '../../../components/toast/ErrorHandler';

//icons
import { cancel } from '../../../assets/icons'

function DeleteCategories() {
    const history = useHistory()
    const params: {
        id: string
    } = useParams()
    const location: {
        state: {
            group_id: string
        }
    } = useLocation()
    const group_id = location.state.group_id

    const goBack = () => {
        history.push(`/marketplace/product-category-group/categories/${group_id}`)
    }

    const handleDelete = (id: string) => {
        axiosCelestine().delete(`admin/special-category/${id}`)
            .then(({ data }) => {
                ErrorHandler('#00C62E', 'Success, categories deleted!')
                setTimeout(() => {
                    history.push(`/marketplace/product-category-group/categories/${group_id}`)
                }, 1500)
            })
            .catch(err => ErrorHandler('#DF5354', 'Error to Delete this category!'))
    }

    return (
        <div className="absolute w-full left-0 top-0 z-50 h-screen pt-10 bg-black bg-opacity-70 flex justify-center">
            <div className="bg-white w-1/3 h-auto rounded-xl my-auto mx-auto font-med">
                <div className="flex w-full justify-end items-center p-8 pb-6">
                    <img src={cancel} alt="cancel" className="cursor-pointer w-5" onClick={goBack} />
                </div>
                <div className="px-10">
                    <div className="flex flex-col items-center">
                        <h1 className="text-xl">
                            Delete Categories
                        </h1>
                        <h1 className="text-base text-center w-72 text-textPrimary mt-4 font-rom">
                            Are you sure you want to delete the categories ?
                        </h1>
                    </div>

                    <div className="flex justify-center my-8 outline-none">
                        <button onClick={goBack} className="mr-3 w-40 h-10 rounded-full bg-white border-2 border-breadscrumbColor focus:outline-none hover:opacity-70">
                            <h1 className="text-textPrimary">Cancel</h1>
                        </button>
                        <button onClick={() => handleDelete(params.id)} className="w-40 h-10 rounded-full bg-primary focus:outline-none hover:opacity-70">
                            <h1 className="text-white">Continue</h1>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteCategories
