import axios from "axios";
import LocalStorage from "./LocalStorage";

export class AxiosInstance {
  static Pandora = () => {
    let value = LocalStorage.AUTH_TOKEN || localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_PANDORA_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
        version: "23.6",
      },
    });
  };

  static Cygnus = () => {
    let value = LocalStorage.AUTH_TOKEN || localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_CYGNUS_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
      },
    });
  };

  static Celestine = () => {
    let value = LocalStorage.AUTH_TOKEN || localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_CELESTINE_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
      },
    });
  };
}
