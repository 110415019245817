import React, { useContext, useRef } from "react";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import { loadOptionsProduct } from "../../../../../utils/loadOption/LoadOption";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Input from "../../../../../components/input/Input";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";
import HandleClickOutside from "../../../../../utils/click_outside_box/HandleClickOutside";

import SetQueryString from "../../../../../utils/query_searchParams/SetQueryString";
import { useHistory, useLocation } from "react-router-dom";
import { queryparamsProductReview } from "../../helpers/list";
import { ActionType } from "../../../../../store/product/product_need_review/actions";

const statusOptions: any[] = [
  { value: "stock_x", label: "Stock x" },
  { value: "kick_avenue", label: "Kick Avenue" },
  { value: "goat", label: "GOAT" },
];
const OptionsProductStatus: any[] = [
  { value: "true", label: "New Product" },
  { value: "false", label: "Existing" },
];
const OptionsProductNeedReview: any[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const ProductReviewFilter = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { productReviewDispatch, productReviewState } =
    useContext(ProductReviewContext);
  const { showFilter } = productReviewState;
  const wrapperRef = useRef(null);
  HandleClickOutside(
    wrapperRef,
    productReviewDispatch,
    showFilter,
    ActionType.ShowFilter
  );

  const handleShowFilter = () => {
    productReviewDispatch({ type: ActionType.ShowFilter });
  };

  const handleChangeFilter = (event: any, default_name?: string) => {
    let name;
    let value;
    if (!event?.label) {
      name = event.target.name;
      value = event.target.value;
    } else {
      value = event.value;
      name = default_name;
    }
    SetQueryString(
      queryparamsProductReview,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  return (
    <div className="fixed z-50 cursor-pointer h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        ref={wrapperRef}
        className="bg-white md:rounded-lg cursor-default rounded-t-none rounded-b-lg overflow-hidden h-3/4 shadow-xl transform transition-all md:w-1/2 w-full"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/6">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full" />
        <div className="h-4/6 overflow-auto">
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Product Code (SKU) / Name
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptionsProduct}
                onChange={(e) => handleChangeFilter(e, "code")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">Source</h1>
              <Select
                isSearchable={true}
                className="my-3.5 font-rom text-sm"
                options={statusOptions}
                onChange={(e) => handleChangeFilter(e, "source")}
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">Product Status</h1>
              <Select
                isSearchable={true}
                className="my-3.5 font-rom text-sm"
                options={OptionsProductStatus}
                onChange={(e) => handleChangeFilter(e, "status")}
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">Product Neew Review</h1>
              <Select
                isSearchable={true}
                className="my-3.5 font-rom text-sm"
                options={OptionsProductNeedReview}
                onChange={(e) => handleChangeFilter(e, "need_review")}
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="grid grid-cols-2 gap-8 w-full px-10 md:my-0 my-3 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Crawl Time (from)
              </h1>
              <Input
                type={"date"}
                name={"crawl_time_from"}
                handleChange={handleChangeFilter}
              />
            </div>
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Crawl Time (to)
              </h1>
              <Input
                type={"date"}
                name={"crawl_time_to"}
                handleChange={handleChangeFilter}
              />
            </div>
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-center items-center h-1/6">
          <SmallButton
            type={"button"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={handleShowFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductReviewFilter;
