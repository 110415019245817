import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { SizingRequest } from "../../../../apis/sizing/SizingRequest";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetExceptionBrand = (
  product_category_id: string,
  product_brand_id: string
) => {
  const query = useQueries(useLocation);
  const { data, isLoading } = useQuery(
    [
      "GetExceptionBrand",
      product_category_id,
      product_brand_id,
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
    ],
    () =>
      SizingRequest.getExceptionBrand(product_category_id, product_brand_id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetExceptionBrand;
