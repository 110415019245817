import { createContext } from "react";
import { ICreditEffectContext, ICreditEffectState } from "./state";

export const initialCreditEffectState: ICreditEffectState = {
  showFilter: false,
  showDelete: {
    status: false,
    id: 0,
  },
  chip: [],
};

const CreditEffectContext = createContext<ICreditEffectContext>({
  CreditEffectState: initialCreditEffectState,
  CreditEffectDispatch: () => undefined,
});

export const CreditEffectContextProvider = CreditEffectContext.Provider;
export const CreditEffectContextConsumer = CreditEffectContext.Consumer;

export default CreditEffectContext;
