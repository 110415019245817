import moment from "moment";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../components/loading/Loading";
import NavDetail from "../../../../components/navbar/NavDetail";
import CampaignContext from "../../../../store/campaign/CampaignContext";
import { optRoutesDetailCampaignProduct } from "../../helpers/list";
import GetCampaignTemplateId from "../../helpers/use_query_hook/GetCampaignTemplateId";
import CampaignProductStockPerSize from "./campaign_product_details/CampaignProductStockPerSize";
import CampaignProductTable from "./campaign_product_details/CampaignProductTable";

const CampaignProductDetail = () => {
  const history = useHistory();
  const params: { id: string; templateId: string } = useParams();
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const { CampaignState } = useContext(CampaignContext);
  const { showProductPerSize } = CampaignState;

  return (
    <>
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Detail"
          title2="Campaign Product"
          handleBack={() => history.goBack()}
        />
      </div>
      {showProductPerSize.status && <CampaignProductStockPerSize product_id={data?.product_id} />}
      <div>
        <DynamicBreadcrumb
          data={optRoutesDetailCampaignProduct(params.id, params.templateId)}
        />
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <main className="mt-14 h-screen">
            <section className="flex h-2/5 ">
              <div className="w-1/3 flex justify-center items-center border rounded-md ">
                <img src={data.product_image} alt="-" />
              </div>
              <div className="w-2/3 ml-10">
                <h1 className="text-2xl">{data.product_name}</h1>
                <div className="flex items-center mt-2">
                  <h1 className="text-lg">Status:</h1>
                  <h1
                    className={
                      "text-lg ml-1 " +
                      (data.active ? "text-success" : "text-primary")
                    }
                  >
                    {data.active ? " Posted" : " Not Posted"}
                  </h1>
                </div>
                <div className="mt-5 grid grid-cols-3 gap-5">
                  <div className="border-2 rounded-md flex flex-col justify-center px-7 py-5">
                    <h1 className="font-rom text-sm">Event Date</h1>
                    <h1 className="text-base mt-1">-</h1>
                  </div>
                  <div className="border-2 rounded-md flex flex-col justify-center px-7 py-5">
                    <h1 className="font-rom text-sm">
                      Start Join Waiting Room
                    </h1>
                    <h1 className="text-base mt-1">
                      {moment(data.start_pre_waiting_room_at).format("LLLL")}
                    </h1>
                  </div>
                  <div className="border-2 rounded-md flex flex-col justify-center px-7 py-5">
                    <h1 className="font-rom text-sm">Start Join Queue</h1>
                    <h1 className="text-base mt-1">
                      {moment(data.start_join_waiting_room_at).format("LLLL")}
                    </h1>
                  </div>
                  <div className="border-2 rounded-md flex flex-col justify-center px-7 py-5">
                    <h1 className="font-rom text-sm">Limit / User</h1>
                    <h1 className="text-base mt-1">1</h1>
                  </div>
                  <div className="border-2 rounded-md flex flex-col justify-center px-7 py-5">
                    <h1 className="font-rom text-sm">Room Capacity (Person)</h1>
                    <h1 className="text-base mt-1">
                      {data.transaction_room_capacity}
                    </h1>
                  </div>
                  <div className="border-2 rounded-md flex flex-col justify-center px-7 py-5">
                    <h1 className="font-rom text-sm">
                      Transaction Duration (Minute)
                    </h1>
                    <h1 className="text-base mt-1">
                      {data.transaction_room_duration_minutes}
                    </h1>
                  </div>
                </div>
              </div>
            </section>

            <section className="my-14">
              <h1 className="text-xl">Stocks</h1>
              <hr className="my-5" />
              <CampaignProductTable data={data} />
            </section>
          </main>
        )}
      </div>
    </>
  );
};

export default CampaignProductDetail;
