import moment from "moment";
import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import LocalStorage from "../../config/LocalStorage";
import { queryparamsProductDepatu } from "../../pages/product/product_depatu/helpers/list";
import { ActionType } from "../../store/product/product_depatu/actions";
import ApplyChipTS from "../../utils/applyChip/ApplyChipTS";

export class ProductRequest {
  static getProduct = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const role = LocalStorage.ROLE;
      const results = await axiosCelestine().get(
        `${role === "admin" ? "admin" : "crawler"}/product`,
        {
          params: {
            ...(query.get("product_id") !== null && {
              id: query.get("product_id"),
            }),
            ...(query.get("product") !== null && {
              search: query.get("product"),
            }),
            ...(query.get("status") !== null && {
              is_active: query.get("status"),
            }),
            ...(query.get("category") !== null && {
              product_category_id: query.get("category"),
            }),
            ...(query.get("release_date_from") !== null &&
            query.get("release_date_to") !== null
              ? {
                  release_date: `${query.get("release_date_from")},${query.get(
                    "release_date_to"
                  )}`,
                }
              : ""),
            ...(query.get("release_date_from") !== null &&
            query.get("release_date_to") === null
              ? { release_date: `${query.get("release_date_from")},2100-01-01` }
              : ""),
            ...(query.get("release_date_from") === null &&
            query.get("release_date_to") !== null
              ? { release_date: `2000-01-01,${query.get("release_date_to")}` }
              : ""),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChipTS(queryparamsProductDepatu, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getProductDetail = async (id: string) => {
    const role = LocalStorage.ROLE;
    try {
      const results = await axiosCelestine().get(
        `${role === "admin" ? "admin" : "crawler"}/product/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getProductScrapedCode = async (code: string) => {
    const role = LocalStorage.ROLE;
    try {
      const results = await axiosCelestine().get(
        `${role === "admin" ? "admin" : "crawler"}/scraped-product/${code}`
      );
      const result = results.data.data;
      if (result) {
        return result;
      }
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static uploadImage = async (
    payload: any,
    productReviewDispatch: any,
    type: string
  ) => {
    const role = LocalStorage.ROLE;
    try {
      const results = await axiosCelestine().post(
        `/${
          role === "admin" ? "admin" : "crawler"
        }/product/6123b9d82413a49eccc750c2/upload-image`,
        payload
      );
      const result = results.data;
      productReviewDispatch({
        type:
          type === "edit"
            ? ActionType.AddDisplayImgEdit
            : ActionType.AddDisplayImgAdd,
        payload: result,
      });
      ErrorHandler("#00C62E", "Upload Success");
      const removeFile: any = document.getElementsByClassName(
        "filepond--file-action-button filepond--action-remove-item"
      );
      removeFile[0]?.click();
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static uploadDetailImage = async (payload: any) => {
    const role = LocalStorage.ROLE;
    try {
      const results = await axiosCelestine().post(
        `/${
          role === "admin" ? "admin" : "crawler"
        }/product/6123b9d82413a49eccc750c2/upload-image`,
        payload
      );

      ErrorHandler("#00C62E", "Upload Success");
      return results;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static editProduct = async (
    payload: any,
    displayImage: string | null,
    dataImage: any[],
    dimension: any,
    date: any,
    id: string,
    pictureInit: any
  ) => {
    try {
      const role = LocalStorage.ROLE;
      const data: any = {
        name: payload.name,
        code: payload.code,
        is_active: payload.is_active,
        story: payload.story,
        gender: Number(payload.gender.value),
        color: payload.color,
        retail_price_currency: payload.retail_price_currency.value,
        retail_price: payload.retail_price,
        product_brand_id: payload.brand.value,
        product_category_id: payload.category.value,
        package_type_id: payload.type.value,
        display_image_url: displayImage,
        original_display_image_url: displayImage,
      };
      if (pictureInit.value === "2d") {
        data["detail_image_urls"] = dataImage;
      } else {
        data["carousel_image_urls"] = dataImage;
      }

      if (dimension.length > 0) {
        data["custom_attributes"] = dimension;
      }
      if (payload.is_active_brand_secondary) {
        const secondary_product_brand_ids = payload.secondary_brand.map(
          (el: any) => el.value
        );
        data["secondary_product_brand_ids"] = secondary_product_brand_ids;
      }
      if (payload.is_active_category_secondary) {
        const secondary_product_category_ids = payload.secondary_category.map(
          (el: any) => el.value
        );
        data["secondary_product_category_ids"] = secondary_product_category_ids;
      }
      if (payload.is_active_product_secondary) {
        const secondary_code_ids = payload.secondary_code.map(
          (el: any) => el.value
        );
        data["secondary_codes"] = secondary_code_ids;
      }
      if (date.value === "date") {
        data["release_date"] = moment(payload.release_date).format("");
      } else if (date.value === "season") {
        data["season_id"] = payload.season_id.value;
        data["season_year"] = Number(payload.season_year.value);
      }
      const results = await axiosCelestine().put(
        `${role === "admin" ? "admin" : "crawler"}/product/${id}`,
        data
      );
      const result = results.data;
      ErrorHandler("#00C62E", "Edit Completed");
      return result;
    } catch (error: any) {
      console.log(error);
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static patchProductImage = async (
    displayImage: string | null,
    dataImage: any[],
    id: string,
    pictureInit: any,
    scraped_product_id: number
  ) => {
    try {
      const role = LocalStorage.ROLE;
      const data: any = {
        display_image_url: displayImage,
        original_display_image_url: displayImage,
        scraped_product_id,
      };
      if (pictureInit.value === "2d") {
        data["detail_image_urls"] = dataImage;
      } else {
        data["carousel_image_urls"] = dataImage;
      }
      const results = await axiosCelestine().patch(
        `${role === "admin" ? "admin" : "crawler"}/product/${id}/update-image`,
        data
      );
      ErrorHandler("#00C62E", "Edit Completed");
      const result = results.data;
      return result;
    } catch (error: any) {
      console.log(error);
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static addProduct = async (
    payload: any,
    displayImage: string | null,
    dataImage: any[],
    dimension: any,
    date: any
  ) => {
    try {
      const role = LocalStorage.ROLE;
      const data: any = {
        name: payload.name,
        code: payload.code,
        is_active: payload.is_active,
        story: payload.story,
        gender: Number(payload.gender.value),
        color: payload.color,
        retail_price_currency: payload.retail_price_currency.value,
        retail_price: payload.retail_price,
        product_brand_id: payload.brand.value,
        product_category_id: payload.category.value,
        package_type_id: payload.type.value,
        display_image_url: displayImage,
        original_display_image_url: displayImage,
        detail_image_urls: dataImage,
      };
      if (dimension.length > 0) {
        data["custom_attributes"] = dimension;
      }
      if (payload.is_active_brand_secondary) {
        const secondary_product_brand_ids = payload.secondary_brand.map(
          (el: any) => el.value
        );
        data["secondary_product_brand_ids"] = secondary_product_brand_ids;
      }
      if (payload.is_active_category_secondary) {
        const secondary_product_category_ids = payload.secondary_category.map(
          (el: any) => el.value
        );
        data["secondary_product_category_ids"] = secondary_product_category_ids;
      }
      if (payload.is_active_product_secondary) {
        const secondary_code_ids = payload.secondary_code.map(
          (el: any) => el.value
        );
        data["secondary_codes"] = secondary_code_ids;
      }
      if (date.value === "date") {
        data["release_date"] = moment(payload.release_date).format("");
      } else if (date.value === "season") {
        data["season_id"] = payload.season_id.value;
        data["season_year"] = Number(payload.season_year.value);
      }
      const results = await axiosCelestine().post(
        `${role === "admin" ? "admin" : "crawler"}/product`,
        data
      );
      const result = results.data;
      ErrorHandler("#00C62E", "Add Completed");
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };
}
