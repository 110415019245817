import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";
import ATextField from "../../atoms/AtextField";
import * as Yup from "yup";
import Aselect from "../../atoms/Aselect";
import { Divider } from "@material-ui/core";
import { useMutatePublishFinalResult } from "../../../hooks/legit_check";
import { ILegitCheck } from "../../../core/legit_check/entities";
import isEmpty from "lodash.isempty";
import { useMutateConfirmFinalResult } from "../../../hooks/bnib_transaction";

interface IOgiveResultLegitCheckProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  handleNext: () => void;
  legitCheck: ILegitCheck | undefined;
}

const OgiveResultLegitCheck: React.FC<IOgiveResultLegitCheckProps> = ({
  isOpen,
  setOpen,
  handleNext,
  legitCheck,
}) => {
  const params: { code: string } = useParams();

  const { handleChange, handleSubmit, values, errors, touched, setValues } =
    useFormik({
      initialValues: {
        final_result: "",
        confirm_result: "",
      },
      validationSchema: Yup.object({
        final_result: Yup.string().required("Result is required!"),
        confirm_result: Yup.string()
          .oneOf(
            [Yup.ref("final_result")],
            "The confirm result does not match!"
          )
          .required("Confirm result is required!"),
      }),
      onSubmit: (values) => {
        mutate(values);
      },
    });

  const { mutate: mutateConfirmLC, isLoading: loadConfirmLC } =
    useMutateConfirmFinalResult(params?.code, handleNext, setOpen);

  const { mutate, isLoading } = useMutatePublishFinalResult(
    legitCheck?.id || 0,
    mutateConfirmLC,
    values
  );

  useEffect(() => {
    if (!isEmpty(legitCheck) && legitCheck?.final_result) {
      setValues({ ...values, final_result: legitCheck.final_result });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legitCheck]);

  return (
    <ADialogBox
      content={{
        title: "Confirm Result",
        body: (
          <form onSubmit={handleSubmit} className="flex flex-col">
            <Divider />
            <div className="flex flex-wrap mt-5 items-center justify-between">
              <h1>Result :</h1>
              <div className="w-2/3 mb-1">
                <Aselect
                  options={[
                    { value: "authentic", label: "authentic" },
                    { value: "fake", label: "fake" },
                    { value: "indefinable", label: "indefinable" },
                  ]}
                  name="final_result"
                  value={values.final_result}
                  handleChange={handleChange}
                  //   disabled={legitCheck?.final_result}
                  error={
                    touched.final_result && errors.final_result ? true : false
                  }
                  helperText={errors.final_result}
                  label="Result"
                />
              </div>
            </div>
            <div className="flex flex-wrap mt-4 items-center justify-between">
              <h1>Confirm Result :</h1>
              <div
                className={
                  "h-10 w-2/3 " +
                  (touched.confirm_result && errors.confirm_result
                    ? "mb-5"
                    : "mb-1")
                }
              >
                <ATextField
                  label="Confirm result"
                  type="text"
                  name="confirm_result"
                  placeholder="Place Holder"
                  value={values.confirm_result}
                  handleChange={handleChange}
                  error={
                    touched.confirm_result && errors.confirm_result
                      ? true
                      : false
                  }
                  helperText={errors.confirm_result}
                />
              </div>
            </div>

            <div className="mt-7 flex gap-3 justify-end">
              <Abutton
                title="Cancel"
                theme="secondary"
                onClick={() => setOpen(false)}
              />
              <Abutton
                isLoading={isLoading || loadConfirmLC}
                type="submit"
                title="Confirm"
              />
            </div>
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={setOpen}
    />
  );
};

export default OgiveResultLegitCheck;
