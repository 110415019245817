import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import CircularLoad from "../../../components/loading/CircularLoad";
import Loading from "../../../components/loading/Loading";
import NewSorting from "../../../components/sorting/NewSorting";
import { ActionType } from "../../../store/product/product_depatu/actions";
import ProductDepatuContext from "../../../store/product/product_depatu/ProductDepatuContext";
import {
  optRoutesProductDepatu,
  optSortingproductDepatu,
  queryparamsProductDepatu,
} from "./helpers/list";
import GetProduct from "./helpers/use_query_hooks/GetProduct";
import ProductDepatuFilter from "./components/product_list/ProductDepatuFilter";
import ProductDepatuTable from "./components/product_list/ProductDepatuTable";
import { validateRole } from "../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../layout/marketplace/interfaces";
import LocalStorage from "../../../config/LocalStorage";

const ProductDepatu = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const history = useHistory();
  const { productDepatuState, productDepatuDispatch } =
    useContext(ProductDepatuContext);
  const { showFilter } = productDepatuState;
  const { data, isFetching, isLoading } = GetProduct();

  return (
    <div>
      {showFilter && <ProductDepatuFilter />}
      <section className="" id="top">
        <DynamicBreadcrumb data={optRoutesProductDepatu} />
        <div className="md:mt-12 mt-10">
          {validateRole(role, [
            AcceptedRole.Admin,
            AcceptedRole.Crawler,
            AcceptedRole.Operation,
            AcceptedRole.Product,
            AcceptedRole.KeyAccount,
          ]) && (
            <div className="flex justify-end">
              <h1
                onClick={() =>
                  history.push(`/marketplace/product-depatu/add-product`)
                }
                className="text-primary md:text-base cursor-pointer inline-flex "
              >
                Add Product
              </h1>
            </div>
          )}
          <div className="flex justify-between mt-2">
            <div className="flex">
              <h1
                onClick={() =>
                  productDepatuDispatch({
                    type: ActionType.ShowFilter,
                  })
                }
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSortingproductDepatu} />
          </div>

          <Chip
            dispatchContext={productDepatuDispatch}
            stateContext={productDepatuState}
            queryParams={queryparamsProductDepatu}
            ActionType={ActionType}
          />
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section id="body" className="mb-16 mt-8">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <ProductDepatuTable data={data} />
          </div>
        )}
      </section>
    </div>
  );
};

export default ProductDepatu;
