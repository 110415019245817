import { IShippingAddress } from "../shipping_address/entities";
import { IProduct } from "../product/entities";
import { ILegitCheck } from "../legit_check/entities";
import { IBnibBuyOrder } from "../bnib_buy_order/entities";
import { IWarehouse } from "../warehouse/entities";

export interface IPaymentInvoice {
  amount: number;
  billing_fee_total: number;
  billing_fees: any[];
  bnib_buy_order: string;
  buyer_address: string;
  buyer_email: string;
  buyer_name: string;
  buyer_username: string;
  code: string;
  created_at: string;
  effect_result: string;
  expired_at: string;
  extra_fees: IExtraFee[];
  id: number;
  installment_term: string;
  is_bidding: boolean;
  is_pre_order: boolean;
  is_refunded: boolean;
  payment_type: string;
  payout_fee_total: number;
  payout_fees: any[];
  refund_transaction: string;
  shipping_message: string;
  status: string;
  type: string;
  user_id: number;
  validation_passed: boolean;
  validation_reasons: string;
  waiting_payment_invoice: string | boolean;
  wallet_mutation_id: number;
}

export interface IExtraFee {
  amount: number;
  description: string;
  id: number;
  invoice_code: string;
  name: string;
  old: boolean;
  scope: string;
  type: string;
}

export interface IBnibTransaction {
  access_log: string;
  accessLog: Record<EAccessLogStatus, IAccessLogItem>;
  admin_fee: number;
  bid_payment_expired_at: string;
  bnib_buy_order_id: number;
  bnib_buy_order: IBnibBuyOrder;
  bnib_buy_order_invoice_code: string;
  bnib_product_id: number;
  buy_price: number;
  buyer_confirmation: number | boolean;
  buyer_confirmation_expired_at: string;
  buyer_id: number;
  buyer_shipping_address_id: number;
  buyer_username: string;
  code: string;
  commitment_fee: number;
  office_custom_tracking_origin: any;
  current_office_custom_tracking_step: any;
  current_office_custom_tracking_step_id: number;
  custom_tracking_enabled: boolean;
  created_at: string;
  defected_buyer_approval: boolean | number;
  defected_buyer_approval_expired_at: string;
  defected_image_url: string;
  defected_image_urls: string[];
  defected_note: string;
  id: number;
  is_defected: number | boolean;
  legit_checked: number | boolean;
  legit_status: string;
  office_shipping_input_expired_at: string;
  office_shipping_provider: string;
  office_shipping_tracking_code: string;
  product_detail: IProduct;
  refund_shipping_address_id: number;
  refund_shipping_provider: string;
  refund_shipping_tracking_code: string;
  seller_confirmation_expired_at: string;
  seller_id: number;
  seller_username: string;
  shipping_address: IShippingAddress;
  shipping_cost: number;
  status: number;
  status_message: string;
  refund_reason: string;
  seller_phone: string;
  buyer_phone: string;
  pre_order: boolean;
  payment_invoice: IPaymentInvoice;
  bid_payment_invoice: IPaymentInvoice;
  legit_check: ILegitCheck;
  buyer_shipping_courier_name: string;
  buyer_shipping_maximum_estimate_time_at: string;
  buyer_shipping_minimum_estimate_time_at: string;
  buyer_shipping_pickup_time: string;
  buyer_shipping_provider: string;
  buyer_shipping_rate_code: string;
  buyer_shipping_tracking_code: string;
  buyer_shipping_cost: number;
  office_shipping_courier_name: string;
  refund_shipping_courier_name: string;
  refund_shipping_pickup_time: string;
  warehouse: IWarehouse;
  seller_shipping_address: IShippingAddress;
  location: {
    stock_move_location_dest_id: number[];
    stock_move_location_id: number[]
  }
}
export type PBnibTransaction = Partial<IBnibTransaction>;

export enum EBnibTransactionStatus {
  WaitingPaymentFromBuyer,
  WaitingTrackingCode,
  SellerExpired,
  BuyerExpired,
  ShippingToDepatu,
  ArrivedAtDepatu,
  LegitChecking,
  LegitCheckAuthentic,
  LegitCheckIndefinable,
  LegitCheckFake,
  RefundedByDepatu,
  DisputedByDepatu,
  AcceptedByDepatu,
  DefectProceedApproval,
  DefectReject,
  ShippingToBuyer,
  ArrivedAtBuyer,
  ShippingToSeller,
  ArrivedAtSeller,
  BuyerConfirmation,
  SellerCancel,
  Done,
  BnibTransactionStatusReadyForLegitCheck,
  BnibTransactionStatusReadyShippingForSeller,
  BnibTransactionStatusReadyShippingForBuyer,
  BnibTransactionStatusSellerConfirmation,
  BnibTransactionStatusWaitingSellerRefundPayment,
}

export const listFilter = [
  {
    title: "All transaction",
    status: [
      EBnibTransactionStatus.WaitingPaymentFromBuyer,
      EBnibTransactionStatus.WaitingTrackingCode,
      EBnibTransactionStatus.SellerExpired,
      EBnibTransactionStatus.BuyerExpired,
      EBnibTransactionStatus.ShippingToDepatu,
      EBnibTransactionStatus.ArrivedAtDepatu,
      EBnibTransactionStatus.LegitChecking,
      EBnibTransactionStatus.LegitCheckAuthentic,
      EBnibTransactionStatus.LegitCheckIndefinable,
      EBnibTransactionStatus.LegitCheckFake,
      EBnibTransactionStatus.RefundedByDepatu,
      EBnibTransactionStatus.DisputedByDepatu,
      EBnibTransactionStatus.AcceptedByDepatu,
      EBnibTransactionStatus.DefectProceedApproval,
      EBnibTransactionStatus.DefectReject,
      EBnibTransactionStatus.ShippingToBuyer,
      EBnibTransactionStatus.ArrivedAtBuyer,
      EBnibTransactionStatus.ShippingToSeller,
      EBnibTransactionStatus.ArrivedAtSeller,
      EBnibTransactionStatus.BuyerConfirmation,
      EBnibTransactionStatus.SellerCancel,
      EBnibTransactionStatus.Done,
      EBnibTransactionStatus.BnibTransactionStatusReadyForLegitCheck,
      EBnibTransactionStatus.BnibTransactionStatusReadyShippingForSeller,
      EBnibTransactionStatus.BnibTransactionStatusReadyShippingForBuyer,
      EBnibTransactionStatus.BnibTransactionStatusSellerConfirmation,
      EBnibTransactionStatus.BnibTransactionStatusWaitingSellerRefundPayment,
    ],
  },
  {
    title: "Waiting Seller Send Product",
    status: [EBnibTransactionStatus.WaitingTrackingCode],
  },
  {
    title: "Shipping to Depatu",
    status: [EBnibTransactionStatus.ShippingToDepatu],
  },
  {
    title: "Arrived at Depatu",
    status: [EBnibTransactionStatus.ArrivedAtDepatu],
  },
  // {
  //   title: "Ready for Identification",
  //   status: [EBnibTransactionStatus.AcceptedByDepatu],
  // },
  {
    title: "Ready for QC",
    status: [EBnibTransactionStatus.AcceptedByDepatu],
  },
  {
    title: "Product Defect",
    status: [EBnibTransactionStatus.DefectProceedApproval],
  },
  {
    title: "LC List",
    status: [EBnibTransactionStatus.BnibTransactionStatusReadyForLegitCheck],
  },
  {
    title: "Authenticating",
    status: [
      EBnibTransactionStatus.LegitChecking,
      EBnibTransactionStatus.LegitCheckAuthentic,
      EBnibTransactionStatus.LegitCheckIndefinable,
      EBnibTransactionStatus.LegitCheckFake,
    ],
  },
  // {
  //   title: "Ready for Service",
  //   status: [EBnibTransactionStatus.PaymentCancelled],
  // },
  // {
  //   title: "On Service",
  //   status: [EBnibTransactionStatus.PaymentCancelled],
  // },
  {
    title: "Waiting Seller Payment",
    status: [
      EBnibTransactionStatus.BnibTransactionStatusWaitingSellerRefundPayment,
    ],
  },
  {
    title: "Ready for Shipping",
    status: [
      EBnibTransactionStatus.BnibTransactionStatusReadyShippingForSeller,
      EBnibTransactionStatus.BnibTransactionStatusReadyShippingForBuyer,
    ],
  },
  {
    title: "On Shipping",
    status: [
      EBnibTransactionStatus.ShippingToBuyer,
      EBnibTransactionStatus.ArrivedAtBuyer,
      EBnibTransactionStatus.ShippingToSeller,
      EBnibTransactionStatus.ArrivedAtSeller,
    ],
  },
  {
    title: "Waiting Confirmation",
    status: [
      EBnibTransactionStatus.BuyerConfirmation,
      EBnibTransactionStatus.BnibTransactionStatusSellerConfirmation,
    ],
  },
  // {
  //   title: "Complain",
  //   status: [EBnibTransactionStatus.PaymentCancelled],
  // },
  {
    title: "Done",
    status: [EBnibTransactionStatus.Done],
  },
  {
    title: "Cancelled",
    status: [
      EBnibTransactionStatus.SellerExpired,
      EBnibTransactionStatus.BuyerExpired,
      EBnibTransactionStatus.RefundedByDepatu,
      EBnibTransactionStatus.DisputedByDepatu,
      EBnibTransactionStatus.DefectReject,
      EBnibTransactionStatus.SellerCancel,
    ],
  },
];

export enum EAccessLogStatus {
  WaitingSellerInputTrack = "waiting-seller-input-track",
  WaitingBuyerPayment = "waiting-buyer-payment",
  BuyerPaid = "buyer-paid",
  SellerInputTrack = "seller-input-track",
  AdminInputCustomTrack = "admin-input-custom-track",
  Arrived = "arrived",
  Accepted = "accepted",
  Rejected = "rejected",
  ArrivedAtBuyer = "arrived-at-buyer",
  ConfirmationBuyer = "arrived-at-buyer",
  DefectTrue = "defect-true",
  DefectFalse = "defect-false",
  DefectTrueAccept = "defect-true-accept",
  DefectTrueReject = "defect-true-reject",
  Disputed = "disputed",
  LegitCheckFake = "legit-check-fake",
  LegitCheckIndefinable = "legit-check-indefinable",
  LegitCheckAuthentic = "legit-check-authentic",
  Refunded = "refunded", // send back to seller
  SellerCancel = "seller-cancel",
  DepatuSend = "depatu-send", // send to buyer
}

export interface IAccessLogItem {
  admin_username: string;
  user_id: number;
  time: string;
}

interface IBnibTransactionFilter {
  id: string;
  code: string;
  status: string;
  buyer_username: string;
  seller_username: string;
  office_shipping_tracking_code: string;
  buyer_shipping_tracking_code: string;
  refund_shipping_tracking_code: string;
  date: string;
}
export type PBnibTransactionFilter = Partial<IBnibTransactionFilter>;

export interface IBnibTransactionCounter {
  status: number;
  status_message: string;
  count: number;
}
export type PBnibTransactionCounter = Partial<IBnibTransactionCounter>;

// export enum EBnibTransactionSortField {
//   code = "code",
//   created_at = "created_at",
//   id = "id",
//   is_defected = "is_defected",
//   legit_checked = "legit_checked",
//   legit_status = "legit_status",
//   status = "status",
//   office_shipping_tracking_code = "office_shipping_tracking_code",
//   buyer_shipping_tracking_code = "buyer_shipping_tracking_code",
//   refund_shipping_tracking_code = "refund_shipping_tracking_code",
// }
export enum EBnibTransactionSortField {
  newest = "created_at desc",
  oldest = "created_at asc",
  mostPrice = "price desc",
  leastPrice = "price asc",
}

export const listSort = [
  {
    label: "Newest Transaction",
    value: `${EBnibTransactionSortField.newest}`,
  },
  {
    label: "Oldest Transaction",
    value: `${EBnibTransactionSortField.oldest}`,
  },
  {
    label: "Most Price",
    value: `${EBnibTransactionSortField.mostPrice}`,
  },
  {
    label: "Least Price",
    value: `${EBnibTransactionSortField.leastPrice}`,
  },
];
