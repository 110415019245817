import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosCelestine from "../../../config/AxiosCelestine";

//components
import Loading from "../../../components/loading/Loading";
import Input from "../../../components/input/Input";
import ErrorHandler from "../../../components/toast/ErrorHandler";

export interface EditCategoriesProps {
  showEdit: number;
  setShowEdit: (showEdit: number) => void;
  refetch: any;
}

const EditCategories: React.FC<EditCategoriesProps> = ({
  showEdit,
  setShowEdit,
  refetch,
}) => {
  const [edited, setEdited] = useState<any>({
    name: "",
    published: false,
    product_category_group_id: 0,
  });

  const goBack = () => {
    setShowEdit(0);
  };

  const handlePost = () => {
    if (edited.published === true) {
      setEdited({ ...edited, published: false });
    } else {
      setEdited({ ...edited, published: true });
    }
  };

  const getCategoriesById = async () => {
    try {
      const results = await axiosCelestine().get(
        `/admin/special-category/${showEdit}`
      );
      const result = await results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const { data, isSuccess } = useQuery(
    ["getCategoriesById", showEdit],
    getCategoriesById,
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setEdited({
      name: data?.name,
      published: data?.published,
      product_category_group_id: data?.product_category_group_id,
    });
    return function () {
      return setEdited({
        name: "",
        published: false,
        product_category_group_id: 0,
      });
    };
  }, [data]);

  const handleChangeInput = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    setEdited({ ...edited, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (edited.name === data.name) {
      delete edited.name;
    }
    axiosCelestine()
      .patch(`admin/special-category/${showEdit}`, edited)
      .then(({ data }) => {
        refetch();
        ErrorHandler("#00C62E", "Edit Completed");
        setShowEdit(0);
      })
      .catch((err) => {
        ErrorHandler("#DF5354", "Edit Failed");
      });
  };

  return isSuccess ? (
    <form
      onSubmit={handleSubmit}
      className="md:w-productCategory md:max-w-md lg:w-productCategory lg:max-w-xl xl:w-productCategoryxl xl:max-w-customxl 2xl:w-productCategory2xl 2xl:max-w-2xl 3xl:w-productCategory3xl 3xl:max-w-3xl h-auto my-5 xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke"
    >
      <div className="mx-10 my-7 ">
        <div className="md:mt-6 mt-3">
          <div className="flex justify-between">
            <h1 className="md:text-base text-sm text-textPrimary">Name</h1>
            <div className="flex justify-between items-center">
              <h1 className="text-base mr-3">Posted</h1>
              <div
                className={
                  !edited.published
                    ? "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500"
                    : "md:w-8 md:h-4 flex items-center rounded-full p-1 duration-300 ease-in-out bg-primary"
                }
              >
                <div
                  className={
                    !edited.published
                      ? "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer"
                      : "bg-white md:w-3 md:h-3 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3 translate-x-3"
                  }
                  onClick={handlePost}
                ></div>
              </div>
            </div>
          </div>
          <Input
            type={"text"}
            handleChange={handleChangeInput}
            value={edited.name}
            disable={false}
            name={"name"}
            placeholder={"Place holder"}
          />
        </div>
        <div className="mt-4">
          <h1 className="md:text-base text-sm text-textPrimary">
            Product Category Group
          </h1>
          <Input
            type={"text"}
            handleChange={handleChangeInput}
            value={data?.product_category_group_id}
            disable={true}
            name={"name"}
            placeholder={"Place holder"}
          />
        </div>
      </div>

      <div className="flex justify-around text-lg h-16 w-full">
        <div className="border-2 border-b-0 border-l-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <h1 onClick={goBack} className="hover:text-primary">
            Cancel
          </h1>
        </div>
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-1/2 flex justify-center items-center">
          <button type="submit" className="focus:outline-none">
            <h1 className="hover:text-primary">Edit</h1>
          </button>
        </div>
      </div>
    </form>
  ) : (
    <div className="flex items-center justify-center md:w-productCategory md:max-w-md lg:w-productCategory lg:max-w-xl xl:w-productCategoryxl xl:max-w-customxl 2xl:w-productCategory2xl 2xl:max-w-2xl 3xl:w-productCategory3xl 3xl:max-w-3xl h-72">
      <Loading />
    </div>
  );
};

export default EditCategories;
