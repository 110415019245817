import { useQuery } from "react-query";
import { ShippingAddressService } from "../../apis/shipping_address";

const useFetchShippingAddress = (id: number) => {
  return useQuery(
    [`ShippingAddress-${id}`],
    () => ShippingAddressService.getShippingAddress(id),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchShippingAddressByUserId = (user_id: number) => {
  return useQuery(
    [`ShippingAddress-by-user-${user_id}`],
    () => ShippingAddressService.getShippingAddress(user_id),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchShippingAddress, useFetchShippingAddressByUserId };
