import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import db from "../../../../../config/InitFirebase";
import { bgDepatu } from "../../../../../assets/icons";
import isEmpty from "lodash.isempty";
import moment from "moment";
import SmallButton from "../../../../../components/button/SmallButton";
import AddQuantityShuffle from "../campaign_product/AddQuantityShuffle";
import DropWinnerModal from "../spin_product/DropWinnerModal";
import { validateRole } from "../../../../../utils/checkRole/CheckRole";
import { AcceptedRole } from "../../../../../layout/marketplace/interfaces";
import LocalStorage from "../../../../../config/LocalStorage";
import GetUsedTicket from "../../../helpers/use_query_hook/GetUsedTicket";
import CampaignSideCard from "../spin_product/CampaignSideCard";

const AnnounceWinner = () => {
  const history = useHistory();
  const params: { id: string; templateId: string } = useParams();
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { data, isLoading } = GetCampaignTemplateId(params.templateId);
  const { data: dataUsedTicket, isLoading: loadUsedTicket } = GetUsedTicket();
  const { data_spinner, drop_winner, showAddQuantityShuffle } = CampaignState;
  const [load, setLoad] = useState(false);
  const [id, setId] = useState<number>(0);
  const [status, setStatus] = useState<boolean>(false);
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");

  const getSnapshot = () => {
    setLoad(true);
    onSnapshot(
      collection(db, "campaign_product_template_detail"),
      (snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (doc) =>
              doc.campaign_product_template_id === +params.templateId &&
              doc.campaign_id === +params.id
          )[0];
        CampaignDispatch({
          type: ActionType.AddDataSpinner,
          payload: data,
        });
        setLoad(false);
        return;
      }
    );
  };

  useEffect(() => {
    getSnapshot();
  }, []);

  const isAvailableSpin = (winners: any[]) => {
    let status = true;
    for (let i = 0; i < winners?.length; i++) {
      if (winners[i]?.winner_status === "pending") {
        status = false;
      } else {
        status = true;
      }
    }
    setStatus(status);
  };

  useEffect(() => {
    isAvailableSpin(data_spinner?.winners);
  }, [data_spinner?.winners]);

  const spinAgain = () => {
    CampaignDispatch({
      type: ActionType.AddDataSpinner,
      payload: null,
    });
    CampaignDispatch({
      type: ActionType.ShowAddQuantityShuffle,
      payload: {
        status: true,
        id: Number(params.templateId),
      },
    });
  };

  const goResults = () => {
    history.push(
      `/marketplace/campaign/edit-campaign/${params.id}/results/${params.templateId}`
    );
  };

  const goParticipants = () => {
    history.push(
      `/marketplace/campaign/edit-campaign/${params.id}/participants/${params.templateId}`
    );
  };

  const goDropWinner = () => {
    CampaignDispatch({
      type: ActionType.DropWinner,
      payload: { status: true, id: id },
    });
  };

  return (
    <div className="absolute overflow-hidden top-0 left-0 z-30 bg-black w-screen h-screen flex">
      {drop_winner.status && <DropWinnerModal setId={setId} />}
      {showAddQuantityShuffle.status && <AddQuantityShuffle />}
      {isLoading || load || loadUsedTicket ? (
        <div className="flex justify-center w-full mt-20">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <>
          <CampaignSideCard
            onClick={() => setId(0)}
            data={data}
            dataUsedTicket={dataUsedTicket}
            title="SPECIAL PRICE"
          />
          <div className="w-2/3 h-full flex justify-center items-center text-white py-10">
            <section className="absolute flex justify-start items-end bottom-0 w-2/3 h-full top-20">
              <img src={bgDepatu} alt="-" />
            </section>
            {!isEmpty(data_spinner) && (
              <div className="flex flex-col items-center justify-center z-40 w-10/12 h-full">
                <h1 className="text-white font-med text-4xl">
                  CONGRATULATIONS
                </h1>

                <div className="my-5 w-full h-1/2 overflow-auto px-2">
                  {!isEmpty(data_spinner?.winners) &&
                    data_spinner?.winners.map((el: any, idx: number) => (
                      <aside
                        key={idx}
                        // ref={wrapperRef}
                        id={`box-${el.winner_id}`}
                        className="flex my-4"
                      >
                        <div className="w-1/12 text-center flex items-center justify-center text-3xl mr-4">
                          {idx + 1}.
                        </div>
                        <div
                          onClick={
                            el.winner_status === "pending"
                              ? () => setId(el.winner_id)
                              : () => {}
                          }
                          className={
                            "flex justify-between w-11/12 px-5 py-3 border-2 " +
                            (el.winner_status === "done"
                              ? "border-success cursor-not-allowed "
                              : el.winner_status === "failed"
                              ? "border-primary cursor-not-allowed "
                              : el.winner_id === id
                              ? "border-yellow-400 cursor-pointer "
                              : el.winner_status === "pending"
                              ? "border-white cursor-pointer "
                              : "border-white cursor-pointer ")
                          }
                        >
                          <div className="w-2/3 text-lg flex items-center">
                            <h1 className="">
                              {el?.winner_username} ({`${el?.total_ticket}`}{" "}
                              credits)
                            </h1>
                          </div>
                          <div className="w-1/3 font-rom text-sm flex flex-col justify-center items-center">
                            <h1>Last Updated Credit</h1>
                            <h1>
                              {moment(
                                el?.last_update_created_at?.seconds * 1000
                              ).format("LL hh:mm")}
                            </h1>
                          </div>
                        </div>
                      </aside>
                    ))}
                </div>

                <div className="flex w-full justify-center mt-10">
                  {status && (
                    <SmallButton
                      borderColor="border-white"
                      border={"border-2"}
                      color={"bg-black"}
                      text={"Back"}
                      height={14}
                      width={"1/5"}
                      type={"button"}
                      handleClick={() =>
                        history.push(
                          `/marketplace/campaign/edit-campaign/${params.id}`
                        )
                      }
                    />
                  )}
                  <div className="ml-5 w-1/5">
                    <SmallButton
                      borderColor="border-white"
                      border={"border-2"}
                      color={"bg-black"}
                      text={"Remove"}
                      height={14}
                      width={"full"}
                      textColor={id ? "text-white" : "text-textPrimary"}
                      type={"button"}
                      handleClick={id > 0 ? goDropWinner : () => {}}
                    />
                  </div>
                  {status && (
                    <div className="ml-5 w-1/5">
                      <SmallButton
                        text={"Add Winner"}
                        height={14}
                        width={"full"}
                        type={"button"}
                        handleClick={spinAgain}
                      />
                    </div>
                  )}
                  {status &&
                    !isLoading &&
                    validateRole(role, AcceptedRole.Admin) && (
                      <div className="ml-5 w-1/5">
                        <SmallButton
                          text={"Summary"}
                          height={14}
                          width={"full"}
                          type={"button"}
                          handleClick={goResults}
                        />
                      </div>
                    )}
                  {!isLoading && validateRole(role, AcceptedRole.Admin) && (
                    <div className="ml-5 w-1/5">
                      <SmallButton
                        text={"Participant"}
                        borderColor="border-white"
                        border={"border-2"}
                        color={"bg-black"}
                        height={14}
                        width={"full"}
                        type={"button"}
                        handleClick={goParticipants}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AnnounceWinner;
