import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  EBnibBuyOrderSortField,
  IBnibBuyOrder,
  IBnibBuyOrderCounter,
  PBnibBuyOrderFilter,
} from "../entities";
import IBnibBuyOrderRepository from "../repository";
import IBnibBuyOrderUsecase from "../usecase";

export default class BnibBuyOrderUsecase implements IBnibBuyOrderUsecase {
  private BnibBuyOrderRepo: IBnibBuyOrderRepository;

  constructor(BnibBuyOrderRepo: IBnibBuyOrderRepository) {
    this.BnibBuyOrderRepo = BnibBuyOrderRepo;
  }

  async getBnibBuyOrders(
    pagination: IPagination,
    filter: PBnibBuyOrderFilter,
    sorts: IBuiltinSort<EBnibBuyOrderSortField>[]
  ): Promise<[IBnibBuyOrder[], IMeta]> {
    const [data, meta] = await this.BnibBuyOrderRepo.getBnibBuyOrders(
      pagination,
      filter,
      sorts
    );
    return [data, meta];
  }

  async getBnibBuyOrder(code: string): Promise<IBnibBuyOrder> {
    const BnibBuyOrder = await this.BnibBuyOrderRepo.getBnibBuyOrder(code);
    return BnibBuyOrder;
  }

  async getBnibBuyOrderById(id: number): Promise<IBnibBuyOrder> {
    const BnibBuyOrder = await this.BnibBuyOrderRepo.getBnibBuyOrderById(id);
    return BnibBuyOrder;
  }

  async getBnibBuyOrderCounter(
    filter: PBnibBuyOrderFilter
  ): Promise<IBnibBuyOrderCounter[]> {
    const data = await this.BnibBuyOrderRepo.getBnibBuyOrderCounter(filter);
    return data;
  }
}
