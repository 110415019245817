import React, { useReducer } from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import ProductDepatu from "../../../../pages/product/product_depatu/ProductDepatu";
import {
  initialProductDepatuState,
  ProductDepatuContextProvider,
} from "../../../../store/product/product_depatu/ProductDepatuContext";
import { productDepatuReducer } from "../../../../store/product/product_depatu/ProductDepatuReducer";
import ProductDetail from "../../../../pages/product/product_depatu/ProductDetail";
import AddProduct from "../../../../pages/product/product_depatu/AddProduct";
import EditProduct from "../../../../pages/product/product_depatu/EditProduct";
import RoleGuard from "../../../../utils/guards/RoleGuard";
import { AcceptedRole } from "../../interfaces";
import LocalStorage from "../../../../config/LocalStorage";

const ProductDepatuRoute = () => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const [productDepatuState, productDepatuDispatch] = useReducer(
    productDepatuReducer,
    initialProductDepatuState
  );
  return (
    <ProductDepatuContextProvider
      value={{ productDepatuState, productDepatuDispatch }}
    >
      <div className="md:pt-28 sm:pt-20 pt-16 font-med">
        <Switch>
          <Route
            path="/marketplace/product-depatu"
            exact
            component={ProductDepatu}
          />

          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Crawler,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/product-depatu/add-product"
            component={AddProduct}
          />

          <RoleGuard
            accepted_role={[
              AcceptedRole.Admin,
              AcceptedRole.Crawler,
              AcceptedRole.Operation,
              AcceptedRole.Product,
              AcceptedRole.KeyAccount,
            ]}
            role={role}
            path="/marketplace/product-depatu/edit-product/:id/code/:code"
            component={EditProduct}
          />

          <Route />
          <Route
            path="/marketplace/product-depatu/:id"
            exact
            component={ProductDetail}
          />
          <Redirect
            from="/marketplace"
            to="/marketplace/product-depatu"
            exact
          />
        </Switch>
      </div>
    </ProductDepatuContextProvider>
  );
};

export default ProductDepatuRoute;
