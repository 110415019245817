import React, { useEffect, useState } from "react";
import ErrorHandler from "../../../../../components/toast/ErrorHandler";
import Input from "../../../../../components/input/Input";
import { useHistory, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosCelestine from "../../../../../config/AxiosCelestine";
import AsyncSelect from "react-select/async";
import {
  loadOptionsStore,
} from "../../../../../utils/loadOption/LoadOption";
import Toggle from "../../../../../components/button/Toggle";
import axiosMercury from "../../../../../config/AxiosMercury";

const ProductBrandOfficialStore = () => {
  const history = useHistory();
  const params: {
    id: string;
    detail: string;
  } = useParams();
  const [shop, setshop] = useState<any>(null);

  const { values, setValues, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      retail_shop_id: { label: "", value: "" },
      special_category_id: params.detail,
      type: "retail_shop",
      published: true,
    },
    validationSchema: Yup.object({
      retail_shop_id: Yup.object().shape({
        value: Yup.string().required(
          "Detail categories brand / sub-brand is required!"
        ),
      }),
    }),
    onSubmit: (values) => {
      const retail_product = new FormData();
      retail_product.append("special_category_id", values.special_category_id);
      retail_product.append("retail_shop_id", values.retail_shop_id.value);
      retail_product.append("published", values.published.toString());
      retail_product.append("type", values.type);

      axiosCelestine()
        .post("admin/special-category-list", retail_product)
        .then(({ data }) => {
          ErrorHandler("#00C62E", "Add Completed");
          history.goBack();
        })
        .catch((err) => ErrorHandler("#DF5354", err.response.data.errors));
    },
  });

  const getProductBrandImg = () => {
    const id = values?.retail_shop_id?.value;
    axiosMercury()
      .get(`admin/retail-shop/${id}`)
      .then(({ data }) => {
        setshop(data);
      })
      .catch((err) => {
        return null;
      });
  };

  useEffect(() => {
    getProductBrandImg();
  }, [values.retail_shop_id]);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-full mt-8 md:flex justify-between"
    >
      {/* form image */}
      <div className="md:w-1/3 h-full rounded-md border-2 border-stroke">
        <div className="md:h-16 h-14 flex items-center md:mx-6 mx-5">
          <h1 className="font-medium md:text-lg text-base">
            Detail Category Images
          </h1>
        </div>
        <hr />

        <div className="md:mx-6 mx-5 mt-7">
          <div className="my-6">
            <h1 className="md:text-sm text-textPrimary">Display Image</h1>
            {shop && (
              <div className="flex justify-center">
                <img
                  src={shop.logo_path}
                  alt="no images uploaded"
                  className="w-44 mt-7 font-rom text-sm"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* form information */}
      <div className="md:w-custom h-full md:mt-0 mt-8">
        <div className="rounded-md rounded-b-none border-2 border-stroke">
          <div className="h-16 flex items-center justify-between md:mx-6 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Detail Category Information
            </h1>

            <div className="flex">
              <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                Posted
              </h1>
              <div className="flex justify-between items-center">
                <Toggle
                  input={values.published}
                  handleClick={() =>
                    setValues({
                      ...values,
                      published: !values.published,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <hr />

          <div className="md:flex w-full mb-8">
            <div className="md:mx-6 mx-5 mt-7 md:w-full">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Detail Categories Brand / Sub-Brand
                </h1>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptionsStore}
                  onChange={(e: any) =>
                    setValues({ ...values, retail_shop_id: e })
                  }
                  className="my-3 font-rom text-sm"
                />
                {touched.retail_shop_id && errors.retail_shop_id ? (
                  <p className="text-primary">{errors.retail_shop_id.value}</p>
                ) : null}
              </div>
              {/* <div className="mt-4">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Detail Categories Name (From Brand)
                </h1>
                <Input
                  type={"text"}
                  disable={true}
                  placeholder="Place Holder"
                  name="name"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
            <button type="reset" className="focus:outline-none">
              <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              <h1 className="md:text-base text-base cursor-pointer hover:text-primary">
                Save
              </h1>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProductBrandOfficialStore;
