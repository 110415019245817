import { ActionType, NavbarAction } from "./action";
import { IinitialNavbarState } from "./interfaces";

export const NavbarReducer = (
  state: IinitialNavbarState,
  action: NavbarAction
) => {
  switch (action.type) {
    case ActionType.ShowSidebar:
      document.body.classList[action.payload ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSidebar: action.payload };
    case ActionType.ShowTransactionList:
      if (state.dropdownTransaction) {
        return { ...state, dropdownTransaction: false };
      } else {
        return { ...state, dropdownTransaction: true };
      }
    case ActionType.ShowProductList:
      if (state.dropdownProduct) {
        return { ...state, dropdownProduct: false };
      } else {
        return { ...state, dropdownProduct: true };
      }
    case ActionType.ShowInvoiceList:
      if (state.dropdownInvoice) {
        return { ...state, dropdownInvoice: false };
      } else {
        return { ...state, dropdownInvoice: true };
      }
  }
};
