import React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { TransactionRequest } from "../../../apis/transactions/Transaction";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../components/navbar/NavDetail";
import { optRoutesDetail } from "../helpers/list";
import TrackingInfo from "./transaction_detail_component/tracking_info/TrackingInfo";
import Loading from "../../../components/loading/Loading";

const TransactionDetailTracking = () => {
  const params: { code: string } = useParams();
  const history = useHistory();
  const { hash } = useLocation();

  const { data, isLoading } = useQuery(
    ["getBnibTransaction"],
    () => TransactionRequest.getBnibTransaction(params.code),
    {
      refetchOnWindowFocus: false,
    }
  );
  
  return (
    <>
      <NavDetail
        title1={"Transaction"}
        title2={"Details"}
        handleBack={() => history.push("/marketplace/transaction")}
      />
      <div className="pt-28">
        <DynamicBreadcrumb data={optRoutesDetail(params.code)} />

        <div className="mt-16 mb-5 w-full grid grid-cols-4 gap-10">
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#general",
              }}
              className="self-center cursor-pointer"
            >
              General Info
            </Link>
          </div>
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#buyer",
              }}
              className="self-center cursor-pointer"
            >
              Buyer Info
            </Link>
          </div>
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#seller",
              }}
              className="self-center cursor-pointer"
            >
              Seller Info
            </Link>
          </div>
          <div className="flex flex-col">
            <Link
              to={{
                hash: "#tracking",
              }}
              className="self-center cursor-pointer"
            >
              Tracking Info
            </Link>
          </div>
        </div>
        <div>
          <hr
            className={
              "border-t-4 border-solid border-primary w-1/4 transform-gpu transition duration-300 " +
              (hash === "#buyer"
                ? "translate-x-full"
                : hash === "#seller"
                ? "translate-x-200%"
                : hash === "#tracking"
                ? "translate-x-300%"
                : "")
            }
          />
          <hr className="border-t-2 -mt-0.5 border-solid" />
        </div>
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>{hash === "#tracking" && <TrackingInfo data={data} />}</div>
        )}
      </div>
    </>
  );
};

export default TransactionDetailTracking;
