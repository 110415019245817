import { useQuery } from "react-query";
import { FeaturedProductRequest } from "../../../apis/featured_product/FeaturedProductRequest";

const GetFeaturedProductId = (id: string) => {
  const { data, isLoading, isFetching } = useQuery(
    ["GetFeaturedProductId"],
    () => FeaturedProductRequest.getFeaturedProductById(id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetFeaturedProductId;
