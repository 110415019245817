import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { cancel } from "../../../assets/icons/index";
import SmallButton from "../../../components/button/SmallButton";
import AsyncSelect from "react-select/async";
import ApplyChip from "../../../utils/applyChip/ApplyChip";
import { useHistory, useLocation } from "react-router-dom";
import { queryparams } from "../helpers/list";
import SetQueryString from "../../../utils/query_searchParams/SetQueryString";
import OnSellingContext from "../../../store/on_selling/OnSellingContext";
import {
  loadOptionsBrand,
  loadOptionsCategory,
  loadOptionsOnSellingSeller,
  loadOptionsProduct,
} from "../../../utils/loadOption/LoadOption";
import MultiSelect from "react-multi-select-component";
import { useQueries } from "../../../utils/query_searchParams/QuerySearchParams";
import { OnSellingRequest } from "../../../apis/on_selling/OnSellingRequest";
import CircularLoad from "../../../components/loading/CircularLoad";
import Input from "../../../components/input/Input";

const OnSellingFilter = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = useQueries(useLocation);
  const queryClient = useQueryClient();
  const context = useContext(OnSellingContext);
  const { onSellingDispatch } = context;
  let filter: any = query.get("filter");
  const [removeUsernameList, setRemoveUsernameList] = useState<any[]>([]);

  const { data, isLoading } = useQuery("getSellerUsername", () =>
    OnSellingRequest.getSellerUsername()
  );

  useEffect(() => {
    if (
      filter === "false" &&
      (query.get("seller_id") !== undefined ||
        query.get("pre_order") !== undefined ||
        query.get("product") !== undefined ||
        query.get("exclude_seller_id") !== undefined)
    ) {
      let new_search = search.replace(filter, "true");
      history.push({ pathname, search: new_search });
    }
  }, [
    query.get("seller_id"),
    query.get("pre_order"),
    query.get("product_id"),
    query.get("exclude_seller_id"),
  ]);

  const handleShowFilter = () => {
    let new_search = search.replace(filter, "false");
    history.push({ pathname, search: new_search });
    onSellingDispatch({ type: "show_filter" });
  };

  const applyFilter = () => {
    queryClient.invalidateQueries("getOnSelling");
    ApplyChip(queryparams, onSellingDispatch);
    let new_search = search.replace(filter, "false");
    history.push({ pathname, search: new_search });
    setTimeout(() => onSellingDispatch({ type: "show_filter" }), 500);
  };

  const handleChangeProduct = (product: any) => {
    SetQueryString(
      [{ real_name: "product_id", show_name: "product_id" }],
      product.value,
      "product_id",
      search,
      pathname,
      history
    );
  };

  const handleChangeUsername = (user: any) => {
    SetQueryString(
      [{ real_name: "seller_id", show_name: "username" }],
      user.values,
      "username",
      search,
      pathname,
      history
    );
  };

  const handleChangeRemoveUsername = (user: any) => {
    const arr: any[] = [];
    user.map((el: any) => {
      return arr.push(el.value);
    });
    SetQueryString(
      [{ real_name: "exclude_seller_id", show_name: "remove_username" }],
      arr.toString(),
      "remove_username",
      search,
      pathname,
      history
    );
    setRemoveUsernameList(user);
  };

  const handleChangeFilter = (event: any, default_name?: string) => {
    let name;
    let value;
    if (!event?.label) {
      name = event.target.name;
      value = event.target.value;
    } else {
      value = event.value;
      name = default_name;
    }
    SetQueryString(
      queryparams,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-auto h-3/4 shadow-xl transform transition-all w-1/2"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/6">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full" />
        <div className="h-4/6 overflow-y-auto">
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Product Name
              </h1>
              <Input
                type="text"
                name="product"
                handleChange={handleChangeFilter}
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Product Code (SKU)
              </h1>
              <AsyncSelect
                name="product_id"
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsProduct}
                onChange={handleChangeProduct}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Category
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptionsCategory}
                onChange={(e) => handleChangeFilter(e, "category")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="w-full md:my-0 my-3">
              <h1 className="md:text-base text-sm text-textPrimary">
                Brand
              </h1>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isSearchable={true}
                loadOptions={loadOptionsBrand}
                onChange={(e) => handleChangeFilter(e, "brand")}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>

          <hr className="w-ful " />
          <div className="flex w-full px-10 items-center md:h-1/3">
            <div className="mr-8 w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">Status</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="false">Brand new</option>
                <option value="true">Pre Order</option>
              </select>
            </div>
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-textPrimary">
                Username
              </h1>
              <AsyncSelect
                name="username"
                cacheOptions
                defaultOptions
                loadOptions={loadOptionsOnSellingSeller}
                onChange={handleChangeUsername}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex px-10 items-center md:h-1/3">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-textPrimary">
                Remove Username
              </h1>
              {isLoading ? (
                <div className="mx-3 my-4">
                  <CircularLoad color="#DF5354" height={24} width={24} />
                </div>
              ) : (
                <MultiSelect
                  isLoading={isLoading ? true : false}
                  debounceDuration={0}
                  className="my-3 font-rom text-sm"
                  options={data}
                  value={removeUsernameList}
                  onChange={(user: any) => handleChangeRemoveUsername(user)}
                  labelledBy="Select"
                />
              )}
            </div>
          </div>
        </div>
        <hr className="w-full " />

        <div className="flex justify-center items-center h-1/6">
          <SmallButton
            type={"button"}
            color={"bg-primary"}
            height={10}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default OnSellingFilter;
