import ShippingAddressRepository from "../../core/shipping_address/repository/repository";
import ShippingAddressUsecase from "../../core/shipping_address/usecase/usecase";

export class ShippingAddressService {
 
  static getShippingAddress = async (id: number) => {
    try {
      const shippingAddressRepo = new ShippingAddressRepository();
      const shippingAddressUsecase = new ShippingAddressUsecase(shippingAddressRepo);
      const ShippingAddress = await shippingAddressUsecase.getShippingAddressById(id);
      return ShippingAddress;
    } catch (error) {
      console.log(error);
    }
  };
  static getShippingAddressByUserId = async (user_id: number) => {
    try {
      const shippingAddressRepo = new ShippingAddressRepository();
      const shippingAddressUsecase = new ShippingAddressUsecase(shippingAddressRepo);
      const ShippingAddress = await shippingAddressUsecase.getShippingAddressByUserId(user_id);
      return ShippingAddress;
    } catch (error) {
      console.log(error);
    }
  };
}
