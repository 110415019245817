import ErrorHandler from "../../components/toast/ErrorHandler";
import { AxiosInstance } from "../../config/AxiosInstance";
import { AcceptedRole } from "../../layout/marketplace/interfaces";
import { queryParamsWaitingInvoice } from "../../pages/invoice/helpers/list";
import { ActionType } from "../../store/invoice/action";
import ApplyChipTS from "../../utils/applyChip/ApplyChipTS";
import { validateRole } from "../../utils/checkRole/CheckRole";

export class InvoiceRequest {
  static getInvoiceWaiting = async (role: string, dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Celestine().get(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Cashier
        }/invoice/waiting`,
        {
          params: {
            ...(query.get("code") !== null && {
              code: query.get("code"),
            }),
            ...(query.get("username") !== null && {
              username: query.get("username"),
            }),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChipTS(queryParamsWaitingInvoice, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getInvoiceWaitingById = async (id: number, role: string | null) => {
    try {
      const results = await AxiosInstance.Celestine().get(
        `${
          role === AcceptedRole.Cashier ? "cashier" : "admin"
        }/invoice/show/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getInvoiceHistory = async (role: string, dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await AxiosInstance.Celestine().get(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Cashier
        }/invoice`,
        {
          params: {
            status: "success",
            ...(query.get("code") !== null && {
              code: query.get("code"),
            }),
            ...(query.get("username") !== null && {
              username: query.get("username"),
            }),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChipTS(queryParamsWaitingInvoice, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };

  static getInvoiceHistoryById = async (id: number, role: string) => {
    try {
      const results = await AxiosInstance.Celestine().get(
        `${
          validateRole(role, AcceptedRole.Admin)
            ? AcceptedRole.Admin
            : AcceptedRole.Cashier
        }/invoice/${id}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ErrorHandler("#DF5354", error.response.data.errors);
    }
  };
}
