import { createContext } from "react";
import { ITransactionContext, ITransactionState } from "./state";

export const InitialTransactionState: ITransactionState = {
  showFilter: false,
  chip: [],
  changeTrackingId: 0,
  showChangeTracking: false,
  showConfirmLocation: {
    location: "",
    id: 0,
    status: false,
  },
  openTrackDialog: {
    status: false,
    code: "",
  }
};

const TransactionContext = createContext<ITransactionContext>({
  TransactionState: InitialTransactionState,
  TransactionDispatch: () => undefined,
});

export const TransactionContextProvider = TransactionContext.Provider;
export const TransactionContextConsumer = TransactionContext.Consumer;

export default TransactionContext;
