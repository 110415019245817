import moment from "moment";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import { formatRupiah } from "../../../utils/format_rupiah/FormatRupiah";
import { optRoutesProductDetail } from "./helpers/list";
import GetProductDetail from "./helpers/use_query_hooks/GetProductDetail";
import Slider from "@material-ui/core/Slider";

const ProductDetail: React.FC = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const { data, isLoading } = GetProductDetail();
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
  };
  return (
    <>
      <nav className="fixed top-0 z-30">
        <NavDetail
          title1="Product"
          title2="Details"
          handleBack={() => history.goBack()}
        />
      </nav>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <Loading />
        </div>
      ) : (
        <main className="mb-10">
          <DynamicBreadcrumb data={optRoutesProductDetail(params.id)} />
          <section className="mt-14 border-b">
            <div className="grid grid-cols-4 gap-y-5 gap-x-10">
              <div className="row-span-3 border rounded-md flex flex-col items-center justify-center">
                <img
                  src={
                    data.carousel_image_url.length > 0
                      ? data.carousel_image_url.split(",")[value]
                      : data.detail_image_url.length > 0
                      ? data.detail_image_url.split(",")[value]
                      : data.display_image_url
                  }
                  alt={data.slug}
                  className="w-3/4"
                  loading="lazy"
                />
                {data.carousel_image_url.length > 0 && (
                  <div className="w-full px-8">
                    <Slider
                      aria-labelledby="continuous-slider"
                      color="secondary"
                      min={0}
                      max={data.carousel_image_url.split(",").length - 1}
                      step={1}
                      value={value}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
              <div className="col-span-3">
                <h1 className="text-2xl">{data.name || "-"}</h1>
                <div className="flex   mt-1">
                  <h1 className="mr-1">Status : </h1>
                  <h1
                    className={
                      data.is_active ? "text-success" : "text-primary"
                    }
                  >
                    {data.is_active ? "Posted" : "Not Posted" || "-"}
                  </h1>
                </div>
              </div>
              <div className="border rounded-md flex flex-col justify-center px-5 py-3">
                <h1 className="text-textPrimary font-rom text-sm">Sold Items</h1>
                <h1 className=" ">{data.sold_count || "-"}</h1>
              </div>
              <div className="border rounded-md flex flex-col justify-center px-5 py-3">
                <h1 className="text-textPrimary font-rom text-sm">
                  Product View
                </h1>
                <h1 className=" ">{data.view_count || "-"}</h1>
              </div>
              <div className="border rounded-md flex flex-col justify-center px-5 py-3">
                <h1 className="text-textPrimary font-rom text-sm">
                  Brand New Highest Bid Price
                </h1>
                <h1 className=" ">
                  {formatRupiah(data.bnib_highest_bid_price) || "-"}
                </h1>
              </div>
              <div className="border rounded-md flex flex-col justify-center px-5 py-3">
                <h1 className="text-textPrimary font-rom text-sm">
                  Brand New Lowest Sell Price
                </h1>
                <h1 className=" ">
                  {formatRupiah(data.bnib_lowest_sell_price) || "-"}
                </h1>
              </div>
              <div className="border rounded-md flex flex-col justify-center px-5 py-3">
                <h1 className="text-textPrimary font-rom text-sm">
                  Pre Order Highest Bid Price
                </h1>
                <h1 className=" ">
                  {formatRupiah(data.pre_order_highest_bid_price) || "-"}
                </h1>
              </div>{" "}
              <div className="border rounded-md flex flex-col justify-center px-5 py-3">
                <h1 className="text-textPrimary font-rom text-sm">
                  Pre Order Lowest Sell Price
                </h1>
                <h1 className=" ">
                  {formatRupiah(data.pre_order_lowest_sell_price) || "-"}
                </h1>
              </div>
            </div>
            {data.detail_image_url.length > 0 ? (
              <div className="grid grid-cols-11 gap-5 w-full my-10">
                {data.detail_image_url
                  .split(",")
                  .map((el: string, index: number) => (
                    <div
                      onClick={() => setValue(index)}
                      className={
                        "flex justify-center border rounded-md p-3 " +
                        (index === value ? "border-primary" : "")
                      }
                    >
                      <img src={el} alt={"img"} loading="lazy" />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="my-10"></div>
            )}
          </section>
          <section className="mt-7">
            <div className="grid grid-rows-10 gap-4">
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Product Code</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  : {data.code || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">
                  Product Code (Secondary)
                </span>
                <span className="w-1/2 font-rom text-textPrimary">
                  : {data.secondary_codes.map((el: any) => el).join(",") || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Brand</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  : {data.product_brand.name || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Brand (Secondary)</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  :{" "}
                  {data.secondary_product_brands
                    .map((el: any) => el.name)
                    .join(", ") || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Category</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  : {data.product_category.name || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">
                  Category (Secondary)
                </span>
                <span className="w-1/2 font-rom text-textPrimary">
                  :{" "}
                  {data.secondary_product_categories
                    .map((el: any) => el.name)
                    .join(", ") || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Release Date</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  :{" "}
                  {data.release_date !== null
                    ? `${moment(data.release_date).format("LL")}`
                    : `${data.season.name} ${data.season_year}`}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Retail Price</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  :{" "}
                  {`${data.retail_price_currency} ${data.retail_price}` || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Color</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  : {data.color || "-"}
                </span>
              </div>
              <div className="flex">
                <span className="w-1/2 text-textPrimary">Despcription</span>
                <span className="w-1/2 font-rom text-textPrimary">
                  : {data.story || "-"}
                </span>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default ProductDetail;
