import axios from "axios";

const axiosCelestineUser = () => {
  let value = localStorage.getItem("AUTH_TOKEN");
  return axios.create({
    baseURL: process.env.REACT_APP_CELESTINE_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${value}`,
      version: '23.6'
    },
  });
};

export default axiosCelestineUser;
