import isEmpty from "lodash.isempty";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { bgDepatu } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import CircularLoad from "../../../../../components/loading/CircularLoad";
import { ActionType } from "../../../../../store/campaign/action";
import CampaignContext from "../../../../../store/campaign/CampaignContext";
import { ScopeType } from "../../../helpers/list";
import GetCampaignTemplateId from "../../../helpers/use_query_hook/GetCampaignTemplateId";
import GetResultWinners from "../../../helpers/use_query_hook/GetResultWinners";
import ConfirmWinnerModal from "./ConfirmWinnerModal";
import GetUsedTicket from "../../../helpers/use_query_hook/GetUsedTicket";
import CampaignSideCard from "./CampaignSideCard";

const ResultsPage = () => {
  const history = useHistory();
  const params: { id: string; templateId: string } = useParams();
  const { CampaignState, CampaignDispatch } = useContext(CampaignContext);
  const { confirm_winner } = CampaignState;
  const { isLoading, data } = GetCampaignTemplateId(params.templateId);
  const { data: dataUsedTicket, isLoading: loadUsedTicket } = GetUsedTicket();
  const { isLoading: loadWinner, data: dataWinner } = GetResultWinners();
  const [id, setId] = useState<number>(0);
  const [code, setCode] = useState<string>("");

  const setInvoiceCode = (id: number, code: string) => {
    setId(id);
    setCode(code);
  };

  const removeInvoice = () => {
    setId(0);
    setCode("");
  };

  return (
    <div className="absolute overflow-hidden top-0 left-0 z-30 bg-black w-screen h-screen flex">
      {confirm_winner.status && (
        <ConfirmWinnerModal setInvoiceCode={setInvoiceCode} />
      )}

      {isLoading || loadWinner || loadUsedTicket ? (
        <div className="flex justify-center mt-20 w-full">
          <CircularLoad color={"#ed4846"} height={40} width={40} />
        </div>
      ) : (
        <>
          <CampaignSideCard
            onClick={() => removeInvoice()}
            data={data}
            dataUsedTicket={dataUsedTicket}
            title={
              data.scope === ScopeType.BASIC
                ? "RETAIL DROP"
                : data.scope === ScopeType.GIVEAWAYRAFFLE ||
                  data.scope === ScopeType.SPECIALPRICERAFFLE
                ? "GIVEAWAY"
                : "SPECIAL PRICE"
            }
          />
          <div className="w-2/3 flex justify-center items-center text-white py-10">
            <section className="absolute flex justify-start items-end bottom-0 w-2/3 h-full top-20">
              <img src={bgDepatu} alt="-" />
            </section>
            {!isEmpty(dataWinner?.data) && (
              <div className="flex flex-col items-center justify-center z-40 w-10/12">
                <h1 className="text-white font-med text-4xl mt-8">
                  CONGRATULATIONS!
                </h1>
                <div
                  className={
                    `grid content-center justify-items-center ` +
                    (dataWinner?.data?.length <= 2
                      ? `grid-cols-${dataWinner?.data?.length} w-full`
                      : "grid-cols-3 w-full gap-x-8")
                  }
                >
                  {dataWinner?.data?.map((el: any) => (
                    <div
                      id={`box-${el.id}`}
                      className={
                        "square mt-10 border h-28 flex flex-col justify-center items-center " +
                        (!isEmpty(el.agent_name)
                          ? "border-success cursor-not-allowed "
                          : el.id === id
                          ? "border-yellow-400 "
                          : "border-white ") +
                        (dataWinner?.data?.length >= 3
                          ? "w-full cursor-pointer "
                          : dataWinner?.data?.length >= 2
                          ? "w-3/4 cursor-pointer "
                          : dataWinner?.data?.length >= 1
                          ? "w-1/3 cursor-pointer  "
                          : "w-1/3 cursor-pointer ")
                      }
                      onClick={
                        isEmpty(el.agent_name) &&
                        el.delivery_option !== "shipping"
                          ? () => setInvoiceCode(el.id, el.invoice_code)
                          : () => {}
                      }
                    >
                      <h1 className="text-white h-2/3 pb-1 w-full flex items-end justify-center">
                        {el.winner_username}
                      </h1>
                      <div className="w-full flex h-1/3 items-end justify-end px-3 pb-1">
                        <h1 className="text-succes font-rom text-sm">
                          {el.delivery_option}
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex w-2/5 justify-center mt-14">
                  <SmallButton
                    borderColor="border-white"
                    border={"border-2"}
                    color={"bg-black"}
                    text={"Back"}
                    height={14}
                    width={"1/2"}
                    type={"button"}
                    handleClick={() =>
                      history.push(
                        `/marketplace/campaign/edit-campaign/${params.id}`
                      )
                    }
                  />
                  <div className="ml-5 w-1/2">
                    <SmallButton
                      borderColor="border-white"
                      border={"border-2"}
                      color={"bg-black"}
                      text={"Confirm"}
                      textColor={id ? "text-white" : "text-textPrimary"}
                      height={14}
                      width={"full"}
                      type={"button"}
                      handleClick={
                        id > 0
                          ? () =>
                              CampaignDispatch({
                                type: ActionType.ConfirmWinner,
                                payload: { status: true, id: code },
                              })
                          : () => {}
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ResultsPage;
