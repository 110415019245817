import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ProductScrapingRequest } from "../../../../../apis/product/ProductScrapingRequest";

const GetProductScrapedDetail = () => {
  const params: { id: string } = useParams();
  const { data, isLoading } = useQuery("getProductScrapedDetail", () =>
    ProductScrapingRequest.getProductScrapedDetail(params.id)
  );
  return {
    data,
    isLoading,
  };
};

export default GetProductScrapedDetail;
