import ErrorHandler from "../../components/toast/ErrorHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import { queryparamsBanner } from "../../pages/banner/helpers/list";
import ApplyChip from "../../utils/applyChip/ApplyChip";
import qs from "qs";

export class BannerRequest {
  static getBanner = async (bannerDispatch: any) => {
    try {
      const location = document.location.search;
      const hash = document.location.hash;
      const query = new URLSearchParams(location);
      const result = await axiosCelestine().get("admin/banner", {
        params: {
          ...(query.get("status") !== null && {
            is_active: query.get("status"),
          }),

          ...(hash === "#marketplace"
            ? {
                scope: 8,
              }
            : hash === "#multibrand"
            ? {
                scope: 9,
              }
            : query.get("scope") !== null
            ? {
                scope: query.get("scope"),
              }
            : {
                scope: [1, 2, 3, 4, 5, 6, 7],
              }),
          // ...(query.get("action") !== null && {
          //   action: query.get("action"),
          // }),
          ...(query.get("expired_at_from") !== null &&
          query.get("expired_at_to") !== null
            ? {
                expired_at: `${query.get("expired_at_from")},${query.get(
                  "expired_at_to"
                )}`,
              }
            : ""),
          ...(query.get("expired_at_from") !== null &&
          query.get("expired_at_to") === null
            ? { expired_at: `${query.get("expired_at_from")},2100-01-01` }
            : ""),
          ...(query.get("expired_at_from") === null &&
          query.get("expired_at_to") !== null
            ? { expired_at: `2000-01-01,${query.get("expired_at_to")}` }
            : ""),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      });
      const res = result.data;
      ApplyChip(queryparamsBanner, bannerDispatch);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  static getBannerById = async (id: string) => {
    try {
      const result = await axiosCelestine().get(`admin/banner/${id}`);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static postBanner = async (
    values: any,
    goBack: () => void,
    setSubmitting: any
  ) => {
    try {
      const result = await axiosCelestine().post(`admin/banner`, values);
      ErrorHandler("#00C62E", "Add Completed");
      setSubmitting(false);
      goBack();
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static deleteBanner = async (id: number, goBack: () => void) => {
    try {
      await axiosCelestine().delete(`admin/banner/${id}`);
      ErrorHandler("#00C62E", "Delete Completed");
      goBack();
    } catch (error) {
      ErrorHandler("#ed4846", "Delete Failed");
    }
  };

  static editBanner = async (
    id: number,
    values: any,
    goBack: () => void,
    setSubmitting: any
  ) => {
    try {
      await axiosCelestine().put(`admin/banner/${id}`, values);
      setSubmitting(false);
      ErrorHandler("#00C62E", "Edit Completed");
      goBack();
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Edit Failed");
      }
    }
  };
}
