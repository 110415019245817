import { createContext } from "react";
import { IInvoiceContext, IInvoiceState } from "./state";

export const initialInvoiceState: IInvoiceState = {
  showFilter: false,
  showDelete: {
    status: false,
    id: 0,
  },
  chip: [],
  showTopup: {
    status: false,
    id: 0,
  },
};

const InvoiceContext = createContext<IInvoiceContext>({
  InvoiceState: initialInvoiceState,
  InvoiceDispatch: () => undefined,
});

export const InvoiceContextProvider = InvoiceContext.Provider;
export const InvoiceContextConsumer = InvoiceContext.Consumer;

export default InvoiceContext;
