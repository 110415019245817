export enum ActionType {
  ShowFilter,
  ShowAddCampaign,
  ShowAddQuantityShuffle,
  ShowAddTermsCondition,
  ShowModalSecurityCode,
  ShowModalListProduct,
  ShowDownloadQrCode,
  ShowProductPerSize,
  ShowDeleteCampaign,
  HandleChip,
  HandleOffset,
  HandleLimit,
  AddDataSpinner,
  DropWinner,
  ConfirmWinner,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface DropWinner {
  type: ActionType.DropWinner;
  payload: {
    status: boolean;
    id: number;
  };
}

export interface ConfirmWinner {
  type: ActionType.ConfirmWinner;
  payload: {
    status: boolean;
    id: string;
  };
}

export interface ShowAddTermsCondition {
  type: ActionType.ShowAddTermsCondition;
}

export interface AddDataSpinner {
  type: ActionType.AddDataSpinner;
  payload: any;
}

export interface ShowAddQuantityShuffle {
  type: ActionType.ShowAddQuantityShuffle;
  payload: {
    status: boolean;
    id: number;
  };
}

export interface ShowDownloadQrCode {
  type: ActionType.ShowDownloadQrCode;
}

export interface ShowAddCampaign {
  type: ActionType.ShowAddCampaign;
}

export interface ShowModalSecurityCode {
  type: ActionType.ShowModalSecurityCode;
  payload: any;
}

export interface ShowModalListProduct {
  type: ActionType.ShowModalListProduct;
  payload: {
    status: boolean;
    payload: {
      product_size_id: number;
      campaign_product_template_id: number;
    };
  };
}

export interface ShowProductPerSize {
  type: ActionType.ShowProductPerSize;
  payload: any;
}

export interface ShowDeleteCampaign {
  type: ActionType.ShowDeleteCampaign;
  payload: any;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface HandleOffset {
  type: ActionType.HandleOffset;
  payload: any;
}
export interface HandleLimit {
  type: ActionType.HandleLimit;
  payload: any;
}

export type CampaignAction =
  | ShowFilter
  | HandleChip
  | HandleOffset
  | HandleLimit
  | ShowAddCampaign
  | ShowModalSecurityCode
  | ShowModalListProduct
  | ShowProductPerSize
  | ShowDeleteCampaign
  | ShowAddTermsCondition
  | ShowAddQuantityShuffle
  | AddDataSpinner
  | DropWinner
  | ShowDownloadQrCode
  | ConfirmWinner;
