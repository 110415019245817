const location = document.location.search;
const queryParams = new URLSearchParams(location);

export let default_sort = queryParams.get("sort");
if (default_sort === "expired_at") {
  default_sort = "Expired at";
} else if (default_sort === "is_active") {
  default_sort = "Status";
} else if (default_sort === "scope") {
  default_sort = "Banner Post in";
} else if (default_sort === "created_at") {
  default_sort = "Date";
} else {
  default_sort = "Status";
}
