interface Point {
    x: number;
    y: number;
    dx: number;
    dy: number;
    d: number;
  }
  
  const random_range = (a: number, b: number) => {
    const plus = Math.floor(Math.random() * (b - a + 1));
    return a + plus;
  };
  
  const random_range_float = (a: number, b: number) => {
    const plus = Math.random() * (b - a);
    return a + plus;
  };
  
  const dist = (a: any, b: any): number => {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  };
  
  export default function sketch(p: any) {
    let points: Point[] = [];
  
    const NUM_OF_POINTS = 120;
    const MIN_LINE_DIST_FOR_LINE = 100;
    const MIN_LINE_DIST_FOR_VISIBILITY = 200;
  
    function init() {
      points = [];
      for (let i = 0; i < NUM_OF_POINTS; i++) {
        const x = random_range_float(0, p.windowWidth);
        const y = random_range_float(0, p.windowHeight);
        const dx = random_range_float(-1, 1);
        const dy = random_range_float(-1, 1);
        const d = random_range(3, 7);
        const point: Point = {
          x,
          y,
          dx,
          dy,
          d,
        };
        points.push(point);
      }
    }
  
    p.setup = function () {
      p.createCanvas(p.windowWidth, p.windowHeight);
      p.smooth();
      init();
    };
  
    p.myCustomRedrawAccordingToNewPropsHandler = function (props: any) {};
  
    p.draw = function () {
      p.clear();
      p.background(0, 0, 0);
  
      p.push();
      for (let i = 0; i < points.length; i++) {
        if (
          points[i].x + points[i].dx <= 0 ||
          points[i].x + points[i].dx >= p.windowWidth
        ) {
          points[i].dx = -points[i].dx;
        }
        if (
          points[i].y + points[i].dy <= 0 ||
          points[i].y + points[i].dy >= p.windowHeight
        ) {
          points[i].dy = -points[i].dy;
        }
        points[i].x += points[i].dx;
        points[i].y += points[i].dy;
  
        // start draw
        p.fill(255);
        p.ellipse(points[i].x, points[i].y, points[i].d, points[i].d);
        const calcedDist = dist(points[i], { x: p.mouseX, y: p.mouseY });
        if (calcedDist > MIN_LINE_DIST_FOR_VISIBILITY) {
          continue;
        }
        // draw line between balls
        for (let j = i + 1; j < points.length; j++) {
          const calcedDist = dist(points[i], points[j]);
          if (calcedDist <= MIN_LINE_DIST_FOR_LINE) {
            p.stroke(`rgba(255, 20, 147, ${1})`);
            p.strokeWeight(1);
            p.line(points[i].x, points[i].y, points[j].x, points[j].y);
          }
        }
      }
      p.pop();
    };
  
    p.windowResized = function () {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
      init();
    };
  }
  