import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InvoiceHistory from "../../../pages/invoice/history/InvoiceHistory";
import InvoiceHistoryDetail from "../../../pages/invoice/history/InvoiceHistoryDetail";
import WaitingInvoice from "../../../pages/invoice/waiting_invoice/WaitingInvoice";
import WaitingInvoiceDetail from "../../../pages/invoice/waiting_invoice/WaitingInvoiceDetail";
import {
  initialInvoiceState,
  InvoiceContextProvider,
} from "../../../store/invoice/InvoiceContext";
import { InvoiceReducer } from "../../../store/invoice/InvoiceReducer";

const InvoiceRoute = () => {
  const [InvoiceState, InvoiceDispatch] = useReducer(
    InvoiceReducer,
    initialInvoiceState
  );
  return (
    <InvoiceContextProvider value={{ InvoiceState, InvoiceDispatch }}>
      <div className="font-med md:pt-28 sm:pt-20 pt-16">
        <Switch>
          <Route
            path="/marketplace/invoice/waiting-invoice"
            exact
            component={WaitingInvoice}
          />
          <Route
            path="/marketplace/invoice/invoice-history"
            component={InvoiceHistory}
            exact
          />
          <Route
            path="/marketplace/invoice/waiting-invoice/:id/:userId"
            exact
            component={WaitingInvoiceDetail}
          />
          <Route
            path="/marketplace/invoice/invoice-history/:id/:userId"
            exact
            component={InvoiceHistoryDetail}
          />
          <Redirect
            from="/marketplace"
            to="/marketplace/waiting-invoice"
            exact
          />
        </Switch>
      </div>
    </InvoiceContextProvider>
  );
};

export default InvoiceRoute;
