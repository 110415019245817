export const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/tags",
    name: "Custom Tags",
  },
];

export const optRoutesEdit = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/tags",
      name: "Custom Tags",
    },
    {
      id: 3,
      path: `/marketplace/tags/${id}`,
      name: "Edit Tag",
    },
  ];
};

export const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "name",
    name: "Name",
  },
];
