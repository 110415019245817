import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "../../../components/pagination/Pagination";
import TopupContext from "../../../store/topup/TopupContext";
import { formatRupiah } from "../../../utils/format_rupiah/FormatRupiah";
export interface ITopUpTableProps {
  data: any;
}
const TopUpTable: React.FC<ITopUpTableProps> = ({ data }) => {
  const history = useHistory();
  const { topupDispatch } = useContext(TopupContext);

  const goDetail = (id: number, user_id: number) => {
    history.push(`/marketplace/topup/${id}/${user_id}`);
  };
  return (
    <div className="w-full my-10">
      <table className="w-full border relative text-center text-sm">
        <thead className="sticky z-30">
          <tr className="h-18 sticky z-30 top-20 bg-white shadow-sm">
            <th className="border-r w-1/5">Top Up Code</th>
            <th className="border-r">Username</th>
            <th className="border-r">Amount</th>
            <th className="border-r">Payment Channel</th>
            <th className="border-r">Payment Status</th>
            <th className="border-r">Top Up Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">{el.code || "-"}</td>
              <td className="border-r"> {el.user.username || "-"}</td>
              <td className="border-r">{formatRupiah(el.amount) || "-"}</td>
              <td className="border-r">
                {el.payment_channel.split("_").join(" ") || "-"}
              </td>
              <td className="border-r font-med">
                {el.payment_status === "success" ? (
                  <h1 className="text-success">{el.payment_status}</h1>
                ) : el.payment_status === "expired" ||
                  el.payment_status === "revert" ? (
                  <h1 className="text-primary">
                    <div>
                      <h1>{el?.payment_status}</h1>
                    </div>
                  </h1>
                ) : (
                  <h1 className="text-yellow-400">{el.payment_status}</h1>
                )}
              </td>
              <td className="border-r">
                {" "}
                {moment(el.created_at).format("ll HH:mm") || "-"}
              </td>
              <td className="">
                {el.payment_channel === "midtrans" ? (
                  <div className="flex justify-center font-med">
                    <h1
                      onClick={() => goDetail(el.id, el.user_id)}
                      className="hover:text-primary cursor-pointer"
                    >
                      Details
                    </h1>
                  </div>
                ) : (
                  <div className="flex justify-center font-med">
                    {el.revert_wallet_mutation_id !== null ? (
                      ""
                    ) : (
                      <h1
                        onClick={() =>
                          topupDispatch({
                            type: "show_cancel",
                            payload: {
                              status: true,
                              id: el.id,
                            },
                          })
                        }
                        className="hover:text-primary cursor-pointer mr-3"
                      >
                        Cancel
                      </h1>
                    )}
                    <h1
                      onClick={() => goDetail(el.id, el.user_id)}
                      className="hover:text-primary cursor-pointer"
                    >
                      Details
                    </h1>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        length={data?.data?.data?.length}
        total={data?.data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};
export default TopUpTable;
