import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  EBnibTransactionSortField,
  IBnibTransaction,
  IBnibTransactionCounter,
  // IBnibTransactionCounter,
  PBnibTransactionFilter,
} from "../entities";
import IBnibTransactionRepository from "../repository";
import IBnibTransactionUsecase from "../usecase";

export default class BnibTransactionUsecase implements IBnibTransactionUsecase {
  private BnibTransactionRepo: IBnibTransactionRepository;

  constructor(BnibTransactionRepo: IBnibTransactionRepository) {
    this.BnibTransactionRepo = BnibTransactionRepo;
  }

  async getBnibTransactions(
    pagination: IPagination,
    filter: PBnibTransactionFilter,
    sorts: IBuiltinSort<EBnibTransactionSortField>[]
  ): Promise<[IBnibTransaction[], IMeta]> {
    const [data, meta] = await this.BnibTransactionRepo.getBnibTransactions(
      pagination,
      filter,
      sorts
    );
    return [data, meta];
  }

  async getBnibTransaction(code: string): Promise<IBnibTransaction> {
    const BnibTransaction = await this.BnibTransactionRepo.getBnibTransaction(
      code
    );
    return BnibTransaction;
  }

  async getBnibTransactionTrack(code: string): Promise<any[]> {
    const BnibTransactionTrack =
      await this.BnibTransactionRepo.getBnibTransactionTrack(code);
    return BnibTransactionTrack;
  }

  async getBnibTransactionProcess(code: string): Promise<any[]> {
    const BnibTransactionProcess =
      await this.BnibTransactionRepo.getBnibTransactionProcess(code);
    return BnibTransactionProcess;
  }

  async getBnibTransactionCounter(
    filter: PBnibTransactionFilter
  ): Promise<IBnibTransactionCounter[]> {
    const data = await this.BnibTransactionRepo.getBnibTransactionCounter(
      filter
    );
    return data;
  }

  async arriveBnibTransaction(
    code: string,
    payload: {
      location_dest_name: string;
    }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.arriveBnibTransaction(
      code,
      payload
    );
    return data;
  }

  async disputeBnibTransaction(
    code: string,
    payload: { reject_reason: string; location_dest_name: string }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.disputeBnibTransaction(
      code,
      payload
    );
    return data;
  }

  async acceptBnibTransaction(
    code: string,
    payload: {
      location_dest_name: string;
    }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.acceptBnibTransaction(
      code,
      payload
    );
    return data;
  }

  async rejectBnibTransaction(
    code: string,
    payload: { reject_reason: string; location_dest_name: string }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.rejectBnibTransaction(
      code,
      payload
    );
    return data;
  }

  async defectBnibTransaction(
    code: string,
    payload: FormData
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.defectBnibTransaction(
      code,
      payload
    );
    return data;
  }

  async readyForLegitCheck(code: string): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.readyForLegitCheck(code);
    return data;
  }

  async confirmFinalResult(
    code: string,
    payload: { result: string }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.confirmFinalResult(
      code,
      payload
    );
    return data;
  }

  async readyShippingToBuyer(code: string): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.readyShippingToBuyer(code);
    return data;
  }

  async sendProductToBuyer(
    code: string,
    payload: { pickup_time: string }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.sendProductToBuyer(
      code,
      payload
    );
    return data;
  }

  async readyShippingToSeller(code: string): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.readyShippingToSeller(code);
    return data;
  }

  async sendProductToSeller(
    code: string,
    payload: { pickup_time: string }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.sendProductToSeller(
      code,
      payload
    );
    return data;
  }

  async receipt(code: string, payload: FormData): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.receipt(code, payload);
    return data;
  }

  async transferStock(
    code: string,
    payload: {
      location_dest_name: string;
    }
  ): Promise<{ message: string }> {
    const data = await this.BnibTransactionRepo.transferStock(code, payload);
    return data;
  }

  async printShippingLabel(code: string): Promise<{ data: string }> {
    const data = await this.BnibTransactionRepo.printShippingLabel(code);
    return data;
  }
}
