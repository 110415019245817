export const optRoutesCampaign = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/campaign",
    name: "Campaign",
  },
];

export const optRoutesCampaignInfo = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/marketplace/campaign",
    name: "Campaign",
  },
  {
    id: 3,
    path: "/marketplace/campaign/info",
    name: "Campaign Info",
  },
];

export const optRoutesEditCampaign = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/campaign",
      name: "Campaign",
    },
    {
      id: 3,
      path: `/marketplace/campaign/edit-campaign/${id}`,
      name: "Edit Campaign",
    },
  ];
};

export const optRoutesAddProductCampaign = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/campaign",
      name: "Campaign",
    },
    {
      id: 3,
      path: `/marketplace/campaign/edit-campaign/${id}`,
      name: "Edit Campaign",
    },
    {
      id: 4,
      path: `/marketplace/campaign/edit-campaign/${id}/add-campaign-product`,
      name: "Add Campaign Product",
    },
  ];
};

export const optRoutesSellproductcampaign = (id: string, productId: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/campaign",
      name: "Campaign",
    },
    {
      id: 3,
      path: `/marketplace/campaign/edit-campaign/${id}`,
      name: "Edit Campaign",
    },
    {
      id: 4,
      path: `/marketplace/campaign/edit-campaign/${id}/sell-product/${productId}`,
      name: "Sell this Product",
    },
  ];
};

export const optRoutesListproductcampaign = (id: string, productId: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/campaign",
      name: "Campaign",
    },
    {
      id: 3,
      path: `/marketplace/campaign/edit-campaign/${id}`,
      name: "Edit Campaign",
    },
    {
      id: 4,
      path: `/marketplace/campaign/edit-campaign/${id}/list-product/${productId}`,
      name: "List this Product",
    },
  ];
};

export const optRoutesEditProductCampaign = (
  id: string,
  templateId: string
) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/campaign",
      name: "Campaign",
    },
    {
      id: 3,
      path: `/marketplace/campaign/edit-campaign/${id}`,
      name: "Edit Campaign",
    },
    {
      id: 4,
      path: `/marketplace/campaign/edit-campaign/${id}/edit-campaign-product/${templateId}`,
      name: "Edit Campaign Product",
    },
  ];
};

export const optRoutesDetailCampaignProduct = (
  id: string,
  templateId: string
) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/marketplace/campaign",
      name: "Campaign",
    },
    {
      id: 3,
      path: `/marketplace/campaign/edit-campaign/${id}`,
      name: "Edit Campaign",
    },
    {
      id: 4,
      path: `/marketplace/campaign/edit-campaign/${id}/details/${templateId}`,
      name: "Details",
    },
  ];
};

export const optSortingCampaign = [
  {
    value: "created_at",
    name: "Date",
  },
];

export enum ScopeType {
  BASIC = "campaign_basic",
  GIVEAWAYRAFFLE = "campaign_giveaway_raffle",
  SPECIALPRICERAFFLE = "campaign_specialprice_raffle",
  GIVEAWAYTOPCREDIT = "campaign_giveaway_ticket",
  SPECIALPRICETOPCREDIT = "campaign_specialprice_ticket",
}
