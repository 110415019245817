import React from "react";
import NumberFormat from "react-number-format";

interface PriceInputProps {
  name: string;
  value: string | number;
  handleChange?: any;
  prefix?: string;
  className?: string;
  displayType?: "input" | "text";
  thousandSeparator?: boolean;
  placeholder?: string;
  format?: string;
  disabled?: boolean;
}

const PriceInput: React.FC<PriceInputProps> = ({
  handleChange,
  value,
  prefix,
  name,
  className,
  displayType,
  thousandSeparator,
  placeholder,
  format,
  disabled,
}) => {
  return (
    <NumberFormat
      disabled={disabled || false}
      format={format}
      placeholder={placeholder || "IDR 0"}
      name={name}
      className={
        className ||
        "md:h-10 h-8 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke focus:ring-1 focus:outline-none"
      }
      displayType={displayType || "input"}
      onChange={handleChange}
      value={value}
      thousandSeparator={thousandSeparator || true}
      prefix={prefix || "IDR "}
    />
  );
};

export default PriceInput;
