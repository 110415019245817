export const validateRole = (
  role: string | null,
  accepted_role: any[] | string
) => {
  if (Array.isArray(accepted_role)) {
    let validate;
    accepted_role.map((el) => (role === el ? (validate = true) : ""));
    if (validate) return true;
    else return false;
  } else {
    return role === accepted_role ? true : false;
  }
};
