import { ActionType, ProductReviewAction } from "./actions";
import { IProductReviewState } from "./state";

export const productReviewReducer = (
  state: IProductReviewState,
  action: ProductReviewAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowConfirmProduct:
      let showConfirmProduct = state.showConfirmProduct;
      document.body.classList[!showConfirmProduct ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return {
        ...state,
        showConfirmProduct: !showConfirmProduct,
      };
    case ActionType.ShowDelete:
      let showDelete = action.payload;
      document.body.classList[showDelete.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return {
        ...state,
        showDelete,
      };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.ShowCrawl:
      let showCrawl = state.showCrawl;
      document.body.classList[!showCrawl ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showCrawl: !showCrawl };
    case ActionType.AddImage:
      const payload = action.payload;
      return { ...state, dataImage: payload };
    case ActionType.AddDisplayImg:
      const displayImage = action.payload;
      return { ...state, displayImage };
    case ActionType.ShowAddInfo:
      let showAddInfo = state.showAddInfo;
      document.body.classList[!showAddInfo ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddInfo: !showAddInfo };
    case ActionType.AddInfo:
      const info = action.payload;
      const new_data_info = [...state.dimension].concat({
        label: info,
        value: "",
      });
      return { ...state, dimension: new_data_info };
    case ActionType.AddInfoValue:
      const value = action.payload;
      const name = action.name;
      const dimension: any = [...state.dimension];
      for (let index = 0; index < dimension.length; index++) {
        if (dimension[index]?.label === name) {
          dimension[index].value = value;
        }
      }
      return { ...state, dimension };
    case ActionType.DeleteInfo:
      const deleted_info = action.payload;
      const data_info = state.dimension.filter((el) => el !== deleted_info);
      return { ...state, dimension: data_info };
    case ActionType.HandlePictureInit:
      const pictureInit = action.payload;
      return { ...state, pictureInit };
  }
};
