import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { SendCreditRequest } from "../../../../apis/send_credit/SendCreditRequest";
import { useQueries } from "../../../../utils/query_searchParams/QuerySearchParams";

const GetListGiver = () => {
  const query = useQueries(useLocation);
  const { data, isLoading, isFetching } = useQuery(
    ["GetListGiver", query.get("limit"), query.get("offset")],
    () => SendCreditRequest.listGiver(),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default GetListGiver;
