import moment from "moment";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ProductRequest } from "../../../../../apis/product/ProductRequest";
import { ProductScrapingRequest } from "../../../../../apis/product/ProductScrapingRequest";
import { cancel } from "../../../../../assets/icons";
import SmallButton from "../../../../../components/button/SmallButton";
import { ActionType } from "../../../../../store/product/product_need_review/actions";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";

interface IProps {
  data: any;
  dataImage: any[];
  displayImage: string | null;
  dimension: any[];
  date: any;
  scrapted_product_id: number;
}

const ConfirmProduct: React.FC<IProps> = ({
  data,
  dataImage,
  dimension,
  displayImage,
  date,
  scrapted_product_id,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { productReviewDispatch, productReviewState } =
    useContext(ProductReviewContext);
  const { pictureInit } = productReviewState;
  const handleShowConfirm = () => {
    productReviewDispatch({
      type: ActionType.ShowConfirmProduct,
    });
    productReviewDispatch({
      type: ActionType.AddDisplayImg,
      payload: null,
    });
    const removeFile: any = document.getElementsByClassName(
      "filepond--file-action-button filepond--action-remove-item"
    );
    removeFile[0]?.click();
    productReviewDispatch({
      type: ActionType.AddImage,
      payload: [],
    });
  };

  const handleSubmit = () => {
    mutation.mutate();
  };

  const mutation = useMutation(
    () =>
      !data.matched_product_id
        ? ProductScrapingRequest.addProduct(
            data,
            displayImage,
            dataImage,
            dimension,
            date,
            history,
            handleShowConfirm,
            pictureInit
          )
        : ProductRequest.patchProductImage(
            displayImage,
            dataImage,
            data.matched_product_id,
            pictureInit,
            scrapted_product_id
          ),
    {
      onMutate: () => {
        console.log("mutate add product");
      },
      onSettled: async (data: any, error: any) => {
        if (data) {
          queryClient.invalidateQueries(`getProductScraped`);
          handleShowConfirm();
          history.goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-full inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white rounded-lg cursor-default overflow-hidden h-5/6 shadow-xl w-2/5 xl:1/2">
        <div className="flex justify-between items-center px-10 py-5 h-12% shadow-sm">
          <div className="text-xl xl:text-2xl flex">
            <h1>Confirm</h1>
            <h1 className="text-primary ml-1">Product</h1>
          </div>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowConfirm}
          />
        </div>
        <hr />

        <div className="h-3/4 overflow-auto">
          <div className="px-10 py-5">
            <h1 className="text-xl">{data.name}</h1>
            <div className="grid grid-cols-5 gap-3 my-3">
              {dataImage.map((el) => (
                <div className="border rounded-md p-1 shadow-md">
                  <img src={el} alt={el} />
                </div>
              ))}
            </div>
            <div className="flex my-2">
              <h1 className="mr-1.5">Status:</h1>
              <h1 className={data.is_active ? "text-success" : "text-primary"}>
                {data.is_active ? "Posted" : " Not Posted"}
              </h1>
            </div>
          </div>
          {!data.matched_product_id && (
            <div>
              <hr />
              <div className="px-10 py-5 text-textPrimary overflow-auto">
                <div className="flex">
                  <h1 className="w-1/2">Product Code</h1>
                  <h1 className="w-1/2">: {data.code}</h1>
                </div>
                {data.secondary_code.length > 0 && (
                  <div className="flex my-1">
                    <h1 className="w-1/2">Product Code (Secondary)</h1>
                    <h1 className="w-1/2">
                      : {data.secondary_code.map((el: any) => el.label + ", ")}
                    </h1>
                  </div>
                )}
                <div className="flex my-1">
                  <h1 className="w-1/2">Brand</h1>
                  <h1 className="w-1/2">: {data.brand.label}</h1>
                </div>
                {data.secondary_brand.length > 0 && (
                  <div className="flex my-1">
                    <h1 className="w-1/2">Brand (Secondary)</h1>
                    <h1 className="w-1/2">
                      : {data.secondary_brand.map((el: any) => el.label + ", ")}
                    </h1>
                  </div>
                )}
                <div className="flex my-1">
                  <h1 className="w-1/2">Category</h1>
                  <h1 className="w-1/2">: {data.category.label}</h1>
                </div>
                {data.secondary_category.length > 0 && (
                  <div className="flex my-1">
                    <h1 className="w-1/2">Category (Secondary)</h1>
                    <h1 className="w-1/2">
                      :{" "}
                      {data.secondary_category.map(
                        (el: any) => el.label + ", "
                      )}
                    </h1>
                  </div>
                )}
                <div className="flex my-1">
                  <h1 className="w-1/2">Type</h1>
                  <h1 className="w-1/2">: {data.type.label}</h1>
                </div>
                <div className="flex my-1">
                  <h1 className="w-1/2">Release Date</h1>
                  <h1 className="w-1/2">
                    :
                    {date.value === "date"
                      ? moment(data.release_date).format("LL")
                      : ` ${data.season_id.label} ${data.season_year.label}`}
                  </h1>
                </div>
                <div className="flex my-1">
                  <h1 className="w-1/2">Retail Price</h1>
                  <h1 className="w-1/2">
                    : {data.retail_price_currency.label} {data.retail_price}
                  </h1>
                </div>
                <div className="flex my-1">
                  <h1 className="w-1/2">Color</h1>
                  <h1 className="w-1/2">: {data.color}</h1>
                </div>
                <div className="flex my-1">
                  <h1 className="w-1/2">Description</h1>
                  <h1 className="w-1/2">: {data.story}</h1>
                </div>
                {dimension &&
                  dimension.map((el: any) => (
                    <div className="flex my-1">
                      <h1 className="w-1/2">{el.label}</h1>
                      <h1 className="w-1/2">: {el.value}</h1>
                    </div>
                  ))}
              </div>
              <hr />
            </div>
          )}
        </div>

        <div className="flex justify-center items-center pt-2 outline-none h-12% shadow-inner">
          <SmallButton
            isSubmitting={mutation.isLoading}
            loadColor={"white"}
            height={10}
            type={"button"}
            color={"bg-primary"}
            hoverColor={"bg-red-300"}
            text={"Submit"}
            textColor={"text-white"}
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmProduct;
