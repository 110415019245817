import moment from "moment";

export const formatRupiah = (value: number, currency?: string) => {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: currency || "IDR",
  });
};

export const formatDate = (
  date: Date | string,
  format:
    | "YYYY-MM-DD"
    | "DD/MM/YYYY"
    | "YYYY"
    | "MM"
    | "DD"
    | "LLLL"
    | "llll"
    | "LLL"
    | "lll" = "YYYY-MM-DD",
  isIso?: boolean
) => {
  if (isIso) {
    return moment(date).toISOString();
  }
  return moment(date).format(format);
};

export const isMobile = () => {
  return window.innerWidth < 640 ? true : false;
};

export const renderRole = (role: string) => {
  const listRole: any = {
    admin: "Main Admin",
    partner: "Partnership Officer",
    official: "Official Officer",
  };
  const desc = listRole[role];
  return desc;
};
