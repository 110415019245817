import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { OnBiddingRequest } from "../../../apis/on_bidding/OnBiddingRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import { optRoutesDetail } from "../helpers/list";
import ProductInfo from "./on_bidding_detail_component/ProductInfo";
import BidderInfo from "./on_bidding_detail_component/BidderInfo";

const OnBiddingDetail = () => {
  const history = useHistory();
  const params: { code: string } = useParams();

  const { data, isLoading } = useQuery("getOnBiddingDetail", () =>
    OnBiddingRequest.getOnBiddingDetail(params.code)
  );

  return (
    <>
      <NavDetail
        title1={"On Bidding"}
        title2={"Details"}
        handleBack={() => history.goBack()}
      />
      <div className="md:pt-28 sm:pt-20 pt-16">
        <DynamicBreadcrumb data={optRoutesDetail(params.code)} />

        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
            {/* Product Images */}
            <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                <h1 className="font-medium md:text-xl text-base">
                  Product Images
                </h1>
              </div>
              <hr />

              <div className="md:mx-8 mx-5 md:my-6 my-5">
                <div className="flex flex-col justify-center">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Display Image
                  </h1>
                  <div className="flex justify-center">
                    <img
                      className="w-10/12"
                      src={data.product_detail.display_image_url}
                      alt={data.product_detail.slug}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
              {/* seller information */}
              <BidderInfo data={data} />

              {/* user information */}
              <div className="mt-10">
                <ProductInfo data={data} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OnBiddingDetail;
