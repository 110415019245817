import { useQuery } from "react-query";
import { CampaignRequest } from "../../../../apis/campaign/CampaignRequest";
import LocalStorage from "../../../../config/LocalStorage";

const GetCampaignTemplateId = (id: string) => {
  const role = LocalStorage.ROLE || localStorage.getItem("ROLE");
  const { data, isLoading } = useQuery(
    ["getCampaignProductbyId", id],
    () => CampaignRequest.getCampaignProductbyId(id, role),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default GetCampaignTemplateId;
