import { createContext } from "react";
import { IFeaturedProductContext, IFeaturedProductState } from "./state";

export const initialFeaturedProductState: IFeaturedProductState = {
  showFilter: false,
  showDelete: {
    status: false,
    id: 0,
  },
  chip: [],
};

const FeaturedProductContext = createContext<IFeaturedProductContext>({
  FeaturedProductState: initialFeaturedProductState,
  FeaturedProductDispatch: () => undefined,
});

export const FeaturedProductContextProvider = FeaturedProductContext.Provider;
export const FeaturedProductContextConsumer = FeaturedProductContext.Consumer;

export default FeaturedProductContext;
