import { useFormik } from "formik";
import React, { useContext } from "react";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { TrackingRequest } from "../../../../apis/tracking/TrackingRequest";
import SmallButton from "../../../../components/button/SmallButton";
import TrackingContext from "../../../../store/tracking/TrackingContext";

const AddLocationCard = () => {
  const context = useContext(TrackingContext);
  const { trackingDispatch } = context;

  const goBack = () => {
    trackingDispatch({
      type: "show_add",
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TrackingRequest.addLocation(values, setSubmitting, goBack),
    {
      onMutate: () => {
        console.log("mutate add location");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTrackingLocation");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-productCategory h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center p-7 pb-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Add</h1>
            <h1 className="ml-1 text-xl text-primary">Location</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 pt-3">
          <div className="mt-2">
            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-textPrimary">Name</h1>
            </div>
            <Input
              type={"text"}
              handleChange={handleChange}
              disable={false}
              name={"name"}
              placeholder={"Place holder"}
            />
            {touched.name && errors.name ? (
              <h1 className="text-primary -mt-1 text-sm">{errors.name}</h1>
            ) : null}
          </div>
          <div className="flex justify-center mt-5 mb-7 outline-none">
            <SmallButton
              isSubmitting={isSubmitting}
              dirty={dirty}
              loadColor={"white"}
              height={10}
              type={"submit"}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Add"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLocationCard;
