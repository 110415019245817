import React, { useRef, useState } from "react";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";
import { Divider } from "@material-ui/core";
import { QRCode } from "react-qrcode-logo";
import { toJpeg } from "html-to-image";
import { downloadFile, reduceImageSize } from "../../../utils/download_file";
import ErrorHandler from "../../toast/ErrorHandler";

interface IOprintLCQRProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  code: string | any;
  title?: string;
  erp?: boolean;
}

const OprintLCQR: React.FC<IOprintLCQRProps> = ({
  isOpen,
  setOpen,
  code,
  title = "Print LC QR",
  erp = false,
}) => {
  const qrCodeRef = useRef<any>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const downloadJpeg = () => {
    setSubmitting(true);
    toJpeg(qrCodeRef.current, { quality: 1 }).then((dataUrl) => {
      reduceImageSize(dataUrl, "jpeg")
        .then((compressed) => {
          downloadFile(`${code}-qr-code.jpeg`, compressed, () =>
            ErrorHandler("#00C62E", "Success Download QR Code")
          );
        })
        .then(() => {
          setSubmitting(false);
          setOpen(false);
        });
    });
  };

  return (
    <ADialogBox
      content={{
        title: title,
        body: (
          <main className="flex flex-col">
            <Divider />
            <div ref={qrCodeRef} className="bg-white p-2 flex justify-center">
              <QRCode
                size={200}
                qrStyle="squares"
                eyeRadius={2}
                value={`${process.env.REACT_APP_SFRONTEND_API_BASE_URL}/${code}`}
                bgColor="#FFFFFF"
              />
            </div>
            <div className="flex justify-center w-full mt-4">
              <Abutton
                model="long"
                onClick={downloadJpeg}
                title="Download QR Code"
                isLoading={isSubmitting}
              />
            </div>
          </main>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={setOpen}
    />
  );
};

export default OprintLCQR;
