import React, { useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import TransactionContext from "../../../../../store/transaction/TransactionContext";
import { ActionType } from "../../../../../store/transaction/action";

interface IProps {
  dataStep: any;
  dataBnib: any;
}

const InputTracking: React.FC<IProps> = ({ dataStep, dataBnib }) => {
  const { TransactionDispatch } = useContext(TransactionContext);

  const [priority, setPriority] = useState(0);

  useEffect(() => {
    setPriority(dataBnib?.current_office_custom_tracking_step?.priority);
  }, [dataBnib]);

  const handleSetStep = (newPriority: number, id: number) => {
    if (newPriority === priority) {
      setPriority(dataBnib.current_office_custom_tracking_step.priority);
    } else {
      setPriority(newPriority);
      TransactionDispatch({
        type: ActionType.SetPriorityTracking,
        payload: id,
      });
    }
  };

  return (
    <div className="mt-2">
      <h1 className="text-textPrimary">Tracking Process</h1>
      <div className="flex flex-col my-3">
        {dataStep?.map((el: any) => (
          <FormControlLabel
            key={el.id}
            style={{ marginTop: 5, marginBottom: 5 }}
            control={
              <Checkbox
                disabled={
                  el?.priority <=
                  dataBnib?.current_office_custom_tracking_step?.priority
                    ? true
                    : false
                }
                checked={el?.priority <= priority ? true : false}
                onChange={() => handleSetStep(el.priority, el.id)}
                name="priority"
                style={{ color: "#ed4846" }}
              />
            }
            label={el.description}
            className="text-sm"
          />
        ))}
      </div>
    </div>
  );
};

export default InputTracking;
