import { createContext } from "react";
import { IOnSellingContext, IinitialOnSellingState } from "./interface";

export const initialOnSellingState: IinitialOnSellingState = {
  showFilter: false,
  showFilterSeeAll: false,
  showSeeAllPerSize: {
    status: false,
    size: 0,
  },
  size: [],
  product: [],
  sellOptions: [],
  seller: [],
  chip: [],
  limit: 10,
  offset: 0,
};

const OnSellingContext = createContext<IOnSellingContext>({
  onSellingState: initialOnSellingState,
  onSellingDispatch: () => {},
});

export const OnSellingContextProvider = OnSellingContext.Provider;
export const OnSellingContextConsumer = OnSellingContext.Consumer;

export default OnSellingContext;
