import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { detail, deleteIcon } from "../../../../../assets/icons";
import Pagination from "../../../../../components/pagination/Pagination";
import { ActionType } from "../../../../../store/product/product_need_review/actions";
import ProductReviewContext from "../../../../../store/product/product_need_review/ProductReviewContext";

interface IProps {
  data: any;
}

const ProductNeedReviewTable: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { productReviewDispatch } = useContext(ProductReviewContext);
  return (
    <div>
      <table className="w-full border text-center text-sm">
        <thead>
          <tr className="h-18 bg-white shadow-sm">
            <th className="border-r">Product Image</th>
            <th className="border-r">Product Code</th>
            <th className="border-r">Product Name</th>
            <th className="border-r">Source</th>
            <th className="border-r">Product Status</th>
            <th className="border-r">Crawl Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom text-xs hover:bg-gray-50">
              <td className="border-r w-1/4">
                <div className="flex justify-center">
                  <img
                    loading="lazy"
                    src={el.foreign_products[0].display_image_url}
                    alt={el.slug}
                    className="w-1/3 p-2 rounded-md"
                  />
                </div>
              </td>
              <td className="border-r">{el.code || "-"}</td>
              <td className="border-r">{el.name || "-"}</td>

              <td className="border-r">
                {el.foreign_products.map((el: any) => (
                  <h1>{el.source}</h1>
                ))}
              </td>

              <td
                className={
                  "border-r font-bold " +
                  (el.matched_product_id ? "text-yellow-300" : "text-success")
                }
              >
                {el.matched_product_id ? "Existing" : "New Product"}
              </td>

              <td className="border-r">
                <div className="flex flex-col justify-center">
                  <h1>{moment(el.created_at).format("lll")}</h1>
                  <h1 className="mt-0.5">
                    {moment().diff(moment(el.created_at), "days") >= 1
                      ? `${moment().diff(moment(el.created_at), "days")} days`
                      : "Same day"}
                  </h1>
                </div>
              </td>

              <td className="">
                <div className="w-full flex justify-evenly">
                  {el.need_review && (
                    <img
                      onClick={() =>
                        history.push(
                          `/marketplace/product-need-review/review-product/${el._id}`
                        )
                      }
                      src={detail}
                      alt="detail"
                      className="w-4 cursor-pointer"
                    />
                  )}
                  <img
                    onClick={() => {
                      productReviewDispatch({
                        type: ActionType.ShowDelete,
                        payload: {
                          status: true,
                          id: el._id,
                        },
                      });
                    }}
                    src={deleteIcon}
                    alt="cancel"
                    className="w-4 cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default ProductNeedReviewTable;
